import { useState, useMemo } from "react";
import { makeStyles } from "tss-react/mui";
import { WidgetSettings } from "../widgets-config";
import { GetCompanyIDFromBuildingId } from "src/utils/buildings.util";
import Error from "src/shared/components/Error/Error";
import { CircularProgress } from "@mui/material";
import {
  DeviceGroup,
  getParentsForInput
} from "../../utils/entity-selection-utils";
import useGetWidgetSettingsEntities from "src/hooks/useGetWidgetSettingsEntities";
import FormInput from "src/shared/components/form-input/form-input.component";
import FormAction from "src/shared/components/form-action/form-action";

const SelectAhuSettingsComponent = ({
  onSave,
  currentProps
}: WidgetSettings) => {
  const { classes } = useStyles();
  const [buildingId, setBuildingId] = useState<string>(
    currentProps.buildingId ?? ""
  );
  const [ahuId, setAhuId] = useState<string>(currentProps.ahuId ?? "");

  const { buildings, deviceParents, isLoading, isFetching, isError, refetch } =
    useGetWidgetSettingsEntities();

  const ahuBuildings = buildings?.filter(building => building.has_ahus);

  const ahusForInput = useMemo(
    () => getParentsForInput(deviceParents, buildingId, DeviceGroup.hvac),
    [deviceParents, buildingId, isLoading, isFetching]
  );
  const handeOnBuildingChange = (value: string) => {
    setAhuId("");
    setBuildingId(value);
  };
  const handleOnAhuChange = (value: string) => {
    const selectedAhu = ahusForInput?.find(ahu => ahu.id === value);
    if (selectedAhu) {
      setBuildingId(selectedAhu.building_id);
    }
    setAhuId(value);
  };
  const handleOnSave = () => {
    onSave({
      ...currentProps,
      buildingId,
      companyId: GetCompanyIDFromBuildingId(buildingId, buildings),
      ahuId
    });
  };

  return (
    <div
      data-testid="select-ahu-settings"
      className={classes.root}>
      {isLoading || isFetching ? (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      ) : (
        <>
          {isError ? (
            <Error
              error={isError}
              setRefetchAgain={refetch}
              showGenericMessage
            />
          ) : (
            <div>
              <FormInput
                label="Building"
                inputValue={buildingId}
                setValue={handeOnBuildingChange}
                array={ahuBuildings}></FormInput>

              <div className={classes.ahuSelector}>
                <FormInput
                  label="AHU"
                  inputValue={ahuId}
                  setValue={handleOnAhuChange}
                  array={ahusForInput}></FormInput>
              </div>
            </div>
          )}
        </>
      )}
      <FormAction
        onSave={handleOnSave}
        requiresConfig={currentProps.requiresConfig}
        saveDisabled={!ahuId}></FormAction>
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {},
    loader: {
      display: "flex",
      justifyContent: "center"
    },

    ahuSelector: {
      marginTop: "1em"
    }
  };
});

export default SelectAhuSettingsComponent;
