import { apiService } from "../api";
import {
  AhusService,
  BuildingsService,
  HvacDataService,
  WebPortalService,
  Ahu,
  HvacData,
  Building,
  AqDataService,
  ScoresAndRecommendations,
  ReportsService,
  WebReportData,
  AhuStagesService,
  StageAndFilterload
} from "src/utils/client";
import { AggGranularity } from "src/utils/client";
import moment from "moment";
import { defaultPromiseArg } from "./types";

export const widgetService = apiService
  .enhanceEndpoints({
    addTagTypes: [
      "HVAC",
      "HVACALL",
      "AHUS",
      "BUILDING",
      "TIMESERIES",
      "SERVICELOG",
      "BUILDINGSUMMARIES",
      "COTWODECAYSCOREANDRECOMMENDATIONS",
      "REPORTDATA",
      "STAGESFILTERLOAD",
      "RECOMMENDATIONS"
    ]
  })
  .injectEndpoints({
    endpoints: build => ({
      getAhuForBuilding: build.query<
        Ahu,
        Pick<defaultPromiseArg, "buildingId" | "ahuId">
      >({
        queryFn: async args => {
          const { buildingId, ahuId } = args;
          const buildingAhu = await AhusService.getAhu(buildingId, ahuId);
          return { data: buildingAhu };
        },
        providesTags: (_result, _error, { ahuId }) => [{ type: "AHUS", ahuId }]
      }),
      getStagesForAhuWithFilterLoad: build.query<
        Record<string, StageAndFilterload>,
        Pick<defaultPromiseArg, "buildingId" | "ahuId">
      >({
        queryFn: async args => {
          const { buildingId, ahuId } = args;
          const stages = await AhuStagesService.getStagesForAhuWithFilterload(
            buildingId,
            ahuId
          );
          return { data: stages };
        },
        providesTags: (_result, _error, { ahuId }) => [
          { type: "STAGESFILTERLOAD", ahuId }
        ]
      }),
      getFiltrationEfficiencyScoreAndRecommendations: build.query<
        ScoresAndRecommendations,
        string
      >({
        queryFn: async buildingId => {
          const recommendations =
            await AqDataService.getFiltrationEfficiencyScoreAndRecommendations(
              buildingId
            );
          return { data: recommendations };
        },
        providesTags: (_result, _error, buildingId) => [
          { type: "RECOMMENDATIONS", buildingId }
        ]
      }),
      getHvacDataForStage: build.query<
        HvacData,
        Pick<defaultPromiseArg, "buildingId" | "ahuId" | "stageId">
      >({
        queryFn: async args => {
          const { buildingId, ahuId, stageId } = args;

          const buildingAhu =
            await HvacDataService.getLatestHvacDeviceDataPointForStage(
              buildingId,
              ahuId,
              stageId
            );
          return { data: buildingAhu };
        },
        providesTags: (_result, _error, { stageId }) => [
          { type: "HVAC", stageId }
        ]
      }),

      getBuilding: build.query<
        Building,
        Pick<defaultPromiseArg, "buildingId" | "companyId">
      >({
        queryFn: async args => {
          const { buildingId, companyId } = args;
          const building = await BuildingsService.getBuilding(
            companyId,
            buildingId
          );
          return { data: building };
        },
        providesTags: (_result, _error, { buildingId }) => [
          { type: "BUILDING", buildingId }
        ]
      }),
      getTimeSeries: build.query<
        Record<string, any>,
        Pick<defaultPromiseArg, "buildingId" | "ahuId"> & { hours: number }
      >({
        queryFn: async args => {
          const { buildingId, ahuId, hours } = args;
          const timeSeries = await WebPortalService.ahuStageData(
            buildingId,
            ahuId,
            String(
              moment()
                .subtract(hours || 168, "hours")
                .unix()
            ),
            String(moment().unix()),
            AggGranularity.HOUR
          );

          return { data: timeSeries };
        },
        providesTags: ["TIMESERIES"]
      }),
      getCotwoDecayScoreAndRecommedations: build.query<
        ScoresAndRecommendations,
        string
      >({
        queryFn: async buildingId => {
          const scoresAndRecommendations =
            await AqDataService.getCotwoDecayScoreAndRecommendations(
              buildingId
            );

          return { data: scoresAndRecommendations };
        },
        providesTags: ["COTWODECAYSCOREANDRECOMMENDATIONS"]
      }),
      getWebReportData: build.query<
        WebReportData,
        Pick<defaultPromiseArg, "buildingId" | "companyId">
      >({
        queryFn: async args => {
          const { buildingId, companyId } = args;

          const report = await ReportsService.getWebReportData(
            companyId,
            buildingId
          );

          return { data: report };
        },
        providesTags: ["REPORTDATA"]
      })
    })
  });

export const {
  useGetAhuForBuildingQuery,
  useGetBuildingQuery,
  useGetHvacDataForStageQuery,
  useLazyGetHvacDataForStageQuery,
  useGetTimeSeriesQuery,
  useGetCotwoDecayScoreAndRecommedationsQuery,
  useGetWebReportDataQuery,
  useGetStagesForAhuWithFilterLoadQuery,
  useGetFiltrationEfficiencyScoreAndRecommendationsQuery
} = widgetService;
