import { useMemo } from "react";
import useGetHvacView from "src/sections/hvac/air-handling-units/hooks/useGetHvacView";
import { useGetRoomDevicesQuery } from "src/store/services/buildings/buildings.service";
import { useAppSelector } from "src/hooks/useAppSelector";
import { getDevicesSearchTerm } from "src/store/hvac/selectors";
import LoaderComponent from "src/shared/components/loader/loader.component";
import Table from "src/shared/components/Table/Table";
import { useParams } from "react-router-dom";

export const Devices = () => {
  const { buildingId, roomId } = useParams();
  const { isListViewActive } = useGetHvacView();
  const devicesSearchTerm = useAppSelector(getDevicesSearchTerm);

  const {
    data: roomWithDevices,
    isLoading,
    isFetching
  } = useGetRoomDevicesQuery({ buildingId, roomId });

  const searchedDevices = useMemo(() => {
    if (!Boolean(roomWithDevices)) return [];
    if (devicesSearchTerm?.length)
      return roomWithDevices.devices?.filter(({ name }) =>
        name.toLowerCase().includes(devicesSearchTerm)
      );
    return roomWithDevices.devices;
  }, [devicesSearchTerm, isFetching]);

  return (
    <div data-testid="devices">
      {isLoading || isFetching ? (
        <LoaderComponent />
      ) : (
        <Table
          showHeader={isListViewActive}
          type="devices"
          data={searchedDevices}
        />
      )}
    </div>
  );
};

export default Devices;
