import { Box, Typography, Tabs, Tab } from "@mui/material";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult
} from "react-beautiful-dnd";
import EditIcon from "@mui/icons-material/Edit";
import {
  getTabStyle,
  getEditIconStyle,
  getTabsStyle
} from "../../view/overview.style";
import useOverview from "src/hooks/useOverview";
import { Page } from "../../view/types";
import { useUpdateUsersPagesMutation } from "src/store/services/overview/overview.service";
import { useAppSelector } from "src/hooks/useAppSelector";
import { getUserId } from "src/store/auth/selectors";
import { useParams, useNavigate } from "react-router-dom";

interface IPageTabs {
  pages: Page[];
}

const PageTabs = ({ pages }: IPageTabs) => {
  const userId = useAppSelector(getUserId);
  const [updateUsersPages] = useUpdateUsersPagesMutation();
  const { editing, openPageModal, setEditingTabIndex } = useOverview();
  const navigate = useNavigate();
  const tabId = Number(useParams()?.tabId);

  const handleDragEnd = (result: DropResult) => {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    const updatedPages = reorderTabs(
      pages,
      result.source.index,
      result.destination.index
    );
    updateUsersPages({ userId, updatedPages });
  };

  const handleChangeTabs = (value: number) => {
    navigate(`/dashboard/tab/${value}`);
  };

  const handleOnEdit = (event, index: number) => {
    event.stopPropagation();
    openPageModal();
    setEditingTabIndex(index);
  };
  const pageExists = Boolean(pages.length);

  return (
    <Box display="div">
      {!pageExists ? null : editing ? (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable
            droppableId="pageTabs"
            direction="horizontal">
            {provided => {
              return (
                // @ts-ignore
                <Box
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={getTabsStyle}>
                  {pages.map((page, index) => (
                    <Draggable
                      key={`page_${index}`}
                      draggableId={`page_${index}`}
                      index={index}>
                      {(provided, snapshot) => (
                        <Box
                          display="div"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getTabStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                            index,
                            pageExists ? tabId : 0
                          )}
                          onClick={() => {
                            handleChangeTabs(index);
                          }}>
                          <Typography
                            style={{
                              color:
                                tabId === index
                                  ? "#0795bb"
                                  : "rgba(0, 0, 0, 0.6)"
                            }}>
                            {page.pageName.toUpperCase()}
                          </Typography>
                          {editing && (
                            <EditIcon
                              sx={getEditIconStyle}
                              onClick={event => {
                                handleOnEdit(event, index);
                              }}
                            />
                          )}
                        </Box>
                      )}
                    </Draggable>
                  ))}
                </Box>
              );
            }}
          </Droppable>
        </DragDropContext>
      ) : (
        <Tabs
          sx={{ marginTop: 1 }}
          scrollButtons
          allowScrollButtonsMobile
          variant="scrollable"
          value={tabId}
          onChange={(_event, pageIndex) => {
            handleChangeTabs(pageIndex);
          }}>
          {pages.map((page, index) => (
            <Tab
              data-testid={`pageTabs-tab-${page.pageId}`}
              value={index}
              key={`page_tab_${index}`}
              label={
                <Box
                  display="div"
                  style={{
                    display: "flex",
                    alignItems: "center"
                  }}>
                  <Typography>{page.pageName}</Typography>
                </Box>
              }
            />
          ))}
        </Tabs>
      )}
    </Box>
  );
};

export default PageTabs;

const reorderTabs = (pages: Page[], startIndex: number, endIndex: number) => {
  const result = Array.from(pages);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
