import { makeStyles } from "tss-react/mui";
import { Grid, Typography, Hidden } from "@mui/material";

const AhuTableHeaderComponent = () => {
  const { classes } = useStyles();
  return (
    <div
      className={classes.root}
      data-testid="ahu-header">
      <Grid container>
        <Grid
          item
          lg={4}
          md={4}
          sm={4}
          xs={6}>
          <Typography>AHU Name</Typography>
        </Grid>
        <Hidden mdDown>
          <Grid
            item
            lg={2}
            md={2}
            sm={2}>
            <Typography>Number of Stages</Typography>
          </Grid>
        </Hidden>
        <Hidden smDown>
          <Grid
            item
            lg={3}
            md={3}
            sm={8}>
            <Typography sx={{ textAlign: "center" }}>Stages</Typography>
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {
      "& .MuiTypography-body1": {
        color: "#919293"
      }
    },
    centered: {
      textAlign: "center" as const
    }
  };
});

export default AhuTableHeaderComponent;
