import { Stack, Box, Typography } from "@mui/material";

interface HorizontalScaleProps {
  currentValue: number;
  ranges: { color: string; to: number; from: number }[];
}

const HorizontalScale = ({ currentValue, ranges }: HorizontalScaleProps) => {
  const lowRange = ranges?.at(0);
  const middleRange = ranges?.at(1);
  const highRange = ranges?.at(-1);
  const min = lowRange?.from;
  const max = highRange?.to;
  const middleRangeMaxValue = middleRange?.to;
  const middleRangeMinValue = middleRange?.from;

  const totalRange = max - min;
  const minToMinValue = ((lowRange?.to - min) / totalRange) * 100;
  const minValueToMaxValue =
    ((middleRangeMaxValue - lowRange?.to) / totalRange) * 100 - 2;
  const maxValueToMax = ((max - middleRangeMaxValue) / totalRange) * 100;
  const valuePosition = ((currentValue - min) / totalRange) * 100;

  const isCurrentValueBiggerThenMax = currentValue > max;

  const renderText = (value: number, position: "right" | "left" | "center") => {
    const isRight = position === "right";
    const isCenter = position === "center";
    return (
      <Typography
        fontSize={12}
        fontWeight={500}
        color={"black"}
        sx={{
          mt: 2,
          left: isRight || isCenter ? "unset" : "-12px",
          right: isRight || !isCenter ? "-5px" : "unset",
          position: "absolute",
          marginTop: "8px"
        }}>
        {Number(value?.toFixed(2))}
      </Typography>
    );
  };
  return (
    <Stack
      direction={"row"}
      sx={{ position: "relative", width: "100%" }}>
      <div
        style={{
          position: "absolute",
          top: "-11px",
          left: ` ${valuePosition}%`
        }}>
        <svg
          width="5"
          height="11"
          viewBox="0 0 5 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2.53564 3L0.535644 1.11273e-07L4.53564 -2.38419e-07L2.53564 3Z"
            fill="#5A5B5D"
          />
          <path
            d="M2.53564 7.5L4.53564 10.5L0.535644 10.5L2.53564 7.5Z"
            fill="#5A5B5D"
          />
        </svg>
      </div>

      <Stack
        sx={{
          width: `${minToMinValue}%`,
          height: "3px",
          backgroundColor: lowRange?.color
        }}>
        <Box
          component="div"
          sx={{
            height: 15,
            width: "1.5px",
            background: "black",
            position: "absolute",
            top: "-6px",
            left: 0
          }}></Box>
        {renderText(min, "center")}
      </Stack>
      <Stack
        sx={{
          height: "3px",
          backgroundColor: middleRange?.color,
          width: `${minValueToMaxValue}%`,
          borderLeft: "2px solid white",
          borderRight: "2px solid white",
          position: "relative"
        }}>
        {renderText(middleRangeMinValue, "left")}
        {renderText(middleRangeMaxValue, "right")}
      </Stack>
      <Stack
        style={{
          width: `${maxValueToMax}%`,
          height: "3px",
          backgroundColor: highRange?.color,
          borderTopRightRadius: 5,
          borderBottomRightRadius: 5
        }}>
        {isCurrentValueBiggerThenMax && renderText(max, "right")}
      </Stack>
    </Stack>
  );
};

export default HorizontalScale;
