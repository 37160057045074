import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useEffect, useState } from "react";
import useGetAppBreakPoints from "src/hooks/useGetAppBreakPoints";
import {
  useGetAhuAuditLogsQuery,
  useGetKettleAuditLogsQuery,
  useGetPaintBoothAuditLogsQuery
} from "src/store/services/buildings/buildings.service";
import { Ahu, Event, Kettle, PaintBooth, Stage } from "src/utils/client";
import { renderWeekPickerDay } from "src/utils/date-picker.utils";
import AddServiceLog from "../../service-log/add-service-log/add-service-log.component";
import ActivityFilters from "./activity-filters/activity-filters";
import ActivityLogsTable from "./activity-logs-table/ActivityLogsTable";
import ActivityTooltip from "./activity-tooltip.component";
import DownloadActivityModal from "./download-modal/download-activity-modal.component";
import { Container, Stack, TextField, Typography } from "@mui/material";

type ActivityLogsProps = {
  ahu?: Ahu;
  stages?: Stage[];
  kettle?: Kettle;
  paintBooth?: PaintBooth;
  buildingId?: string;
  open: boolean;
  setOpen: (data: boolean) => void;
};

const ActivityLogs = ({
  ahu,
  stages,
  kettle,
  open,
  setOpen,
  paintBooth,
  buildingId
}: ActivityLogsProps) => {
  const { isLg } = useGetAppBreakPoints();
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [pageData, setPageData] = useState([]);
  const [startTs, setStartTs] = useState(
    moment().subtract(4, "months").startOf("day").toDate()
  );
  const [endTs, setEndTs] = useState(moment());
  const [filteredList, setFilteredList] = useState<Event[]>([]);
  const startDate = startTs.valueOf().toString();
  const endDate = endTs.valueOf().toString();

  const {
    data: ahuAuditLogs,
    isLoading: isLoadingAhuAuditLogs,
    isFetching: isFetchingAhuAuditLogs
  } = useGetAhuAuditLogsQuery(
    {
      buildingId,
      startTs: startDate,
      endTs: endDate,
      ahuId: ahu?.id
    },
    { skip: !Boolean(ahu?.id) }
  );
  const {
    data: kettleAuditLogs,
    isLoading: isLoadingKettleAuditLogs,
    isFetching: isFetchingKettleAuditLogs
  } = useGetKettleAuditLogsQuery(
    {
      buildingId,
      kettleId: kettle?.id,
      startTs: startDate,
      endTs: endDate
    },
    { skip: !Boolean(kettle?.id) }
  );

  const {
    data: paintBoothAuditLogs,
    isLoading: isLoadingPaintBoothAuditLogs,
    isFetching: isFetchingPaintBoothAuditLogs
  } = useGetPaintBoothAuditLogsQuery(
    {
      buildingId,
      paintBoothId: paintBooth?.id,
      startTs: startDate,
      endTs: endDate
    },
    { skip: !Boolean(paintBooth?.id) }
  );
  const auditLogs = kettleAuditLogs || ahuAuditLogs || paintBoothAuditLogs;

  const logsExist = Boolean(auditLogs);
  const loading =
    isLoadingAhuAuditLogs ||
    isLoadingKettleAuditLogs ||
    isLoadingPaintBoothAuditLogs;
  const fetching =
    isFetchingAhuAuditLogs ||
    isFetchingKettleAuditLogs ||
    isFetchingPaintBoothAuditLogs;

  useEffect(() => {
    if (pageData) setPageData(pageData);
  }, [pageData]);

  useEffect(() => {
    if (!logsExist || loading) return;
    setPageData(filteredList?.slice(0, 5));
  }, [filteredList?.length, loading]);

  const userHasFilteredData = filteredList?.length !== auditLogs?.length;
  const handleAdd = () => {
    setOpen(true);
  };

  const handleDownload = () => {
    setDownloadModalOpen(true);
  };

  return (
    <Container
      maxWidth={false}
      style={{ padding: 0 }}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ p: 2, mb: 1, borderBottom: "2px solid rgba(0, 0, 0, 0.25)" }}>
        <Stack
          direction={"row"}
          alignItems={"center"}>
          <ActivityTooltip />
          <Typography
            fontWeight={500}
            ml={2}>
            ACTIVITY
          </Typography>
        </Stack>
        <Stack
          direction={{ lg: "row", md: "column" }}
          alignItems={{ lg: "center", xs: "flex-end" }}>
          {isLg && (
            <Stack
              spacing={1}
              direction={"row"}
              alignItems={"center"}
              sx={{ width: "320px", mr: 2 }}>
              <DatePicker
                label="from date"
                value={startTs}
                renderDay={renderWeekPickerDay}
                onChange={newValue => setStartTs(newValue)}
                renderInput={params => <TextField {...params} />}
              />
              <DatePicker
                label="to date"
                value={endTs}
                renderDay={renderWeekPickerDay}
                onChange={newValue => setEndTs(newValue)}
                renderInput={params => <TextField {...params} />}
              />
            </Stack>
          )}
          <Typography
            sx={styles.action}
            onClick={handleAdd}>
            Add Service Log
          </Typography>
          <Typography
            sx={styles.action}
            onClick={handleDownload}>
            Download
          </Typography>
        </Stack>
      </Stack>
      <ActivityFilters
        setFilteredList={setFilteredList}
        auditLogs={auditLogs}
      />
      <ActivityLogsTable
        stages={stages}
        loading={fetching || loading}
        pageData={pageData}
        filteredList={filteredList}
        userHasFilteredData={userHasFilteredData}
        setPageData={setPageData}
        setOpen={setOpen}
      />
      <AddServiceLog
        ahu={ahu}
        kettle={kettle}
        paintBooth={paintBooth}
        open={open}
        setOpen={setOpen}
      />
      <DownloadActivityModal
        entityType={ahu ? "ahu" : kettle ? "kettle" : "paint_booth"}
        entity={ahu ?? kettle ?? paintBooth}
        downloadModalOpen={downloadModalOpen}
        setDownloadModalOpen={setDownloadModalOpen}
      />
    </Container>
  );
};

const styles = {
  action: {
    fontWeight: 500,
    fontSize: "0.9rem",
    color: "#0795BB",
    cursor: "pointer",
    ":hover": {
      textDecoration: "underline"
    },
    margin: "0rem 0.5rem"
  }
};

export default ActivityLogs;
