import { Box, Typography, Button } from "@mui/material";
import { Views } from "src/shared/constants/generals";

const WidgetPreview = ({
  widget,
  view,
  widgetsToAdd,
  setWidgetsToAdd,
  toBeAdded = false
}) => {
  const isList = view === Views.LIST;

  const handleOnAdd = () => {
    if (toBeAdded) {
      let currentWidgetsToAdd = [...widgetsToAdd];
      currentWidgetsToAdd.splice(widget.index, 1);
      setWidgetsToAdd([...currentWidgetsToAdd]);
    } else {
      setWidgetsToAdd([...widgetsToAdd, widget]);
    }
  };

  return (
    <Box
      data-testid="container"
      sx={styles.box(isList)}>
      {!isList && (
        <>
          <img
            className="widget-preview"
            src={widget.preview}
          />
          <br />
        </>
      )}
      <Box sx={styles.name}>
        <Typography>{widget.name}</Typography>
        <Button
          data-testid="add-widget"
          onClick={handleOnAdd}
          size="small"
          variant="contained">
          <Typography>{toBeAdded ? "Remove" : "Add"}</Typography>
        </Button>
      </Box>
    </Box>
  );
};

const styles = {
  box: (isList: boolean) => ({
    padding: 1,
    border: "1px solid #efefef",
    borderRadius: isList ? 0 : 1,
    paddingBottom: 0,
    paddingTop: isList ? 0 : 1,
    marginBottom: isList ? 0 : 1,
    transition: "all 0.2s",
    backgroundColor: isList ? "transparent" : "rgba(0,0,0,0.035)"
  }),
  name: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 1
  }
};

export default WidgetPreview;
