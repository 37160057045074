/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AqDeviceAndLatest } from '../models/AqDeviceAndLatest';
import type { Room } from '../models/Room';
import type { RoomNew } from '../models/RoomNew';
import type { RoomStatus } from '../models/RoomStatus';
import type { RoomUpdates } from '../models/RoomUpdates';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class RoomsService {
  /**
   * Get Rooms For Building
   * Returns possibly empty list of rooms for the building, or `null` for invalid building_id.
   * @param buildingId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getRoomsForBuilding(
    buildingId: string,
  ): CancelablePromise<(Array<Room> | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/rooms/',
      path: {
        'building_id': buildingId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Create Room
   * @param buildingId
   * @param requestBody
   * @returns Room Successful Response
   * @throws ApiError
   */
  public static createRoom(
    buildingId: string,
    requestBody: RoomNew,
  ): CancelablePromise<Room> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/buildings/{building_id}/rooms/',
      path: {
        'building_id': buildingId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Database Error`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Room Statuses
   * Returns possibly empty list of room statuses for the building, or `null` for invalid building_id.
   * @param buildingId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getRoomStatuses(
    buildingId: string,
  ): CancelablePromise<(Array<RoomStatus> | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/rooms/current-statuses/',
      path: {
        'building_id': buildingId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Room
   * @param buildingId
   * @param roomId
   * @param includeDeviceStatuses
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getRoom(
    buildingId: string,
    roomId: string,
    includeDeviceStatuses: boolean = false,
  ): CancelablePromise<(Room | RoomStatus | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/rooms/{room_id}',
      path: {
        'building_id': buildingId,
        'room_id': roomId,
      },
      query: {
        'include_device_statuses': includeDeviceStatuses,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Update Room
   * @param buildingId
   * @param roomId
   * @param requestBody
   * @returns Room Successful Response
   * @throws ApiError
   */
  public static updateRoom(
    buildingId: string,
    roomId: string,
    requestBody: RoomUpdates,
  ): CancelablePromise<Room> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/buildings/{building_id}/rooms/{room_id}',
      path: {
        'building_id': buildingId,
        'room_id': roomId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Database Error`,
        404: `Invalid Object Id`,
        409: `Name Conflict`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Delete Room
   * @param buildingId
   * @param roomId
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public static deleteRoom(
    buildingId: string,
    roomId: string,
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/buildings/{building_id}/rooms/{room_id}',
      path: {
        'building_id': buildingId,
        'room_id': roomId,
      },
      errors: {
        400: `Bad Request`,
        404: `Invalid Object Id`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Aq Devices For Room
   * Returns possibly empty list of aq devices for the room, or `null` for invalid building/room id.
   * @param buildingId
   * @param roomId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getAqDevicesForRoom(
    buildingId: string,
    roomId: string,
  ): CancelablePromise<(Array<AqDeviceAndLatest> | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/rooms/{room_id}/aq-devices/',
      path: {
        'building_id': buildingId,
        'room_id': roomId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
