import ModalComponent from "src/shared/components/modal/modal.component";
import get from "lodash/get";
import { makeStyles } from "tss-react/mui";
import { alpha, Typography, useTheme } from "@mui/material";
import {
  GOOD,
  MODERATE,
  SENSITIVE,
  UNHEALTHY,
  VERY_UNHEALTHY,
  HAZARDOUS
} from "src/shared/constants/building-status";
import useGetPolutantDictionary from "src/hooks/useGetPollutantDictionary";
import useGetHvacStatusInfoDictionary from "src/hooks/useGetHvacStatusInfoDictionary";

type Props = {
  measurements?: string;
  open?: boolean;
  onClose?: () => void;
};

const AqiReferenceTableComponent = (props: Props) => {
  const { open, onClose, measurements } = props;
  const { classes } = useStyles();
  const { pollInfoDict } = useGetPolutantDictionary();
  const { dictionary: hvacDictionary } = useGetHvacStatusInfoDictionary();

  const dictionaries = {
    aq: pollInfoDict,
    hvac: hvacDictionary
  };

  const statuses = {
    aq: "status",
    hvac: "pressureStatus"
  };

  const statusKey = {
    aq: "statuses.thresholds",
    hvac: "pressure_status"
  };

  const theme: any = useTheme();

  const aqiClasses = {
    aq: [GOOD, MODERATE, SENSITIVE, UNHEALTHY, VERY_UNHEALTHY, HAZARDOUS],
    hvac: ["Too Low", "Good", "Moderate", "High", "Very High", "Too High"]
  };
  if (!measurements || (measurements !== "aq" && measurements !== "hvac")) {
    return <div></div>;
  }
  return (
    <ModalComponent
      open={open}
      onClose={onClose}>
      <div
        data-testid="aqi-reference-table"
        className={classes.root}>
        <div>
          <Typography variant="h6">Air Quality Reference Table</Typography>
          <Typography variant="subtitle1">
            According to the EPA Standard
          </Typography>
        </div>
        <div className={classes.body}>
          <table className={classes.table}>
            <thead>
              <tr>
                <th>AQI Class</th>
                {Object.values(dictionaries[measurements]).map((item: any) => (
                  <th key={item.name || item.full_name}>
                    {item.short_name || item.full_name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {aqiClasses[measurements].map(aqiClass => {
                let className = aqiClass;
                if (measurements === "hvac") {
                  className = className.toLowerCase().replace(" ", "_");
                }
                let classColor =
                  theme.palette[statuses[measurements]][className];

                return (
                  <tr key={`aqi_class_${className}`}>
                    <td
                      className={classes.className}
                      style={{
                        backgroundColor: alpha(classColor, 0.4),
                        borderColor: classColor
                      }}>
                      {aqiClass}
                    </td>
                    {Object.values(dictionaries[measurements]).map(
                      (item: any, index: number) => {
                        let statusArray = get(item, statusKey[measurements]);
                        let range = statusArray
                          ? statusArray.find(
                              status => status.status === aqiClass
                            )
                          : null;

                        if (range) {
                          return (
                            <td
                              style={{
                                backgroundColor: alpha(classColor, 0.2)
                              }}
                              key={`aqi_class_range${
                                item.name || item.short_name || index
                              }`}>
                              {range.low} - {range.high}
                            </td>
                          );
                        } else {
                          return (
                            <td
                              key={`aqi_class_range${
                                item.name || item.short_name || index
                              }`}
                              style={{
                                backgroundColor: alpha(classColor, 0.2)
                              }}></td>
                          );
                        }
                      }
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </ModalComponent>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {
      maxWidth: "calc(100vw - 150px)"
    },
    body: {
      overflow: "auto",
      border: "2px solid #EBEFFC",
      borderRadius: 8
    },
    table: {
      overflow: "hidden",
      borderCollapse: "collapse",
      fontSize: "smaller",
      "& td": {
        textAlign: "left",
        padding: "0.2em 0.75em",
        whiteSpace: "nowrap"
      },
      "& th": {
        textAlign: "left",
        padding: "0.2em 0.75em",
        whiteSpace: "nowrap"
      },
      "& tr": {
        borderBottom: "2px solid #EBEFFC",
        height: 54,
        textAlign: "left"
      },
      "& tr:last-child": {
        borderBottom: "none"
      }
    },
    className: {
      fontWeight: "bold",
      borderRight: "4px solid"
    }
  };
});

export default AqiReferenceTableComponent;
