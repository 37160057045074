import { Button, Link, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { handleDeviceModal } from "src/store/hvac/reducer";

const DevicesEmptyComponent = () => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();

  const setModalOpen = () => {
    dispatch(handleDeviceModal(true));
  };

  return (
    <div
      className={classes.root}
      data-testid="device-empty">
      <Typography
        variant="h4"
        gutterBottom>
        You don't have any devices
      </Typography>
      <Typography
        variant="body1"
        className={classes.paragraph}
        gutterBottom>
        Please add a device or request support with the link below to start
        monitoring your building's air quality.
      </Typography>
      <Button
        variant="contained"
        className={classes.btn}
        onClick={setModalOpen}
        data-testid="add-first-device-button">
        Add device
      </Button>
      <Typography
        variant="body1"
        gutterBottom>
        or
      </Typography>
      <Link href="mailto:support@i-qlair.com?subject=Help with AQ Devices">
        Request support
      </Link>
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {
      textAlign: "center" as const
    },
    btn: {
      marginTop: "1em",
      marginBottom: "0.5em",
      width: "100%"
    },
    paragraph: {
      textAlign: "justify" as const
    }
  };
});

export default DevicesEmptyComponent;
