import { ServiceLog } from "./client";
import moment from "moment";

export const sortServiceLogs = (serviceLogs: ServiceLog[]): ServiceLog[] =>
  serviceLogs?.sort((a, b) => {
    const dateA = moment(a.time);
    const dateB = moment(b.time);

    if (dateA.isBefore(dateB)) {
      return 1;
    }
    if (dateA.isAfter(dateB)) {
      return -1;
    }

    return 0;
  }) ?? [];
