import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Button, Hidden, Stack } from "@mui/material";
import BuildingTabNavigation from "src/shared/components/building-tab-navigation/building-tab-navigation";
interface DeviceDetailsHeaderProps {
  device: any;
  hasMore: boolean;
  disabled: boolean;
  onNavigate: (next: boolean) => void;
}

export default function DeviceDetailsHeader({
  device,
  hasMore,
  disabled,
  onNavigate
}: DeviceDetailsHeaderProps) {
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}>
      <BuildingTabNavigation titleName={device?.name}></BuildingTabNavigation>
      {hasMore && (
        <Stack
          direction={"row"}
          alignItems={"center"}>
          <Button
            data-testid="back-arrow"
            disabled={disabled}
            onClick={() => onNavigate(false)}
            sx={{ mr: 1 }}>
            <ArrowBackIosRoundedIcon />
            <Hidden smDown>Prev</Hidden>
          </Button>
          <Button
            data-testid="next"
            disabled={disabled}
            onClick={() => onNavigate(true)}>
            <Hidden smDown>Next</Hidden>
            <ArrowForwardIosIcon />
          </Button>
        </Stack>
      )}
    </Stack>
  );
}
