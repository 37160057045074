import { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

export interface Option {
  id: string;
  name: string;
}

interface IDropdownProps {
  label: string;
  options: Array<Option>;
  selected: Option;
  handleChange: Function;
}

const Dropdown = ({
  label,
  options,
  selected,
  handleChange
}: IDropdownProps) => {
  const [selectedValue, setSelectedValue] = useState(selected?.id || "");

  useEffect(() => {
    setSelectedValue(selected?.id || "");
  }, [selected]);

  const onChange = (event: SelectChangeEvent) => {
    const selectedOption: Option = options.find(
      option => option.id === event.target.value
    );
    handleChange(selectedOption);
  };

  return (
    <FormControl
      sx={{ minWidth: 220 }}
      size="small">
      <InputLabel id="demo-select-small-label">{label}</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        data-testid="demo-select-small"
        value={selectedValue}
        label={label}
        onChange={onChange}>
        {options?.map((option: Option) => (
          <MenuItem
            key={option.id}
            value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Dropdown;
