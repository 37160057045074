import { InvitationStatus } from "./../../utils/client/models/InvitationStatus";
import { CompanyInvitation, CompanyMembership } from "src/utils/client";

export const filterUsers = (
  members: CompanyMembership[],
  invited: CompanyInvitation[]
) => {
  const uniqueMembers = members.filter(
    (obj, index, self) =>
      index === self.findIndex(el => el.user_email === obj.user_email)
  );

  const uniqueInvited = invited.filter(
    user => user.invitation_status === InvitationStatus.PENDING
  );

  const users = [...uniqueMembers, ...uniqueInvited];
  return users;
};
