import { useMemo } from "react";
import { useAppSelector } from "src/hooks/useAppSelector";
import Table from "src/shared/components/Table/Table";
import LoaderComponent from "src/shared/components/loader/loader.component";
import { getKettleSearchTerm } from "src/store/kettle/selector";
import { useGetKettlesForBuildingQuery } from "src/store/services/kettle/kettle.service";
import useGetHvacView from "../hvac/air-handling-units/hooks/useGetHvacView";
import { useParams } from "react-router-dom";

const Kettles = () => {
  const { buildingId } = useParams();
  const kettleSearchTerm = useAppSelector(getKettleSearchTerm);
  const { isListViewActive } = useGetHvacView();
  const {
    data: kettles,
    isLoading,
    isFetching
  } = useGetKettlesForBuildingQuery(buildingId);

  const sortedKettles = useMemo(() => {
    if (!Boolean(kettles)) return [];
    if (kettles?.length)
      return kettles?.filter(({ name }) =>
        name.toLowerCase().includes(kettleSearchTerm)
      );

    return kettles;
  }, [kettles, kettleSearchTerm]);

  return isLoading || isFetching ? (
    <LoaderComponent />
  ) : (
    <div data-testid="kettles">
      <Table
        data={sortedKettles}
        type="kettles"
        showHeader={isListViewActive}
      />
    </div>
  );
};

export default Kettles;
