import moment from "moment";
import ModalComponent from "src/shared/components/modal/modal.component";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { useAppSelector } from "src/hooks/useAppSelector";
import {
  getAlertModalData,
  getAlertModalState
} from "src/store/notification/selectors";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import {
  setAlertModalData,
  setAlertModalOpen
} from "src/store/notification/reducer";

const AlertModalComponent = () => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const open = useAppSelector(getAlertModalState);
  const alert = useAppSelector(getAlertModalData);

  const alertValue =
    alert?.user_pref_alert_value ?? alert?.alert_value ?? "N/A";
  const inAlert = alert?.active;

  const close = () => {
    dispatch(setAlertModalOpen(false));
    dispatch(setAlertModalData(null));
  };

  const directionList = {
    lt: "<",
    gt: ">"
  };

  if (!alert) return null;

  return (
    <ModalComponent
      data-testid="alert-modal-container"
      open={open}
      onClose={close}>
      <div
        data-testid="alert-modal"
        className={classes.root}>
        <Typography variant="h6">Alert Details</Typography>
        {alert && (
          <div>
            <Grid
              container
              spacing={1}>
              <Grid
                item
                md={6}
                sm={12}
                xs={12}>
                <Typography variant="subtitle1">ID</Typography>
              </Grid>
              <Grid
                item
                md={6}
                sm={12}
                xs={12}>
                <Typography
                  sx={{ mb: "15px" }}
                  variant="body1">
                  {alert.id}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                sm={12}
                xs={12}>
                <Typography variant="subtitle1">Title</Typography>
              </Grid>
              <Grid
                item
                md={6}
                sm={12}
                xs={12}>
                <Typography
                  sx={{ mb: "15px" }}
                  variant="body1">
                  {alert?.notification?.title}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                sm={12}
                xs={12}>
                <Typography variant="subtitle1">High Priority</Typography>
              </Grid>
              <Grid
                item
                md={6}
                sm={12}
                xs={12}>
                <Typography
                  data-testid="high-priority"
                  sx={{ mb: "15px" }}
                  variant="body1">
                  {alert.high_priority ? "Yes" : "No"}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                sm={12}
                xs={12}>
                <Typography variant="subtitle1">In Alert</Typography>
              </Grid>
              <Grid
                item
                md={6}
                sm={12}
                xs={12}>
                <Typography
                  sx={{ mb: "15px" }}
                  variant="body1"
                  data-testid="in-alert-text"
                  style={{ color: inAlert ? "red" : "green" }}>
                  {inAlert ? "Yes" : "No"}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                sm={12}
                xs={12}>
                <Typography variant="subtitle1">Alert Time</Typography>
              </Grid>
              <Grid
                item
                md={6}
                sm={12}
                xs={12}>
                <Typography
                  sx={{ mb: "15px" }}
                  variant="body1">
                  {moment.utc(alert.created_at).local().format("lll")}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                sm={12}
                xs={12}>
                <Typography variant="subtitle1">Alert Header</Typography>
              </Grid>
              <Grid
                item
                md={6}
                sm={12}
                xs={12}>
                <Typography
                  sx={{ mb: "15px" }}
                  variant="body1">
                  {alert.header}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                sm={12}
                xs={12}>
                <Typography variant="subtitle1">Alerted Measurement</Typography>
              </Grid>
              <Grid
                item
                md={6}
                sm={12}
                xs={12}>
                <Typography
                  sx={{ mb: "15px" }}
                  variant="body1">
                  {alertValue}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                sm={12}
                xs={12}>
                <Typography variant="subtitle1">Alert Threshold</Typography>
              </Grid>
              <Grid
                item
                md={6}
                sm={12}
                xs={12}>
                <Typography
                  sx={{ mb: "15px" }}
                  variant="body1">
                  {directionList[alert?.notification?.threshold_direction]}
                  {" " + alert?.user_pref_threshold ??
                    alert?.notification?.threshold}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                sm={12}
                xs={12}>
                <Typography variant="subtitle1">Alert Device Type</Typography>
              </Grid>
              <Grid
                item
                md={6}
                sm={12}
                xs={12}>
                <Typography
                  variant="body1"
                  sx={{ mb: "15px" }}
                  style={{ whiteSpace: "pre-line" }}>
                  {alert?.device_with_context?.device_type}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                sm={12}
                xs={12}>
                <Typography variant="subtitle1">Content</Typography>
              </Grid>
              <Grid
                item
                md={6}
                sm={12}
                xs={12}>
                <Typography
                  variant="body1"
                  style={{ whiteSpace: "pre-line" }}>
                  {alert.content}
                </Typography>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    </ModalComponent>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {
      maxWidth: "1024px"
    }
  };
});

export default AlertModalComponent;
