import { NotificationDefinitionReturn } from "src/utils/client";

export class CreateNotificationViewModel {
  title: string;
  id: string = null;
  building_id: string = null;
  company_id: string = null;
  alert_device_type: string = null;
  entity_type: string = "device";
  device_id: string = null;
  text_message_enabled: boolean = false;
  email_enabled: boolean = false;
  threshold_direction: string = null;
  threshold: number = null;
  alert_key: string;
  device_with_context = null;
  time_required: number;

  constructor(entity: NotificationDefinitionReturn) {
    this.id = entity?.id ?? "";
    this.title = entity?.title ?? "";
    this.building_id = entity?.building_id ?? "";
    this.company_id = entity?.company_id ?? "";
    this.entity_type = "device";
    this.alert_device_type = entity?.alert_device_type ?? "";
    this.device_id = entity?.device_id ?? "";
    this.text_message_enabled = entity?.text_message_enabled ?? false;
    this.email_enabled = entity?.email_enabled ?? false;
    this.threshold_direction = entity?.threshold_direction ?? "";
    this.threshold = entity?.user_preference_threshold ?? null;
    this.alert_key = entity?.alert_key ?? "";
    this.device_with_context = entity?.device_with_context ?? null;
    this.time_required = entity?.time_required ?? null;
  }
}
