/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AggGranularity } from '../models/AggGranularity';
import type { AggItem } from '../models/AggItem';
import type { PaintBoothData } from '../models/PaintBoothData';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PaintBoothDataService {
  /**
   * Get Paint Booth Sensor Data
   * Returns aggregated paint booth data for a sensor for the given range.
   * If `end_ts` is `None`, current time is used.
   *
   * `start_ts` and `end_ts` can be given as Unix timestamps integers
   * or floats in seconds or milliseconds, or as isoformat strings.
   * ```
   * Examples:
   * 1677827157                       (seconds)
   * 1677827157.5451648               (seconds)
   * 1677827157545                    (milliseconds)
   * 2023-03-03T07:05:57.545165+00:00 (isoformat)
   * 2023-03-03T02:05:57.545165+05:00 (isoformat)
   * ```
   * All of the above generate the same timestamp in the backend
   * (except where minor place values were omitted).
   * @param sensorId
   * @param buildingId
   * @param startTs
   * @param endTs
   * @param granularity
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getPaintBoothSensorData(
    sensorId: string,
    buildingId: string,
    startTs: string,
    endTs?: (string | null),
    granularity?: AggGranularity,
  ): CancelablePromise<(Array<AggItem> | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/paint-booth-data/{sensor_id}',
      path: {
        'sensor_id': sensorId,
        'building_id': buildingId,
      },
      query: {
        'start_ts': startTs,
        'end_ts': endTs,
        'granularity': granularity,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Raw Paint Booth Sensor Data
   * Returns raw paint_booth data for the sensor for the given range.
   * If `end_ts` is `None`, current time is used.
   *
   * `start_ts` and `end_ts` can be given as Unix timestamps integers
   * or floats in seconds or milliseconds, or as isoformat strings.
   * ```
   * Examples:
   * 1677827157                       (seconds)
   * 1677827157.5451648               (seconds)
   * 1677827157545                    (milliseconds)
   * 2023-03-03T07:05:57.545165+00:00 (isoformat)
   * 2023-03-03T02:05:57.545165+05:00 (isoformat)
   * ```
   * All of the above generate the same timestamp in the backend
   * (except where minor place values were omitted).
   * @param sensorId
   * @param buildingId
   * @param startTs
   * @param endTs
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getRawPaintBoothSensorData(
    sensorId: string,
    buildingId: string,
    startTs: string,
    endTs?: (string | null),
  ): CancelablePromise<(Array<PaintBoothData> | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/paint-booth-data/{sensor_id}/raw/',
      path: {
        'sensor_id': sensorId,
        'building_id': buildingId,
      },
      query: {
        'start_ts': startTs,
        'end_ts': endTs,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Download Raw Csv
   * Export raw paint_booth data to csv for a paint_booth sensor
   * @param sensorId
   * @param startTs
   * @param endTs
   * @returns any Successful Response
   * @throws ApiError
   */
  public static downloadRawCsv(
    sensorId: string,
    startTs: string,
    endTs?: (string | null),
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/paint-booth-data/{sensor_id}/raw/csv/',
      path: {
        'sensor_id': sensorId,
      },
      query: {
        'start_ts': startTs,
        'end_ts': endTs,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Latest Paint Booth Data Point For A Sensor
   * @param sensorId
   * @param buildingId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getLatestPaintBoothDataPointForASensor(
    sensorId: string,
    buildingId: string,
  ): CancelablePromise<(PaintBoothData | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/paint-booth-data/{sensor_id}/latest/',
      path: {
        'sensor_id': sensorId,
        'building_id': buildingId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Latest Paint Booth Sensor Data Point For Zone
   * @param buildingId
   * @param boothId
   * @param zoneId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getLatestPaintBoothSensorDataPointForZone(
    buildingId: string,
    boothId: string,
    zoneId: string,
  ): CancelablePromise<(PaintBoothData | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/paint-booth-data/paint-booths/{booth_id}/zones/{zone_id}/latest/',
      path: {
        'building_id': buildingId,
        'booth_id': boothId,
        'zone_id': zoneId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Paint Booth Data Export
   * Export hvac data to excel for a stage, and entire, or all the AHUs for a building.
   *
   * Returns pressure drop values in Pascals or iwg depending on the preference of the user
   * calling this endpoint.
   * @param buildingId
   * @param boothId
   * @param startTs
   * @param endTs
   * @returns any Successful Response
   * @throws ApiError
   */
  public static paintBoothDataExport(
    buildingId: string,
    boothId: string,
    startTs: string,
    endTs?: (string | null),
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/booth-data-export/',
      query: {
        'building_id': buildingId,
        'booth_id': boothId,
        'start_ts': startTs,
        'end_ts': endTs,
      },
      errors: {
        400: `Invalid entity id or no data available`,
        422: `Validation Error`,
      },
    });
  }
}
