/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AggGranularity } from '../models/AggGranularity';
import type { AggItem } from '../models/AggItem';
import type { HvacData } from '../models/HvacData';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class HvacDataService {
  /**
   * Get Hvac Device Data
   * Returns aggregated hvac data for a device for the given range.
   * If `end_ts` is `None`, current time is used.
   *
   * `start_ts` and `end_ts` can be given as Unix timestamps integers
   * or floats in seconds or milliseconds, or as isoformat strings.
   * ```
   * Examples:
   * 1677827157                       (seconds)
   * 1677827157.5451648               (seconds)
   * 1677827157545                    (milliseconds)
   * 2023-03-03T07:05:57.545165+00:00 (isoformat)
   * 2023-03-03T02:05:57.545165+05:00 (isoformat)
   * ```
   * All of the above generate the same timestamp in the backend
   * (except where minor place values were omitted).
   * @param deviceId
   * @param buildingId
   * @param startTs
   * @param endTs
   * @param granularity
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getHvacDeviceData(
    deviceId: string,
    buildingId: string,
    startTs: string,
    endTs?: (string | null),
    granularity?: AggGranularity,
  ): CancelablePromise<(Array<AggItem> | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/hvac-data/{device_id}',
      path: {
        'device_id': deviceId,
        'building_id': buildingId,
      },
      query: {
        'start_ts': startTs,
        'end_ts': endTs,
        'granularity': granularity,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Hvac Device Data V2
   * Returns:
   * - Aggregated hvac data for a specific installation location given a time range;
   * may return data from a device that was decommissioned as well as the device
   * that replaced it, should the selected time range encompasses both periods.
   *
   * Attrs:
   * - `end_ts`: if `end_ts` is `None`, current time is used
   * - `granularity`: optional, defaults to hour
   *
   * `start_ts` and `end_ts` can be given as Unix timestamps integers
   * or floats in seconds or milliseconds, or as isoformat strings.
   * ```
   * Examples:
   * 1677827157                       (seconds)
   * 1677827157.5451648               (seconds)
   * 1677827157545                    (milliseconds)
   * 2023-03-03T07:05:57.545165+00:00 (isoformat)
   * 2023-03-03T02:05:57.545165+05:00 (isoformat)
   * ```
   * All of the above generate the same timestamp in the backend
   * (except where minor place values were omitted).
   * @param deviceId
   * @param buildingId
   * @param startTs
   * @param endTs
   * @param granularity
   * @returns AggItem Successful Response
   * @throws ApiError
   */
  public static getHvacDeviceDataV2(
    deviceId: string,
    buildingId: string,
    startTs: string,
    endTs?: (string | null),
    granularity?: AggGranularity,
  ): CancelablePromise<Array<AggItem>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/hvac-data/{device_id}/v2/',
      path: {
        'device_id': deviceId,
        'building_id': buildingId,
      },
      query: {
        'start_ts': startTs,
        'end_ts': endTs,
        'granularity': granularity,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Raw Hvac Device Data
   * Returns raw hvac data for the device for the given range.
   * If `end_ts` is `None`, current time is used.
   *
   * `start_ts` and `end_ts` can be given as Unix timestamps integers
   * or floats in seconds or milliseconds, or as isoformat strings.
   * ```
   * Examples:
   * 1677827157                       (seconds)
   * 1677827157.5451648               (seconds)
   * 1677827157545                    (milliseconds)
   * 2023-03-03T07:05:57.545165+00:00 (isoformat)
   * 2023-03-03T02:05:57.545165+05:00 (isoformat)
   * ```
   * All of the above generate the same timestamp in the backend
   * (except where minor place values were omitted).
   * @param deviceId
   * @param buildingId
   * @param startTs
   * @param endTs
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getRawHvacDeviceData(
    deviceId: string,
    buildingId: string,
    startTs: string,
    endTs?: (string | null),
  ): CancelablePromise<(Array<HvacData> | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/hvac-data/{device_id}/raw/',
      path: {
        'device_id': deviceId,
        'building_id': buildingId,
      },
      query: {
        'start_ts': startTs,
        'end_ts': endTs,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Raw Hvac Device Data V2
   * Returns:
   * - Raw hvac data for a specific installation location given a time range;
   * may return data from a device that was decommissioned as well as the device
   * that replaced it, should the selected time range encompasses both periods.
   *
   * Attrs:
   * - `end_ts`: if `end_ts` is `None`, current time is used
   *
   * `start_ts` and `end_ts` can be given as Unix timestamps integers
   * or floats in seconds or milliseconds, or as isoformat strings.
   * ```
   * Examples:
   * 1677827157                       (seconds)
   * 1677827157.5451648               (seconds)
   * 1677827157545                    (milliseconds)
   * 2023-03-03T07:05:57.545165+00:00 (isoformat)
   * 2023-03-03T02:05:57.545165+05:00 (isoformat)
   * ```
   * All of the above generate the same timestamp in the backend
   * (except where minor place values were omitted).
   * @param deviceId
   * @param buildingId
   * @param startTs
   * @param endTs
   * @returns HvacData Successful Response
   * @throws ApiError
   */
  public static getRawHvacDeviceDataV2(
    deviceId: string,
    buildingId: string,
    startTs: string,
    endTs?: (string | null),
  ): CancelablePromise<Array<HvacData>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/hvac-data/{device_id}/raw/v2/',
      path: {
        'device_id': deviceId,
        'building_id': buildingId,
      },
      query: {
        'start_ts': startTs,
        'end_ts': endTs,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Download Raw Csv
   * Export raw hvac data to csv for a hvac sensor
   * @param deviceId
   * @param startTs
   * @param endTs
   * @returns any Successful Response
   * @throws ApiError
   */
  public static downloadRawCsv(
    deviceId: string,
    startTs: string,
    endTs?: (string | null),
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/hvac-data/{device_id}/raw/csv/',
      path: {
        'device_id': deviceId,
      },
      query: {
        'start_ts': startTs,
        'end_ts': endTs,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Download Raw Csv V2
   * Same as download_raw_csv() except that it may return data from the device
   * and the device that was decommissioned/replaced in the same location,
   * should the time range cover it.
   *
   * Export raw hvac data to csv for a hvac sensor
   * @param deviceId
   * @param startTs
   * @param endTs
   * @returns any Successful Response
   * @throws ApiError
   */
  public static downloadRawCsvV2(
    deviceId: string,
    startTs: string,
    endTs?: (string | null),
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/hvac-data/{device_id}/raw/csv/v2/',
      path: {
        'device_id': deviceId,
      },
      query: {
        'start_ts': startTs,
        'end_ts': endTs,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Latest Hvac Device Data Point For A Sensor
   * @param deviceId
   * @param buildingId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getLatestHvacDeviceDataPointForASensor(
    deviceId: string,
    buildingId: string,
  ): CancelablePromise<(HvacData | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/hvac-data/{device_id}/latest/',
      path: {
        'device_id': deviceId,
        'building_id': buildingId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Latest Hvac Device Data Point For Stage
   * @param buildingId
   * @param ahuId
   * @param stageId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getLatestHvacDeviceDataPointForStage(
    buildingId: string,
    ahuId: string,
    stageId: string,
  ): CancelablePromise<(HvacData | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/hvac-data/ahus/{ahu_id}/stage/{stage_id}/latest/',
      path: {
        'building_id': buildingId,
        'ahu_id': ahuId,
        'stage_id': stageId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Ahu Stage Data
   * Returns pressure drop data for given time range for all stages of the AHU.
   * `start_ts` and `end_ts` can be given as Unix timestamps integers
   * or floats in seconds or milliseconds, or as isoformat strings.
   * ```
   * Examples:
   * 1677827157                       (seconds)
   * 1677827157.5451648               (seconds)
   * 1677827157545                    (milliseconds)
   * 2023-03-03T07:05:57.545165+00:00 (isoformat)
   * 2023-03-03T02:05:57.545165+05:00 (isoformat)
   * ```
   * All of the above generate the same timestamp in the backend
   * (except where minor place values were omitted).
   * @param buildingId
   * @param ahuId
   * @param startTs
   * @param endTs
   * @param granularity
   * @returns any Successful Response
   * @throws ApiError
   */
  public static ahuStageData(
    buildingId: string,
    ahuId: string,
    startTs: string,
    endTs?: (string | null),
    granularity?: AggGranularity,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/hvac-data/ahus/{ahu_id}/stage-data/',
      path: {
        'building_id': buildingId,
        'ahu_id': ahuId,
      },
      query: {
        'start_ts': startTs,
        'end_ts': endTs,
        'granularity': granularity,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
