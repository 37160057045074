import TermsModalComponent from "src/shared/components/terms-modal/terms-modal.component";
import { useAppSelector } from "src/hooks/useAppSelector";
import { getUserId } from "src/store/auth/selectors";
import { useGetUserSettings } from "src/sections/settings/hooks";
import useGetPolutantDictionary from "src/hooks/useGetPollutantDictionary";
import useGetHvacStatusInfoDictionary from "src/hooks/useGetHvacStatusInfoDictionary";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { logout } from "src/store/auth/actions";
import { useGetUserTermsQuery } from "src/store/services/general/general.service";
import Main from "../components/main.component";
import useGetBuildings from "src/hooks/useGetBuildings";
import { DownloadDataModalComponent } from "src/shared/components/download-data-modal/view/download-data-modal.component";

const Dashboard = ({ children }) => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector(getUserId);

  const { isError } = useGetUserSettings();
  const { isError: isErrorBuilding } = useGetBuildings();
  const { isError: isHvacError } = useGetPolutantDictionary();
  const { isError: isHvacStatusInfoError } = useGetHvacStatusInfoDictionary();
  const { isLoading: isLoadingTerms } = useGetUserTermsQuery(userId);

  if (isError || isHvacError || isHvacStatusInfoError || isErrorBuilding) {
    dispatch(logout());
  }

  return (
    <>
      <Main childern={children} />
      {!isLoadingTerms && <TermsModalComponent />}
      <DownloadDataModalComponent />
    </>
  );
};

export default Dashboard;
