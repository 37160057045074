import { useTheme } from "@mui/material";
import useGetPolutantDictionary from "./useGetPollutantDictionary";
const WHITE_COLOR = "#FFF";
type RGB = `rgb(${number}, ${number}, ${number})`;

export default function usePaleteColor(paleteType: string, status: string) {
  const theme = useTheme();
  const { pollInfoDict } = useGetPolutantDictionary();

  const statusColor: RGB = pollInfoDict
    ? theme.palette[paleteType][status]
    : WHITE_COLOR;

  const gradientColors: RGB[] = pollInfoDict
    ? pollInfoDict?.aqi?.statuses?.thresholds.map(
        status => theme.palette[paleteType][status.status]
      )
    : [];

  return { statusColor, gradientColors };
}
