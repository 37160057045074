import { Box, Stack, alpha } from "@mui/material";

const GREEN = "rgb(76, 175, 80)";

// TODO: This component will be a bar displaying the percentage of filter load once the correct value is returned from backend.
const FilterLoading = () => {
  return (
    <Stack
      justifyContent={"center"}
      py={"6px"}>
      <Box
        height={"0.4rem"}
        bgcolor={alpha("#000000", 0.25)}
        borderRadius={"1rem"}>
        <Box
          height={"0.4rem"}
          bgcolor={GREEN}
          borderRadius={"1rem"}
          width={0}
        />
      </Box>
    </Stack>
  );
};

export default FilterLoading;
