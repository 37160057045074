import { useState, useMemo, ChangeEvent } from "react";
import { useDispatch } from "react-redux";
import { setUserClickedOnWidgetTitle } from "src/store/hvac/reducer";
import { setSelectedOverviewTab } from "src/store/overview/reducer";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import {
  Typography,
  Switch,
  TableContainer,
  Table,
  TableBody
} from "@mui/material";
import { TimeRangeObject } from "../aq-building-with-time-settings/aq-building-with-time-settings.component";
import {
  defaultTwoWeeksRange,
  getStartAndEndDate
} from "../../utils/time-span-utils";
import { FormattedBuildingWithEntity } from "src/shared/types/building";
import { Pollutant } from "../../utils/aq-widget-enums";
import { BuildingOverviewRow } from "src/utils/client";
import useOverview from "src/hooks/useOverview";
import { useOverviewTab } from "../../hooks/useGetOverviewTab";
import useGetBuildingOverviewAQData from "../../hooks/useGetBuildingOverviewAQData";
import LoaderComponent from "src/shared/components/loader/loader.component";
import WidgetTitle from "src/sections/overview/components/WidgetTitle/WidgetTitle";
import Error from "src/shared/components/Error/Error";
import SortingTableHead from "./sorting-table-head.component";
import PollutantRow from "./pollutant-row.component";
import {
  SortOrder,
  sortRows
} from "../../utils/aq-building-overview-widget-utils";

type Props = {
  building: FormattedBuildingWithEntity;
  requiresConfig?: boolean;
  range?: TimeRangeObject;
};

const AqBuildingOverviewWidgetComponent = ({
  building,
  requiresConfig,
  range
}: Props) => {
  const { classes } = useStyles();
  const widgetsRange = range ?? defaultTwoWeeksRange;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { editing } = useOverview();
  const { tabId } = useOverviewTab();
  const [showStatus, setShowStatus] = useState<boolean>(false);
  const [showOffline, setShowOffline] = useState<boolean>(false);
  const [order, setOrder] = useState<SortOrder>("desc");
  const [selectedPollutant, setSelectedPollutant] = useState<Pollutant>(
    Pollutant.aqiTitle
  );
  const { buildingOverviewAqData, isLoading, isError, refetch } =
    useGetBuildingOverviewAQData(building?.id, widgetsRange, requiresConfig);

  const overviewAqDataExist = Boolean(buildingOverviewAqData);

  const onlineDevices = useMemo(() => {
    return (
      buildingOverviewAqData?.filter(
        device => Object.values(device.indoor).length > 0
      ) ?? []
    );
  }, [buildingOverviewAqData]);

  const devices = useMemo(() => {
    const data = showOffline ? buildingOverviewAqData : onlineDevices;
    return sortRows(data, order, selectedPollutant);
  }, [
    showOffline,
    buildingOverviewAqData,
    onlineDevices,
    order,
    selectedPollutant
  ]);

  const { start, end } = useMemo(() => {
    return getStartAndEndDate(
      widgetsRange?.method,
      widgetsRange?.startDate,
      widgetsRange?.endDate,
      widgetsRange?.timeSpan,
      widgetsRange?.timeQuantity
    );
  }, [widgetsRange]);

  const handleShowStatusToggle = (event: ChangeEvent<HTMLInputElement>) => {
    setShowStatus(event.target.checked);
  };

  const handleShowOfflineToggle = (event: ChangeEvent<HTMLInputElement>) => {
    setShowOffline(event.target.checked);
  };

  const handleOrderPollutant = (pollutant: Pollutant) => {
    const selectedIsDescending =
      selectedPollutant === pollutant && order === "desc";
    setOrder(selectedIsDescending ? "asc" : "desc");
    setSelectedPollutant(pollutant);
  };

  const handleTitleClick = () => {
    if (editing) return;
    dispatch(setUserClickedOnWidgetTitle(true));
    dispatch(setSelectedOverviewTab(tabId));
    navigate(`/buildings/iaq/${building?.id}`);
  };

  return (
    <div
      className={classes.root}
      data-testid="building-aq-overview">
      <div className={classes.header}>
        <Typography
          data-testid="title"
          variant="h6"
          className={editing ? classes.default : classes.pointer}
          onClick={handleTitleClick}>
          Building Air Quality Overview
        </Typography>
      </div>
      <div className={classes.toggles}>
        <Typography>Show Satuses?</Typography>
        <Switch
          size="small"
          checked={showStatus}
          onChange={handleShowStatusToggle}
        />
        <Typography>Include Offline Devices?</Typography>
        <Switch
          size="small"
          checked={showOffline}
          onChange={handleShowOfflineToggle}
        />
      </div>

      {requiresConfig ? (
        <Typography>Configuration is required</Typography>
      ) : isLoading ? (
        <LoaderComponent />
      ) : (
        <>
          {!isError && (
            <WidgetTitle
              error={isError}
              building={building}
            />
          )}
          <div className={classes.widgetBody}>
            {isError ? (
              <Error
                setRefetchAgain={refetch}
                error={isError}
              />
            ) : (
              <>
                <TableContainer>
                  <Table>
                    <SortingTableHead
                      order={order}
                      selectedPollutant={selectedPollutant}
                      handleOrderPollutant={handleOrderPollutant}
                    />
                    <TableBody>
                      {overviewAqDataExist &&
                        devices?.map(
                          (device: BuildingOverviewRow, index: number) => {
                            return (
                              <PollutantRow
                                key={`${device.device_name}_${index}`}
                                device={device}
                                index={index}
                                showStatus={showStatus}
                              />
                            );
                          }
                        )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Typography
                  variant="subtitle1"
                  className={classes.timeFrame}>
                  {start} - {end}
                </Typography>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      padding: "10px"
    },
    header: {
      display: "flex"
    },
    switch: {
      marginLeft: "0.25rem"
    },
    toggles: {
      display: "flex",
      alignItems: "center",
      gap: "8px"
    },
    default: {
      cursor: "default"
    },
    pointer: {
      cursor: "pointer",
      "&:hover": { color: "#0795bb" }
    },
    widgetBody: {
      height: "100%",
      overflow: "auto",
      marginBottom: "1rem"
    },
    headerRow: {
      borderBottom: "2px solid grey"
    },
    title: {
      font: "bold",
      color: "grey"
    },
    timeFrame: {
      position: "absolute",
      bottom: 0,
      right: 16,
      fontSize: "14px !important"
    }
  };
});

export default AqBuildingOverviewWidgetComponent;
