/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Kettle } from '../models/Kettle';
import type { KettleNew } from '../models/KettleNew';
import type { KettleSensorAndLatest } from '../models/KettleSensorAndLatest';
import type { KettleUpdates } from '../models/KettleUpdates';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class KettleService {
  /**
   * Get Kettles For Building
   * Returns possibly empty list of kettles for the building, or `null` for invalid building_id.
   * @param buildingId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getKettlesForBuilding(
    buildingId: string,
  ): CancelablePromise<(Array<Kettle> | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/kettles/',
      path: {
        'building_id': buildingId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Create Kettle
   * @param buildingId
   * @param requestBody
   * @returns Kettle Successful Response
   * @throws ApiError
   */
  public static createKettle(
    buildingId: string,
    requestBody: KettleNew,
  ): CancelablePromise<Kettle> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/buildings/{building_id}/kettles/',
      path: {
        'building_id': buildingId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Database Error`,
        409: `Name Conflict`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Kettle
   * @param buildingId
   * @param kettleId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getKettle(
    buildingId: string,
    kettleId: string,
  ): CancelablePromise<(Kettle | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/kettles/{kettle_id}',
      path: {
        'building_id': buildingId,
        'kettle_id': kettleId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Update Kettle
   * @param buildingId
   * @param kettleId
   * @param requestBody
   * @returns Kettle Successful Response
   * @throws ApiError
   */
  public static updateKettle(
    buildingId: string,
    kettleId: string,
    requestBody: KettleUpdates,
  ): CancelablePromise<Kettle> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/buildings/{building_id}/kettles/{kettle_id}',
      path: {
        'building_id': buildingId,
        'kettle_id': kettleId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Database Error`,
        404: `Invalid Object Id`,
        409: `Name Conflict`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Delete Kettle
   * @param buildingId
   * @param kettleId
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public static deleteKettle(
    buildingId: string,
    kettleId: string,
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/buildings/{building_id}/kettles/{kettle_id}',
      path: {
        'building_id': buildingId,
        'kettle_id': kettleId,
      },
      errors: {
        400: `Bad Request`,
        404: `Invalid Object Id`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Sensors For Kettle
   * Returns possibly empty list of sensors for the kettle, or `null` for invalid building/kettle id.
   * @param buildingId
   * @param kettleId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getSensorsForKettle(
    buildingId: string,
    kettleId: string,
  ): CancelablePromise<(Array<KettleSensorAndLatest> | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/kettles/{kettle_id}/kettle-sensors/',
      path: {
        'building_id': buildingId,
        'kettle_id': kettleId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
