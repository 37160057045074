import { Grid, Typography } from "@mui/material";

const BoothTableHeader = () => {
  return (
    <Grid
      container
      sx={{ color: "#919293" }}
      data-testid="booth-header">
      <Grid container>
        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          xs={6}>
          <Typography>Booth Name</Typography>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={6}>
          <Typography>Zones</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BoothTableHeader;
