import { Alert, Button } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import useErrorTimer from "src/hooks/useErrorTimer";

interface IError {
  error: any;
  showGenericMessage?: boolean;
  setRefetchAgain: Dispatch<SetStateAction<boolean>>;
}

const Error = ({
  error,
  setRefetchAgain,
  showGenericMessage = false
}: IError) => {
  const { retryTimer } = useErrorTimer(error);
  return (
    <Alert
      data-testid="alert"
      severity="error"
      action={
        <Button
          data-testid="reload-btn"
          color="inherit"
          size="small"
          onClick={() => {
            setRefetchAgain(true);
          }}>
          Reload
        </Button>
      }>
      {showGenericMessage
        ? "Error while loading — please try reloading"
        : `Error while loading — please try reloading, autorefreshing in ${retryTimer}
      s`}
    </Alert>
  );
};

export default Error;
