/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NotificationSubscription } from '../models/NotificationSubscription';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class NotificationSubscriptionsService {
  /**
   * Get All Notification Subscriptions For Building
   * @param buildingId
   * @returns NotificationSubscription Successful Response
   * @throws ApiError
   */
  public static getAllNotificationSubscriptionsForBuilding(
    buildingId: string,
  ): CancelablePromise<Array<NotificationSubscription>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/notification-subscriptions/',
      path: {
        'building_id': buildingId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Subscribe User To Building Notifications
   * @param buildingId
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public static subscribeUserToBuildingNotifications(
    buildingId: string,
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/buildings/{building_id}/notification-subscriptions/',
      path: {
        'building_id': buildingId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Unsubscribe User From Building Notifications
   * @param buildingId
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public static unsubscribeUserFromBuildingNotifications(
    buildingId: string,
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/buildings/{building_id}/notification-subscriptions/',
      path: {
        'building_id': buildingId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get All Notification Subscriptions For Notification
   * @param notificationId
   * @param buildingId
   * @returns NotificationSubscription Successful Response
   * @throws ApiError
   */
  public static getAllNotificationSubscriptionsForNotification(
    notificationId: string,
    buildingId: string,
  ): CancelablePromise<Array<NotificationSubscription>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/notifications/{notification_id}/subscriptions/',
      path: {
        'notification_id': notificationId,
        'building_id': buildingId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Subscribe User To Notification
   * Subscribes the user with `user_id` to the `notification_id`
   * @param buildingId
   * @param notificationId
   * @param userId
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public static subscribeUserToNotification(
    buildingId: string,
    notificationId: string,
    userId: string,
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/buildings/{building_id}/notifications/{notification_id}/subscriptions/',
      path: {
        'building_id': buildingId,
        'notification_id': notificationId,
      },
      query: {
        'user_id': userId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Unsubscribe User From Notification
   * @param buildingId
   * @param notificationId
   * @param userId
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public static unsubscribeUserFromNotification(
    buildingId: string,
    notificationId: string,
    userId: string,
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/buildings/{building_id}/notifications/{notification_id}/subscriptions/',
      path: {
        'building_id': buildingId,
        'notification_id': notificationId,
      },
      query: {
        'user_id': userId,
      },
      errors: {
        400: `Bad subscription`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get All Notification Subscriptions For User
   * @param userId
   * @returns NotificationSubscription Successful Response
   * @throws ApiError
   */
  public static getAllNotificationSubscriptionsForUser(
    userId: string,
  ): CancelablePromise<Array<NotificationSubscription>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/{user_id}/notification-subscriptions/',
      path: {
        'user_id': userId,
      },
      errors: {
        400: `Bad subscription`,
        422: `Validation Error`,
      },
    });
  }
}
