import { apiService } from "../api";
import {
  AlertAcknowledgementNew,
  AlertReturn,
  AlertUpdates,
  AlertsService,
  NotificationDefinitionNew,
  NotificationDefinitionUpdates,
  NotificationDevicesResp,
  NotificationSubscription,
  NotificationSubscriptionsService,
  NotificationsService,
  UpdateManyAlertsRequest,
  NotificationDeviceParentsResp
} from "src/utils/client";
import { NotificationDefinitionReturn } from "src/utils/client";
import moment from "moment";

export const notificationsService = apiService
  .enhanceEndpoints({
    addTagTypes: [
      "NOTIFICATIONS",
      "SUBSCRIPTIONS",
      "TYPES",
      "DEVICES",
      "DEVICEPARENTS",
      "ALERTS",
      "ALERT"
    ]
  })
  .injectEndpoints({
    endpoints: build => ({
      getNotifications: build.query<NotificationDefinitionReturn[], void>({
        queryFn: async () => {
          const notifications =
            await NotificationsService.getAllPossibleUserNotificationDefinitions();
          return { data: notifications };
        },
        providesTags: ["NOTIFICATIONS"]
      }),
      getSubscriptions: build.query<NotificationSubscription[], string>({
        queryFn: async userId => {
          const subscriptions =
            await NotificationSubscriptionsService.getAllNotificationSubscriptionsForUser(
              userId
            );
          return { data: subscriptions };
        },
        providesTags: ["SUBSCRIPTIONS"]
      }),
      getTypes: build.query<any, void>({
        queryFn: async () => {
          const types = await NotificationsService.notificationTypes();
          return { data: types?.alert_types };
        },
        providesTags: ["TYPES"]
      }),
      getDeviceParents: build.query<NotificationDeviceParentsResp, void>({
        queryFn: async () => {
          const device_parents =
            await NotificationsService.notificationDevicesParents();
          return { data: device_parents };
        },
        providesTags: ["DEVICEPARENTS"]
      }),
      getDevices: build.query<NotificationDevicesResp, void>({
        queryFn: async () => {
          const devices = await NotificationsService.notificationDevices();
          return { data: devices };
        },
        providesTags: ["DEVICES"]
      }),
      getAlerts: build.query<AlertReturn[], { notificationId: string }>({
        queryFn: async data => {
          const { notificationId } = data;
          const startDate = moment().subtract(30, "days").startOf("day");
          const endDate = moment().endOf("day");
          var alerts = [];
          if (notificationId) {
            alerts = await NotificationsService.getAlertsForNotification(
              notificationId,
              startDate.unix().toString(),
              endDate.unix().toString()
            );
          } else {
            alerts = await AlertsService.getAlertsForUser(
              startDate.unix().toString(),
              endDate.unix().toString()
            );
          }

          return { data: alerts };
        },
        providesTags: (_result, _error, { notificationId }) => [
          { type: "ALERTS", notificationId }
        ]
      }),
      getAlert: build.query<
        AlertReturn,
        {
          notificationId: string;
          alertId: string;
        }
      >({
        queryFn: async data => {
          const { notificationId, alertId } = data;
          const alert = await AlertsService.getAlert(notificationId, alertId);

          return { data: alert };
        },
        providesTags: ["ALERT"]
      }),
      subscribeToNotification: build.mutation<
        boolean,
        { userId: string; buildingId: string; notificationId: string }
      >({
        // @ts-ignore
        queryFn: async data => {
          const { buildingId, userId, notificationId } = data;

          return await NotificationSubscriptionsService.subscribeUserToNotification(
            buildingId,
            notificationId,
            userId
          );
        },
        invalidatesTags: ["SUBSCRIPTIONS", "NOTIFICATIONS"]
      }),
      unSubscribeFromNotification: build.mutation<
        boolean,
        { userId: string; buildingId: string; notificationId: string }
      >({
        // @ts-ignore
        queryFn: async data => {
          const { buildingId, userId, notificationId } = data;

          return await NotificationSubscriptionsService.unsubscribeUserFromNotification(
            buildingId,
            notificationId,
            userId
          );
        },
        invalidatesTags: ["SUBSCRIPTIONS", "NOTIFICATIONS"]
      }),
      deleteNotification: build.mutation<
        boolean,
        { buildingId: string; notificationId: string }
      >({
        // @ts-ignore
        queryFn: async data => {
          const { buildingId, notificationId } = data;

          return await NotificationsService.deleteNotification(
            notificationId,
            buildingId
          );
        },
        invalidatesTags: ["NOTIFICATIONS"]
      }),
      toggleNotification: build.mutation<
        boolean,
        { buildingId: string; notificationId: string; activate: boolean }
      >({
        // @ts-ignore
        queryFn: async data => {
          const { buildingId, notificationId, activate } = data;

          return await NotificationsService.toggleEnabled(
            buildingId,
            activate,
            {
              ids: [notificationId]
            }
          );
        },
        invalidatesTags: ["NOTIFICATIONS"]
      }),
      createNotification: build.mutation<
        boolean,
        { buildingId: string; payload: NotificationDefinitionNew }
      >({
        // @ts-ignore
        queryFn: async data => {
          const { buildingId, payload } = data;

          return await NotificationsService.createNotification(
            buildingId,
            payload
          );
        },
        invalidatesTags: ["NOTIFICATIONS"]
      }),
      updateNotification: build.mutation<
        boolean,
        {
          buildingId: string;
          id: string;
          payload: NotificationDefinitionUpdates;
        }
      >({
        // @ts-ignore
        queryFn: async data => {
          const { buildingId, id, payload } = data;

          return await NotificationsService.updateNotification(
            id,
            buildingId,
            payload
          );
        },
        invalidatesTags: ["NOTIFICATIONS"]
      }),
      markAsRead: build.mutation<
        boolean,
        { alerts: UpdateManyAlertsRequest[]; notificationId: string }
      >({
        // @ts-ignore
        queryFn: async data => {
          const { alerts } = data;

          return await AlertsService.updateManyAlerts(alerts);
        },
        invalidatesTags: (_, __, args) => [
          {
            type: "ALERTS",
            notificationId: args.notificationId
          }
        ]
      }),
      updateAlert: build.mutation<
        AlertReturn,
        { notificationId: string; alertId: string; alert: AlertUpdates }
      >({
        // @ts-ignore
        queryFn: async data => {
          const { alert, notificationId, alertId } = data;

          return await AlertsService.updateAlert(
            notificationId,
            alertId,
            alert
          );
        },
        invalidatesTags: (_, __, args) => [
          { type: "ALERTS", notificationId: args.notificationId }
        ]
      }),
      acknowledgeAlert: build.mutation<
        AlertReturn,
        {
          notificationId: string;
          alertId: string;
          body: AlertAcknowledgementNew;
        }
      >({
        // @ts-ignore
        queryFn: async data => {
          const { notificationId, alertId, body } = data;

          return await AlertsService.acknowledgeAlert(
            notificationId,
            alertId,
            body
          );
        },
        invalidatesTags: (_, __, args) => [
          { type: "ALERTS", notificationId: args.notificationId }
        ]
      })
    })
  });

export const {
  useGetNotificationsQuery,
  useGetSubscriptionsQuery,
  useGetDevicesQuery,
  useGetDeviceParentsQuery,
  useGetTypesQuery,
  useGetAlertsQuery,
  useGetAlertQuery,
  useSubscribeToNotificationMutation,
  useUnSubscribeFromNotificationMutation,
  useDeleteNotificationMutation,
  useToggleNotificationMutation,
  useCreateNotificationMutation,
  useUpdateNotificationMutation,
  useMarkAsReadMutation,
  useUpdateAlertMutation,
  useAcknowledgeAlertMutation
} = notificationsService;
