import { useStyles } from "./loader.style";
import qlairLoader from "src/assets/qlair-load.gif";

const LoaderComponent = ({ customWidth }: { customWidth?: number }) => {
  const { classes } = useStyles();
  return (
    <div
      data-testid="loader"
      className={classes.root}>
      <img
        width={customWidth}
        src={qlairLoader}
        alt="Logo"
      />
    </div>
  );
};

export default LoaderComponent;
