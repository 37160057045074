import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Stack,
  Typography
} from "@mui/material";
import moment from "moment";
import useGetPolutantDictionary from "../../../../../hooks/useGetPollutantDictionary";
import { useGetUserSettings } from "../../../../settings/hooks";
import DevicePollutantComponent from "../../device-pollutant/device-pollutant.component";

interface DeviceDetailsSideMenuProps {
  details: any;
}

export default function DeviceDetailsSideMenu({
  details
}: DeviceDetailsSideMenuProps) {
  const { temperature } = useGetUserSettings();
  const { pollInfoDict } = useGetPolutantDictionary();

  const handleRenderPolutant = (metric: any, index: number) => {
    let indoor = details?.latest_measurement?.data[metric.key]?.value;
    let outdoor = details?.latest_outdoor_data?.data[metric.key]?.value;

    if (metric.key === "t" && temperature?.toLowerCase() === "fahrenheit") {
      indoor = indoor * (9 / 5) + 32;
      outdoor = outdoor * (9 / 5) + 32;
    }

    return (
      <DevicePollutantComponent
        key={`pollutant_${index}`}
        metric={metric}
        indoorValue={indoor}
        outdoorValue={outdoor}
      />
    );
  };

  const handleRenderRow = (name: string, value: string) => (
    <Stack
      data-testid="data-row"
      mb={0.5}
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}>
      <Typography variant="body2">{name}</Typography>
      <Typography>{value || "-"}</Typography>
    </Stack>
  );

  const formatDate = (val: any) =>
    val ? moment(val).format("yyyy-MM-DD") : null;

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={4}
      lg={3}>
      <Accordion
        sx={{ marginBottom: 1, borderRadius: "8px" }}
        defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="button">information about device</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {details && (
            <Stack>
              {handleRenderRow("Device Name", details?.name)}
              {handleRenderRow("Serial Number", details?.serial_number)}
              {handleRenderRow("Model type", details?.device_type)}
              {handleRenderRow(
                "Installation date",
                formatDate(details?.created_at)
              )}
              {handleRenderRow(
                "Last connection time",
                formatDate(details?.latest_measurement?.time)
              )}
            </Stack>
          )}
        </AccordionDetails>
      </Accordion>
      <Stack
        overflow={"auto"}
        direction={{ md: "column", sm: "row", xs: "row" }}>
        {Boolean(pollInfoDict) &&
          Object.entries(pollInfoDict)
            .map(([key, value]) => ({ ...value, key }))
            .filter(item => {
              return details?.latest_measurement?.data
                ? item.key in details?.latest_measurement?.data
                : false;
            })
            .map(handleRenderPolutant)}
      </Stack>
    </Grid>
  );
}
