import { useState } from "react";
import { makeStyles } from "tss-react/mui";
import { WidgetSettings } from "../widgets-config";
import Error from "src/shared/components/Error/Error";
import { CircularProgress } from "@mui/material";
import useGetBuildings from "src/hooks/useGetBuildings";
import FormInput from "src/shared/components/form-input/form-input.component";
import FormAction from "src/shared/components/form-action/form-action";

const AqBuildingSettingsComponent = ({
  onSave,
  currentProps
}: WidgetSettings) => {
  const { classes } = useStyles();
  const [buildingId, setBuildingId] = useState(currentProps?.buildingId ?? "");

  const { buildings, isLoading, isFetching, isError, refetch } =
    useGetBuildings();

  const aqBuildings = buildings?.filter(building => building.indoorData);

  const handleOnSave = () => {
    onSave({
      ...currentProps,
      buildingId,
      building: buildings.find(({ id }) => id === buildingId)
    });
  };
  return (
    <div
      data-testid="aq-building-settings"
      className={classes.root}>
      {isLoading || isFetching ? (
        <div
          className={classes.loader}
          data-testid="aq-building-settings-loader">
          <CircularProgress />
        </div>
      ) : (
        <>
          {isError ? (
            <div data-testid="aq-building-settings-error">
              <Error
                error={isError}
                setRefetchAgain={refetch}
                showGenericMessage
              />
            </div>
          ) : (
            <FormInput
              data-testid="aq-building-settings-form"
              label="Building"
              inputValue={buildingId}
              setValue={setBuildingId}
              array={aqBuildings}></FormInput>
          )}
        </>
      )}
      <FormAction
        requiresConfig={currentProps.requiresConfig}
        saveDisabled={!buildingId}
        onSave={handleOnSave}></FormAction>
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    loader: {
      display: "flex",
      justifyContent: "center"
    }
  };
});

export default AqBuildingSettingsComponent;
