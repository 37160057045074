import { BuildingPollutant } from "../types/aq-widget-types";
import { Pollutant } from "./aq-widget-enums";
import { getStatusColor } from "./aq-widget-utils";

export const formatPollutantTitle = (metric: string) => {
  switch (metric) {
    case Pollutant.aqi:
      return Pollutant.aqiLong;
    case Pollutant.cotwo:
      return Pollutant.cotwoLong;
    case Pollutant.voc:
      return Pollutant.vocLong;
    case Pollutant.pmtwo:
      return Pollutant.pmtwoLong;
    case Pollutant.pmten:
      return Pollutant.pmtenLong;
  }
};

export const getPollutantUnit = (metric: string) => {
  switch (metric) {
    case Pollutant.aqi:
      return Pollutant.aqiUnit;
    case Pollutant.cotwo:
      return Pollutant.cotwoUnit;
    case Pollutant.voc:
      return Pollutant.vocUnit;
    case Pollutant.pmtwo:
      return Pollutant.pmUnit;
    case Pollutant.pmten:
      return Pollutant.pmUnit;
  }
};

// Function to create the correct data shape for BuildingAqWidgetComponent.
// Example Object = {title: "Air Quality Index", value: "68.93 AQI", status: "Moderate", color: "rgb(224, 200, 58)"}
export const formatBuildingPollutants = (
  pollutants: [string, number][],
  statuses: string[]
): BuildingPollutant[] => {
  if (pollutants && statuses) {
    const arr = [];

    pollutants.map((pollutant, index) => {
      const title = pollutant ? formatPollutantTitle(pollutant[0]) : "--";
      const value = pollutant
        ? `${pollutant[1]?.toFixed(2)} ${getPollutantUnit(pollutant[0])}`
        : "--";
      const status = statuses[index] ?? "Unavailable";
      const color = getStatusColor(status);

      const obj = { title, value, status, color };
      arr.push(obj);
    });

    return arr;
  }
};
