import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { Kettle, AggGranularity } from "src/utils/client";

type InitialState = {
  selectedKettle: Kettle | null;
  kettleStartDate: Date;
  kettleEndDate: Date;
  kettleSearchTerm: string;
  selectedOutlet: string;
  selectedKettleType: "outlet" | "inlet" | null;
  granularity: AggGranularity;
};

const initialState: InitialState = {
  selectedKettle: null,
  kettleStartDate: moment().subtract(48, "hours").startOf("day").toDate(),
  kettleEndDate: moment().endOf("day").toDate(),
  kettleSearchTerm: "",
  selectedOutlet: "",
  selectedKettleType: null,
  granularity: AggGranularity.HOUR
};

export const kettleSlice = createSlice({
  name: "kettle",
  initialState,
  reducers: {
    setSelectedKettle: (state, action: PayloadAction<Kettle | null>) => {
      state.selectedKettle = action.payload;
    },
    setKettleStartDate: (state, action: PayloadAction<Date>) => {
      state.kettleStartDate = action.payload;
    },
    setKettleEndDate: (state, action: PayloadAction<Date>) => {
      state.kettleEndDate = action.payload;
    },
    setKettleSearchTerm: (state, action: PayloadAction<string>) => {
      state.kettleSearchTerm = action.payload;
    },
    setSelectedOutlet: (state, action: PayloadAction<string>) => {
      state.selectedOutlet = action.payload;
    },
    setSelectedKettleType: (
      state,
      action: PayloadAction<"outlet" | "inlet" | null>
    ) => {
      state.selectedKettleType = action.payload;
    },
    setKettleChartGranularity: (
      state,
      action: PayloadAction<AggGranularity>
    ) => {
      state.granularity = action.payload;
    }
  }
});

export const {
  setSelectedKettle,
  setKettleStartDate,
  setKettleEndDate,
  setKettleSearchTerm,
  setSelectedOutlet,
  setSelectedKettleType,
  setKettleChartGranularity
} = kettleSlice.actions;

export const { reducer: kettleReducer } = kettleSlice;
