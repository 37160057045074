import { Button, Link, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { handleBuildingModal } from "src/store/buildings/reducer";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import useGetBuildings from "src/hooks/useGetBuildings";

const BuildingsEmptyComponent = () => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const { isSearchTermActive, searchTerm } = useGetBuildings();

  const openBuildingModal = () => {
    dispatch(handleBuildingModal(true));
  };

  return (
    <div
      className={classes.root}
      data-testid="buildings-empty">
      {isSearchTermActive ? (
        <Typography
          variant="h6"
          gutterBottom>
          You don’t have any building matching the term: {searchTerm}
        </Typography>
      ) : (
        <>
          <Typography
            variant="h4"
            gutterBottom>
            You don't have any buildings
          </Typography>
          <Typography
            variant="body1"
            className={classes.paragraph}
            gutterBottom>
            You do not have access to any buildings. Request access from your
            company admin or get started by making your own building
          </Typography>
          <Button
            data-testid="add-btn"
            variant="contained"
            className={classes.btn}
            onClick={openBuildingModal}>
            Add building
          </Button>
          <Typography
            variant="body1"
            gutterBottom>
            or
          </Typography>
          <Link href="mailto:support@i-qlair.com">Request support</Link>
        </>
      )}
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {
      textAlign: "center" as const
    },
    btn: {
      marginTop: "1em",
      marginBottom: "0.5em",
      width: "100%"
    },
    paragraph: {
      textAlign: "justify" as const
    }
  };
});

export default BuildingsEmptyComponent;
