import {
  Container,
  Grid,
  Paper,
  Stack,
  Theme,
  Typography,
  useTheme
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import useGetKettleAndSenorsData from "src/hooks/useGetKettleAndSensorsData";
import { setSelectedKettle } from "src/store/kettle/reducer";
import useGetHvacView from "../hvac/air-handling-units/hooks/useGetHvacView";
import KettleInfo from "./kattle.row.info";
import { Kettle } from "src/utils/client";

const KettleRowAndCardInfo = ({ kettle }: { kettle: Kettle }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isChartViewActive } = useGetHvacView();
  const { id, building_id } = kettle;

  const { isLoading, inletPSI, outletPSI, differentialPSI, alertColor } =
    useGetKettleAndSenorsData(building_id, id);

  const handleOnClick = () => {
    dispatch(setSelectedKettle(kettle));
    navigate(`/buildings/kettle/${building_id}/${id}`);
  };

  return (
    <Container
      maxWidth={false}
      style={{ padding: 0 }}>
      {!isChartViewActive ? (
        <Grid
          data-testid="kettle-row"
          container
          style={styles.root(theme)}
          onClick={handleOnClick}>
          <Grid
            item
            lg={6}
            md={6}
            sm={6}
            xs={6}>
            <Typography style={styles.column}>{kettle.name}</Typography>
          </Grid>

          <Grid
            item
            lg={6}
            md={6}
            sm={6}>
            {isLoading ? (
              <Typography data-testid="loading">Loading...</Typography>
            ) : (
              <KettleInfo
                inletPSI={inletPSI}
                outletPSI={outletPSI}
                alertColor={alertColor}
                differentialPSI={differentialPSI}
              />
            )}
          </Grid>
        </Grid>
      ) : (
        <Paper
          data-testid="kettle-card"
          onClick={handleOnClick}>
          <Stack>
            <Typography variant="body1">Kettle name</Typography>
            <Typography
              variant="body1"
              data-testid="kettle-name">
              {kettle.name}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="body1">Details</Typography>
            <KettleInfo
              inletPSI={inletPSI}
              outletPSI={outletPSI}
              alertColor={alertColor}
              differentialPSI={differentialPSI}
            />
          </Stack>
        </Paper>
      )}
    </Container>
  );
};

const styles = {
  root: (theme: Theme) => ({
    paddingLeft: "16px",
    paddingRight: "16px",
    transition: "all 250ms",
    "&:hover": {
      backgroundColor: theme.palette.grey["200"]
    },
    cursor: "pointer",
    backgroundColor: "white",
    alignItems: "center"
  }),
  column: {
    whiteSpace: "nowrap" as const,
    textOverflow: "ellipsis",
    overflow: "hidden",
    paddingLeft: 5,
    paddingBottom: 0
  }
};

export default KettleRowAndCardInfo;
