import AirIcon from "@mui/icons-material/Air";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import CloudCircleIcon from "@mui/icons-material/CloudCircle";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import { Box, Tabs, Tab } from "@mui/material";
import { logout } from "src/store/auth/actions";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useLocation, useNavigate } from "react-router-dom";
import { SyntheticEvent, useContext, useState } from "react";
import {
  DrawerContext,
  getCorrectFilter
} from "src/sections/dashboard/Context/DashboardContext";
import { makeStyles } from "tss-react/mui";
interface MainMenuProps {
  orientation?: "vertical" | "horizontal";
}

export enum Filters {
  DASH = "dashboard",
  HVAC = "hvac",
  KETTLE = "kettle",
  BOOTH = "booth",
  IAQ = "iaq",
  SUPPORT = "support",
  SETTINGS = "settings",
  SIGNOUT = "signout",
  KETTLE_REPORTS = "kettle_reports",
  IAQ_REPORTS = "iaq_reports"
}

const MainMenu = (props: MainMenuProps) => {
  const { classes } = useStyles();
  let { orientation = "horizontal" } = props;
  const { pathname } = useLocation();
  const initialFilter = getCorrectFilter(pathname);
  const [value, setValue] = useState<string>(initialFilter);
  const { setFilter } = useContext(DrawerContext);
  const handleChange = (_event: SyntheticEvent, newValue: Filters) => {
    setValue(newValue);
  };
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleSignOut = () => {
    dispatch(logout());
  };

  return (
    <>
      <Tabs
        className={classes.tabs}
        scrollButtons="auto"
        variant="scrollable"
        data-testid="main-menu"
        onChange={handleChange}
        value={pathname}
        indicatorColor="primary"
        orientation={orientation}
        TabIndicatorProps={{ children: <span /> }}>
        <Tab
          data-testid="overview-btn"
          className={classes.tabSx}
          value="/dashboard/tab/0"
          onClick={() => {
            setFilter(Filters.DASH);
            navigate("/dashboard");
          }}
          label={
            <Box className={classes.box}>
              <ViewQuiltIcon className={classes.icons} />
              Dashboard
            </Box>
          }
        />
        <Tab
          data-testid="hvac-btn"
          className={classes.tabSx}
          value="/buildings/hvac"
          onClick={() => {
            setFilter(Filters.HVAC);
            navigate(`/buildings/${Filters.HVAC}`);
          }}
          label={
            <Box className={classes.box}>
              <AirIcon className={classes.icons} />
              Air Filtration
            </Box>
          }
        />
        <Tab
          data-testid="kettles-btn"
          className={classes.tabSx}
          value="/buildings/kettle"
          onClick={() => {
            setFilter(Filters.KETTLE);
            navigate(`/buildings/${Filters.KETTLE}`);
          }}
          label={
            <Box className={classes.box}>
              <WaterDropIcon className={classes.icons} />
              Liquid Filtration
            </Box>
          }
        />
        {/* {(value === "/buildings/kettle" || value === "/reports/kettle") && ( */}
        {/*   <Tab */}
        {/*     data-testid="reports-btn" */}
        {/*     className={classes.liquidTab} */}
        {/*     value="/reports/kettle" */}
        {/*     onClick={() => { */}
        {/*       setFilter(Filters.KETTLE_REPORTS); */}
        {/*       navigate("/reports/kettle"); */}
        {/*     }} */}
        {/*     label={ */}
        {/*       <Box */}
        {/*         data-testid="reports-btn" */}
        {/*         className={classes.reports}> */}
        {/*         <ContentPasteIcon className={classes.icons} /> */}
        {/*         Generate Report */}
        {/*       </Box> */}
        {/*     } */}
        {/*   /> */}
        {/* )} */}
        <Tab
          data-testid="booth-btn"
          className={classes.tabSx}
          value="/buildings/booth"
          onClick={() => {
            setFilter(Filters.BOOTH);
            navigate(`/buildings/${Filters.BOOTH}`);
          }}
          label={
            <Box className={classes.box}>
              <FormatColorFillIcon className={classes.icons} />
              Booth Balancing
            </Box>
          }
        />
        <Tab
          data-testid="iaq-btn"
          className={classes.tabSx}
          value="/buildings/iaq"
          onClick={() => {
            setFilter(Filters.IAQ);
            navigate(`/buildings/${Filters.IAQ}`);
          }}
          label={
            <Box className={classes.box}>
              <CloudCircleIcon className={classes.icons} />
              IAQ Monitoring
            </Box>
          }
        />
        {(value === "/buildings/iaq" || value === "/reports/iaq") && (
          <Tab
            data-testid="reports-btn"
            className={classes.tabConditional}
            value="/reports/iaq"
            onClick={() => {
              setFilter(Filters.IAQ_REPORTS);
              navigate("/reports/iaq");
            }}
            label={
              <Box
                data-testid="reports-btn"
                className={classes.reports}>
                <ContentPasteIcon className={classes.icons} />
                Generate Report
              </Box>
            }
          />
        )}
      </Tabs>
      <div>
        <Tab
          className={classes.tabSettings}
          label={
            <Box className={classes.box}>
              <HelpOutlineIcon className={classes.icons} />
              Support
            </Box>
          }
          onClick={() => {
            window.open("https://6252707.hs-sites.com/knowledge");
          }}
          data-testid="support-tab"
          value="support"
        />
        <Tab
          data-testid="logout-btn"
          className={classes.tabSettings}
          label={
            <Box className={classes.box}>
              <ExitToAppIcon className={classes.icons} />
              Signout
            </Box>
          }
          onClick={handleSignOut}
        />
      </div>
    </>
  );
};

const useStyles = makeStyles()(() => {
  return {
    tabs: {
      display: "flex",
      marginLeft: "50px"
    },
    tabSx: { display: "flex", alignItems: "start" },
    tabSettings: {
      display: "flex",
      alignItems: "start",
      marginLeft: "50px"
    },
    tabConditional: {
      display: "flex",
      alignItems: "start"
    },
    liquidTab: {
      display: "flex",
      alignItems: "start"
    },
    icons: {
      fontSize: "18px",
      marginRight: "1rem"
    },
    box: {
      display: "flex",
      alignItems: "end"
    },
    reports: {
      display: "flex",
      alignItems: "end",
      marginLeft: "15px",
      fontSize: "13px"
    }
  };
});

export default MainMenu;
