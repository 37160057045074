/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Event } from '../models/Event';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class EventService {
  /**
   * Get Audit Log
   * Params:
   * - `start_ts` optional, defaults to 30 days before `end_ts`
   * - `end_ts` optional, defaults to now
   *
   * Returns:
   * - `list[Event]` service logs, threshold alerts, and connection alerts
   * sorted in chronological order, for a specific AHU or Kettle.
   * @param buildingId
   * @param startTs
   * @param endTs
   * @param ahuId
   * @param kettleId
   * @param boothId
   * @returns Event Successful Response
   * @throws ApiError
   */
  public static getAuditLog(
    buildingId: string,
    startTs?: (string | null),
    endTs?: (string | null),
    ahuId?: (string | null),
    kettleId?: (string | null),
    boothId?: (string | null),
  ): CancelablePromise<Array<Event>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/events/audit-log/',
      query: {
        'building_id': buildingId,
        'start_ts': startTs,
        'end_ts': endTs,
        'ahu_id': ahuId,
        'kettle_id': kettleId,
        'booth_id': boothId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Audit Log Download
   * Params:
   * - `start_ts` optional, defaults to 30 days before `end_ts`
   * - `end_ts` optional, defaults to now
   *
   * Returns:
   * - `base64 bytes` excel file (.xlsx) with three sheets for threshold alerts, connection alerts, and service logs
   *
   * Raises:
   * - `EntityDoesNotExistError` if building_id or ahu/kettle_id are incorrect
   * - `ValueError` if `start_ts` >= `end_ts`
   * @param buildingId
   * @param startTs
   * @param endTs
   * @param ahuId
   * @param kettleId
   * @param boothId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static auditLogDownload(
    buildingId: string,
    startTs?: (string | null),
    endTs?: (string | null),
    ahuId?: (string | null),
    kettleId?: (string | null),
    boothId?: (string | null),
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/events/audit-log/download/',
      query: {
        'building_id': buildingId,
        'start_ts': startTs,
        'end_ts': endTs,
        'ahu_id': ahuId,
        'kettle_id': kettleId,
        'booth_id': boothId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
