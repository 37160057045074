import defaultConfig from "./default.config.js";
import example from "./example.config.js";
import mh from "./mh.config.js";
import ourair from "./ourair.config.js";

export default {
  default: defaultConfig,
  example,
  mh,
  ourair
};
