import LoaderComponent from "src/shared/components/loader/loader.component";
import { useDispatch } from "react-redux";
import { setSelectedServiceLog } from "src/store/servicelogs/reducer";
import ModalComponent from "src/shared/components/modal/modal.component";
import { DatePicker } from "@mui/x-date-pickers";
import { Field, Form } from "react-final-form";
import { makeStyles } from "tss-react/mui";
import { renderWeekPickerDay } from "src/utils/date-picker.utils";

import {
  Alert,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { useAppSelector } from "src/hooks/useAppSelector";
import { getSelectedServiceLog } from "src/store/servicelogs/selectors";
import {
  useCreateServiceLogMutation,
  useUpdateServiceLogMutation,
  useDeleteServiceLogMutation
} from "src/store/services/servicelogs/servicelogs.service";
import { useGetAhuStagesQuery } from "src/store/services/hvac/hvac.service";
import { Dispatch, SetStateAction, useRef } from "react";
import { getUserId } from "src/store/auth/selectors";
import {
  Ahu,
  Kettle,
  PaintBooth,
  ServiceLogNew,
  ServiceLogUpdates
} from "src/utils/client";
import { AnyObject } from "final-form";
import moment, { Moment } from "moment";

export enum Service_type {
  FilterAhuReplacement = "ahu_filter_change",
  AhuOtherReplacement = "ahu_other",
  FilterKettleReplacement = "kettle_filter_change",
  KettleOtherReplacement = "kettle_other",
  PaintBoothReplacement = "paint_booth_filter_change",
  PaintBoohtOtherReplacement = "paint_booth_other"
}
const ahuCategoryOptions = {
  Filter: {
    value: Service_type.FilterAhuReplacement,
    label: "Filter Replacement"
  },
  Other: {
    value: Service_type.AhuOtherReplacement,
    label: "Other"
  }
};

const kettleCategoryOptions = {
  Filter: {
    value: Service_type.FilterKettleReplacement,
    label: "Filter Replacement"
  },
  Other: {
    value: Service_type.KettleOtherReplacement,
    label: "Other"
  }
};

const paintBoohtCategoryOptions = {
  Filter: {
    value: Service_type.PaintBoothReplacement,
    label: "Filter Replacement"
  },
  Other: {
    value: Service_type.PaintBoohtOtherReplacement,
    label: "Other"
  }
};
const AddServiceLogComponent = ({
  kettle,
  ahu,
  paintBooth,
  open,
  setOpen
}: {
  kettle: Kettle;
  ahu: Ahu;
  paintBooth: PaintBooth;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const selectedLog = useAppSelector(getSelectedServiceLog);
  const userId = useAppSelector(getUserId);
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const categoryRef = useRef(null);
  const isKettleFlowActive = Boolean(kettle);
  const isPaintBoothFlowActive = Boolean(paintBooth);
  const [createServiceLog, { isLoading: isCreating }] =
    useCreateServiceLogMutation();
  const [updateServiceLog, { isLoading: isUpdating }] =
    useUpdateServiceLogMutation();
  const [deleteServiceLog, { isLoading: isDeleting }] =
    useDeleteServiceLogMutation();

  const building_id =
    kettle?.building_id ?? ahu?.building_id ?? paintBooth?.building_id;
  const ahuId = ahu?.id;
  const categoryValue = isKettleFlowActive
    ? kettleCategoryOptions.Filter.value
    : ahuCategoryOptions.Filter.value;

  const { data: stages } = useGetAhuStagesQuery(
    {
      buildingId: building_id,
      ahuId
    },
    { skip: isKettleFlowActive || isPaintBoothFlowActive || !open }
  );

  const loading = isCreating || isUpdating || isDeleting;
  const activeLog = Boolean(selectedLog);

  const closeModal = () => {
    setOpen(false);
    dispatch(setSelectedServiceLog(null));
    categoryRef.current = null;
  };

  const handleOnSuccess = () => {
    closeModal();
  };

  const validate = (values: AnyObject) => {
    let errors: AnyObject = {};
    const defaultFields = ["description"]
      .concat(isKettleFlowActive || isPaintBoothFlowActive ? "" : "stage_id")
      .filter(Boolean);
    let fields =
      categoryRef.current === categoryValue
        ? defaultFields
        : defaultFields.concat("title");

    for (let field of fields) {
      if (!values[field]) {
        errors[field] = `${field.replace("_", " ")} is required`;
      }
    }

    return errors;
  };

  const submit = (serviceLog: {
    time: Moment;
    stage_id: string;
    title: string;
    description: string;
    category: `${Service_type}`;
  }) => {
    const serviceLogWithBuildingData: ServiceLogNew | ServiceLogUpdates = {
      // @ts-ignore
      time: activeLog ? serviceLog.time : moment(serviceLog.time).format(),
      details: {
        ids: isPaintBoothFlowActive
          ? { booth_id: paintBooth.id, entity_type: "paint_booth" }
          : !isKettleFlowActive
          ? {
              // @ts-ignore
              ahu_id: ahu.id,
              stage_id: serviceLog.stage_id,
              entity_type: "ahu"
            }
          : {
              kettle_id: kettle.id,
              entity_type: "kettle"
            },
        title: serviceLog.title,
        notes: serviceLog.description,
        service_type: serviceLog.category
      },
      building_id: building_id,
      user_id: userId
    };
    if (activeLog) {
      updateServiceLog({
        buildingId: selectedLog.building_id,
        serviceLogId: selectedLog.id,
        body: serviceLogWithBuildingData
      }).finally(() => handleOnSuccess());
    } else {
      // @ts-ignore
      createServiceLog(serviceLogWithBuildingData).finally(() =>
        handleOnSuccess()
      );
    }
  };

  const remove = () => {
    const logToDelete = {
      buildingId: selectedLog.building_id,
      serviceLogId: selectedLog.id,
      ahuId: ahu?.id,
      stageId: selectedLog?.details?.ids?.stage_id,
      kettleId: kettle?.id,
      boothId: paintBooth?.id
    };
    deleteServiceLog(logToDelete).finally(() => handleOnSuccess());
  };

  return (
    <ModalComponent
      open={open}
      onClose={closeModal}>
      <div
        data-testid="add-audit-log"
        className={classes.root}>
        <div className={classes.title}>
          <Typography
            variant="h6"
            gutterBottom>
            {selectedLog ? "Edit" : "Add"} service log
          </Typography>
        </div>
        <div>
          <Form
            initialValues={{
              category: selectedLog?.details?.service_type,
              title: selectedLog?.details?.title,
              stage_id: selectedLog?.details?.ids?.stage_id,
              time: selectedLog?.time,
              description: selectedLog?.details?.notes
            }}
            validate={validate}
            onSubmit={submit}
            render={({ handleSubmit, hasValidationErrors, form }) => {
              return (
                <form
                  onSubmit={handleSubmit}
                  data-testid="form">
                  {loading && <LoaderComponent />}
                  <div>
                    <Field name="category">
                      {({ input, meta }) => (
                        <FormControl fullWidth>
                          <InputLabel>Choose Service Category</InputLabel>
                          <Select
                            label="Choose Service Category"
                            className={classes.textInput}
                            error={Boolean(meta.touched && meta.error)}
                            inputProps={input}
                            onChange={() => {
                              categoryRef.current =
                                form.getState().values.category;
                            }}>
                            {Object.values(
                              isPaintBoothFlowActive
                                ? paintBoohtCategoryOptions
                                : isKettleFlowActive
                                ? kettleCategoryOptions
                                : ahuCategoryOptions
                            ).map(({ value, label }) => (
                              <MenuItem
                                key={label}
                                value={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                    <div>
                      {!Boolean(categoryRef.current) &&
                        !Boolean(selectedLog) && (
                          <div className={classes.fieldset}>
                            <Alert severity="info">
                              Select a Service Category Above
                            </Alert>
                          </div>
                        )}
                      <div>
                        <Field name="title">
                          {({ input, meta }) => (
                            <TextField
                              placeholder={null}
                              label="Service Title *"
                              className={classes.textInput}
                              error={
                                Boolean(meta.touched) && Boolean(meta.error)
                              }
                              helperText={meta.touched && meta.error}
                              InputProps={input}></TextField>
                          )}
                        </Field>
                      </div>
                      {!isKettleFlowActive &&
                        !Boolean(selectedLog) &&
                        !isPaintBoothFlowActive && (
                          <div>
                            <Field name="stage_id">
                              {({ input, meta }) => (
                                <FormControl fullWidth>
                                  <InputLabel>Filter Stage *</InputLabel>
                                  <Select
                                    label="Filter stage *"
                                    className={classes.textInput}
                                    error={Boolean(meta.touched && meta.error)}
                                    inputProps={input}>
                                    {stages?.map((stage, index) => (
                                      <MenuItem
                                        key={`stagetype-${index}`}
                                        value={stage.id}>
                                        {stage.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              )}
                            </Field>
                          </div>
                        )}
                      <Grid
                        container
                        columnSpacing={1}>
                        <Grid
                          item
                          xs={12}
                          md={12}>
                          <Field name="time">
                            {({ input, meta }) => (
                              <DatePicker
                                onChange={value => {
                                  form.change("time", value);
                                }}
                                value={form.getState().values.time ?? moment()}
                                label="Date serviced *"
                                renderDay={renderWeekPickerDay}
                                renderInput={params => (
                                  <TextField
                                    placeholder={null}
                                    label="Date serviced *"
                                    style={{ width: "100%" }}
                                    className={classes.textInput}
                                    error={
                                      Boolean(meta.touched) &&
                                      Boolean(meta.error)
                                    }
                                    helperText={meta.touched && meta.error}
                                    InputProps={input}
                                    {...params}></TextField>
                                )}
                              />
                            )}
                          </Field>
                        </Grid>
                      </Grid>
                      <div>
                        <Field name="description">
                          {({ input, meta }) => (
                            <TextField
                              placeholder={null}
                              label="Description *"
                              multiline
                              rows={4}
                              className={classes.textInput}
                              error={
                                Boolean(meta.touched) && Boolean(meta.error)
                              }
                              helperText={meta.touched && meta.error}
                              InputProps={input}></TextField>
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>
                  <div className={classes.actions}>
                    <Button
                      variant="contained"
                      type="submit"
                      className={classes.saveBtn}
                      disabled={hasValidationErrors || loading}>
                      Save changes
                    </Button>
                    {selectedLog && (
                      <Button
                        type="button"
                        variant="text"
                        color="warning"
                        onClick={remove}>
                        Delete
                      </Button>
                    )}
                    <Button
                      type="button"
                      variant="text"
                      color="inherit"
                      onClick={() => closeModal()}>
                      Cancel
                    </Button>
                  </div>
                </form>
              );
            }}
          />
        </div>
      </div>
    </ModalComponent>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {},
    category: {},
    title: {
      marginBottom: "1.5em"
    },
    fieldset: {
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(255,255,255, 0.5)",
      zIndex: 2,
      left: "50%",
      transform: "translateX(-50%)",
      width: "100%",
      height: "52%"
    },
    loader: {
      top: 0,
      left: 0
    },
    saveBtn: {
      width: 180,
      marginRight: "1em"
    },
    actions: {
      marginTop: "1em"
    },
    textInput: {
      width: "100%",
      marginBottom: "0.75em"
    }
  };
});

export default AddServiceLogComponent;
