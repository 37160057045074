import { Alert, AlertTitle, Button, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import AddIcon from "@mui/icons-material/Add";
import NotificationsListComponent from "../notifications-list/notifications-list.component";
import NotificationDrawerComponent from "../notification-drawer/notification-drawer.component";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import HelpIcon from "@mui/icons-material/Help";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { setDrawerOpen } from "src/store/notification/reducer";
import { useNavigate } from "react-router-dom";

type Props = {
  internalError?: any;
};

export const NotificationsComponent = (props: Props) => {
  const { internalError } = props;
  const navigate = useNavigate();
  const helpLink =
    "https://6252707.hs-sites.com/knowledge/notifications-and-how-to-use-them";
  const dispatch = useAppDispatch();
  const { classes } = useStyles();

  if (internalError) {
    return (
      <Alert severity="error">
        <AlertTitle>
          Error{internalError.message ? `: ${internalError.message}` : ""}
        </AlertTitle>
        <div style={{ whiteSpace: "pre-line" }}>
          {internalError.componentStack}
        </div>
      </Alert>
    );
  }

  const handleClick = () => {
    window.location.replace(helpLink);
  };
  const handleBack = () => {
    navigate("/dashboard");
  };

  return (
    <div
      className={classes.root}
      data-testid="notifications">
      <div className={classes.header}>
        <div className={classes.title}>
          <Button
            data-testid="back-btn"
            variant="text"
            onClick={handleBack}
            className={classes.backBtn}
            size="small">
            <ArrowBackIosRoundedIcon />
            Back
          </Button>
          <Tooltip
            title="Notifications are defined by users in the table below. Alerts are generated when the latest device value is outside of the defined threshold and displayed to the user in the upper right screen bell icon. Open the Alerts Drawer to view the latest Alerts Generated for each defined Notification below."
            arrow
            placement="top"
            className="tooltip">
            <InfoOutlinedIcon fontSize="small" />
          </Tooltip>
          <div>
            <Typography variant="h6">
              &nbsp;&#8203;Notification Settings
            </Typography>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}>
          <Tooltip
            data-testid="tooltip-btn"
            onClick={handleClick}
            title="Help link for notifications definition page"
            arrow
            placement="top"
            className="tooltip">
            <HelpIcon
              color="primary"
              fontSize="large"
              sx={{ cursor: "pointer", mr: "5px" }}
            />
          </Tooltip>
          <Button
            data-testid="create-notification-btn"
            variant="contained"
            onClick={() => dispatch(setDrawerOpen(true))}>
            <AddIcon />
            Create Notification
          </Button>
        </div>
      </div>

      <div>
        <NotificationsListComponent />
      </div>
      <NotificationDrawerComponent />
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {
      padding: 16,
      background: "rgb(243, 246, 255)"
    },
    title: {
      marginBottom: "1em",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& div": {
        display: "flex",
        alignItems: "center"
      }
    },
    backBtn: {
      left: -10,
      marginRight: "0.5em",
      "& svg": {
        marginRight: "1em",
        fontSize: "12pt"
      }
    },
    header: {
      display: "flex",
      justifyContent: "space-between"
    }
  };
});

export default NotificationsComponent;
