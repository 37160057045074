/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcknowledgementStatus } from '../models/AcknowledgementStatus';
import type { AlertReturn } from '../models/AlertReturn';
import type { GetTermsResponseBody } from '../models/GetTermsResponseBody';
import type { Memberships } from '../models/Memberships';
import type { NotificationDefinitionReturn } from '../models/NotificationDefinitionReturn';
import type { NotificationSubscription } from '../models/NotificationSubscription';
import type { UpdateTermsRequestBody } from '../models/UpdateTermsRequestBody';
import type { User } from '../models/User';
import type { UserNew } from '../models/UserNew';
import type { UserRole } from '../models/UserRole';
import type { UserSettings_Input } from '../models/UserSettings_Input';
import type { UserUpdates } from '../models/UserUpdates';
import type { UserWithRole } from '../models/UserWithRole';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UsersService {
  /**
   * Get Alerts For User
   * @param start
   * @param end
   * @param alertStateFilter
   * @returns AlertReturn Successful Response
   * @throws ApiError
   */
  public static getAlertsForUser(
    start?: (string | null),
    end?: (string | null),
    alertStateFilter?: (AcknowledgementStatus | null),
  ): CancelablePromise<Array<AlertReturn>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/alerts/',
      query: {
        'start': start,
        'end': end,
        'alert_state_filter': alertStateFilter,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Subscribed To Notifications For User
   * @param userId
   * @returns NotificationDefinitionReturn Successful Response
   * @throws ApiError
   */
  public static getSubscribedToNotificationsForUser(
    userId: string,
  ): CancelablePromise<Array<NotificationDefinitionReturn>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/subscribed-notifications/',
      query: {
        'user_id': userId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Subscribe User To Notification
   * Subscribes the user with `user_id` to the `notification_id`
   * @param buildingId
   * @param notificationId
   * @param userId
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public static subscribeUserToNotification(
    buildingId: string,
    notificationId: string,
    userId: string,
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/buildings/{building_id}/notifications/{notification_id}/subscriptions/',
      path: {
        'building_id': buildingId,
        'notification_id': notificationId,
      },
      query: {
        'user_id': userId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Unsubscribe User From Notification
   * @param buildingId
   * @param notificationId
   * @param userId
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public static unsubscribeUserFromNotification(
    buildingId: string,
    notificationId: string,
    userId: string,
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/buildings/{building_id}/notifications/{notification_id}/subscriptions/',
      path: {
        'building_id': buildingId,
        'notification_id': notificationId,
      },
      query: {
        'user_id': userId,
      },
      errors: {
        400: `Bad subscription`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get All Notification Subscriptions For User
   * @param userId
   * @returns NotificationSubscription Successful Response
   * @throws ApiError
   */
  public static getAllNotificationSubscriptionsForUser(
    userId: string,
  ): CancelablePromise<Array<NotificationSubscription>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/{user_id}/notification-subscriptions/',
      path: {
        'user_id': userId,
      },
      errors: {
        400: `Bad subscription`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get All Users
   * Returns all users.
   * @returns UserWithRole Successful Response
   * @throws ApiError
   */
  public static getAllUsers(): CancelablePromise<Array<UserWithRole>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/',
    });
  }
  /**
   * Create User
   * Create user within groups specified.
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static createUser(
    requestBody: UserNew,
  ): CancelablePromise<(User | null)> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/users/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        409: `Email is Taken`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get User
   * @param userId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getUser(
    userId: string,
  ): CancelablePromise<(UserWithRole | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/{user_id}',
      path: {
        'user_id': userId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Update User
   * @param userId
   * @param requestBody
   * @returns User Successful Response
   * @throws ApiError
   */
  public static updateUser(
    userId: string,
    requestBody: UserUpdates,
  ): CancelablePromise<User> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/users/{user_id}',
      path: {
        'user_id': userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Delete User
   * @param userId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static deleteUser(
    userId: string,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/users/{user_id}',
      path: {
        'user_id': userId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get User Memberships
   * Returns the companies and buildings the user is a member of.
   * @param userId
   * @returns Memberships Successful Response
   * @throws ApiError
   */
  public static getUserMemberships(
    userId: string,
  ): CancelablePromise<Memberships> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/{user_id}/memberships/',
      path: {
        'user_id': userId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get User Widgets
   * @param userId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getUserWidgets(
    userId: string,
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/{user_id}/widgets/',
      path: {
        'user_id': userId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Update User Widgets
   * @param userId
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static updateUserWidgets(
    userId: string,
    requestBody: Array<Record<string, any>>,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/users/{user_id}/widgets/',
      path: {
        'user_id': userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Change Role
   * @param userId
   * @param newRole
   * @returns any Successful Response
   * @throws ApiError
   */
  public static changeRole(
    userId: string,
    newRole: UserRole,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/users/{user_id}/role/',
      path: {
        'user_id': userId,
      },
      query: {
        'new_role': newRole,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Update User Terms
   * Update the `user.accepted_terms` to True/False.
   * @param userId
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static updateUserTerms(
    userId: string,
    requestBody: UpdateTermsRequestBody,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/users/{user_id}/terms/',
      path: {
        'user_id': userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get User Terms
   * If user has not yet accepted the terms, otherwise returns the terms as a string.
   * @param userId
   * @returns GetTermsResponseBody Successful Response
   * @throws ApiError
   */
  public static getUserTerms(
    userId: string,
  ): CancelablePromise<GetTermsResponseBody> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/{user_id}/terms/',
      path: {
        'user_id': userId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Update User Settings
   * @param userId
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static updateUserSettings(
    userId: string,
    requestBody: UserSettings_Input,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/users/{user_id}/settings/',
      path: {
        'user_id': userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Avaiable User Setting Options
   * Returns static info about the settings/preferences available for user.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static avaiableUserSettingOptions(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/settings/options/',
    });
  }
}
