import { makeStyles } from "tss-react/mui";
import { TableHead, TableRow, TableCell, TableSortLabel } from "@mui/material";
import { Pollutant } from "../../utils/aq-widget-enums";

type Props = {
  order: "asc" | "desc";
  selectedPollutant: string;
  handleOrderPollutant: (pollutant: string) => void;
};

const HEADERS = ["Room", "Device"];
const POLLUTANTS = [
  Pollutant.aqiTitle,
  Pollutant.cotwoTitle,
  Pollutant.vocTitle,
  Pollutant.pmtwoTitle,
  Pollutant.pmtenTitle
];

const SortingTableHead = ({
  order,
  selectedPollutant,
  handleOrderPollutant
}: Props) => {
  const { classes } = useStyles();

  return (
    <TableHead
      className={classes.headerRow}
      data-testid="table-head">
      <TableRow>
        {HEADERS.map(header => (
          <TableCell
            key={header}
            className={classes.title}>
            {header}
          </TableCell>
        ))}
        {POLLUTANTS.map(pollutant => (
          <TableCell
            key={pollutant}
            className={classes.title}
            sortDirection={selectedPollutant === pollutant ? order : false}>
            <TableSortLabel
              classes={{ root: classes.label }}
              active={selectedPollutant === pollutant}
              direction={selectedPollutant === pollutant ? order : "asc"}
              onClick={() => handleOrderPollutant(pollutant)}>
              {pollutant}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    headerRow: {
      borderBottom: "2px solid grey"
    },
    title: {
      font: "bold",
      color: "grey"
    },
    label: {
      marginLeft: "-2rem",
      display: "flex",
      justifyContent: "center",
      flexDirection: "row-reverse"
    }
  };
});

export default SortingTableHead;
