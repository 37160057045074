import { makeStyles } from "tss-react/mui";
export const useStyles = makeStyles()(theme => {
  return {
    root: {
      backgroundColor: "white",
      padding: "4%",
      paddingTop: "0.5em",
      paddingBottom: "2em"
    },
    details: {
      margin: "1em 0 1em 0"
    },
    title: {
      display: "flex",
      alignItems: "center"
    },
    separator: {
      height: 3,
      width: "100%",
      backgroundColor: theme.palette.grey["500"],
      marginBottom: "1em"
    },
    backBtn: {
      marginRight: "1em",
      height: 34
    },
    settings: {
      paddingTop: "1em",
      paddingBottom: "1em",
      borderBottom: `1px solid ${theme.palette.grey["500"]}`,
      alignItems: "center",
      maxWidth: 720
    },
    btn: {
      width: "100%",
      "&.MuiButton-outlined": {
        color: theme.palette.grey["500"]
      }
    },
    settingsGrid: {
      alignItems: "center"
    },
    actions: {
      marginTop: "1em"
    },
    actionBtn: {
      width: 140,
      marginRight: "1em"
    },
    select: {
      width: "100%",
      borderRadius: 3,
      border: `1px solid ${theme.palette.grey["400"]}`,
      color: theme.palette.grey["400"],
      "& .MuiSelect-select": {
        paddingTop: 8,
        paddingBottom: 8
      }
    },
    flag: {
      marginRight: "1em"
    },
    option: {
      display: "flex"
    },
    notifications: {
      width: "100%",
      paddingTop: "1.5em",
      paddingBottom: "1.5em",
      borderBottom: `1px solid ${theme.palette.grey["500"]}`,
      display: "flex",
      justifyContent: "space-between",
      maxWidth: 720,
      "& svg": {
        zoom: 0.8,
        marginRight: "1em"
      }
    },
    phoneContainer: {
      paddingBottom: "0.35em"
    }
  };
});
