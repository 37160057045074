import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import { Button, Stack, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import useGetBuildings from "src/hooks/useGetBuildings";
interface BuildingTabNavigationProps {
  titleName?: string;
}

const BuildingTabNavigation = ({ titleName }: BuildingTabNavigationProps) => {
  const navigate = useNavigate();
  const { buildingId } = useParams();
  const { building } = useGetBuildings(buildingId);
  const selectedBuilding = building;
  const buttonText = "Back";
  const handleBackButton = () => {
    navigate(-1);
    return;
  };
  return (
    <Stack
      mb={2}
      direction={{ md: "row", sm: "column" }}
      alignItems={{ md: "center", sm: "center" }}
      justifyContent={{ sm: "flex-start" }}>
      <Button
        data-testid="back"
        sx={{ alignSelf: "flex-start", mr: 2 }}
        variant="text"
        onClick={handleBackButton}>
        <ArrowBackIosRoundedIcon sx={{ fontSize: "12pt", mr: 1 }} />
        {buttonText}
      </Button>
      <Stack
        direction={{ md: "row", sm: "column" }}
        alignItems={{ md: "center", sm: "center" }}
        justifyContent={{ sm: "flex-start" }}>
        <Typography
          mr={1}
          variant="h6"
          translate="no"
          color={"grey"}>
          {Boolean(selectedBuilding) && selectedBuilding.name}
          {titleName ? " /" : ""}
        </Typography>
        <Typography
          variant="h6"
          translate="no">
          {titleName ?? ""}
        </Typography>
      </Stack>
    </Stack>
  );
};
export default BuildingTabNavigation;
