// import global configs here

import { createTheme } from "@mui/material/styles";
import { cyan, teal } from "@mui/material/colors";

let apiUrl = API_BASE_URL;

if (NODE_ENV === "stg") {
  apiUrl = "https://stag-eu.api.i-qlair.com";
}

if (NODE_ENV === "production") {
  apiUrl = "https://eu.api.i-qlair.com";
}

export default {
  name: "qlair",
  title: "qlair",
  favicon: "qlair_favicon.png",
  assets: "ourair",
  apiUrl,
  cookiesDomain: ".i-qlair.eu",
  apiStage: "",
  amLicense: "AM5C313415178",
  mapCenter: {
    lat: 47.751569,
    lng: 1.675063
  },
  currency: "EUR",
  theme: createTheme({
    typography: {
      fontFamily: ['"Cera Pro"'].join(","),
      h4: {
        fontWeight: 600
      },
      h6: {
        fontWeight: 600
      }
    },
    shape: {
      borderRadius: 8
    },
    palette: {
      primary: cyan,
      secondary: teal,
      status: {
        Excellent: "rgb(21, 142, 72)",
        Good: "rgb(106, 222, 89)",
        Moderate: "rgb(244, 200, 58)",
        "Unhealthy for sensitive groups": "rgb(253, 146, 49)",
        Unhealthy: "rgb(232, 37, 71)",
        Hazardous: "rgb(188, 8, 37)",
        "Very unhealthy": "rgb(188, 8 ,37)",
        Default: "rgb(125, 125, 125)"
      },
      humidityStatus: {
        Unhealthy: "rgb(232, 120, 38)",
        Moderate: "rgb(236, 220, 108)",
        Good: "rgb(104, 207, 162)"
      },
      temperatureStatus: {
        Unhealthy: "rgb(232, 120, 38)",
        Moderate: "rgb(236, 220, 108)",
        Good: "rgb(104, 207, 162)",
        "Very unhealthy": "rgb(221, 23, 23)"
      },
      statusBg: {
        Excellent: "rgba(21, 142, 72, 0.5)",
        Good: "rgba(106, 222, 89, 0.5)",
        Moderate: "rgba(244, 200, 58, 0.5)",
        "Unhealthy for sensitive groups": "rgb(253, 146, 49)",
        Unhealthy: "rgba(232, 37, 71, 0.5)",
        Hazardous: "rgba(188, 8, 37, 0.5)",
        "Very unhealthy": "rgb(188,8,37, 0.5)",
        Default: "rgba(125, 125, 125, 0.5)"
      },
      ahuStatus: {
        clean_filters: "#008000",
        moderately_clean: "#CCCC00",
        moderately_dirty: "#FFA500",
        dirty_filters: "#FF0000",
        default: "#a9a9a9"
      },
      pressureStatus: {
        too_low: "rgb(238,130,238)",
        good: "#008000",
        moderate: "#CCCC00",
        high: "#FFA500",
        very_high: "#FF0000",
        too_high: "#800081",
        default: "#a9a9a9"
      },
      tcoHorizontalRanges: {
        tcoCurve: "#B36BFC",
        changeInterval: "#232526",
        lowestCostIndex: "#38C183",
        lowestCarbonIndex: "#E7D54D"
      }
    },
    components: {
      MuiFormControl: {
        defaultProps: {
          size: "small"
        }
      },
      MuiTextField: {
        defaultProps: {
          variant: "outlined",
          margin: "dense",
          size: "small"
        },
        styleOverrides: {
          root: {
            border: "none !important"
          }
        }
      },
      MuiButton: {
        defaultProps: {
          variant: "outlined",
          disableElevation: false
        },
        styleOverrides: {
          root: {
            textTransform: "none",
            "&$disabled": {
              backgroundColor: "rgba(0, 0, 0, 0.12)"
            }
          }
        }
      },
      MuiLink: {
        defaultProps: {
          underline: "always"
        }
      },
      MuiSelect: {
        defaultProps: {
          variant: "outlined",
          margin: "dense"
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            minWidth: "120px !important"
          }
        }
      },
      MuiTypography: {
        styleOverrides: {
          subtitle1: {
            color: "#a9a9a9"
          },
          subtitle2: {
            color: "#a9a9a9"
          },
          body2: {
            color: "#a9a9a9"
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltipPlacementBottom: {
            marginTop: "4px !important"
          }
        }
      },
      MuiButtonGroup: {
        styleOverrides: {
          grouped: {
            background: "#fff"
          },
          root: {
            "& .MuiButton-outlinedInherit": {
              borderColor: "#999",
              color: "grey"
            },
            "& .MuiButton-outlinedPrimary": {
              background: "transparent !important"
            }
          }
        }
      }
    }
  })
};

/*
GREEN CONFIG
module.exports = {
  name: 'mh',
  title: 'Mann+Hummel OurAir',
  favicon: 'mh_favicon.png',
  assets: 'mh',
  apiUrl,
  apiStage: '',
  amLicense: 'AM5C313415178',
  theme: createTheme({
    typography: {
      fontFamily: ['"Cera Pro"'].join(','),
      h4: {
        fontWeight: 600,
      },
      h6: {
        fontWeight: 600,
      },
    },
    shape: {
      borderRadius: 8,
    },
    palette: {
      primary: {
        main: '#00823C',
        light: '#46AF28',
      },
      secondary: {
        main: '#C80F2D',
        light: '#FF7300',
      },
      status: {
        Excellent: 'rgb(21, 142, 72)',
        Good: 'rgb(106, 222, 89)',
        Moderate: 'rgb(244, 200, 58)',
        'Unhealthy for sensitive groups': 'rgb(253, 146, 49)',
        Unhealthy: 'rgb(232, 37, 71)',
        Hazardous: 'rgb(188, 8, 37)',
        'Very unhealthy': 'rgb(188,8,37)',
        Default: 'rgb(125, 125, 125)',
      },
      humidityStatus: {
        Unhealthy: 'rgb(232, 120, 38)',
        Moderate: 'rgb(236, 220, 108)',
        Good: 'rgb(104, 207, 162)',
      },
      temperatureStatus: {
        Unhealthy: 'rgb(232, 120, 38)',
        Moderate: 'rgb(236, 220, 108)',
        Good: 'rgb(104, 207, 162)',
        'Very unhealthy': 'rgb(221, 23, 23)',
      },
      statusBg: {
        Excellent: 'rgba(21, 142, 72, 0.5)',
        Good: 'rgba(106, 222, 89, 0.5)',
        Moderate: 'rgba(244, 200, 58, 0.5)',
        'Unhealthy for sensitive groups': 'rgb(253, 146, 49)',
        Unhealthy: 'rgba(232, 37, 71, 0.5)',
        Hazardous: 'rgba(188, 8, 37, 0.5)',
        'Very unhealthy': 'rgb(188,8,37, 0.5)',
        Default: 'rgba(125, 125, 125, 0.5)',
      },
      ahuStatus: {
        clean_filters: '#008000',
        moderately_clean: '#CCCC00',
        moderately_dirty: '#FFA500',
        dirty_filters: '#FF0000',
        default: '#a9a9a9',
      },
      pressureStatus: {
        too_low: 'rgb(238,130,238)',
        good: '#008000',
        moderate: '#CCCC00',
        high: '#FFA500',
        very_high: '#FF0000',
        too_high: '#800081',
        default: '#a9a9a9',
      },
      tcoHorizontalRanges: {
        tcoCurve: '#B36BFC',
        changeInterval: '#232526',
        lowestCostIndex: '#38C183',
        lowestCarbonIndex: '#E7D54D',
      },
    },
    components: {
      MuiFormControl: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: 'outlined',
          margin: 'dense',
          size: 'small',
        },
      },
      MuiButton: {
        defaultProps: {
          variant: 'outlined',
          disableElevation: false,
        },
        styleOverrides: {
          root: {
            '&$disabled': {
              backgroundColor: 'rgba(0, 0, 0, 0.12)',
            },
          },
        },
      },
      MuiLink: {
        defaultProps: {
          underline: 'always',
        },
      },
      MuiSelect: {
        defaultProps: {
          variant: 'outlined',
          margin: 'dense',
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            minWidth: '120px !important',
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          subtitle1: {
            color: '#a9a9a9',
          },
          subtitle2: {
            color: '#a9a9a9',
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltipPlacementBottom: {
            marginTop: '4px !important',
          },
        },
      },
      MuiButtonGroup: {
        styleOverrides: {
          grouped: {
            background: '#fff',
          },
          root: {
            '& .MuiButton-outlinedInherit': {
              borderColor: '#999',
              color: 'grey',
            },
            '& .MuiButton-outlinedPrimary': {
              background: 'transparent !important',
            },
          },
        },
      },
    },
  }),
};
*/
