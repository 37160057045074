import { useContext, useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMore from "@mui/icons-material/ExpandMore";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import Wave2 from "src/assets/wave2.png";
import assets from "src/utils/config-assets";
import MainMenu from "src/shared/components/main-menu/main-menu.component";
import classNames from "classnames";
import AlertsDrawerComponent from "src/sections/notifications/alerts-drawer/alerts-drawer.component";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { makeStyles } from "tss-react/mui";
import { currentUserName } from "src/utils/session";
import { LoadScript } from "@react-google-maps/api";

import {
  AppBar,
  Avatar,
  Badge,
  Drawer,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Slide,
  Toolbar,
  Typography,
  useScrollTrigger
} from "@mui/material";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { setAlertsDrawerOpen } from "src/store/notification/reducer";
import { useGetAlertsQuery } from "src/store/services/notifications/notificationsService";
import { useNavigate } from "react-router-dom";
import { DrawerContext } from "../Context/DashboardContext";
import useGetAppBreakPoints from "src/hooks/useGetAppBreakPoints";

const libraries: any[] = ["visualization", "places"];

const HideOnScroll = props => {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });
  return (
    <Slide
      appear={false}
      direction="down"
      in={!trigger}>
      {children}
    </Slide>
  );
};

const Main = ({ childern }) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let [unreadAlerts, setUnreadAlerts] = useState([]);
  const { isDrawerOpen, setIsDrawerOpen } = useContext(DrawerContext);
  const { isLg } = useGetAppBreakPoints();

  const { data: alerts } = useGetAlertsQuery({ notificationId: null });

  const userName = currentUserName();

  const setAlertDrawerOpen = () => {
    dispatch(setAlertsDrawerOpen(true));
  };

  useEffect(() => {
    setUnreadAlerts((alerts || []).filter(alert => !alert.is_read));
  }, [alerts]);

  const handleLogoClick = () => {
    navigate("/dashboard");
  };

  const handleAvatarClick = () => {
    navigate("/settings");
  };

  return (
    <div
      data-testid="main"
      className={classes.root}>
      <HideOnScroll>
        <AppBar
          color="transparent"
          className={classes.header}>
          <Toolbar className={classes.headerElements}>
            <div className={classes.headerRight}>
              <div className={classes.userInfo}>
                <IconButton
                  color="primary"
                  className={classes.notificationBtn}
                  onClick={setAlertDrawerOpen}>
                  <Badge
                    badgeContent={
                      unreadAlerts.length >= 20 ? "20+" : unreadAlerts.length
                    }
                    color="error"
                    invisible={!Boolean(unreadAlerts.length)}>
                    <NotificationsNoneOutlinedIcon />
                  </Badge>
                </IconButton>

                <PopupState
                  variant="popover"
                  popupId="profile-menu">
                  {popupState => (
                    <>
                      <div
                        translate="no"
                        className={classNames(
                          classes.profileMenu,
                          "notranslate"
                        )}
                        aria-controls="profile-menu"
                        aria-haspopup="true"
                        {...bindTrigger(popupState)}
                        data-testid="profile-menu">
                        <Avatar sx={{ width: 32, height: 32 }}>
                          {userName.charAt(0)}
                        </Avatar>{" "}
                        <Hidden mdDown>
                          <div className={classes.headerName}>{userName}</div>
                          <ExpandMore />
                        </Hidden>
                      </div>
                      <Menu
                        id="profile-menu"
                        keepMounted
                        {...bindMenu(popupState)}>
                        <MenuItem
                          data-testid="settings-button"
                          onClick={() => {
                            navigate("/settings");
                          }}>
                          System Settings
                        </MenuItem>
                        <MenuItem
                          data-testid="notification-settings-button"
                          onClick={() => {
                            navigate("/notifications");
                          }}>
                          Notification Settings
                        </MenuItem>
                        <MenuItem
                          data-testid="user-admin-button"
                          onClick={() => {
                            navigate("/user-administration-portal");
                          }}>
                          User Administration
                        </MenuItem>
                      </Menu>
                    </>
                  )}
                </PopupState>
              </div>

              <div className={classes.logoContainer}>
                <img
                  className={classes.logo}
                  src={assets.logo.default}
                  onClick={handleLogoClick}
                />
              </div>

              <IconButton
                onClick={() => {
                  setIsDrawerOpen(true);
                }}
                edge="start"
                color="inherit"
                aria-label="menu"
                size="large">
                <MenuIcon className={classes.menu} />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Drawer
        open={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false);
        }}
        elevation={16}
        variant={isLg ? "persistent" : "temporary"}>
        <div className={classes.drawerHeader}>
          <img
            src={Wave2}
            className={classes.waves}></img>
          <img
            onClick={handleLogoClick}
            className={classes.drawerHeaderLogo}
            src={assets.logo.default}
          />
          <IconButton
            onClick={() => {
              setIsDrawerOpen(false);
            }}
            aria-label="close"
            sx={{ color: "white", position: "absolute", top: 10, right: 10 }}>
            <CloseRoundedIcon />
          </IconButton>
          <div
            className={classes.drawerUser}
            onClick={handleAvatarClick}
            role="button">
            <Avatar>{userName.charAt(0)}</Avatar>{" "}
            <Typography className={classes.drawerName}>{userName}</Typography>
          </div>
        </div>
        <div className={classes.drawer}>
          <MainMenu orientation="vertical" />
        </div>
      </Drawer>

      {isDrawerOpen && isLg ? (
        <div style={{ marginLeft: "290px", transition: "0.25s ease" }}>
          <LoadScript
            googleMapsApiKey={GOOGLE_MAPS_KEY}
            libraries={libraries}>
            <div className={classes.mainContainer}>{childern}</div>
          </LoadScript>
          <AlertsDrawerComponent />
        </div>
      ) : (
        <div style={{ transition: "0.2s ease-out" }}>
          <LoadScript
            googleMapsApiKey={GOOGLE_MAPS_KEY}
            libraries={libraries}>
            <div className={classes.mainContainer}>{childern}</div>
          </LoadScript>
          <AlertsDrawerComponent />
        </div>
      )}
    </div>
  );
};

export const useStyles = makeStyles()(theme => {
  return {
    root: {
      width: "100%",
      height: "100%",
      backgroundColor: "rgb(243, 246, 255)"
    },
    mainContainer: {
      paddingTop: 60
    },
    header: {
      height: 60,
      backgroundColor: "#fff",
      "@media (max-width: 599px)": {
        height: 60,
        paddingTop: 0
      },
      boxShadow: "none",
      borderBottom: "2px solid #EBEFFC"
    },
    logoContainer: {
      height: "100%",
      display: "contents"
    },
    logoContainerDesktop: {
      padding: 10
    },
    logo: {
      cursor: "pointer",
      height: 36,
      "@media (max-width: 599px)": {
        height: 32
      }
    },
    space: {
      flexGrow: 1
    },
    profileMenu: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer"
    },
    menu: {
      color: theme.palette.grey["400"]
    },
    drawer: {
      width: 300,
      justifyContent: "space-between",
      flex: 1,
      display: "flex",
      flexDirection: "column" as const
    },
    indicator: {
      bottom: 0,
      height: 4,
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
      "& > span": {
        width: "100%",
        backgroundColor:
          theme.palette.primary["500"] || theme.palette.primary["main"]
      }
    },
    tabs: {
      marginBottom: 4
    },
    tabsFlexContainer: {
      height: 60
    },
    wrapper: {
      paddingTop: 60
    },
    drawerHeader: {
      height: 168,
      width: "100%",
      padding: 20,
      marginBottom: 36,
      position: "relative" as const,
      overflow: "hidden",
      backgroundColor:
        theme.palette.primary["500"] || theme.palette.primary["main"]
    },
    drawerHeaderLogo: {
      cursor: "pointer",
      width: 58,
      filter: "brightness(0) invert(1)"
    },
    waves: {
      position: "absolute" as const,
      transform: "rotate(-90deg);",
      filter: "brightness(0) invert(1)",
      top: 415,
      left: -415,
      height: 600
    },
    tabLabelV: {
      alignSelf: "start",
      textTransform: "capitalize" as const,
      position: "relative" as const,
      left: -16
    },
    drawerUser: {
      cursor: "pointer",
      position: "absolute" as const,
      bottom: 20,
      display: "flex",
      alignItems: "center"
    },
    drawerName: {
      marginLeft: "0.5em",
      color: "#fff",
      fontSize: "18px",
      fontWeight: 500
    },
    indicatorV: {
      display: "none"
    },
    headerElements: {
      width: "100%",
      display: "block",
      justifyContent: "space-between"
    },
    headerName: {
      marginLeft: "0.5em",
      marginRight: "0.5em"
    },
    headerRight: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row-reverse" as const,
      alignItems: "center",
      "@media (max-width: 599px)": {
        flex: 1
      }
    },
    notificationBtn: {
      marginRight: "0.5em"
    },
    userInfo: {
      display: "flex"
    }
  };
});

export default Main;
