import { useMemo } from "react";
import { makeStyles } from "tss-react/mui";
import get from "lodash/get";
import { Typography, Tooltip } from "@mui/material";
import {
  getValues,
  getCategories,
  getBackground,
  formatLabel
} from "../../utils/aq-long-term-widgets-utils";
import { formatMetric } from "../../utils/aq-widget-utils";

type Props = {
  metric: string;
  showStatus: boolean;
  data: Record<string, any>;
};

const TableColumnComponent = ({ metric, showStatus, data }: Props) => {
  const { classes } = useStyles();

  const yearlyMetricValues = useMemo(
    () => getValues(get(data, metric) ?? []),
    []
  );
  const yearlyMetricStatuses = useMemo(
    () => getCategories(get(data, `${metric}_category`) ?? []),
    []
  );

  return (
    <div className={classes.column}>
      <Typography className={classes.title}>{formatMetric(metric)}</Typography>
      {Boolean(yearlyMetricValues) &&
        yearlyMetricValues.map((value, index) => {
          const displayValue = value ? value.toFixed(0) : "--";
          const background = getBackground(
            displayValue,
            yearlyMetricStatuses[index]
          );
          const status = formatLabel(yearlyMetricStatuses[index]);
          const label = showStatus
            ? displayValue === "--"
              ? "--"
              : status
            : displayValue;
          const tooltip = showStatus
            ? displayValue
            : displayValue === "--"
            ? "Unavailable"
            : yearlyMetricStatuses[index];

          return (
            <div
              key={`${metric}_value_${index}`}
              className={classes.value}
              style={background}>
              <Tooltip
                title={tooltip}
                placement="top-end"
                disableInteractive>
                <Typography>{label}</Typography>
              </Tooltip>
            </div>
          );
        })}
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    column: {
      width: "16.4%"
    },
    title: {
      fontWeight: "bold",
      color: "grey",
      padding: "0.2rem 0rem"
    },
    value: {
      borderWidth: "1px",
      borderStyle: "solid",
      padding: "0.2rem 0rem",
      marginBottom: "2px",
      borderRadius: "2px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  };
});

export default TableColumnComponent;
