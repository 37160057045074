import CurverProgressBarComponent from "src/shared/components/curver-progress-bar/curver-progress-bar.component";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AirIcon from "@mui/icons-material/Air";
import { Typography, Tooltip } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { getMetricClass } from "src/utils/buildings.util";
import { DEFAULT } from "src/shared/constants/building-status";
import { useMemo } from "react";
import usePaleteColor from "src/hooks/usePaleteColor";
import LoaderComponent from "src/shared/components/loader/loader.component";
import Error from "src/shared/components/Error/Error";
import WidgetTitle from "../../components/WidgetTitle/WidgetTitle";
import useGetBuildings from "src/hooks/useGetBuildings";
import { useGetCotwoDecayScoreAndRecommedationsQuery } from "src/store/services/widgets/widgets.service";

type Props = {
  buildingId: string;
  requiresConfig?: boolean;
};

const CoTwoDecayWidgetComponent = ({ buildingId, requiresConfig }: Props) => {
  const { classes } = useStyles();
  const { buildings } = useGetBuildings();
  const building = buildings.find(({ id }) => id === buildingId);
  const {
    data: decay,
    isLoading,
    isFetching,
    isError: error,
    refetch
  } = useGetCotwoDecayScoreAndRecommedationsQuery(buildingId);

  const decayExist = Boolean(decay);

  const coTwoValue = decayExist ? decay.scores.cotwo : 0;

  const coTwoStatus = useMemo(() => {
    if (!decayExist) return DEFAULT;
    return getMetricClass(coTwoValue);
  }, [coTwoValue]);

  const { statusColor, gradientColors } = usePaleteColor("status", coTwoStatus);

  return (
    <div
      data-testid="cotwo-decay-widget"
      className={classes.root}>
      <Typography variant="h6">
        CO₂ Decay
        <Tooltip
          title="This score is an indication of how fast carbon dioxide levels drop in your space. A higher score means that CO₂ concentrations tend to drop quickly, indicating that your space is efficiently ventilated."
          placement="top-start"
          className="tooltip"
          arrow>
          <InfoOutlinedIcon
            color="disabled"
            fontSize="small"
          />
        </Tooltip>
      </Typography>
      {requiresConfig ? (
        <Typography>Configuration is required</Typography>
      ) : isLoading || isFetching ? (
        <LoaderComponent />
      ) : (
        <>
          <div className={classes.widgetBody}>
            {error ? (
              <Error
                setRefetchAgain={refetch}
                error={error}></Error>
            ) : (
              <>
                <WidgetTitle
                  error={error}
                  building={building}></WidgetTitle>
                <div className={classes.gaugeContainer}>
                  <CurverProgressBarComponent
                    value={decay?.scores?.cotwo}
                    displayGradient={false}
                    unit=""
                    min={0}
                    max={100}
                    classes={{ root: classes.curvedGauge }}
                    color={statusColor}
                    gradientsStops={gradientColors}
                  />
                  <div className={classes.description}>
                    <div>
                      <AirIcon
                        sx={{
                          color: statusColor,
                          marginRight: "0.25em",
                          marginTop: "2px"
                        }}
                      />
                    </div>
                    <div>
                      <Typography
                        sx={{ color: statusColor }}
                        variant="h5">
                        {coTwoStatus}
                      </Typography>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: "100%",
      padding: "10px"
    },
    title: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: 8
    },
    facilityName: {
      flex: 1,
      color: "grey",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textAlign: "right"
    },
    widgetBody: {
      flex: 1,
      overflow: "hidden"
    },
    loader: {
      position: "absolute",
      top: 0,
      left: 0
    },
    curvedGauge: {
      height: 130
    },
    description: {
      display: "flex",
      justifyContent: "center",
      position: "relative",
      alignItems: "center",
      top: -20
    },
    gaugeContainer: {
      position: "relative",
      top: -27
    }
  };
});

export default CoTwoDecayWidgetComponent;
