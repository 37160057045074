import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Box
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import WarningIcon from "@mui/icons-material/Warning";

type DialogProps = {
  title: string;
  text: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onConfirm: () => void;
  confirmButtonText?: string | null;
  showCancel?: boolean;
  showWarningIcon?: boolean;
};
const ConfirmDialog = ({
  title,
  text,
  open,
  setOpen,
  onConfirm,
  confirmButtonText = null,
  showCancel = false,
  showWarningIcon = false
}: DialogProps) => {
  return (
    <Dialog
      open={open}
      data-testid="confirm-dialog"
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog">
      <Box
        sx={{ padding: "20px" }}
        maxWidth={"400px"}>
        <Stack
          justifyContent={"left"}
          flexDirection={"row"}
          alignItems={"center"}>
          {showWarningIcon && <WarningIcon color="error" />}
          <DialogTitle
            id="confirm-dialog"
            sx={{ padding: "5px" }}>
            {title}
          </DialogTitle>
        </Stack>

        <DialogContent sx={{ padding: "5px" }}>{text}</DialogContent>
        <DialogActions>
          {showCancel && (
            <Button
              data-testid="cancel-btn"
              variant="contained"
              onClick={() => setOpen(false)}
              color="secondary">
              Cancel
            </Button>
          )}
          <Button
            data-testid="yes-btn"
            variant="contained"
            onClick={() => {
              setOpen(false);
              onConfirm();
            }}>
            {confirmButtonText ?? "Yes"}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ConfirmDialog;
