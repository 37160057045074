/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HTTPBasicCredentials } from '../models/HTTPBasicCredentials';
import type { LoginResp } from '../models/LoginResp';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class LoginService {
  /**
   * Login
   * Return tokens for a valid email+password.
   * @param requestBody
   * @returns LoginResp Successful Response
   * @throws ApiError
   */
  public static login(
    requestBody: HTTPBasicCredentials,
  ): CancelablePromise<LoginResp> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/login/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Logout
   * Revokes the tokens associated with `refresh_token`.
   *
   * Note that this can't be with local mocked UserPool;
   * moto doesn't implement the `revoke_token` method of the
   * Cognito api.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static logout(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/logout/',
    });
  }
  /**
   * Refresh
   * @returns any Successful Response
   * @throws ApiError
   */
  public static refresh(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/refresh/',
    });
  }
}
