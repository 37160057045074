import { useRef } from "react";
import LoaderComponent from "src/shared/components/loader/loader.component";
import BatteryGauge from "react-battery-gauge";
import { Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import Error from "src/shared/components/Error/Error";
import {
  useGetAhuForBuildingQuery,
  useGetHvacDataForStageQuery
} from "src/store/services/widgets/widgets.service";
import { useGetAhuStagesQuery } from "src/store/services/hvac/hvac.service";

type Props = {
  requiresConfig: boolean;
  ahuId: string;
  stageId: string;
  buildingId: string;
  companyId: string;
};

const AranetBatteryLifeWidgetComponent = ({
  requiresConfig,
  ahuId,
  stageId,
  buildingId
}: Props) => {
  const { classes } = useStyles();
  const componentRef = useRef(null);
  const {
    data: ahu,
    isLoading: isLoadingAhu,
    isError: isErrorAhu,
    refetch: refetchAhu
  } = useGetAhuForBuildingQuery({ buildingId, ahuId });
  const {
    data: stages,
    isLoading: isLoadingStages,
    isError: isErrorStages,
    refetch: refetchStages
  } = useGetAhuStagesQuery({ buildingId, ahuId });
  const {
    data: latestHvacDeviceData,
    isLoading: isLoadingHvacData,
    isError: isErrorHvacData,
    refetch: refetchHvacData
  } = useGetHvacDataForStageQuery({ buildingId, ahuId, stageId });

  const isLoading = isLoadingAhu || isLoadingStages || isLoadingHvacData;
  const error = isErrorAhu || isErrorStages || isErrorHvacData;
  const dataExist =
    Boolean(ahu) && Boolean(latestHvacDeviceData) && Boolean(stages);

  const refetch = () => {
    refetchHvacData();
    refetchAhu();
    refetchStages();
  };
  return (
    <div
      data-testid="aranet-battery-life-widget"
      className={classes.root}
      ref={componentRef}>
      {requiresConfig ? (
        <div>
          <Typography variant="h6">Device Battery Life</Typography>
          <Typography>Configuration is required</Typography>
        </div>
      ) : isLoading ? (
        <LoaderComponent></LoaderComponent>
      ) : error ? (
        <Error
          setRefetchAgain={refetch}
          error={error}></Error>
      ) : dataExist ? (
        <div className={classes.body}>
          <div className={classes.header}>
            <Typography variant="h6">Device Battery Life</Typography>
          </div>
          <div className={classes.content}>
            {typeof latestHvacDeviceData?.battery_level === "number" ? (
              <BatteryGauge
                value={latestHvacDeviceData.battery_level}
                style={{
                  position: "relative",
                  maxHeight: "60%",
                  width: "100%"
                }}
                padding={0}
                customization={{
                  batteryBody: {
                    strokeWidth: 1,
                    cornerRadius: 4,
                    fill: "none",
                    strokeColor: "#333"
                  },
                  batteryCap: {
                    fill: "none",
                    strokeWidth: 1,
                    strokeColor: "#111",
                    cornerRadius: 2,
                    capToBodyRatio: 0.4
                  },
                  readingText: {
                    style: {
                      filter: "url(#shadow)"
                    }
                  }
                }}
                aspectRatio={0.4}
                animated>
                <defs>
                  <filter id="shadow">
                    <feDropShadow
                      dx="0.25"
                      dy="0.4"
                      stdDeviation="0.2"
                    />
                  </filter>
                </defs>
              </BatteryGauge>
            ) : (
              <Typography>No Data</Typography>
            )}
          </div>
          <Typography
            variant="subtitle2"
            className={classes.ahuName}
            sx={{
              fontSize: "14px!important"
            }}>
            {ahu.name} -{stages.find(item => item.id == stageId)?.name}
          </Typography>
        </div>
      ) : null}
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {
      height: "100%",
      padding: "10px"
    },
    loader: {
      position: "absolute",
      top: 0,
      left: 0
    },
    error: {
      width: "100%",
      height: "100%",
      overflow: "auto"
    },
    body: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column"
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    content: {
      flex: 1,
      overflow: "hideen",
      display: "flex",
      transition: "all 0.2s",
      marginTop: 4
    },
    grid: {
      flexDirection: "row",
      gap: 16,
      flexWrap: "wrap"
    },
    batteryBody: {
      strokeWidth: 1,
      cornerRadius: 6,
      fill: "none",
      strokeColor: "#333"
    },
    ahuName: {
      position: "absolute",
      bottom: 4
    }
  };
});

export default AranetBatteryLifeWidgetComponent;
