/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompanyMembership } from '../models/CompanyMembership';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CompanyMembershipService {
  /**
   * Get Company Memberships
   * @param companyId
   * @returns CompanyMembership Successful Response
   * @throws ApiError
   */
  public static getCompanyMemberships(
    companyId: string,
  ): CancelablePromise<Array<CompanyMembership>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/companies/{company_id}/memberships/',
      path: {
        'company_id': companyId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Admin Create Membership
   * Directly add an existing user as a member of company without going through the invitation process.
   * The usual notification email is not sent to the user.
   * IMPORTANT: Only meant for internal qlair admin use.
   * @param companyId
   * @param userId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static adminCreateMembership(
    companyId: string,
    userId: string,
  ): CancelablePromise<(CompanyMembership | null)> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/companies/{company_id}/memberships/',
      path: {
        'company_id': companyId,
      },
      query: {
        'user_id': userId,
      },
      errors: {
        404: `User not found`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Remove User From Company
   * @param companyId
   * @param userId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static removeUserFromCompany(
    companyId: string,
    userId: string,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/companies/{company_id}/memberships/{user_id}',
      path: {
        'company_id': companyId,
        'user_id': userId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
