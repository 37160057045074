import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";

interface TableOfOwnershipCostsProps {
  costTable: any;
}

export default function TableOfOwnershipCosts({
  costTable
}: TableOfOwnershipCostsProps) {
  return (
    <Box
      py={"10px"}
      px={"24px"}
      overflow={"auto"}
      mt={1}>
      <Table
        data-testid="table-container"
        sx={styles.table}>
        <TableHead>
          <TableRow>
            <TableCell sx={styles.td}>
              <Typography variant="subtitle1">Cost Type</Typography>
            </TableCell>
            <TableCell sx={styles.td}>
              <Typography variant="subtitle1">
                Current Change Interval Cost
              </Typography>
            </TableCell>
            <TableCell sx={styles.td}>
              <Typography variant="subtitle1">
                Optimized Change Interval Cost
              </Typography>
            </TableCell>
            <TableCell sx={styles.td}>
              <Typography variant="subtitle1">Difference</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {costTable.map((row: any, index: number) => {
            return (
              <TableRow
                data-testid="row"
                sx={styles.tr}
                key={`table_row_${index}`}>
                <TableCell sx={styles.td}>
                  <Typography variant="body1">{row.costType}</Typography>
                </TableCell>
                <TableCell sx={styles.td}>
                  <Typography variant="body1">{row.currentInterval}</Typography>
                </TableCell>
                <TableCell sx={styles.td}>
                  <Typography variant="body1">
                    {row.optimizedInterval}
                  </Typography>
                </TableCell>
                <TableCell sx={styles.td}>
                  <Typography variant="body1">{row.difference}</Typography>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
}

const styles = {
  table: {
    borderSpacing: 0,
    borderCollapse: "collapse" as const,
    backgroundColor: "#ffffff",
    overflow: "hidden",
    width: "100%",
    textAlign: "center"
  },
  td: {
    p: { md: "1em", sm: "0.25em" },
    textAlign: "center"
  },
  tr: {
    borderTop: "1px solid #E9EAEA",
    "&:nth-of-type(odd)": {
      backgroundColor: "#EBEEFC"
    }
  }
};
