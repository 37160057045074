import { useMemo } from "react";
import { useAppSelector } from "src/hooks/useAppSelector";
import { getSelectedKettleType } from "src/store/kettle/selector";
import moment from "moment";
import { useGetKettleChartDataQuery } from "src/store/services/kettle/kettle.service";
import { getChartDataForKettle } from "src/utils/pressure.utils";
import { KettleTypes } from "src/sections/kettle/kettle.details.card";
import { AggGranularity } from "src/utils/client";

const THIRTY_MINUTES = 1_800_000;

export enum KettleChartColors {
  INLET = "#90CAE5",
  OUTLET = "#A290E5",
  DIFFERENTIAL = "#E58fDB"
}

const useGetChartData = (
  inletSensorId: string,
  outletSensorId: string,
  buildingId: string,
  startDate: Date,
  endDate: Date,
  granularity: AggGranularity.DAY | AggGranularity.HOUR,
  requiresConfig = false,
  isChartWidget = false
) => {
  const selectedKettleType = useAppSelector(getSelectedKettleType);
  const startDateUnix = moment(startDate).unix().toString();
  const endDateUnix = moment(endDate).endOf("day").unix().toString();
  const {
    data: inletSensorChartData,
    isLoading: isLoadingInlet,
    isError: isErrorInlet,
    refetch: refetchInlet
  } = useGetKettleChartDataQuery(
    {
      sensorId: inletSensorId,
      buildingId,
      startTime: startDateUnix,
      endTime: endDateUnix,
      granularity
    },
    {
      skip: !Boolean(inletSensorId) || requiresConfig,
      pollingInterval: isChartWidget ? THIRTY_MINUTES : Infinity
    }
  );

  const {
    data: outletSensorChartData,
    isLoading: isLoadingOutlet,
    isError: isErrorOulet,
    refetch: refetchOutlet
  } = useGetKettleChartDataQuery(
    {
      sensorId: outletSensorId,
      buildingId,
      startTime: startDateUnix,
      endTime: endDateUnix,
      granularity
    },
    {
      skip: !Boolean(outletSensorId) || requiresConfig,
      pollingInterval: isChartWidget ? THIRTY_MINUTES : Infinity
    }
  );

  const chartConfig = [
    {
      data: inletSensorChartData,
      label: KettleTypes.INLET,
      color: KettleChartColors.INLET
    },
    {
      data: outletSensorChartData,
      label: KettleTypes.OUTLET,
      color: KettleChartColors.OUTLET
    }
  ];

  const inletChartDataExist = Boolean(outletSensorChartData?.length);

  const outletChartDataExist = Boolean(inletSensorChartData?.length);
  const chartData = useMemo(() => {
    if (!outletChartDataExist && !inletChartDataExist) return [];

    const chartData = chartConfig
      .map(({ data, label, color }) =>
        getChartDataForKettle(data, label, color)
      )
      .filter(Boolean);

    if (Boolean(selectedKettleType)) {
      const activeKettleType = chartData.filter(
        ({ label }) => label.toLowerCase() === selectedKettleType.toLowerCase()
      );
      return activeKettleType;
    }
    return chartData;
  }, [
    outletChartDataExist,
    inletChartDataExist,
    selectedKettleType,
    inletSensorChartData,
    outletSensorChartData
  ]);

  return {
    chartData,
    chartDataExist: inletChartDataExist || outletChartDataExist,
    isLoading: isLoadingOutlet || isLoadingInlet,
    isError: isErrorOulet || isErrorInlet,
    refetch: () => {
      refetchInlet();
      refetchOutlet();
    }
  };
};

export default useGetChartData;
