import { apiService } from "../api";
import {
  LongTermResponse,
  AirPollutionsLevelsResponse,
  AqDataService,
  OutdoorAqDataService,
  OutdoorAqData,
  BuildingAvgPerformanceResponse,
  BuildingOverviewRow,
  IndexAndCategory,
  AqDataAndScores
} from "src/utils/client";

type AqBuildingRequest = {
  buildingId: string;
  startDate: string;
  endDate?: string;
};

type LatestAqDeviceRequest = {
  deviceId: string;
  buildingId: string;
};

export const aqDataService = apiService
  .enhanceEndpoints({
    addTagTypes: [
      "LONGTERMAQDATA",
      "BUILDINGAQDATA",
      "BUILDINGPERFORMANCEDATA",
      "BUILDINGOVERVIEWAQDATA",
      "OUTDOORAQDATA",
      "DEVICEAQDATA"
    ]
  })
  .injectEndpoints({
    endpoints: build => ({
      getLongTermAqData: build.query<LongTermResponse, AqBuildingRequest>({
        queryFn: async args => {
          const { buildingId, startDate } = args;
          const longTermAqData = await AqDataService.getLongTermAqData(
            buildingId,
            startDate
          );
          return { data: longTermAqData };
        },
        providesTags: (_result, _error, { buildingId }) => [
          { type: "LONGTERMAQDATA", buildingId }
        ]
      }),
      getBuildingAqData: build.query<
        AirPollutionsLevelsResponse,
        AqBuildingRequest
      >({
        queryFn: async args => {
          const { buildingId, startDate } = args;
          const buildingAqData =
            await AqDataService.getBuildingAirPollutionLevels(
              buildingId,
              startDate
            );
          return { data: buildingAqData };
        },
        providesTags: (_result, _error, { buildingId }) => [
          { type: "BUILDINGAQDATA", buildingId }
        ]
      }),
      getBuildingPerformanceData: build.query<
        BuildingAvgPerformanceResponse,
        AqBuildingRequest
      >({
        queryFn: async args => {
          const { buildingId, startDate, endDate } = args;
          const performanceData =
            await AqDataService.getBuildingIaqAvgPerformance(
              buildingId,
              startDate,
              endDate
            );
          return { data: performanceData };
        },
        providesTags: (_result, _error, { buildingId }) => [
          { type: "BUILDINGPERFORMANCEDATA", buildingId }
        ]
      }),
      getBuildingOverviewAqData: build.query<
        BuildingOverviewRow[],
        AqBuildingRequest
      >({
        queryFn: async args => {
          const { buildingId, startDate, endDate } = args;
          const overviewAqData = await AqDataService.getBuildingOverviewAqData(
            buildingId,
            startDate,
            endDate
          );
          return { data: overviewAqData };
        },
        providesTags: (_result, _error, { buildingId }) => [
          { type: "BUILDINGOVERVIEWAQDATA", buildingId }
        ]
      }),
      getOutdoorAqData: build.query<
        | OutdoorAqData
        | Record<string, OutdoorAqData | Record<string, IndexAndCategory>>,
        AqBuildingRequest
      >({
        queryFn: async args => {
          const { buildingId } = args;
          const outdoorData =
            await OutdoorAqDataService.getLatestOutdoorAqDataPoint(buildingId);
          return { data: outdoorData };
        },
        providesTags: (_result, _error, { buildingId }) => [
          { type: "OUTDOORAQDATA", buildingId }
        ]
      }),
      getDeviceLatestAqData: build.query<
        AqDataAndScores,
        LatestAqDeviceRequest
      >({
        queryFn: async args => {
          const { deviceId, buildingId } = args;
          const deviceData = await AqDataService.getLatestAqDeviceDataPoint(
            deviceId,
            buildingId
          );
          return { data: deviceData };
        },
        providesTags: (_result, _error, { deviceId }) => [
          { type: "DEVICEAQDATA", deviceId }
        ]
      })
    })
  });

export const {
  useGetLongTermAqDataQuery,
  useGetBuildingAqDataQuery,
  useGetBuildingPerformanceDataQuery,
  useGetBuildingOverviewAqDataQuery,
  useGetOutdoorAqDataQuery,
  useGetDeviceLatestAqDataQuery
} = aqDataService;
