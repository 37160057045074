import {
  GOOD,
  MODERATE,
  UNHEALTHY
} from "src/shared/constants/building-status";
import { getStatusColor } from "./aq-widget-utils";
import { BuildingAvgPerformanceResponse } from "src/utils/client";
import { ProgressBarData } from "../types";
import { PerformanceMetric } from "./aq-widget-enums";

const keyOrder = [
  PerformanceMetric.aqi_hundred,
  PerformanceMetric.asthma,
  PerformanceMetric.comfort,
  PerformanceMetric.covid,
  PerformanceMetric.energy,
  PerformanceMetric.productivity,
  PerformanceMetric.mold
];

export const getValuesForAverage = (
  scoreAverages: Record<string, number | null>
): number[] => {
  const values = [];
  for (const key of keyOrder) {
    if (scoreAverages.hasOwnProperty(key)) {
      values.push(scoreAverages[key]);
    }
  }
  return values;
};

export const averageScores = (
  scoreAverages: Record<string, number | null>
): number => {
  const values = getValuesForAverage(scoreAverages);
  return Number((values.reduce((a, b) => a + b, 0) / values.length).toFixed(0));
};

export const getPerformanceStatus = (score: number): string => {
  switch (true) {
    case !score:
      return "No Data to Average";
    case score > 66:
      return GOOD;
    case score > 33 && score <= 66:
      return MODERATE;
    default:
      return UNHEALTHY;
  }
};

export const getOverallScore = (
  scoreAverages: Record<string, number | null>
): { average: number; status: string; color: string } => {
  if (!scoreAverages) return;
  const average = averageScores(scoreAverages);
  const status = getPerformanceStatus(average);
  const color = getStatusColor(status);
  const overallScore = { average, status, color };
  return overallScore;
};

export const formatPerformanceTitle = (key: string): string => {
  switch (key) {
    case PerformanceMetric.aqi:
      return PerformanceMetric.aqiTitle;
    case PerformanceMetric.aqi_hundred:
      return PerformanceMetric.aqi_hundredTitle;
    case PerformanceMetric.ashrae:
      return PerformanceMetric.ashraeTitle;
    case PerformanceMetric.asthma:
      return PerformanceMetric.asthmaTitle;
    case PerformanceMetric.comfort:
      return PerformanceMetric.comfortTitle;
    case PerformanceMetric.covid:
      return PerformanceMetric.covidTitle;
    case PerformanceMetric.energy:
      return PerformanceMetric.energyTitle;
    case PerformanceMetric.mold:
      return PerformanceMetric.moldTitle;
    case PerformanceMetric.productivity:
      return PerformanceMetric.productivityTitle;
    default:
      return key;
  }
};

export const shapePerformanceBarData = (
  performanceData: BuildingAvgPerformanceResponse
): ProgressBarData[] => {
  if (!performanceData) return;
  const arr = [];

  for (let key of keyOrder) {
    const name = formatPerformanceTitle(key);
    const value = performanceData.score_avgs[key] ?? "--";
    const status = performanceData.score_categories[key] ?? "--";
    arr.push({ name, value, percentage: value, status });
  }

  return arr;
};
