import classnames from "classnames";
import SignalWifiOffIcon from "@mui/icons-material/SignalWifiOff";
import { makeStyles } from "tss-react/mui";
import { getAQIStatus, getAQValueFromDevice } from "src/utils/buildings.util";
import { Device } from "src/shared/types/building";
import { Button, Typography, Paper } from "@mui/material";
import { DEFAULT, OUTDATED } from "src/shared/constants/building-status";
import { handleDeviceModal, setIsEditingDevice } from "src/store/hvac/reducer";
import get from "lodash/get";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { MouseEvent } from "react";
import AirQualityIndicator from "src/sections/buildings/air-quality-indicator/air-quality-indicator";
import { useNavigate, useParams } from "react-router-dom";
import useGetRooms from "src/hooks/useGetRooms";

type Props = {
  device: Device;
};

const DeviceCardComponent = ({ device }: Props) => {
  const { buildingId, roomId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { room } = useGetRooms();
  const { classes } = useStyles();
  const value = getAQValueFromDevice(
    device,
    "latest_measurement.data.aqi.value"
  );
  const status = device.isOutdated
    ? OUTDATED
    : getAQIStatus(get(device, `latest_measurement.data.aqi.value`));

  const openDeviceDetailsModal = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    dispatch(setIsEditingDevice(true));
    dispatch(handleDeviceModal(true));
  };

  const handleNavigate = () => {
    navigate(
      `/buildings/iaq/${buildingId}/rooms/${roomId}/devices/${device.id}`
    );
  };

  return (
    <Paper
      data-testid="device-card"
      className={classnames(classes.root)}
      onClick={handleNavigate}>
      <div
        className={classnames(
          classes.statusIndicator,
          classes[`back${status.replace(/ /g, "_")}`]
        )}></div>
      <div className={classes.info}>
        <Typography>Device Name</Typography>
        <Typography data-testid="device-name">
          {device.name}
          {device.isOutdated && (
            <SignalWifiOffIcon
              data-testid="wifi-off-icon"
              className={classes.offline}
            />
          )}
        </Typography>
      </div>
      <div className={classes.info}>
        <Typography>Floor Name</Typography>
        <Typography>{room?.floor}</Typography>
      </div>
      <div className={classes.info}>
        <Typography>Room Name</Typography>
        <Typography>{room?.name}</Typography>
      </div>
      <div className={classes.info}>
        <Typography>AQI</Typography>
        <AirQualityIndicator value={value}></AirQualityIndicator>
      </div>
      <div className={classes.footer}>
        <Button
          variant="text"
          onClick={event => openDeviceDetailsModal(event)}>
          Edit Device
        </Button>
      </div>
    </Paper>
  );
};

export const useStyles = makeStyles()(theme => {
  if (!theme.palette.status) {
    theme.palette.status = {
      Excellent: "rgb(21, 142, 72)",
      Good: "rgb(106, 222, 89)",
      Moderate: "rgb(244, 200, 58)",
      "Unhealthy for sensitive groups": "rgb(253, 146, 49)",
      Unhealthy: "rgb(232, 37, 71)",
      Hazardous: "rgb(188, 8, 37)",
      "Very unhealthy": "rgb(188, 8 ,37)",
      Default: "rgb(125, 125, 125)"
    };
  }
  return {
    root: {
      position: "relative" as const,
      padding: 16,
      display: "flex",
      flexDirection: "column" as const,
      transition: "all 250ms",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: theme.palette.grey["200"]
      },
      borderRadius: 5,
      overflow: "hidden",
      marginTop: 10,
      marginRight: 10
    },
    title: {
      whiteSpace: "nowrap" as const,
      textOverflow: "ellipsis",
      overflow: "hidden"
    },

    qty: {
      display: "flex",
      flexDirection: "column" as const,
      alignItems: "center"
    },
    offline: {
      color: "#d32f2f",
      fontSize: "medium",
      verticalAlign: "top"
    },
    statusIndicator: {
      position: "absolute" as const,
      top: 0,
      left: 0,
      height: "100%",
      width: 8,
      backgroundColor: theme.palette.status[DEFAULT],
      zIndex: 2
    },
    footer: {
      backgroundColor: "#F8F9FF",
      fontSize: "13px",
      position: "relative" as const,
      top: 16,
      left: -16,
      padding: 8,
      width: "calc(100% + 32px)",
      display: "flex",
      justifyContent: "end"
    },
    info: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "0.25em",
      alignItems: "center"
    }
  };
});

export default DeviceCardComponent;
