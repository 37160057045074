import BuildingsModal from "src/sections/buildings/buildings-modal/buildings-modal.component";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { makeStyles } from "tss-react/mui";
import { useLocation, useParams } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { useGetUserSettings } from "src/sections/settings/hooks";
import useGetAppBreakPoints from "src/hooks/useGetAppBreakPoints";
import { FormattedBuildingWithEntity } from "src/shared/types/building";
import useGetBuildings from "src/hooks/useGetBuildings";
import { useNavigate } from "react-router-dom";
import { DrawerContext } from "src/sections/dashboard/Context/DashboardContext";
import { useContext } from "react";
import BuildingTabNavigation from "src/shared/components/building-tab-navigation/building-tab-navigation";

export const BuildingDetails = () => {
  const { default_webportal_view } = useGetUserSettings();
  const { filter } = useContext(DrawerContext);

  const { classes } = useStyles();
  const navigate = useNavigate();
  const { buildingId } = useParams();
  const { buildings } = useGetBuildings();
  const { isSm } = useGetAppBreakPoints();

  const building = buildings?.find(b => b.id === buildingId);

  const handlePrevAndNextButton = (isPrevButton: boolean) => {
    const currentIndex = buildings.findIndex(item => item.id == building.id);
    let activeBuilding: FormattedBuildingWithEntity;
    if (isPrevButton) {
      if (currentIndex === 0) {
        activeBuilding = buildings.at(-1);
      } else {
        activeBuilding = buildings[currentIndex - 1];
      }
    } else {
      if (currentIndex === buildings.length - 1) {
        activeBuilding = buildings.at(0);
      } else {
        activeBuilding = buildings[currentIndex + 1];
      }
    }
    navigate(`/buildings/${filter}/${activeBuilding.id}`);
  };

  return Boolean(building) ? (
    <div
      className={classes.root}
      data-testid="building-details">
      <Box className={classes.header}>
        <BuildingTabNavigation />
        <div>
          {!isSm && default_webportal_view === "aq" && (
            <Button
              sx={{ height: 36, lineHeight: 1.2, marginRight: "0.5em" }}
              onClick={() => {
                window.open(`/#/dashboard/tv/${building.id}`);
              }}>
              Air Quality Dashboard
            </Button>
          )}
          {buildings.length > 1 && (
            <>
              <Button
                data-testid="btn-move"
                className={classes.navBtn}
                sx={{ marginRight: 1 }}
                onClick={() => handlePrevAndNextButton(true)}>
                <ArrowBackIosNewOutlinedIcon />
                {!isSm && "Prev"}
              </Button>
              <Button
                data-testid="btn-move"
                className={classes.navBtn}
                onClick={() => handlePrevAndNextButton(false)}>
                {!isSm && "Next"}
                <ArrowForwardIosIcon />
              </Button>
            </>
          )}
        </div>
      </Box>
      <BuildingsModal />
    </div>
  ) : null;
};

const useStyles = makeStyles()(theme => {
  return {
    root: {
      padding: 16,
      height: "100%",
      backgroundColor: "rgb(243, 246, 255)"
    },
    buildingName: {
      maxWidth: 250,
      whiteSpace: "nowrap" as const,
      textOverflow: "ellipsis",
      overflow: "hidden"
    },
    tabs: {
      backgroundColor: "white"
    },
    rootTabs: {
      padding: 16,
      paddingTop: 0,
      paddingBottom: 16,
      "@media (max-width: 600px)": {
        display: "none"
      }
    },
    flexContainer: {
      borderBottom: "1px solid #E9EAEA"
    },
    selected: {
      backgroundColor: theme.palette.grey["200"],
      border: "none"
    },
    filters: {
      paddingBottom: "1em",
      display: "flex",
      justifyContent: "space-between",
      overflow: "auto",
      paddingTop: 32
    },
    title: {
      display: "flex",
      padding: "0.5em",
      paddingLeft: 0,
      paddingBottom: 0
    },
    buildingNameTitle: {
      color: "grey",
      marginRight: "0.3em"
    },
    viewButtons: {
      backgroundColor: "#F4F4F4",
      padding: "0.5em",
      marginTop: "1em",
      marginBottom: "1em",
      borderRadius: theme.shape.borderRadius,
      "@media (min-width: 601px)": {
        display: "none"
      }
    },
    header: {
      display: "flex",
      justifyContent: "space-between"
    },
    breadcrumbs: {
      display: "flex",
      alignItems: "center"
    },
    titleNav: {
      display: "flex"
    },
    titleSection: {
      display: "flex",
      alignItems: "center"
    },
    navBtn: {
      "@media (max-width: 600px)": {
        border: "none",
        minWidth: 24,
        width: 24,
        "&:hover": {
          border: "none"
        }
      }
    },
    emptyContainer: {
      display: "flex",
      alignItems: "center"
    },

    empty: {
      backgroundColor: "white",
      textAlign: "center",
      borderRadius: 3,
      padding: 20,
      maxWidth: 300,
      marginTop: 15,
      "& h4": {
        fontSize: 22
      }
    }
  };
});

export default BuildingDetails;

export const useGetActiveView = () => {
  const { pathname } = useLocation();
  return {
    isHvacViewActive: pathname.includes("hvac"),
    isIndoorViewActive: pathname.includes("aq"),
    isRoomViewActive: pathname.includes("rooms"),
    isKettleViewActive: pathname.includes("kettle"),
    isBoothViewActive: pathname.includes("booth")
  };
};
