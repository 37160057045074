import { getUserId } from "src/store/auth/selectors";
import { useGetUserWidgetsWithPagesQuery } from "src/store/services/overview/overview.service";
import { useAppSelector } from "./useAppSelector";

const useGetUserPages = () => {
  const userId = useAppSelector(getUserId);

  const {
    data: pages = [],
    isLoading,
    isFetching,
    isError,
    isSuccess,
    refetch
  } = useGetUserWidgetsWithPagesQuery(userId);

  return {
    pages,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    refetch
  };
};

export default useGetUserPages;
