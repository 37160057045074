import { makeStyles } from "tss-react/mui";
import { useMemo } from "react";
import { Typography } from "@mui/material";
import { getAQChartData } from "../../utils/aq-long-term-widgets-utils";
import LoaderComponent from "src/shared/components/loader/loader.component";
import WidgetTitle from "../../components/WidgetTitle/WidgetTitle";
import Error from "src/shared/components/Error/Error";
import ErrorIcon from "@mui/icons-material/Error";
import LineChart from "src/shared/components/line-chart/line-chart-component";
import useGetLongTermAQData from "../../hooks/useGetLongTermAQData";
import useOverview from "src/hooks/useOverview";
import { useDispatch } from "react-redux";
import { setUserClickedOnWidgetTitle } from "src/store/hvac/reducer";
import useGetPolutantDictionary from "src/hooks/useGetPollutantDictionary";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { setSelectedOverviewTab } from "src/store/overview/reducer";
import { FormattedBuildingWithEntity } from "src/shared/types/building";
import { useOverviewTab } from "../../hooks/useGetOverviewTab";

type Props = {
  buildingId?: string;
  building?: FormattedBuildingWithEntity;
  requiresConfig?: boolean;
};

const AqCurvesWidgetComponent = ({
  buildingId,
  building,
  requiresConfig
}: Props) => {
  const { classes } = useStyles();
  const { editing } = useOverview();
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { pollInfoDict } = useGetPolutantDictionary();
  const { tabId } = useOverviewTab();

  const { indoorWeeklyAqiData, isLoading, isFetching, isError, refetch } =
    useGetLongTermAQData(buildingId, requiresConfig);

  const dictionaryExist = Object.keys(pollInfoDict)?.length;

  const bgRanges = useMemo(() => {
    if (!dictionaryExist) return [];
    return pollInfoDict["aqi"]?.statuses?.thresholds?.map(range => ({
      min: range.low,
      max: range.high,
      status: range.status,
      color: theme.palette.statusBg[range.status] || "#ffffff"
    }));
  }, [dictionaryExist]);

  const chartData = useMemo(
    () => getAQChartData(indoorWeeklyAqiData),
    [indoorWeeklyAqiData]
  );

  const handleTitleClick = () => {
    if (editing) return;
    dispatch(setSelectedOverviewTab(tabId));
    dispatch(setUserClickedOnWidgetTitle(true));
    navigate(`/buildings/iaq/${buildingId}`);
  };

  return (
    <div
      className={classes.root}
      data-testid="aq-curves">
      <div className={classes.header}>
        <Typography
          variant="h6"
          className={editing ? classes.default : classes.pointer}
          onClick={handleTitleClick}>
          Indoor Air Quality
        </Typography>
      </div>
      {requiresConfig ? (
        <Typography>Configuration is required</Typography>
      ) : isLoading || isFetching ? (
        <LoaderComponent />
      ) : (
        <div className={classes.widget}>
          {!isError && (
            <WidgetTitle
              error={isError}
              building={building}
            />
          )}
          {isError ? (
            <Error
              setRefetchAgain={refetch}
              error={isError}
            />
          ) : (
            <>
              {Boolean(chartData?.length) ? (
                <LineChart
                  data={chartData}
                  drawBgColors={true}
                  bgRanges={bgRanges}
                  customYTitle={"Air Quality Index"}
                  maintainAspectRatio={false}
                  showSecondYAxis={false}
                  enableZoom={false}
                  isWidget={true}
                />
              ) : (
                <div className={classes.noData}>
                  <ErrorIcon />
                  <Typography>No Air Quality Data</Typography>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {
      height: "100%",
      padding: "10px"
    },
    header: {
      display: "flex"
    },
    widget: {
      height: "100%",
      paddingBottom: "1rem"
    },
    noData: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: "grey"
    },
    default: {
      cursor: "default"
    },
    pointer: {
      cursor: "pointer",
      "&:hover": { color: "#0795bb" }
    }
  };
});

export default AqCurvesWidgetComponent;
