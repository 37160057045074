import { useState, useMemo } from "react";
import { makeStyles } from "tss-react/mui";
import { WidgetSettings } from "../widgets-config";
import Error from "src/shared/components/Error/Error";
import { CircularProgress } from "@mui/material";
import {
  DeviceGroup,
  getParentsForInput,
  getAqDevicesForInput
} from "../../utils/entity-selection-utils";
import useGetWidgetSettingsEntities from "src/hooks/useGetWidgetSettingsEntities";
import FormInput from "src/shared/components/form-input/form-input.component";
import FormAction from "src/shared/components/form-action/form-action";

const AqDeviceSettingsComponent = (props: WidgetSettings) => {
  const { onSave, currentProps } = props;
  const { classes } = useStyles();
  const [buildingId, setBuildingId] = useState<string>(
    currentProps?.buildingId ?? ""
  );
  const [roomId, setRoomId] = useState<string>(currentProps?.roomId ?? "");
  const [deviceId, setDeviceId] = useState<string>(
    currentProps?.deviceId ?? ""
  );
  const [deviceName, setDeviceName] = useState<string>(
    currentProps?.name ?? ""
  );

  const {
    buildings,
    deviceParents,
    devices,
    isLoading,
    isFetching,
    isError,
    refetch
  } = useGetWidgetSettingsEntities();

  const aqBuildings = buildings?.filter(building => building.indoorData);

  const roomsForInput = useMemo(
    () => getParentsForInput(deviceParents, buildingId, DeviceGroup.aq),
    [deviceParents, devices, buildingId, isLoading, isFetching]
  );

  const devicesForInput = useMemo(
    () => getAqDevicesForInput(devices, buildingId, roomId),
    [devices, buildingId, roomId, isLoading, isFetching]
  );

  const handleOnSave = () => {
    onSave({
      ...currentProps,
      buildingId,
      roomId,
      deviceId,
      deviceName
    });
  };
  const handleOnBuildingChange = (value: string) => {
    setRoomId("");
    setDeviceId("");
    setBuildingId(value);
  };
  const handleOnRoomChange = (value: string) => {
    const selectedRoom = roomsForInput?.find(room => room.id === value);
    if (selectedRoom) {
      setBuildingId(selectedRoom.building_id);
    }
    setDeviceId("");
    setRoomId(value);
  };

  const handleOnDeviceChange = (value: string) => {
    const selectedDevice = devicesForInput?.find(device => device.id === value);
    if (selectedDevice) {
      setDeviceName(selectedDevice.name);
      setRoomId(selectedDevice.room_id);
      setBuildingId(selectedDevice.building_id);
    }
    setDeviceId(value);
  };

  return (
    <div
      data-testid="aq-device-settings"
      className={classes.root}>
      {isLoading || isFetching ? (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      ) : (
        <>
          {isError ? (
            <Error
              error={isError}
              setRefetchAgain={refetch}
              showGenericMessage
            />
          ) : (
            <>
              <div>
                <FormInput
                  inputValue={buildingId}
                  label="Building"
                  array={aqBuildings}
                  setValue={handleOnBuildingChange}></FormInput>
              </div>
              <div className={classes.entitySelector}>
                <FormInput
                  inputValue={roomId}
                  label="Room"
                  array={roomsForInput}
                  setValue={handleOnRoomChange}></FormInput>
              </div>
              <div className={classes.entitySelector}>
                <FormInput
                  inputValue={deviceId}
                  label="Device"
                  array={devicesForInput}
                  setValue={handleOnDeviceChange}></FormInput>
              </div>
            </>
          )}
        </>
      )}
      <FormAction
        requiresConfig={currentProps.requiresConfig}
        saveDisabled={!deviceId}
        onSave={handleOnSave}></FormAction>
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {},
    loader: {
      display: "flex",
      justifyContent: "center"
    },
    entitySelector: {
      marginTop: "1em"
    }
  };
});

export default AqDeviceSettingsComponent;
