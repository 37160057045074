import LoaderComponent from "src/shared/components/loader/loader.component";
import ModalComponent from "src/shared/components/modal/modal.component";
import { DatePicker } from "@mui/x-date-pickers";
import { Field, Form } from "react-final-form";
import { makeStyles } from "tss-react/mui";
import { StageType } from "src/utils/client";

import {
  Typography,
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";

import { useAppDispatch } from "src/hooks/useAppDispatch";
import {
  getFilterStageModalState,
  getSelectedAhu,
  getStageToEdit
} from "src/store/hvac/selectors";
import { useAppSelector } from "src/hooks/useAppSelector";
import { handleFilterStageModal, setStageToEdit } from "src/store/hvac/reducer";
import {
  useCreateAhuStageMutation,
  useUpdateAhuStageMutation
} from "src/store/services/hvac/hvac.service";

const AddAhuStageComponent = () => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const stage = useAppSelector(getStageToEdit);
  const ahu = useAppSelector(getSelectedAhu);
  const open = useAppSelector(getFilterStageModalState);
  const [createAhuStage, { isLoading: isCreating }] =
    useCreateAhuStageMutation();

  const [updateAhuStage, { isLoading: isUpdating }] =
    useUpdateAhuStageMutation();

  const loading = isCreating || isUpdating;
  const stageTypes = Object.values(StageType);

  const onClose = () => {
    dispatch(handleFilterStageModal(false));
    dispatch(setStageToEdit(null));
  };

  const submit = stage => {
    const stageId = stage.id;
    const body = {
      id: stage.id,
      ahu_id: stage.ahu_id,
      stage_type: stage.stage_type,
      name: stage.name,
      height: Number(stage.height),
      length: Number(stage.length),
      depth: Number(stage.depth),
      size_units: "inch",
      filter: {
        replaced_date: stage.filter_replaced_date,
        name: stage.filter_name,
        filter_rating: stage.filter_filter_rating,
        number_of_filters: Number(stage.filter_number_of_filters),
        expected_start_pressure_iwg: Number(
          stage.filter_expected_start_pressure_iwg
        ),
        expected_end_pressure_iwg: Number(
          stage.filter_expected_end_pressure_iwg
        ),
        expected_lifetime_months: Number(stage.filter_expected_lifetime_months)
      }
    };

    if (Boolean(stageId)) {
      updateAhuStage({
        buildingId: ahu.building_id,
        ahuId: ahu.id,
        stageId,
        body
      }).finally(onClose);
    } else {
      createAhuStage({
        buildingId: ahu.building_id,
        ahuId: ahu.id,
        body: { ...body, ahu_id: ahu.id }
      }).finally(onClose);
    }
  };

  const validate = values => {
    let errors: any = {};
    let {
      filter_expected_lifetime_months,
      stage_type,
      name,
      filter_replaced_date
    } = values;

    if (!name) {
      errors.name = "Name is required";
    }
    if (!filter_replaced_date) {
      errors.filter_replaced_date = "Replaced date is required";
    }
    if (!stage_type) {
      errors.stage_type = "Type is required";
    }
    if (!filter_expected_lifetime_months) {
      errors.filter_expected_lifetime_months =
        "Lifetime Expectancy is required";
    }

    return errors;
  };

  const stageTypeMap = {
    pre_filter: "Pre Filter",
    secondary_filter: "Secondary Filter",
    final_filter: "Final Filter",
    hepa_filter: "HEPA Filter",
    coil: "Coil",
    other: "Other"
  };

  const initialValues = {
    id: stage?.id ?? null,
    ahu_id: stage?.ahu_id ?? null,
    stage_type: stage?.stage_type ?? null,
    name: stage?.name ?? null,
    height: stage?.height ?? null,
    length: null,
    depth: stage?.depth ?? null,
    filter_replaced_date: stage?.filter?.replaced_date ?? null,
    //@ts-ignore
    filter_name: stage?.filter?.name ?? null,
    filter_filter_rating: stage?.filter?.filter_rating ?? null,
    filter_number_of_filters: stage?.filter?.number_of_filters ?? null,
    filter_expected_start_pressure_iwg:
      stage?.filter?.expected_start_pressure_iwg ?? null,
    filter_expected_end_pressure_iwg:
      stage?.filter?.expected_end_pressure_iwg ?? null,
    filter_expected_lifetime_months:
      stage?.filter?.expected_lifetime_months ?? null
  };

  return (
    <ModalComponent
      open={open}
      onClose={() => dispatch(handleFilterStageModal(false))}>
      <div
        data-testid="add-ahu-stage"
        className={classes.root}>
        <div className={classes.title}>
          {ahu && (
            <Typography
              variant="h6"
              gutterBottom>
              {!stage ? `Add stage to ${ahu.name}` : "Edit AHU stage"}
            </Typography>
          )}
        </div>
        <div>
          <Form
            initialValues={initialValues}
            mutators={{
              setCustomValue: ([field, value], state, utils) => {
                utils.changeValue(state, field, () => value);
              }
            }}
            onSubmit={submit}
            validate={validate}
            render={({ handleSubmit, hasValidationErrors, form }) => {
              return (
                <form
                  onSubmit={handleSubmit}
                  data-testid="form">
                  {loading && <LoaderComponent />}
                  <div>
                    <div>
                      <Field name="stage_type">
                        {({ input, meta }) => (
                          <FormControl fullWidth>
                            <InputLabel>Stage type*</InputLabel>
                            <Select
                              label="Stage type*"
                              className={classes.textInput}
                              error={Boolean(meta.touched && meta.error)}
                              inputProps={input}>
                              {stageTypes.map((stageType: StageType, index) => (
                                <MenuItem
                                  key={`stagetype-${index}`}
                                  value={stageType}>
                                  {stageTypeMap[stageType]}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      </Field>
                    </div>
                    <div>
                      <Field name="name">
                        {({ input, meta }) => (
                          <TextField
                            placeholder={null}
                            label="Name*"
                            className={classes.textInput}
                            error={Boolean(meta.touched) && Boolean(meta.error)}
                            helperText={meta.touched && meta.error}
                            InputProps={input}></TextField>
                        )}
                      </Field>
                    </div>
                    <Grid
                      container
                      columnSpacing={1.5}>
                      <Grid
                        item
                        xs={12}
                        md={6}>
                        <Field name="filter_replaced_date">
                          {({ input, meta }) => (
                            <DatePicker
                              label="Replaced date*"
                              value={
                                form.getState().values.filter_replaced_date ||
                                null
                              }
                              onChange={newDate => {
                                form.mutators.setCustomValue(
                                  "filter_replaced_date",
                                  newDate
                                );
                              }}
                              renderInput={params => (
                                <TextField
                                  placeholder={null}
                                  label="Replaced date*"
                                  className={classes.textInput}
                                  error={
                                    Boolean(meta.touched) && Boolean(meta.error)
                                  }
                                  helperText={meta.touched && meta.error}
                                  InputProps={input}
                                  {...params}></TextField>
                              )}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}>
                        <Field name="filter_number_of_filters">
                          {({ input, meta }) => (
                            <TextField
                              placeholder={null}
                              label="Number of filters"
                              type="number"
                              className={classes.textInput}
                              error={
                                Boolean(meta.touched) && Boolean(meta.error)
                              }
                              helperText={meta.touched && meta.error}
                              InputProps={input}></TextField>
                          )}
                        </Field>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      columnSpacing={1.5}>
                      <Grid
                        item
                        xs={12}
                        md={6}>
                        <Field name="filter_expected_lifetime_months">
                          {({ input, meta }) => {
                            return (
                              <TextField
                                placeholder={null}
                                label="Lifetime expectancy in months*"
                                type="number"
                                className={classes.textInput}
                                error={
                                  Boolean(meta.touched) && Boolean(meta.error)
                                }
                                inputProps={{ min: 1 }}
                                helperText={meta.touched && meta.error}
                                InputProps={input}></TextField>
                            );
                          }}
                        </Field>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}>
                        <Field name="filter_filter_rating">
                          {({ input, meta }) => (
                            <TextField
                              placeholder={null}
                              label="Filter rating"
                              className={classes.textInput}
                              error={
                                Boolean(meta.touched) && Boolean(meta.error)
                              }
                              helperText={meta.touched && meta.error}
                              InputProps={input}></TextField>
                          )}
                        </Field>
                      </Grid>
                    </Grid>
                    <div>
                      <div>
                        <Typography variant="subtitle1">
                          Dimensions (inches)
                        </Typography>
                        <Grid
                          container
                          columnSpacing={1.5}>
                          <Grid
                            item
                            xs={12}
                            md={4}>
                            <Field name="height">
                              {({ input, meta }) => (
                                <TextField
                                  placeholder={null}
                                  label="Height"
                                  type="number"
                                  className={classes.textInput}
                                  error={
                                    Boolean(meta.touched) && Boolean(meta.error)
                                  }
                                  helperText={meta.touched && meta.error}
                                  InputProps={input}></TextField>
                              )}
                            </Field>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={4}>
                            <Field name="length">
                              {({ input, meta }) => (
                                <TextField
                                  placeholder={null}
                                  label="Length"
                                  type="number"
                                  className={classes.textInput}
                                  error={
                                    Boolean(meta.touched) && Boolean(meta.error)
                                  }
                                  helperText={meta.touched && meta.error}
                                  InputProps={input}></TextField>
                              )}
                            </Field>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={4}>
                            <Field name="depth">
                              {({ input, meta }) => (
                                <TextField
                                  placeholder={null}
                                  label="Depth"
                                  type="number"
                                  className={classes.textInput}
                                  error={
                                    Boolean(meta.touched) && Boolean(meta.error)
                                  }
                                  helperText={meta.touched && meta.error}
                                  InputProps={input}></TextField>
                              )}
                            </Field>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          columnSpacing={1.5}>
                          <Grid
                            item
                            xs={12}
                            md={6}>
                            <Field name="filter_expected_start_pressure_iwg">
                              {({ input, meta }) => (
                                <TextField
                                  placeholder={null}
                                  label="Expected start pressure in IWG"
                                  type="number"
                                  className={classes.textInput}
                                  error={
                                    Boolean(meta.touched) && Boolean(meta.error)
                                  }
                                  helperText={meta.touched && meta.error}
                                  InputProps={input}></TextField>
                              )}
                            </Field>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}>
                            <Field name="filter_expected_end_pressure_iwg">
                              {({ input, meta }) => (
                                <TextField
                                  placeholder={null}
                                  label="Expected end pressure in IWG"
                                  type="number"
                                  className={classes.textInput}
                                  error={
                                    Boolean(meta.touched) && Boolean(meta.error)
                                  }
                                  helperText={meta.touched && meta.error}
                                  InputProps={input}></TextField>
                              )}
                            </Field>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </div>
                  <div className={classes.actions}>
                    <Button
                      variant="contained"
                      type="submit"
                      className={classes.saveBtn}
                      disabled={hasValidationErrors || loading}
                      data-testid="save-stage-button">
                      Save stage
                    </Button>
                    <Button
                      type="button"
                      variant="text"
                      color="inherit"
                      onClick={() => onClose()}>
                      Cancel
                    </Button>
                  </div>
                </form>
              );
            }}
          />
        </div>
      </div>
    </ModalComponent>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {
      minWidth: 250,
      maxHeight: "80vh",
      overflow: "auto",
      backgroundColor: "transparent"
    },
    title: {
      marginBottom: "1.5em"
    },
    loader: {
      top: 0,
      left: 0
    },
    saveBtn: {
      width: 120,
      marginRight: "1em"
    },
    actions: {
      marginTop: "1em"
    },
    textInput: {
      width: "100%",
      marginBottom: "0.5em"
    }
  };
});

export default AddAhuStageComponent;
