/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Ahu } from '../models/Ahu';
import type { AhuDetails } from '../models/AhuDetails';
import type { AhuNew } from '../models/AhuNew';
import type { AhuUpdates } from '../models/AhuUpdates';
import type { HvacSensor } from '../models/HvacSensor';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AhusService {
  /**
   * Get Ahus For Building
   * Returns possibly empty list of AHUs for the building, or `null` for invalid building_id.
   * @param buildingId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getAhusForBuilding(
    buildingId: string,
  ): CancelablePromise<(Array<Ahu> | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/ahus/',
      path: {
        'building_id': buildingId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Create Ahu
   * @param buildingId
   * @param requestBody
   * @returns Ahu Successful Response
   * @throws ApiError
   */
  public static createAhu(
    buildingId: string,
    requestBody: AhuNew,
  ): CancelablePromise<Ahu> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/buildings/{building_id}/ahus/',
      path: {
        'building_id': buildingId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Database Error`,
        409: `Name is Taken`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Ahu
   * @param buildingId
   * @param ahuId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getAhu(
    buildingId: string,
    ahuId: string,
  ): CancelablePromise<(Ahu | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/ahus/{ahu_id}',
      path: {
        'building_id': buildingId,
        'ahu_id': ahuId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Update Ahu
   * Update basic fields of an Ahu. To update complex fields like stages,
   * fans, etc. use dedicated endpoints for those entities.
   * @param buildingId
   * @param ahuId
   * @param requestBody
   * @returns Ahu Successful Response
   * @throws ApiError
   */
  public static updateAhu(
    buildingId: string,
    ahuId: string,
    requestBody: AhuUpdates,
  ): CancelablePromise<Ahu> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/buildings/{building_id}/ahus/{ahu_id}',
      path: {
        'building_id': buildingId,
        'ahu_id': ahuId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Database Error`,
        404: `Invalid Object Id`,
        409: `Name Conflict`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Delete Ahu
   * @param buildingId
   * @param ahuId
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public static deleteAhu(
    buildingId: string,
    ahuId: string,
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/buildings/{building_id}/ahus/{ahu_id}',
      path: {
        'building_id': buildingId,
        'ahu_id': ahuId,
      },
      errors: {
        404: `Invalid Object Id`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Ahus With Filterload
   * Get the stages for a particular AHU along with filter load data.
   * Returns an object keyed by stage_id's.
   *
   * Essentially a replacement of:
   * GET https://dev.qd.i-qlair.com/v2/hvac/statuses?facilityId=123
   * from old backend.
   * @param buildingId
   * @returns AhuDetails Successful Response
   * @throws ApiError
   */
  public static getAhusWithFilterload(
    buildingId: string,
  ): CancelablePromise<Record<string, AhuDetails>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/ahus/details/',
      path: {
        'building_id': buildingId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Sensors
   * Returns the sensors attached to a particular AHU, keyed by stage_id.
   * @param buildingId
   * @param ahuId
   * @param includeInactive
   * @returns HvacSensor Successful Response
   * @throws ApiError
   */
  public static getSensors(
    buildingId: string,
    ahuId: string,
    includeInactive: boolean = false,
  ): CancelablePromise<Record<string, Array<HvacSensor>>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/ahus/{ahu_id}/sensors/',
      path: {
        'building_id': buildingId,
        'ahu_id': ahuId,
      },
      query: {
        'include_inactive': includeInactive,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
