import {
  BrowserRouter,
  Routes as Router,
  Route,
  Navigate
} from "react-router-dom";
import PrivateRoute from "./PrivateRoutes";
import { Overview } from "src/sections/overview/view/overview.component";
import BuildingsMainScreenComponent from "src/sections/buildings/buildings-main-screen/buildings-main-screen.component";
import BuildingDetails from "src/sections/building-details/view/building-details.component";
import Reports from "src/sections/buildings/full-report-form/full-report-form.component";
import AhuDetails from "src/sections/hvac/air-handling-units/ahu-details/view/ahu-details.component";
import KettleDetails from "src/sections/kettle/kettle.details";
import { SettingsComponent } from "src/sections/settings/view/settings.component";
import Notifications from "src/sections/notifications/view/notifications.component";
import UserAdministrationPortal from "src/sections/user-administration-portal";
import { AirHandlingUnits } from "src/sections/hvac/air-handling-units/view/air-handling-units.component";
import Kettles from "src/sections/kettle/kettles";
import RoomOutdoorPollutantsComponent from "src/sections/indoor-air-quality/rooms/room-outdoor-pollutants/room-outdoor-pollutants.component";
import Rooms from "src/sections/indoor-air-quality/rooms/view/rooms.component";
import Devices from "src/sections/indoor-air-quality/devices/view/devices.component";
import DeviceDetails from "src/sections/indoor-air-quality/devices/device-details/view/device-details.component";
import { OverviewProvider } from "src/sections/overview/Context/OverviewContext";
import Login from "src/sections/session/login/login.component";
import RecoverPasswordComponent from "src/sections/session/recover-password/recover-password.component";
import RecoverCheckCodeComponent from "src/sections/session/recover-check-code/recover-check-code.component";
import InvitationComponent from "src/sections/session/user-invitation/invitation.component";
import { TradefairDashboard } from "src/sections/tradefair-dashboard/view/tradefair-dashboard.component";
import RouteNoMatchComponent from "src/shared/components/route-no-match/route-no-match.component";
import PaintBoothDetails from "src/components/PaintBoothDetails";
import Booths from "src/sections/booth/booths";

const Routes = () => {
  return (
    <BrowserRouter>
      <Router>
        <Route element={<PrivateRoute />}>
          <Route
            path="/dashboard/tab/:tabId"
            element={
              <OverviewProvider>
                <Overview />
              </OverviewProvider>
            }
          />
          <Route
            path="/buildings/:category"
            element={<BuildingsMainScreenComponent />}>
            <Route
              path="kettle"
              element={<Kettles />}></Route>
            <Route
              path="hvac"
              element={<AirHandlingUnits />}></Route>
            <Route
              path="iaq"
              element={<Devices />}></Route>
            <Route
              path="booth"
              element={<Booths />}></Route>
          </Route>

          <Route
            path="/buildings/hvac/:buildingId"
            element={
              <>
                <BuildingDetails />
                <AirHandlingUnits />
              </>
            }></Route>
          <Route
            path="/buildings/kettle/:buildingId"
            element={
              <>
                <BuildingDetails />
                <Kettles />
              </>
            }></Route>
          <Route
            path="/buildings/booth/:buildingId"
            element={
              <>
                <BuildingDetails />
                <Booths />
              </>
            }></Route>
          <Route
            path="/buildings/iaq/:buildingId"
            element={
              <>
                <BuildingDetails />
                <RoomOutdoorPollutantsComponent />
                <Rooms />
              </>
            }></Route>
          <Route
            path="/buildings/iaq/:buildingId/rooms/:roomId"
            element={
              <>
                <BuildingDetails /> <Devices />
              </>
            }></Route>
          <Route
            path="/buildings/iaq/:buildingId/rooms/:roomId/devices/:deviceId"
            element={<DeviceDetails />}
          />
          <Route
            path="/reports/:category"
            element={<Reports />}></Route>

          <Route
            path="/buildings/hvac/:buildingId/:ahuId"
            element={<AhuDetails />}
          />
          <Route
            path="/buildings/kettle/:buildingId/:kettleId"
            element={<KettleDetails />}
          />
          <Route
            path="/buildings/booth/:buildingId/:boothId"
            element={<PaintBoothDetails />}
          />

          <Route
            path="/settings"
            element={<SettingsComponent />}
          />
          <Route
            path="/notifications"
            element={<Notifications />}
          />
          <Route
            path="/user-administration-portal"
            element={<UserAdministrationPortal />}
          />
          <Route
            path="/tv/:buildingId"
            element={<TradefairDashboard />}
          />
          <Route
            path="*"
            element={
              <Navigate
                to="/dashboard/tab/0"
                replace></Navigate>
            }
          />
        </Route>
        <Route
          path="/login"
          element={<Login />}
        />
        <Route
          path="/recover"
          element={<RecoverPasswordComponent />}
        />
        <Route
          path="/check-code"
          element={<RecoverCheckCodeComponent />}
        />
        <Route
          path="/invitation"
          element={<InvitationComponent />}
        />
        <Route
          path="/create-account"
          element={<RecoverPasswordComponent createAccount />}
        />

        <Route
          path="*"
          element={<RouteNoMatchComponent />}
        />
      </Router>
    </BrowserRouter>
  );
};

export default Routes;
