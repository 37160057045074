import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { NotificationState } from "./types";
import { AlertReturn, NotificationDefinitionReturn } from "src/utils/client";

const initialState: NotificationState = {
  drawerOpen: false,
  alertsDrawerOpen: false,
  alertModalOpen: false,
  selectedNotification: null,
  selectedNotificationId: null,
  alertModalData: null
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.drawerOpen = action.payload;
    },
    setAlertsDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.alertsDrawerOpen = action.payload;
    },
    setAlertModalOpen: (state, action: PayloadAction<boolean>) => {
      state.alertModalOpen = action.payload;
    },
    setSelectedNotification: (
      state,
      action: PayloadAction<NotificationDefinitionReturn>
    ) => {
      state.selectedNotification = action.payload;
    },
    setSelectedNotificationId: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.selectedNotificationId = action.payload;
    },
    setAlertModalData: (state, action: PayloadAction<AlertReturn>) => {
      state.alertModalData = action.payload;
    }
  }
});

export const {
  setDrawerOpen,
  setSelectedNotification,
  setAlertModalOpen,
  setSelectedNotificationId,
  setAlertsDrawerOpen,
  setAlertModalData
} = notificationSlice.actions;

export const { reducer: notificationReducer } = notificationSlice;
