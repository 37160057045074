import { Button, Link, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { handleAHUModal } from "src/store/hvac/reducer";

const AHUEmptyComponent = () => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  return (
    <div
      className={classes.root}
      data-testid="ahu-empty">
      <Typography
        variant="h5"
        gutterBottom>
        No Air Handling Units Created
      </Typography>
      <div>
        <br />
        <Button
          onClick={() => dispatch(handleAHUModal(true))}
          variant="contained"
          data-testid="add-first-unit-button">
          Add First Unit
        </Button>
      </div>
      <br />
      <Link href="mailto:support@i-qlair.com?subject=Help with HVAC Devices">
        Request support
      </Link>
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {
      textAlign: "center" as const
    },
    paragraph: {
      textAlign: "justify" as const
    }
  };
});

export default AHUEmptyComponent;
