import get from "lodash/get";
import classnames from "classnames";
import SignalWifiOffIcon from "@mui/icons-material/SignalWifiOff";
import { makeStyles } from "tss-react/mui";
import { getAQIStatus, getAQValueFromDevice } from "src/utils/buildings.util";
import { Device } from "src/shared/types/building";
import { nanoid } from "nanoid";
import { Button, Grid, Hidden, Typography } from "@mui/material";

import { OUTDATED } from "src/shared/constants/building-status";
import useGetPolutantDictionary from "src/hooks/useGetPollutantDictionary";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { handleDeviceModal, setIsEditingDevice } from "src/store/hvac/reducer";
import AirQualityIndicator from "src/sections/buildings/air-quality-indicator/air-quality-indicator";
import useGetAppBreakPoints from "src/hooks/useGetAppBreakPoints";
import { useStyles as useStatusColors } from "src/shared/styles/statusColors";
import { NA } from "src/shared/constants/generals";
import { useNavigate, useParams } from "react-router-dom";
type Props = {
  device: Device;
};

const DeviceRowComponent = ({ device }: Props) => {
  const { buildingId, roomId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { classes: classesStatusColor } = useStatusColors();
  const { pollInfoDict } = useGetPolutantDictionary();
  const { isSm } = useGetAppBreakPoints();
  const status = device.isOutdated
    ? OUTDATED
    : getAQIStatus(get(device, "latest_measurement.data.aqi.value"));
  let metrics = ["cotwo", "voc", "pmtwo", "pmten"];

  const aqiValue = getAQValueFromDevice(
    device,
    "latest_measurement.data.aqi.value"
  );

  const openDeviceDetailsModal = event => {
    event.stopPropagation();
    dispatch(setIsEditingDevice(true));
    dispatch(handleDeviceModal(true));
  };

  const handleOnRowClick = () => {
    navigate(
      `/buildings/iaq/${buildingId}/rooms/${roomId}/devices/${device.id}`
    );
  };
  return (
    <Grid
      data-testid="device-row"
      container
      className={classnames(classes.root)}
      key={`device_row_${device.name}`}
      onClick={handleOnRowClick}>
      <Grid
        xs={1}
        sm={1}
        item
        className={classes.statusContainer}>
        <div
          className={classnames(
            classes.statusIndicator,
            classesStatusColor[`back${status.replace(/ /g, "_")}`]
          )}></div>
      </Grid>
      <Grid
        item
        xs={10}
        sm={2}
        md={2}
        lg={2}>
        <Typography
          className={classnames(
            classes.column,
            classes.firstColumn,
            classes.name
          )}>
          {device.name}{" "}
          {device.isOutdated && (
            <SignalWifiOffIcon
              data-testid="wifi-off-icon"
              className={classes.offline}
            />
          )}
        </Typography>
      </Grid>
      <Hidden smUp>
        <Grid
          item
          xs={1}></Grid>
      </Hidden>
      <Grid
        item
        lg={6}
        md={6}
        sm={6}
        xs={10}
        className={classes.pollutants}>
        <AirQualityIndicator
          value={aqiValue}
          showLabel={isSm}
          label="AQI"
        />

        {metrics?.map(metric => {
          const qty: number = get(
            device,
            `latest_measurement.data.${metric}.value`
          );
          const shortName = pollInfoDict[metric].short_name;

          return (
            <AirQualityIndicator
              key={nanoid()}
              pollutantType={metric}
              value={qty ? qty?.toFixed(0) : NA}
              showLabel={isSm}
              label={shortName}></AirQualityIndicator>
          );
        })}
      </Grid>
      <Grid
        item
        md={2}
        sm={2}
        xs={4}
        className={classes.centered}>
        <Button
          data-testid="edit-device-btn"
          variant="text"
          onClick={event => openDeviceDetailsModal(event)}>
          Edit Device
        </Button>
      </Grid>
    </Grid>
  );
};

export const useStyles = makeStyles()(theme => {
  return {
    root: {
      paddingBottom: "0.5em",
      paddingTop: "0.5em",
      paddingLeft: "1em",
      transition: "all 250ms",
      backgroundColor: "white",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: theme.palette.grey["200"]
      }
    },
    column: {
      whiteSpace: "nowrap" as const,
      textOverflow: "ellipsis",
      overflow: "hidden",
      padding: 8,
      paddingLeft: 5,
      paddingBottom: 0
    },
    name: {
      cursor: "pointer"
    },
    firstColumn: {
      paddingLeft: "5% !important"
    },
    actions: {
      paddingTop: 8,
      textAlign: "center" as const
    },
    centered: {
      textAlign: "center" as const
    },
    selected: {
      backgroundColor: theme.palette.grey["300"]
    },
    statusContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    statusIndicator: {
      width: 12,
      height: 12,
      borderRadius: "50%"
    },
    offline: {
      color: "#d32f2f",
      fontSize: "medium",
      verticalAlign: "top"
    },
    pollutant: {
      textAlign: "center" as const,
      border: "1px solid #E9EAEA",
      borderRadius: 4,
      padding: 4,
      minWidth: 50
    },
    pollutants: {
      display: "flex",
      justifyContent: "space-between" as const,
      overflow: "auto",
      alignItems: "center"
    }
  };
});

export default DeviceRowComponent;
