import MapIcon from "@mui/icons-material/Map";
import ListIcon from "@mui/icons-material/List";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import AddIcon from "@mui/icons-material/Add";
import classNames from "classnames";
import { makeStyles } from "tss-react/mui";
import { Box, Button, useTheme, Fab } from "@mui/material";
import useGetBuildingView from "../hooks/useGetBuildingView";
import {
  handleBuildingModal,
  setBuildingView
} from "src/store/buildings/reducer";
import { Views } from "src/shared/constants/generals";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import useGetAppBreakPoints from "src/hooks/useGetAppBreakPoints";

type Props = {
  orientation?: "vertical" | "horizontal";
};

const BuildingsToolbarComponent = ({ orientation = "horizontal" }: Props) => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const { isTablet } = useGetAppBreakPoints();
  const { isMapView, isChartView, isListView } = useGetBuildingView();
  const isVerticalOrientation = orientation === "vertical";

  const setModalOpen = () => {
    dispatch(handleBuildingModal(true));
  };

  const handleChangeView = (newView: "list" | "map" | "chart") => {
    dispatch(setBuildingView(newView));
  };

  return (
    <>
      <Box
        data-testid="buildings-toolbar"
        className={classNames(classes.root, classes[orientation])}
        sx={{
          justifyContent: isMapView ? "space-between" : "end",
          marginLeft: isVerticalOrientation ? 0 : "1em"
        }}>
        <div className={classes.viewButtons}>
          {!isTablet && (
            <Button
              data-testid="map-btn"
              variant="contained"
              className={classes.viewBtn}
              onClick={() => handleChangeView(Views.MAP)}
              sx={{
                color: getColor(isMapView)
              }}>
              <MapIcon />
              <span>Map</span>
            </Button>
          )}
          <Button
            data-testid="list-btn"
            onClick={() => handleChangeView(Views.LIST)}
            variant="contained"
            className={classes.viewBtn}
            sx={{
              color: getColor(isListView)
            }}>
            <ListIcon />
            <span>List</span>
          </Button>
          <Button
            data-testid="grid-btn"
            onClick={() => handleChangeView(Views.CHART)}
            variant="contained"
            className={classes.viewBtn}
            sx={{
              color: getColor(isChartView)
            }}>
            <GridViewOutlinedIcon />
            <span>Chart</span>
          </Button>
        </div>
        {!isVerticalOrientation && !isTablet && (
          <Button
            disableElevation
            variant="contained"
            className={classes.addBtn}
            onClick={setModalOpen}
            data-testid="add-building-btn">
            Add Building
          </Button>
        )}
      </Box>
      {(isVerticalOrientation || isTablet) && (
        <Fab
          data-testid="add"
          onClick={setModalOpen}
          color="primary"
          aria-label="add"
          className={classes.fab}>
          <AddIcon />
        </Fab>
      )}
    </>
  );
};

export const useStyles = makeStyles()(theme => {
  return {
    root: {
      display: "flex"
    },
    viewBtn: {
      backgroundColor: "white",
      marginRight: "0.5em",
      "& svg": {
        fontSize: "initial",
        marginRight: "0.5em",
        "@media (max-width: 1200px)": {}
      },
      "@media (max-width: 1200px)": {
        width: 48,
        height: 48,
        minWidth: 48,
        marginBottom: "0.5em",
        "& span": {
          display: "none"
        },
        "& svg": {
          fontSize: "16pt",
          marginRight: 0
        }
      }
    },
    heatpointsSwitch: {
      paddingLeft: 8,
      paddingRight: 8,
      marginRight: 8,
      background: "white",
      borderRadius: theme.shape.borderRadius,
      height: 36.5,
      display: "inline-block",
      "@media (max-width: 1200px)": {
        width: 48,
        height: 48,
        paddingTop: 4,
        marginBottom: "0.5em"
      }
    },
    addBtn: {
      display: "block",
      maxHeight: 48,
      minWidth: 152
    },
    horizontal: {},
    vertical: {
      position: "absolute" as const,
      right: 16,
      flexDirection: "column" as const
    },
    viewButtons: {
      display: "flex",
      flexDirection: "inherit" as const
    },
    fab: {
      position: "fixed" as const,
      bottom: 20,
      right: 20,
      zIndex: 9
    }
  };
});

export default BuildingsToolbarComponent;

const getColor = (isViewActive: boolean) => {
  const theme = useTheme();
  return isViewActive
    ? theme.palette.primary[500] || theme.palette.primary["main"]
    : "black";
};
