/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AlertDeviceType } from './AlertDeviceType';
import type { AlertKey } from './AlertKey';
import type { qlairlib__contexts__alerts_and_notifications__models__LegacyFields } from './qlairlib__contexts__alerts_and_notifications__models__LegacyFields';
/**
 * Notification entity, with 'id' field
 */
export type NotificationDefinition = {
  title: string;
  company_id: string;
  building_id: string;
  alert_device_type: AlertDeviceType;
  alert_key: AlertKey;
  /**
   * This property means `PressureMeasurement` satisfies the same interface as the `Measurement`
   * model (having `units` and `value` attributes.)
   */
  readonly threshold: number;
  threshold_direction?: NotificationDefinition.threshold_direction;
  time_required?: number;
  device_id: string;
  is_active?: boolean;
  text_message_enabled?: boolean;
  email_enabled?: boolean;
  in_alert?: boolean;
  last_alert_id?: (string | null);
  last_alert_created_at?: (string | null);
  last_alert_value?: (number | null);
  last_alert_value_time?: (string | null);
  legacy?: (qlairlib__contexts__alerts_and_notifications__models__LegacyFields | null);
  id: string;
  created_user_id: string;
};
export namespace NotificationDefinition {
  export enum threshold_direction {
    GT = 'gt',
    LT = 'lt',
    EQ = 'eq',
  }
}

