import { makeStyles } from "tss-react/mui";
export const useStyles = makeStyles()(() => {
  return {
    root: {
      padding: 16,
      marginBottom: 8
    },
    outPoll: {
      textAlign: "center" as const,
      whiteSpace: "nowrap" as const,
      textOverflow: "ellipsis",
      overflow: "hidden"
    },

    address: {
      overflow: "hidden",
      whiteSpace: "nowrap" as const,
      textOverflow: "ellipsis",
      "& svg": {
        marginRight: "0.5em",
        marginLeft: "0.75em",
        fontSize: "18px",
        color: "#273B4A",
        opacity: 0.5,
        position: "relative" as const,
        top: 4
      }
    },
    wrapper: {
      display: "flex",
      width: "100%",
      overflow: "auto"
    },
    header: {
      display: "flex"
    }
  };
});
