import { createContext, useState, useCallback } from "react";
import { OverviewContextProps, OverviewProviderProps } from "./types";

export const OverviewContext = createContext<OverviewContextProps>(null!);

export const OverviewProvider = ({ children }: OverviewProviderProps) => {
  const [isWidgetModalOpen, setIsWidgetModalOpen] = useState(false);
  const [isPageModalOpen, setIsPageModalOpen] = useState(false);
  const [isConfigurationModalOpen, setIsConfigurationModalOpen] =
    useState(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [editingTabIndex, setEditingTabIndex] = useState<number | null>(null);
  const openConfigurationModal = useCallback(
    () => setIsConfigurationModalOpen(true),
    []
  );
  const closeConfigurationModal = useCallback(
    () => setIsConfigurationModalOpen(false),
    []
  );

  const openWidgetModal = useCallback(() => setIsWidgetModalOpen(true), []);
  const closeWidgetModal = useCallback(() => setIsWidgetModalOpen(false), []);

  const openPageModal = useCallback(() => setIsPageModalOpen(true), []);
  const closePageModal = useCallback(() => setIsPageModalOpen(false), []);

  return (
    <OverviewContext.Provider
      value={{
        editing,
        editingTabIndex,
        isConfigurationModalOpen,
        isWidgetModalOpen,
        isPageModalOpen,
        openConfigurationModal,
        closeConfigurationModal,
        openWidgetModal,
        closeWidgetModal,
        openPageModal,
        closePageModal,
        setEditing,
        setEditingTabIndex
      }}>
      {children}
    </OverviewContext.Provider>
  );
};
