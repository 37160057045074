import { Tooltip, Typography, Button } from "@mui/material";
import { Box } from "@mui/system";
import {
  MarkChatRead,
  ReportProblem,
  CheckCircle,
  InfoOutlined
} from "@mui/icons-material";
import moment, { Moment } from "moment";

import classNames from "classnames";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import {
  setAlertModalOpen,
  setAlertModalData
} from "src/store/notification/reducer";
import { useAcknowledgeAlertMutation } from "src/store/services/notifications/notificationsService";

import {
  AcknowledgementStatus,
  AlertReturn,
  AqDeviceWithContext,
  HvacDeviceWithContext,
  KettleDeviceWithContext
} from "src/utils/client";
import { useAppSelector } from "src/hooks/useAppSelector";
import { getUserId } from "src/store/auth/selectors";
import { useStyles } from "./alert-row.style";

type Props = {
  alert?: AlertReturn;
  forWidget?: boolean;
};

const directionList = {
  lt: "< LESS THAN",
  gt: "> GREATER THAN"
};

type DeviceTypeLabels = {
  labelOne: { label: string; value: string };
  labelTwo?: { label: string; value?: string };
};

function determineDeviceTypeLabels(alert: AlertReturn): DeviceTypeLabels {
  switch (alert?.device_with_context?.device_type) {
    case "hvac":
      return {
        labelOne: {
          label: "AHU: ",
          value: (alert?.device_with_context as HvacDeviceWithContext).ahu?.name
        },
        labelTwo: {
          label: "FILTER: ",
          value: (alert?.device_with_context as HvacDeviceWithContext)?.stage
            ?.name
        }
      };
    case "kettle":
      return {
        labelOne: {
          label: "KETTLE: ",
          value: (alert?.device_with_context as KettleDeviceWithContext)?.kettle
            ?.name
        }
      };
    case "aq":
      return {
        labelOne: {
          label: "ROOM: ",
          value: (alert?.device_with_context as AqDeviceWithContext).room?.name
        },
        labelTwo: {
          label: "FLOOR: ",
          value: (alert?.device_with_context as AqDeviceWithContext).room?.floor
        }
      };
  }
}

const AlertRowComponent = ({ alert }: Props) => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector(getUserId);
  const { classes } = useStyles();

  const inAlert: boolean = alert?.active ?? false;
  const alertValue: number = alert?.user_pref_alert_value ?? alert?.alert_value;
  const alertTime: Moment = moment.utc(alert?.created_at).local();
  const button_label: string = !alert?.is_read ? "ACKNOWLEDGE" : "ACKNOWLEDGED";
  const alert_color: string = inAlert ? "red" : "green";
  const buildingName: string = alert?.building_name;
  const deviceTypeLabels = determineDeviceTypeLabels(alert);

  const [acknowledgeAlert] = useAcknowledgeAlertMutation();

  const rootClass = classNames(
    classes?.root,
    alert?.is_read ? classes.seen : classes.unseen,
    alert?.high_priority && classes.highPriorityColor
  );

  const markAsRead = (e: any) => {
    e.stopPropagation();
    acknowledgeAlert({
      notificationId: alert?.notification?.id,
      alertId: alert?.id,
      body: {
        acknowledged_by_user_id: userId,
        acknowledgment_state: AcknowledgementStatus.ACTION_NEEDED,
        acknowledgement_notes: ""
      }
    });
  };

  const openDetailsModal = () => {
    dispatch(setAlertModalData(alert));
    dispatch(setAlertModalOpen(true));
  };

  const HandleIconView = () => {
    if (inAlert)
      return (
        <ReportProblem
          data-testid="report-icon"
          sx={{ color: "red" }}
        />
      );

    return (
      <CheckCircle
        data-testid="check-circle"
        sx={{ color: "green" }}
      />
    );
  };

  return (
    <div
      data-testid="alert-row"
      className={rootClass}>
      <div
        data-testid="open-details-modal"
        className={classes.alertContent}
        onClick={openDetailsModal}>
        <div className={classes.alertIcons}>
          <Typography variant="h6">{alert?.notification?.title}</Typography>
          <HandleIconView />
        </div>

        <Tooltip
          title={<div style={{ whiteSpace: "pre-line" }}>{alert?.content}</div>}
          placement="bottom-start">
          <Typography className={classes.subtitle}>
            <span>
              {alert?.header} <InfoOutlined sx={{ fontSize: 13 }} />
            </span>
          </Typography>
        </Tooltip>
        <div style={{ marginTop: "20px", marginBottom: "20px" }}>
          <Typography>
            <span className={classes.displayRow}>BUILDING:</span>
            {buildingName}
          </Typography>
          <Typography data-testid="label-one-label">
            <span className={classes.displayRow}>
              {deviceTypeLabels?.labelOne?.label}
            </span>
            {deviceTypeLabels?.labelOne?.value}
          </Typography>
          {deviceTypeLabels?.labelTwo ? (
            <Typography data-testid="label-two-label">
              <span className={classes.displayRow}>
                {deviceTypeLabels?.labelTwo?.label}
              </span>
              {deviceTypeLabels?.labelTwo?.value}
            </Typography>
          ) : (
            <Typography />
          )}
        </div>

        <Box className={classes.numbers}>
          <div className={classes.numberQty}>
            <Typography className={classes.qtyLabel}>
              LATEST MEASUREMENT
            </Typography>

            <Typography
              variant="h5"
              className={classes.qty}
              style={{ color: alert_color }}>
              {alertValue}
            </Typography>
          </div>
          <div className={classes.numberQty}>
            <Typography />
            <Typography className={classes.qtyLabel}>
              {directionList[alert?.notification?.threshold_direction]}{" "}
              <br></br>
              ALERT THRESHOLD
            </Typography>
            <Typography variant="h5">{alert?.user_pref_threshold}</Typography>
          </div>
        </Box>

        {alert?.acknowledgment && (
          <Typography
            data-testid="acknowledgment-text"
            className={classes.acknowledge}>
            <span>Acknowledged by</span>
            {alert.acknowledgment.acknowledged_by_email} at{" "}
            {moment(alert.acknowledgment.time).format("lll")}
          </Typography>
        )}

        <Typography className={classes.time}>
          {" "}
          Alert Generated&nbsp;&#8203;
          {`${alertTime.from(moment())} - `}
          {alertTime.format("lll")}
        </Typography>

        <div className={classes.ackButtonDiv}>
          <Button
            size="small"
            data-testid="mark-as-read"
            onClick={markAsRead}
            disabled={alert?.is_read}>
            <MarkChatRead
              sx={{ mr: "5px" }}
              fontSize="small"
            />
            {button_label}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AlertRowComponent;
