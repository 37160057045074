import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FormattedAhu } from "src/shared/types/ahu";
import { AggGranularity, Room, Stage } from "src/utils/client";
import moment from "moment";
import {
  AHU_VIEW_OPTION_LOAD,
  AHU_VIEW_OPTION_PRESSURE,
  AHU_VIEW_OPTION_REMAINING
} from "src/shared/constants/ahu-status";

type InitialState = {
  tableExpanded: boolean;
  ahuModalOpen: boolean;
  isEditingAhu: boolean;
  downloadModalOpen: boolean;
  downloadModalEntity:
    | "building"
    | "ahu"
    | "booth"
    | "stage"
    | "kettle"
    | "booth";
  downloadModalEntityId: string;
  selectedAHU: FormattedAhu | null;
  searchHvacTerm: string;
  airQualitySearchTerm: string;
  deviceSearchTerm: string;
  expandedAhusById: string[];
  filterStageModalOpen: boolean;
  view: "list" | "chart";
  roomModalOpen: boolean;
  isEditingRoom: boolean;
  selectedRoom: Room | null;
  deviceModalOpen: boolean;
  isEditingDevice: boolean;
  selectedDeviceMetric: string;
  deviceGraphStartDate: Date;
  deviceGraphEndData: Date;
  deviceGraphGranuality: AggGranularity.DAY | AggGranularity.HOUR;
  selectedAhuTableOptions: string[];
  ahuDetailsStartDate: Date;
  ahuDetailsEndDate: Date;
  selectedAhuDetailsStageId: string;
  stageToEdit: Stage | null;
  userClickedOnWidgetTitle: boolean;
  granularity: AggGranularity;
};

const initialState: InitialState = {
  tableExpanded: false,
  ahuModalOpen: false,
  isEditingAhu: false,
  downloadModalOpen: false,
  downloadModalEntity: "building",
  downloadModalEntityId: null,
  selectedAHU: null,
  searchHvacTerm: "",
  airQualitySearchTerm: "",
  deviceSearchTerm: "",
  expandedAhusById: [],
  filterStageModalOpen: false,
  view: "list",
  roomModalOpen: false,
  isEditingRoom: false,
  selectedRoom: null,
  deviceModalOpen: false,
  isEditingDevice: false,
  selectedDeviceMetric: null,
  deviceGraphStartDate: moment().subtract(1, "week").startOf("day").toDate(),
  deviceGraphEndData: moment().endOf("day").toDate(),
  deviceGraphGranuality: AggGranularity.HOUR,
  selectedAhuTableOptions: [
    AHU_VIEW_OPTION_LOAD,
    AHU_VIEW_OPTION_PRESSURE,
    AHU_VIEW_OPTION_REMAINING
  ],
  ahuDetailsStartDate: moment().subtract(2, "week").startOf("day").toDate(),
  ahuDetailsEndDate: moment().endOf("day").toDate(),
  selectedAhuDetailsStageId: null,
  stageToEdit: null,
  userClickedOnWidgetTitle: false,
  granularity: AggGranularity.DAY
};

export const HvacSlice = createSlice({
  name: "hvac",
  initialState: { ...initialState },
  reducers: {
    setTableExpanded: (state, action: PayloadAction<boolean>) => {
      state.tableExpanded = action.payload;
    },
    handleAHUModal: (state, action: PayloadAction<boolean>) => {
      state.ahuModalOpen = action.payload;
    },
    setIsEditingAhu: (state, action: PayloadAction<boolean>) => {
      state.isEditingAhu = action.payload;
    },
    handleDownloadModal: (state, action: PayloadAction<boolean>) => {
      state.downloadModalOpen = action.payload;
    },
    setDownloadModalEntity: (
      state,
      action: PayloadAction<
        "building" | "ahu" | "booth" | "stage" | "kettle" | "booth"
      >
    ) => {
      state.downloadModalEntity = action.payload;
    },
    setDownloadModalEntityId: (state, action: PayloadAction<string>) => {
      state.downloadModalEntityId = action.payload;
    },
    setSelectedAHU: (state, action: PayloadAction<FormattedAhu>) => {
      state.selectedAHU = action.payload;
    },
    setSearchHvacTerm: (state, action: PayloadAction<string>) => {
      state.searchHvacTerm = action.payload;
    },
    setAirQualitySearchTerm: (state, action: PayloadAction<string>) => {
      state.airQualitySearchTerm = action.payload;
    },
    setDeviceSearchTerm: (state, action: PayloadAction<string>) => {
      state.deviceSearchTerm = action.payload;
    },
    setHvacView: (state, action: PayloadAction<"list" | "chart">) => {
      state.view = action.payload;
    },
    setSelectedAhuTableOptions: (state, action: PayloadAction<any>) => {
      state.selectedAhuTableOptions = action.payload;
    },
    /**
     * Keep ids of ahus which are expanded to shown more details
     */
    setExpandedAhusById: (state, action: PayloadAction<Array<string>>) => {
      state.expandedAhusById = action.payload;
    },
    handleFilterStageModal: (state, action: PayloadAction<boolean>) => {
      state.filterStageModalOpen = action.payload;
    },
    handleRoomModal: (state, action: PayloadAction<boolean>) => {
      state.roomModalOpen = action.payload;
    },
    setIsEditingRoom: (state, action: PayloadAction<boolean>) => {
      state.isEditingRoom = action.payload;
    },
    setSelectedRoom: (state, action: PayloadAction<Room | null>) => {
      state.selectedRoom = action.payload;
    },
    handleDeviceModal: (state, action: PayloadAction<boolean>) => {
      state.deviceModalOpen = action.payload;
    },

    setDeviceGraphGranuality: (
      state,
      action: PayloadAction<AggGranularity.DAY | AggGranularity.HOUR>
    ) => {
      state.deviceGraphGranuality = action.payload;
    },
    setSelectedDeviceMetric: (state, action: PayloadAction<string>) => {
      state.selectedDeviceMetric = action.payload;
    },
    setIsEditingDevice: (state, action: PayloadAction<boolean>) => {
      state.isEditingDevice = action.payload;
    },
    setDeviceGraphStartDate: (state, action: PayloadAction<Date>) => {
      state.deviceGraphStartDate = action.payload;
    },
    setDeviceGraphEndDate: (state, action: PayloadAction<Date>) => {
      state.deviceGraphEndData = action.payload;
    },
    setAhuDetailsEndDate: (state, action: PayloadAction<Date>) => {
      state.ahuDetailsEndDate = action.payload;
    },
    setAhuDetailsStartDate: (state, action: PayloadAction<Date>) => {
      state.ahuDetailsStartDate = action.payload;
    },
    setSelectedAhuDetailsStageId: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.selectedAhuDetailsStageId = action.payload;
    },
    setStageToEdit: (state, action: PayloadAction<Stage | null>) => {
      state.stageToEdit = action.payload;
    },
    setUserClickedOnWidgetTitle: (state, action: PayloadAction<boolean>) => {
      state.userClickedOnWidgetTitle = action.payload;
    },
    setAhuChartGranularty: (state, action: PayloadAction<AggGranularity>) => {
      state.granularity = action.payload;
    }
  }
});

export const {
  setTableExpanded,
  handleAHUModal,
  setIsEditingAhu,
  handleDownloadModal,
  setSelectedAHU,
  setSearchHvacTerm,
  setAirQualitySearchTerm,
  setExpandedAhusById,
  setDownloadModalEntityId,
  setDownloadModalEntity,
  setDeviceSearchTerm,
  handleFilterStageModal,
  setHvacView,
  handleRoomModal,
  setIsEditingDevice,
  setIsEditingRoom,
  setSelectedRoom,
  handleDeviceModal,
  setSelectedDeviceMetric,
  setDeviceGraphStartDate,
  setDeviceGraphEndDate,
  setDeviceGraphGranuality,
  setSelectedAhuTableOptions,
  setAhuDetailsEndDate,
  setAhuDetailsStartDate,
  setSelectedAhuDetailsStageId,
  setStageToEdit,
  setUserClickedOnWidgetTitle,
  setAhuChartGranularty
} = HvacSlice.actions;

export const { reducer: hvacReducer } = HvacSlice;
