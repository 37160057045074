import {
  Container,
  Grid,
  Paper,
  Stack,
  Theme,
  Typography,
  useTheme
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import useGetHvacView from "../hvac/air-handling-units/hooks/useGetHvacView";
import {
  setSelectedBooth,
  setChartZonesSensorsIds,
  setChartSensorsIds
} from "src/store/booth/reducer";
import { PaintBoothZone } from "src/utils/client";

const BoothRowAndCardInfo = ({
  booth
}: {
  booth: {
    zones: Record<string, PaintBoothZone>;
    name: string;
    building_id: string;
    id: string;
    created_at: string;
  };
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isChartViewActive } = useGetHvacView();
  const { id, building_id } = booth;
  const zones = Object.values(booth.zones).map(({ name }) => name);
  const handleOnClick = () => {
    dispatch(setSelectedBooth(booth));
    dispatch(setChartZonesSensorsIds([]));
    dispatch(setChartSensorsIds([]));
    navigate(`/buildings/booth/${building_id}/${id}`);
  };

  return (
    <Container
      maxWidth={false}
      style={{ padding: 0 }}>
      {!isChartViewActive ? (
        <Grid
          data-testid="booth-row"
          container
          style={styles.root(theme)}
          onClick={handleOnClick}>
          <Grid
            item
            lg={6}
            md={6}
            sm={6}
            xs={6}>
            <Typography style={styles.column}>{booth.name}</Typography>
          </Grid>

          <Grid
            item
            lg={6}
            md={6}
            sm={6}>
            <Stack>
              {zones.map(name => {
                return (
                  <Typography
                    data-testId="booth-zone"
                    key={name}>
                    {name}
                  </Typography>
                );
              })}
            </Stack>
          </Grid>
        </Grid>
      ) : (
        <Paper
          sx={styles.row}
          data-testid="booth-card"
          onClick={handleOnClick}>
          <Stack>
            <Typography variant="body1">Booth name</Typography>
            <Typography
              variant="body1"
              data-testid="kettle-name">
              {booth.name}
            </Typography>
          </Stack>
          <Stack>
            <Typography
              variant="body1"
              sx={{ margin: "5px 0" }}>
              Details
            </Typography>
            {zones.map(name => {
              return (
                <Typography
                  key={name}
                  data-testId="booth-zone"
                  sx={{ marginBottom: "5px" }}>
                  {name}
                </Typography>
              );
            })}
          </Stack>
        </Paper>
      )}
    </Container>
  );
};

const styles = {
  root: (theme: Theme) => ({
    padding: "16px",
    transition: "all 250ms",
    "&:hover": {
      backgroundColor: theme.palette.grey["200"]
    },
    cursor: "pointer",
    backgroundColor: "white",
    alignItems: "center"
  }),
  column: {
    whiteSpace: "nowrap" as const,
    textOverflow: "ellipsis",
    overflow: "hidden",
    paddingLeft: 5,
    paddingBottom: 0
  },
  row: {
    padding: "10px 0"
  }
};

export default BoothRowAndCardInfo;
