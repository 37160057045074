import { Button, Link, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { handleRoomModal, setSelectedRoom } from "src/store/hvac/reducer";

const RoomEmptyComponent = () => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const handleOnAdd = () => {
    dispatch(setSelectedRoom(null));
    dispatch(handleRoomModal(true));
  };
  return (
    <div
      className={classes.root}
      data-testid="room-empty">
      <Typography
        variant="h4"
        gutterBottom>
        Create your first room
      </Typography>
      <Typography
        variant="body1"
        className={classes.paragraph}
        gutterBottom>
        To continue setting up your account, create a room with the button below
      </Typography>
      <Button
        variant="contained"
        className={classes.btn}
        onClick={handleOnAdd}
        data-testid="add-first-room-button">
        Add room
      </Button>
      <Typography
        variant="body1"
        gutterBottom>
        or
      </Typography>
      <Link href="mailto:support@i-qlair.com">Request support</Link>
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {
      textAlign: "center" as const
    },
    btn: {
      marginTop: "1em",
      marginBottom: "0.5em",
      width: "100%"
    },
    paragraph: {
      textAlign: "justify" as const
    }
  };
});

export default RoomEmptyComponent;
