// Widgets Here
import AranetBatteryLifeWidgetComponent from "./aranet-battery-life-widget/aranet-battery-life-widget.component";
import alertsWidgetComponent from "./alerts-widget/alerts-widget.component";
import AqBuildingSettingsComponent from "./aq-building-settings/aq-building-settings.component";
import AqBuildingWithTimeSettingsComponent from "./aq-building-with-time-settings/aq-building-with-time-settings.component";
import AqDeviceSettingsComponent from "./aq-device-settings/aq-device-settings.component";
import AqDeviceWidgetComponent from "./aq-device-widget/aq-device-widget.component";
import BuildingSettingsComponent from "./building-settings/building-settings.component";
import OutdoorAqWidgetComponent from "./aq-outdoor-widget/outdoor-aq-widget.component";
import BuildingAqWidgetComponent from "./aq-building-widget/building-aq-widget.component";
import AqBuildingOverviewWidgetComponent from "./aq-building-overview-widget/aq-building-overview-widget.component";
import BuildingPerformanceWidgetComponent from "./aq-building-performance-widget/building-performance-widget.component";
import AqCurvesWidgetComponent from "./aq-curves-widget/aq-curves-widget.component";
import AqTableWidgetComponent from "./aq-table-widget/aq-table-widget.component";
import selectAhuSettings from "./select-ahu-settings/select-ahu-settings.component";
import serviceLogsWidgetComponent from "./service-logs-widget/service-logs-widget.component";
import textWidgetSettingsComponent from "./text-widget-settings/text-widget-settings.component";
import testWidgetComponent from "./test-widget/test-widget.component";
import filterResistanceWidgetComponent from "./filter-resistance-widget/filter-resistance-widget.component";
import filterLoadWidgetComponent from "./filter-load-widget/filter-load-widget.component";
import remainingWeeksWidgetComponent from "./remaining-weeks-widget/remaining-weeks-widget.component";
import ahuStageGaugeWidgetComponent from "./ahu-stage-gauge-widget/ahu-stage-gauge-widget.component";
import ahuStageGaugeSettingsComponent from "./ahu-stage-gauge-settings/ahu-stage-gauge-settings.component";
import separatorWidgetComponent from "./separator-widget/separator-widget.component";
import CoTwoDecayWidgetComponent from "./cotwo-decay-widget/cotwo-decay-widget.component";
import FiltrationEfficiencyWidgetComponent from "./filtration-efficiency-widget/filtration-efficiency-widget.component";
import pressureCurvesWidgetComponent from "./pressure-curves-widget/pressure-curves-widget.component";
import selectAhuStageSettingsComponent from "./select-ahu-stage-settings/select-ahu-stage-settings.component";
import serviceLogsSettingsComponent from "./service-logs-settings/service-logs-settings.component";
import pressureCurvesSettingsComponent from "./pressure-curves-settings/pressure-curves-settings.component";

// Previews Here
import AlertsPreview from "./alerts-widget/preview.png";
import AranetBatteryLifePreview from "./aranet-battery-life-widget/preview.png";
import NotePreview from "./test-widget/preview.png";
import ServiceLogPreview from "./service-logs-widget/preview.png";
import OutdoorAqPreview from "./aq-outdoor-widget/preview.png";
import DeviceAqPreview from "./aq-device-widget/preview.png";
import BuildingAqPreview from "./aq-building-widget/preview.png";
import AqCurvesPreview from "./aq-curves-widget/preview.png";
import AqTablePreview from "./aq-table-widget/preview.png";
import BuildingOverviewAqPreview from "./aq-building-overview-widget/preview.png";
import BuildingPerformancePreview from "./aq-building-performance-widget/preview.png";
import ResistancePreview from "./filter-resistance-widget/preview.png";
import FilterLoadPreview from "./filter-load-widget/preview.png";
import RemainingWeeksPreview from "./remaining-weeks-widget/preview.png";
import StageGaugePreview from "./ahu-stage-gauge-widget/preview.png";
import SeparatorPreview from "./separator-widget/preview.png";
import CoTwoDecayPreview from "./decay-and-filtration-efficiency-settings/cotwo-decay-preview.png";
import FiltrationEfficiencyPreview from "./decay-and-filtration-efficiency-settings/filter-efficiency-preview.png";
import FilterPressurePreview from "./pressure-curves-widget/preview.png";
import DecayAndFiltrationEfficiencySettings from "./decay-and-filtration-efficiency-settings/decay-and-filtration-efficiency-settings";
import WaterKettleWidget from "./water-kettle-widget/water-kettle-widget";
import WaterKettleSettings from "./water-kettle-settings/water-kettle-settings";
import WaterKettlePreview from "./water-kettle-widget/preview.png";
import WaterKettleChart3Days from "./water-kettle-chart-3days/water-kettle-chart-3days";
import WaterKettleChartPreview from "./water-kettle-chart-3days/KettleChart3Days.png";
import BoothPressureWidget from "./booth-pressure/booth-pressure";
import BoothPressureSettings from "./booth-pressure-settings/paint-booth-settings";
import BoothSmallPreview from "./booth-pressure/booth-small.png";
import BoothBigPreview from "./booth-pressure/booth-big.png";

import { widgetNames } from "./widgets-config-names";

export const widgets = {
  stageGauge: {
    name: widgetNames.stageGauge,
    Component: ahuStageGaugeWidgetComponent,
    settingsComponent: ahuStageGaugeSettingsComponent,
    gridData: { w: 3, h: 2, minW: 2, maxW: 3, maxH: 2 },
    zone: "body",
    preview: StageGaugePreview,
    type: "stageGauge",
    props: {
      requiresConfig: true,
      widgetName: widgetNames.stageGauge
    }
  },
  pressureCurves: {
    name: widgetNames.pressureCurves,
    Component: pressureCurvesWidgetComponent,
    settingsComponent: pressureCurvesSettingsComponent,
    gridData: { w: 6, h: 2, minW: 4, minH: 2 },
    zone: "body",
    preview: FilterPressurePreview,
    props: {
      requiresConfig: true,
      widgetName: widgetNames.pressureCurves
    },
    type: "pressureCurves"
  },
  serviceLogsBody: {
    name: widgetNames.serviceLogs,
    Component: serviceLogsWidgetComponent,
    settingsComponent: serviceLogsSettingsComponent,
    gridData: { w: 3, h: 2, minW: 2 },
    zone: "body",
    preview: ServiceLogPreview,
    props: {
      requiresConfig: true,
      widgetName: widgetNames.serviceLogs
    },
    type: "serviceLogsBody"
  },
  boothPressure: {
    name: widgetNames.boothPressure,
    Component: BoothPressureWidget,
    settingsComponent: BoothPressureSettings,
    gridData: { w: 1, h: 1, minW: 1, maxH: 1 },
    zone: "body",
    preview: BoothSmallPreview,
    props: {
      requiresConfig: true,
      widgetName: widgetNames.boothPressure
    },
    type: "boothPressure"
  },
  boothPressurePoint: {
    name: widgetNames.boothPressurePoint,
    Component: BoothPressureWidget,
    settingsComponent: BoothPressureSettings,
    gridData: { w: 2, h: 1, minW: 2, maxH: 1 },
    zone: "body",
    preview: BoothBigPreview,
    props: {
      requiresConfig: true,
      showSetPoint: true,
      widgetName: widgetNames.boothPressurePoint
    },
    type: "boothPressurePoint"
  },
  note: {
    name: widgetNames.note,
    Component: testWidgetComponent,
    settingsComponent: textWidgetSettingsComponent,
    gridData: { w: 4, h: 1 },
    preview: NotePreview,
    zone: "body",
    props: {
      requiresConfig: true,
      widgetName: widgetNames.note
    },
    type: "note"
  },
  alerts: {
    name: widgetNames.alerts,
    Component: alertsWidgetComponent,
    settingsComponent: null,
    gridData: { w: 3, h: 2, minW: 2 },
    preview: AlertsPreview,
    zone: "body",
    props: {
      requiresConfig: true,
      widgetName: widgetNames.alerts
    },
    type: "alerts"
  },
  aranetBatteryLifeBody: {
    name: widgetNames.aranetBatteryLife,
    Component: AranetBatteryLifeWidgetComponent,
    settingsComponent: selectAhuStageSettingsComponent,
    gridData: { w: 2, h: 1, maxH: 1, minH: 1, minW: 2, maxW: 2 },
    preview: AranetBatteryLifePreview,
    zone: "body",
    props: {
      requiresConfig: true,
      widgetName: widgetNames.aranetBatteryLife
    },
    type: "aranetBatteryLifeBody"
  },
  filterResistance: {
    name: widgetNames.filterResistance,
    Component: filterResistanceWidgetComponent,
    settingsComponent: selectAhuSettings,
    gridData: { w: 4, h: 1, minW: 2, maxH: 1 },
    preview: ResistancePreview,
    zone: "body",
    props: {
      requiresConfig: true,
      widgetName: widgetNames.filterResistance
    },
    type: "filterResistance"
  },
  filterLoad: {
    name: widgetNames.filterLoad,
    Component: filterLoadWidgetComponent,
    settingsComponent: selectAhuSettings,
    gridData: { w: 4, h: 1, minW: 2, maxH: 1 },
    zone: "body",
    preview: FilterLoadPreview,
    props: {
      requiresConfig: true,
      widgetName: widgetNames.filterLoad
    },
    type: "filterLoad"
  },
  remainingWeeks: {
    name: widgetNames.remainingWeeks,
    Component: remainingWeeksWidgetComponent,
    settingsComponent: selectAhuSettings,
    gridData: { w: 4, h: 1, minW: 2, maxH: 1 },
    zone: "body",
    preview: RemainingWeeksPreview,
    props: {
      requiresConfig: true,
      widgetName: widgetNames.remainingWeeks
    },
    type: "remainingWeeks"
  },
  waterKettle: {
    name: widgetNames.waterKettle,
    Component: WaterKettleWidget,
    settingsComponent: WaterKettleSettings,
    gridData: { w: 3, h: 2, minW: 2, maxW: 3, maxH: 2 },
    zone: "body",
    preview: WaterKettlePreview,
    props: {
      requiresConfig: true,
      widgetName: widgetNames.waterKettle
    },
    type: "waterKettle"
  },
  waterKettleChart3Days: {
    name: widgetNames.waterKettleChart3Days,
    Component: WaterKettleChart3Days,
    settingsComponent: WaterKettleSettings,
    gridData: { w: 6, h: 2, minW: 4, minH: 2 },
    zone: "body",
    preview: WaterKettleChartPreview,
    props: {
      requiresConfig: true,
      widgetName: widgetNames.waterKettleChart3Days
    },
    type: "waterKettleChart3Days"
  },
  outdoorAQ: {
    name: widgetNames.outdoorAQ,
    Component: OutdoorAqWidgetComponent,
    settingsComponent: BuildingSettingsComponent,
    gridData: { w: 6, h: 1, minW: 4, maxH: 1 },
    zone: "body",
    preview: OutdoorAqPreview,
    props: {
      requiresConfig: true,
      widgetName: widgetNames.outdoorAQ
    },
    type: "outdoorAQ"
  },
  indoorAQ: {
    name: widgetNames.indoorAQ,
    Component: AqDeviceWidgetComponent,
    settingsComponent: AqDeviceSettingsComponent,
    gridData: { w: 6, h: 1, minW: 4, maxH: 1 },
    zone: "body",
    preview: DeviceAqPreview,
    props: {
      requiresConfig: true,
      widgetName: widgetNames.indoorAQ
    },
    type: "indoorAQ"
  },
  buildingPerformance: {
    name: widgetNames.buildingPerformance,
    Component: BuildingPerformanceWidgetComponent,
    settingsComponent: AqBuildingWithTimeSettingsComponent,
    gridData: { w: 6, h: 1, minW: 4, maxH: 1 },
    zone: "body",
    preview: BuildingPerformancePreview,
    props: {
      requiresConfig: true,
      widgetName: widgetNames.buildingPerformance
    },
    type: "buildingPerformance"
  },
  buildingAQ: {
    name: widgetNames.buildingAQ,
    Component: BuildingAqWidgetComponent,
    settingsComponent: AqBuildingWithTimeSettingsComponent,
    gridData: { w: 6, h: 2, minW: 6, maxH: 2 },
    zone: "body",
    preview: BuildingAqPreview,
    props: {
      requiresConfig: true,
      widgetName: widgetNames.buildingAQ
    },
    type: "buildingAQ"
  },
  buildingAQOverview: {
    name: widgetNames.buildingAQOverview,
    Component: AqBuildingOverviewWidgetComponent,
    settingsComponent: AqBuildingWithTimeSettingsComponent,
    gridData: { w: 8, h: 2, minW: 6 },
    zone: "body",
    preview: BuildingOverviewAqPreview,
    props: {
      requiresConfig: true,
      widgetName: widgetNames.buildingAQOverview
    },
    type: "buildingAQOverview"
  },
  aqCurves: {
    name: widgetNames.aqCurves,
    Component: AqCurvesWidgetComponent,
    settingsComponent: AqBuildingSettingsComponent,
    gridData: { w: 6, h: 2, minW: 4, minH: 2 },
    zone: "body",
    preview: AqCurvesPreview,
    props: {
      requiresConfig: true,
      widgetName: widgetNames.aqCurves
    },
    type: "aqCurves"
  },
  aqTable: {
    name: widgetNames.aqTable,
    Component: AqTableWidgetComponent,
    settingsComponent: AqBuildingSettingsComponent,
    gridData: { w: 6, h: 3, minW: 4, minH: 3, maxH: 3 },
    zone: "body",
    preview: AqTablePreview,
    props: {
      requiresConfig: true,
      widgetName: widgetNames.aqTable
    },
    type: "aqTable"
  },
  coTwoDecay: {
    name: widgetNames.co2Decay,
    Component: CoTwoDecayWidgetComponent,
    settingsComponent: DecayAndFiltrationEfficiencySettings,
    gridData: { w: 4, h: 1, minW: 2, maxH: 1.5 },
    zone: "body",
    preview: CoTwoDecayPreview,
    props: {
      requiresConfig: true,
      widgetName: widgetNames.co2Decay
    },
    type: "coTwoDecay"
  },
  filtrationEfficiency: {
    name: widgetNames.filtrationEfficiency,
    Component: FiltrationEfficiencyWidgetComponent,
    settingsComponent: DecayAndFiltrationEfficiencySettings,
    gridData: { w: 4, h: 1, minW: 2, maxH: 1.5 },
    zone: "body",
    preview: FiltrationEfficiencyPreview,
    props: {
      requiresConfig: true,
      widgetName: widgetNames.filtrationEfficiency
    },
    type: "filtrationEfficiency"
  },
  separator: {
    name: widgetNames.separator,
    Component: separatorWidgetComponent,
    settingsComponent: textWidgetSettingsComponent,
    gridData: {
      w: 12,
      h: 0.35,
      isResizable: false,
      minH: 0.3
    },
    zone: "body",
    preview: SeparatorPreview,
    type: "separator",
    props: {
      requiresConfig: true,
      widgetName: widgetNames.separator
    }
  }
};

export type WidgetSettings = {
  onSave: (newWidgetConfig: any) => void;
  currentProps: any;
};
