import useGetBuildings from "./useGetBuildings";
import {
  useGetDeviceParentsQuery,
  useGetDevicesQuery
} from "src/store/services/notifications/notificationsService";

const useGetWidgetSettingsEntities = () => {
  const {
    buildings,
    isLoading: isLoadingBuildings,
    isFetching: isFetchingBuildings,
    isError: isErrorBuildings,
    refetch: refetchBuildings
  } = useGetBuildings();
  const {
    data: deviceParents,
    isLoading: isLoadingDeviceParents,
    isFetching: isFetchingDeviceParents,
    isError: isErrorDeviceParents,
    refetch: refetchDeviceParents
  } = useGetDeviceParentsQuery();
  const {
    data: devices,
    isLoading: isLoadingDevices,
    isFetching: isFetchingDevices,
    isError: isErrorDevices,
    refetch: refetchDevices
  } = useGetDevicesQuery();

  return {
    buildings,
    deviceParents,
    devices,
    isLoading: isLoadingBuildings || isLoadingDeviceParents || isLoadingDevices,
    isFetching:
      isFetchingBuildings || isFetchingDeviceParents || isFetchingDevices,
    isError: isErrorBuildings || isErrorDeviceParents || isErrorDevices,
    refetch: () => {
      refetchBuildings();
      refetchDeviceParents();
      refetchDevices();
    }
  };
};

export default useGetWidgetSettingsEntities;
