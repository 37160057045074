import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AggGranularity, PaintBooth } from "src/utils/client";
import moment from "moment";
export type UserActions = "adding" | "removing";
type DetailsTabs = "pressure" | "humidity" | "temperature";

type InitialState = {
  isModalOpen: boolean;
  isAddingOrRemoving: UserActions;
  selectedBooth: PaintBooth | null;
  selectedSensorId: string | null;
  boothStartDate: Date;
  boothEndDate: Date;
  selectedDetailsTab: DetailsTabs;
  chartZonesSensorsIds: string[];
  chartSensorsIds: string[];
  boothSearchTerm: string;
  granularity: AggGranularity;
};

const initialState: InitialState = {
  isModalOpen: false,
  isAddingOrRemoving: "adding",
  selectedBooth: null,
  selectedSensorId: null,
  boothStartDate: moment().subtract(7, "day").startOf("day").toDate(),
  boothEndDate: moment().endOf("day").toDate(),
  selectedDetailsTab: "pressure",
  chartZonesSensorsIds: [],
  chartSensorsIds: [],
  boothSearchTerm: "",
  granularity: AggGranularity.HOUR
};

export const boothSlice = createSlice({
  name: "booth",
  initialState,
  reducers: {
    setModalState: (state, action: PayloadAction<boolean>) => {
      state.isModalOpen = action.payload;
    },
    setIsAddingOrRemoving: (state, action: PayloadAction<UserActions>) => {
      state.isAddingOrRemoving = action.payload;
    },
    setSelectedBooth: (state, action: PayloadAction<PaintBooth | null>) => {
      state.selectedBooth = action.payload;
    },
    setSelectedSensorId: (state, action: PayloadAction<string | null>) => {
      state.selectedSensorId = action.payload;
    },
    setBoothStartDate: (state, action: PayloadAction<Date>) => {
      state.boothStartDate = action.payload;
    },
    setBoothEndDate: (state, action: PayloadAction<Date>) => {
      state.boothEndDate = action.payload;
    },
    setDetailsTab: (state, action: PayloadAction<DetailsTabs>) => {
      state.selectedDetailsTab = action.payload;
    },
    setChartZonesSensorsIds: (state, action: PayloadAction<string[]>) => {
      state.chartZonesSensorsIds = action.payload;
    },
    setChartSensorsIds: (state, action: PayloadAction<string[]>) => {
      state.chartSensorsIds = action.payload;
    },
    setBoothSearchTerm: (state, action: PayloadAction<string>) => {
      state.boothSearchTerm = action.payload;
    },
    setChartGranularity: (state, action: PayloadAction<AggGranularity>) => {
      state.granularity = action.payload;
    }
  }
});

export const {
  setModalState,
  setIsAddingOrRemoving,
  setSelectedBooth,
  setSelectedSensorId,
  setBoothStartDate,
  setBoothEndDate,
  setDetailsTab,
  setChartZonesSensorsIds,
  setChartSensorsIds,
  setBoothSearchTerm,
  setChartGranularity
} = boothSlice.actions;

export const { reducer: boothReducer } = boothSlice;
