import { Link, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const BoothEmpty = () => {
  const { classes } = useStyles();

  return (
    <div
      className={classes.root}
      data-testid="booth-empty">
      <Typography
        variant="body1"
        className={classes.paragraph}
        gutterBottom>
        To continue setting up your account, request support for creating paint
        booth
      </Typography>
      <Link href="mailto:support@i-qlair.com">Request support</Link>
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {
      textAlign: "center" as const
    },
    paragraph: {
      textAlign: "justify" as const
    }
  };
});

export default BoothEmpty;
