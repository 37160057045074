/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SyneticaLiquidPressureSensor = {
  sensor_type?: any;
  hardware_id: string;
  side: SyneticaLiquidPressureSensor.side;
};
export namespace SyneticaLiquidPressureSensor {
  export enum side {
    IN = 'IN',
    OUT = 'OUT',
  }
}

