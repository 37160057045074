import { createTheme } from "@mui/material";

const Theme = createTheme({
  typography: {
    fontFamily: ['"Cera Pro"'].join(","),
    h4: {
      fontWeight: 600
    },
    h6: {
      fontWeight: 600
    }
  },
  shape: {
    borderRadius: 8
  },
  palette: {
    primary: {
      main: "#0795bb"
    },
    secondary: {
      main: "#00476b"
    },
    status: {
      Excellent: "rgb(21, 142, 72)",
      Good: "rgb(106, 222, 89)",
      Moderate: "rgb(244, 200, 58)",
      "Unhealthy for sensitive groups": "rgb(253, 146, 49)",
      Unhealthy: "rgb(232, 37, 71)",
      Hazardous: "rgb(188, 8, 37)",
      "Very unhealthy": "rgb(188, 8 ,37)",
      Default: "rgb(125, 125, 125)"
    },
    humidityStatus: {
      Unhealthy: "rgb(232, 120, 38)",
      Moderate: "rgb(236, 220, 108)",
      Good: "rgb(104, 207, 162)"
    },
    temperatureStatus: {
      Unhealthy: "rgb(232, 120, 38)",
      Moderate: "rgb(236, 220, 108)",
      Good: "rgb(104, 207, 162)",
      "Very unhealthy": "rgb(221, 23, 23)"
    },
    statusBg: {
      Excellent: "rgba(21, 142, 72, 0.2)",
      Good: "rgba(106, 222, 89, 0.2)",
      Moderate: "rgba(244, 200, 58, 0.2)",
      "Unhealthy for sensitive groups": "rgba(253, 146, 49, 0.2)",
      Unhealthy: "rgba(232, 37, 71, 0.2)",
      Hazardous: "rgba(188, 8, 37, 0.2)",
      "Very unhealthy": "rgba(188, 8,37, 0.2)",
      Default: "rgba(125, 125, 125, 0.2)"
    },
    ahuStatus: {
      clean_filters: "#008000",
      moderately_clean: "#CCCC00",
      moderately_dirty: "#FFA500",
      dirty_filters: "#FF0000",
      default: "#a9a9a9"
    },
    pressureStatus: {
      too_low: "rgb(238,130,238)",
      default: "#a9a9a9",
      good: "#008000",
      moderate: "#CCCC00",
      high: "#FFA500",
      very_high: "#FF0000",
      too_high: "#800081"
    },
    tcoHorizontalRanges: {
      tcoCurve: "#B36BFC",
      changeInterval: "#232526",
      lowestCostIndex: "#38C183",
      lowestCarbonIndex: "#E7D54D"
    }
  },
  components: {
    MuiFormControl: {
      defaultProps: {
        size: "small"
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        margin: "dense",
        size: "small"
      },
      styleOverrides: {
        root: {
          border: "none !important"
        }
      }
    },
    MuiButton: {
      defaultProps: {
        variant: "outlined",
        disableElevation: false
      },
      styleOverrides: {
        root: {
          textTransform: "none",
          "&$disabled": {
            backgroundColor: "rgba(0, 0, 0, 0.12)"
          }
        }
      }
    },
    MuiLink: {
      defaultProps: {
        underline: "always"
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: "outlined",
        margin: "dense"
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minWidth: "120px !important"
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        subtitle1: {
          color: "#a9a9a9"
        },
        subtitle2: {
          color: "#a9a9a9"
        },
        body2: {
          color: "#a9a9a9"
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltipPlacementBottom: {
          marginTop: "4px !important"
        }
      }
    },
    MuiButtonGroup: {
      styleOverrides: {
        grouped: {
          background: "#fff"
        },
        root: {
          "& .MuiButton-outlinedInherit": {
            borderColor: "#999",
            color: "grey"
          },
          "& .MuiButton-outlinedPrimary": {
            background: "transparent !important"
          }
        }
      }
    }
  }
});

type Status = {
  Excellent: string;
  Good: string;
  Moderate: string;
  "Unhealthy for sensitive groups": string;
  Unhealthy: string;
  Hazardous: string;
  "Very unhealthy": string;
  Default: string;
};

type TcoHorizontalRanges = {
  tcoCurve: `#${string}`;
  changeInterval: `#${string}`;
  lowestCostIndex: `#${string}`;
  lowestCarbonIndex: `#${string}`;
};

type HumidityStatus = Pick<Status, "Unhealthy" | "Moderate" | "Good">;
type TemperatureStatus = Pick<
  Status,
  "Unhealthy" | "Moderate" | "Good" | "Very unhealthy"
>;

type AhuStatus = {
  clean_filters: `#${string}`;
  moderately_clean: `#${string}`;
  moderately_dirty: `#${string}`;
  dirty_filters: `#${string}`;
  default: `#${string}`;
};

type PressureStatus = {
  too_low: string;
  default: `#${string}`;
  good: `#${string}`;
  moderate: `#${string}`;
  high: `#${string}`;
  very_high: `#${string}`;
  too_high: `#${string}`;
};
declare module "@mui/material/styles/createPalette" {
  // Here we used declaration merging
  // For more info https://www.typescriptlang.org/docs/handbook/declaration-merging.html
  interface Palette {
    status: Status;
    humidityStatus: HumidityStatus;
    temperatureStatus: TemperatureStatus;
    statusBg: Status;
    ahuStatus: AhuStatus;
    pressureStatus: PressureStatus;
    tcoHorizontalRanges: TcoHorizontalRanges;
  }
  interface PaletteOptions {
    status: Status;
    humidityStatus: HumidityStatus;
    temperatureStatus: TemperatureStatus;
    statusBg: Status;
    ahuStatus: AhuStatus;
    pressureStatus: PressureStatus;
    tcoHorizontalRanges: TcoHorizontalRanges;
  }
}

export default Theme;
