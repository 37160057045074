import { makeStyles } from "tss-react/mui";
export const useStyles = makeStyles()(() => {
  return {
    root: {
      padding: 4,
      alignItems: "center",
      paddingLeft: 24,
      background: "white"
    }
  };
});
