import { createAsyncThunk } from "@reduxjs/toolkit";
import { LoginService } from "src/utils/client";
import { authSlice } from "./reducer";
import { LoginInformation } from "./types";
import { apiService } from "../services/api";

export const login = createAsyncThunk(
  "auth/login",
  async (body: LoginInformation, { dispatch }) => {
    const { tokens, user } = await LoginService.login(body);
    const { setAuthenticated } = authSlice.actions;
    const { accepted_terms, id, name, role, email } = user;
    localStorage.setItem("token", tokens.AccessToken);
    localStorage.setItem("refreshtoken", tokens.RefreshToken);
    localStorage.setItem("role", role);
    localStorage.setItem("name", name);
    localStorage.setItem("email", email);
    localStorage.setItem("id", id);
    localStorage.setItem("terms", accepted_terms.toString());
    dispatch(setAuthenticated({ tokens, user }));

    pendo.initialize({
      visitor: {
        id: id,
        email: email,
        full_name: name,
        role: role,
        environment: NODE_ENV
      },
      account: {
        id: id,
        name: name
      }
    });

    return {
      tokens,
      user
    };
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (_, { dispatch }) => {
    localStorage.clear();
    dispatch(apiService.util.resetApiState());
    dispatch(authSlice.actions.setUnauthenticated());
  }
);
