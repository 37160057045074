import LineChart from "src/shared/components/line-chart/line-chart-component";
import useGetChartData from "src/hooks/useGetChartData";
import moment from "moment";
import useGetKettleAndSenorsData from "src/hooks/useGetKettleAndSensorsData";
import { useStyles } from "src/styles/chartWidget";
import { Typography, Tooltip } from "@mui/material";
import LoaderComponent from "src/shared/components/loader/loader.component";
import Error from "src/shared/components/Error/Error";
import LinkIcon from "@mui/icons-material/Link";
import { AggGranularity } from "src/utils/client";
import useGetBuildings from "src/hooks/useGetBuildings";
import useOverview from "src/hooks/useOverview";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { setSelectedKettle } from "src/store/kettle/reducer";
import { setSelectedOverviewTab } from "src/store/overview/reducer";
import { setUserClickedOnWidgetTitle } from "src/store/hvac/reducer";
import { useNavigate } from "react-router-dom";
import { useOverviewTab } from "../../hooks/useGetOverviewTab";

type WaterKettle = {
  buildingId: string;
  kettleId: string;
  requiresConfig: boolean;
};

const WaterKettleChart3Days = ({
  buildingId,
  kettleId,
  requiresConfig
}: WaterKettle) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { editing } = useOverview();
  const { buildings } = useGetBuildings();
  const startDate = moment().subtract(4, "day").startOf("day").toDate();
  const endDate = moment().endOf("day").toDate();
  const { tabId } = useOverviewTab();
  const building = buildings?.find(({ id }) => id === buildingId);
  const isGaugeWidget = false;
  const isChartWidget = true;

  const {
    isLoading: isLoadingKettleData,
    inletSensor,
    kettle,
    outletSensor,
    refetch: refetchKettleData,
    isError: isKettleDataError
  } = useGetKettleAndSenorsData(
    buildingId,
    kettleId,
    isGaugeWidget,
    requiresConfig
  );

  const {
    chartData,
    chartDataExist,
    isLoading: isLoadingCharts,
    isError: isErrorChartData,
    refetch: refetchChartData
  } = useGetChartData(
    inletSensor?.id,
    outletSensor?.id,
    buildingId,
    startDate,
    endDate,
    AggGranularity.HOUR,
    requiresConfig,
    isChartWidget
  );

  const loading = isLoadingCharts || isLoadingKettleData;
  const error = isKettleDataError || isErrorChartData;

  const refetchAgain = () => {
    refetchKettleData();
    refetchChartData();
  };
  const handleOnClick = () => {
    if (editing) return;
    dispatch(setSelectedKettle(kettle));
    dispatch(setSelectedOverviewTab(tabId));
    dispatch(setUserClickedOnWidgetTitle(true));
    navigate(`/buildings/kettle/${building?.id}/${kettle?.id}`);
  };

  return (
    <div
      data-testid="pressure-curves-widget"
      className={classes.root}>
      {requiresConfig ? (
        <>
          <Typography variant="h6">Filter Pressure</Typography>
          <Typography>Configuration is required</Typography>
        </>
      ) : loading ? (
        <LoaderComponent />
      ) : error ? (
        <Error
          setRefetchAgain={refetchAgain}
          error={error}></Error>
      ) : (
        <div className={classes.widget}>
          <div className={classes.header}>
            <Typography
              variant="h6"
              onClick={handleOnClick}
              style={{ display: "flex", gap: "4px" }}
              sx={
                editing
                  ? { cursor: "default" }
                  : {
                      cursor: "pointer",
                      "&:hover": { color: "#0795bb" }
                    }
              }>
              Kettle pressure: {kettle?.name}
              <LinkIcon
                fontSize="small"
                sx={{
                  margin: "auto",
                  transform: "rotate(-30deg)",
                  fontSize: 15,
                  color: "#a9a9a9"
                }}
              />
            </Typography>
            {Boolean(building) && (
              <Tooltip
                title={building.location?.address}
                placement="top-end">
                <Typography
                  variant="h6"
                  className={classes.facilityName}>
                  {building.name}
                </Typography>
              </Tooltip>
            )}
          </div>
          <>
            {chartDataExist ? (
              <div className={classes.graphContainer}>
                <LineChart
                  data={chartData}
                  maintainAspectRatio={false}
                  isKettle={true}
                  enableZoom={false}
                  isWidget={true}
                />
              </div>
            ) : (
              <div className={classes.noDataContainer}>
                <>
                  <Typography
                    gutterBottom
                    variant="subtitle1">
                    NO DEVICE DATA AVAILABLE
                  </Typography>
                  <Typography variant="body2">
                    Please check that your device has its connection
                  </Typography>
                </>
              </div>
            )}
          </>
        </div>
      )}
    </div>
  );
};

export default WaterKettleChart3Days;
