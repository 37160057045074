import { Dispatch, SetStateAction } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from "@mui/material";
type FormInputProps = {
  label: string;
  inputValue: string;
  array: Array<any>;
  showHelperText?: boolean;
  helperText?: string;
  setValue: (value?: string) => void | Dispatch<SetStateAction<string>>;
};

const FormInput = ({
  label,
  inputValue,
  array,
  showHelperText = false,
  helperText = "",
  setValue
}: FormInputProps) => {
  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        value={inputValue}
        data-testid="building-select"
        label={label}
        onChange={({ target: { value } }) => {
          setValue(value);
        }}>
        {array?.map(item => (
          <MenuItem
            key={`menu_item_${item.id}`}
            data-testid={`select-${item.name}`}
            value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
      {showHelperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default FormInput;
