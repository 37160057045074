import { Dispatch, SetStateAction, useCallback } from "react";
import { useGetTypesQuery } from "src/store/services/notifications/notificationsService";
import { Grid, Button } from "@mui/material";
import { FormApi } from "final-form";
import { useStyles } from "./alert-types.styles";
import AirIcon from "@mui/icons-material/Air";
import MicrowaveIcon from "@mui/icons-material/Microwave";
import { FormFields } from "../notification-drawer/notification-drawer.component";

export enum AlertTypesEnum {
  AQ = "aq",
  HVAC = "hvac",
  KETTLE = "kettle",
  PAINT_BOOTH = "paint_booth"
}

type Alerts = Record<
  `${AlertTypesEnum}`,
  {
    key: `${AlertTypesEnum}`;
    label: string;
    options: Array<{ label: string; key: string }>;
  }
>;

const AlertTypes = ({
  alertDeviceType,
  form,
  setSelectedAlertType,
  setSelectedDeviceParent,
  setSelectedDevice,
  setSelectedBuildingId
}: {
  alertDeviceType: string;
  form: FormApi<any, Partial<any>>;
  setSelectedAlertType: Dispatch<SetStateAction<`${AlertTypesEnum}`>>;
  setSelectedDeviceParent: Dispatch<SetStateAction<any>>;
  setSelectedDevice: Dispatch<SetStateAction<any>>;
  setSelectedBuildingId: Dispatch<SetStateAction<any>>;
}) => {
  const { classes } = useStyles();
  const { data: alertTypes } = useGetTypesQuery();

  const getIcon = useCallback((key: `${AlertTypesEnum}`) => {
    switch (key) {
      case AlertTypesEnum.AQ:
        return (
          <AirIcon
            data-testid="aq-icon"
            className={classes.alert_typeIcon}
          />
        );
      case AlertTypesEnum.HVAC:
        return (
          <MicrowaveIcon
            data-testid="hvac-icon"
            className={classes.alert_typeIcon}
          />
        );
      default:
        return null;
    }
  }, []);

  const handleOnClick = (alertType: `${AlertTypesEnum}`) => {
    if (form) {
      form?.change(FormFields.ALERT_DEVICE_TYPE, alertType);
      [
        FormFields.ALERT_KEY,
        FormFields.DEVICE_ID,
        FormFields.BUILDING_ID
      ].forEach(field => {
        form.change(field, null);
      });
    }
    setSelectedDeviceParent(null);
    setSelectedDevice(null);
    setSelectedBuildingId(null);
    setSelectedAlertType(alertType);
  };

  return (
    <>
      {Boolean(alertTypes) &&
        Object.values(alertTypes as Alerts).map(option => {
          return (
            <Grid
              key={`alert_device_type_option_${option.key}`}
              item
              xs={6}>
              <Button
                data-testid="option-button"
                variant={
                  alertDeviceType === option.key ? "contained" : "outlined"
                }
                className={classes.alert_typeBtn}
                type="button"
                onClick={() => {
                  handleOnClick(option.key);
                }}>
                {getIcon(option.key)}
                {option.label}
              </Button>
            </Grid>
          );
        })}
    </>
  );
};

export default AlertTypes;
