import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => {
  return {
    log: {
      display: "flex",
      marginLeft: "1rem",
      borderTop: "2px solid rgba(0, 0, 0, 0.1)",
      "&:first-of-type": {
        borderTop: 0
      }
    },
    margin: {
      margin: "0.5rem 0rem"
    },
    iconItem: {
      display: "flex",
      alignItems: "center",
      padding: "2px",
      marginLeft: "0.5rem"
    },
    logTitle: {
      fontWeight: 500
    },
    editItem: {
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
      padding: "2px"
    },
    item: {
      display: "flex",
      alignItems: "center",
      padding: "2px"
    },
    questionItem: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: "-1rem"
    },
    question: {
      color: "#FFF",
      padding: "2px",
      background: "rgb(244, 200, 58)",
      borderRadius: "1px",
      boxShadow: "0.2rem 0.1rem 0.1rem rgba(0, 0, 0, 0.2)"
    },
    flex: {
      display: "flex"
    },
    stage: {
      marginLeft: "0.5rem",
      fontWeight: 500
    },
    font: {
      fontSize: "0.85rem"
    },
    descriptionItem: {
      padding: "2px 2px 2px 2rem"
    },
    edit: {
      display: "flex",
      alignItems: "center"
    },
    editButton: {
      fontWeight: 500,
      color: "#0795BB",
      cursor: "pointer",
      ":hover": {
        textDecoration: "underline"
      }
    }
  };
});
