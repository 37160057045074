import { makeStyles } from "tss-react/mui";
import { Grid, Typography, Hidden } from "@mui/material";
import useGetPolutantDictionary from "src/hooks/useGetPollutantDictionary";

const DevicesTableHeaderComponent = () => {
  const { classes } = useStyles();
  const { pollInfoDict } = useGetPolutantDictionary();
  let metrics = ["cotwo", "voc_ppb", "voc", "pmtwo", "pmten"];

  metrics.splice(metrics.indexOf("voc"), 1);

  return (
    <Grid
      container
      className={classes.root}
      data-testid="devices-table">
      <Grid
        xs={2}
        sm={1}
        md={1}
        lg={1}
        item>
        <Typography className={classes.name}>Status</Typography>
      </Grid>
      <Grid
        item
        xs={5}
        sm={2}
        md={2}
        lg={2}>
        <Typography>Device Name</Typography>
      </Grid>
      <Grid
        item
        lg={6}
        md={6}
        sm={6}
        xs={5}
        className={classes.pollutants}>
        <div className={classes.centered}>
          <Typography>AQI</Typography>
        </div>
        {metrics.map(metric => {
          let shortName =
            pollInfoDict[metric === "voc_ppb" ? "voc" : metric].short_name;
          return (
            <div
              key={"pollutant" + shortName}
              className={classes.pollutantName}>
              <Typography>
                {shortName.replace("(PPB)", "").replace("(PPM)", "")}
              </Typography>
            </div>
          );
        })}
      </Grid>
      <Hidden smDown>
        <Grid
          item
          sm={2}
          md={2}
          lg={2}
          className={classes.centered}>
          <Typography>Action</Typography>
        </Grid>
      </Hidden>
    </Grid>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {
      "& .MuiTypography-body1": {
        color: "#919293"
      },
      backgroundColor: "white"
    },
    name: {
      paddingLeft: "5%"
    },
    centered: {
      textAlign: "center" as const
    },
    pollutants: {
      display: "flex",
      justifyContent: "space-between" as const,
      overflow: "auto",
      alignItems: "center"
    },
    pollutantName: {
      textAlign: "center" as const
    }
  };
});

export default DevicesTableHeaderComponent;
