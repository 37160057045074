import { useMemo, useState } from "react";
import { makeStyles } from "tss-react/mui";
import Error from "src/shared/components/Error/Error";
import { WidgetSettings } from "../widgets-config";
import { GetCompanyIDFromBuildingId } from "src/utils/buildings.util";
import {
  PresetGauge,
  ahuOptionRanges,
  ahuPresetConfig,
  psiRanges,
  COLORS
} from "./preset-gauge.component";
import {
  FormControl,
  FormControlLabel,
  Typography,
  RadioGroup,
  Radio,
  CircularProgress
} from "@mui/material";
import useUserPascalSettings from "src/hooks/useUserPascalSettings";
import {
  DeviceGroup,
  getParentsForInput,
  getSensorsWithStageNameForInput
} from "../../utils/entity-selection-utils";
import useGetWidgetSettingsEntities from "src/hooks/useGetWidgetSettingsEntities";
import {
  convertFromIWGToPascals,
  convertRangesValue
} from "src/utils/pressure.utils";
import SelectRange from "./range";
import FormInput from "src/shared/components/form-input/form-input.component";
import FormAction from "src/shared/components/form-action/form-action";

export type Range = {
  to: number;
  from: number;
  color: string;
};

// Values in IWG which will be converted to pascals
const defaultRanges: Range[] = [
  { from: 0, to: 124, color: COLORS.OLD_GREEN }, // green
  { from: 124, to: 249, color: COLORS.OLD_YELLOW }, //yellow
  { from: 249, to: 496, color: COLORS.OLD_RED } //red
];
// color: '#ff9800' // orange if we want it later

export enum GAUGES {
  KETTLE_GAUGE = "Kettle",
  AHU_GAUGE = "AHU"
}

const SelectAhuStageSettingsComponent = (props: WidgetSettings) => {
  const { onSave, currentProps } = props;
  const { classes } = useStyles();
  const isUserUsingPascal = useUserPascalSettings();
  const [buildingId, setBuildingId] = useState<string>(
    currentProps?.buildingId ?? ""
  );
  const [ahuId, setAhuId] = useState<string>(currentProps?.ahuId ?? "");
  const [stageId, setStageId] = useState<string>(currentProps?.stageId ?? "");
  const [sensorId, setSensorId] = useState<string>(currentProps.sensorId ?? "");
  const [kettleId, setKettleId] = useState<string>(
    currentProps?.kettleId ?? ""
  );
  const [ranges, setRanges] = useState(
    convertRangesValue(isUserUsingPascal, currentProps?.ranges ?? defaultRanges)
  );
  const [rangeOption, setRangeOption] = useState<string>(
    currentProps?.gaugeType === GAUGES.KETTLE_GAUGE ? "custom" : "preset"
  );
  const [presetChoice, setPresetChoice] = useState<number>(0);
  const [gaugeType, setGaugeType] = useState<GAUGES>(
    currentProps?.gaugeType ?? GAUGES.AHU_GAUGE
  );
  const isAhuGauge = gaugeType === GAUGES.AHU_GAUGE;
  const {
    buildings,
    deviceParents,
    devices,
    isLoading,
    isFetching,
    isError,
    refetch
  } = useGetWidgetSettingsEntities();

  const ahuBuildings = buildings?.filter(building => building.has_ahus);
  const kettleBuildings = buildings?.filter(building => building.has_kettles);

  const deviceParentsForInput = useMemo(
    () =>
      getParentsForInput(
        deviceParents,
        buildingId,
        gaugeType === GAUGES.AHU_GAUGE ? DeviceGroup.hvac : DeviceGroup.kettle
      ),
    [deviceParents, devices, buildingId, gaugeType, isLoading, isFetching]
  );

  const sensorsForInput = useMemo(
    () =>
      getSensorsWithStageNameForInput(
        deviceParents,
        devices,
        buildingId,
        ahuId
      ),
    [deviceParents, devices, buildingId, ahuId, isLoading, isFetching]
  );

  const handleOnSave = () => {
    // To prevent issues with ranges when user is making gauge and changing system
    // setting to IWG or Pascals, we will save every range to pascals
    // and calculate if user is using IWG
    const covertedRanges = convertRangesValue(isUserUsingPascal, ranges, true);
    onSave({
      ...currentProps,
      buildingId,
      companyId: GetCompanyIDFromBuildingId(buildingId, buildings),
      ranges: covertedRanges,
      ahuId,
      stageId,
      sensorId,
      kettleId,
      gaugeType
    });
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRangeOption((event.target as HTMLInputElement).value);
  };

  const handleGaugeRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setGaugeType(event.target.value as GAUGES);
    if (event.target.value === "Kettle") {
      setRanges(psiRanges);
      setRangeOption("custom");
      setAhuId("");
      setStageId("");
    } else {
      handlePresetClick(0);
      setRangeOption("preset");
      setKettleId("");
    }
    setBuildingId("");
  };

  const handlePresetClick = (option: number) => {
    setPresetChoice(option);
    const chosenRanges: Range[] = ahuOptionRanges.at(option);
    const convertedRanges = chosenRanges.map(({ to, from, color }) => {
      return {
        from: isUserUsingPascal ? convertFromIWGToPascals(from) : from,
        to: isUserUsingPascal ? convertFromIWGToPascals(to) : to,
        color
      };
    });
    setRanges(convertedRanges);
  };

  const handleOnBuildingChange = (value: string) => {
    setAhuId("");
    setStageId("");
    setBuildingId(value);
  };
  const handleOnAhuChange = (value: string) => {
    const selectedAhu = deviceParentsForInput?.find(ahu => ahu.id === value);
    if (selectedAhu) {
      setBuildingId(selectedAhu.building_id);
    }
    setStageId("");
    setAhuId(value);
  };
  const handleOnKettleChange = (value: string) => {
    const selectedKettle = deviceParentsForInput?.find(
      kettle => kettle.id === value
    );
    if (selectedKettle) {
      setBuildingId(selectedKettle.building_id);
    }
    setKettleId(value);
  };

  const handelOnStageChange = (value: string) => {
    const selectedSensor = sensorsForInput?.find(sensor => sensor.id === value);
    if (selectedSensor) {
      setBuildingId(selectedSensor.building_id);
      setAhuId(selectedSensor.ahu_id);
    }
    setStageId(selectedSensor.stage_id);
    setSensorId(selectedSensor.id);
  };
  return (
    <div
      data-testid="ahu-stage-gauge-settings"
      className={classes.root}>
      {isLoading || isFetching ? (
        <div
          className={classes.loader}
          data-testid="ahu-loader-component">
          <CircularProgress />
        </div>
      ) : (
        <div>
          {isError ? (
            <Error
              error={isError}
              setRefetchAgain={refetch}
              showGenericMessage
            />
          ) : (
            <div>
              <div className={classes.radioButtonContainer}>
                <Typography
                  gutterBottom
                  variant="h5"
                  sx={{ fontSize: 18, marginRight: 4 }}>
                  Gauge Type
                </Typography>
                <FormControl>
                  <RadioGroup
                    row
                    value={gaugeType}
                    name="gauge-options-group"
                    onChange={handleGaugeRadioChange}>
                    <FormControlLabel
                      value={GAUGES.AHU_GAUGE}
                      control={<Radio />}
                      label={GAUGES.AHU_GAUGE}
                    />
                    <FormControlLabel
                      value={GAUGES.KETTLE_GAUGE}
                      control={<Radio />}
                      label={GAUGES.KETTLE_GAUGE}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div data-testid="ahu-stage-building-select">
                <FormInput
                  label="Building"
                  inputValue={buildingId}
                  setValue={handleOnBuildingChange}
                  array={
                    isAhuGauge ? ahuBuildings : kettleBuildings
                  }></FormInput>
              </div>

              <div className={classes.ahuSelector}>
                <FormInput
                  inputValue={isAhuGauge ? ahuId : kettleId}
                  array={deviceParentsForInput}
                  setValue={
                    isAhuGauge ? handleOnAhuChange : handleOnKettleChange
                  }
                  label={isAhuGauge ? "AHU" : "Kettle"}></FormInput>
              </div>
              {isAhuGauge && (
                <div className={classes.ahuSelector}>
                  <FormInput
                    label="Stage"
                    inputValue={sensorId}
                    setValue={handelOnStageChange}
                    array={sensorsForInput}></FormInput>
                </div>
              )}
              <div>
                <div style={{ display: "flex" }}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    sx={{ marginTop: 1.5, fontSize: 18, marginRight: 4 }}>
                    Ranges
                  </Typography>
                  <FormControl>
                    <RadioGroup
                      row
                      value={rangeOption}
                      name="range-options-group"
                      onChange={handleRadioChange}>
                      <FormControlLabel
                        value="preset"
                        control={<Radio />}
                        label="Preset"
                      />
                      <FormControlLabel
                        value="custom"
                        control={<Radio />}
                        label="Custom"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>

                {rangeOption === "preset" ? (
                  <div className={classes.optionsContainer}>
                    <div className={classes.optionColumns}>
                      {ahuPresetConfig.map(({ maxValue, option }) => {
                        return (
                          <div
                            key={option}
                            className={`${classes.presetOption}
                      ${
                        presetChoice === option
                          ? classes.clickedOption
                          : classes.notClicked
                      }`}
                            onClick={() => handlePresetClick(option)}>
                            <PresetGauge
                              ranges={ahuOptionRanges.at(option)}
                              maxValue={maxValue}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <div>
                    {ranges.map((range, index) => {
                      return (
                        <SelectRange
                          isPSI={gaugeType === GAUGES.KETTLE_GAUGE}
                          key={index}
                          ranges={ranges}
                          range={range}
                          index={index}
                          setRanges={setRanges}></SelectRange>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
      <FormAction
        saveDisabled={!Boolean(stageId) && !Boolean(kettleId)}
        requiresConfig={currentProps.requiresConfig}
        onSave={handleOnSave}></FormAction>
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {
      height: "100%"
    },
    loader: {
      display: "flex",
      justifyContent: "center"
    },

    ahuSelector: {
      marginTop: "1em"
    },
    rangeRow: {
      display: "flex",
      gap: 12,
      alignItems: "center"
    },
    optionsContainer: {
      display: "flex",
      justifyContent: "center"
    },
    optionColumns: {
      width: "100%",
      flexWrap: "wrap",
      display: "flex",
      maxWidth: 280
    },
    presetOption: {
      maxWidth: 130,
      width: "100%",
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
      borderRadius: "12px",
      background: "white",
      boxShadow: "0px 8px 26px rgba(0, 0, 0, 0.15)",
      margin: "4px",
      padding: "4px",
      transition: "background-color 0.3s ease",
      ":hover": {
        cursor: "pointer",
        boxShadow: "0px 8px 26px rgba(0, 0, 0, 0.25)"
      }
    },
    clickedOption: {
      border: "2px solid rgba(0, 0, 0, 0.2)"
    },
    notClicked: {
      border: "2px solid transparent"
    },
    radioButtonContainer: {
      display: "flex",
      alignItems: "center",
      marginBottom: ".5rem"
    }
  };
});

export default SelectAhuStageSettingsComponent;
