/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcknowledgementStatus } from '../models/AcknowledgementStatus';
import type { AlertReturn } from '../models/AlertReturn';
import type { Ids } from '../models/Ids';
import type { NotificationDefinition } from '../models/NotificationDefinition';
import type { NotificationDefinitionNew } from '../models/NotificationDefinitionNew';
import type { NotificationDefinitionReturn } from '../models/NotificationDefinitionReturn';
import type { NotificationDefinitionUpdates } from '../models/NotificationDefinitionUpdates';
import type { NotificationDeviceParentsResp } from '../models/NotificationDeviceParentsResp';
import type { NotificationDevicesResp } from '../models/NotificationDevicesResp';
import type { NotificationSubscription } from '../models/NotificationSubscription';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class NotificationsService {
  /**
   * Get Alerts For Notification
   * @param notificationId
   * @param start
   * @param end
   * @param alertStateFilter
   * @returns AlertReturn Successful Response
   * @throws ApiError
   */
  public static getAlertsForNotification(
    notificationId: string,
    start?: (string | null),
    end?: (string | null),
    alertStateFilter?: (AcknowledgementStatus | null),
  ): CancelablePromise<Array<AlertReturn>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/notifications/{notification_id}/alerts/',
      path: {
        'notification_id': notificationId,
      },
      query: {
        'start': start,
        'end': end,
        'alert_state_filter': alertStateFilter,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get All Notifications For Company
   * @param companyId
   * @returns NotificationDefinitionReturn Successful Response
   * @throws ApiError
   */
  public static getAllNotificationsForCompany(
    companyId: string,
  ): CancelablePromise<Array<NotificationDefinitionReturn>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/companies/company-notifications/',
      query: {
        'company_id': companyId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get All Notifications
   * @returns NotificationDefinitionReturn Successful Response
   * @throws ApiError
   */
  public static getAllNotifications(): CancelablePromise<Array<NotificationDefinitionReturn>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/all-notifications/',
    });
  }
  /**
   * Get Subscribed To Notifications For User
   * @param userId
   * @returns NotificationDefinitionReturn Successful Response
   * @throws ApiError
   */
  public static getSubscribedToNotificationsForUser(
    userId: string,
  ): CancelablePromise<Array<NotificationDefinitionReturn>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/subscribed-notifications/',
      query: {
        'user_id': userId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get All Building Notifications
   * @param buildingId
   * @returns NotificationDefinitionReturn Successful Response
   * @throws ApiError
   */
  public static getAllBuildingNotifications(
    buildingId: string,
  ): CancelablePromise<Array<NotificationDefinitionReturn>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/notifications/',
      path: {
        'building_id': buildingId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Delete Many Notifications
   * @param buildingId
   * @param notificationIds
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public static deleteManyNotifications(
    buildingId: string,
    notificationIds?: (Array<string> | null),
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/buildings/{building_id}/notifications/',
      path: {
        'building_id': buildingId,
      },
      query: {
        'notification_ids': notificationIds,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Create Notification
   * @param buildingId
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static createNotification(
    buildingId: string,
    requestBody: NotificationDefinitionNew,
  ): CancelablePromise<(NotificationDefinition | null)> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/buildings/{building_id}/notifications/',
      path: {
        'building_id': buildingId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Notification
   * @param notificationId
   * @param buildingId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getNotification(
    notificationId: string,
    buildingId: string,
  ): CancelablePromise<(NotificationDefinitionReturn | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/notifications/{notification_id}',
      path: {
        'notification_id': notificationId,
        'building_id': buildingId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Update Notification
   * @param notificationId
   * @param buildingId
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static updateNotification(
    notificationId: string,
    buildingId: string,
    requestBody: NotificationDefinitionUpdates,
  ): CancelablePromise<(NotificationDefinition | null)> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/buildings/{building_id}/notifications/{notification_id}',
      path: {
        'notification_id': notificationId,
        'building_id': buildingId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Delete Notification
   * @param notificationId
   * @param buildingId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static deleteNotification(
    notificationId: string,
    buildingId: string,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/buildings/{building_id}/notifications/{notification_id}',
      path: {
        'notification_id': notificationId,
        'building_id': buildingId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Toggle Enabled
   * @param buildingId
   * @param enabled
   * @param requestBody
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public static toggleEnabled(
    buildingId: string,
    enabled: boolean,
    requestBody: Ids,
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/buildings/{building_id}/notifications/toggle-enabled/',
      path: {
        'building_id': buildingId,
      },
      query: {
        'enabled': enabled,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Notification Types
   * Helper endpoint for web portal to get the Alert types available to make a notification definition for.
   * Return date is static, unless the underlying AlertDeviceType model changes.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static notificationTypes(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/notifications/types/',
    });
  }
  /**
   * Notification Devices Parents
   * Helper endpoint for web portal to get lists of parent entities inside buildings for notification devices available to make a notification definition for,
   * i.e. AHUs for hvac sensors, kettles for kettle sensors, rooms for iaq devices.
   * @returns NotificationDeviceParentsResp Successful Response
   * @throws ApiError
   */
  public static notificationDevicesParents(): CancelablePromise<NotificationDeviceParentsResp> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/notifications/entities/',
    });
  }
  /**
   * Notification Devices
   * Helper endpoint for web portal to get lists of aq, hvac, and kettle devices available to make a notification definition for.
   * @returns NotificationDevicesResp Successful Response
   * @throws ApiError
   */
  public static notificationDevices(): CancelablePromise<NotificationDevicesResp> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/notifications/devices/',
    });
  }
  /**
   * Get All Notification Subscriptions For Notification
   * @param notificationId
   * @param buildingId
   * @returns NotificationSubscription Successful Response
   * @throws ApiError
   */
  public static getAllNotificationSubscriptionsForNotification(
    notificationId: string,
    buildingId: string,
  ): CancelablePromise<Array<NotificationSubscription>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/notifications/{notification_id}/subscriptions/',
      path: {
        'notification_id': notificationId,
        'building_id': buildingId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Subscribe User To Notification
   * Subscribes the user with `user_id` to the `notification_id`
   * @param buildingId
   * @param notificationId
   * @param userId
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public static subscribeUserToNotification(
    buildingId: string,
    notificationId: string,
    userId: string,
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/buildings/{building_id}/notifications/{notification_id}/subscriptions/',
      path: {
        'building_id': buildingId,
        'notification_id': notificationId,
      },
      query: {
        'user_id': userId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Unsubscribe User From Notification
   * @param buildingId
   * @param notificationId
   * @param userId
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public static unsubscribeUserFromNotification(
    buildingId: string,
    notificationId: string,
    userId: string,
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/buildings/{building_id}/notifications/{notification_id}/subscriptions/',
      path: {
        'building_id': buildingId,
        'notification_id': notificationId,
      },
      query: {
        'user_id': userId,
      },
      errors: {
        400: `Bad subscription`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get All Possible User Notification Definitions
   * Returns the full NotificationDefinition object for all the notifications available to the _requesting_ user based on the buildings the user has access to.
   * @returns NotificationDefinitionReturn Successful Response
   * @throws ApiError
   */
  public static getAllPossibleUserNotificationDefinitions(): CancelablePromise<Array<NotificationDefinitionReturn>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/available-notifications/',
    });
  }
}
