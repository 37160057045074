import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => {
  return {
    alert_typeIcon: {
      marginRight: "0.5em"
    },
    alert_typeBtn: {
      marginLeft: 0,
      width: "100%",
      height: 50,
      lineHeight: 1.2
    }
  };
});
