import { Stack, Typography, alpha } from "@mui/material";

interface KeyValueRowProps {
  text: string;
  value: string;
}

export default function KeyValueRow({ text, value }: KeyValueRowProps) {
  const size = { xl: "0.75rem", lg: "0.7rem", md: "0.8rem" };

  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      px={"2px"}
      py={"4px"}>
      <Typography
        color={alpha("#000000", 0.75)}
        fontSize={size}>
        {text}
      </Typography>
      <Typography
        fontWeight={500}
        fontSize={size}>
        {value}
      </Typography>
    </Stack>
  );
}
