import Button from "@mui/material/Button";
import toast from "react-hot-toast";
import { TextField } from "@mui/material";
import { useState } from "react";
import Dropdown, { Option } from "src/components/Dropdown";
import ModalComponent from "src/shared/components/modal/modal.component";
import { Company, CompanyInvitationsService } from "src/utils/client";
import { makeStyles } from "tss-react/mui";
import { EndUserRole } from "src/utils/client";
interface IProps {
  isOpened: boolean;
  refreshMembershipData: () => void;
  setInviteMemberModalOpened: (isOpened: boolean) => void;
  companies: Array<Company>;
}

const roles = [
  { id: "companyAdmin", name: "CompanyAdmin" },
  { id: "userWrite", name: "UserWrite" },
  {
    id: "userRead",
    name: "UserRead"
  }
];

function InviteMember({
  isOpened,
  refreshMembershipData,
  setInviteMemberModalOpened,
  companies
}: IProps) {
  const { classes } = useStyles();
  const [userEmail, setUserEmail] = useState<string>("");
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
  const [selectedRole, setSelectedRole] = useState<Option | null>(null);
  const [userEnteredCorrectEmail, setUserEnteredCorrectEmail] = useState(true);
  const handleChangeCompany = (company: Company) => {
    setSelectedCompany(company);
  };

  const handleChangeRole = newRole => {
    setSelectedRole(newRole);
  };

  const getRole = () => {
    if (selectedRole?.id === "companyAdmin") return EndUserRole.COMPANY_ADMIN;
    if (selectedRole?.id === "userWrite") return EndUserRole.USER_WRITE;
    if (selectedRole?.id === "userRead") return EndUserRole.USER_READ;
  };
  const resetInputFields = () => {
    setSelectedRole(null);
    setSelectedCompany(null);
    setUserEmail("");
  };

  const handleOnClose = () => {
    setInviteMemberModalOpened(false);
    resetInputFields();
  };

  const inviteUser = () => {
    CompanyInvitationsService.inviteUserToCompany(selectedCompany?.id, {
      user_email: userEmail,
      role: getRole()
    })
      .then(() => {
        toast.success("User has been invited");
        refreshMembershipData();
        handleOnClose();
      })
      .catch(() => {
        toast.error("User has not been invited. Please try again.");
      });
  };

  const isInvatationButtonDisabled =
    !Boolean(selectedCompany) ||
    !Boolean(userEmail) ||
    !userEnteredCorrectEmail ||
    !Boolean(selectedRole);

  const validateEmail = (email: string) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      if (!userEnteredCorrectEmail) {
        setUserEnteredCorrectEmail(true);
      }
      return true;
    }
    setUserEnteredCorrectEmail(false);
    return false;
  };
  return (
    <ModalComponent
      onClose={handleOnClose}
      open={isOpened}>
      <>
        <h3>Invite Member</h3>
        <div className={classes.inputWrapper}>
          <Dropdown
            label="Company"
            options={companies}
            selected={selectedCompany}
            handleChange={handleChangeCompany}
          />
        </div>
        <div className={classes.inputWrapper}>
          <TextField
            fullWidth
            label="User email"
            placeholder="User email"
            value={userEmail}
            error={!userEnteredCorrectEmail}
            helperText={!userEnteredCorrectEmail && "Invalid address"}
            onChange={({ target: { value } }) => {
              setUserEmail(value);
              validateEmail(value);
            }}
          />
        </div>
        <div className={classes.inputWrapper}>
          <Dropdown
            label="Role"
            options={roles}
            selected={selectedRole}
            handleChange={handleChangeRole}
          />
        </div>

        <div className={classes.btnWrapper}>
          <Button
            variant="text"
            onClick={inviteUser}
            disabled={isInvatationButtonDisabled}>
            Send invitation
          </Button>
        </div>
      </>
    </ModalComponent>
  );
}

export const useStyles = makeStyles()(() => {
  return {
    inputWrapper: {
      marginBottom: 16
    },
    btnWrapper: {
      display: "flex",
      flexDirection: "row-reverse"
    }
  };
});

export default InviteMember;
