import { createWidgetId } from "../view/utils";
import { IWidget } from "../view/types";
export const formatWidget = (widget: IWidget) => ({
  type: widget.type,
  gridData: widget.gridData,
  props: {
    ...widget.props,
    widgetId: createWidgetId(
      widget.props.requiresConfig,
      widget.props.widgetId
    ),
    requiresConfig: Boolean(widget.props.requiresConfig)
  }
});
