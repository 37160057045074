/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserSettings_Input } from './UserSettings_Input';
/**
 * Model specifically for creating a new user with a new password.
 */
export type AdminUserNew = {
  name: string;
  email: string;
  phone_number: string;
  settings?: UserSettings_Input;
  role: AdminUserNew.role;
  new_password: string;
};
export namespace AdminUserNew {
  export enum role {
    QLAIR_SUPER_ADMIN = 'QlairSuperAdmin',
    QLAIR_WRITE_ADMIN = 'QlairWriteAdmin',
    QLAIR_READ_ADMIN = 'QlairReadAdmin',
  }
}

