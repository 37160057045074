import { makeStyles } from "tss-react/mui";

import {
  Typography,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText
} from "@mui/material";

import {
  setTradefairSelectedDeviceId,
  setTradefairSelectedPollutant
} from "src/store/buildings/reducer";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import { getTradefairSelectedPollutant } from "src/store/buildings/selector";
import useGetTradefairInfo from "../../hooks/useGetTradefairInfo";
import useGetSelectedDevice from "../../hooks/useGetSelectedDevice";

const SidebarComponent = ({ drawerOpen }) => {
  let devices = [];
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const { mainData } = useGetTradefairInfo();
  const { selectedDevice } = useGetSelectedDevice();
  const selectedPollutant = useAppSelector(getTradefairSelectedPollutant);

  for (let key in mainData?.aq_devices) {
    let device = { ...mainData?.aq_devices[key] };
    devices.push({
      ...device,
      id: key,
      aqData: mainData?.aq_data[key]
    });
  }

  devices = devices.sort((_, next) => {
    if (!next.aqData) {
      return -1;
    }
    return 0;
  });

  return (
    <div
      className={drawerOpen ? classes.sidebarOpen : classes.sidebarClosed}
      data-testid="sidebar">
      {!selectedDevice && (
        <div className={classes.pollutantList}>
          <Typography
            variant="h6"
            className={classes.subtitle}
            gutterBottom>
            Pollutant
          </Typography>
          <div>
            {mainData.pollutant_list.map(pollutant => (
              <Button
                data-testid={pollutant.key}
                variant={
                  selectedPollutant === pollutant.key ? "contained" : "outlined"
                }
                className={classes.pollutantBtn}
                key={pollutant.key}
                onClick={() => {
                  dispatch(setTradefairSelectedPollutant(pollutant.key));
                }}>
                {pollutant.label}
              </Button>
            ))}
          </div>
        </div>
      )}
      <div className={classes.deviceList}>
        <Typography
          variant="h6"
          className={classes.subtitle}>
          Device List
        </Typography>
        <Typography
          variant="subtitle2"
          gutterBottom>
          {mainData?.building_name}, {mainData?.building_address}
        </Typography>
        <div>
          <List>
            {devices.map(device => (
              <ListItem
                className={
                  selectedDevice && selectedDevice.id === device.id
                    ? classes.selected
                    : ""
                }
                key={device.id}
                disablePadding>
                <ListItemButton
                  data-testid={device.id}
                  onClick={() => {
                    let value = device.id;
                    if (selectedDevice && selectedDevice.id === device.id) {
                      value = null;
                    }
                    dispatch(setTradefairSelectedDeviceId(value));
                  }}>
                  <ListItemText
                    primary={
                      <small>{`${device.device_name} (${device.room})`}</small>
                    }
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </div>
      </div>
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {
      display: "flex",
      flexDirection: "column" as const,
      flex: 1,
      maxWidth: 300,
      padding: 16,
      "@media (max-width: 600px)": {
        padding: 16,
        maxWidth: 300
      }
    },
    pollutantList: {
      display: "flex",
      flexDirection: "column" as const,
      justifyContent: "center",
      flex: 0.35
    },
    subtitle: {
      fontSize: "18px"
    },
    pollutantBtn: {
      marginBottom: 8,
      marginRight: 8
    },
    deviceList: {
      flex: 0.7,
      overflow: "auto"
    },
    selected: {
      backgroundColor: "rgba(200, 200, 200, 0.8)"
    },
    sidebarOpen: {},
    sidebarClosed: { display: "none" }
  };
});

export default SidebarComponent;
