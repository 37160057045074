import { useAppSelector } from "src/hooks/useAppSelector";
import { getUserId } from "src/store/auth/selectors";
import {
  useGetNotificationsQuery,
  useGetSubscriptionsQuery
} from "src/store/services/notifications/notificationsService";
import { useState } from "react";
type Sortable = {
  bySubscribed: boolean;
  byEnabled: boolean;
};

const useGetNotificationsAndSubscriptions = () => {
  const userId = useAppSelector(getUserId);
  const [sort, setSort] = useState<Sortable>({
    bySubscribed: false,
    byEnabled: false
  });
  const {
    data: subscriptions,
    isLoading: isLoadingSubscriptions,
    isFetching: isFetchingSubscriptions
  } = useGetSubscriptionsQuery(userId);

  const {
    data: notifications,
    isLoading: isLoadingNotifications,
    isFetching: isFetchingNotifications
  } = useGetNotificationsQuery();

  const isSubscribed = (notification: { id: string }) =>
    subscriptions?.find(
      ({ notification_id }: { notification_id: string }) =>
        notification_id === notification.id
    );
  const isEnabled = ({ is_active }: { is_active: boolean }) => is_active;

  const customSort = (a: any, b: any) => {
    const aSubscribed = isSubscribed(a);
    const bSubscribed = isSubscribed(b);
    const aEnabled = isEnabled(a);
    const bEnabled = isEnabled(b);
    const bySubscribed = sort.bySubscribed;
    const byEnabled = sort.byEnabled;

    if (bySubscribed && !byEnabled) {
      if (aSubscribed && !bSubscribed) {
        return -1;
      } else if (!aSubscribed && bSubscribed) {
        return 1;
      }
    } else if (byEnabled && !bySubscribed) {
      if (aEnabled && !bEnabled) {
        return -1;
      } else if (!aEnabled && bEnabled) {
        return 1;
      }
    } else {
      if (aSubscribed && !bSubscribed) {
        return -1;
      } else if (!aSubscribed && bSubscribed) {
        return 1;
      } else if (aEnabled && !bEnabled) {
        return -1;
      } else if (!aEnabled && bEnabled) {
        return 1;
      }
    }

    return 0;
  };

  const handleSort = (field: "subscribed" | "enabled") => {
    if (field === "subscribed") {
      setSort({ bySubscribed: !sort.bySubscribed, byEnabled: false });
    } else if (field === "enabled") {
      setSort({ bySubscribed: false, byEnabled: !sort.byEnabled });
    }
  };
  return {
    subscriptions,
    sortedNotifications: [...(notifications ?? [])].sort(customSort),
    handleSort,
    sort,
    isLoading: isLoadingNotifications || isLoadingSubscriptions,
    isFetching: isFetchingNotifications || isFetchingSubscriptions
  };
};

export default useGetNotificationsAndSubscriptions;
