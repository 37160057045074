import {
  useGetAllPaintBoothSensorDataQuery,
  useGetPaintBoothSensorsQuery
} from "src/store/services/booth/booth.service";
import { AggGranularity, PressureUnits } from "src/utils/client";
import moment from "moment";
import { useMemo } from "react";
import { useAppSelector } from "./useAppSelector";
import {
  getChartSensorsIds,
  getChartZoneSensorsIds,
  getSelectedBoothSensorId,
  getSelectedDetailsTab
} from "src/store/booth/selector";
import { capitalizeFirstLetter } from "src/utils/text.utils";
import { useGetUserSettings } from "src/sections/settings/hooks";
import useUserCelsiusSettings from "./useUserCelsiusSettings";
import { convertToFahrenheit } from "src/utils/units";
import { convertFromPascalsToIWG } from "src/utils/pressure.utils";
import { getUserClickedOnWidgetTitle } from "src/store/hvac/selectors";
import { useParams } from "react-router-dom";
import { colors } from "src/sections/hvac/air-handling-units/ahu-details/utils/accordion-background";

export const useGetChartIds = () => {
  const { buildingId, boothId } = useParams();
  const userClickedOnWidgetTitle = useAppSelector(getUserClickedOnWidgetTitle);
  const zoneSensorsIds = useAppSelector(getChartZoneSensorsIds);

  const sensorsIds = useAppSelector(getChartSensorsIds) ?? [];
  const selectedSensorId = useAppSelector(getSelectedBoothSensorId);
  const {
    data: sensors,
    isLoading: isLoadingSensors,
    isError: isErrorSensors,
    isFetching: isFetchingSensors
  } = useGetPaintBoothSensorsQuery({
    buildingId,
    boothId
  });

  const chartIds = sensors
    ? [
        ...(sensorsIds.length > 0
          ? sensorsIds
          : zoneSensorsIds.length > 0
          ? zoneSensorsIds
          : selectedSensorId && userClickedOnWidgetTitle
          ? [selectedSensorId]
          : [sensors.map(({ id }) => id)?.at(0)])
      ]
    : [];
  return {
    chartIds,
    sensors,
    isLoadingSensors,
    isErrorSensors,
    isFetchingSensors
  };
};

const useGetBoothChartData = (
  startDate: Date,
  endDate: Date,
  granularity: AggGranularity
) => {
  const { pressure } = useGetUserSettings();
  const { buildingId } = useParams();
  const isUserUsingCelsus = useUserCelsiusSettings();
  const {
    chartIds,
    sensors,
    isLoadingSensors,
    isErrorSensors,
    isFetchingSensors
  } = useGetChartIds();
  const startDateUnix = moment(startDate).unix().toString();
  const endDateUnix = moment(endDate).endOf("day").unix().toString();
  const selectedTab = useAppSelector(getSelectedDetailsTab);
  const {
    data: sensorData,
    isLoading: isLoadingSensorData,
    isFetching: isFetchingSensorData,
    isError: isErrorSensorData
  } = useGetAllPaintBoothSensorDataQuery({
    sensorsId: chartIds,
    buildingId,
    startDate: startDateUnix,
    endDate: endDateUnix,
    granularity
  });

  const getUnit = () => {
    switch (selectedTab) {
      case "pressure": {
        if (pressure === PressureUnits.PASCALS) return "Pa";
        if (pressure === PressureUnits.IWG) return "IWG";
        return "PSI";
      }
      case "temperature":
        if (isUserUsingCelsus) return "°C";
        return "°F";
      case "humidity":
        return "%";
    }
  };

  const chartData = useMemo(() => {
    if (!sensorData || !sensors) return [];
    const activeTab =
      selectedTab === "pressure" ? "pressure_drop" : selectedTab;

    return sensorData?.map((data, index: number) => {
      return {
        labels: data.map(({ time }) => moment(time).toDate().valueOf()),
        tension: 0.5,
        borderColor: colors[index],
        yTitle: `${capitalizeFirstLetter(selectedTab)} (${getUnit()})`,
        label: sensors.find(({ id }) => id === data?.at(0)?.sensorId)?.name,
        data: data?.map(({ data, time }) => ({
          value:
            selectedTab === "temperature" && !isUserUsingCelsus
              ? convertToFahrenheit(data[activeTab]?.mean)
              : selectedTab === "pressure" && pressure === PressureUnits.IWG
              ? data[activeTab]?.mean
              : convertFromPascalsToIWG(data[activeTab]?.mean),
          date: moment(time).toDate().valueOf()
        }))
      };
    });
  }, [selectedTab, sensorData, sensors]);

  return {
    chartData,
    chartDataExist: Boolean(chartData?.at(0)?.data.length),
    isLoading: isLoadingSensors || isLoadingSensorData,
    isFetching: isFetchingSensors || isFetchingSensorData,
    isError: isErrorSensorData || isErrorSensors
  };
};

export default useGetBoothChartData;
