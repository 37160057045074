import Switch from "@mui/material/Switch";
import { makeStyles } from "tss-react/mui";

const CustomSwitchComponent = () => {
  const { classes } = useStyles();
  return (
    <Switch
      data-testid="custom-switch"
      className={classes.root}
    />
  );
};

export const useStyles = makeStyles()(theme => {
  return {
    root: {
      width: 44,
      height: 24,
      padding: 0,
      "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        transitionDuration: "300ms",
        "&.Mui-checked": {
          transform: "translateX(20px)",
          color: "#fff",
          "& + .MuiSwitch-track": {
            backgroundColor:
              theme.palette.mode === "dark" ? "#38C183" : "#38C183",
            opacity: 1,
            border: 0
          },
          "&.Mui-disabled + .MuiSwitch-track": {
            opacity: 0.5
          }
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
          color: "#33cf4d",
          border: "6px solid #fff"
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
          color:
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[600]
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: theme.palette.mode === "light" ? 0.7 : 0.3
        }
      },
      "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 20,
        height: 20
      },
      "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
          duration: 500
        })
      }
    }
  };
});

export default CustomSwitchComponent;
