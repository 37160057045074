import { useState } from "react";
import { WidgetSettings } from "../widgets-config";
import useGetBuildings from "src/hooks/useGetBuildings";
import LoaderComponent from "src/shared/components/loader/loader.component";
import Error from "src/shared/components/Error/Error";
import { Typography } from "@mui/material";
import SelectRange from "../ahu-stage-gauge-settings/range";
import { useStyles } from "../ahu-stage-gauge-settings/ahu-stage-gauge-settings.component";
import { Range, convertRangesValue } from "src/utils/pressure.utils";
import {
  useGetPaintBoothSensorsQuery,
  useGetPaintBoothsWithZonesQuery
} from "src/store/services/booth/booth.service";
import useUserPascalSettings from "src/hooks/useUserPascalSettings";
import FormInput from "src/shared/components/form-input/form-input.component";
import FormAction from "src/shared/components/form-action/form-action";
const defaultRanges: Range[] = [
  { from: 0, to: 124, color: "#C13838" },
  { from: 124, to: 249, color: "#38C183" },
  { from: 249, to: 496, color: "#C13838" }
];

const PaintBoothSettings = ({ onSave, currentProps }: WidgetSettings) => {
  const { classes } = useStyles();
  const { buildings } = useGetBuildings();
  const isUserUsingPascals = useUserPascalSettings();
  const [facilityId, setFacilityId] = useState(currentProps?.buildingId ?? "");
  const [paintBoothId, setPaintBoothId] = useState(
    currentProps?.paintBoothId ?? ""
  );
  const [boothSectionId, setBoothSectionId] = useState(
    currentProps?.zoneId ?? ""
  );
  const [sensorId, setSensorId] = useState(currentProps?.sensorId ?? "");
  const [ranges, setRanges] = useState(
    convertRangesValue(
      isUserUsingPascals,
      currentProps?.ranges ?? defaultRanges
    )
  );

  const {
    data: boothsWithZones,
    isLoading,
    isError: error
  } = useGetPaintBoothsWithZonesQuery(facilityId, { skip: !facilityId });

  const { data: sensors } = useGetPaintBoothSensorsQuery(
    { buildingId: facilityId, boothId: paintBoothId },
    { skip: !paintBoothId }
  );

  const paintBooth =
    boothsWithZones &&
    Object.values(boothsWithZones)
      .map(booths => booths.paint_booth)
      .filter(({ building_id }) => building_id === facilityId);

  const zones =
    boothsWithZones &&
    Object.values(boothsWithZones)
      .flatMap(booths => Object.values(booths.zones))
      .filter(({ booth_id }) => booth_id === paintBoothId);

  const sensorsForInput = sensors?.filter(
    ({ zone_id }) => zone_id === boothSectionId
  );

  const handleOnSave = () => {
    const covertedRanges = convertRangesValue(isUserUsingPascals, ranges, true);
    onSave({
      ...currentProps,
      buildingId: facilityId,
      ranges: covertedRanges,
      paintBoothId,
      zoneId: boothSectionId,
      sensorId
    });
  };

  return (
    <div className={classes.root}>
      {isLoading && !error && <LoaderComponent />}
      {error ? (
        <Error
          error={error}
          setRefetchAgain={() => {}}
          showGenericMessage
        />
      ) : (
        <div>
          <div>
            <FormInput
              label="Facility"
              inputValue={facilityId}
              array={buildings}
              setValue={setFacilityId}
              showHelperText
              helperText="Input your Facility Name"></FormInput>
          </div>
          <div>
            <FormInput
              label="Paint Booth"
              inputValue={paintBoothId}
              array={paintBooth}
              setValue={setPaintBoothId}
              showHelperText
              helperText="E.g. Paint Booth 1"></FormInput>
          </div>
          <div>
            <FormInput
              label="Booth Section"
              inputValue={boothSectionId}
              array={zones}
              setValue={setBoothSectionId}
              showHelperText
              helperText="E.g. Tack Zone, Robot Zone1"></FormInput>
          </div>
          <div>
            <FormInput
              label="Paint Sensor"
              inputValue={sensorId}
              array={sensorsForInput}
              setValue={setSensorId}
              showHelperText
              helperText="E.g. Tack Booth, Robot Booth"></FormInput>
          </div>
          <div>
            <div style={{ display: "flex" }}>
              <Typography
                gutterBottom
                variant="h5"
                sx={{ marginTop: 1.5, fontSize: 18, marginRight: 4 }}>
                Ranges
              </Typography>
            </div>
            <div>
              {ranges?.map((range: Range, index: number) => {
                return (
                  <SelectRange
                    key={index}
                    ranges={ranges}
                    range={range}
                    index={index}
                    setRanges={setRanges}></SelectRange>
                );
              })}
            </div>
          </div>
          <FormAction
            saveDisabled={!Boolean(boothSectionId)}
            requiresConfig={currentProps.requiresConfig}
            onSave={handleOnSave}></FormAction>
        </div>
      )}
    </div>
  );
};

export default PaintBoothSettings;
