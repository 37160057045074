import {
  DEFAULT,
  BUILDING_STATUSES
} from "src/shared/constants/building-status";
import useGetPolutantDictionary from "src/hooks/useGetPollutantDictionary";

export const useGetBuildingAQIStatus = (value: number, key: string) => {
  const { pollInfoDict } = useGetPolutantDictionary();
  const thresholds = pollInfoDict[key || "aqi"]?.statuses?.thresholds ?? [];
  const dictionaryStatus = thresholds.find(
    ({ low, high }) => value >= low && value <= high
  )?.status;

  const status =
    thresholds && value
      ? BUILDING_STATUSES.find(status => status === dictionaryStatus)
      : DEFAULT;

  return { status };
};
