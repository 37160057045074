import { RadialGauge } from "react-canvas-gauges";
import useUserPascalSettings from "src/hooks/useUserPascalSettings";
import { convertFromIWGToPascals } from "src/utils/pressure.utils";
import { PressureUnits } from "src/utils/client";

export enum COLORS {
  OLD_GREEN = "#4caf50",
  OLD_YELLOW = "#ffeb3b",
  OLD_RED = "#ff3d00",
  NEW_RED = "#C13838",
  NEW_GREEN = "#38C183"
}

export const PresetGauge = ({ ranges, maxValue, isPSI = false }) => {
  const isUserUsingPascals = useUserPascalSettings();

  return (
    <RadialGauge
      title="PRESSURE"
      units={
        isPSI
          ? PressureUnits.PSI.toUpperCase()
          : isUserUsingPascals
          ? PressureUnits.PASCALS.toLocaleUpperCase()
          : PressureUnits.IWG.toLocaleUpperCase()
      }
      exactTicks
      minValue={0}
      maxValue={
        isUserUsingPascals && !isPSI
          ? convertFromIWGToPascals(maxValue)
          : maxValue
      }
      fontNumbersSize={24}
      minorTicks={0}
      majorTicks={[
        0,
        ...ranges.map(({ to }: { to: number }) => {
          return isUserUsingPascals && !isPSI
            ? convertFromIWGToPascals(to).toFixed(1)
            : to.toFixed(isUserUsingPascals ? 0 : 1);
        })
      ]}
      highlights={ranges.map(range => ({
        ...range,
        from:
          isUserUsingPascals && !isPSI
            ? convertFromIWGToPascals(range.from)
            : range.from,
        to:
          isUserUsingPascals && !isPSI
            ? convertFromIWGToPascals(range.to)
            : range.to,

        color: range.color
      }))}
      height={115}
      width={115}
      colorValueText={"#fff"}
      value={0.2}
      valueText={(0.2).toFixed(2)}
      fontValueSize={24}
      fontValueWeight="bold"
      colorValueBoxBackground={"#4caf50"}
      borders={false}
      valueBoxStroke={2}
      borderShadowWidth={0}
    />
  );
};

export const ahuPresetConfig = [
  { maxValue: 2, option: 0 },
  { maxValue: 2, option: 1 },
  { maxValue: 2, option: 2 },
  { maxValue: 3, option: 3 }
];

export const psiRanges = [
  { from: 0, to: 10, color: COLORS.OLD_GREEN },
  { from: 10, to: 15, color: COLORS.OLD_YELLOW },
  { from: 15, to: 20, color: COLORS.OLD_RED }
];

export const ahuOptionRanges = [
  [
    { from: 0, to: 0.5, color: COLORS.OLD_GREEN },
    { from: 0.5, to: 1, color: COLORS.OLD_YELLOW },
    { from: 1, to: 2, color: COLORS.OLD_RED }
  ],
  [
    { from: 0, to: 0.8, color: COLORS.OLD_GREEN },
    { from: 0.8, to: 1.2, color: COLORS.OLD_YELLOW },
    { from: 1.2, to: 2, color: COLORS.OLD_RED }
  ],
  [
    { from: 0, to: 1.2, color: COLORS.OLD_GREEN },
    { from: 1.2, to: 1.5, color: COLORS.OLD_YELLOW },
    { from: 1.5, to: 2, color: COLORS.OLD_RED }
  ],
  [
    { from: 0, to: 1.2, color: COLORS.OLD_GREEN },
    { from: 1.2, to: 1.7, color: COLORS.OLD_YELLOW },
    { from: 1.7, to: 3, color: COLORS.OLD_RED }
  ]
];

export const boothOptionRanges = [
  [
    { from: 0, to: 2.2, color: COLORS.NEW_RED },
    { from: 2.2, to: 5, color: COLORS.NEW_GREEN },
    { from: 5, to: 10, color: COLORS.NEW_RED }
  ],
  [
    { from: 0, to: 2.2, color: COLORS.NEW_RED },
    { from: 2.2, to: 5, color: COLORS.NEW_GREEN },
    { from: 5, to: 10, color: COLORS.NEW_RED }
  ],
  [
    { from: 0, to: 2.2, color: COLORS.NEW_RED },
    { from: 2.2, to: 5, color: COLORS.NEW_GREEN },
    { from: 5, to: 10, color: COLORS.NEW_RED }
  ],
  [
    { from: 0, to: 2.2, color: COLORS.NEW_RED },
    { from: 2.2, to: 5, color: COLORS.NEW_GREEN },
    { from: 5, to: 10, color: COLORS.NEW_RED }
  ]
];
