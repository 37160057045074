import { useMemo } from "react";
import { makeStyles } from "tss-react/mui";
import SensorsIcon from "@mui/icons-material/Sensors";
import { Grid, Typography } from "@mui/material";
import { useGetUserSettings } from "src/sections/settings/hooks";
import useGetPolutantDictionary from "src/hooks/useGetPollutantDictionary";
import { shapeProgressBarData } from "../../utils/aq-progress-bars-utils";
import AQIStatus from "../../components/AqOverallScore/aqi-overall-score.component";
import ProgressBars from "../../components/ProgressBars/progress-bars.component";
import WidgetTitle from "../../components/WidgetTitle/WidgetTitle";
import { useDispatch } from "react-redux";
import { setUserClickedOnWidgetTitle } from "src/store/hvac/reducer";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { setSelectedOverviewTab } from "src/store/overview/reducer";
import useOverview from "src/hooks/useOverview";
import useGetBuildings from "src/hooks/useGetBuildings";
import usePaleteColor from "src/hooks/usePaleteColor";
import { useOverviewTab } from "../../hooks/useGetOverviewTab";

type Props = {
  deviceId?: string;
  roomId?: string;
  buildingId?: string;
  deviceName?: string;
  requiresConfig?: boolean;
};

const keys = ["t", "h", "voc", "cotwo", "pmone", "pmtwo", "pmten"];

const AqDeviceWidgetComponent = ({
  deviceId,
  roomId,
  buildingId,
  deviceName,
  requiresConfig
}: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { editing } = useOverview();
  const { tabId } = useOverviewTab();
  const { buildings, isError } = useGetBuildings();
  const { pollInfoDict } = useGetPolutantDictionary();
  const { temperature } = useGetUserSettings();
  const building = buildings.find(({ id }) => id === buildingId);
  const indoorDeviceData = building?.indoorData;
  const indoorData = indoorDeviceData?.data;
  const measurementTime = indoorDeviceData?.time
    ? moment(indoorDeviceData.time).format("lll")
    : "--";

  const indoorAqiValue = indoorData?.aqi?.value;

  const status = pollInfoDict?.aqi?.statuses?.thresholds.find(
    ({ high, low }) => high >= indoorAqiValue && low <= indoorAqiValue
  )?.status;

  const { statusColor: color } = usePaleteColor("status", status);

  const formattedData = useMemo(() => {
    if (!indoorData) return;
    return shapeProgressBarData(keys, indoorData, pollInfoDict, temperature);
  }, [indoorData]);

  const handleTitleClick = () => {
    if (editing) return;
    //@ts-ignore
    dispatch(setSelectedOverviewTab(tabId));
    dispatch(setUserClickedOnWidgetTitle(true));
    navigate(
      `/buildings/iaq/${buildingId}/rooms/${roomId}/devices/${deviceId}`
    );
  };

  return (
    <div
      className={classes.root}
      data-testid="aq-device">
      <WidgetTitle
        error={isError}
        building={building}
      />
      <div className={classes.widgetContent}>
        <div className={classes.header}>
          <div
            className={editing ? classes.default : classes.pointer}
            onClick={handleTitleClick}>
            <div style={{ marginRight: "0.5rem" }}>
              <SensorsIcon />
            </div>
            <Typography variant="h6">Device Air Quality</Typography>
          </div>
        </div>
        {requiresConfig ? (
          <Typography>Configuration is required</Typography>
        ) : (
          <div
            style={{
              marginTop: "-0.2em",
              height: "100%",
              overflow: "auto"
            }}>
            <Grid
              container
              spacing={1}
              className={classes.contentContainer}>
              <AQIStatus
                title={"Indoor AQI"}
                color={color}
                value={indoorData?.aqi?.value ?? 0}
                status={status}
              />
              <ProgressBars data={formattedData} />
            </Grid>

            <Typography
              variant="subtitle1"
              className={classes.deviceName}>
              {`${deviceName} || ${measurementTime}`}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {
      height: "100%",
      padding: "10px"
    },
    widgetContent: {
      width: "100%",
      display: "flex",
      flexDirection: "column"
    },
    header: {
      display: "flex"
    },
    loader: {
      position: "absolute",
      top: 0,
      left: 0
    },
    iconContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around"
    },
    mainLbl: {
      fontSize: "14px !important"
    },
    aqiNumber: {
      textAlign: "center"
    },
    aqiClass: {
      textAlign: "center",
      fontWeight: "bold",
      lineHeight: 1,
      marginTop: 10
    },
    sensorIcon: {
      fontSize: 37,
      color: "white"
    },
    sensorIndicator: {
      borderRadius: 8,
      padding: 4,
      lineHeight: 0.8
    },
    insight: {
      overflow: "auto",
      height: "100%",
      position: "relative",
      "@media(max-width:900px)": {
        maxHeight: "none"
      }
    },
    backBtn: {
      zoom: 0.6,
      marginRight: "0.5em"
    },
    contentContainer: {
      height: "104%",
      overflow: "hidden",
      "@media(max-width:900px)": {
        overflow: "auto"
      }
    },
    facility: {
      flex: 1,
      overflow: "hidden",
      textOverflow: "ellipsis",
      marginLeft: "0.5rem"
    },
    facilityName: {
      color: "grey",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textAlign: "right"
    },
    deviceName: {
      position: "absolute",
      bottom: 0,
      right: 16,
      fontSize: "14px !important"
    },
    default: {
      display: "flex",
      cursor: "default"
    },
    pointer: {
      display: "flex",
      cursor: "pointer",
      "&:hover": { color: "#0795bb" }
    }
  };
});

export default AqDeviceWidgetComponent;
