import moment from "moment";
import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import { useState } from "react";
import useOverview from "src/hooks/useOverview";
import { InfoOutlined } from "@mui/icons-material";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import useGetDataForAhu from "../hooks/useGetDataForAhu";
import LoaderComponent from "src/shared/components/loader/loader.component";
import Error from "src/shared/components/Error/Error";
import { setSelectedOverviewTab } from "src/store/overview/reducer";
import { styles } from "./service-logs-widget.style";
import {
  setSelectedAHU,
  setUserClickedOnWidgetTitle
} from "src/store/hvac/reducer";
import { useGetServiceLogQuery } from "src/store/services/servicelogs/servicelogs.service";
import { useGetKettlesForBuildingQuery } from "src/store/services/kettle/kettle.service";
import { useNavigate } from "react-router-dom";
import AddServiceLogComponent from "src/sections/hvac/air-handling-units/service-log/add-service-log/add-service-log.component";
import { setSelectedKettle } from "src/store/kettle/reducer";
import { useGetPaintBoothQuery } from "src/store/services/booth/booth.service";
import { setSelectedBooth } from "src/store/booth/reducer";
import { useOverviewTab } from "../../hooks/useGetOverviewTab";

type Props = {
  ahuId?: string;
  buildingId: string;
  kettleId?: string;
  paintBoothId?: string;
  requiresConfig?: boolean;
};

const ServiceLogsWidgetComponent = ({
  ahuId,
  buildingId,
  kettleId,
  paintBoothId,
  requiresConfig
}: Props) => {
  const style = styles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { editing } = useOverview();
  const [open, setOpenModal] = useState(false);
  const { tabId } = useOverviewTab();

  const {
    isLoading: isLoadingBuildingAndAhu,
    isFetching: isFetchingBuildingAndAhu,
    isError: isErrorBuildingAndAhu,
    refetchAhuData,
    ahu
  } = useGetDataForAhu(buildingId, ahuId, requiresConfig);

  const startDate = moment()
    .subtract(1, "month")
    .startOf("day")
    .unix()
    .toString();
  const endDate = moment().endOf("day").unix().toString();

  const isKettleServiceWidget = Boolean(kettleId);
  const isPaintBoothServiceWidget = Boolean(paintBoothId);

  const { data: kettles } = useGetKettlesForBuildingQuery(buildingId, {
    skip: !isKettleServiceWidget
  });

  const {
    data: paintBooth,
    isLoading: isLoadingPaintBooth,
    isFetching: isFetchingPaintBooth,
    isError: isErrorPaintBooth
  } = useGetPaintBoothQuery(
    {
      buildingId,
      boothId: paintBoothId
    },
    { skip: !paintBoothId }
  );

  const kettle = kettles?.find(({ id }) => kettleId === id);

  const {
    isLoading: isLoadingServiceLogRange,
    isError: isErrorServiceLogRange,
    isFetching: isFetchingServiceLogRange,
    refetch: refetchServiceLogs,
    data: serviceLogs
  } = useGetServiceLogQuery(
    {
      buildingId,
      ahuId,
      startDate,
      endDate,
      kettleId,
      paintBoothId
    },
    {
      skip: requiresConfig
    }
  );

  const loading =
    isLoadingBuildingAndAhu || isLoadingServiceLogRange || isLoadingPaintBooth;
  const fetching =
    isFetchingServiceLogRange ||
    isFetchingBuildingAndAhu ||
    isFetchingPaintBooth;
  const error =
    isErrorServiceLogRange || isErrorBuildingAndAhu || isErrorPaintBooth;
  const refetchAgain = () => {
    refetchAhuData();
    refetchServiceLogs();
  };

  const handleSeeMore = () => {
    if (editing) return;
    const url = isPaintBoothServiceWidget
      ? `/buildings/booth/${buildingId}/${paintBoothId}`
      : isKettleServiceWidget
      ? `/buildings/kettle/${buildingId}/${kettleId}`
      : `/buildings/hvac/${buildingId}/${ahuId}`;

    isPaintBoothServiceWidget
      ? dispatch(setSelectedBooth(paintBooth))
      : isKettleServiceWidget
      ? dispatch(setSelectedKettle(kettle))
      : dispatch(setSelectedAHU(ahu));
    dispatch(setSelectedOverviewTab(tabId));
    dispatch(setUserClickedOnWidgetTitle(true));
    navigate(url);
  };

  const handleAddNewLog = () => {
    setOpenModal(true);
  };

  return (
    <>
      <Box
        display="div"
        sx={style.root}>
        {requiresConfig ? (
          <>
            <Typography variant="h6">Service Logs</Typography>
            <Typography>Configuration is required</Typography>
          </>
        ) : loading || fetching ? (
          <LoaderComponent></LoaderComponent>
        ) : error ? (
          <Error
            setRefetchAgain={refetchAgain}
            error={error}></Error>
        ) : (
          <Box
            display="div"
            style={{ width: "100%", display: "flex", flexDirection: "column" }}>
            <Box
              display="div"
              sx={style.header}>
              <Typography
                variant="h6"
                sx={editing ? style.default : style.pointer}
                onClick={handleSeeMore}>
                Service Logs: {kettle?.name ?? ahu?.name ?? paintBooth?.name}
                <LinkIcon
                  fontSize="small"
                  sx={style.linkIcon}
                />
              </Typography>
              {!editing && (
                <Button
                  size="small"
                  onClick={handleAddNewLog}>
                  Add Log
                </Button>
              )}
            </Box>

            <Box
              display="div"
              style={{ flex: 1, overflow: "auto" }}>
              <Grid
                container
                sx={style.headerWrapper}>
                {Boolean(serviceLogs.length) && (
                  <>
                    <Grid
                      item
                      xs={4}>
                      <Typography
                        sx={style.headerItem}
                        variant="subtitle1"
                        gutterBottom>
                        Date
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}>
                      <Typography
                        sx={style.headerItem}
                        variant="subtitle1"
                        gutterBottom>
                        Title
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}>
                      <Typography
                        sx={style.headerItem}
                        variant="subtitle1"
                        gutterBottom>
                        Worker
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={1}>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={style.headerItem}></Typography>
                    </Grid>
                  </>
                )}
              </Grid>
              {Boolean(serviceLogs.length) &&
                serviceLogs.map((serviceLog, index) => {
                  return (
                    <Grid
                      sx={style.rowContainer}
                      key={`service_log_${index}`}
                      container>
                      <Grid
                        sx={style.rowItem}
                        item
                        xs={4}>
                        {moment(serviceLog.time).format("MMM DD")}
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sx={{
                          ...style.rowItem,
                          borderLeft: "2px solid rgba(0,0,0,0.2)"
                        }}>
                        {serviceLog.details.title}
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sx={{
                          ...style.rowItem,
                          borderLeft: "2px solid rgba(0,0,0,0.2)"
                        }}>
                        {serviceLog?.user?.name}
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        sx={{ ...style.rowItem, cursor: "pointer" }}>
                        <Tooltip
                          title={
                            <div style={{ whiteSpace: "pre-line" }}>
                              {serviceLog.details.notes}
                            </div>
                          }
                          placement="bottom-start">
                          <Typography>
                            <InfoOutlined sx={{ fontSize: 18 }} />
                          </Typography>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  );
                })}
              {!Boolean(serviceLogs.length) && (
                <Box display="div">
                  <>
                    <Typography
                      gutterBottom
                      variant="subtitle1">
                      No Service Logs in Last 30 Days
                    </Typography>
                    <Typography variant="body2">
                      Please create a new service log to view it here
                    </Typography>
                  </>
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>
      {!requiresConfig && (
        <AddServiceLogComponent
          kettle={isKettleServiceWidget ? kettle : null}
          ahu={isKettleServiceWidget ? null : ahu}
          paintBooth={paintBooth ?? null}
          open={open}
          setOpen={setOpenModal}></AddServiceLogComponent>
      )}
    </>
  );
};

export default ServiceLogsWidgetComponent;
