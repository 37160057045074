import {
  useGetBuildingSummariesQuery,
  useGetBuildingsEntityQuery
} from "src/store/services/buildings/buildings.service";
import { useAppSelector } from "./useAppSelector";
import { getSearchTerm, getSorterType } from "src/store/buildings/selector";
import { useCallback, useMemo } from "react";
import { FormattedBuildingWithEntity } from "src/shared/types/building";
import { useLocation } from "react-router-dom";

const useGetBuildings = (buildingId?: string) => {
  const location = useLocation();
  const searchTerm = useAppSelector(getSearchTerm);
  const sorterType = useAppSelector(getSorterType);

  const isSearchTermActive = searchTerm?.length > 0;

  const {
    data: buildings,
    isLoading: isLoadingBuildings,
    isFetching: isFetchingBuildings,
    isError: isErrorBuildings,
    refetch: refetchBuildings
  } = useGetBuildingSummariesQuery();

  const {
    data: entities,
    isLoading: isLoadingEnteties,
    isFetching: isFetchingEnteties,
    isError: isErrorEnteties,
    refetch: refetchEntries
  } = useGetBuildingsEntityQuery();

  const updatedBuildings = useMemo(() => {
    if (!Boolean(buildings) || !Boolean(entities)) return [];
    return buildings?.map(building => {
      const buildingId = building?.id;
      const entryData = entities[buildingId];
      return {
        ...building,
        ...entryData
      };
    });
  }, [buildings, entities]);

  const getSortedBuildings = useCallback(
    (buildings: FormattedBuildingWithEntity[], pathNameFilter: string) => {
      if (!Boolean(buildings)) return [];
      if (searchTerm?.length && location.pathname === pathNameFilter) {
        return buildings?.filter(({ name }) =>
          name.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }

      return [...buildings]?.sort((buildingA, buildingB) => {
        switch (sorterType) {
          case "name":
            return buildingA.name
              .toLowerCase()
              .localeCompare(buildingB.name.toLowerCase());
          case "outdoor":
            return (
              buildingB?.outdoorData?.data?.aqi?.value -
              buildingA?.outdoorData?.data?.aqi?.value
            );
          case "indoor":
            if (buildingB?.indoorData?.data?.aqi?.value === undefined)
              return -1;
            else
              return (
                buildingB?.indoorData?.data?.aqi?.value -
                buildingA?.indoorData?.data?.aqi?.value
              );
        }
      });
    },
    [searchTerm, sorterType]
  );

  const sortedBuildings = useMemo(() => {
    getSortedBuildings(updatedBuildings, "/buildings");
  }, [updatedBuildings, getSortedBuildings]);

  return {
    buildings: updatedBuildings as FormattedBuildingWithEntity[],
    isSearchTermActive,
    searchTerm,
    getSortedBuildings,
    sortedBuildings,
    building: buildings?.find(({ id }) => id === buildingId),
    isLoading: isLoadingBuildings || isLoadingEnteties,
    isFetching: isFetchingBuildings || isFetchingEnteties,
    isError: isErrorBuildings || isErrorEnteties,
    refetch: () => {
      refetchBuildings();
      refetchEntries();
    }
  };
};

export default useGetBuildings;
