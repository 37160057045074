import {
  Box,
  Button,
  Stack,
  Tab,
  Tabs,
  Typography,
  useTheme
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LinesChart, {
  XType
} from "src/shared/components/lines-chart/lines-chart.component";
import config from "src/utils/config";
import { useAppSelector } from "../../../hooks/useAppSelector";
import useUserPascalSettings from "../../../hooks/useUserPascalSettings";
import CostOfOwnershipDrawer from "../../../sections/hvac/air-handling-units/cost-of-ownership-drawer/cost-of-ownership-drawer.component";
import { getSelectedAhu } from "../../../store/hvac/selectors";
import { StageAndFilterload } from "../../../utils/client";
import TableOfOwnershipCosts from "../TableOfOwnershipCosts";

enum TabType {
  TCO = "tco",
  LCE = "lce",
  RECOMMENDED = "recommended"
}

const tabs = [
  {
    label: "Total Cost of Ownersip",
    value: TabType.TCO
  },
  {
    label: "Total Carbon Footprint",
    value: TabType.LCE
  }
];

interface CostOfOwnershipProps {
  open: boolean;
  TCOData: any;
  COOData: any;
  chartData: any;
  showTCOCurve?: any;
  activeAhuStage: StageAndFilterload | null;
  setIsDrawerOpen: any;
}

export default function CostOfOwnership({
  open,
  TCOData,
  COOData,
  chartData,
  showTCOCurve,
  activeAhuStage,
  setIsDrawerOpen
}: CostOfOwnershipProps) {
  const theme = useTheme();
  const isUserUsingPascals = useUserPascalSettings();
  const [curveData, setCurveData] = useState([]);
  const [selectedTab, setSelectedTab] = useState(TabType.TCO);
  const [horizontalRanges, setHorizontalRanges] = useState([]);
  const [tcoLegends, setTcoLegends] = useState([]);
  const [costTable, setCostTable] = useState([]);
  const { ahuId, buildingId } = useParams();
  const ahu = useAppSelector(getSelectedAhu);

  const curveChartYTitles = {
    [TabType.TCO]: `Annual Total Cost of Ownership (${config.currency})`,
    [TabType.LCE]: "Annual Carbon Footprint (tons)"
  };
  const currencyUnit = config.currency === "USD" ? "$" : "€";

  const [curveChartYTitle, setCurveChartYTitle] = useState(
    curveChartYTitles[TabType.TCO]
  );

  const defaultYAxisZoom = {
    min:
      selectedTab === "tco"
        ? 0
        : Math.min(
            ...(curveData[0]?.data?.map(item => Math.floor(item.value)) || [0])
          ) - 3000,
    max:
      Math.min(
        ...(curveData[0]?.data?.map(item => Math.floor(item.value)) || [0])
      ) * 3
  };

  const minMaxYAxis = curveData[0] &&
    (selectedTab === TabType.TCO || selectedTab === TabType.LCE) && {
      min: 0,
      max:
        curveData[0]?.data?.length > 100 &&
        Math.floor(curveData[0]?.data[10]?.value)
    };

  const getLowestCostTitle = () => {
    if (selectedTab === TabType.TCO) {
      const lowestCost = Math.min(...(TCOData?.tco_array || []));
      const lowestCostIndex = TCOData?.tco_array?.indexOf(lowestCost);
      return `Your Lowest Cost Change Interval is ${lowestCostIndex} days`;
    } else {
      const lowestCarbon = Math.min(...TCOData?.carbon_array);
      const lowestCarbonIndex = TCOData?.carbon_array?.indexOf(lowestCarbon);
      return `Your Lowest Carbon Emissions Change Interval is ${lowestCarbonIndex} days`;
    }
  };

  useEffect(() => {
    if (showTCOCurve?.buildingId === buildingId && TCOData && activeAhuStage) {
      const lowestCost = Math.min(...(TCOData?.tco_array || []));
      const lowestCostIndex = TCOData?.tco_array?.indexOf(lowestCost);
      const lowestCarbon = Math.min(...(TCOData?.carbon_array || []));
      const lowestCarbonIndex = TCOData?.carbon_array?.indexOf(lowestCarbon);
      const changeIntervalDays = 1;

      let data = [];
      let table = [];
      let optimizedIndex;

      if (selectedTab === TabType.TCO) {
        optimizedIndex = lowestCostIndex;
        setCurveChartYTitle(curveChartYTitles[TabType.TCO]);
        data = TCOData?.tco_array?.map((item, index) => {
          return {
            value: item,
            date: index
          };
        });
      } else if (selectedTab === TabType.LCE) {
        optimizedIndex = lowestCarbonIndex;
        setCurveChartYTitle(curveChartYTitles[TabType.LCE]);
        data = TCOData?.carbon_array?.map((item, index) => {
          return {
            value: item / 1000,
            date: index
          };
        });
      }

      const currentChangeFilterCost =
        TCOData?.filter_cost_array?.[changeIntervalDays] || 0;
      const currentChangeLaborCost =
        TCOData?.maintenance_cost_array?.[changeIntervalDays] || 0;
      const currentChangeEnergyCost =
        TCOData?.energy_cost_array?.[changeIntervalDays] || 0;
      const currentChangeCarbon =
        (TCOData?.carbon_array?.[changeIntervalDays] || 0) / 1000;
      const currentChangeTotalCost =
        TCOData?.tco_array?.[changeIntervalDays] || 0;

      const optimizedChangeFilterCost =
        TCOData?.filter_cost_array?.[optimizedIndex];
      const optimizedChangeLaborCost =
        TCOData?.maintenance_cost_array?.[optimizedIndex];
      const optimizedChangeEnergyCost =
        TCOData?.energy_cost_array?.[optimizedIndex];
      const optimizedChangeCarbon =
        TCOData?.carbon_array?.[optimizedIndex] / 1000;
      const optimizedChangeTotalCost = TCOData.tco_array?.[optimizedIndex];

      const filterDifference =
        optimizedChangeFilterCost - currentChangeFilterCost;
      const laborDifference = optimizedChangeLaborCost - currentChangeLaborCost;
      const energyDifference =
        optimizedChangeEnergyCost - currentChangeEnergyCost;
      const carbonDifference = optimizedChangeCarbon - currentChangeCarbon;
      const totalDifference = optimizedChangeTotalCost - currentChangeTotalCost;

      const getDifferenceFormatted = (
        difference: number,
        currencyFormat = true
      ) => {
        if (difference < 0) {
          return `${currencyFormat ? currencyUnit : ""}(${Math.abs(
            difference
          )})`;
        }
        return `${currencyFormat ? currencyUnit : ""}${difference}`;
      };

      table = [
        {
          costType: "Filter Cost",
          currentInterval: `${currencyUnit}${Math.round(
            currentChangeFilterCost
          )}`,
          optimizedInterval: `${currencyUnit}${Math.round(
            optimizedChangeFilterCost
          )}`,
          difference: getDifferenceFormatted(Math.round(filterDifference))
        },
        {
          costType: "Labor Cost",
          currentInterval: `${currencyUnit}${Math.round(
            currentChangeLaborCost
          )}`,
          optimizedInterval: `${currencyUnit}${Math.round(
            optimizedChangeLaborCost
          )}`,
          difference: getDifferenceFormatted(Math.round(laborDifference))
        },
        {
          costType: "Energy Cost",
          currentInterval: `${currencyUnit}${Math.round(
            currentChangeEnergyCost
          )}`,
          optimizedInterval: `${currencyUnit}${Math.round(
            optimizedChangeEnergyCost
          )}`,
          difference: getDifferenceFormatted(Math.round(energyDifference))
        },
        {
          costType: "Total Cost",
          currentInterval: `${currencyUnit}${Math.round(
            currentChangeTotalCost
          )}`,
          optimizedInterval: `${currencyUnit}${Math.round(
            optimizedChangeTotalCost
          )}`,
          difference: getDifferenceFormatted(Math.round(totalDifference))
        },
        {
          costType: "Carbon Footprint",
          currentInterval: `${Math.round(currentChangeCarbon)} tons`,
          optimizedInterval: `${Math.round(optimizedChangeCarbon)} tons`,
          difference: `${getDifferenceFormatted(
            Math.round(carbonDifference),
            false
          )} tons`
        }
      ];
      setCostTable(table);

      setHorizontalRanges([
        {
          value: changeIntervalDays,
          color: theme.palette.tcoHorizontalRanges.changeInterval
        },
        {
          value: lowestCostIndex,
          color: theme.palette.tcoHorizontalRanges.lowestCostIndex
        },
        {
          value: lowestCarbonIndex,
          color: theme.palette.tcoHorizontalRanges.lowestCarbonIndex
        }
      ]);

      setCurveData([{ short_name: "Total Cost Curve", data }]);

      setTcoLegends([
        {
          name: "Total Cost Curve",
          color: theme.palette.tcoHorizontalRanges.tcoCurve
        },
        {
          name: `Current Change Interval (${changeIntervalDays} days)`,
          color: theme.palette.tcoHorizontalRanges.changeInterval
        },
        {
          name: `Minimum TCO Interval (${lowestCostIndex} days)`,
          color: theme.palette.tcoHorizontalRanges.lowestCostIndex
        },
        {
          name: `Minimum Carbon Footprint (${lowestCarbonIndex} days)`,
          color: theme.palette.tcoHorizontalRanges.lowestCarbonIndex
        }
      ]);
    } else {
      setCurveData([]);
      setTcoLegends([]);
    }
  }, [showTCOCurve, TCOData, selectedTab, activeAhuStage]);

  return (
    <>
      <CostOfOwnershipDrawer
        open={open}
        setOpen={setIsDrawerOpen}
        asPascals={isUserUsingPascals}
        initialValues={COOData}
        ahuId={ahuId}
        ahu={ahu}
        stage={activeAhuStage}
        chartData={chartData}
        showTCOCurve={showTCOCurve}
        buildingId={buildingId}
      />

      {showTCOCurve?.buildingId === buildingId &&
        activeAhuStage &&
        chartData[0]?.data?.length > 0 && (
          <Stack sx={styles.curveCard}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              mb={1}>
              <Typography
                fontWeight={500}
                textTransform={"uppercase"}>
                Your expected total cost of ownership
              </Typography>

              <Button
                variant="text"
                onClick={() => {
                  setIsDrawerOpen(true);
                }}>
                Adjust Inputs
              </Button>
            </Stack>
            <Tabs
              data-testid="tabs"
              value={selectedTab}
              onChange={(_event, value) => {
                setSelectedTab(value);
              }}
              variant="scrollable"
              scrollButtons="auto"
              sx={{ display: "block", textAlign: "center" }}>
              {tabs.map(tab => (
                <Tab
                  data-testid="test-tab"
                  key={tab.value}
                  value={tab.value}
                  label={<Typography variant="button">{tab.label}</Typography>}
                />
              ))}
            </Tabs>

            <Box
              height={"400px"}
              mb={"20px"}>
              {/* @ts-ignore */}
              {Boolean(curveData?.data) && (
                <LinesChart
                  granularity={{ timeUnit: "day", count: 1 }}
                  // @ts-ignore
                  data={curveData?.data}
                  yTitle={curveChartYTitle}
                  xTitle="Change Interval (Days)"
                  xType={
                    selectedTab === TabType.TCO ? XType.MONEY : XType.WEIGHT
                  }
                  useDefaultMarker={true}
                  horizontalRanges={horizontalRanges}
                  defaultLegends={tcoLegends}
                  defaultStrokeColor={
                    theme.palette.tcoHorizontalRanges.tcoCurve
                  }
                  useYAxisScrollBar={true}
                  defaultYAxisZoom={defaultYAxisZoom}
                  minMaxYAxis={minMaxYAxis}
                  currencyUnit={currencyUnit}
                />
              )}
            </Box>

            <Typography
              textAlign={"center"}
              mt={1}
              fontWeight={500}>
              {getLowestCostTitle().toUpperCase()}
            </Typography>
            <TableOfOwnershipCosts costTable={costTable} />
          </Stack>
        )}
    </>
  );
}

const styles = {
  curveCard: {
    padding: "16px",
    mb: "16px",
    border: "2px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "1rem"
  }
};
