import {
  useGetAhuForBuildingQuery,
  useGetStagesForAhuWithFilterLoadQuery
} from "src/store/services/widgets/widgets.service";
const useGetFilterLoadData = (
  buildingId: string,
  ahuId: string,
  requiresConfig: boolean
) => {
  const {
    data: ahu,
    isLoading: isLoadingAhu,
    isError: isErrorAhu,
    refetch: refetchAhu
  } = useGetAhuForBuildingQuery(
    { buildingId, ahuId },
    { skip: requiresConfig }
  );

  const {
    data: stages,
    isLoading: isLoadingStagesData,
    isError: isErrorStagesData,
    refetch: refetchStagesData
  } = useGetStagesForAhuWithFilterLoadQuery(
    { buildingId, ahuId },
    { skip: requiresConfig }
  );

  const loading = isLoadingAhu || isLoadingStagesData;
  const error = isErrorAhu || isErrorStagesData;

  const refetch = () => {
    refetchStagesData();
    refetchAhu();
  };

  return {
    ahu,
    stages,
    loading,
    error,
    refetch
  };
};

export default useGetFilterLoadData;
