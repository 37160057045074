import { Grid, Typography } from "@mui/material";

const KettleTableHeader = () => {
  return (
    <Grid
      container
      sx={{ color: "#919293" }}
      data-testid="kettle-header">
      <Grid container>
        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          xs={6}>
          <Typography>Kettle Name</Typography>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={6}>
          <Typography>Details</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default KettleTableHeader;
