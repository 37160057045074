import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";

const selectBooth = (state: RootState) => state.booth;

export const getModalState = createSelector(
  selectBooth,
  state => state.isModalOpen
);

export const getUserAction = createSelector(
  selectBooth,
  state => state.isAddingOrRemoving
);

export const getSelectedBooth = createSelector(
  selectBooth,
  state => state.selectedBooth
);

export const getSelectedBoothSensorId = createSelector(
  selectBooth,
  state => state.selectedSensorId
);

export const getBoothStartDate = createSelector(
  selectBooth,
  state => state.boothStartDate
);

export const getBoothEndDate = createSelector(
  selectBooth,
  state => state.boothEndDate
);

export const getSelectedDetailsTab = createSelector(
  selectBooth,
  state => state.selectedDetailsTab
);

export const getChartSensorsIds = createSelector(
  selectBooth,
  state => state.chartSensorsIds
);

export const getChartZoneSensorsIds = createSelector(
  selectBooth,
  state => state.chartZonesSensorsIds
);

export const getBoothSearchTerm = createSelector(
  selectBooth,
  state => state.boothSearchTerm
);

export const getChartGranularity = createSelector(
  selectBooth,
  state => state.granularity
);
