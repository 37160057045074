import { Box, Grid, Stack, Typography, Tooltip } from "@mui/material";
import LoaderComponent from "src/shared/components/loader/loader.component";
import Error from "src/shared/components/Error/Error";
import { useStyles } from "./booth-pressure.style";
import UpdateIcon from "@mui/icons-material/Update";
import HorizontalScale from "src/shared/components/horizontal-scale/horizontal-scale";
import moment from "moment";
import classnames from "classnames";
import SignalCellular0BarOutlinedIcon from "@mui/icons-material/SignalCellular0BarOutlined";
import useOverview from "src/hooks/useOverview";
import {
  Range,
  convertFromPascalsToIWG,
  convertRangesValue
} from "src/utils/pressure.utils";
import {
  useGetLatestBoothSensorDataQuery,
  useGetPaintBoothsWithZonesQuery,
  useGetUserSetPointQuery
} from "src/store/services/booth/booth.service";
import useGetPaintBoothData from "./hooks/useGetPaintBoothData";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectedOverviewTab } from "src/store/overview/reducer";
import useGetBuildings from "src/hooks/useGetBuildings";
import { setUserClickedOnWidgetTitle } from "src/store/hvac/reducer";
import {
  setChartSensorsIds,
  setChartZonesSensorsIds,
  setSelectedBooth,
  setSelectedSensorId
} from "src/store/booth/reducer";
import useUserPascalSettings from "src/hooks/useUserPascalSettings";
import { useOverviewTab } from "../../hooks/useGetOverviewTab";
import { useMemo } from "react";

type BoothPressureProps = {
  ranges: Range[];
  paintBoothId: string;
  zoneId: string;
  buildingId: string;
  sensorId: string;
  showSetPoint?: boolean;
  requiresConfig: boolean;
};

const BoothPressureWidget = ({
  ranges,
  paintBoothId,
  buildingId,
  zoneId,
  sensorId,
  showSetPoint = false,
  requiresConfig
}: BoothPressureProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { editing } = useOverview();
  const { building } = useGetBuildings(buildingId);
  const { tabId } = useOverviewTab();

  const {
    sensors,
    paintBooth,
    loading: loadingHook,
    error: errorHook,
    refetchAgain
  } = useGetPaintBoothData(
    buildingId,
    paintBoothId,
    requiresConfig,
    showSetPoint
  );
  const isUserUsingPascals = useUserPascalSettings();
  const {
    data: sensorData,
    isLoading: isLoadingSensorData,
    isError: isErrorSensorData,
    refetch: refetchSensorData
  } = useGetLatestBoothSensorDataQuery(
    {
      buildingId,
      sensorId
    },
    { skip: requiresConfig || !sensors }
  );

  const sensor = useMemo(() => {
    if (!sensors) return undefined;
    return sensors.find(sensor => sensor.id === sensorId);
  }, [sensors, sensorId]);

  const { data: selectedUserSetPoint, isLoading: isLoadingUserSetPoint } =
    useGetUserSetPointQuery(
      {
        buildingId,
        boothId: paintBoothId
      },
      { skip: !showSetPoint }
    );
  const { data: boothsWithZones, isLoading: isLoadingBoothsWithZones } =
    useGetPaintBoothsWithZonesQuery(buildingId, {
      skip: requiresConfig || !buildingId
    });

  const zoneName = useMemo(() => {
    if (!boothsWithZones) return "";
    return Object.values(boothsWithZones)
      .flatMap(booths => Object.values(booths.zones))
      .find(({ id }) => id === zoneId)?.name;
  }, [zoneId, boothsWithZones]);

  const loading =
    loadingHook ||
    isLoadingSensorData ||
    isLoadingUserSetPoint ||
    isLoadingBoothsWithZones;
  const error = errorHook || isErrorSensorData;
  const hardwareId = sensorData?.hardware_id;
  const setPointValue =
    selectedUserSetPoint &&
    Object.values(selectedUserSetPoint?.data)
      .filter(Boolean)
      // @ts-ignore
      .find(({ hardware_id }) => hardwareId === hardware_id)?.data
      ?.pressure_drop?.value;

  const convertedSetPointValue = isUserUsingPascals
    ? setPointValue
    : convertFromPascalsToIWG(setPointValue);

  const lastUpdated = sensorData?.time;
  const isLastUpdatedOld =
    moment.duration(moment().diff(moment(lastUpdated))).asMinutes() > 45;
  const _currentValue = sensorData?.data?.pressure_drop?.value;
  const currentValue = !isUserUsingPascals
    ? _currentValue
    : convertFromPascalsToIWG(_currentValue);
  ranges = convertRangesValue(isUserUsingPascals, ranges);
  const unit = isUserUsingPascals ? "pascals" : "iwg"; //sensorData?.data?.pressure_drop?.units;
  const currentValueColor =
    ranges?.find(({ to, from }) => currentValue >= from && currentValue <= to)
      ?.color ?? ranges?.at(-1)?.color;
  const color = isLastUpdatedOld ? "#d72a2f" : "#c8c8c9";
  const unitText = unit === "pascals" ? "Pa" : "IWG";

  const handleOnClick = () => {
    if (editing) return;
    dispatch(setSelectedOverviewTab(tabId));
    dispatch(setSelectedSensorId(sensorId));
    dispatch(setChartZonesSensorsIds([]));
    dispatch(setChartSensorsIds([]));
    dispatch(setSelectedBooth(paintBooth));
    dispatch(setUserClickedOnWidgetTitle(true));
    navigate(`/buildings/booth/${buildingId}/${paintBoothId}`);
  };

  return (
    <div
      className={classnames(
        classes.root,
        isLastUpdatedOld ? classes.isInAlert : null
      )}>
      {requiresConfig ? (
        <Stack data-testid="config-container">
          <Typography variant="h6">Paint booth</Typography>
          <Typography>Configuration is required</Typography>
        </Stack>
      ) : loading ? (
        <LoaderComponent />
      ) : error ? (
        <Error
          setRefetchAgain={() => {
            refetchAgain();
            refetchSensorData();
          }}
          error={error}></Error>
      ) : (
        <>
          <div className={classes.header}>
            <Typography
              variant="h5"
              onClick={handleOnClick}
              className={editing ? classes.editingTitle : classes.title}>
              {`${zoneName}, ${sensor?.name}`}
            </Typography>
            {!editing && (
              <div
                className={classes.lastTimeUpdated}
                style={{ display: "flex", alignItems: "center" }}>
                <Grid
                  container
                  sx={{ display: "flex", alignItems: "center" }}>
                  {isLastUpdatedOld && (
                    <SignalCellular0BarOutlinedIcon
                      sx={{ fontSize: "1rem", color }}
                    />
                  )}
                  <UpdateIcon
                    sx={{
                      fontSize: "1rem",
                      color
                    }}
                  />

                  <Typography
                    variant="h5"
                    className={classes.time}
                    sx={{
                      fontSize: "0.75rem",
                      color,
                      marginLeft: "5px"
                    }}>
                    {moment(lastUpdated).format("MMM D, hh:mm ")}
                  </Typography>
                </Grid>
              </div>
            )}
          </div>
          <div
            style={
              showSetPoint
                ? {
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    width: "100%"
                  }
                : { width: "100%" }
            }>
            <Grid
              container
              className={classes.body}>
              <div className={classes.mainBody}>
                <Typography
                  variant="h4"
                  sx={{ color: currentValueColor, marginBottom: "10px" }}>
                  {currentValue?.toFixed(2)}
                  <Box
                    component="span"
                    fontWeight="fontWeightMedium"
                    sx={{ fontSize: "1rem" }}>
                    {unitText}
                  </Box>
                </Typography>
                <HorizontalScale
                  ranges={ranges}
                  currentValue={currentValue}></HorizontalScale>
              </div>
              <Typography
                variant="h6"
                className={classes.footer}>
                Booth Pressure
              </Typography>
              {Boolean(building) && (
                <Stack
                  justifyContent={"start"}
                  width={"100%"}>
                  <Tooltip
                    title={building?.location?.address}
                    placement="bottom"
                    arrow>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: "0.8rem !important" }}>
                      {building?.name}
                    </Typography>
                  </Tooltip>
                </Stack>
              )}
            </Grid>
            {showSetPoint && (
              <div
                style={{
                  width: "1px",
                  height: "100%",
                  background: "#ebebeb",
                  margin: "0 10px"
                }}></div>
            )}
            {showSetPoint && (
              <Grid
                data-testid="booth-set-point"
                container
                className={classes.body}>
                <div className={classes.mainBody}>
                  <Typography
                    variant="h4"
                    data-testid="set-point"
                    sx={{ color: currentValueColor, marginBottom: "10px" }}>
                    {Boolean(selectedUserSetPoint)
                      ? convertedSetPointValue?.toFixed(2)
                      : "-"}
                    <Box
                      component="span"
                      fontWeight="fontWeightMedium"
                      sx={{ fontSize: "1rem" }}>
                      {unitText}
                    </Box>
                  </Typography>
                  <HorizontalScale
                    ranges={ranges}
                    currentValue={convertedSetPointValue}></HorizontalScale>
                </div>
                <Typography
                  variant="h6"
                  className={classes.footer}>
                  Set Point
                </Typography>
                <Box
                  width={"100%"}
                  height={"20px"}></Box>
              </Grid>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default BoothPressureWidget;
