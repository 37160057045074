/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InsightsRequestBody } from '../models/InsightsRequestBody';
import type { TimeRange } from '../models/TimeRange';
import type { WebReportData } from '../models/WebReportData';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ReportsService {
  /**
   * Get Aq Insights
   * Returns aggregated aq data for a device for the given range.
   *
   * Args:
   * device_id: When `device_id` is `None`, all the indoor aq data for the building's devices will be used to generate the insights.
   * time_range: Defaults to past 14 days. `time_range.start` and `time_range.end` can be given as Unix timestamps integers or floats in seconds or milliseconds, or as isoformat strings.
   *
   * ```
   * Examples:
   * 1677827157                       (seconds)
   * 1677827157.5451648               (seconds)
   * 1677827157545                    (milliseconds)
   * 2023-03-03T07:05:57.545165+00:00 (isoformat)
   * 2023-03-03T02:05:57.545165+05:00 (isoformat)
   * ```
   * All of the above generate the same timestamp in the backend
   * (except where minor place values were omitted).
   * @param buildingId
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getAqInsights(
    buildingId: string,
    requestBody: InsightsRequestBody,
  ): CancelablePromise<(Record<string, string> | null)> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/insights/',
      query: {
        'building_id': buildingId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Make Pdf Report
   * Generates a single page pdf iaq report for a building.
   * If no `time_range` is provided, defaults to the last two weeks.
   *
   * `time_range.start` and `time_range.end` can be given as Unix timestamps integers
   * or floats in seconds or milliseconds, or as isoformat strings.
   * ```
   * Examples:
   * 1677827157                       (seconds)
   * 1677827157.5451648               (seconds)
   * 1677827157545                    (milliseconds)
   * 2023-03-03T07:05:57.545165+00:00 (isoformat)
   * 2023-03-03T02:05:57.545165+05:00 (isoformat)
   * ```
   * All of the above generate the same timestamp in the backend
   * (except where minor place values were omitted).
   * @param companyId
   * @param buildingId
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static makePdfReport(
    companyId: string,
    buildingId: string,
    requestBody?: (TimeRange | null),
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/reports/pdf/',
      query: {
        'company_id': companyId,
        'building_id': buildingId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `A report could not be generated`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Web Report Data
   * Main endpoint for delivering the data the web report uses.
   * If no `building_id` is provided, a company-level report is produced (all buildings for the company).
   * If a `building_id` is provided, a building-level report is made.
   * If no `time_range` is provided, defaults to the last two weeks.
   *
   * `time_range.start` and `time_range.end` can be given as Unix timestamps integers
   * or floats in seconds or milliseconds, or as isoformat strings.
   * ```
   * Examples:
   * 1677827157                       (seconds)
   * 1677827157.5451648               (seconds)
   * 1677827157545                    (milliseconds)
   * 2023-03-03T07:05:57.545165+00:00 (isoformat)
   * 2023-03-03T02:05:57.545165+05:00 (isoformat)
   * ```
   * All of the above generate the same timestamp in the backend
   * (except where minor place values were omitted).
   * @param companyId
   * @param buildingId
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getWebReportData(
    companyId: string,
    buildingId: string,
    requestBody?: (TimeRange | null),
  ): CancelablePromise<(WebReportData | null)> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/reports/web/',
      query: {
        'company_id': companyId,
        'building_id': buildingId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `At least on of building_id or company_id must be provided`,
        422: `Validation Error`,
      },
    });
  }
}
