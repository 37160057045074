import classNames from "classnames";
import { makeStyles } from "tss-react/mui";
import { alpha, Tooltip, Typography } from "@mui/material";
import { NA } from "src/shared/constants/generals";

import {
  AHU_DEFAULT,
  LOAD_CLEAN_FILTERS,
  LOAD_DIRTY_FILTERS,
  LOAD_MODERATELY_CLEAN,
  LOAD_MODERATELY_DIRTY
} from "src/shared/constants/ahu-status";
import { StageAndFilterload } from "src/utils/client";
import useGetHvacStatusInfoDictionary from "src/hooks/useGetHvacStatusInfoDictionary";
import { DEFAULT } from "src/shared/constants/building-status";
type Props = {
  stage: StageAndFilterload;
};

const MaxLoadComponent = ({ stage }: Props) => {
  const { classes } = useStyles();
  const { dictionary: pollInfoDict } = useGetHvacStatusInfoDictionary();
  const value = stage?.filterload?.filter_loading;
  const status =
    pollInfoDict[stage.stage_type]?.["loading_status"]?.find(
      ({ high, low }) => high >= value && low <= value
    )?.status ?? DEFAULT.toLowerCase();

  return (
    <div data-testid="max-load">
      <div
        className={classNames(
          classes.pollutant,
          classes[`background${status.replace(" ", "_")}`]
        )}>
        <Tooltip
          title={status.toLowerCase()}
          disableFocusListener={status === AHU_DEFAULT}
          disableHoverListener={status === AHU_DEFAULT}
          disableTouchListener={status === AHU_DEFAULT}>
          <Typography
            className={classNames(
              classes.root,
              classes[`status${status.replace(" ", "_")}`]
            )}>
            {value ? `${Number(value.toFixed(2))}%` : NA}
          </Typography>
        </Tooltip>
      </div>
    </div>
  );
};

export const useStyles = makeStyles()(theme => {
  if (!theme.palette.ahuStatus) {
    theme.palette.ahuStatus = {
      clean_filters: "#008000",
      moderately_clean: "#CCCC00",
      moderately_dirty: "#FFA500",
      dirty_filters: "#FF0000",
      default: "#a9a9a9"
    };
  }
  return {
    root: {},
    pollutant: {
      textAlign: "center" as const,
      border: "1px solid #E9EAEA",
      borderRadius: 8,
      padding: 2,
      minWidth: 50
    },
    [`background${LOAD_CLEAN_FILTERS.replace(/ /g, "_")}`]: {
      background: alpha(theme.palette.ahuStatus[LOAD_CLEAN_FILTERS], 0.2),
      border: `1px solid ${alpha(
        theme.palette.ahuStatus[LOAD_CLEAN_FILTERS],
        0.5
      )}`
    },
    [`background${LOAD_MODERATELY_CLEAN.replace(/ /g, "_")}`]: {
      background: alpha(theme.palette.ahuStatus[LOAD_MODERATELY_CLEAN], 0.2),
      border: `1px solid ${alpha(
        theme.palette.ahuStatus[LOAD_MODERATELY_CLEAN],
        0.5
      )}`
    },
    [`background${LOAD_MODERATELY_DIRTY.replace(/ /g, "_")}`]: {
      background: alpha(theme.palette.ahuStatus[LOAD_MODERATELY_DIRTY], 0.2),
      border: `1px solid ${alpha(
        theme.palette.ahuStatus[LOAD_MODERATELY_DIRTY],
        0.5
      )}`
    },
    [`background${LOAD_DIRTY_FILTERS.replace(/ /g, "_")}`]: {
      background: alpha(theme.palette.ahuStatus[LOAD_DIRTY_FILTERS], 0.2),
      border: `1px solid ${alpha(
        theme.palette.ahuStatus[LOAD_DIRTY_FILTERS],
        0.5
      )}`
    },
    [`background${AHU_DEFAULT.replace(/ /g, "_")}`]: {
      background: alpha(theme.palette.ahuStatus[AHU_DEFAULT], 0.2),
      border: `1px solid ${alpha(theme.palette.ahuStatus[AHU_DEFAULT], 0.5)}`
    },
    [`status${LOAD_CLEAN_FILTERS}`]: {
      color: theme.palette.ahuStatus[LOAD_CLEAN_FILTERS]
    },
    [`status${LOAD_MODERATELY_CLEAN}`]: {
      color: theme.palette.ahuStatus[LOAD_MODERATELY_CLEAN]
    },
    [`status${LOAD_MODERATELY_DIRTY}`]: {
      color: theme.palette.ahuStatus[LOAD_MODERATELY_DIRTY]
    },
    [`status${LOAD_DIRTY_FILTERS}`]: {
      color: theme.palette.ahuStatus[LOAD_DIRTY_FILTERS]
    },
    [`status${AHU_DEFAULT}`]: {
      color: theme.palette.ahuStatus[AHU_DEFAULT]
    }
  };
});

export default MaxLoadComponent;
