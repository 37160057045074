import CurverProgressBarComponent from "src/shared/components/curver-progress-bar/curver-progress-bar.component";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AirIcon from "@mui/icons-material/Air";
import { Typography, Tooltip } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { getMetricClass } from "src/utils/buildings.util";
import { DEFAULT } from "src/shared/constants/building-status";
import { useMemo } from "react";
import usePaleteColor from "src/hooks/usePaleteColor";
import LoaderComponent from "src/shared/components/loader/loader.component";
import Error from "src/shared/components/Error/Error";
import WidgetTitle from "../../components/WidgetTitle/WidgetTitle";
import useGetBuildings from "src/hooks/useGetBuildings";
import { useGetFiltrationEfficiencyScoreAndRecommendationsQuery } from "src/store/services/widgets/widgets.service";

type Props = {
  buildingId: string;
  requiresConfig?: boolean;
};

const FiltrationEfficiencyWidgetComponent = ({
  buildingId,
  requiresConfig
}: Props) => {
  const { classes } = useStyles();
  const { buildings } = useGetBuildings();
  const building = buildings.find(({ id }) => id === buildingId);

  const {
    data: filtration,
    isLoading,
    isError,
    refetch: setRefetchAgain
  } = useGetFiltrationEfficiencyScoreAndRecommendationsQuery(buildingId);

  const filtrationEfficiencyExist = Boolean(filtration);
  const filtrationEfficiencyValue = filtrationEfficiencyExist
    ? filtration?.scores?.filtration_efficiency
    : 0;

  const filtrationEfficiencyStatus = useMemo(() => {
    if (!filtrationEfficiencyExist) return DEFAULT;

    return getMetricClass(filtrationEfficiencyValue);
  }, [filtrationEfficiencyValue]);

  const { statusColor, gradientColors } = usePaleteColor(
    "status",
    filtrationEfficiencyStatus
  );

  return (
    <div
      data-testid="filtration-efficiency-widget"
      className={classes.root}>
      <Typography variant="h6">
        Filtration Efficiency
        <Tooltip
          title="This score is an indication of how well your HVAC system removes particulate matter from outside fresh air. A higher score means that your filters are cleaning air more effectively."
          placement="top-start"
          className="tooltip"
          arrow>
          <InfoOutlinedIcon
            color="disabled"
            fontSize="small"
          />
        </Tooltip>
      </Typography>
      {requiresConfig ? (
        <Typography>Configuration is required</Typography>
      ) : isLoading ? (
        <LoaderComponent />
      ) : (
        <>
          <div className={classes.widgetBody}>
            {isError ? (
              <Error
                setRefetchAgain={setRefetchAgain}
                error={isError}></Error>
            ) : (
              <>
                <WidgetTitle
                  error={isError}
                  building={building}></WidgetTitle>
                <div className={classes.gaugeContainer}>
                  <CurverProgressBarComponent
                    value={filtrationEfficiencyValue}
                    displayGradient={false}
                    unit=""
                    min={0}
                    max={100}
                    classes={{ root: classes.curvedGauge }}
                    color={statusColor}
                    gradientsStops={gradientColors}
                  />
                  <div className={classes.description}>
                    <div>
                      <AirIcon
                        sx={{
                          color: statusColor,
                          marginRight: "0.25em",
                          marginTop: "2px"
                        }}
                      />
                    </div>
                    <div>
                      <Typography
                        sx={{ color: statusColor }}
                        variant="h5">
                        {filtrationEfficiencyStatus}
                      </Typography>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: "100%",
      padding: "10px"
    },
    title: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: 8
    },
    facilityName: {
      flex: 1,
      color: "grey",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textAlign: "right",
      marginLeft: ".7em"
    },
    widgetBody: {
      flex: 1,
      overflow: "hidden"
    },
    loader: {
      position: "absolute",
      top: 0,
      left: 0
    },
    curvedGauge: {
      height: 130
    },
    description: {
      display: "flex",
      justifyContent: "center",
      position: "relative",
      top: -20
    },
    gaugeContainer: {
      position: "relative",
      top: -27
    }
  };
});

export default FiltrationEfficiencyWidgetComponent;
