import { makeStyles } from "tss-react/mui";
export const useStyles = makeStyles()(theme => {
  return {
    root: {
      paddingBottom: "0.25em",
      paddingTop: "0.25em",
      transition: "all 250ms",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: theme.palette.grey["200"]
      },
      backgroundColor: "white"
    },
    column: {
      whiteSpace: "nowrap" as const,
      textOverflow: "ellipsis",
      overflow: "hidden",
      padding: 8,
      paddingLeft: 5,
      paddingBottom: 0
    },
    name: {
      cursor: "pointer"
    },
    firstColumn: {
      paddingLeft: "5% !important"
    },
    actions: {
      paddingTop: 8,
      textAlign: "center" as const
    },
    address: {
      whiteSpace: "nowrap" as const,
      textOverflow: "ellipsis",
      overflow: "hidden",
      padding: 0,
      paddingLeft: 6
    },
    centered: {
      textAlign: "center" as const,
      paddingTop: 8
    },
    aqi: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column" as const
    },
    selected: {
      backgroundColor: theme.palette.grey["300"]
    },
    statusContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    statusIndicator: {
      width: 12,
      height: 12,
      borderRadius: "50%"
    },
    floorName: {
      position: "relative" as const,
      top: 8
    },

    offline: {
      color: "#d32f2f",
      fontSize: "medium",
      verticalAlign: "top"
    }
  };
});
