import { useEffect, useState, useMemo } from "react";
import moment from "moment";
import LoaderComponent from "src/shared/components/loader/loader.component";
import { Typography, Tooltip } from "@mui/material";
import { RadialGauge } from "react-canvas-gauges";
import { alpha, Box } from "@mui/system";
import useUserPascalSettings from "src/hooks/useUserPascalSettings";
import {
  convertFromIWGToPascals,
  convertFromPascalsToIWG,
  convertToIWGIfUserUsePascals
} from "src/utils/pressure.utils";
import { PressureUnits } from "src/utils/client";
import Error from "src/shared/components/Error/Error";
import LinkIcon from "@mui/icons-material/Link";
import useOverview from "src/hooks/useOverview";
import { useGetHvacDataForStageQuery } from "src/store/services/widgets/widgets.service";
import useGetDataForAhu from "../hooks/useGetDataForAhu";
import useGetKettleAndSenorsData from "src/hooks/useGetKettleAndSensorsData";
import { checkLuminance } from "../../view/utils";
import { useStyles } from "./ahu-stage-gauge-widget.style";
import { setSelectedOverviewTab } from "src/store/overview/reducer";
import { setUserClickedOnWidgetTitle } from "src/store/hvac/reducer";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { GAUGES } from "../ahu-stage-gauge-settings/ahu-stage-gauge-settings.component";
import { setSelectedAHU } from "src/store/hvac/reducer";
import { useOverviewTab } from "../../hooks/useGetOverviewTab";
import { setSelectedKettle } from "src/store/kettle/reducer";

const COLOR_MINOR_TICK = "#ddd";

type Props = {
  pressureValue?: number;
  ahuId?: string;
  buildingId?: string;
  kettleId?: string;
  ranges?: any[];
  requiresConfig?: boolean;
  dataGrid?: any;
  selectedView?: string;
  index?: number;
  stageData: any;
  opacity?: number;
  stageId?: string;
  widgetId: string;
  gaugeType: GAUGES;
};

const AhuStageGaugeWidgetComponent = ({
  ahuId,
  opacity = 1,
  stageId,
  buildingId,
  kettleId,
  requiresConfig,
  dataGrid,
  gaugeType,
  ranges = []
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isUserUsingPascal = useUserPascalSettings();
  const { classes } = useStyles();
  const [pressure, setPressure] = useState(0);
  const [lastUpdated, setLastUpdated] = useState("--");
  const [stageName, setStageName] = useState("");
  const [gaugeTitle, setGaugeTitle] = useState("");
  const { editing } = useOverview();
  const { tabId } = useOverviewTab();
  const gaugeRangeValues = ranges
    ? Object.values(ranges).map(({ to }: { to: number }) => to)
    : [];
  const gaugeMinValue = convertToIWGIfUserUsePascals(ranges.at(0)?.from);
  const gaugeMaxValue = convertToIWGIfUserUsePascals(ranges.at(-1)?.to);

  const {
    isLoading: isLoadingAhuData,
    isFetching: isFetchingAhuData,
    isError: isAhuError,
    refetchAhuData,
    ahu,
    building,
    stages
  } = useGetDataForAhu(buildingId, ahuId, requiresConfig);

  const {
    data: stageData,
    isLoading: isLoadingStageData,
    isFetching: isFetchingHvac,
    isError: isHvacError,
    refetch: hvacRefetch
  } = useGetHvacDataForStageQuery(
    { buildingId, ahuId, stageId },
    {
      skip: requiresConfig || gaugeType === GAUGES.KETTLE_GAUGE,
      pollingInterval: 30 * 1000
    }
  );

  const {
    kettle,
    inletPSI,
    outletPSI,
    differentialPSI,
    lastUpdatedTime,
    isLoading,
    isError,
    refetch
  } = useGetKettleAndSenorsData(
    buildingId,
    kettleId,
    true,
    requiresConfig,
    gaugeType
  );

  const loading = isLoadingStageData || isLoadingAhuData || isLoading;

  const isFetching = isFetchingAhuData || isFetchingHvac;
  const error = (isAhuError || isHvacError) && isError;

  const refetchAgain = () => {
    refetchAhuData();
    hvacRefetch();
    refetch();
  };
  const stageDataExist = Boolean(stageData?.data);
  const kettleDataExist = Boolean(kettle && kettleId);

  useEffect(() => {
    if (stageDataExist && Boolean(ahu)) {
      const { data, time } = stageData;
      const pressure = isUserUsingPascal
        ? convertFromIWGToPascals(data.pressure_drop?.left)
        : data?.pressure_drop?.left;

      setPressure(pressure || 0);
      setGaugeTitle(ahu?.name);
      setLastUpdated(time ? moment(time).format("lll") : "--");
      setStageName(stages?.find(stage => stage.id === stageId)?.name ?? "");
    }
    if (kettleDataExist) {
      setPressure(Number(differentialPSI));
      setLastUpdated(lastUpdatedTime);
      setGaugeTitle(kettle?.name);
    }
  }, [
    stageDataExist,
    ahu,
    stageId,
    stages,
    stageData,
    kettle,
    kettleId,
    lastUpdatedTime
  ]);

  const labelColor = useMemo(() => {
    if (!ranges.length) return "#333";
    return Object.values(ranges).find(({ to, from }) => {
      const startRangeValue = isUserUsingPascal
        ? to
        : convertFromPascalsToIWG(to);
      const endRangeValue = isUserUsingPascal
        ? from
        : convertFromPascalsToIWG(from);
      return pressure <= startRangeValue && pressure >= endRangeValue;
    })?.color;
  }, [pressure]);

  const valueTextColor = (background: string) => {
    const luminance = checkLuminance(background);
    if (luminance > 150) {
      return "#000";
    }
    return "#FFF";
  };

  const handleOpenDetailPage = () => {
    if (editing) return;
    dispatch(setSelectedOverviewTab(tabId));
    dispatch(setUserClickedOnWidgetTitle(true));
    if (gaugeType === GAUGES.AHU_GAUGE || ahuId) {
      dispatch(setSelectedAHU(ahu));
      navigate(`/buildings/hvac/${buildingId}/${ahuId}`);
    } else {
      dispatch(setSelectedKettle(kettle));
      navigate(`/buildings/kettle/${building?.id}/${kettle?.id}`);
    }
  };

  return (
    <div
      data-testid="ahu-stage-gauge-widget"
      className={classes.root}>
      {requiresConfig ? (
        <>
          <Typography variant="h6">Filter Pressure</Typography>
          <Typography>Configuration is required</Typography>
        </>
      ) : loading || isFetching ? (
        <LoaderComponent />
      ) : error ? (
        <Error
          setRefetchAgain={refetchAgain}
          error={error}></Error>
      ) : (
        <>
          <div className={classes.body}>
            {dataGrid?.h === 1 && (
              <div className={classes.smallHeader}>
                <Typography
                  variant="h6"
                  sx={{ cursor: "pointer" }}
                  onClick={handleOpenDetailPage}>
                  {gaugeTitle}
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.subtitle}>
                  {stageName}
                </Typography>
              </div>
            )}
            {dataGrid?.h > 1 && (
              <div className={classes.header}>
                <Typography
                  variant="h5"
                  className={editing ? classes.default : classes.pointer}
                  onClick={handleOpenDetailPage}>
                  {gaugeTitle}
                  <LinkIcon className={classes.linkIcon} />
                </Typography>
                <Typography variant="subtitle1">{stageName}</Typography>
              </div>
            )}
            {kettleDataExist && (
              <div className={classes.inletOutletPSIContainer}>
                <div className={classes.inletOutletPSI}>
                  <Typography
                    sx={{
                      width:
                        dataGrid.w === 2 && dataGrid.h === 1 ? "15%" : "30%",
                      textAlign: "center",
                      fontSize: dataGrid.w === 2 ? "13px" : "16px"
                    }}>{`IN: ${inletPSI} PSI`}</Typography>
                  <Typography
                    sx={{
                      width:
                        dataGrid.w === 2 && dataGrid.h === 1 ? "15%" : "30%",
                      textAlign: "center",
                      fontSize: dataGrid.w === 2 ? "13px" : "16px"
                    }}>{`OUT: ${outletPSI} PSI`}</Typography>
                </div>
              </div>
            )}
            {stageDataExist || kettleDataExist ? (
              <>
                <div
                  className={classes.gaugeContainer}
                  style={{ top: dataGrid.h === 1 ? -14 : 0 }}>
                  <RadialGauge
                    title="PRESSURE"
                    units={
                      kettleDataExist
                        ? "𝜟PSI"
                        : isUserUsingPascal
                        ? PressureUnits.PASCALS.toLocaleUpperCase()
                        : PressureUnits.IWG.toLocaleUpperCase()
                    }
                    exactTicks
                    colorValueText={valueTextColor(labelColor)}
                    value={pressure}
                    fontNumbersSize={dataGrid.h === 1 ? 32 : 24}
                    colorMinorTicks={COLOR_MINOR_TICK}
                    minValue={gaugeMinValue}
                    maxValue={gaugeMaxValue}
                    minorTicks={0}
                    majorTicks={[
                      gaugeMinValue.toFixed(
                        isUserUsingPascal || kettleDataExist ? 0 : 2
                      ),
                      ...gaugeRangeValues.map(value =>
                        Number(
                          isUserUsingPascal
                            ? value
                            : convertFromPascalsToIWG(value)
                        ).toFixed(isUserUsingPascal || kettleDataExist ? 0 : 2)
                      )
                    ]}
                    valueText={pressure.toFixed(2)}
                    highlights={ranges.map(range => ({
                      ...range,
                      from: isUserUsingPascal
                        ? range.from
                        : convertFromPascalsToIWG(range.from),
                      to: isUserUsingPascal
                        ? range.to
                        : convertFromPascalsToIWG(range.to),
                      color: alpha(range.color, opacity)
                    }))}
                    borders={false}
                    width={dataGrid.h === 1 ? 150 : 290}
                    height={
                      dataGrid.h === 1 ? 125 : dataGrid.w >= 3 ? 200 : 190
                    }
                    animatedValue
                    valueBoxStroke={2}
                    borderShadowWidth={0}
                    colorValueBoxBackground={labelColor}
                    fontValueSize={dataGrid.h === 1 ? 40 : 24}
                    fontValueWeight="bold"
                  />
                </div>
              </>
            ) : (
              <Typography className={classes.noDevice}>
                No device data available
              </Typography>
            )}
          </div>
          {Boolean(building) && (
            <Box
              sx={{
                position: "relative",
                top:
                  dataGrid.h === 1
                    ? -10
                    : dataGrid.h === 2 && dataGrid.w === 2
                    ? -30
                    : -25,
                display: "flex",
                justifyContent: "space-between"
              }}>
              <Tooltip
                title={building?.location?.address}
                placement="bottom"
                arrow>
                <Typography
                  sx={{
                    fontSize:
                      dataGrid.h === 1 ? "11px !important" : "13px!important",
                    marginRight: 2
                  }}
                  variant="subtitle2">
                  {building?.name}
                </Typography>
              </Tooltip>
              <Typography
                sx={{
                  fontSize:
                    dataGrid.h === 1 ? "11px !important" : "13px!important"
                }}
                variant="subtitle2">
                {lastUpdated}
              </Typography>
            </Box>
          )}
        </>
      )}
    </div>
  );
};

export default AhuStageGaugeWidgetComponent;
