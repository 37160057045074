import { createContext, PropsWithChildren, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import useGetAppBreakPoints from "src/hooks/useGetAppBreakPoints";
import { Filters } from "src/shared/components/main-menu/main-menu.component";

type DrawerContext = {
  isDrawerOpen?: boolean;
  setIsDrawerOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  filter?: Filters;
  setFilter?: React.Dispatch<React.SetStateAction<Filters>>;
};

export const DrawerContext = createContext<DrawerContext>({
  isDrawerOpen: true,
  setIsDrawerOpen: () => {},
  setFilter: () => {}
});

const DrawerProvider = ({ children }: PropsWithChildren) => {
  const { isLg } = useGetAppBreakPoints();
  const { pathname } = useLocation();

  const initialFilter = getCorrectFilter(pathname);

  const [isDrawerOpen, setIsDrawerOpen] = useState(isLg);
  const [filter, setFilter] = useState<Filters>(initialFilter);

  const contextValue = useMemo<DrawerContext>(
    () => ({
      isDrawerOpen,
      setIsDrawerOpen,
      filter,
      setFilter
    }),
    [isDrawerOpen, setIsDrawerOpen, filter, setFilter]
  );

  return (
    <DrawerContext.Provider value={contextValue}>
      {children}
    </DrawerContext.Provider>
  );
};

export const getCorrectFilter = (pathName: string): Filters => {
  if (pathName.startsWith("/buildings/hvac")) return Filters.HVAC;
  if (pathName.startsWith("/buildings/kettle")) return Filters.KETTLE;
  if (pathName.startsWith("/buildings/booth")) return Filters.BOOTH;
  if (pathName.startsWith("/buildings/iaq")) return Filters.IAQ;
  if (pathName.startsWith("/settings")) return Filters.SETTINGS;
  else {
    return Filters.DASH;
  }
};

export default DrawerProvider;
