import { Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

type Props = {
  title?: string;
};

const TestWidgetComponent = (props: Props) => {
  const { title } = props;
  const { classes } = useStyles();
  return (
    <div
      data-testid="test-widget"
      className={classes.root}>
      <Typography variant="h5">{title}</Typography>
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {}
  };
});

export default TestWidgetComponent;
