import moment from "moment";
import {
  useGetKettleQuery,
  useGetKettleSensorsForBuildingQuery
} from "src/store/services/kettle/kettle.service";
import { convertPascalsToPSI } from "src/utils/pressure.utils";
import { NA } from "src/shared/constants/generals";
import { GAUGES } from "src/sections/overview/widgets/ahu-stage-gauge-settings/ahu-stage-gauge-settings.component";
export enum SENSORS_TYPE {
  IN = "IN",
  OUT = "OUT"
}

const useGetKettleAndSenorsData = (
  buildingId: string,
  kettleId: string,
  isGaugeWidget = false,
  requiresConfig: boolean = false,
  gaugeType?: string
) => {
  const {
    data: kettle,
    isLoading: isLoadingKettle,
    isError: isKettleError,
    refetch: refetchKettle
  } = useGetKettleQuery(
    {
      buildingId,
      kettleId
    },
    { skip: requiresConfig }
  );

  const {
    data: sensors,
    isLoading,
    isFetching,
    isError: isSensorsError,
    refetch: refetchKettleSensors
  } = useGetKettleSensorsForBuildingQuery(
    {
      buildingId,
      kettleId
    },
    {
      skip: requiresConfig || gaugeType === GAUGES.AHU_GAUGE,
      pollingInterval: isGaugeWidget ? 30000 : Infinity
    }
  );

  const kettleInSensor = sensors?.find(
    // @ts-ignore
    sensor => sensor?.details.side === SENSORS_TYPE.IN
  );
  const kettleOutSensor = sensors?.find(
    // @ts-ignore
    sensor => sensor?.details.side === SENSORS_TYPE.OUT
  );

  const inletPressure =
    kettleInSensor?.latest_measurement?.data?.pressure.value;
  const outletPressure =
    kettleOutSensor?.latest_measurement?.data?.pressure.value;
  const inletPSI = convertPascalsToPSI(inletPressure);
  const outletPSI = convertPascalsToPSI(outletPressure);
  const differentialPSI = inletPSI - outletPSI;

  const inletKettleTime = kettleInSensor?.latest_measurement?.time;
  const lastUpdatedTime = inletKettleTime
    ? moment(inletKettleTime).format("lll")
    : "--";

  // In case that differential pressure between inlet and outlet sensor is bigger then 5 PSI
  // We will show in Alert
  const isDifferentialPressureOutAlert = differentialPSI >= 10;
  const refetch = () => {
    if (isSensorsError) {
      refetchKettleSensors();
    }
    if (isKettleError) {
      refetchKettle();
    }
  };

  return {
    kettle,
    sensors,
    isError: isKettleError || isSensorsError,
    isLoading: isLoading || isFetching || isLoadingKettle,
    refetch,
    lastUpdatedTime,
    inletSensor: kettleInSensor,
    outletSensor: kettleOutSensor,
    inletPSI: isNaN(inletPSI) ? NA : inletPSI?.toFixed(0),
    outletPSI: isNaN(outletPSI) ? NA : outletPSI?.toFixed(0),
    differentialPSI: isNaN(differentialPSI) ? NA : differentialPSI?.toFixed(0),
    // In case that differential pressure between inlet and outlet sensor is bigger then 5 PSI
    // We will show in Alert
    isDifferentialPressureOutAlert,
    alertColor: isDifferentialPressureOutAlert ? "red" : "green"
  };
};

export default useGetKettleAndSenorsData;
