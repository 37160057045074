import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type InitialState = {
  TCOData: any;
  showTCOCurve: Record<string, string> | null;
  COOData: any;
};

const initialState: InitialState = {
  TCOData: [],
  showTCOCurve: null,
  COOData: []
};

export const buildingCostSlice = createSlice({
  name: "download",
  initialState,
  reducers: {
    // endpoint
    setTCOData: (state, action: PayloadAction<any>) => {
      state.TCOData = action.payload;
    },
    setShowTCOCurve: (state, action: PayloadAction<any>) => {
      state.showTCOCurve = action.payload;
    },
    setCOOData: (state, action: PayloadAction<any>) => {
      state.showTCOCurve = action.payload;
    }
  }
});

export const { setTCOData, setShowTCOCurve, setCOOData } =
  buildingCostSlice.actions;

export const { reducer: buildingCostReducer } = buildingCostSlice;
