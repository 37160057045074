import { useAppSelector } from "src/hooks/useAppSelector";
import useGetBoothChartData from "src/hooks/useGetBoothChartData";
import {
  getBoothEndDate,
  getBoothStartDate,
  getChartGranularity,
  getSelectedBooth,
  getSelectedDetailsTab
} from "src/store/booth/selector";
import { AggGranularity } from "src/utils/client";
import {
  Stack,
  Box,
  TextField,
  Typography,
  Tabs,
  Tab,
  Button
} from "@mui/material";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import {
  setBoothEndDate,
  setBoothStartDate,
  setChartGranularity,
  setDetailsTab
} from "src/store/booth/reducer";
import LineChart from "src/shared/components/line-chart/line-chart-component";
import { renderWeekPickerDay } from "src/utils/date-picker.utils";
import { DatePicker } from "@mui/x-date-pickers";
import {
  handleDownloadModal,
  setDownloadModalEntity,
  setDownloadModalEntityId
} from "src/store/hvac/reducer";
import LoaderComponent from "src/shared/components/loader/loader.component";
import { MenuItem, FormControl, InputLabel, Select } from "@mui/material";
import useGetAppBreakPoints from "src/hooks/useGetAppBreakPoints";

enum TabType {
  PRESSURE = "pressure",
  HUMIDITY = "humidity",
  TEMPERATURE = "temperature"
}

const tabs = [
  {
    label: "PRESSURE",
    value: TabType.PRESSURE
  },
  {
    label: "HUMIDITY",
    value: TabType.HUMIDITY
  },
  {
    label: "TEMPERATURE",
    value: TabType.TEMPERATURE
  }
];

const PaintBoothChart = () => {
  const dispatch = useAppDispatch();
  const startDate = useAppSelector(getBoothStartDate);
  const endDate = useAppSelector(getBoothEndDate);
  const booth = useAppSelector(getSelectedBooth);
  const granularity = useAppSelector(getChartGranularity);
  const selectedTab = useAppSelector(getSelectedDetailsTab);
  const { isXl } = useGetAppBreakPoints();

  const { chartData, chartDataExist, isLoading, isFetching, isError } =
    useGetBoothChartData(startDate, endDate, granularity);

  const openDownloadDataModal = () => {
    dispatch(handleDownloadModal(true));
    dispatch(setDownloadModalEntity("booth"));
    dispatch(setDownloadModalEntityId(booth.id));
  };

  return (
    <Stack
      width={"100%"}
      flexDirection={"column"}
      justifyContent={"space-between"}>
      <Stack
        width={"100%"}
        flexDirection={"column"}
        justifyContent={"space-between"}>
        <Stack
          width={"100%"}
          flexDirection={isXl ? "row" : "column"}
          justifyContent={"space-between"}>
          <Tabs
            data-testid="tabs"
            value={selectedTab}
            onChange={(_event, value) => {
              dispatch(setDetailsTab(value));
            }}
            variant="scrollable"
            scrollButtons="auto"
            sx={{ display: "block" }}>
            {tabs.map(tab => (
              <Tab
                data-testid={tab.value}
                key={tab.value}
                value={tab.value}
                label={<Typography variant="button">{tab.label}</Typography>}
              />
            ))}
          </Tabs>
          <Stack
            flexDirection={"row"}
            justifyContent={"end"}>
            <FormControl
              fullWidth
              style={{ marginTop: 8, width: 130, marginRight: 20 }}>
              <InputLabel id="granularity-label">Granularity</InputLabel>
              <Select
                data-testid="granulatiry-select"
                labelId="granularity-label"
                value={granularity ?? AggGranularity.HOUR}
                label="Granularity"
                onChange={({ target: { value } }) => {
                  dispatch(
                    setChartGranularity(
                      value as AggGranularity.DAY | AggGranularity.HOUR
                    )
                  );
                }}>
                <MenuItem value={"hour"}>Hours</MenuItem>
                <MenuItem value={"day"}>Days</MenuItem>
              </Select>
            </FormControl>
            <Box
              width={"160px"}
              mr={"1.2rem"}>
              <DatePicker
                label="From date"
                value={startDate}
                renderDay={renderWeekPickerDay}
                onChange={newValue => {
                  dispatch(setBoothStartDate(newValue.toString()));
                }}
                renderInput={params => <TextField {...params} />}
              />
            </Box>
            <Box
              width={"160px"}
              mr={"1.2rem"}>
              <DatePicker
                label="To date"
                value={endDate}
                renderDay={renderWeekPickerDay}
                onChange={newValue => {
                  dispatch(setBoothEndDate(newValue.toString()));
                }}
                renderInput={params => <TextField {...params} />}
              />
            </Box>
            <Box mt={1}>
              <Button
                data-testid="download"
                onClick={openDownloadDataModal}
                variant="text">
                Download
              </Button>
            </Box>
          </Stack>
        </Stack>
      </Stack>
      {isLoading || isFetching ? (
        <LoaderComponent />
      ) : isError ? (
        <Typography
          data-testId="error"
          sx={styles.errorMessage}>
          An error occured please try again
        </Typography>
      ) : chartDataExist ? (
        <Box
          position={"relative"}
          data-testid="chart"
          height={"400px"}
          mb={"20px"}>
          <LineChart
            data={chartData}
            customYTitle={chartData?.at(0)?.yTitle}
            customYMinValue={selectedTab === "humidity" ? 0 : null}
            customYMaxValue={selectedTab === "humidity" ? 100 : null}
            maintainAspectRatio={false}
          />
        </Box>
      ) : (
        <Stack sx={styles.empty}>
          <>
            <Typography
              gutterBottom
              variant="subtitle1">
              NO DEVICE DATA AVAILABLE
            </Typography>
            <Typography variant="body2">
              Please check that your device has its connection
            </Typography>
          </>
        </Stack>
      )}
    </Stack>
  );
};

export default PaintBoothChart;

const styles = {
  empty: {
    py: 5,
    my: 3,
    bgcolor: "#F3F6FF",
    textAlign: "center",
    borderRadius: "8px",
    boxShadow:
      "0px 11px 15px rgba(47, 74, 170, 0.05), 0px 9px 46px rgba(47, 74, 170, 0.03), 0px 24px 38px rgba(47, 74, 170, 0.04);"
  },
  errorMessage: {
    fontSize: "30px",
    display: "flex",
    justifyContent: "center",
    marginTop: "30px"
  }
};
