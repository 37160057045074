import { useMemo } from "react";
import useGetBuildings from "src/hooks/useGetBuildings";
import { Filters } from "src/shared/components/main-menu/main-menu.component";
import { FormattedBuildingWithEntity } from "src/shared/types/building";

export const useGetFilteredBuildings = (filter?: Filters) => {
  const { buildings, getSortedBuildings } = useGetBuildings();

  const filteredBuildings: FormattedBuildingWithEntity[] = useMemo(() => {
    return buildings.filter(building => {
      if (filter === Filters.HVAC) {
        return building.has_ahus;
      }
      if (filter === Filters.KETTLE) {
        return building.has_kettles;
      }
      if (filter === Filters.BOOTH) {
        return building.has_paint_booths;
      }
      if (filter === Filters.IAQ) {
        return building.has_rooms;
      }
    });
  }, [buildings, filter]);

  const sortedBuildings = useMemo(() => {
    return getSortedBuildings(filteredBuildings, `/buildings/${filter}`);
  }, [getSortedBuildings, filter, filteredBuildings]);

  return { sortedBuildings };
};

export default useGetFilteredBuildings;
