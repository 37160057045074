import { makeStyles } from "tss-react/mui";
import { useState } from "react";
import useGetAppBreakPoints from "src/hooks/useGetAppBreakPoints";
import { Event } from "src/utils/client";
import { Pagination } from "@mui/material";

type Props = {
  classes?: any;
  hvacActivity?: Event[];
  setPageData?: Function;
};

const HvacActivityPagination = (props: Props) => {
  const { hvacActivity, setPageData } = props;
  const { classes } = useStyles();
  const [pagination, setPagination] = useState({
    from: 0,
    to: 5
  });
  const { isMd } = useGetAppBreakPoints();

  const handleChange = (_, page: number) => {
    const from = (page - 1) * 5;
    const to = (page - 1) * 5 + 5;
    const slicedData = hvacActivity?.slice(from, to);
    setPageData(slicedData);
    setPagination({ ...pagination, from: from, to: to });
  };

  return (
    <div className={classes.container}>
      {isMd ? (
        <Pagination
          data-testid="pagination-md"
          count={Math.ceil(hvacActivity?.length / 5)}
          siblingCount={0}
          onChange={handleChange}
        />
      ) : (
        <Pagination
          data-testid="pagination-lg"
          count={Math.ceil(hvacActivity?.length / 5)}
          onChange={handleChange}
        />
      )}
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    container: {
      display: "flex",
      justifyContent: "center",
      padding: "1.5rem 0rem",
      borderTop: "2px solid rgba(0, 0, 0, 0.1)",
      width: "100%"
    }
  };
});

export default HvacActivityPagination;
