import { FormattedAhu } from "src/shared/types/ahu";
import { apiService } from "../api";
import { Ahu, AhusService, Stage, AhuStagesService } from "src/utils/client";
import { defaultPromiseArg } from "../widgets/types";

export const hvacService = apiService
  .enhanceEndpoints({
    addTagTypes: ["AHUSWITHFILTER", "ROOMS", "AHUSSTAGES"]
  })
  .injectEndpoints({
    endpoints: build => ({
      getAhusWithFilterLoad: build.query<FormattedAhu[], string>({
        // @ts-ignore
        queryFn: async buildingId => {
          const ahus = await AhusService.getAhusWithFilterload(buildingId);
          const formattedAhus = Object.values(ahus).map(({ ahu, stages }) => ({
            id: ahu.id,
            building_id: ahu.building_id,
            average_airflow_cfm: ahu.average_airflow_cfm,
            maximum_airflow_cfm: ahu.maximum_airflow_cfm,
            name: ahu.name,
            variable_freq_drive: ahu.variable_freq_drive,
            zone: ahu.name,
            stages: Object.values(stages),
            created_at: ahu.created_at
          }));
          return { data: formattedAhus };
        },
        providesTags: ["AHUSWITHFILTER"]
      }),
      createAhu: build.mutation<Ahu, { buildingId: string; ahu: any }>({
        //@ts-ignore
        queryFn: async args => {
          const { buildingId, ahu } = args;
          const newAhu = await AhusService.createAhu(buildingId, ahu);
          return newAhu;
        },
        invalidatesTags: ["AHUSWITHFILTER"]
      }),
      updateAhu: build.mutation<Ahu, { buildingId: string; ahu: Ahu }>({
        //@ts-ignore
        queryFn: async args => {
          const { buildingId, ahu } = args;
          const updatedAhu = await AhusService.updateAhu(
            buildingId,
            ahu.id,
            ahu
          );
          return updatedAhu;
        },
        invalidatesTags: ["AHUSWITHFILTER"]
      }),
      getAhuStages: build.query<
        Stage[],
        Pick<defaultPromiseArg, "buildingId" | "ahuId">
      >({
        queryFn: async args => {
          const { buildingId, ahuId } = args;
          const ahuStages = await AhuStagesService.getStagesForAhu(
            buildingId,
            ahuId
          );
          return { data: ahuStages };
        },
        providesTags: (_result, _error, { ahuId }) => [
          { type: "AHUSSTAGES", ahuId }
        ]
      }),
      createAhuStage: build.mutation<
        Stage,
        Pick<defaultPromiseArg, "buildingId" | "ahuId"> & {
          body: any;
        }
      >({
        // @ts-ignore
        queryFn: async (args, { dispatch }) => {
          const { buildingId, ahuId, body } = args;
          return await AhuStagesService.addStageToAhu(buildingId, ahuId, {
            building_id: buildingId,
            ...body
          });
        },
        invalidatesTags: (_result, _error, { ahuId }) => [
          { type: "AHUSSTAGES", ahuId },
          "AHUSWITHFILTER"
        ]
      }),
      updateAhuStage: build.mutation<
        Stage,
        Pick<defaultPromiseArg, "buildingId" | "ahuId" | "stageId"> & {
          body: any;
        }
      >({
        // @ts-ignore
        queryFn: async args => {
          const { buildingId, ahuId, stageId, body } = args;
          return await AhuStagesService.updateStage(
            buildingId,
            ahuId,
            stageId,
            body
          );
        },
        invalidatesTags: (_result, _error, { ahuId }) => [
          { type: "AHUSSTAGES", ahuId },
          "AHUSWITHFILTER"
        ]
      })
    })
  });

export const {
  useGetAhusWithFilterLoadQuery,
  useCreateAhuMutation,
  useUpdateAhuMutation,
  useGetAhuStagesQuery,
  useCreateAhuStageMutation,
  useUpdateAhuStageMutation
} = hvacService;
