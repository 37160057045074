import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "src/hooks/useAppSelector";
import { getAccessToken } from "src/store/auth/selectors";
import Dashboard from "src/sections/dashboard/view/dashboard.section";
import DrawerProvider from "src/sections/dashboard/Context/DashboardContext";
const PrivateRoute = () => {
  const isSignedIn = useAppSelector(getAccessToken);

  if (!isSignedIn) {
    return <Navigate to="/login" />;
  }
  return (
    <DrawerProvider>
      <Dashboard>
        <Outlet />
      </Dashboard>
    </DrawerProvider>
  );
};

export default PrivateRoute;
