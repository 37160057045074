import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

const useGetAppBreakPoints = () => {
  const theme = useTheme();
  return {
    /**
     * isMd represent breakpoint up to 900px
     */
    isMd: useMediaQuery(theme.breakpoints.down("md")),
    /**
     * isSM represent breakpoint up to 600px
     */
    isSm: useMediaQuery(theme.breakpoints.down("sm")),
    /**
     * isTablet represent breakpoint up to 705px
     */
    isTablet: useMediaQuery("@media (max-width:705px)"),
    /**
     * isLG represent breakpoint up to 1200px
     */
    isLg: useMediaQuery(theme.breakpoints.up("lg")),
    /**
     * isXl represent breakpoint up to 1536px
     */
    isXl: useMediaQuery(theme.breakpoints.up("xl"))
  };
};

export default useGetAppBreakPoints;
