import moment from "moment";
import AlertInfo from "./alert-info.component";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { getAlertStatus, getStageName } from "../../activity-utils";
import { Grid, Typography } from "@mui/material";
import { AlertReturn, Stage } from "src/utils/client";
import useGetAppBreakPoints from "src/hooks/useGetAppBreakPoints";
import { useStyles } from "./alert-item.style";

type AlertItemProps = {
  alert: AlertReturn;
  stages: Stage[];
  time: string;
};

const AlertItem = ({ alert, stages, time }: AlertItemProps) => {
  const { isMd } = useGetAppBreakPoints();
  const { classes } = useStyles();
  const stageName = getStageName(stages, (alert?.device as any)?.stage_id);
  const inAlert = getAlertStatus(alert);
  const color = inAlert ? "rgb(232, 37, 71)" : "#4CAF50";

  const getAlertState = () => {
    return inAlert ? (
      <Typography className={classes.inAlertTitle}>In Alert</Typography>
    ) : (
      <Typography className={classes.outOfAlertTitle}>Out of Alert</Typography>
    );
  };

  return isMd ? (
    <Grid
      data-testid="alert"
      sm={12}
      xs={12}
      container
      className={classes.alert}>
      <Grid
        data-testid="alert-md"
        sm={12}
        xs={12}
        container
        className={classes.margin}>
        <Grid
          sm={8}
          xs={8}
          container
          className={classes.item}>
          <Grid
            item
            sm={2}
            xs={2}
            className={classes.iconItem}>
            {inAlert ? (
              <WarningIcon
                data-testid="in-alert-icon"
                fontSize="small"
                className={classes.exclamation}
              />
            ) : (
              <CheckCircleIcon
                data-testid="out-alert-icon"
                fontSize="small"
                className={classes.check}
              />
            )}
          </Grid>
          <Grid
            item
            sm={6}
            xs={6}>
            {getAlertState()}
          </Grid>
        </Grid>
        <Grid
          item
          sm={4}
          xs={4}
          className={classes.stageItem}>
          <Typography className={classes.stage}>{stageName}</Typography>
        </Grid>
      </Grid>
      <Grid
        item
        sm={12}
        xs={12}
        className={classes.alertInfo}>
        <AlertInfo
          alert={alert}
          color={color}
        />
      </Grid>
      <Grid
        item
        sm={12}
        xs={12}
        className={classes.margin}>
        <Typography className={classes.date}>
          {moment(time).format("lll")}
        </Typography>
      </Grid>
    </Grid>
  ) : (
    <Grid
      data-testid="alert"
      item
      lg={12}
      md={12}
      className={classes.alert}>
      <Grid
        data-testid="alert-lg"
        item
        lg={1}
        md={1}
        className={classes.exclamationItem}>
        {inAlert ? (
          <WarningIcon
            data-testid="in-alert-icon"
            fontSize="small"
            className={classes.exclamation}
          />
        ) : (
          <CheckCircleIcon
            data-testid="out-alert-icon"
            fontSize="small"
            className={classes.check}
          />
        )}
      </Grid>
      <Grid
        item
        lg={2}
        md={2}
        className={classes.item}>
        {getAlertState()}
      </Grid>
      <Grid
        item
        lg={2}
        md={2}
        className={classes.item}>
        <Typography className={classes.stage}>{stageName}</Typography>
      </Grid>
      <Grid
        item
        lg={9}
        md={10}
        className={classes.alertInfo}>
        <AlertInfo
          alert={alert}
          color={color}
        />
      </Grid>
      <Grid
        item
        lg={2}
        md={2}
        className={classes.dateItem}>
        <Typography className={classes.date}>
          {moment(time).format("MMM D, YYYY")}
        </Typography>
        <Typography className={classes.date}>
          {moment(time).format("h:mm A")}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AlertItem;
