import { Grid, Typography, Button } from "@mui/material";
import { FormFieldProps } from "../types";

const FormButtonField = ({
  classes,
  setValue,
  initalValue,
  systemOptions,
  labelName
}: Omit<FormFieldProps, "labelConfig" | "type">) => {
  return (
    <div className={classes.settings}>
      <Grid
        spacing={2}
        container
        className={classes.settingsGrid}>
        <Grid
          item
          xs={12}
          sm={4}>
          <Typography>{labelName}</Typography>
        </Grid>
        {systemOptions?.map(item => {
          const value = "value" in item ? item.value : "";
          return (
            <Grid
              key={`option_${value}`}
              item
              xs={6}
              sm={4}>
              <Button
                className={classes.btn}
                disabled={initalValue === value}
                variant={initalValue === value ? "contained" : "outlined"}
                onClick={() => setValue(value)}
                color="inherit">
                {item.label}
              </Button>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default FormButtonField;
