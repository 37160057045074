import { Box, Stack, Typography } from "@mui/material";
import moment from "moment";
import useUserCelsiusSettings from "src/hooks/useUserCelsiusSettings";
import { useGetUserSettings } from "src/sections/settings/hooks";
import LoaderComponent from "src/shared/components/loader/loader.component";
import { useGetSensorDataForPaintBoothZoneQuery } from "src/store/services/booth/booth.service";
import { PressureUnits } from "src/utils/client";
import { convertFromPascalsToIWG } from "src/utils/pressure.utils";
import { convertToFahrenheit } from "src/utils/units";

type PaintSensorDetailsProps = {
  buildingId: string;
  boothId: string;
  zoneId: string;
};

const PaintSensorDetails = ({
  buildingId,
  boothId,
  zoneId
}: PaintSensorDetailsProps) => {
  const { pressure } = useGetUserSettings();
  const isUserUsingCelsius = useUserCelsiusSettings();
  const {
    data: sensorData,
    isLoading,
    isError
  } = useGetSensorDataForPaintBoothZoneQuery({
    buildingId,
    boothId,
    zoneId
  });

  const isUserUsingIWG = pressure === PressureUnits.IWG;
  return (
    <>
      {isLoading ? (
        <LoaderComponent customWidth={35}></LoaderComponent>
      ) : isError ? (
        <Typography data-testid="error">Error occured </Typography>
      ) : (
        <Box sx={{ mx: "5px" }}>
          <DetailsRow
            label="Pressure"
            value={
              isUserUsingIWG
                ? sensorData?.data?.pressure_drop?.value?.toFixed(2)
                : convertFromPascalsToIWG(
                    sensorData?.data?.pressure_drop?.value
                  )?.toFixed(2)
            }
            unit={isUserUsingIWG ? PressureUnits.IWG.toUpperCase() : "Pa"}
          />
          <DetailsRow
            label="Temperature"
            value={
              isUserUsingCelsius
                ? sensorData?.data?.temperature?.value?.toFixed()
                : convertToFahrenheit(
                    sensorData?.data?.temperature?.value
                  )?.toFixed()
            }
            unit={isUserUsingCelsius ? "°C" : "°F"}
          />
          <DetailsRow
            label="Humidity"
            value={sensorData?.data?.humidity?.value?.toString()}
            unit={"%"}
          />
          <DetailsRow
            label="Last Updated"
            value={
              sensorData
                ? moment(sensorData?.time).format("MMM DD, HH:MM")
                : "N/A"
            }
          />
        </Box>
      )}
    </>
  );
};

export default PaintSensorDetails;

const DetailsRow = ({
  label,
  value,
  unit
}: {
  label: string;
  value: string;
  unit?: string;
}) => (
  <Stack
    justifyContent={"space-between"}
    flexDirection={"row"}
    alignItems={"center"}
    my={"5px"}>
    <Typography sx={{ color: "black", fontSize: "14px", my: "2px" }}>
      {label}
    </Typography>
    <Typography
      data-testid={`${label}-unit`}
      sx={{ color: "black", fontSize: "14px" }}>
      {value} {unit ? unit : ""}
    </Typography>
  </Stack>
);
