import classNames from "classnames";
import { makeStyles } from "tss-react/mui";
import { alpha, Typography } from "@mui/material";

import {
  EXPECTANCY_GREEN,
  EXPECTANCY_ORANGE,
  EXPECTANCY_RED,
  EXPECTANCY_YELLOW
} from "src/shared/constants/ahu-status";
import { StageAndFilterload } from "src/utils/client";
import { NA } from "src/shared/constants/generals";

type Props = {
  stage: StageAndFilterload;
};

const RemainingWeeksComponent = (props: Props) => {
  const { stage } = props;
  const { classes } = useStyles();
  let status = "default";
  let qty = Number(stage.filterload?.remaining_useful_life);

  if (!isNaN(qty)) {
    if (qty > 4) {
      status = EXPECTANCY_GREEN;
    }
    if (qty <= 4) {
      status = EXPECTANCY_YELLOW;
    }
    if (qty <= 2) {
      status = EXPECTANCY_ORANGE;
    }
    if (qty <= 1) {
      status = EXPECTANCY_RED;
    }
  } else {
    qty = Number(stage.filterload?.remaining_useful_life);
  }

  return (
    <div data-testid="remaining-weeks">
      <div
        className={classNames(
          classes.pollutant,
          classes[`background${status}`]
        )}>
        <Typography
          className={classNames(classes.root, classes[`status${status}`])}>
          {isNaN(qty) ? NA : qty}
        </Typography>
      </div>
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {},
    pollutant: {
      textAlign: "center" as const,
      border: "1px solid #E9EAEA",
      borderRadius: 8,
      padding: 2,
      minWidth: 50
    },
    [`background${EXPECTANCY_RED}`]: {
      background: alpha("#ff0000", 0.2),
      border: `1px solid ${alpha("#ff0000", 0.5)}`
    },
    [`background${EXPECTANCY_GREEN}`]: {
      background: alpha("#00ff00", 0.2),
      border: `1px solid ${alpha("#00ff00", 0.5)}`
    },
    [`backgrounddefault`]: {
      background: alpha("#E9EAEA", 0.2),
      border: `1px solid ${alpha("#E9EAEA", 0.5)}`
    },
    [`background${EXPECTANCY_ORANGE}`]: {
      background: alpha("#ffa500", 0.2),
      border: `1px solid ${alpha("#ffa500", 0.5)}`
    },
    [`background${EXPECTANCY_YELLOW}`]: {
      background: alpha("#fbc02d", 0.2),
      border: `1px solid ${alpha("#fbc02d", 0.5)}`
    },
    [`status${EXPECTANCY_RED}`]: {
      color: "#ff0000"
    },
    [`status${EXPECTANCY_GREEN}`]: {
      color: "#00ff00"
    },
    [`status${EXPECTANCY_ORANGE}`]: {
      color: "#ffa500"
    },
    [`status${EXPECTANCY_YELLOW}`]: {
      color: "#fbc02d"
    },
    [`statusdefault`]: {
      color: "grey"
    }
  };
});

export default RemainingWeeksComponent;
