import moment from "moment";
import BuildIcon from "@mui/icons-material/Build";
import { ServiceLogReturn, Stage } from "src/utils/client";
import { Grid, Typography } from "@mui/material";
import useGetAppBreakPoints from "src/hooks/useGetAppBreakPoints";
import { useStyles } from "./service-log.style";
import { setSelectedServiceLog } from "src/store/servicelogs/reducer";
import { getStageName } from "../../activity-utils";
import { useDispatch } from "react-redux";

type ServiceLogItemProps = {
  event?: ServiceLogReturn;
  stages: Stage[];
  setOpen: (data: boolean) => void;
};

const ServiceLogItem = ({ event, stages, setOpen }: ServiceLogItemProps) => {
  const { time, details, user } = event;
  const { isMd } = useGetAppBreakPoints();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  // @ts-ignore
  const stageName = getStageName(stages, details?.ids?.stage_id);

  const handleEdit = () => {
    dispatch(setSelectedServiceLog(event));
    setOpen(true);
  };

  return isMd ? (
    <Grid
      data-testid="service"
      sm={12}
      xs={12}
      container
      className={classes.log}>
      <Grid
        data-testid="service-md"
        sm={12}
        xs={12}
        container
        className={classes.margin}>
        <Grid
          sm={8}
          xs={8}
          container
          className={classes.item}>
          <Grid
            item
            sm={2}
            xs={2}
            className={classes.iconItem}>
            <BuildIcon
              fontSize="small"
              className={classes.wrench}
            />
          </Grid>
          <Grid
            item
            sm={6}
            xs={6}>
            <Typography className={classes.logTitle}>
              {details?.title}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          sm={4}
          xs={4}
          className={classes.stageItem}>
          <Typography className={classes.stage}>{stageName}</Typography>
        </Grid>
      </Grid>
      <Grid
        item
        sm={12}
        xs={12}
        className={classes.descriptionItem}>
        <div>
          <Typography>{details?.notes}</Typography>
          <Typography className={classes.worker}>by: {user?.name}</Typography>
          <Typography
            className={classes.editButton}
            onClick={handleEdit}>
            Edit
          </Typography>
        </div>
      </Grid>
      <Grid
        item
        sm={12}
        xs={12}
        className={classes.margin}>
        <Typography className={classes.date}>
          {moment(time).format("lll")}
        </Typography>
      </Grid>
    </Grid>
  ) : (
    <Grid
      data-testid="service"
      item
      lg={12}
      md={12}
      className={classes.log}>
      <Grid
        data-testid="service-lg"
        item
        lg={1}
        md={1}
        className={classes.wrenchItem}>
        <BuildIcon
          fontSize="small"
          className={classes.wrench}
        />
      </Grid>
      <Grid
        item
        lg={2}
        md={2}
        className={classes.item}>
        <Typography className={classes.logTitle}>{details?.title}</Typography>
      </Grid>
      <Grid
        item
        lg={2}
        md={2}
        className={classes.item}>
        <Typography className={classes.stage}>{stageName}</Typography>
      </Grid>
      <Grid
        item
        lg={9}
        md={10}
        className={classes.descriptionItem}>
        <div style={{ width: "100%" }}>
          <Typography>{details?.notes}</Typography>
          <div className={classes.workerAndEdit}>
            <Typography className={classes.worker}>by: {user?.name}</Typography>
            <Typography
              className={classes.editButton}
              onClick={handleEdit}>
              Edit
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid
        item
        lg={2}
        md={2}
        className={classes.dateItem}>
        <Typography className={classes.date}>
          {moment(time).format("MMM D, YYYY")}
        </Typography>
        <Typography className={classes.date}>
          {moment(time).format("h:mm A")}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ServiceLogItem;
