import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";

const selectNotification = (state: RootState) => state.notification;

export const getNotificationDrawerState = createSelector(
  selectNotification,
  state => state.drawerOpen
);

export const getAlertsDrawerState = createSelector(
  selectNotification,
  state => state.alertsDrawerOpen
);

export const getAlertModalState = createSelector(
  selectNotification,
  state => state.alertModalOpen
);

export const getSelectedNotification = createSelector(
  selectNotification,
  state => state.selectedNotification
);

export const getSelectedNotificationId = createSelector(
  selectNotification,
  state => state.selectedNotificationId
);

export const getAlertModalData = createSelector(
  selectNotification,
  state => state.alertModalData
);
