import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Button, Stack } from "@mui/material";
import BuildingTabNavigation from "src/shared/components/building-tab-navigation/building-tab-navigation";
interface DetailsHeaderProps {
  item?: any;
  loading: boolean;
  hasItems: boolean;
  navigate: (val: boolean) => void;
}

export default function DetailsHeader({
  item,
  loading,
  hasItems,
  navigate
}: DetailsHeaderProps) {
  return (
    <Box sx={styles.container}>
      <BuildingTabNavigation titleName={item?.name} />
      {hasItems && (
        <Stack direction={"row"}>
          <Button
            sx={[styles.button, { mr: 1 }]}
            data-testid="prev-ahu"
            variant="contained"
            disabled={loading}
            onClick={() => navigate(false)}>
            <ArrowBackIosRoundedIcon />
          </Button>
          <Button
            sx={styles.button}
            data-testid="next-ahu"
            variant="contained"
            disabled={loading}
            onClick={() => navigate(true)}>
            <ArrowForwardIosIcon />
          </Button>
        </Stack>
      )}
    </Box>
  );
}

const styles = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    position: "sticky",
    top: 0,
    background: "#F3F6FF",
    zIndex: 1,
    padding: "1em 16px 0 16px",
    margin: "0 -16px"
  },
  button: {
    color: "black",
    width: 36,
    height: 36,
    minHeight: 36,
    minWidth: 36,
    backgroundColor: "white",
    "& svg": {
      fontSize: "12px"
    },
    "&:hover": {
      color: "white"
    }
  }
};
