import { Container, Stack, Grid, Paper, Box } from "@mui/material";
import { useAppSelector } from "src/hooks/useAppSelector";
import DetailsHeader from "../DetailsHeader";
import SetPoints from "../AhuDetails/SetPoints";
import { getSelectedBooth } from "src/store/booth/selector";
import SetPointsModal from "../AhuDetails/SetPointsModal";
import PaintBoothChart from "./PaintBoothChart";
import PaintBoothSidePanel from "./PaintBoothSidePanel";
import ActivityLogs from "src/sections/hvac/air-handling-units/ahu-details/hvac-activity-log/activity-log.component";
import { useState } from "react";

const PaintBoothDetails = () => {
  const booth = useAppSelector(getSelectedBooth);
  const [openAddServiceLog, setOpenAddServiceLog] = useState(false);
  return (
    <Container
      maxWidth={false}
      sx={{ p: 2, pt: 0, bgcolor: "#f3f6ff" }}
      data-testid="paint-booth-details">
      <Stack
        justifyContent={"space-between"}
        flexDirection={"row"}>
        <DetailsHeader
          item={booth}
          loading={false}
          hasItems={false}
          navigate={() => {}}
        />
        <SetPoints />
      </Stack>

      <Paper sx={{ heigh: "100vh", padding: "10px" }}>
        <Grid
          container
          spacing={2}>
          <Grid
            item
            xs={12}
            sm={5}
            md={4}
            lg={3}
            xl={2.5}>
            <Box sx={styles.asideContainer}>
              <PaintBoothSidePanel />
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={7}
            md={8}
            lg={9}
            xl={9.5}>
            <Stack my={2}>
              <PaintBoothChart />
              <Box style={styles.auditLogContainer}>
                <ActivityLogs
                  buildingId={booth?.building_id}
                  paintBooth={booth}
                  open={openAddServiceLog}
                  setOpen={setOpenAddServiceLog}></ActivityLogs>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
      <SetPointsModal />
    </Container>
  );
};

export default PaintBoothDetails;
export const styles = {
  auditLogContainer: {
    border: "2px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "1rem",
    ml: { md: "-1rem", sm: "0rem" }
  },
  asideContainer: {
    marginTop: "30px",
    marginLeft: "15px"
  }
};
