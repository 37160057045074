import { useState, useMemo } from "react";
import { makeStyles } from "tss-react/mui";
import { WidgetSettings } from "../widgets-config";
import { GetCompanyIDFromBuildingId } from "src/utils/buildings.util";
import Error from "src/shared/components/Error/Error";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
  TextField
} from "@mui/material";
import {
  DeviceGroup,
  getParentsForInput
} from "../../utils/entity-selection-utils";
import useGetWidgetSettingsEntities from "src/hooks/useGetWidgetSettingsEntities";

const PressureCurvesSettingsComponent = ({
  onSave,
  currentProps
}: WidgetSettings) => {
  const { classes } = useStyles();
  const [buildingId, setBuildingId] = useState<string>(
    currentProps.buildingId ?? ""
  );
  const [ahuId, setAhuId] = useState<string>(currentProps.ahuId ?? "");
  const [hours, setHours] = useState<number>(currentProps.hours ?? 72);

  const { buildings, deviceParents, isLoading, isFetching, isError, refetch } =
    useGetWidgetSettingsEntities();

  const ahuBuildings = useMemo(() => {
    if (!buildings) return;
    return buildings.filter(building => building.has_ahus);
  }, [buildings]);

  const ahusForInput = useMemo(
    () => getParentsForInput(deviceParents, buildingId, DeviceGroup.hvac),
    [deviceParents, buildingId]
  );

  const handleDaysInput = (input: number) => {
    if (input > 0) {
      setHours(input * 24);
    } else setHours(null);
  };

  return (
    <div
      data-testid="select-ahu-settings"
      className={classes.root}>
      {isLoading || isFetching ? (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      ) : isError ? (
        <Error
          error={isError}
          setRefetchAgain={refetch}
          showGenericMessage
        />
      ) : (
        <div>
          <FormControl fullWidth>
            <InputLabel>Building</InputLabel>
            <Select
              value={buildingId}
              label="Building"
              onChange={({ target: { value } }) => {
                setAhuId("");
                setBuildingId(value);
              }}>
              {ahuBuildings.map(building => (
                <MenuItem
                  key={`building_option_${building.id}`}
                  value={building.id}>
                  {building.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className={classes.ahuSelector}>
            <FormControl fullWidth>
              <InputLabel>AHU</InputLabel>
              <Select
                value={ahuId}
                label="AHU"
                onChange={({ target: { value } }) => {
                  const selectedAhu = ahusForInput?.find(
                    ahu => ahu.id === value
                  );
                  if (selectedAhu) {
                    setBuildingId(selectedAhu.building_id);
                  }
                  setAhuId(value);
                }}>
                {ahusForInput?.map(ahu => (
                  <MenuItem
                    key={`ahu_option_${ahu.id}`}
                    value={ahu.id}>
                    {ahu.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className={classes.ahuSelector}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                label="Days"
                type="number"
                defaultValue={hours / 24}
                onChange={event => handleDaysInput(Number(event.target.value))}
                inputProps={{ min: 1 }}
              />
            </FormControl>
          </div>
        </div>
      )}
      <div className={classes.actions}>
        <Button
          disabled={!Boolean(ahuId && hours)}
          variant="contained"
          onClick={() => {
            onSave({
              ...currentProps,
              hours,
              buildingId,
              companyId: GetCompanyIDFromBuildingId(buildingId, buildings),
              ahuId
            });
          }}>
          Save
        </Button>
      </div>
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {},
    loader: {
      display: "flex",
      justifyContent: "center"
    },
    actions: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "1em",
      gap: "1em"
    },
    ahuSelector: {
      marginTop: "1em"
    }
  };
});

export default PressureCurvesSettingsComponent;
