import { makeStyles } from "tss-react/mui";
import { nanoid } from "nanoid";

import {
  alpha,
  FormControl,
  InputLabel,
  TextField,
  TextFieldProps
} from "@mui/material";

const CustomTextField = (props: TextFieldProps) => {
  const { label } = props;
  const { classes } = useStyles();
  const id = nanoid();
  return (
    <FormControl
      fullWidth
      variant="standard">
      <InputLabel
        shrink
        className={classes.label}>
        {label}
      </InputLabel>
      <TextField
        className={classes.input}
        {...props}
        label={null}
        InputLabelProps={{ shrink: true }}
        id={id}
      />
    </FormControl>
  );
};

export const useStyles = makeStyles()(theme => {
  return {
    label: {
      fontWeight: "bold" as const,
      zIndex: "4",
      top: -10
    },
    input: {
      "& .MuiInputBase-input": {
        borderRadius: 8,
        position: "relative",
        backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
        width: "100%",
        transition: theme.transitions.create([
          "border-color",
          "background-color",
          "box-shadow"
        ]),
        "&:focus": {
          boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
          borderColor: theme.palette.primary.main
        }
      }
    }
  };
});

export default CustomTextField;
