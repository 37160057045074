import { useAppSelector } from "src/hooks/useAppSelector";
import { getTradefairSelectedDeviceId } from "src/store/buildings/selector";
import useGetTradefairInfo from "./useGetTradefairInfo";

const useGetSelectedDevice = () => {
  const selectedDeviceId = useAppSelector(getTradefairSelectedDeviceId);
  const { mainData } = useGetTradefairInfo();
  const device = mainData?.aq_devices[selectedDeviceId];
  return {
    selectedDevice:
      mainData && device
        ? {
            ...mainData.aq_devices[selectedDeviceId],
            aqData: mainData?.aq_data[selectedDeviceId],
            id: selectedDeviceId
          }
        : null
  };
};

export default useGetSelectedDevice;
