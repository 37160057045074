/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: Revoked invitations are just deleted
 */
export enum InvitationStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
}
