import { makeStyles } from "tss-react/mui";
export const useStyles = makeStyles()(() => {
  return {
    root: {
      position: "absolute",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 2,
      top: "50%"
    }
  };
});
