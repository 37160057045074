/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HvacSensor } from '../models/HvacSensor';
import type { HvacSensorAndLatest } from '../models/HvacSensorAndLatest';
import type { HvacSensorNew } from '../models/HvacSensorNew';
import type { HvacSensorUpdates } from '../models/HvacSensorUpdates';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class HvacSensorsService {
  /**
   * Get Hvac Sensors For Building
   * Returns possibly empty list of hvac sensors for the building.
   * @param buildingId
   * @param includeInactive
   * @returns HvacSensor Successful Response
   * @throws ApiError
   */
  public static getHvacSensorsForBuilding(
    buildingId: string,
    includeInactive: boolean = false,
  ): CancelablePromise<Array<HvacSensor>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/hvac-sensors/',
      path: {
        'building_id': buildingId,
      },
      query: {
        'include_inactive': includeInactive,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Create Hvac Sensor
   * @param buildingId
   * @param requestBody
   * @returns HvacSensor Successful Response
   * @throws ApiError
   */
  public static createHvacSensor(
    buildingId: string,
    requestBody: HvacSensorNew,
  ): CancelablePromise<HvacSensor> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/buildings/{building_id}/hvac-sensors/',
      path: {
        'building_id': buildingId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Database Error`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Hvac Sensor
   * Returns the hvac_sensor, with its latest measurement by default (or `null` if the id is invalid)
   * @param sensorId
   * @param buildingId
   * @param includeLatest
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getHvacSensor(
    sensorId: string,
    buildingId: string,
    includeLatest: boolean = true,
  ): CancelablePromise<(HvacSensorAndLatest | HvacSensor | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/hvac-sensors/{sensor_id}',
      path: {
        'sensor_id': sensorId,
        'building_id': buildingId,
      },
      query: {
        'include_latest': includeLatest,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Update Hvac Sensor
   * @param sensorId
   * @param buildingId
   * @param requestBody
   * @returns HvacSensor Successful Response
   * @throws ApiError
   */
  public static updateHvacSensor(
    sensorId: string,
    buildingId: string,
    requestBody: HvacSensorUpdates,
  ): CancelablePromise<HvacSensor> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/buildings/{building_id}/hvac-sensors/{sensor_id}',
      path: {
        'sensor_id': sensorId,
        'building_id': buildingId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Database Error`,
        404: `Invalid Object Id`,
        409: `Name Conflict`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Delete Hvac Sensor
   * @param sensorId
   * @param buildingId
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public static deleteHvacSensor(
    sensorId: string,
    buildingId: string,
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/buildings/{building_id}/hvac-sensors/{sensor_id}',
      path: {
        'sensor_id': sensorId,
        'building_id': buildingId,
      },
      errors: {
        400: `Bad Request`,
        404: `Invalid Object Id`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Update Hvac Sensor Installed At
   * @param sensorId
   * @param buildingId
   * @param installedAt
   * @returns HvacSensor Successful Response
   * @throws ApiError
   */
  public static updateHvacSensorInstalledAt(
    sensorId: string,
    buildingId: string,
    installedAt: string,
  ): CancelablePromise<HvacSensor> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/buildings/{building_id}/hvac-sensors/{sensor_id}/installed_at/',
      path: {
        'sensor_id': sensorId,
        'building_id': buildingId,
      },
      query: {
        'installed_at': installedAt,
      },
      errors: {
        400: `Database Error`,
        404: `Invalid Object Id`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Update Hvac Sensor Uninstalled At
   * Set `uninstalled_at=none` to clear uninstalled status and make into active sensor again.
   * If there are other sensors that have `sensor_id` as their `replaced_id`, then setting
   * this sensor to active again will return an error.
   * @param sensorId
   * @param buildingId
   * @param uninstalledAt
   * @returns HvacSensor Successful Response
   * @throws ApiError
   */
  public static updateHvacSensorUninstalledAt(
    sensorId: string,
    buildingId: string,
    uninstalledAt: string,
  ): CancelablePromise<HvacSensor> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/buildings/{building_id}/hvac-sensors/{sensor_id}/uninstalled_at/',
      path: {
        'sensor_id': sensorId,
        'building_id': buildingId,
      },
      query: {
        'uninstalled_at': uninstalledAt,
      },
      errors: {
        400: `Error in completing action`,
        404: `Invalid Object Id`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Update Hvac Sensor Replaced Id
   * Args:
   * - `replaced_id`: HvacSensorId of the sensor being replaced
   * @param sensorId
   * @param buildingId
   * @param replacedId
   * @returns HvacSensor Successful Response
   * @throws ApiError
   */
  public static updateHvacSensorReplacedId(
    sensorId: string,
    buildingId: string,
    replacedId: string,
  ): CancelablePromise<HvacSensor> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/buildings/{building_id}/hvac-sensors/{sensor_id}/replaced_id/',
      path: {
        'sensor_id': sensorId,
        'building_id': buildingId,
      },
      query: {
        'replaced_id': replacedId,
      },
      errors: {
        400: `Database Error`,
        404: `Invalid Object Id`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Hvac Sensors
   * Returns list of all hvac sensors by default, or all offline sensors if the filter is 'offline'.
   * @param filter
   * @param withLatest
   * @param includeInactive
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getHvacSensors(
    filter?: null,
    withLatest: boolean = false,
    includeInactive: boolean = false,
  ): CancelablePromise<(Array<HvacSensorAndLatest> | Array<HvacSensor>)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/hvac-sensors/',
      query: {
        'filter': filter,
        'with_latest': withLatest,
        'include_inactive': includeInactive,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
