import useGetBuildingView from "src/sections/buildings/hooks/useGetBuildingView";
import { Box, Grid, Hidden, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import BuildingsTableHeaderComponent from "src/sections/buildings/buildings-table-header/buildings-table-header.component";
import BuildingsEmpty from "src/sections/buildings/buildings-empty/buildings-empty.component";
import BuildingsRowComponent from "src/sections/buildings/buildings-row/buildings-row.component";
import { useCallback } from "react";
import RoomTableHeaderComponent from "src/sections/indoor-air-quality/rooms/room-table-header/room-table-header.component";
import RoomCardComponent from "src/sections/indoor-air-quality/rooms/room-card/room-card.component";
import RoomRowComponent from "src/sections/indoor-air-quality/rooms/room-row/room-row.component";
import RoomEmpty from "src/sections/indoor-air-quality/rooms/room-empty/room-empty.component";
import TableHeader from "src/sections/hvac/air-handling-units/ahu-table-header/ahu-table-header.component";
import AhuCardComponent from "src/sections/hvac/air-handling-units/ahu-card/ahu-card.component";
import AhuRowComponent from "src/sections/hvac/air-handling-units/ahu-row/ahu-row.component";
import AhuEmptyComponent from "src/sections/hvac/air-handling-units/ahu-empty/ahu-empty.component";
import useGetHvacView from "src/sections/hvac/air-handling-units/hooks/useGetHvacView";
import DeviceRowComponent from "src/sections/indoor-air-quality/devices/device-row/device-row.component";
import DeviceCardComponent from "src/sections/indoor-air-quality/devices/device-card/device-card.component";
import DevicesTableHeaderComponent from "src/sections/indoor-air-quality/devices/devices-table-header/devices-table-header.component";
import DeviceEmptyComponent from "src/sections/indoor-air-quality/devices/device-empty/device-empty.component";
import classNames from "classnames";
import AhuTableActions from "src/sections/hvac/air-handling-units/AhuTableActions/AhuTableActions";
import { useGetActiveView } from "src/sections/building-details/view/building-details.component";
import NotificationsHeaderComponent from "src/sections/notifications/notifications-header/notifications-header.component";
import NotificationRowComponent from "src/sections/notifications/notification-row/notification-row.component";
import KettleRowAndCardInfo from "src/sections/kettle/kettle.row.card";
import KettleEmpty from "src/sections/kettle/kettle.empty";
import KettleTableHeader from "src/sections/kettle/kettle.table.header";
import BoothTableHeader from "src/sections/booth/booth.table.header";
import BoothRowAndCardInfo from "src/sections/booth/booth.row.card";
import BoothEmpty from "src/sections/booth/booth.empty";

enum TableTypes {
  BUILDING = "building",
  IAQBUILDING = "iaq",
  ROOM = "room",
  AHU = "ahu",
  DEVICES = "devices",
  NOTIFICATIONS = "notifications",
  KETTLES = "kettles",
  BOOTHS = "booths"
}

type Props = {
  data: any;
  type: `${TableTypes}`;
  showHeader?: boolean;
  showPreActions?: boolean;
  showExtraActions?: boolean;
};

const Table = ({
  data,
  type,
  showHeader = false,
  showExtraActions = false
}: Props) => {
  const { classes } = useStyles();
  const {
    isChartView: isBuildingChartViewActive,
    isListView: isBuildingListViewActive
  } = useGetBuildingView();
  const {
    isChartViewActive: isHvacChartViewActive,
    isListViewActive: isHvacListViewActive
  } = useGetHvacView();
  const { isHvacViewActive, isIndoorViewActive, isRoomViewActive } =
    useGetActiveView();
  const dataExist = Boolean(data?.length);
  // For Mui Grid to work we need to pass configuration for each breakpoint
  // For more info https://mui.com/material-ui/react-grid/
  const tableConfiguration = getTableConfiguration(
    type === "building" ? isBuildingChartViewActive : isHvacChartViewActive
  );

  const getHeaderElement = useCallback(() => {
    switch (type) {
      case TableTypes.ROOM:
        return <RoomTableHeaderComponent />;
      case TableTypes.BUILDING:
        return <BuildingsTableHeaderComponent />;
      case TableTypes.AHU:
        return <TableHeader />;
      case TableTypes.DEVICES:
        return <DevicesTableHeaderComponent />;
      case TableTypes.NOTIFICATIONS:
        return <NotificationsHeaderComponent />;
      case TableTypes.BOOTHS:
        return <BoothTableHeader />;
      case TableTypes.KETTLES: {
        if (isHvacListViewActive) return <KettleTableHeader />;
        return null;
      }
      default:
        console.error("Choose correct table type.");
    }
  }, [isHvacListViewActive]);

  const getBodyElement = useCallback(
    (item: any) => {
      switch (type) {
        case TableTypes.ROOM:
          return isHvacChartViewActive ? (
            <RoomCardComponent room={item} />
          ) : (
            <RoomRowComponent room={item} />
          );
        case TableTypes.BUILDING:
          return <BuildingsRowComponent building={item} />;
        case TableTypes.AHU:
          return isHvacChartViewActive ? (
            <AhuCardComponent ahu={item} />
          ) : (
            <AhuRowComponent ahu={item} />
          );
        case TableTypes.KETTLES:
          return <KettleRowAndCardInfo kettle={item}></KettleRowAndCardInfo>;
        case TableTypes.DEVICES:
          return isHvacListViewActive ? (
            <DeviceRowComponent device={item} />
          ) : (
            <DeviceCardComponent device={item} />
          );
        case TableTypes.BOOTHS:
          return <BoothRowAndCardInfo booth={item} />;
        case TableTypes.NOTIFICATIONS:
          return <NotificationRowComponent notification={item} />;
        default:
          console.error("Choose correct table type.");
      }
    },
    [isHvacChartViewActive]
  );

  const getFooterElement = useCallback(() => {
    switch (type) {
      case TableTypes.ROOM:
        return <RoomEmpty />;
      case TableTypes.BUILDING:
        return <BuildingsEmpty />;

      case TableTypes.AHU:
        return <AhuEmptyComponent />;

      case TableTypes.KETTLES:
        return <KettleEmpty />;
      case TableTypes.DEVICES:
        return <DeviceEmptyComponent />;
      case TableTypes.BOOTHS:
        return <BoothEmpty />;
      case TableTypes.NOTIFICATIONS:
        return <div>No Notifications</div>;
      default:
        console.error("Choose correct table type.");
    }
  }, []);

  const getExtraActionElement = useCallback(() => {
    switch (type) {
      case TableTypes.AHU:
        return <AhuTableActions />;
      default:
        return null;
    }
  }, []);
  const hvacViewIsActive =
    isHvacViewActive || isRoomViewActive || isIndoorViewActive;
  const isListViewActive =
    (isHvacListViewActive && hvacViewIsActive) ||
    (isBuildingListViewActive && !hvacViewIsActive);

  return (
    <div className={classes.root}>
      {dataExist ? (
        <>
          <div>
            {showExtraActions && (
              <div
                className={classNames(
                  classes.header,
                  isListViewActive ? null : classes.headerChart
                )}>
                {getExtraActionElement()}
                {isListViewActive && <div className={classes.bottomBorder} />}
              </div>
            )}
            {showHeader && (
              <Hidden smDown>
                <div
                  data-testid="table-header"
                  className={classes.tableHeader}>
                  {getHeaderElement()}
                  <div className={classes.bottomBorder}></div>
                </div>
              </Hidden>
            )}
          </div>

          <Grid
            key="grid-container"
            container
            spacing={isListViewActive ? 0 : 2}
            sx={{
              top: isListViewActive ? 0 : 16,
              paddingBottom: isListViewActive ? 0 : 3
            }}
            className={classes.container}>
            {data.map((item, index) => (
              <Grid
                data-testid="item"
                sx={{ position: "relative" }}
                key={`grid_item_${index}`}
                item
                {...tableConfiguration}>
                {getBodyElement(item)}
                {isListViewActive && (
                  <Box
                    className={classes.bottomBorder}
                    sx={{ left: "24px" }}></Box>
                )}
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <div className={classes.emptyContainer}>
          <div className={classes.empty}>{getFooterElement()}</div>
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      width: "100%",
      minHeight: 500,
      backgroundColor: "rgb(243, 246, 255)"
    },
    header: {
      position: "relative" as const,
      background: "white",
      width: "100%",
      display: "flex",
      padding: "8px 24px",
      justifyContent: "space-between",
      "@media (max-width: 500px)": {
        paddingLeft: "0.5em"
      }
    },
    headerChart: {
      borderRadius: 4,
      marginBottom: 5,
      boxShadow:
        "0px 1px 0.5px rgba(0, 0, 0, 0.07), 0px 2px 2px rgba(0, 0, 0, 0.05);"
    },
    emptyContainer: {
      width: "100%",
      height: 500,
      display: "flex",
      flexDirection: "column" as const,
      justifyContent: "center" as const,
      alignItems: "center" as const
    },
    empty: {
      width: 350,
      padding: "2.5em",
      border: `1px solid ${theme.palette.grey[500]}`,
      backgroundColor: "white",
      boxShadow: "0px 0px 8px 1px gray",
      borderRadius: 5
    },
    headerOption: {
      marginLeft: "1em",
      display: "inline-block"
    },
    tableHeader: {
      padding: "12px 16px 12px 24px",
      background: "white",
      position: "relative" as const
    },
    loader: {
      position: "absolute" as const,
      top: 0,
      left: 0,
      width: "100%",
      height: "100%"
    },
    container: {
      backgroundColor: "rgb(243, 246, 255)",
      position: "relative" as const,
      width: "100%",
      maxWidth: "100%"
    },
    actions: {
      display: "flex",
      alignItems: "center"
    },
    leftContainer: {
      display: "flex"
    },
    compare: {},
    bottomBorder: {
      width: "calc(100% - 48px)",
      height: 1,
      backgroundColor: "#E9EAEA",
      position: "absolute" as const,
      bottom: 0
    }
  };
});

export default Table;

const getTableConfiguration = (isChartView: boolean) => {
  return {
    xs: isChartView ? 6 : 12,
    sm: isChartView ? 4 : 12,
    md: isChartView ? 3 : 12,
    lg: isChartView ? 2 : 12,
    xl: isChartView ? 2 : 12
  };
};
