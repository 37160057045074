import { useMemo } from "react";
import { useAppSelector } from "src/hooks/useAppSelector";
import Table from "src/shared/components/Table/Table";
import LoaderComponent from "src/shared/components/loader/loader.component";
import useGetHvacView from "../hvac/air-handling-units/hooks/useGetHvacView";
import { useParams } from "react-router-dom";
import { getBoothSearchTerm } from "src/store/booth/selector";
import { useGetPaintBoothsWithZonesQuery } from "src/store/services/booth/booth.service";

const Booths = () => {
  const { buildingId } = useParams();
  const boothSearchTerm = useAppSelector(getBoothSearchTerm);
  const { isListViewActive } = useGetHvacView();
  const {
    data: booths,
    isLoading,
    isFetching
  } = useGetPaintBoothsWithZonesQuery(buildingId);

  const boothsWithZones = useMemo(() => {
    if (!Boolean(booths)) return [];
    return Object.entries(booths).map(([_key, value]) => ({
      ...value.paint_booth,
      zones: value.zones
    }));
  }, [booths]);

  const sortedBooths = useMemo(() => {
    if (!Boolean(boothsWithZones)) return [];
    if (boothsWithZones?.length)
      return boothsWithZones?.filter(({ name }) =>
        name.toLowerCase().includes(boothSearchTerm)
      );

    return boothsWithZones;
  }, [boothsWithZones, boothSearchTerm]);

  return isLoading || isFetching ? (
    <LoaderComponent />
  ) : (
    <div data-testid="booths">
      <Table
        data={sortedBooths}
        type="booths"
        showHeader={isListViewActive}
      />
    </div>
  );
};

export default Booths;
