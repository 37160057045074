/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum EventType {
  CONNECTION_ALERT = 'connection_alert',
  THRESHOLD_ALERT = 'threshold_alert',
  SERVICE_LOG = 'service_log',
  PAINT_BOOTH_SET_POINT = 'paint_booth_set_point',
}
