/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum Unit {
  SCALAR = 'scalar',
  PPM = 'ppm',
  PPB = 'ppb',
  IWG = 'iwg',
  NUMBER = 'number',
  PASCALS = 'pascals',
  PERCENT = 'percent',
  PSI = 'psi',
  VOLTS = 'volts',
  MILLI_VOLTS = 'milli_volts',
  CELCIUS = 'celcius',
  FAHRENHEIT = 'fahrenheit',
  METERS_PER_S = 'meters_per_s',
  MICRO_G_PER_M3 = 'micro_g_per_m3',
  MILLI_G_PER_M3 = 'milli_g_per_m3',
}
