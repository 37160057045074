import LoaderComponent from "../loader/loader.component";
import { makeStyles } from "tss-react/mui";
import { Modal, Paper, Button, CircularProgress } from "@mui/material";

import {
  useGetUserTermsQuery,
  useUpdateUserTermsMutation
} from "src/store/services/general/general.service";
import { useAppSelector } from "src/hooks/useAppSelector";
import { getUserId } from "src/store/auth/selectors";

const TermsModalComponent = () => {
  const { classes } = useStyles();

  const userId = useAppSelector(getUserId);
  const { data: userTerms, isLoading } = useGetUserTermsQuery(userId);
  const [updateUserTerms, { isLoading: isUpdating }] =
    useUpdateUserTermsMutation();
  const acceptTerms = () => {
    updateUserTerms(userId);
  };
  const loading = isLoading || isUpdating;
  return (
    <Modal
      data-testid="terms-modal"
      open={!userTerms?.termsAccepted}
      className={classes.modal}>
      <Paper className={classes.root}>
        <div className={classes.container}>
          {loading && <LoaderComponent />}
          <div
            data-testid="terms-text"
            dangerouslySetInnerHTML={{
              __html: userTerms?.termsText
            }}></div>
          <div className={classes.actions}>
            <Button
              data-testid="terms-accept"
              onClick={acceptTerms}
              variant="contained">
              I Accept
              {loading && (
                <CircularProgress
                  sx={{ marginLeft: "1em", color: "#fff" }}
                  size={20}
                  color="secondary"
                />
              )}
            </Button>
          </div>
        </div>
      </Paper>
    </Modal>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    modal: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    root: {
      width: "75%",
      maxHeight: "80%",
      overflow: "auto",
      padding: 24,
      "@media (max-width: 820px)": {
        width: "80%"
      },
      "@media (max-width: 460px)": {
        width: "90%"
      }
    },
    actions: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: 24,
      paddingBottom: 8
    },
    container: {
      position: "relative" as const
    }
  };
});

export default TermsModalComponent;
