import moment from "moment";
import {
  TimeRangeObject,
  timeRangeFetchMethod,
  timeSpanOption
} from "../widgets/aq-building-with-time-settings/aq-building-with-time-settings.component";

export const defaultTwoWeeksRange: TimeRangeObject = {
  method: timeRangeFetchMethod?.TWO_WEEKS,
  timeQuantity: 2,
  timeSpan: timeSpanOption?.WEEKS,
  startDate: null,
  endDate: null
};

export const getRange = (
  method: timeRangeFetchMethod,
  timeQuantity?: number,
  timeSpan?: timeSpanOption,
  startDate?: number,
  endDate?: number
) => {
  switch (method) {
    case timeRangeFetchMethod.TWO_WEEKS:
      return {
        method,
        timeQuantity: 2,
        timeSpan: timeSpanOption.WEEKS
      };
    case timeRangeFetchMethod.ONE_MONTH:
      return {
        method,
        timeQuantity: 1,
        timeSpan: timeSpanOption.MONTHS
      };
    case timeRangeFetchMethod.CUSTOM_RANGE:
      return {
        method,
        timeQuantity: Number(timeQuantity),
        timeSpan
      };
    case timeRangeFetchMethod.CUSTOM_DATES:
      return {
        method,
        startDate,
        endDate
      };
    default:
      return {
        method: timeRangeFetchMethod.TWO_WEEKS,
        timeQuantity: 2,
        timeSpan: timeSpanOption.WEEKS
      };
  }
};

export const getStartDate = (
  timeSpan: timeSpanOption,
  timeQuantity: number
) => {
  const currentDate = new Date();

  switch (timeSpan) {
    case timeSpanOption.DAYS:
      currentDate.setDate(currentDate.getDate() - timeQuantity);
      break;
    case timeSpanOption.WEEKS:
      currentDate.setDate(currentDate.getDate() - timeQuantity * 7);
      break;
    case timeSpanOption.MONTHS:
      currentDate.setMonth(currentDate.getMonth() - timeQuantity);
      break;
    default:
      currentDate.setDate(currentDate.getDate() - timeQuantity * 7);
      break;
  }
  return currentDate.setHours(0, 0, 0, 0).valueOf();
};

export const getStartAndEndDate = (
  method: timeRangeFetchMethod,
  startDate?: string,
  endDate?: string,
  timeSpan?: timeSpanOption,
  timeQuantity?: number
) => {
  if (method === timeRangeFetchMethod.CUSTOM_DATES) {
    return {
      start: moment(startDate).format("ll"),
      end: moment(endDate).format("ll")
    };
  } else {
    return {
      start: moment(getStartDate(timeSpan, timeQuantity)).format("ll"),
      end: moment().format("ll")
    };
  }
};
