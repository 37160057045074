import { makeStyles } from "tss-react/mui";
import { alpha } from "@mui/material";
import {
  HAZARDOUS,
  UNHEALTHY,
  VERY_UNHEALTHY,
  SENSITIVE,
  MODERATE,
  GOOD,
  EXCELLENT,
  DEFAULT,
  OUTDATED
} from "src/shared/constants/building-status";
import { addUnderscoreInWord } from "../utils/textUtils";

export const useStyles = makeStyles()(theme => {
  return {
    [`status${HAZARDOUS}`]: {
      color: theme.palette.status[HAZARDOUS]
    },
    [`status${UNHEALTHY}`]: {
      color: theme.palette.status[UNHEALTHY]
    },
    [`status${addUnderscoreInWord(SENSITIVE)}`]: {
      color: theme.palette.status[SENSITIVE]
    },
    [`status${MODERATE}`]: {
      color: theme.palette.status[MODERATE]
    },
    [`status${GOOD}`]: {
      color: theme.palette.status[GOOD]
    },
    [`status${EXCELLENT}`]: {
      color: theme.palette.status[EXCELLENT]
    },
    [`status${addUnderscoreInWord(VERY_UNHEALTHY)}`]: {
      color: theme.palette.status[VERY_UNHEALTHY]
    },
    [`status${DEFAULT}`]: {
      color: theme.palette.status[DEFAULT]
    },
    [`background${HAZARDOUS}`]: {
      background: alpha(theme.palette.status[HAZARDOUS], 0.2),
      border: `1px solid ${alpha(theme.palette.status[HAZARDOUS], 0.5)}`
    },
    [`background${UNHEALTHY}`]: {
      background: alpha(theme.palette.status[UNHEALTHY], 0.1),
      border: `1px solid ${alpha(theme.palette.status[UNHEALTHY], 0.5)}`
    },
    [`background${addUnderscoreInWord(SENSITIVE)}`]: {
      background: alpha(theme.palette.status[SENSITIVE], 0.1),
      border: `1px solid ${alpha(theme.palette.status[SENSITIVE], 0.5)}`
    },
    [`background${MODERATE}`]: {
      background: alpha(theme.palette.status[MODERATE], 0.1),
      border: `1px solid ${alpha(theme.palette.status[MODERATE], 0.5)}`
    },
    [`background${GOOD}`]: {
      background: alpha(theme.palette.status[GOOD], 0.1),
      border: `1px solid ${alpha(theme.palette.status[GOOD], 0.5)}`
    },
    [`background${EXCELLENT}`]: {
      background: alpha(theme.palette.status[EXCELLENT], 0.1),
      border: `1px solid ${alpha(theme.palette.status[EXCELLENT], 0.5)}`
    },
    [`background${addUnderscoreInWord(VERY_UNHEALTHY)}`]: {
      background: alpha(theme.palette.status[VERY_UNHEALTHY], 0.1),
      border: `1px solid ${alpha(theme.palette.status[VERY_UNHEALTHY], 0.5)}`
    },
    [`background${DEFAULT}`]: {
      background: alpha(theme.palette.status[DEFAULT], 0.1),
      border: `1px solid ${alpha(theme.palette.status[DEFAULT], 0.5)}`
    },
    [`back${HAZARDOUS}`]: {
      backgroundColor: theme.palette.status[HAZARDOUS]
    },
    [`back${UNHEALTHY}`]: {
      backgroundColor: theme.palette.status[UNHEALTHY]
    },
    [`back${addUnderscoreInWord(SENSITIVE)}`]: {
      backgroundColor: theme.palette.status[SENSITIVE]
    },
    [`back${MODERATE}`]: {
      backgroundColor: theme.palette.status[MODERATE]
    },
    [`back${GOOD}`]: {
      backgroundColor: theme.palette.status[GOOD]
    },
    [`back${EXCELLENT}`]: {
      backgroundColor: theme.palette.status[EXCELLENT]
    },
    [`back${addUnderscoreInWord(VERY_UNHEALTHY)}`]: {
      backgroundColor: theme.palette.status[VERY_UNHEALTHY]
    },
    [`back${DEFAULT}`]: {
      backgroundColor: theme.palette.status[DEFAULT]
    },
    [`back${OUTDATED}`]: {
      backgroundColor: theme.palette.status[DEFAULT]
    }
  };
});
