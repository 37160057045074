import { apiService } from "../api";
import { UsersService } from "src/utils/client";
import { Page } from "src/sections/overview/view/types";

export const overviewService = apiService
  .enhanceEndpoints({ addTagTypes: ["OVERVIEW"] })
  .injectEndpoints({
    endpoints: build => ({
      getUserWidgetsWithPages: build.query<Page[], string>({
        queryFn: async userId => {
          //@ts-ignore
          const { content }: { content: Page[] } =
            await UsersService.getUserWidgets(userId);
          return { data: content };
        },
        providesTags: ["OVERVIEW"]
      }),
      updateUsersPages: build.mutation<
        Page[],
        { userId: string; updatedPages: Page[] }
      >({
        queryFn: async data => {
          const { userId, updatedPages } = data;
          const pages = await UsersService.updateUserWidgets(
            userId,
            updatedPages
          );
          return pages;
        },
        invalidatesTags: ["OVERVIEW"]
      })
    })
  });

export const { useGetUserWidgetsWithPagesQuery, useUpdateUsersPagesMutation } =
  overviewService;
