import { makeStyles } from "tss-react/mui";
// @ts-ignore
export const useStyles = makeStyles()(() => {
  return {
    filters: {
      display: "flex",
      alignItems: "start",
      marginBottom: 8,
      gap: 8
    },
    filterItem: {
      display: "flex",
      alignItems: "center",
      marginBottom: 10
    },
    button: {
      height: 40,
      boxSizing: "borderBox"
    },
    buttonOutline: {
      height: 38,
      marginLeft: "auto"
    }
  };
});

export const modalStyle = theme => {
  return {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: `2px solid ${theme.palette.primary.main}`,
    boxShadow: 24,
    p: 4,
    borderRadius: "5px"
  };
};
