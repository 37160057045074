import { Grid, Paper, Typography } from "@mui/material";
import classNames from "classnames";
import { useCallback, useMemo } from "react";
import { useAppSelector } from "../../../../../hooks/useAppSelector";
import useGetPolutantDictionary from "../../../../../hooks/useGetPollutantDictionary";
import LoaderComponent from "../../../../../shared/components/loader/loader.component";
import { getSelectedDeviceMetric } from "../../../../../store/hvac/selectors";
import { getMetricStatus } from "../../../../../utils/buildings.util";
import useStyles from "../view/device-details.style";

interface AverageValuesProps {
  loading: boolean;
  chartData: any;
  recommendation: any;
  updatedHistoryData: any;
  loadingRecommendation: boolean;
}

export default function AverageValues({
  loading,
  chartData,
  recommendation,
  updatedHistoryData,
  loadingRecommendation
}: AverageValuesProps) {
  const { classes } = useStyles();
  const { pollInfoDict } = useGetPolutantDictionary();

  const selectedMetric = useAppSelector(getSelectedDeviceMetric);

  const metricDefinition = selectedMetric ? pollInfoDict[selectedMetric] : null;

  const textRecommendation = useMemo(() => {
    if (loadingRecommendation || !Boolean(recommendation)) return "";
    if (selectedMetric === "aqi") {
      return recommendation.aqi;
    }

    if (selectedMetric === "voc_ppb") {
      return recommendation.voc;
    }
    return recommendation[selectedMetric];
  }, [selectedMetric, loadingRecommendation]);

  const getAverage = (key: string, area = "indoor") => {
    if (!updatedHistoryData) return 0;

    const historyMetric = updatedHistoryData[area].find(
      item => item.key === key
    );
    if (!historyMetric) return NaN;

    const result =
      (historyMetric.data as any)
        .map(item => item.value)
        .reduce((prev, next) => prev + next, 0) /
      (historyMetric.data as any)?.length;

    return result;
  };

  const average = useCallback(
    (isOutdoor = false) => {
      if (!selectedMetric || loading) return NaN;
      return isOutdoor
        ? getAverage(selectedMetric, "outdoor")
        : getAverage(selectedMetric);
    },
    [selectedMetric, loading]
  );

  const status = useCallback(
    (isOutdoor = false) => {
      if (loading) return "";
      return getMetricStatus(selectedMetric, average(isOutdoor), pollInfoDict);
    },
    [loading]
  );

  return (
    <div>
      <Grid
        container
        spacing={3}>
        {selectedMetric && !isNaN(average()) && (
          <>
            <Grid
              data-testid="container"
              item
              lg={status(true) ? 2 : 4}
              md={6}
              sm={status(true) ? 6 : 12}
              xs={status(true) ? 6 : 12}
              className={classes.averageContainer}>
              <Paper className={classes.average}>
                <Typography variant="body2">Indoor average</Typography>
                <Typography variant="overline">
                  {metricDefinition?.short_name}
                </Typography>
                <div className={classes.avg}>{Math.round(average())}</div>
                <Typography
                  data-testid="status"
                  variant="button"
                  className={classNames(
                    classes.avgStatus,
                    classes[`status${status()?.replace(/ /g, "")}`]
                  )}>
                  {status()}
                </Typography>
              </Paper>
            </Grid>
            {status(true) && !isNaN(average(true)) && (
              <Grid
                item
                lg={2}
                md={6}
                sm={6}
                xs={6}
                className={classes.averageContainer}>
                <Paper className={classes.average}>
                  <Typography variant="body2">Outdoor average</Typography>
                  <Typography
                    data-testid="metric"
                    variant="overline">
                    {metricDefinition?.short_name}
                  </Typography>
                  <div className={classes.avg}>{Math.round(average(true))}</div>
                  <Typography
                    variant="button"
                    className={classNames(
                      classes.avgStatus,
                      classes[`status${status(true).replace(/ /g, "")}`]
                    )}>
                    {status(true)}
                  </Typography>
                </Paper>
              </Grid>
            )}
          </>
        )}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={selectedMetric ? 8 : 12}>
          {Boolean(chartData?.length) && Boolean(textRecommendation) && (
            <Paper className={classes.card}>
              <Typography variant="h6">Our Recommendations</Typography>
              {loadingRecommendation && (
                <div
                  data-testid="loader"
                  className={classes.loadingRecommendation}>
                  <LoaderComponent />
                </div>
              )}
              <Typography
                data-testid="recommendation"
                dangerouslySetInnerHTML={{
                  __html: textRecommendation
                }}></Typography>
            </Paper>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
