import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import classNames from "classnames";
import moment from "moment";
import { DateRangePicker } from "mui-daterange-picker";
import { useMemo, useState } from "react";
import { useAppDispatch } from "../../../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../../../hooks/useAppSelector";
import useGetPolutantDictionary from "../../../../../hooks/useGetPollutantDictionary";
import LineChart from "../../../../../shared/components/line-chart/line-chart-component";
import {
  setDeviceGraphEndDate,
  setDeviceGraphGranuality,
  setDeviceGraphStartDate
} from "../../../../../store/hvac/reducer";
import {
  getDeviceGraphEndDate,
  getDeviceGraphGranuality,
  getDeviceGraphStartDate,
  getSelectedDeviceMetric
} from "../../../../../store/hvac/selectors";
import { DEFAULT_METRIC } from "../view/device-details.component";
import useStyles from "../view/device-details.style";
import { AggGranularity } from "src/utils/client";

interface AirQualityOverTimeProps {
  loading: boolean;
  chartData: any;
}

export default function AirQualityOverTime({
  loading,
  chartData
}: AirQualityOverTimeProps) {
  const theme = useTheme();
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const endDate = useAppSelector(getDeviceGraphEndDate);
  const startDate = useAppSelector(getDeviceGraphStartDate);
  const granularity = useAppSelector(getDeviceGraphGranuality);
  const selectedMetric = useAppSelector(getSelectedDeviceMetric);

  const { pollInfoDict } = useGetPolutantDictionary();

  const [dateRangeOpen, setDateRangeOpen] = useState<boolean>(false);

  const dictionaryExist = Object.keys(pollInfoDict)?.length;
  const metricDefinition = selectedMetric ? pollInfoDict[selectedMetric] : null;

  const newRanges = useMemo(() => {
    if (!dictionaryExist) return [];
    return pollInfoDict[
      selectedMetric ?? DEFAULT_METRIC
    ]?.statuses?.thresholds?.map(range => ({
      min: range.low,
      max: range.high,
      status: range.status,
      color: theme.palette.statusBg[range.status] || "#ffffff"
    }));
  }, [selectedMetric, dictionaryExist]);

  return (
    <Paper className={classes.chartContainer}>
      <div className={classes.titleG}>
        <Typography
          data-testid="metric-def"
          variant="h6">
          {selectedMetric ? metricDefinition?.full_name : "Air Quality Index"}{" "}
          Over Time
        </Typography>
        <div className={classes.dateFilters}>
          <div className={classes.datePicker}>
            <FormControl
              fullWidth
              style={{ marginTop: 8 }}>
              <InputLabel id="granularity-label">Granularity</InputLabel>
              <Select
                data-testid="granulatiry-select"
                labelId="granularity-label"
                value={granularity}
                label="Granularity"
                onChange={({ target: { value } }) => {
                  dispatch(
                    setDeviceGraphGranuality(
                      value as AggGranularity.DAY | AggGranularity.HOUR
                    )
                  );
                }}>
                <MenuItem value={"hour"}>Hours</MenuItem>
                <MenuItem value={"day"}>Days</MenuItem>
              </Select>
            </FormControl>
          </div>
          <TextField
            sx={{ ml: 2 }}
            label="Dates"
            value={`${moment(startDate).format("MMM D")} - ${moment(
              endDate
            ).format("MMM D")}`}
            onClick={() => setDateRangeOpen(true)}
          />
          <Box position={"absolute"}>
            <DateRangePicker
              data-testid="date-picker"
              onChange={event => {
                dispatch(setDeviceGraphStartDate(event.startDate));
                dispatch(setDeviceGraphEndDate(event.endDate));
                setDateRangeOpen(false);
              }}
              open={dateRangeOpen}
              wrapperClassName={classNames(classes.rangePicker, "notranslate")}
              toggle={() => setDateRangeOpen(!dateRangeOpen)}
            />
          </Box>
        </div>
      </div>
      {loading ? null : (
        <>
          {Boolean(chartData.length) && (
            <div>
              {!selectedMetric && (
                <Typography sx={{ marginLeft: 2 }}>
                  Pollutant Values Shown in AQI Scale
                </Typography>
              )}
              <div
                data-testid="chart"
                className={classes.canvasContainer}>
                <LineChart
                  data={chartData}
                  drawBgColors={true}
                  showFirstYAxisTitle={false}
                  maintainAspectRatio={false}
                  bgRanges={newRanges}
                />
              </div>
            </div>
          )}
          {!Boolean(chartData.length) && (
            <div
              data-testid="no-data"
              className={classes.card}>
              <Typography variant="h5">No device data</Typography>
            </div>
          )}
        </>
      )}
    </Paper>
  );
}
