import { useMemo, useState } from "react";
import LoaderComponent from "src/shared/components/loader/loader.component";
import ModalComponent from "src/shared/components/modal/modal.component";
import { makeStyles } from "tss-react/mui";
import { NA } from "src/shared/constants/generals";
import { Form, Field } from "react-final-form";
import { StandaloneSearchBox } from "@react-google-maps/api";
import {
  Button,
  TextField,
  Typography,
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel
} from "@mui/material";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import {
  getBuildingModalState,
  getIsBuildingEdit
} from "src/store/buildings/selector";
import {
  handleBuildingModal,
  setBuildingEditing
} from "src/store/buildings/reducer";
import {
  useCreateBuildingMutation,
  useUpdateBuildingMutation
} from "src/store/services/buildings/buildings.service";
import useGetBuildings from "src/hooks/useGetBuildings";
import { getUserId } from "src/store/auth/selectors";
import { UsersService } from "src/utils/client";
import { removeDuplicatesById } from "src/sections/user-administration-portal/removeDuplicatesById";
import { useParams } from "react-router-dom";

type FormBuilding = {
  address: string;
  city: string;
  companyId: string;
  country: string;
  id: string;
  lat: number;
  long: number;
  name: string;
};

export type BuildingForUpdate = {
  companyId: string;
  id: string;
  name: string;
  location: {
    address: string;
    city: string;
    country: string;
    latitude: number;
    longitude: number;
  };
};
const BuildingsModalComponent = () => {
  const dispatch = useAppDispatch();
  const { buildingId } = useParams();
  const { classes } = useStyles();
  const { buildings, refetch: refetchBuildings } = useGetBuildings();
  const modalOpen = useAppSelector(getBuildingModalState);
  const building = buildings?.find(({ id }) => id === buildingId);
  const isEdit = useAppSelector(getIsBuildingEdit);
  const currentUserId = useAppSelector(getUserId);
  const [companies, setCompanies] = useState(null);
  const [createBuilding, { isLoading: isCreatingBuilding }] =
    useCreateBuildingMutation();
  const [updateBuilding, { isLoading: isUpdatingBuilding }] =
    useUpdateBuildingMutation();
  const loading = isUpdatingBuilding || isCreatingBuilding;

  const isEditing = Boolean(building) && isEdit;

  const closeBuildingModal = () => {
    dispatch(handleBuildingModal(false));
    dispatch(setBuildingEditing(false));
  };

  useMemo(() => {
    const fetchUserMemberships = async () => {
      try {
        const { companies } = await UsersService.getUserMemberships(
          currentUserId
        );
        setCompanies(removeDuplicatesById(companies));
      } catch (error) {
        console.error("Failed to fetch user company memberships:", error);
      }
    };
    if (!isEditing) {
      fetchUserMemberships();
    }
  }, [currentUserId]);

  const submit = (building: FormBuilding) => {
    const formattedBuilding = {
      companyId: building.companyId,
      id: buildingId,
      name: building.name,
      location: {
        address: building.address,
        city: building.city,
        country: building.country,
        latitude: Number(building.lat),
        longitude: Number(building.long)
      }
    };
    if (Boolean(buildingId)) {
      updateBuilding(formattedBuilding);
    } else {
      createBuilding(formattedBuilding);
      refetchBuildings();
    }
  };

  const validate = values => {
    const errors: any = {};
    const { name, address, customerName } = values;
    if (buildings.length) {
      if (!name) {
        errors.name = "Building name is required";
      }
      if (!address) {
        errors.address = "Address is required";
      }
    }
    if (!buildings.length) {
      if (!customerName) {
        errors.customerName = "Company Name is required";
      }
    }

    return errors;
  };

  return (
    <ModalComponent
      open={modalOpen}
      onClose={closeBuildingModal}
      data-testid="buildings-modal">
      <Form
        initialValues={{
          address: isEditing ? building.location?.address : null,
          city: isEditing ? building.location?.city : null,
          country: isEditing ? building.location?.country : null,
          lat: isEditing ? building.location?.latitude : null,
          long: isEditing ? building.location?.longitude : null,
          name: isEditing ? building.name : null,
          id: isEditing ? buildingId : null,
          // TODO Fix issue with interface
          // @ts-ignore
          companyId: building ? building.companyId : null
        }}
        onSubmit={submit}
        validate={validate}
        render={({ handleSubmit, hasValidationErrors, form }) => {
          return (
            <form
              className={classes.root}
              onSubmit={handleSubmit}
              data-testid="form">
              {loading && <LoaderComponent />}
              {!Boolean(buildings.length) && (
                <>
                  <Typography
                    variant="h6"
                    gutterBottom>
                    Add Company
                  </Typography>
                  <Typography>
                    Please create an Company to add a <br /> building
                  </Typography>
                  <div>
                    <Field name="customerName">
                      {({ input, meta }) => (
                        <TextField
                          label="Company Name*"
                          className={classes.textInput}
                          error={Boolean(meta.touched && meta.error)}
                          helperText={meta.touched && meta.error}
                          InputProps={input}
                        />
                      )}
                    </Field>
                  </div>
                </>
              )}
              {Boolean(buildings.length) && (
                <>
                  {isEditing && (
                    <Box
                      mb={2}
                      className={classes.buildingInfo}>
                      <Typography variant="h6">{building.name}</Typography>
                      <Typography variant="subtitle1">
                        {building.location?.address || NA}
                      </Typography>
                    </Box>
                  )}
                  <Typography
                    variant="h6"
                    gutterBottom>
                    {isEditing ? "Edit" : "Add"} Building
                  </Typography>
                  {!isEditing && (
                    <div className={classes.customerSelect}>
                      <Field name="companyId">
                        {({ input, meta }) => (
                          <FormControl fullWidth>
                            <InputLabel id="custonmeIdLabel">
                              Select Company
                            </InputLabel>
                            <Select
                              label="Select Company"
                              className={classes.textInput}
                              error={Boolean(meta.touched && meta.error)}
                              inputProps={input}>
                              {companies.map((option, index) => (
                                <MenuItem
                                  key={`s-c-${index}`}
                                  value={option.id}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      </Field>
                    </div>
                  )}
                  <div>
                    <Field name="name">
                      {({ input, meta }) => (
                        <TextField
                          label="Building name*"
                          className={classes.textInput}
                          error={Boolean(meta.touched && meta.error)}
                          helperText={meta.touched && meta.error}
                          InputProps={input}
                        />
                      )}
                    </Field>
                  </div>
                  <Typography
                    variant="body1"
                    gutterBottom
                    mt={1}>
                    Location
                  </Typography>
                  <StandaloneSearchBox
                    onPlacesChanged={function () {
                      const places = this.getPlaces();
                      // TODO Refactor after redux
                      let address;
                      let city;
                      let country;
                      let lat;
                      let long;

                      if (places.length) {
                        let place = places[0];
                        let location = place.geometry.location.toJSON();
                        address = place.formatted_address;

                        city = place.address_components.find(
                          item =>
                            item.types.includes("political") &&
                            (item.types.includes("locality") ||
                              item.types.includes(
                                "administrative_area_level_1"
                              ))
                        ).long_name;

                        country = place.address_components.find(
                          item =>
                            item.types.includes("political") &&
                            item.types.includes("country")
                        ).long_name;

                        lat = location.lat;
                        long = location.lng;

                        form.batch(() => {
                          form.change("address", address);
                          form.change("city", city);
                          form.change("country", country);
                          form.change("lat", lat.toFixed(5));
                          form.change("long", long.toFixed(5));
                          form.change("searchBox", place.name);
                        });
                      } else {
                        form.batch(() => {
                          form.change("searchBox", null);
                          form.change("address", null);
                          form.change("city", null);
                          form.change("country", null);
                          form.change("lat", null);
                          form.change("long", null);
                        });
                      }
                    }}>
                    <Field name="searchBox">
                      {({ input, meta }) => (
                        <TextField
                          placeholder={null}
                          label="Address*"
                          className={classes.textInput}
                          error={
                            Boolean(meta.touched) &&
                            !form.getState().values.address
                          }
                          helperText={
                            meta.touched &&
                            !form.getState().values.address &&
                            "Invalid address"
                          }
                          InputProps={input}></TextField>
                      )}
                    </Field>
                  </StandaloneSearchBox>
                  {Boolean(form.getState().values.address) && (
                    <div className={classes.address}>
                      <Typography>{form.getState().values.address}</Typography>
                      <Typography
                        variant="body2"
                        mt={1}>
                        Latitude: {form.getState().values.lat}
                      </Typography>
                      <Typography variant="body2">
                        Longitude: {form.getState().values.long}
                      </Typography>
                      <Button
                        type="button"
                        color="error"
                        className={classes.deleteAddress}
                        onClick={() =>
                          form.batch(() => {
                            form.change("searchBox", null);
                            form.change("address", null);
                            form.change("city", null);
                            form.change("country", null);
                            form.change("lat", null);
                            form.change("long", null);
                          })
                        }>
                        Delete
                      </Button>
                    </div>
                  )}
                </>
              )}
              <div className={classes.actions}>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.saveBtn}
                  disabled={hasValidationErrors || loading}
                  data-testid="save-button">
                  Save
                </Button>
                <Button
                  type="button"
                  variant="text"
                  color="inherit"
                  onClick={closeBuildingModal}>
                  Cancel
                </Button>
              </div>
            </form>
          );
        }}
      />
    </ModalComponent>
  );
};

export const useStyles = makeStyles()(theme => {
  return {
    root: {
      minWidth: 250
    },
    saveBtn: {
      width: 120,
      marginRight: "1em"
    },
    actions: {
      marginTop: "1em"
    },
    textInput: {
      width: "100%"
    },
    customerSelect: {
      marginBottom: "0.5em"
    },
    buildingInfo: {
      width: "100%"
    },
    address: {
      position: "relative" as const,
      width: "100%",
      padding: "0.75em",
      border: `1.5px solid ${theme.palette.grey["400"]}`,
      borderRadius: 4,
      "& p": {
        color: theme.palette.grey["500"],
        fontWeight: 600
      }
    },
    deleteAddress: {
      position: "absolute" as const,
      bottom: 0,
      right: 0
    },
    loader: {
      top: 0,
      left: 0
    }
  };
});

export default BuildingsModalComponent;
