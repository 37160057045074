import { useGetSettingsOptionsQuery } from "src/store/services/settings/settings.service";

export const useGetGeneralSettingsOptions = () => {
  const {
    data: settings,
    isLoading,
    isFetching,
    isError
  } = useGetSettingsOptionsQuery();
  const { languages, detailOptions } = settings ?? {};

  return {
    isLoading,
    isError,
    isFetching,
    optionsExist: Boolean(settings),
    languages,
    aqiStandards: detailOptions?.aqi_scale,
    pressures: detailOptions?.pressure,
    temperatures: detailOptions?.temperature
  };
};
