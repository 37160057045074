import LoaderComponent from "src/shared/components/loader/loader.component";
import CustomTextField from "src/shared/components/custom-input/custom-input.component";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import toast from "react-hot-toast";
import { Box, Typography, Button, InputAdornment } from "@mui/material";
import { useState } from "react";
import { Form, Field } from "react-final-form";
import { NewPassword } from "src/shared/types/login.type";
import { AccountsService } from "src/utils/client";
import { CompanyInvitationsService } from "src/utils/client";
import { makeStyles } from "tss-react/mui";
import { useNavigate } from "react-router-dom";

type Props = {
  email: string;
  token: string;
  createAccount?: boolean;
  invitation?: boolean;
};

const SetNewPasswordComponent = (props: Props) => {
  const { email, token, createAccount, invitation } = props;
  const navigate = useNavigate();
  const { classes } = useStyles();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const submit = (values: NewPassword) => {
    const { password, name } = values;
    setLoading(true);

    if (createAccount) {
      AccountsService.createNewUserAccount({
        email,
        token,
        new_password: password,
        name
      })
        .then(() => {
          setLoading(false);
          toast.success("Account created");
          navigate(`/login`);
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (invitation) {
      CompanyInvitationsService.acceptInvitation({
        name: name,
        new_password: password,
        session_token: token
      })
        .then(() => {
          setLoading(false);
          toast.success("Invitation accepted");
          navigate(`/login`);
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      AccountsService.resetPassword({ email, token, new_password: password })
        .then(() => {
          setLoading(false);
          toast.success("Password changed");
          navigate(`/login`);
        })
        .catch(error => {
          console.log(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const validate = (values: NewPassword) => {
    let errors: NewPassword = {};
    const { password, confirmation } = values;
    if (!password) {
      errors.password = "Password is required";
    }
    if (!confirmation) {
      errors.confirmation = "Confirmation is required";
    }
    if (password && password.length < 12) {
      errors.password = "Password must have at least 12 characters ";
    }
    if (confirmation !== password) {
      errors.confirmation = "Password and confirmation not matching";
    }
    if (createAccount || invitation) {
      const { name } = values;
      if (!name) {
        errors.name = "Name is required";
      }
    }
    return errors;
  };

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      render={({ handleSubmit, hasValidationErrors }) => (
        <form
          data-testid="new-password"
          className={classes.passwordForm}
          onSubmit={handleSubmit}>
          {loading && <LoaderComponent />}
          <Box mb={5}>
            <Typography variant="h4">Set Your Password</Typography>
            <Typography variant="subtitle1">
              Manage air quality effectively
            </Typography>
          </Box>
          <div className={classes.fieldContainer}>
            <Field name="password">
              {({ input, meta }) => (
                <CustomTextField
                  label="Password"
                  error={Boolean(meta.touched && meta.error)}
                  helperText={meta.touched && meta.error}
                  InputProps={{
                    ...input,
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}>
                        {showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </InputAdornment>
                    )
                  }}
                  type={showPassword ? "text" : "password"}
                />
              )}
            </Field>
          </div>
          <div className={classes.fieldContainer}>
            <Field name="confirmation">
              {({ input, meta }) => (
                <CustomTextField
                  label="Confirmation"
                  error={Boolean(meta.touched && meta.error)}
                  helperText={meta.touched && meta.error}
                  InputProps={{
                    ...input,
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() => {
                          setShowConfirmation(!showConfirmation);
                        }}>
                        {showConfirmation ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </InputAdornment>
                    )
                  }}
                  type={showConfirmation ? "text" : "password"}
                />
              )}
            </Field>
          </div>
          {(invitation || createAccount) && (
            <div className={classes.fieldContainer}>
              <Field name="name">
                {({ input, meta }) => (
                  <CustomTextField
                    label="Your name"
                    error={Boolean(meta.touched && meta.error)}
                    helperText={meta.touched && meta.error}
                    InputProps={{
                      ...input
                    }}
                    type="text"
                  />
                )}
              </Field>
            </div>
          )}

          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={hasValidationErrors || loading}
            className={classes.setBtn}>
            Send
            <ArrowForwardRoundedIcon sx={{ marginLeft: 1, fontSize: 16 }} />
          </Button>
        </form>
      )}
    />
  );
};

export const useStyles = makeStyles()(() => ({
  passwordForm: {
    height: 450,
    display: "flex",
    flexDirection: "column" as const,
    alignSelf: "center",
    justifyContent: "space-between",
    position: "relative" as const,
    marginTop: "1em"
  },
  fieldContainer: {
    height: 64,
    display: "grid"
  },
  setBtn: {
    marginTop: "1em"
  }
}));

export default SetNewPasswordComponent;
