import { makeStyles } from "tss-react/mui";
import { useState } from "react";
import moment from "moment";
import ModalComponent from "src/shared/components/modal/modal.component";
import LoaderComponent from "src/shared/components/loader/loader.component";
import { DatePicker } from "@mui/x-date-pickers";
import { renderWeekPickerDay } from "src/utils/date-picker.utils";
import { Building, Ahu, Kettle, PaintBooth } from "src/utils/client";
import { Typography, Grid, TextField, Button } from "@mui/material";
import { APIv2 } from "src/utils/apiv2";
import useGetBuildings from "src/hooks/useGetBuildings";
import { useParams } from "react-router-dom";

type Props = {
  building?: Building;
  entityType: "ahu" | "kettle" | "paint_booth";
  entity: Ahu | Kettle | PaintBooth;
  downloadModalOpen?: boolean;
  setDownloadModalOpen?: Function;
};

const ActivityDownloadModal = ({
  entityType,
  entity,
  downloadModalOpen,
  setDownloadModalOpen
}: Props) => {
  const { buildingId } = useParams();
  const { classes } = useStyles();
  const [startDate, setStartDate] = useState(moment().subtract(6, "month"));
  const [endDate, setEndDate] = useState(moment());
  const [loading, setLoading] = useState(false);
  const { building } = useGetBuildings(buildingId);
  const closeModal = () => {
    setStartDate(moment().subtract(6, "month"));
    setEndDate(moment());
    setDownloadModalOpen(false);
  };

  function getAuditLogDownload(
    buildingId: string,
    entityType: "ahu" | "kettle" | "paint_booth",
    entity: Ahu | Kettle,
    startDate,
    endDate
  ) {
    const params: any = {
      building_id: buildingId,
      start_ts: startDate,
      end_ts: endDate
    };
    if (entityType === "ahu") {
      params.ahu_id = entity.id;
    } else if (entityType === "kettle") {
      params.kettle_id = entity.id;
    } else {
      params.booth_id = entity.id;
    }
    return APIv2.get(`/events/audit-log/download/`, {
      params,
      responseType: "blob"
    });
  }

  const getData = async () => {
    setLoading(true);
    const response = await getAuditLogDownload(
      building.id,
      entityType,
      entity,
      moment(startDate).format(),
      moment(endDate).format()
    );
    try {
      const filename = `${entity.name} Event Log.xlsx`;
      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");

      link.href = href;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalComponent
      open={downloadModalOpen}
      onClose={closeModal}>
      <div data-testid="download-service-logs-modal">
        {loading && <LoaderComponent />}
        <div>
          <Typography
            variant="h6"
            gutterBottom>
            Export Data
          </Typography>
        </div>
        <div>
          <Grid
            container
            columnSpacing={1}
            sx={{ paddingTop: 2 }}>
            <Grid
              item
              xs={12}
              sm={6}>
              <DatePicker
                onChange={(value: Date) => {
                  setStartDate(moment(value));
                }}
                value={startDate}
                label="Start Date"
                renderDay={renderWeekPickerDay}
                renderInput={params => <TextField {...params}></TextField>}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}>
              <DatePicker
                onChange={(value: Date) => {
                  setEndDate(moment(value));
                }}
                value={endDate}
                label="End Date"
                renderDay={renderWeekPickerDay}
                renderInput={params => <TextField {...params}></TextField>}
              />
            </Grid>
          </Grid>
        </div>
        {building && (
          <div className={classes.actions}>
            <Button
              variant="contained"
              disabled={!endDate || !startDate || loading}
              onClick={getData}>
              Download
            </Button>
            <Button
              type="button"
              variant="text"
              color="inherit"
              onClick={closeModal}>
              Cancel
            </Button>
          </div>
        )}
      </div>
    </ModalComponent>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    actions: {
      marginTop: "1em",
      display: "flex"
    }
  };
});

export default ActivityDownloadModal;
