/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum StageType {
  PRE_FILTER = 'pre_filter',
  SECONDARY_FILTER = 'secondary_filter',
  FINAL_FILTER = 'final_filter',
  HEPA_FILTER = 'hepa_filter',
  COIL = 'coil',
  OTHER = 'other',
  AIRFLOW = 'airflow',
}
