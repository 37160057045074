import { Button, Stack } from "@mui/material";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { setIsAddingOrRemoving, setModalState } from "src/store/booth/reducer";

const SetPoints = () => {
  const dispatch = useAppDispatch();

  const handleOnAdd = () => {
    dispatch(setModalState(true));
    dispatch(setIsAddingOrRemoving("adding"));
  };

  const handleOnRemove = () => {
    dispatch(setModalState(true));
    dispatch(setIsAddingOrRemoving("removing"));
  };

  return (
    <Stack
      justifyContent={"space-between"}
      flexDirection={"row"}
      alignItems={"center"}>
      <Button
        data-testid="add-set-point"
        onClick={handleOnAdd}
        sx={{ mr: 2 }}>
        Add Set Point
      </Button>
      <Button
        data-testid="remove-set-point"
        onClick={handleOnRemove}>
        Remove Set Point
      </Button>
    </Stack>
  );
};

export default SetPoints;
