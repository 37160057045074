import LoaderComponent from "src/shared/components/loader/loader.component";
import AlertRowComponent from "src/sections/notifications/alert-row/alert-row.component";
import { useDispatch } from "react-redux";
import { makeStyles } from "tss-react/mui";

import {
  Button,
  List,
  ListItem,
  ListItemButton,
  Typography
} from "@mui/material";

import { useGetAlertsQuery } from "src/store/services/notifications/notificationsService";
import {
  setAlertsDrawerOpen,
  setSelectedNotificationId
} from "src/store/notification/reducer";

const AlertsWidgetComponent = () => {
  const dispatch = useDispatch();

  const { classes } = useStyles();
  const {
    data: alerts,
    isLoading,
    isFetching
  } = useGetAlertsQuery(
    { notificationId: null },
    { pollingInterval: 60 * 1000 }
  );

  const openDrawer = () => {
    dispatch(setAlertsDrawerOpen(true));
    dispatch(setSelectedNotificationId(null));
  };

  return (
    <div
      data-testid="alerts-widget"
      className={classes.root}>
      {(isLoading || isFetching) && <LoaderComponent />}
      <div className={classes.title}>
        <Typography variant="h6">All Recent Alerts</Typography>
        <Button
          variant="text"
          className={classes.viewAllBtn}
          onClick={() => openDrawer()}>
          View All
        </Button>
      </div>
      <div className={classes.widgetBody}>
        <List>
          {alerts?.map((alert, index) => {
            return (
              <ListItem
                data-testId="alert-item"
                sx={{ width: "100%" }}
                key={`alert_row_${alert?.notification?.id}_${index}`}
                disablePadding>
                <ListItemButton sx={{ padding: 0 }}>
                  <AlertRowComponent
                    forWidget
                    alert={alert}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
        {!alerts?.length && (
          <div
            style={{ textAlign: "center" }}
            data-testId="no-alerts">
            There are no recent alerts
          </div>
        )}
      </div>
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: "100%",
      padding: "10px"
    },
    title: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    widgetBody: {
      flex: 1,
      overflow: "auto"
    },
    loader: {
      position: "absolute",
      top: 0,
      left: 0
    },
    alertContent: {
      width: "auto",
      flex: 1
    },
    viewAllBtn: {
      height: 25
    }
  };
});

export default AlertsWidgetComponent;
