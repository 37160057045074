import { alpha } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(theme => {
  return {
    root: {
      display: "flex",
      paddingTop: 8,
      paddingBottom: 8,
      position: "relative",
      width: "95%",
      boxShadow: "0px 5px 10px rgba(0,0,0,0.2)",
      marginBottom: "20px",
      marginLeft: "5px",
      border: "1px solid rgba(0,0,0,0.2)",
      borderRadius: "5px",
      "&:after": {
        content: '""',
        position: "absolute",
        bottom: 0,
        width: "90%",
        left: 16,
        height: 1,
        backgroundColor: "#D3D3D3"
      }
    },
    ackButton: {
      height: 15,
      color: "#444"
    },
    ackButtonDiv: {
      display: "flex",
      paddingLeft: 100
    },
    alertTypeContainer: {
      width: 50,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column"
    },
    alertContent: {
      flex: 1,
      paddingLeft: 10,
      paddingRight: 10,
      overflow: "hidden"
    },
    alertIcons: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "5px"
    },
    highPriority: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: "4px 8px",
      gap: "4px",
      background: "#FFFFFF",
      border: "1px solid #C13838",
      color: "#C13838",
      borderRadius: 4,
      fontSize: "10px",
      justifyContent: "center",
      marginTop: 4,
      marginBottom: 4
    },
    highPriorityColor: {
      background: alpha("#F44336", 0.15)
    },
    flagIcon: {
      fontSize: "12px"
    },
    subtitle: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      width: "100%",
      fontSize: "14px",
      display: "flex",
      flexDirection: "column"
    },
    time: {
      fontSize: "12px",
      color: "#5A5B5D",
      lineHeight: "24px"
    },
    seen: {
      background: "white"
    },
    unseen: {
      background: "#F8F9FF"
    },
    options: {
      width: 50,
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    alertTypeIcon: {
      color: theme.palette.primary[500] || theme.palette.primary["main"]
    },
    numbers: {
      marginTop: 4,
      marginBottom: 10,
      padding: 8,
      backgroundColor: "rgba(0, 0, 0, 0.04)",
      display: "flex"
    },
    numberQty: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center"
    },
    qtyLabel: {
      textAlign: "center",
      fontSize: "8pt !important"
    },
    qty: {
      fontSize: "25pt",
      fontWeight: "450"
    },
    displayRow: {
      fontWeight: "bold",
      marginRight: "5px"
    },
    acknowledge: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "10px",
      border: "1px dotted black",
      fontWeight: "bold",
      fontSize: "12px"
    }
  };
});
