import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => {
  return {
    alert: {
      display: "flex",
      margin: "0.5rem 0rem 0.5rem 1rem",
      borderTop: "2px solid rgba(0, 0, 0, 0.1)",
      "&:first-of-type": {
        borderTop: 0
      }
    },
    exclamationItem: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: "-1rem"
    },
    exclamation: {
      color: "#fff",
      padding: "2px",
      background: "rgb(232, 37, 71)",
      borderRadius: "1px",
      boxShadow: "0.2rem 0.1rem 0.1rem rgba(0, 0, 0, 0.2)"
    },
    check: {
      color: "#fff",
      padding: "2px",
      background: "#4CAF50",
      borderRadius: "1px",
      boxShadow: "0.2rem 0.1rem 0.1rem rgba(0, 0, 0, 0.2)"
    },
    item: {
      display: "flex",
      alignItems: "center",
      padding: "2px"
    },
    inAlertTitle: {
      fontWeight: 500,
      color: "rgb(232, 37, 71)"
    },
    outOfAlertTitle: {
      fontWeight: 500,
      color: "green"
    },
    stage: {
      fontWeight: 500
    },
    dateItem: {
      display: "flex",
      alignItems: "end",
      justifyContent: "center",
      flexDirection: "column",
      padding: "2px"
    },
    date: {
      paddingRight: "0.5rem",
      color: "rgba(0, 0, 0, 0.5)"
    },
    margin: {
      margin: "0.5rem 0rem"
    },
    alertInfo: {
      margin: "0rem 0.5rem"
    },
    stageItem: {
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
      padding: "2px"
    },
    iconItem: {
      display: "flex",
      alignItems: "center",
      padding: "2px",
      marginLeft: "0.5rem"
    }
  };
});
