import {
  Box,
  Grid,
  Stack,
  TextField,
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import LineChart from "../../shared/components/line-chart/line-chart-component";
import {
  setKettleChartGranularity,
  setKettleEndDate,
  setKettleStartDate
} from "../../store/kettle/reducer";
import {
  getKettleChartGranularity,
  getKettleEndDate,
  getKettleStartDate
} from "../../store/kettle/selector";
import { renderWeekPickerDay } from "../../utils/date-picker.utils";
import { AggGranularity } from "src/utils/client";

interface KettleChartProps {
  chartData: any;
  chartDataExist: boolean;
}

export default function KettleChart({
  chartData,
  chartDataExist
}: KettleChartProps) {
  const dispatch = useAppDispatch();
  const endDate = useAppSelector(getKettleEndDate);
  const startDate = useAppSelector(getKettleStartDate);
  const granularity = useAppSelector(getKettleChartGranularity);

  return (
    <>
      <Grid
        container
        style={{ flexDirection: "row-reverse" }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={"auto"}>
          <Stack
            direction={{ md: "row", sm: "column" }}
            width={{ sm: 1 }}>
            <FormControl
              fullWidth
              style={{ marginTop: 8, width: 130, marginRight: 20 }}>
              <InputLabel id="granularity-label">Granularity</InputLabel>
              <Select
                data-testid="granulatiry-select"
                labelId="granularity-label"
                value={granularity ?? AggGranularity.DAY}
                label="Granularity"
                onChange={({ target: { value } }) => {
                  dispatch(
                    setKettleChartGranularity(
                      value as AggGranularity.DAY | AggGranularity.HOUR
                    )
                  );
                }}>
                <MenuItem value={"hour"}>Hours</MenuItem>
                <MenuItem value={"day"}>Days</MenuItem>
              </Select>
            </FormControl>
            <Box
              width={"160px"}
              mr={"1.2rem"}>
              <DatePicker
                label="From date"
                value={startDate}
                renderDay={renderWeekPickerDay}
                onChange={newValue => {
                  dispatch(setKettleStartDate(newValue.toString()));
                }}
                renderInput={params => <TextField {...params} />}
              />
            </Box>
            <Box
              width={"160px"}
              mr={"1.2rem"}>
              <DatePicker
                label="To date"
                value={endDate}
                renderDay={renderWeekPickerDay}
                onChange={newValue => {
                  dispatch(setKettleEndDate(newValue.toString()));
                }}
                renderInput={params => <TextField {...params} />}
              />
            </Box>
          </Stack>
        </Grid>
      </Grid>
      {chartDataExist ? (
        <Box
          position={"relative"}
          data-testid="chart"
          height={"400px"}
          mb={"20px"}>
          <LineChart
            data={chartData}
            maintainAspectRatio={false}
            isKettle={true}
          />
        </Box>
      ) : (
        <Stack sx={styles.empty}>
          <>
            <Typography
              gutterBottom
              variant="subtitle1">
              NO DEVICE DATA AVAILABLE
            </Typography>
            <Typography variant="body2">
              Please check that your device has its connection
            </Typography>
          </>
        </Stack>
      )}
    </>
  );
}

const styles = {
  empty: {
    py: 5,
    my: 3,
    bgcolor: "#F3F6FF",
    textAlign: "center",
    borderRadius: "8px",
    boxShadow:
      "0px 11px 15px rgba(47, 74, 170, 0.05), 0px 9px 46px rgba(47, 74, 170, 0.03), 0px 24px 38px rgba(47, 74, 170, 0.04);"
  }
};
