/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AggGranularity } from '../models/AggGranularity';
import type { AhuDetails } from '../models/AhuDetails';
import type { BuildingEntityInfo } from '../models/BuildingEntityInfo';
import type { BuildingSummary } from '../models/BuildingSummary';
import type { HvacExportRequestBody } from '../models/HvacExportRequestBody';
import type { InputTco } from '../models/InputTco';
import type { LegacyDeviceDataResp } from '../models/LegacyDeviceDataResp';
import type { NotificationDeviceParentsResp } from '../models/NotificationDeviceParentsResp';
import type { NotificationDevicesResp } from '../models/NotificationDevicesResp';
import type { OutputTco } from '../models/OutputTco';
import type { PaintBoothAndZones } from '../models/PaintBoothAndZones';
import type { StageAndFilterload } from '../models/StageAndFilterload';
import type { TimeRange } from '../models/TimeRange';
import type { TradefairDashboardData } from '../models/TradefairDashboardData';
import type { WebReportData } from '../models/WebReportData';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class WebPortalService {
  /**
   * Get Ahus With Filterload
   * Get the stages for a particular AHU along with filter load data.
   * Returns an object keyed by stage_id's.
   *
   * Essentially a replacement of:
   * GET https://dev.qd.i-qlair.com/v2/hvac/statuses?facilityId=123
   * from old backend.
   * @param buildingId
   * @returns AhuDetails Successful Response
   * @throws ApiError
   */
  public static getAhusWithFilterload(
    buildingId: string,
  ): CancelablePromise<Record<string, AhuDetails>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/ahus/details/',
      path: {
        'building_id': buildingId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Ahu Stage Data
   * Returns pressure drop data for given time range for all stages of the AHU.
   * `start_ts` and `end_ts` can be given as Unix timestamps integers
   * or floats in seconds or milliseconds, or as isoformat strings.
   * ```
   * Examples:
   * 1677827157                       (seconds)
   * 1677827157.5451648               (seconds)
   * 1677827157545                    (milliseconds)
   * 2023-03-03T07:05:57.545165+00:00 (isoformat)
   * 2023-03-03T02:05:57.545165+05:00 (isoformat)
   * ```
   * All of the above generate the same timestamp in the backend
   * (except where minor place values were omitted).
   * @param buildingId
   * @param ahuId
   * @param startTs
   * @param endTs
   * @param granularity
   * @returns any Successful Response
   * @throws ApiError
   */
  public static ahuStageData(
    buildingId: string,
    ahuId: string,
    startTs: string,
    endTs?: (string | null),
    granularity?: AggGranularity,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/hvac-data/ahus/{ahu_id}/stage-data/',
      path: {
        'building_id': buildingId,
        'ahu_id': ahuId,
      },
      query: {
        'start_ts': startTs,
        'end_ts': endTs,
        'granularity': granularity,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Paint Booths With Zones
   * Get the zones for a particular paint booth.
   * Returns an object keyed by `zone_id`'s.
   * @param buildingId
   * @returns PaintBoothAndZones Successful Response
   * @throws ApiError
   */
  public static getPaintBoothsWithZones(
    buildingId: string,
  ): CancelablePromise<Record<string, PaintBoothAndZones>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/paint-booths/details/',
      path: {
        'building_id': buildingId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Notification Types
   * Helper endpoint for web portal to get the Alert types available to make a notification definition for.
   * Return date is static, unless the underlying AlertDeviceType model changes.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static notificationTypes(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/notifications/types/',
    });
  }
  /**
   * Notification Devices Parents
   * Helper endpoint for web portal to get lists of parent entities inside buildings for notification devices available to make a notification definition for,
   * i.e. AHUs for hvac sensors, kettles for kettle sensors, rooms for iaq devices.
   * @returns NotificationDeviceParentsResp Successful Response
   * @throws ApiError
   */
  public static notificationDevicesParents(): CancelablePromise<NotificationDeviceParentsResp> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/notifications/entities/',
    });
  }
  /**
   * Notification Devices
   * Helper endpoint for web portal to get lists of aq, hvac, and kettle devices available to make a notification definition for.
   * @returns NotificationDevicesResp Successful Response
   * @throws ApiError
   */
  public static notificationDevices(): CancelablePromise<NotificationDevicesResp> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/notifications/devices/',
    });
  }
  /**
   * Make Pdf Report
   * Generates a single page pdf iaq report for a building.
   * If no `time_range` is provided, defaults to the last two weeks.
   *
   * `time_range.start` and `time_range.end` can be given as Unix timestamps integers
   * or floats in seconds or milliseconds, or as isoformat strings.
   * ```
   * Examples:
   * 1677827157                       (seconds)
   * 1677827157.5451648               (seconds)
   * 1677827157545                    (milliseconds)
   * 2023-03-03T07:05:57.545165+00:00 (isoformat)
   * 2023-03-03T02:05:57.545165+05:00 (isoformat)
   * ```
   * All of the above generate the same timestamp in the backend
   * (except where minor place values were omitted).
   * @param companyId
   * @param buildingId
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static makePdfReport(
    companyId: string,
    buildingId: string,
    requestBody?: (TimeRange | null),
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/reports/pdf/',
      query: {
        'company_id': companyId,
        'building_id': buildingId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `A report could not be generated`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Web Report Data
   * Main endpoint for delivering the data the web report uses.
   * If no `building_id` is provided, a company-level report is produced (all buildings for the company).
   * If a `building_id` is provided, a building-level report is made.
   * If no `time_range` is provided, defaults to the last two weeks.
   *
   * `time_range.start` and `time_range.end` can be given as Unix timestamps integers
   * or floats in seconds or milliseconds, or as isoformat strings.
   * ```
   * Examples:
   * 1677827157                       (seconds)
   * 1677827157.5451648               (seconds)
   * 1677827157545                    (milliseconds)
   * 2023-03-03T07:05:57.545165+00:00 (isoformat)
   * 2023-03-03T02:05:57.545165+05:00 (isoformat)
   * ```
   * All of the above generate the same timestamp in the backend
   * (except where minor place values were omitted).
   * @param companyId
   * @param buildingId
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getWebReportData(
    companyId: string,
    buildingId: string,
    requestBody?: (TimeRange | null),
  ): CancelablePromise<(WebReportData | null)> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/reports/web/',
      query: {
        'company_id': companyId,
        'building_id': buildingId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `At least on of building_id or company_id must be provided`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Stages For Ahu With Filterload
   * Get the stages for a particular AHU along with filter load data.
   * Returns an object keyed by stage_id's.
   *
   * Essentially a replacement of:
   * GET https://dev.qd.i-qlair.com/v2/hvac/units/{unitId}/stages
   * from old backend.
   * @param buildingId
   * @param ahuId
   * @returns StageAndFilterload Successful Response
   * @throws ApiError
   */
  public static getStagesForAhuWithFilterload(
    buildingId: string,
    ahuId: string,
  ): CancelablePromise<Record<string, StageAndFilterload>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/ahus/{ahu_id}/stages/details/',
      path: {
        'building_id': buildingId,
        'ahu_id': ahuId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Filter Types
   * @returns string Successful Response
   * @throws ApiError
   */
  public static getFilterTypes(): CancelablePromise<Array<'Pre Filter' | 'Secondary Filter' | 'Final Filter'>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/tco/filter_type/',
    });
  }
  /**
   * Get Energy Companies
   * @returns number Successful Response
   * @throws ApiError
   */
  public static getEnergyCompanies(): CancelablePromise<Record<string, number>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/tco/energy_companies/',
    });
  }
  /**
   * Get Energy Sources
   * @returns number Successful Response
   * @throws ApiError
   */
  public static getEnergySources(): CancelablePromise<Record<string, number>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/tco/energy_sources/',
    });
  }
  /**
   * Get Calculated Tco
   * @param requestBody
   * @returns OutputTco Successful Response
   * @throws ApiError
   */
  public static getCalculatedTco(
    requestBody: InputTco,
  ): CancelablePromise<OutputTco> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/tco/calculate/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Avaiable User Setting Options
   * Returns static info about the settings/preferences available for user.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static avaiableUserSettingOptions(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/settings/options/',
    });
  }
  /**
   * Legacy Device Data
   * Used for building device detail view in customer webportal. Brought over from old back-end for compatibility, but should probably be refactored/put somewhere more logical.
   * Old backend supported query param for averaging over different time windows (2 hours, 6 hours, etc),
   * but for now that doesn't seem necessary.
   * @param buildingId
   * @param deviceId
   * @param startTs
   * @param endTs
   * @param granularity
   * @returns LegacyDeviceDataResp Successful Response
   * @throws ApiError
   */
  public static legacyDeviceData(
    buildingId: string,
    deviceId: string,
    startTs: string,
    endTs?: (string | null),
    granularity?: AggGranularity,
  ): CancelablePromise<LegacyDeviceDataResp> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/device-data/',
      query: {
        'building_id': buildingId,
        'device_id': deviceId,
        'start_ts': startTs,
        'end_ts': endTs,
        'granularity': granularity,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Legacy Get Recent Device Data
   * Used for on-boarding sensedge device in customer webportal. Brought over from old back-end for compatibility, but should probably be refactored/put somewhere more logical.
   * @param deviceId
   * @param buildingId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static legacyGetRecentDeviceData(
    deviceId: string,
    buildingId: string,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/device-data/recent/',
      query: {
        'device_id': deviceId,
        'building_id': buildingId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Building Summaries
   * List of building details for a given user's list of buildings. Includes Building model and latest indoor/outdoor aq info.
   * @returns BuildingSummary Successful Response
   * @throws ApiError
   */
  public static buildingSummaries(): CancelablePromise<Record<string, BuildingSummary>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/building-summaries/',
      errors: {
        400: `Unable to load building info.`,
      },
    });
  }
  /**
   * Building Entity Info
   * Returns a dictionary with `building_id` keys and which subentities the building has, e.g. aq devices, AHUs, kettles etc, e.g.
   * ```
   * {
     * "buildingid-123": {
       * "has_ahus": true,
       * "has_rooms": true,
       * "has_kettles": true
       * },
       * "buildingid-456": {
         * "has_ahus": true,
         * "has_rooms": true,
         * "has_kettles": true
         * },
         * }
         * ```
         * @returns BuildingEntityInfo Successful Response
         * @throws ApiError
         */
        public static buildingEntityInfo(): CancelablePromise<Record<string, BuildingEntityInfo>> {
          return __request(OpenAPI, {
            method: 'GET',
            url: '/building-entities/',
          });
        }
        /**
         * Buildings Outdoor Heatmap Info
         * List of building coordinates and latest outdoor aq info for a user's buildings. Used for the webportal outdoor heatmap view.
         * @param companyId
         * @param buildingId
         * @returns any Successful Response
         * @throws ApiError
         */
        public static buildingsOutdoorHeatmapInfo(
          companyId: string,
          buildingId: string,
        ): CancelablePromise<Array<Record<string, any>>> {
          return __request(OpenAPI, {
            method: 'GET',
            url: '/building-heatmap/',
            query: {
              'company_id': companyId,
              'building_id': buildingId,
            },
            errors: {
              400: `Unable to load building info.`,
              422: `Validation Error`,
            },
          });
        }
        /**
         * Tradefair Dashboard Info
         * @param companyId
         * @param buildingId
         * @returns TradefairDashboardData Successful Response
         * @throws ApiError
         */
        public static tradefairDashboardInfo(
          companyId: string,
          buildingId: string,
        ): CancelablePromise<TradefairDashboardData> {
          return __request(OpenAPI, {
            method: 'GET',
            url: '/building-dashboard/',
            query: {
              'company_id': companyId,
              'building_id': buildingId,
            },
            errors: {
              404: `No data found`,
              422: `Validation Error`,
            },
          });
        }
        /**
         * Hvac Export
         * Export hvac data to excel for a stage, and entire, or all the AHUs for a building.
         *
         * Returns pressure drop values in Pascals or iwg depending on the preference of the user
         * calling this endpoint.
         * @param companyId
         * @param buildingId
         * @param requestBody
         * @returns any Successful Response
         * @throws ApiError
         */
        public static hvacExport(
          companyId: string,
          buildingId: string,
          requestBody: HvacExportRequestBody,
        ): CancelablePromise<any> {
          return __request(OpenAPI, {
            method: 'POST',
            url: '/hvac-export/',
            query: {
              'company_id': companyId,
              'building_id': buildingId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
              400: `Invalid entity id or no data available`,
              422: `Validation Error`,
            },
          });
        }
        /**
         * Paint Booth Data Export
         * Export hvac data to excel for a stage, and entire, or all the AHUs for a building.
         *
         * Returns pressure drop values in Pascals or iwg depending on the preference of the user
         * calling this endpoint.
         * @param buildingId
         * @param boothId
         * @param startTs
         * @param endTs
         * @returns any Successful Response
         * @throws ApiError
         */
        public static paintBoothDataExport(
          buildingId: string,
          boothId: string,
          startTs: string,
          endTs?: (string | null),
        ): CancelablePromise<any> {
          return __request(OpenAPI, {
            method: 'GET',
            url: '/booth-data-export/',
            query: {
              'building_id': buildingId,
              'booth_id': boothId,
              'start_ts': startTs,
              'end_ts': endTs,
            },
            errors: {
              400: `Invalid entity id or no data available`,
              422: `Validation Error`,
            },
          });
        }
      }
