/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AlertDeviceType } from './AlertDeviceType';
import type { AlertKey } from './AlertKey';
import type { AqDeviceWithContext } from './AqDeviceWithContext';
import type { HvacDeviceWithContext } from './HvacDeviceWithContext';
import type { KettleDeviceWithContext } from './KettleDeviceWithContext';
import type { PaintBoothDeviceWithContext } from './PaintBoothDeviceWithContext';
import type { qlairlib__contexts__alerts_and_notifications__models__LegacyFields } from './qlairlib__contexts__alerts_and_notifications__models__LegacyFields';
/**
 * Helper model for api endpoint return values that extends NotificationDefinition model
 * with extra fields that must be determined dynamically.
 */
export type NotificationDefinitionReturn = {
  title: string;
  company_id: string;
  building_id: string;
  alert_device_type: AlertDeviceType;
  alert_key: AlertKey;
  /**
   * This property means `PressureMeasurement` satisfies the same interface as the `Measurement`
   * model (having `units` and `value` attributes.)
   */
  readonly threshold: number;
  threshold_direction?: NotificationDefinitionReturn.threshold_direction;
  time_required?: number;
  device_id: string;
  is_active?: boolean;
  text_message_enabled?: boolean;
  email_enabled?: boolean;
  in_alert?: boolean;
  last_alert_id?: (string | null);
  last_alert_created_at?: (string | null);
  last_alert_value?: (number | null);
  last_alert_value_time?: (string | null);
  legacy?: (qlairlib__contexts__alerts_and_notifications__models__LegacyFields | null);
  id: string;
  created_user_id: string;
  device_name: string;
  device_with_context: (AqDeviceWithContext | HvacDeviceWithContext | KettleDeviceWithContext | PaintBoothDeviceWithContext | null);
  description: string;
  /**
   * This property means `PressureMeasurement` satisfies the same interface as the `Measurement`
   * model (having `units` and `value` attributes.)
   */
  readonly user_preference_threshold: number;
};
export namespace NotificationDefinitionReturn {
  export enum threshold_direction {
    GT = 'gt',
    LT = 'lt',
    EQ = 'eq',
  }
}

