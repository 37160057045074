import { useParams } from "react-router-dom";
import { useGetBuildingRoomsQuery } from "src/store/services/buildings/buildings.service";

const useGetRooms = () => {
  const { buildingId, roomId } = useParams();
  const {
    data: rooms,
    isLoading,
    isFetching
  } = useGetBuildingRoomsQuery(buildingId, { skip: !buildingId });

  return {
    rooms,
    room: rooms?.find(r => r.room.id === roomId)?.room,
    isLoading,
    isFetching
  };
};

export default useGetRooms;
