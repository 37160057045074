import { styled } from "@mui/system";

interface IContentContainerProps {
  children: JSX.Element[];
}

const Wrapper = styled("div")({
  padding: "4%",
  paddingTop: "0.5em",
  paddingBottom: "2em"
});

function ContentContainer({ children }: IContentContainerProps) {
  return <Wrapper>{children}</Wrapper>;
}

export default ContentContainer;
