import { useAppSelector } from "src/hooks/useAppSelector";
import { getHvacView } from "src/store/hvac/selectors";
import { Views } from "src/shared/constants/generals";
const useGetHvacView = () => {
  const view = useAppSelector(getHvacView);

  return {
    isListViewActive: view === Views.LIST,
    isChartViewActive: view === Views.CHART
  };
};

export default useGetHvacView;
