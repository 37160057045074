/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Stage } from '../models/Stage';
import type { StageAndFilterload } from '../models/StageAndFilterload';
import type { StageNew } from '../models/StageNew';
import type { StageUpdates } from '../models/StageUpdates';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AhuStagesService {
  /**
   * Get Stages For Ahu
   * Get the stages for a particular AHU.
   * @param buildingId
   * @param ahuId
   * @returns Stage Successful Response
   * @throws ApiError
   */
  public static getStagesForAhu(
    buildingId: string,
    ahuId: string,
  ): CancelablePromise<Array<Stage>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/ahus/{ahu_id}/stages/',
      path: {
        'building_id': buildingId,
        'ahu_id': ahuId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Add Stage To Ahu
   * Add a new stage to an Ahu, and return the updated Ahu.
   * @param buildingId
   * @param ahuId
   * @param requestBody
   * @returns Stage Successful Response
   * @throws ApiError
   */
  public static addStageToAhu(
    buildingId: string,
    ahuId: string,
    requestBody: StageNew,
  ): CancelablePromise<Stage> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/buildings/{building_id}/ahus/{ahu_id}/stages/',
      path: {
        'building_id': buildingId,
        'ahu_id': ahuId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Stages For Ahu With Filterload
   * Get the stages for a particular AHU along with filter load data.
   * Returns an object keyed by stage_id's.
   *
   * Essentially a replacement of:
   * GET https://dev.qd.i-qlair.com/v2/hvac/units/{unitId}/stages
   * from old backend.
   * @param buildingId
   * @param ahuId
   * @returns StageAndFilterload Successful Response
   * @throws ApiError
   */
  public static getStagesForAhuWithFilterload(
    buildingId: string,
    ahuId: string,
  ): CancelablePromise<Record<string, StageAndFilterload>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/ahus/{ahu_id}/stages/details/',
      path: {
        'building_id': buildingId,
        'ahu_id': ahuId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Stage
   * Get single stage by stage_id.
   * @param buildingId
   * @param ahuId
   * @param stageId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getStage(
    buildingId: string,
    ahuId: string,
    stageId: string,
  ): CancelablePromise<(Stage | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/ahus/{ahu_id}/stages/{stage_id}',
      path: {
        'building_id': buildingId,
        'ahu_id': ahuId,
        'stage_id': stageId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Update Stage
   * Update attributes of a filter stage
   * @param buildingId
   * @param ahuId
   * @param stageId
   * @param requestBody
   * @returns Stage Successful Response
   * @throws ApiError
   */
  public static updateStage(
    buildingId: string,
    ahuId: string,
    stageId: string,
    requestBody: StageUpdates,
  ): CancelablePromise<Stage> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/buildings/{building_id}/ahus/{ahu_id}/stages/{stage_id}',
      path: {
        'building_id': buildingId,
        'ahu_id': ahuId,
        'stage_id': stageId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Unknown stage`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Delete Stage
   * @param buildingId
   * @param ahuId
   * @param stageId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static deleteStage(
    buildingId: string,
    ahuId: string,
    stageId: string,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/buildings/{building_id}/ahus/{ahu_id}/stages/{stage_id}',
      path: {
        'building_id': buildingId,
        'ahu_id': ahuId,
        'stage_id': stageId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
