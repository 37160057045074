import { useParams } from "react-router-dom";
import useGetBuildings from "src/hooks/useGetBuildings";
import { useGetTradeFairDashboardInfoQuery } from "src/store/services/buildings/buildings.service";

const useGetTradefairInfo = () => {
  const { buildingId } = useParams();
  const { building } = useGetBuildings(buildingId);
  const {
    data: mainData,
    isLoading,
    isError
  } = useGetTradeFairDashboardInfoQuery(
    {
      companyId: building?.companyId,
      buildingId
    },
    { skip: !Boolean(building), pollingInterval: 60000 }
  );

  return { mainData, isLoading, isError };
};

export default useGetTradefairInfo;
