/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AqDevice } from '../models/AqDevice';
import type { DeviceClass } from '../models/DeviceClass';
import type { DeviceId } from '../models/DeviceId';
import type { HvacExportRequestBody } from '../models/HvacExportRequestBody';
import type { HvacSensor } from '../models/HvacSensor';
import type { KettleSensor } from '../models/KettleSensor';
import type { PaintBoothSensor } from '../models/PaintBoothSensor';
import type { PollInfoReturn } from '../models/PollInfoReturn';
import type { StageTypeInfo } from '../models/StageTypeInfo';
import type { TradefairDashboardData } from '../models/TradefairDashboardData';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class MiscService {
  /**
   * Version
   * @returns any Successful Response
   * @throws ApiError
   */
  public static version(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/version',
    });
  }
  /**
   * Get Pollutant Info
   * Returns information about the pollutants we monitor, e.g. default unit, user setting-based aqi scale statuses, etc
   * @returns PollInfoReturn Successful Response
   * @throws ApiError
   */
  public static getPollutantInfo(): CancelablePromise<Record<string, PollInfoReturn>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/pollutants/',
    });
  }
  /**
   * Get Hvac Status Info Dictionary
   * Returns the dictionary of default hvac loading status info for different stage types (pre filter, etc).
   * @returns StageTypeInfo Successful Response
   * @throws ApiError
   */
  public static getHvacStatusInfoDictionary(): CancelablePromise<Record<string, StageTypeInfo>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/hvac-statuses/',
    });
  }
  /**
   * Get Device By Id
   * @param deviceId
   * @param deviceClass
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getDeviceById(
    deviceId: DeviceId,
    deviceClass: DeviceClass,
  ): CancelablePromise<(AqDevice | HvacSensor | KettleSensor | PaintBoothSensor | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/devices/{device_id}',
      path: {
        'device_id': deviceId,
      },
      query: {
        'device_class': deviceClass,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Tradefair Dashboard Info
   * @param companyId
   * @param buildingId
   * @returns TradefairDashboardData Successful Response
   * @throws ApiError
   */
  public static tradefairDashboardInfo(
    companyId: string,
    buildingId: string,
  ): CancelablePromise<TradefairDashboardData> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/building-dashboard/',
      query: {
        'company_id': companyId,
        'building_id': buildingId,
      },
      errors: {
        404: `No data found`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Hvac Export
   * Export hvac data to excel for a stage, and entire, or all the AHUs for a building.
   *
   * Returns pressure drop values in Pascals or iwg depending on the preference of the user
   * calling this endpoint.
   * @param companyId
   * @param buildingId
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static hvacExport(
    companyId: string,
    buildingId: string,
    requestBody: HvacExportRequestBody,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/hvac-export/',
      query: {
        'company_id': companyId,
        'building_id': buildingId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid entity id or no data available`,
        422: `Validation Error`,
      },
    });
  }
}
