import { OutputTco, WebPortalService } from "src/utils/client";
import { apiService } from "../api";

export const buildingCostService = apiService
  .enhanceEndpoints({
    addTagTypes: ["ENERGYCOMPANIES", "ENERGYSOURCES", "FILTERTYPES", "TCO"]
  })
  .injectEndpoints({
    endpoints: build => ({
      getEnergyCompanies: build.query<Record<string, number>, void>({
        queryFn: async () => {
          const energyCompanies = await WebPortalService.getEnergyCompanies();
          return { data: energyCompanies };
        },
        providesTags: ["ENERGYCOMPANIES"]
      }),
      getEnergySources: build.query<Record<string, number>, void>({
        queryFn: async () => {
          const energySources = await WebPortalService.getEnergySources();
          return { data: energySources };
        },
        providesTags: ["ENERGYSOURCES"]
      }),
      getFilterTypes: build.query<
        Array<"Pre Filter" | "Secondary Filter" | "Final Filter">,
        void
      >({
        queryFn: async () => {
          const filterTypes = await WebPortalService.getFilterTypes();
          return { data: filterTypes };
        },
        providesTags: ["FILTERTYPES"]
      }),
      cacluateTCO: build.query<OutputTco, any>({
        queryFn: async body => {
          const {
            filter_type,
            filters_per_array,
            flow_rate,
            change_interval,
            maximum_pressure_value,
            labor_cost,
            labor_time,
            energy_cost,
            filter_cost,
            cotwo_per_kilowatt_output,
            pressure_start,
            pressure_end
          } = body;
          const updatedBody = {
            filter_type,
            filters_per_array: Number(filters_per_array),
            flow_rate: Number(flow_rate),
            change_interval: Number(change_interval) * 30,
            maximum_pressure_value: Number(maximum_pressure_value),
            labor_cost: Number(labor_cost),
            labor_time: Number(labor_time),
            energy_cost: Number(energy_cost),
            filter_cost: Number(filter_cost),
            cotwo_per_kilowatt_output:
              Number(cotwo_per_kilowatt_output) * 2.20462262,
            pressure_start: Number(pressure_start),
            pressure_end: Number(pressure_end)
          };
          const data = await WebPortalService.getCalculatedTco(updatedBody);

          return { data };
        },
        providesTags: (_result, _error, { filter_type }) => [
          { type: "TCO", filter_type }
        ]
      })
    })
  });

export const {
  useGetEnergyCompaniesQuery,
  useGetEnergySourcesQuery,
  useGetFilterTypesQuery,
  useLazyCacluateTCOQuery
} = buildingCostService;
