import { useEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5Radar from "@amcharts/amcharts5/radar";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { nanoid } from "nanoid";
import { Box } from "@mui/system";

import {
  RadarChart,
  AxisRendererCircular,
  RadarColumnSeries,
  AxisRendererRadial
} from "@amcharts/amcharts5/radar";

type Props = {
  classes: any;
  value: number;
  color?: string;
  pollutant?: string;
  max?: number;
  min?: number;
  unit?: string;
  displayGradient?: boolean;
  gradientsStops?: any;
};

const CurverProgressBarComponent = (props: Props) => {
  const {
    classes,
    value,
    color = "#00FFBB",
    pollutant = "",
    min = 0,
    max = 100,
    unit = "%",
    displayGradient,
    gradientsStops = ["#19d228", "#f4fb16", "#f6d32b", "#fb7116"]
  } = props;
  let id = nanoid();
  let [rootState, setRoot] = useState<any>();

  useEffect(() => {
    if (!rootState) {
      let root = am5.Root.new(id);

      setRoot(root);

      root.setThemes([am5themes_Animated.new(root)]);

      let chart = root.container.children.push(
        RadarChart.new(root, {
          panX: false,
          panY: false,
          innerRadius: am5.percent(80),
          startAngle: -180,
          endAngle: 0
        })
      );

      let calculated = 100 - ((max - value) / (max - min)) * 100;

      let data = [
        {
          category: "-",
          value: calculated,
          full: max,
          empty: min,
          columnSettings: {
            fill: am5.Color.fromAny(color)
          }
        }
      ];

      chart.radarContainer.children.push(
        am5.Label.new(root, {
          centerX: am5.percent(50),
          textAlign: "center",
          centerY: am5.percent(50),
          fontSize: "1em",
          text: Boolean(value) ? `${value}${unit}` : `0`,
          y: am5.percent(-20)
        })
      );

      chart.radarContainer.children.push(
        am5.Label.new(root, {
          fill: am5.Color.fromHex(0x66797c),
          centerX: am5.percent(50),
          textAlign: "center",
          centerY: am5.percent(50),
          fontSize: "0.75em",
          text: pollutant,
          y: am5.percent(10)
        })
      );

      let xRenderer = AxisRendererCircular.new(root, {});

      xRenderer.labels.template.setAll({
        forceHidden: true
      });

      xRenderer.grid.template.setAll({
        forceHidden: true
      });

      let xAxis = chart.xAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: xRenderer,
          min: min,
          max: max,
          strictMinMax: true,
          numberFormat: "#'%'"
        })
      );

      let yRenderer = AxisRendererRadial.new(root, {
        minGridDistance: 20
      });

      yRenderer.labels.template.setAll({
        forceHidden: true
      });

      yRenderer.grid.template.setAll({
        forceHidden: true
      });

      let yAxis = chart.yAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: "category",
          renderer: yRenderer
        })
      );

      yAxis.data.setAll(data);

      // background
      let series1 = chart.series.push(
        RadarColumnSeries.new(root, {
          xAxis: xAxis,
          yAxis: yAxis,
          clustered: false,
          valueXField: "full",
          categoryYField: "category",
          fill: root.interfaceColors.get("alternativeBackground")
        })
      );

      series1.columns.template.setAll({
        width: am5.p100,
        fillOpacity: 0.08,
        strokeOpacity: 0,
        cornerRadius: 50
      });

      series1.data.setAll(data);

      let series2 = chart.series.push(
        RadarColumnSeries.new(root, {
          xAxis: xAxis,
          yAxis: yAxis,
          clustered: false,
          valueXField: "value",
          categoryYField: "category"
        })
      );

      series2.columns.template.setAll({
        width: am5.p100,
        strokeOpacity: 0,
        cornerRadius: 50,
        templateField: "columnSettings"
      });

      series2.data.setAll(data);

      if (displayGradient) {
        let series3 = chart.series.push(
          RadarColumnSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            clustered: false,
            valueXField: "value",
            categoryYField: "category"
          })
        );

        series3.columns.template.setAll({
          width: am5.p100,
          strokeOpacity: 0,
          cornerRadius: 50,
          templateField: "columnSettings"
        });

        series3.data.setAll([
          {
            category: "-",
            value: max,
            full: max,
            empty: min,
            columnSettings: {
              fillGradient: am5.LinearGradient.new(root, {
                rotation: 0,
                stops: gradientsStops.map(color => ({
                  color: am5.color(color)
                }))
              })
            }
          }
        ]);

        var axisDataItem = xAxis.makeDataItem({});
        axisDataItem.set("value", 0);

        var hand = am5Radar.ClockHand.new(root, {
          radius: am5.percent(50),
          pinRadius: 0,
          innerRadius: am5.percent(50),
          layer: 1,
          topWidth: 20,
          bottomWidth: 10
        });

        // Use a Triangle element as a tip of the hand
        var tip = hand.children.push(
          am5.Circle.new(root, {
            radius: 7.5,
            stroke: am5.color(0xffffff),
            fill: am5.color(color),
            strokeWidth: 3.5,
            centerX: am5.percent(105)
          })
        );

        chart.onPrivate("radius", function (radius) {
          tip.set("x", radius);
        });

        axisDataItem.set(
          "bullet",
          am5xy.AxisBullet.new(root, {
            sprite: hand
          })
        );

        xAxis.createAxisRange(axisDataItem);
        axisDataItem.get("grid").set("visible", false);

        axisDataItem.animate({
          key: "value",
          to: Math.round(value),
          duration: 800,
          easing: am5.ease.out(am5.ease.cubic)
        });

        series3.appear(1000);
        series1.dispose();
        series2.dispose();
      } else {
        series1.appear(1000);
        series2.appear(1000);
      }

      chart.appear(1000, 100);
      root._logo.dispose();
    }
  }, []);

  return (
    <Box
      data-testid="curver-progress-bar"
      id={id}
      className={classes.root}></Box>
  );
};

export default CurverProgressBarComponent;
