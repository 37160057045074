import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => {
  return {
    devices: {
      textAlign: "center",
      border: "1px solid #E9EAEA",
      borderRadius: 4,
      paddingBottom: 2,
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column"
    },
    pollutant: {
      textAlign: "center" as const,
      border: "1px solid #E9EAEA",
      borderRadius: 4,
      paddingBottom: 2,
      marginRight: "0.5em",
      minWidth: 120
    },
    qtyContainer: {
      minWidth: 58,
      width: "fit-content",
      padding: 8
    },
    pollutantQty: {
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: "1.235rem"
    },
    pollutantQtyBigger: {
      fontSize: "2.125rem",
      fontWeight: 400
    }
  };
});

export default useStyles;
