/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaintBooth } from '../models/PaintBooth';
import type { PaintBoothAndZones } from '../models/PaintBoothAndZones';
import type { PaintBoothNew } from '../models/PaintBoothNew';
import type { PaintBoothSensor } from '../models/PaintBoothSensor';
import type { PaintBoothUpdates } from '../models/PaintBoothUpdates';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PaintBoothService {
  /**
   * Get Paint Booths For Building
   * Returns possibly empty list of paint booths for the building, or `null` for invalid building_id.
   * @param buildingId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getPaintBoothsForBuilding(
    buildingId: string,
  ): CancelablePromise<(Array<PaintBooth> | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/paint-booths/',
      path: {
        'building_id': buildingId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Create Paint Booth
   * @param buildingId
   * @param requestBody
   * @returns PaintBooth Successful Response
   * @throws ApiError
   */
  public static createPaintBooth(
    buildingId: string,
    requestBody: PaintBoothNew,
  ): CancelablePromise<PaintBooth> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/buildings/{building_id}/paint-booths/',
      path: {
        'building_id': buildingId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Database Error`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Paint Booth
   * @param buildingId
   * @param boothId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getPaintBooth(
    buildingId: string,
    boothId: string,
  ): CancelablePromise<(PaintBooth | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/paint-booths/{booth_id}',
      path: {
        'building_id': buildingId,
        'booth_id': boothId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Update Paint Booth
   * Update basic fields of an PaintBooth. To update complex fields like zones,
   * fans, etc. use dedicated endpoints for those entities.
   * @param buildingId
   * @param boothId
   * @param requestBody
   * @returns PaintBooth Successful Response
   * @throws ApiError
   */
  public static updatePaintBooth(
    buildingId: string,
    boothId: string,
    requestBody: PaintBoothUpdates,
  ): CancelablePromise<PaintBooth> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/buildings/{building_id}/paint-booths/{booth_id}',
      path: {
        'building_id': buildingId,
        'booth_id': boothId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Database Error`,
        404: `Invalid Object Id`,
        409: `Name Conflict`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Delete Paint Booth
   * @param buildingId
   * @param boothId
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public static deletePaintBooth(
    buildingId: string,
    boothId: string,
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/buildings/{building_id}/paint-booths/{booth_id}',
      path: {
        'building_id': buildingId,
        'booth_id': boothId,
      },
      errors: {
        404: `Invalid Object Id`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Paint Booths With Zones
   * Get the zones for a particular paint booth.
   * Returns an object keyed by `zone_id`'s.
   * @param buildingId
   * @returns PaintBoothAndZones Successful Response
   * @throws ApiError
   */
  public static getPaintBoothsWithZones(
    buildingId: string,
  ): CancelablePromise<Record<string, PaintBoothAndZones>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/paint-booths/details/',
      path: {
        'building_id': buildingId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Sensors
   * Returns the sensors attached to a particular paint booth, keyed by zone_id.
   * @param buildingId
   * @param boothId
   * @returns PaintBoothSensor Successful Response
   * @throws ApiError
   */
  public static getSensors(
    buildingId: string,
    boothId: string,
  ): CancelablePromise<Array<PaintBoothSensor>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/paint-booths/{booth_id}/sensors/',
      path: {
        'building_id': buildingId,
        'booth_id': boothId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
