export function formatDate(date: string) {
  const dateObj = new Date(date);
  const year = dateObj.getFullYear();
  const monthIndex = dateObj.getMonth();
  const monthAbbreviations = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  const month = monthAbbreviations[monthIndex];
  const day = dateObj.getDate();
  const formattedDate = `${month} ${day}, ${year}`;
  return formattedDate;
}
