import GoogleMap from "src/shared/components/map/map.component";
import BuildingMapMarker from "src/shared/components/building-map-marker/building-map-marker.component";
import BuildingsModal from "../buildings-modal/buildings-modal.component";
import LoaderComponent from "src/shared/components/loader/loader.component";
import BuildingsToolbarComponent from "../buildings-toolbar/buildings-toolbar.component";
import BuildingsListComponent from "../buildings-list/buildings-list.component";
import { makeStyles } from "tss-react/mui";
import { useDispatch } from "react-redux";
import { Grid, Typography } from "@mui/material";
import useGetBuildingView from "../hooks/useGetBuildingView";
import useGetBuildings from "src/hooks/useGetBuildings";
import { useAppSelector } from "src/hooks/useAppSelector";
import { getReportModalState } from "src/store/buildings/selector";
import ModalComponent from "src/shared/components/modal/modal.component";
import FullReportForm from "../full-report-form/full-report-form.component";
import {
  handleReportModal,
  setBuildingView
} from "src/store/buildings/reducer";
import { Views } from "src/shared/constants/generals";
import useGetAppBreakPoints from "src/hooks/useGetAppBreakPoints";
import useGetFilteredBuildings from "./useGetFilteredBuildings";
import { useContext } from "react";
import { DrawerContext } from "src/sections/dashboard/Context/DashboardContext";

const BuildingsMainScreenComponent = () => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { isSm, isMd, isTablet } = useGetAppBreakPoints();
  const { buildings, isLoading, isError, isFetching } = useGetBuildings();
  const { isMapView } = useGetBuildingView();
  const reportModalOpen = useAppSelector(getReportModalState);
  const { filter } = useContext(DrawerContext);

  if (isTablet && isMapView) {
    dispatch(setBuildingView(Views.CHART));
  }

  const { sortedBuildings } = useGetFilteredBuildings(filter);

  return (
    <div
      className={classes.root}
      data-testid="buildings-main">
      {isLoading || isFetching ? (
        <LoaderComponent />
      ) : isError ? (
        <Typography
          data-testId="error"
          className={classes.errorMessage}>
          An error occured please try again
        </Typography>
      ) : (
        <Grid container>
          {(!isMapView || (isMapView && isSm)) && (
            <Grid
              item
              xs={12}
              className={classes.title}>
              <Typography
                variant="h6"
                sx={{ fontWeight: 500 }}>
                Buildings
              </Typography>
            </Grid>
          )}
          {((isMapView && !isMd) || !isMapView) && (
            <Grid
              item
              md={isMapView ? 5 : 12}
              lg={isMapView ? 4 : 12}
              xs={12}
              className={isMapView ? classes.mapList : null}
              sx={{
                overflow: "auto",
                height: `calc(100vh - 60px${
                  !isMapView && !isSm ? " - 65px" : ""
                })`
              }}>
              <BuildingsListComponent buildings={sortedBuildings} />
            </Grid>
          )}
          {isMapView && (
            <Grid
              item
              sm={12}
              xs={12}
              md={7}
              lg={8}
              className={classes.mapContainer}
              sx={{
                height: `calc(100vh - 60px${isSm ? " - 10px" : ""})`
              }}>
              <div className={classes.filters}>
                <BuildingsToolbarComponent
                  orientation={isMd ? "vertical" : "horizontal"}
                />
              </div>
              <GoogleMap>
                {buildings?.map(building => (
                  <BuildingMapMarker
                    key={`building_marker_${building.id}`}
                    building={building}
                  />
                ))}
              </GoogleMap>
            </Grid>
          )}
        </Grid>
      )}
      <BuildingsModal />
      <ModalComponent
        open={reportModalOpen}
        onClose={() => {
          dispatch(handleReportModal(false));
        }}>
        <FullReportForm />
      </ModalComponent>
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {},
    title: {
      backgroundColor: "white",
      height: 50,
      paddingLeft: 16,
      display: "flex",
      alignItems: "center",
      "@media(min-width: 600px)": {
        backgroundColor: "transparent",
        marginTop: 16
      }
    },
    filters: {
      padding: "1em",
      backgroundColor: "transparent",
      position: "absolute" as const,
      width: "100%",
      zIndex: 9
    },
    mapContainer: {
      position: "relative" as const
    },
    mapList: {
      overflow: "auto"
    },
    edge: {
      position: "absolute" as const,
      top: -56,
      borderTopLeftRadius: 24,
      borderTopRightRadius: 24,
      visibility: "visible" as const,
      right: 0,
      left: 0,
      backgroundColor: "white",
      height: 56,
      display: "flex",
      flexDirection: "column" as const,
      justifyContent: "center",
      alignItems: "center"
    },
    drawer: {
      "& .MuiDrawer-paper": {
        overflow: "visible",
        height: `calc(100% - 166px)`,
        backgroundColor: "#F3F6FF"
      }
    },
    puller: {
      width: 40,
      height: 4,
      borderRadius: 100,
      backgroundColor: "#232426"
    },
    drawerContent: {
      overflow: "auto"
    },
    errorMessage: {
      fontSize: "30px",
      display: "flex",
      justifyContent: "center",
      marginTop: "30px"
    }
  };
});

export default BuildingsMainScreenComponent;
