import { apiService } from "../api";
import {
  GetTermsResponseBody,
  MiscService,
  OutdoorAqDataService,
  PollInfoReturn,
  StageTypeInfo,
  UsersService,
  OutdoorAqData
} from "src/utils/client";

export const generalService = apiService
  .enhanceEndpoints({
    addTagTypes: [
      "POLLUTANTDICTIONARY",
      "HVACSTATUSDICTIONARY",
      "OUTDOORAQDATAPOINT",
      "USERTERMS"
    ]
  })
  .injectEndpoints({
    endpoints: build => ({
      getPollutantDictionary: build.query<Record<string, PollInfoReturn>, void>(
        {
          queryFn: async () => {
            const dictionary = await MiscService.getPollutantInfo();
            return { data: dictionary };
          },
          providesTags: ["POLLUTANTDICTIONARY"]
        }
      ),
      getHvacStatusInfoDictionary: build.query<
        Record<string, StageTypeInfo>,
        void
      >({
        queryFn: async () => {
          const dictionary = await MiscService.getHvacStatusInfoDictionary();
          return { data: dictionary };
        },
        providesTags: ["HVACSTATUSDICTIONARY"]
      }),
      getLatestOutdoorAqDataPoint: build.query<OutdoorAqData, string>({
        //@ts-ignore
        queryFn: async buildingId => {
          const outdoorData =
            await OutdoorAqDataService.getLatestOutdoorAqDataPoint(buildingId);

          return { data: outdoorData?.data ?? null };
        },
        providesTags: (_result, _error, buildingId) => [
          { type: "OUTDOORAQDATAPOINT", buildingId }
        ]
      }),
      getUserTerms: build.query<GetTermsResponseBody, string>({
        queryFn: async userId => {
          const terms = await UsersService.getUserTerms(userId);
          return { data: terms };
        },
        providesTags: ["USERTERMS"]
      }),
      updateUserTerms: build.mutation<string, string>({
        //@ts-ignore
        queryFn: async userId => {
          return UsersService.updateUserTerms(userId, { termsAccepted: true });
        },
        invalidatesTags: ["USERTERMS"]
      })
    })
  });

export const {
  useGetPollutantDictionaryQuery,
  useGetHvacStatusInfoDictionaryQuery,
  useGetLatestOutdoorAqDataPointQuery,
  useGetUserTermsQuery,
  useUpdateUserTermsMutation
} = generalService;
