import { useEffect, useMemo, useState } from "react";

import {
  Alert,
  Button,
  Checkbox,
  Drawer,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { Field, Form } from "react-final-form";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
0;

import LoaderComponent from "src/shared/components/loader/loader.component";
import AqiReferenceTableComponent from "../aqi-reference-table/aqi-reference-table.component";

import {
  Ahu,
  Kettle,
  NotificationDefinitionNew,
  NotificationDefinitionUpdates,
  Room
} from "src/utils/client";

import { useStyles } from "./notification-drawer.style";
import { CreateNotificationViewModel } from "./notification-drawer.models";
import useGetPolutantDictionary from "src/hooks/useGetPollutantDictionary";
import {
  GetCompanyIDFromBuildingId,
  getBuildingNameFromBuildingId
} from "src/utils/buildings.util";
import {
  useCreateNotificationMutation,
  useUpdateNotificationMutation,
  useGetDevicesQuery,
  useGetTypesQuery,
  useGetDeviceParentsQuery
} from "src/store/services/notifications/notificationsService";
import { useAppSelector } from "src/hooks/useAppSelector";
import {
  getNotificationDrawerState,
  getSelectedNotification
} from "src/store/notification/selectors";
import {
  setDrawerOpen,
  setSelectedNotification
} from "src/store/notification/reducer";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import useGetBuildings from "src/hooks/useGetBuildings";
import AlertTypes from "../alert-types/alert-types";
import { AlertTypesEnum } from "../alert-types/alert-types";
import AutoCompleteFormField from "./autocomplete-form";
import qlairLoader from "src/assets/qlair-load.gif";
import useUserPascalSettings from "src/hooks/useUserPascalSettings";
import useUserCelsiusSettings from "src/hooks/useUserCelsiusSettings";

export enum FormFields {
  TITLE = "title",
  BUILDING_ID = "building_id",
  THRESHOLD = "threshold",
  DEVICE_ID = "device_id",
  THRESHOLD_DIRECTION = "threshold_direction",
  EMAIL_ENABLED = "email_enabled",
  TIME_REQUIRED = "time_required",
  TEXT_MESSAGE_ENABLED = "text_message_enabled",
  ALERT_DEVICE_TYPE = "alert_device_type",
  ALERT_KEY = "alert_key"
}

const TIMING_VALUES = [
  { value: 0, text: "Immediately" },
  { value: 3600, text: "After 1 hour" },
  { value: 7200, text: "After 2 hours" },
  { value: 21600, text: "After 6 hours" },
  { value: 43200, text: "After 12 hours" },
  { value: 86400, text: "After 24 hours" }
];
const DEVICE_PARENT_INFO = {
  aq: { type: "room", label: "Room" },
  hvac: { type: "ahu", label: "Ahu" },
  kettle: { type: "kettle", label: "Kettle" },
  paint_booth: { type: "booth", label: "Booth" }
};

const middleOptionId = {
  aq: "room_id",
  hvac: "ahu_id",
  kettle: "kettle_id",
  paint_booth: "booth_id"
};

const NotificationDrawerComponent = () => {
  const dispatch = useAppDispatch();
  const { pollInfoDict } = useGetPolutantDictionary();
  const open = useAppSelector(getNotificationDrawerState);
  const selectedNotification = useAppSelector(getSelectedNotification);
  const isUserUsingPascals = useUserPascalSettings();
  const isUserUsingCelcius = useUserCelsiusSettings();
  const { buildings } = useGetBuildings();
  const { classes } = useStyles();
  const [selectedAlertType, setSelectedAlertType] =
    useState<`${AlertTypesEnum}`>(null);
  const [referenceOpen, setReferenceOpen] = useState(false);
  const [selectedBuildingId, setSelectedBuildingId] = useState<string | null>(
    null
  );
  const [selectedMeasurement, setSelectedMeasurement] = useState(null);
  const [selectedDeviceParent, setSelectedDeviceParent] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const { data: stateAlertTypes } = useGetTypesQuery();
  const {
    data: stateDevices,
    isLoading: isLoadingDevices,
    isFetching: isFetchingDevices
  } = useGetDevicesQuery();
  const {
    data: stateDeviceParents,
    isLoading: isLoadingParents,
    isFetching: isFetchingDeviceParents
  } = useGetDeviceParentsQuery();

  const initialNotification = useMemo(() => {
    if (Boolean(selectedNotification))
      return new CreateNotificationViewModel(selectedNotification);

    return new CreateNotificationViewModel(null);
  }, [selectedNotification]);

  const [createNotification, { isLoading: isSaving }] =
    useCreateNotificationMutation();
  const [updateNotification, { isLoading: isAdding }] =
    useUpdateNotificationMutation();

  const deviceParentOptions = useMemo(() => {
    if (
      !Boolean(selectedAlertType) ||
      !Boolean(stateDevices) ||
      !Boolean(stateDeviceParents)
    )
      return [];

    const deviceParents: Kettle[] | Ahu[] | Room[] =
      stateDeviceParents[selectedAlertType] ?? [];

    return deviceParents
      ?.filter(entity => {
        if (!selectedBuildingId) return entity;
        return entity.building_id === selectedBuildingId;
      })
      ?.map(entity => ({
        ...entity,
        label: entity.name,
        id: entity.id,
        key: entity.id
      }));
  }, [
    selectedAlertType,
    stateDevices,
    selectedBuildingId,
    isLoadingParents,
    isFetchingDeviceParents,
    isLoadingDevices
  ]);

  const deviceOptions = useMemo(() => {
    if (
      !Boolean(selectedAlertType) ||
      !Boolean(stateDevices) ||
      !Boolean(stateDeviceParents)
    )
      return [];

    const devices = stateDevices[selectedAlertType] ?? [];

    return (
      devices
        ?.filter(device => {
          if (!selectedBuildingId) return device;
          if (Boolean(selectedDeviceParent)) {
            const parentType = DEVICE_PARENT_INFO[selectedAlertType]?.type;
            return (
              device.building_id === selectedBuildingId &&
              device[`${parentType}_id`] === selectedDeviceParent?.id
            );
          }
          return device.building_id === selectedBuildingId;
        })
        // NOTE: kettle sensors come in two types: absolute pressure and differential pressure
        ?.filter(device => {
          if (selectedAlertType === "kettle") {
            if (selectedMeasurement === "pressure_abs") {
              // @ts-ignore
              return device.details.sensor_type.endsWith("_pressure");
            }
            if (selectedMeasurement === "pressure_drop") {
              // @ts-ignore
              return device.details.sensor_type.endsWith("_dp");
            }
          }
          return true;
        })
        ?.map(device => ({
          ...device,
          label: device.name,
          id: device.id,
          key: device.id
        }))
    );
  }, [
    selectedAlertType,
    stateDevices,
    stateDeviceParents,
    selectedBuildingId,
    selectedDeviceParent,
    selectedMeasurement,
    isFetchingDeviceParents,
    isLoadingDevices
  ]);

  const buildingsForInput = useMemo(() => {
    if (!Boolean(buildings)) return [];

    return buildings?.map(({ name, id }) => ({ label: name, id, key: id }));
  }, [buildings]);

  const getMeassurementUnit = (
    alertType: string,
    meassureKey: string
  ): string => {
    const tempUnit = isUserUsingCelcius ? "°C" : "°F";
    if (
      meassureKey === "t" &&
      (alertType === AlertTypesEnum.AQ ||
        alertType === AlertTypesEnum.PAINT_BOOTH)
    ) {
      return tempUnit;
    }
    if (alertType === AlertTypesEnum.AQ && pollInfoDict[meassureKey]) {
      return pollInfoDict[meassureKey].default_unit.symbol ?? "";
    }
    if (alertType === AlertTypesEnum.KETTLE) {
      return "PSI";
    }
    if (alertType === AlertTypesEnum.PAINT_BOOTH && meassureKey === "h") {
      return "%";
    }
    if (
      alertType === AlertTypesEnum.HVAC ||
      alertType === AlertTypesEnum.PAINT_BOOTH
    ) {
      return isUserUsingPascals ? "Pascals" : "iwg";
    }
  };

  const validate = values => {
    let errors: any = {};
    let {
      title,
      alert_device_type,
      threshold,
      threshold_direction,
      time_required,
      alert_key
    } = values;

    if (!title) {
      errors.title = "Title is required";
    }
    if (!alert_device_type) {
      errors.alert_device_type = "Alert type is required";
    }
    if (!time_required && time_required !== 0) {
      errors.time_required = "Timing is required";
    }

    if (threshold == null) {
      errors.threshold = "Threshold is required";
    }
    if (!threshold_direction) {
      errors.threshold_direction = "Threshold Direction is required";
    }
    if (!alert_key) {
      errors.alert_type = "Alert key is required";
    }
    if (!selectedDevice?.key) {
      errors.sensor_id = "Sensor is required";
    }

    return errors;
  };
  const close = () => {
    dispatch(setDrawerOpen(false));
    dispatch(setSelectedNotification(null));
  };

  const onCreateNotification = (data: any) => {
    const payload: NotificationDefinitionNew = {
      ...data,
      device_id: selectedDevice?.id,
      company_id: GetCompanyIDFromBuildingId(data.building_id, buildings),
      threshold: Number(data.threshold),
      time_required: Number(data.time_required),
      is_active: true,
      in_alert: false
    };

    createNotification({ buildingId: data.building_id, payload: payload }).then(
      _ => close()
    );
  };

  const onUpdateNotification = (data: any) => {
    const payload: NotificationDefinitionUpdates = {
      ...data,
      device_id: selectedDevice?.id,
      company_id: GetCompanyIDFromBuildingId(data.building_id, buildings),
      threshold: Number(data.threshold),
      time_required: Number(data.time_required)
    };

    updateNotification({
      id: data.id,
      buildingId: data.building_id,
      payload: payload
    }).then(_ => close());
  };

  const submit = data => {
    if (data.id) {
      onUpdateNotification(data);
      return;
    }
    onCreateNotification(data);
  };

  useEffect(() => {
    if (!Boolean(initialNotification.id)) return;

    setSelectedAlertType(
      initialNotification.alert_device_type as `${AlertTypesEnum}`
    );
    const devParentLabel =
      initialNotification.alert_device_type === AlertTypesEnum.KETTLE
        ? initialNotification?.device_with_context?.kettle?.name
        : initialNotification.alert_device_type === AlertTypesEnum.HVAC
        ? initialNotification?.device_with_context?.ahu.name
        : initialNotification.alert_device_type === AlertTypesEnum.AQ
        ? initialNotification?.device_with_context?.room.name
        : initialNotification.alert_device_type === AlertTypesEnum.PAINT_BOOTH
        ? initialNotification?.device_with_context?.booth.name
        : "";

    setSelectedDeviceParent({ label: devParentLabel });

    setSelectedDevice({
      label: initialNotification?.device_with_context?.device.name
    });

    return () => {
      setSelectedAlertType(null);
      setSelectedDeviceParent(null);
      setSelectedDevice(null);
    };
  }, [initialNotification.id]);

  return (
    <Drawer
      open={open}
      onClose={close}
      anchor="right">
      <div
        data-testid="notification-drawer"
        className={classes.root}>
        <div className={classes.title}>
          <Typography variant="h6">
            {selectedNotification ? "Edit" : "Create"} Notification
          </Typography>
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={classes.body}>
          <Form
            initialValues={initialNotification}
            onSubmit={submit}
            validate={validate}
            render={({ handleSubmit, hasValidationErrors, form }) => {
              const formStateValues = form.getState().values;
              const alertDeviceType = formStateValues.alert_device_type;

              const isDisabled = !alertDeviceType;
              return (
                <form
                  data-testid="alert-drawer"
                  className={classes.form}
                  onSubmit={handleSubmit}>
                  {(isSaving || isAdding) && <LoaderComponent />}
                  <div className={classes.formContent}>
                    <div className={classes.panel}>
                      <Field name={FormFields.TITLE}>
                        {({ input, meta }) => (
                          <TextField
                            label="Notification Name"
                            className={classes.input}
                            error={Boolean(meta.touched && meta.error)}
                            helperText={meta.touched && meta.error}
                            InputProps={input}
                            inputProps={{ maxLength: 30 }}></TextField>
                        )}
                      </Field>
                      <div>
                        <Typography>Select an Alert Type</Typography>
                      </div>
                      <div>
                        <Grid
                          container
                          spacing={1}>
                          <AlertTypes
                            alertDeviceType={alertDeviceType}
                            form={form}
                            setSelectedAlertType={setSelectedAlertType}
                            setSelectedDeviceParent={setSelectedDeviceParent}
                            setSelectedDevice={setSelectedDevice}
                            setSelectedBuildingId={setSelectedBuildingId}
                          />
                        </Grid>

                        <>
                          <Box mt={2}>
                            <Field name={FormFields.ALERT_KEY}>
                              {({ input, meta }) => (
                                <FormControl fullWidth>
                                  <InputLabel>Measurement</InputLabel>
                                  <Select
                                    disabled={isDisabled}
                                    label="Measurement"
                                    error={Boolean(meta.touched && meta.error)}
                                    inputProps={input}
                                    onChange={() => {
                                      // NOTE: kettle sensor choices depend on the selected measurement type
                                      selectedAlertType === "kettle" &&
                                        setSelectedDevice(null);
                                      setSelectedMeasurement(
                                        form.getState().values.alert_key
                                      );
                                    }}>
                                    {Boolean(stateAlertTypes) &&
                                      stateAlertTypes[
                                        form.getState().values[
                                          FormFields.ALERT_DEVICE_TYPE
                                        ]
                                      ]?.options?.map(option => (
                                        <MenuItem
                                          key={`option_${option.key}`}
                                          value={option.key}>
                                          {option.label}
                                        </MenuItem>
                                      ))}
                                  </Select>
                                </FormControl>
                              )}
                            </Field>
                          </Box>
                          <Grid
                            container
                            mt={1}>
                            <Grid
                              item
                              xs={10}>
                              <Field name={FormFields.THRESHOLD_DIRECTION}>
                                {({ input, meta }) => (
                                  <FormControl
                                    fullWidth
                                    sx={{ marginTop: 1 }}>
                                    <InputLabel>Direction</InputLabel>
                                    <Select
                                      label="direction"
                                      error={Boolean(
                                        meta.touched && meta.error
                                      )}
                                      inputProps={input}>
                                      <MenuItem value="gt">
                                        Greater than
                                      </MenuItem>
                                      <MenuItem value="lt">Less than</MenuItem>
                                    </Select>
                                  </FormControl>
                                )}
                              </Field>
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                              }}>
                              <IconButton
                                disabled={isDisabled}
                                color="primary"
                                onClick={() => setReferenceOpen(true)}>
                                <InfoOutlinedIcon />
                              </IconButton>
                            </Grid>
                            <Grid
                              item
                              xs={8}
                              sx={{ display: "flex" }}>
                              <Field name={FormFields.THRESHOLD}>
                                {({ input, meta }) => (
                                  <TextField
                                    label="Threshold"
                                    type="number"
                                    error={
                                      Boolean(meta.touched) &&
                                      Boolean(meta.error)
                                    }
                                    helperText={meta.touched && meta.error}
                                    InputProps={input}></TextField>
                                )}
                              </Field>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              mt={1}>
                              <Typography sx={{ padding: "8px" }}>
                                <div translate="no">
                                  {getMeassurementUnit(
                                    form.getState().values.alert_device_type,
                                    form.getState().values.alert_key
                                  )}
                                </div>
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              mt={1}>
                              <Field name={FormFields.TIME_REQUIRED}>
                                {({ input, meta }) => (
                                  <FormControl fullWidth>
                                    <InputLabel>Timing</InputLabel>
                                    <Select
                                      label="Timing"
                                      error={Boolean(
                                        meta.touched && meta.error
                                      )}
                                      value={initialNotification.time_required}
                                      inputProps={input}>
                                      {TIMING_VALUES.map(({ value, text }) => (
                                        <MenuItem
                                          value={value}
                                          key={text}>
                                          {text}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                    {meta.touched && meta.error && (
                                      <FormHelperText>
                                        {meta.error}
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                )}
                              </Field>
                            </Grid>
                          </Grid>
                        </>
                      </div>
                    </div>
                    <div className={classes.panel}>
                      <AutoCompleteFormField
                        form={form}
                        label="Select Building"
                        type="Building"
                        inputLabel={getBuildingNameFromBuildingId(
                          form.getState().values.building_id,
                          buildings
                        )}
                        data={buildingsForInput}
                        isBuilding={true}
                        setSelectedDevice={setSelectedDevice}
                        setSelectedDeviceParent={setSelectedDeviceParent}
                        setSelectedBuildingId={
                          setSelectedBuildingId
                        }></AutoCompleteFormField>
                    </div>

                    <div className={classes.panel}>
                      {!alertDeviceType && (
                        <div className={classes.fieldset}>
                          <Alert severity="info">
                            Select an Alert Type Above
                          </Alert>
                        </div>
                      )}
                      {isFetchingDevices ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "25px 0"
                          }}>
                          <img
                            style={{ height: 33 }}
                            src={qlairLoader}
                            alt="Logo"
                          />
                        </div>
                      ) : (
                        <AutoCompleteFormField
                          form={form}
                          label={`Choose ${
                            DEVICE_PARENT_INFO?.[selectedAlertType]?.label ??
                            `AHU`
                          } `}
                          type={DEVICE_PARENT_INFO?.[selectedAlertType]?.label}
                          setSelectedDeviceParent={setSelectedDeviceParent}
                          setSelectedBuildingId={setSelectedBuildingId}
                          data={deviceParentOptions}
                          inputLabel={
                            (selectedDevice &&
                              deviceParentOptions?.find(
                                ({ id }) =>
                                  id ===
                                  selectedDevice[
                                    middleOptionId[selectedAlertType]
                                  ]
                              )?.name) ??
                            selectedDeviceParent?.label
                          }></AutoCompleteFormField>
                      )}
                      {isLoadingDevices || isFetchingDevices ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "25px 0"
                          }}>
                          <img
                            style={{ height: 33 }}
                            src={qlairLoader}
                            alt="Logo"
                          />
                        </div>
                      ) : (
                        <AutoCompleteFormField
                          form={form}
                          label="Choose sensor"
                          type="Sensor"
                          data={deviceOptions}
                          isSensor={true}
                          setSelectedDevice={setSelectedDevice}
                          setSelectedBuildingId={setSelectedBuildingId}
                          inputLabel={
                            selectedDevice?.label
                          }></AutoCompleteFormField>
                      )}
                    </div>

                    <Grid
                      container
                      columnSpacing={1}>
                      <Grid
                        item
                        xs={12}>
                        <div className={classes.panel}>
                          <Typography>Send Alert To:</Typography>
                          <FormControlLabel
                            control={<Checkbox sx={{ padding: "0 9px" }} />}
                            label="Email"
                            checked={formStateValues.email_enabled}
                            onChange={(_event, checked) => {
                              form.change(FormFields.EMAIL_ENABLED, checked);
                            }}
                          />
                          <FormControlLabel
                            control={<Checkbox sx={{ padding: "0 9px" }} />}
                            label="Text"
                            checked={formStateValues.text_message_enabled}
                            onChange={(_event, checked) => {
                              form.change(
                                FormFields.TEXT_MESSAGE_ENABLED,
                                checked
                              );
                            }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <div>
                      <Alert severity="info">
                        Note: This service generates alerts on an every 5 minute
                        basis
                      </Alert>
                    </div>
                  </div>
                  <div className={classes.actions}>
                    <Button
                      type="button"
                      variant="text"
                      onClick={() => form.reset()}>
                      Reset
                    </Button>
                    <Button
                      type="button"
                      onClick={close}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      disabled={hasValidationErrors}
                      variant="contained">
                      Save Changes
                    </Button>
                  </div>
                  <AqiReferenceTableComponent
                    measurements={alertDeviceType}
                    open={referenceOpen}
                    onClose={() => setReferenceOpen(false)}
                  />
                </form>
              );
            }}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default NotificationDrawerComponent;
