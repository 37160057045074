import { useMemo } from "react";
import { useGetAhusWithFilterLoadQuery } from "src/store/services/hvac/hvac.service";
import { useAppSelector } from "src/hooks/useAppSelector";
import LoaderComponent from "src/shared/components/loader/loader.component";
import useGetHvacView from "../hooks/useGetHvacView";
import Table from "src/shared/components/Table/Table";
import { getHvacSearchTerm } from "src/store/hvac/selectors";
import { useParams } from "react-router-dom";

export const AirHandlingUnits = () => {
  const { buildingId } = useParams();
  const airUnitsSearchTerm = useAppSelector(getHvacSearchTerm);
  const {
    data: ahusWithFilterLoad,
    isLoading,
    isFetching
  } = useGetAhusWithFilterLoadQuery(buildingId);
  const { isListViewActive } = useGetHvacView();
  const sortedAirHandelingUnits = useMemo(() => {
    if (!Boolean(ahusWithFilterLoad)) return [];
    if (airUnitsSearchTerm?.length)
      return ahusWithFilterLoad?.filter(({ name }) =>
        name.toLowerCase().includes(airUnitsSearchTerm)
      );

    return ahusWithFilterLoad;
  }, [airUnitsSearchTerm, isLoading, isFetching, buildingId]);

  return (
    <div data-testid="ahu">
      {isLoading || isFetching ? (
        <LoaderComponent />
      ) : (
        <Table
          showHeader={isListViewActive}
          showExtraActions={true}
          type="ahu"
          data={sortedAirHandelingUnits}></Table>
      )}
    </div>
  );
};

export const AirHandlingUnitsComponent = AirHandlingUnits;
