import { useEffect, useRef, useState } from "react";
import LoaderComponent from "src/shared/components/loader/loader.component";
import { Typography, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import LinkIcon from "@mui/icons-material/Link";
import useUserPascalSettings from "src/hooks/useUserPascalSettings";
import { getAhuPressureLineChartData } from "src/utils/pressure.utils";
import Error from "src/shared/components/Error/Error";
import useOverview from "src/hooks/useOverview";
import { useGetTimeSeriesQuery } from "src/store/services/widgets/widgets.service";
import useGetDataForAhu from "../hooks/useGetDataForAhu";
import { useStyles } from "./presure-curves-widget.style";
import { StageType } from "src/utils/client";
import { setSelectedOverviewTab } from "src/store/overview/reducer";
import {
  setSelectedAHU,
  setUserClickedOnWidgetTitle
} from "src/store/hvac/reducer";
import LineChart from "src/shared/components/line-chart/line-chart-component";
import { useNavigate } from "react-router-dom";
import { useOverviewTab } from "../../hooks/useGetOverviewTab";

type Props = {
  ahuId?: string;
  buildingId?: string;
  requiresConfig?: boolean;
  index?: number;
  hours?: number;
};

const PressureCurvesWidgetComponent = ({
  ahuId,
  buildingId,
  requiresConfig,
  hours
}: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { editing } = useOverview();
  const componentRef = useRef(null);
  const [chartData, setChartData] = useState(null);
  const isUserUsingPascal = useUserPascalSettings();
  const { tabId } = useOverviewTab();

  const {
    isLoading: isLoadingAhuData,
    isFetching: isFetchingAhuData,
    isError: isAhuError,
    refetchAhuData,
    ahu,
    building,
    stages
  } = useGetDataForAhu(buildingId, ahuId, requiresConfig);

  const airflowStage = stages?.find(
    stage => stage.stage_type === StageType.AIRFLOW
  );

  const {
    data: timeSeries,
    isLoading: isLoadingTimeSeries,
    isFetching: isFetchingTimeSeries,
    isError: isTimeSeriesError,
    refetch: timeSeriesRefetch
  } = useGetTimeSeriesQuery(
    { buildingId, ahuId, hours },
    {
      skip: requiresConfig
    }
  );

  const loading = isLoadingTimeSeries || isLoadingAhuData;
  const fetching = isFetchingAhuData || isFetchingTimeSeries;
  const error = isAhuError || isTimeSeriesError;

  const refetchAgain = () => {
    refetchAhuData();
    timeSeriesRefetch();
  };

  const dataForChartExist = Boolean(timeSeries) && Boolean(stages);
  const showDotsOnLine = false;

  const getChartData = () => {
    const timeValuesExist = Object.values(timeSeries).some(
      (s: any) => s.length
    );
    if (!timeValuesExist) return;
    const chartData = getAhuPressureLineChartData(
      stages,
      timeSeries,
      isUserUsingPascal,
      showDotsOnLine
    );
    setChartData(chartData);
  };

  useEffect(() => {
    if (!dataForChartExist) return;
    getChartData();
  }, [loading, dataForChartExist, ahuId, buildingId, fetching]);

  const handleOpenAhu = () => {
    if (!building || editing) return;
    dispatch(setSelectedAHU(ahu));
    dispatch(setSelectedOverviewTab(tabId));
    dispatch(setUserClickedOnWidgetTitle(true));
    navigate(`/buildings/hvac/${buildingId}/${ahuId}`);
  };

  return (
    <div
      data-testid="pressure-curves-widget"
      className={classes.root}
      ref={componentRef}>
      {requiresConfig ? (
        <>
          <Typography variant="h6">Filter Pressure</Typography>
          <Typography>Configuration is required</Typography>
        </>
      ) : loading ? (
        <LoaderComponent />
      ) : error ? (
        <Error
          setRefetchAgain={refetchAgain}
          error={error}></Error>
      ) : (
        <div className={classes.widget}>
          <div className={classes.header}>
            <Typography
              variant="h6"
              className={editing ? classes.default : classes.pointer}
              onClick={handleOpenAhu}>
              Filter Pressure: {ahu?.name}
              <LinkIcon
                fontSize="small"
                className={classes.linkIcon}
              />
            </Typography>
            {building && (
              <Tooltip
                title={building.location?.address}
                placement="top-end">
                <Typography
                  variant="h6"
                  className={classes.facilityName}>
                  {building.name}
                </Typography>
              </Tooltip>
            )}
          </div>
          <>
            {Boolean(chartData?.length) ? (
              <div className={classes.graphContainer}>
                <LineChart
                  data={chartData}
                  maintainAspectRatio={false}
                  showSecondYAxis={Boolean(airflowStage)}
                  enableZoom={false}
                  isWidget={true}
                />
              </div>
            ) : (
              <div className={classes.noDataContainer}>
                <>
                  <Typography
                    gutterBottom
                    variant="subtitle1">
                    NO DEVICE DATA AVAILABLE
                  </Typography>
                  <Typography variant="body2">
                    Please check that your device has its connection
                  </Typography>
                </>
              </div>
            )}
          </>
        </div>
      )}
    </div>
  );
};

export default PressureCurvesWidgetComponent;
