/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AggGranularity } from '../models/AggGranularity';
import type { AggItemAndScores } from '../models/AggItemAndScores';
import type { AirPollutionsLevelsResponse } from '../models/AirPollutionsLevelsResponse';
import type { AqDataAndScores } from '../models/AqDataAndScores';
import type { BuildingAvgPerformanceResponse } from '../models/BuildingAvgPerformanceResponse';
import type { BuildingOverviewRow } from '../models/BuildingOverviewRow';
import type { LongTermResponse } from '../models/LongTermResponse';
import type { Score } from '../models/Score';
import type { ScoresAndRecommendations } from '../models/ScoresAndRecommendations';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AqDataService {
  /**
   * Get Aq Device Data
   * Returns aggregated aq data for a device for the given range.
   * If `end_ts` is `None`, current time is used.
   *
   * Args:
   * use_scores: the performance scores to calculate based on the aq data packets.
   * Scores that can't be calculated, e.g. from missing pollutants needed for that
   * score's calculation, will be returned with the None/null value.
   *
   * `start_ts` and `end_ts` can be given as Unix timestamps integers
   * or floats in seconds or milliseconds, or as isoformat strings.
   * ```
   * Examples:
   * 1677827157                       (seconds)
   * 1677827157.5451648               (seconds)
   * 1677827157545                    (milliseconds)
   * 2023-03-03T07:05:57.545165+00:00 (isoformat)
   * 2023-03-03T02:05:57.545165+05:00 (isoformat)
   * ```
   * All of the above generate the same timestamp in the backend
   * (except where minor place values were omitted).
   * @param deviceId
   * @param buildingId
   * @param startTs
   * @param endTs
   * @param granularity
   * @param useScores
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getAqDeviceData(
    deviceId: string,
    buildingId: string,
    startTs: string,
    endTs?: (string | null),
    granularity?: AggGranularity,
    useScores?: (Array<Score> | null),
  ): CancelablePromise<(Array<AggItemAndScores> | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/aq-data/{device_id}',
      path: {
        'device_id': deviceId,
        'building_id': buildingId,
      },
      query: {
        'start_ts': startTs,
        'end_ts': endTs,
        'granularity': granularity,
        'use_scores': useScores,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Raw Aq Device Data
   * Returns raw aq data for the device for the given range.
   * If `end_ts` is `None`, current time is used.
   *
   * Args:
   * use_scores: the performance scores to calculate based on the aq data packets.
   * Scores that can't be calculated, e.g. from missing pollutants needed for that
   * score's calculation, will be returned with the None/null value.
   *
   * `start_ts` and `end_ts` can be given as Unix timestamps integers
   * or floats in seconds or milliseconds, or as isoformat strings.
   * ```
   * Examples:
   * 1677827157                       (seconds)
   * 1677827157.5451648               (seconds)
   * 1677827157545                    (milliseconds)
   * 2023-03-03T07:05:57.545165+00:00 (isoformat)
   * 2023-03-03T02:05:57.545165+05:00 (isoformat)
   * ```
   * All of the above generate the same timestamp in the backend
   * (except where minor place values were omitted).
   * @param deviceId
   * @param buildingId
   * @param startTs
   * @param endTs
   * @param useScores
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getRawAqDeviceData(
    deviceId: string,
    buildingId: string,
    startTs: string,
    endTs?: (string | null),
    useScores?: (Array<Score> | null),
  ): CancelablePromise<(Array<AqDataAndScores> | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/aq-data/{device_id}/raw/',
      path: {
        'device_id': deviceId,
        'building_id': buildingId,
      },
      query: {
        'start_ts': startTs,
        'end_ts': endTs,
        'use_scores': useScores,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Download Raw Csv
   * Export raw aq data to csv for a aq device
   * @param deviceId
   * @param buildingId
   * @param startTs
   * @param endTs
   * @returns any Successful Response
   * @throws ApiError
   */
  public static downloadRawCsv(
    deviceId: string,
    buildingId: string,
    startTs: string,
    endTs?: (string | null),
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/aq-data/{device_id}/raw/csv/',
      path: {
        'device_id': deviceId,
        'building_id': buildingId,
      },
      query: {
        'start_ts': startTs,
        'end_ts': endTs,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Latest Aq Device Data Point
   * Get the most recent raw sensor measurements for a device.
   *
   * Args:
   * use_scores: the performance scores to calculate based on the aq data packets.
   * Scores that can't be calculated, e.g. from missing pollutants needed for that
   * score's calculation, will be returned with the None/null value.
   * @param deviceId
   * @param buildingId
   * @param useScores
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getLatestAqDeviceDataPoint(
    deviceId: string,
    buildingId: string,
    useScores?: (Array<Score> | null),
  ): CancelablePromise<(AqDataAndScores | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/aq-data/{device_id}/latest/',
      path: {
        'device_id': deviceId,
        'building_id': buildingId,
      },
      query: {
        'use_scores': useScores,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Long Term Aq Data
   * Returns AQ pollutant data (no scores) averged weekly (over all aq devices in the building) over the provided time range.
   *
   * start_ts: MyDatetime,
   * end_ts: MyDatetime | None = None,
   * If `end_ts` is `None`, current time is used.
   *
   * `start_ts` and `end_ts` can be given as Unix timestamps integers
   * or floats in seconds or milliseconds, or as isoformat strings.
   * ```
   * Examples:
   * 1677827157                       (seconds)
   * 1677827157.5451648               (seconds)
   * 1677827157545                    (milliseconds)
   * 2023-03-03T07:05:57.545165+00:00 (isoformat)
   * 2023-03-03T02:05:57.545165+05:00 (isoformat)
   * 2023-03-03T07:05:57.545165       (isoformat)
   * 2023-03-03T07:05:57              (isoformat)
   * ```
   * All of the above generate the same timestamp in the backend
   * (except where minor place values were omitted).
   *
   * If the timezone (e.g. +00:00) is omitted, UTC timezone is assumed.
   * @param buildingId
   * @param startTs
   * @param endTs
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getLongTermAqData(
    buildingId: string,
    startTs: string,
    endTs?: (string | null),
  ): CancelablePromise<(LongTermResponse | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/aq-data/long-term/',
      path: {
        'building_id': buildingId,
      },
      query: {
        'start_ts': startTs,
        'end_ts': endTs,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Building Overview Aq Data
   * Returns AQ pollutant data (no scores) averged over the provided time range for all aq devices in the building,
   * as well as the corresponding AQI category.
   *
   * start_ts: MyDatetime,
   * end_ts: IsoDatetime | None = None,
   * If `end_ts` is `None`, current time is used.
   *
   * `start_ts` and `end_ts` can be given as Unix timestamps integers
   * or floats in seconds or milliseconds, or as isoformat strings.
   * ```
   * Examples:
   * 1677827157                       (seconds)
   * 1677827157.5451648               (seconds)
   * 1677827157545                    (milliseconds)
   * 2023-03-03T07:05:57.545165+00:00 (isoformat)
   * 2023-03-03T02:05:57.545165+05:00 (isoformat)
   * 2023-03-03T07:05:57.545165       (isoformat)
   * 2023-03-03T07:05:57              (isoformat)
   * ```
   * All of the above generate the same timestamp in the backend
   * (except where minor place values were omitted).
   *
   * If the timezone (e.g. +00:00) is omitted, UTC timezone is assumed.
   * @param buildingId
   * @param startTs
   * @param endTs
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getBuildingOverviewAqData(
    buildingId: string,
    startTs: string,
    endTs?: (string | null),
  ): CancelablePromise<(Array<BuildingOverviewRow> | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/aq-data/overview/',
      path: {
        'building_id': buildingId,
      },
      query: {
        'start_ts': startTs,
        'end_ts': endTs,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Building Air Pollution Levels
   * Returns AQ pollutant data (no scores) averged over the provided time range for all aq devices in the building,
   * as well as the corresponding AQI category.
   *
   * start_ts: MyDatetime,
   * end_ts: MyDatetime | None = None,
   * If `end_ts` is `None`, current time is used.
   *
   * `start_ts` and `end_ts` can be given as Unix timestamps integers
   * or floats in seconds or milliseconds, or as isoformat strings.
   * ```
   * Examples:
   * 1677827157                       (seconds)
   * 1677827157.5451648               (seconds)
   * 1677827157545                    (milliseconds)
   * 2023-03-03T07:05:57.545165+00:00 (isoformat)
   * 2023-03-03T02:05:57.545165+05:00 (isoformat)
   * 2023-03-03T07:05:57.545165       (isoformat)
   * 2023-03-03T07:05:57              (isoformat)
   * ```
   * All of the above generate the same timestamp in the backend
   * (except where minor place values were omitted).
   *
   * If the timezone (e.g. +00:00) is omitted, UTC timezone is assumed.
   * @param buildingId
   * @param startTs
   * @param endTs
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getBuildingAirPollutionLevels(
    buildingId: string,
    startTs: string,
    endTs?: (string | null),
  ): CancelablePromise<(AirPollutionsLevelsResponse | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/aq-data/air-pollution-levels/',
      path: {
        'building_id': buildingId,
      },
      query: {
        'start_ts': startTs,
        'end_ts': endTs,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Building Iaq Avg Performance
   * Returns the average performace scores and their categories ("Good", "Moderate", "Hazardous")
   * over all devices in the building for the given time range.
   *
   * Args:
   * use_scores: the performance scores to calculate based on the aq data packets.
   * Scores that can't be calculated, e.g. from missing pollutants needed for that
   * score's calculation, will be returned with the None/null value.
   * Defaults to all performance scores we have.
   *
   * If `end_ts` is `None`, current time is used.
   *
   * `start_ts` and `end_ts` can be given as Unix timestamps integers
   * or floats in seconds or milliseconds, or as isoformat strings.
   * ```
   * Examples:
   * 1677827157                       (seconds)
   * 1677827157.5451648               (seconds)
   * 1677827157545                    (milliseconds)
   * 2023-03-03T07:05:57.545165+00:00 (isoformat)
   * 2023-03-03T02:05:57.545165+05:00 (isoformat)
   * 2023-03-03T07:05:57.545165       (isoformat)
   * 2023-03-03T07:05:57              (isoformat)
   * ```
   * All of the above generate the same timestamp in the backend
   * (except where minor place values were omitted).
   *
   * If the timezone (e.g. +00:00) is omitted, UTC timezone is assumed.
   * @param buildingId
   * @param startTs
   * @param endTs
   * @param useScores
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getBuildingIaqAvgPerformance(
    buildingId: string,
    startTs: string,
    endTs?: (string | null),
    useScores?: (Array<Score> | null),
  ): CancelablePromise<(BuildingAvgPerformanceResponse | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/aq-data/building-avg-performance/',
      path: {
        'building_id': buildingId,
      },
      query: {
        'start_ts': startTs,
        'end_ts': endTs,
        'use_scores': useScores,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Filtration Efficiency Score And Recommendations
   * @param buildingId
   * @returns ScoresAndRecommendations Successful Response
   * @throws ApiError
   */
  public static getFiltrationEfficiencyScoreAndRecommendations(
    buildingId: string,
  ): CancelablePromise<ScoresAndRecommendations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/metrics/filtration-efficiency/',
      query: {
        'building_id': buildingId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Cotwo Decay Score And Recommendations
   * @param buildingId
   * @returns ScoresAndRecommendations Successful Response
   * @throws ApiError
   */
  public static getCotwoDecayScoreAndRecommendations(
    buildingId: string,
  ): CancelablePromise<ScoresAndRecommendations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/metrics/cotwo-decay/',
      query: {
        'building_id': buildingId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
