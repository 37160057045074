/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcceptInvitationPostBody } from '../models/AcceptInvitationPostBody';
import type { CompanyInvitation } from '../models/CompanyInvitation';
import type { CompanyMembership } from '../models/CompanyMembership';
import type { CreateInvitationPostBody } from '../models/CreateInvitationPostBody';
import type { VerifyInvitationSessionBody } from '../models/VerifyInvitationSessionBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CompanyInvitationsService {
  /**
   * Invite User To Company
   * The `current_user` is the one making the api request, and thus, the user who is inviting someone else to join.
   *
   * If the `new_invitation` is to an existing qlair user to join a company, then the
   * membership to that company is created immediately and an email is sent to the
   * invitee as a notification.
   *
   * If the `new_invitation` is to an email not associated with a qlair user, then
   * an email is sent asking the invitee to register an account with qlair.
   *
   * In the future, we should probably require an acceptance email for both existing and new users,
   * instead of automatically creating a membership for existing users. That prevents the (unlikely) case
   * of a company admin accidentally/misusing the invitation feature. Plus, it would make the two cases in
   * this function follow a more similar flow.
   * @param companyId
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static inviteUserToCompany(
    companyId: string,
    requestBody: CreateInvitationPostBody,
  ): CancelablePromise<(CompanyMembership | CompanyInvitation)> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/companies/{company_id}/invitations/',
      path: {
        'company_id': companyId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Faile to commit to Db`,
        404: `Failed to create invitation`,
        422: `Validation Error`,
        500: `Failed to create invitation`,
      },
    });
  }
  /**
   * Get All Invitations
   * @param companyId
   * @returns CompanyInvitation Successful Response
   * @throws ApiError
   */
  public static getAllInvitations(
    companyId: string,
  ): CancelablePromise<Array<CompanyInvitation>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/companies/{company_id}/invitations/',
      path: {
        'company_id': companyId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Revoke Invitation
   * @param companyId
   * @param invitationId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static revokeInvitation(
    companyId: string,
    invitationId: string,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/companies/{company_id}/invitations/{invitation_id}',
      path: {
        'company_id': companyId,
        'invitation_id': invitationId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Verify Invitation Session
   * @param requestBody
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public static verifyInvitationSession(
    requestBody: VerifyInvitationSessionBody,
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/verify-invitation-session/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Invalid token.`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Accept Invitation
   * @param requestBody
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public static acceptInvitation(
    requestBody: AcceptInvitationPostBody,
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/invitation-accept/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Invitation not found`,
        422: `Validation Error`,
        500: `Failed to create user`,
      },
    });
  }
}
