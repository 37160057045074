import rootReducer from "./rootReducer";
import { setupListeners } from "@reduxjs/toolkit/query";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { configureStore as configure } from "@reduxjs/toolkit";

import ReduxThunk from "redux-thunk";
import { apiService } from "./services/api";

const persistConfigToolkit = {
  key: "root",
  storage,
  blacklist: [apiService.reducerPath]
};

const persistedReducer = persistReducer(persistConfigToolkit, rootReducer);

const middlewares = [ReduxThunk, apiService.middleware];

const store = configure({
  reducer: persistedReducer,

  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false
    }).concat(middlewares)
});

const persistor = persistStore(store);
setupListeners(store.dispatch);
export { store, persistor, middlewares };

export type AppDispatch = typeof store.dispatch;
