import LoaderComponent from "src/shared/components/loader/loader.component";
import Wrapper from "src/sections/session/session-wrapper/session-wrapper.component";
import CustomTextField from "src/shared/components/custom-input/custom-input.component";
import toast from "react-hot-toast";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { makeStyles } from "tss-react/mui";
import { useState } from "react";
import { Box, Button, Typography, Link } from "@mui/material";
import { Field, Form } from "react-final-form";
import { RecoverPasswordFileds } from "src/shared/types/login.type";
import { AccountsService } from "src/utils/client";
import { useNavigate } from "react-router-dom";

type Props = {
  createAccount?: boolean;
};

const RecoverPasswordComponent = (props: Props) => {
  const { createAccount } = props;
  const navigate = useNavigate();
  const { classes } = useStyles();
  const [loading, setLoading] = useState(false);

  const validate = (values: RecoverPasswordFileds) => {
    const errors: RecoverPasswordFileds = {};
    const { email } = values;
    if (!email) {
      errors.email = "Required";
    }
    return errors;
  };

  const submit = (values: RecoverPasswordFileds) => {
    let { email } = values;
    setLoading(true);

    if (createAccount) {
      AccountsService.sendEmailVerificationCode({ email })
        .then(() => {
          toast.success("Verification code has been sent");
          navigate(`/check-code?email=${email}&newAccount=true`);
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      AccountsService.sendResetPasswordVerificationCode({ email })
        .then(() => {
          toast.success("Verification code has been sent");
          navigate(`/check-code?email=${email}`);
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Wrapper>
      <Form
        onSubmit={submit}
        validate={validate}
        render={({ handleSubmit, hasValidationErrors }) => (
          <form
            data-testid="recover-password"
            className={classes.recoverForm}
            onSubmit={handleSubmit}>
            {loading && <LoaderComponent />}
            <Box mb={5}>
              <Typography variant="h4">
                {createAccount ? "Register" : "Recover Password"}
              </Typography>
              <Typography variant="subtitle1">
                Manage air quality effectively
              </Typography>
            </Box>

            <div className={classes.fieldContainer}>
              <Field name="email">
                {({ input, meta }) => (
                  <CustomTextField
                    label={createAccount ? "Your Email" : "Email or username"}
                    error={Boolean(meta.touched && meta.error)}
                    helperText={meta.touched && meta.error}
                    InputProps={input}
                    placeholder="Enter your email"
                  />
                )}
              </Field>
            </div>

            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={hasValidationErrors || loading}
              className={classes.resetBtn}
              data-testid="send-btn">
              Send
              <ArrowForwardRoundedIcon sx={{ marginLeft: 1, fontSize: 16 }} />
            </Button>

            <Box mt={2}>
              <Typography variant="body2">
                A verification token will be sent to your email address
              </Typography>
            </Box>
          </form>
        )}
      />
      <Box className={classes.getHelp}>
        <Typography
          variant="body1"
          className={classes.supportText}>
          Get help to choose a package that suits you by contacting the
        </Typography>
        <Link href="mailto:support@i-qlair.com?subject=Help With Account">
          "MANN+HUMMEL Support"
        </Link>
      </Box>
      <div className={classes.extraOptions}>
        <Typography
          variant="body1"
          className={classes.supportText}>
          Already have an account?{" "}
          <Link
            onClick={() => {
              navigate("/login");
            }}>
            Sign In
          </Link>
        </Typography>
        {!createAccount && (
          <>
            <Typography variant="body1">
              Don't have an account yet?{" "}
              <Link
                onClick={() => {
                  navigate("/create-account");
                }}>
                Create Account
              </Link>
            </Typography>
          </>
        )}
      </div>
    </Wrapper>
  );
};

export const useStyles = makeStyles()(() => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center"
  },
  mainColumn: {
    paddingLeft: 24,
    paddingRight: 24
  },
  recoverForm: {
    maxWidth: 350,
    height: 300,
    display: "flex",
    flexDirection: "column" as const,
    alignSelf: "center",
    justifyContent: "space-between",
    position: "relative" as const
  },
  getHelp: {
    marginTop: "3em",
    textAlign: "center" as const,
    maxWidth: 350
  },
  extraOptions: {
    marginTop: 24,
    textAlign: "center" as const,
    maxWidth: 350
  },
  supportText: {
    marginTop: 12,
    marginBottom: 12,
    lineHeight: 1.5
  },
  fieldContainer: {
    height: 64,
    display: "grid"
  },
  resetBtn: {
    marginTop: "1em"
  }
}));

export default RecoverPasswordComponent;
