import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  Box,
  SelectChangeEvent
} from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";

import {
  useGetPaintBoothSetPointsQuery,
  useGetUserSetPointQuery,
  useSaveUserSetPointMutation
} from "src/store/services/booth/booth.service";
import useGetWidgetsForPage from "../../hooks/useGetPageData";
import moment from "moment";
import { NA } from "src/shared/constants/generals";
import LoaderComponent from "src/shared/components/loader/loader.component";

const PaintBoothSelect = () => {
  const { paintBoothWidgets } = useGetWidgetsForPage();

  const buildingId = paintBoothWidgets?.at(0)?.props.buildingId;
  const boothId = paintBoothWidgets?.at(0)?.props.paintBoothId;
  const { data: userSelectedSetPoint, isLoading: isLoadingUserSelectedPoint } =
    useGetUserSetPointQuery(
      {
        buildingId,
        boothId
      },
      { skip: !buildingId || !boothId }
    );

  const { data: setpoints, isLoading: isLoadingSetPoints } =
    useGetPaintBoothSetPointsQuery(
      {
        buildingId,
        boothId,
        startTs: moment().subtract(2, "months").format()
      },
      { skip: !buildingId || !boothId }
    );

  const [saveUserSetPoint, { isLoading: isSaving }] =
    useSaveUserSetPointMutation();

  const loading = isLoadingUserSelectedPoint || isLoadingSetPoints || isSaving;

  const hanleOnChange = (event: SelectChangeEvent) => {
    const selectedSetPoint = setpoints.find(
      ({ title }) => title === event.target.value
    );
    saveUserSetPoint({
      buildingId: selectedSetPoint.building_id,
      boothId: selectedSetPoint.booth_id,
      created_at: selectedSetPoint.created_at
    });
  };
  return (
    <>
      {loading ? (
        <Box sx={{ position: "relative" }}>
          <LoaderComponent customWidth={40}></LoaderComponent>
        </Box>
      ) : (
        <FormControl sx={{ top: "12px" }}>
          <InputLabel>Select Set Point</InputLabel>
          <Select
            label="Select Set Point"
            value={userSelectedSetPoint?.title}
            sx={{ minWidth: 200 }}
            onChange={hanleOnChange}>
            {setpoints?.map((point, index) => (
              <MenuItem
                key={`setpoint-${point.title}`}
                value={point.title}>
                <Tooltip
                  key={`tooltip-${index}`}
                  sx={{
                    bgcolor: "common.white",
                    "& .MuiTooltip-arrow": {
                      color: "common.white"
                    }
                  }}
                  componentsProps={{
                    popper: {
                      sx: {
                        [`& .${tooltipClasses.arrow}`]: {
                          color: theme => theme.palette.common.white
                        },
                        [`& .${tooltipClasses.tooltip}`]: {
                          backgroundColor: theme => theme.palette.common.white,
                          color: theme => theme.palette.common.black
                        }
                      }
                    }
                  }}
                  title={getSetPointInfo(
                    moment(point.created_at).format("MMM DD HH:MM"),
                    moment(point.set_point_time).format("MMM DD HH:MM"),
                    point.user.name,
                    point.notes ?? NA
                  )}
                  placement="left">
                  <span>{point.title}</span>
                </Tooltip>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export default PaintBoothSelect;

const getSetPointInfo = (
  created_at: string,
  set_point_time: string,
  user: string,
  comment: string
) => {
  return (
    <Grid
      container
      sx={{ padding: "8px" }}>
      <Grid container>
        <Grid xs={6}>
          <Typography sx={{ color: "#c8c8c9" }}>Set Point Date</Typography>
        </Grid>
        <Grid xs={6}>
          <Typography align="right">{set_point_time} </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid xs={6}>
          <Typography sx={{ color: "#c8c8c9" }}>Created at</Typography>
        </Grid>
        <Grid xs={6}>
          <Typography align="right">{created_at} </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid xs={6}>
          <Typography sx={{ color: "#c8c8c9" }}>User</Typography>
        </Grid>
        <Grid xs={6}>
          <Typography align="right">{user}</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Typography sx={{ color: "#c8c8c9" }}>Comment</Typography>
      </Grid>
      <Grid container>
        <Grid xs={12}>
          <Typography>{comment}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
