export const styles = () => {
  return {
    root: {
      position: "relative",
      width: "100%",
      height: "100%",
      padding: "10px",
      display: "flex",
      ".MuiPaper-root": {
        boxShadow: "none !important"
      }
    },
    card: {
      padding: 0,
      marginBottom: 0,
      height: "100%"
    },
    accordion: {
      boxShadow: "none",
      marginTop: "0 !important"
    },
    cardHeader: {
      justifyContent: "end",
      padding: 0
    },
    missing: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center"
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "10px"
    },
    default: {
      cursor: "default",
      display: "flex",
      gap: "4px"
    },
    pointer: {
      cursor: "pointer",
      display: "flex",
      gap: "4px",
      "&:hover": { color: "#0795bb" }
    },
    linkIcon: {
      margin: "auto",
      transform: "rotate(-30deg)",
      fontSize: 15,
      color: "#a9a9a9"
    },
    headerWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    headerItem: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    rowContainer: {
      minHeight: "32px",
      padding: "8px 8px 8px 0px",
      border: "1px solid rgba(0,0,0,0.07)",
      borderRadius: "10px",
      boxShadow: "0px 5px 10px rgba(0,0,0,0.2)",
      background: "rgba(0,0,0,0.02)",
      marginBottom: "15px",
      fontStyle: "italic",
      "&:hover": {
        boxShadow: "0px 7px 12px rgba(0,0,0,0.2)",
        background: "rgba(0,0,0,0.05)"
      }
    },
    rowItem: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }
  };
};
