import ModalComponent from "../../modal/modal.component";
import LoaderComponent from "../../loader/loader.component";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { makeStyles } from "tss-react/mui";
import { renderWeekPickerDay } from "src/utils/date-picker.utils";
import { useAppSelector } from "src/hooks/useAppSelector";

import { useAppDispatch } from "src/hooks/useAppDispatch";
import { handleDownloadModal } from "src/store/hvac/reducer";
import { getSelectedBooth } from "src/store/booth/selector";
import moment from "moment";
import { APIv2 } from "src/utils/apiv2";
import { useState } from "react";

import {
  getDownloadModalEntity,
  getDownloadModalEntityId,
  getDownloadModalState
} from "src/store/hvac/selectors";
import { useParams } from "react-router-dom";
import useGetBuildings from "src/hooks/useGetBuildings";

export const DownloadDataModal = () => {
  const { buildingId } = useParams();
  const dispatch = useAppDispatch();
  const { building } = useGetBuildings(buildingId);
  const booth = useAppSelector(getSelectedBooth);
  const open = useAppSelector(getDownloadModalState);
  const featureType = useAppSelector(getDownloadModalEntity);
  const deviceId = useAppSelector(getDownloadModalEntityId);
  const { classes } = useStyles();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment().subtract(2, "weeks"));
  const [endDate, setEndDate] = useState(moment());

  const close = () => {
    dispatch(handleDownloadModal(false));
  };

  const getPromise = (
    buildingId: string,
    entity_type: "booth" | "ahu" | "kettle" | "building" | "stage",
    entityId: string,
    startDate: string,
    endDate: string
  ) => {
    if (entity_type === "booth") {
      const params = {
        building_id: buildingId,
        booth_id: booth.id,
        start_ts: startDate,
        end_ts: endDate
      };
      return returnPromise("/booth-data-export/", params);
    }
    const body = {
      id: entityId,
      entity_type,
      time_range: {
        start: startDate,
        end: endDate
      }
    };
    const params = {
      company_id: building.companyId,
      building_id: buildingId
    };
    return APIv2.post("/hvac-export", body, { params, responseType: "blob" });
  };

  const getReport = async () => {
    setLoading(true);
    const response = await getPromise(
      buildingId,
      featureType,
      deviceId,
      moment(startDate).format(),
      moment(endDate).format()
    );
    try {
      const filename = `${featureType}_${deviceId}_report.xlsx`;
      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");

      link.href = href;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <ModalComponent
      open={open}
      onClose={close}>
      <div data-testid="download-data-modal">
        {loading && (
          <div className={classes.loader}>
            <LoaderComponent />
          </div>
        )}
        <div>
          <Typography variant="h6">Download Data</Typography>
        </div>
        <div className={classes.body}>
          <Grid
            container
            columnSpacing={1}>
            <Grid
              item
              xs={12}
              sm={6}>
              <DatePicker
                label="Start Date"
                value={startDate}
                renderDay={renderWeekPickerDay}
                onChange={newValue => {
                  setStartDate(newValue);
                }}
                renderInput={params => <TextField {...params} />}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}>
              <DatePicker
                label="End Date"
                value={endDate}
                renderDay={renderWeekPickerDay}
                onChange={newValue => {
                  setEndDate(newValue);
                }}
                renderInput={params => <TextField {...params} />}
              />
            </Grid>
          </Grid>
        </div>
        <div className={classes.actions}>
          <Button
            data-testid="download-btn"
            onClick={getReport}
            variant="contained"
            disabled={!endDate || !startDate || loading}>
            Download
          </Button>
        </div>
      </div>
    </ModalComponent>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    body: {
      marginTop: "1em"
    },
    actions: {
      marginTop: "1em"
    },
    loader: {
      position: "absolute" as const,
      width: "100%",
      height: "100%",
      top: 0,
      left: 0
    },
    csvBtn: {
      width: "fit-content"
    }
  };
});

export const DownloadDataModalComponent = DownloadDataModal;

const returnPromise = (url: string, params: any) =>
  APIv2.get(url, { params, responseType: "blob" });
