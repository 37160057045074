import { useGetBuildingAqDataQuery } from "src/store/services/air-quality/air-quality.service";
import {
  TimeRangeObject,
  timeRangeFetchMethod
} from "../widgets/aq-building-with-time-settings/aq-building-with-time-settings.component";
import { getStartDate } from "../utils/time-span-utils";

const useGetBuildingAQData = (
  buildingId: string,
  range: TimeRangeObject,
  requiresConfig: boolean
) => {
  const query =
    range?.method === timeRangeFetchMethod.CUSTOM_DATES
      ? { buildingId, startDate: range?.startDate, endDate: range?.endDate }
      : {
          buildingId,
          startDate: String(getStartDate(range?.timeSpan, range?.timeQuantity))
        };

  const {
    data: buildingAqData,
    isLoading,
    isError,
    isFetching,
    refetch
  } = useGetBuildingAqDataQuery(query, { skip: requiresConfig });

  return {
    buildingAqData,
    isLoading,
    isFetching,
    isError,
    refetch
  };
};

export default useGetBuildingAQData;
