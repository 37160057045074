import { combineReducers } from "redux";
import { authReducer } from "./auth/reducer";
import { notificationReducer } from "./notification/reducer";
import { apiService } from "./services/api";
import { buildingsReducer } from "./buildings/reducer";
import { hvacReducer } from "./hvac/reducer";
import { serviceLogReducer } from "./servicelogs/reducer";
import { reportsReducer } from "./reports/reducer";
import { kettleReducer } from "./kettle/reducer";
import { boothReducer } from "./booth/reducer";
import { downloadReducer } from "./download/reducer";
import { buildingCostReducer } from "./building-cost/reducer";
import { overviewReducer } from "./overview/reducer";

const rootReducer = combineReducers({
  [apiService.reducerPath]: apiService.reducer,
  auth: authReducer,
  buildings: buildingsReducer,
  notification: notificationReducer,
  hvac: hvacReducer,
  serviceLog: serviceLogReducer,
  reports: reportsReducer,
  kettle: kettleReducer,
  booth: boothReducer,
  download: downloadReducer,
  buildingCost: buildingCostReducer,
  overview: overviewReducer
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
