import { useGetOutdoorAqDataQuery } from "src/store/services/air-quality/air-quality.service";
import moment from "moment";

const useGetOutdoorAqData = (buildingId: string, requiresConfig: boolean) => {
  const twoWeeksAgo = String(
    moment().subtract(2, "weeks").startOf("day").valueOf()
  );
  const {
    data: outdoorAqData,
    isLoading,
    isError,
    isFetching,
    refetch
  } = useGetOutdoorAqDataQuery(
    {
      buildingId,
      startDate: twoWeeksAgo
    },
    { skip: requiresConfig }
  );

  return {
    outdoorAqData,
    isLoading,
    isFetching,
    isError,
    refetch
  };
};

export default useGetOutdoorAqData;
