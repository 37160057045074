import { PickersDay, PickersDayProps } from "@mui/x-date-pickers";

export const renderWeekPickerDay = (
  ...props: [
    day: any,
    selectedDays: any[],
    pickersDayProps: PickersDayProps<any>
  ]
) => {
  const [_, __, pickersDayProps] = props;
  return (
    <PickersDay
      data-testid="picker-item"
      translate="no"
      {...pickersDayProps}
    />
  );
};
