import classnames from "classnames";
import EditIcon from "@mui/icons-material/Edit";
import SignalWifiOffIcon from "@mui/icons-material/SignalWifiOff";
import { makeStyles } from "tss-react/mui";
import { getAQIStatus } from "src/utils/buildings.util";
import { RoomStatus } from "src/utils/client";

import { Button, Paper, Typography } from "@mui/material";
import { getAQValueFromRoom } from "src/utils/buildings.util";
import { DEFAULT } from "src/shared/constants/building-status";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import {
  handleRoomModal,
  setIsEditingRoom,
  setSelectedRoom
} from "src/store/hvac/reducer";
import { MouseEvent } from "react";
import AirQualityIndicator from "src/sections/buildings/air-quality-indicator/air-quality-indicator";
import { useNavigate } from "react-router-dom";

type Props = {
  room: RoomStatus;
};

export const KEY = "latest_aqi";

const RoomCardComponent = ({ room }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const status = getAQIStatus(room.latest_aqi);
  const hasOutdated = room.devices.some((item: any) => item.is_offline);
  const deviceCount = room.devices.length;
  const aqValue = getAQValueFromRoom(room, KEY);

  const handleChangeToDevices = () => {
    dispatch(setSelectedRoom(room.room));
    navigate(`rooms/${room.room.id}`);
  };

  const handleOpenRoomStatusModal = (e: MouseEvent) => {
    e.stopPropagation();
    dispatch(setIsEditingRoom(true));
    dispatch(setSelectedRoom(room.room));
    dispatch(handleRoomModal(true));
  };

  return (
    <>
      {Boolean(room.room.id) && (
        <Paper
          data-testid="room-card"
          className={classnames(classes.root)}
          onClick={handleChangeToDevices}>
          <div
            className={classnames(
              classes.statusIndicator,
              classes[`back${status.replace(/ /g, "_")}`]
            )}></div>
          <div className={classes.info}>
            <Typography variant="body1">Room name</Typography>
            <Typography
              variant="body1"
              data-testid="room-name">
              {room.room.name}
              {hasOutdated && (
                <SignalWifiOffIcon
                  data-testid="wifi-off-icon"
                  className={classes.offline}
                />
              )}
            </Typography>
          </div>
          <div className={classes.info}>
            <Typography variant="body1">Floor Name</Typography>
            <Typography variant="body1">{room.room.floor}</Typography>
          </div>
          <div className={classes.info}>
            <Typography variant="body1">Number of devices</Typography>
            <Typography variant="body1">{deviceCount} Devices</Typography>
          </div>
          <div className={classes.info}>
            <Typography variant="body1">Current AQI</Typography>
            {aqValue && <AirQualityIndicator value={aqValue} />}
          </div>
          <div className={classes.footer}>
            <Button
              data-testid="edit-button"
              variant="text"
              onClick={e => handleOpenRoomStatusModal(e)}>
              <EditIcon sx={{ fontSize: 14, marginRight: "0.5em" }} />
              <span>Edit</span>
            </Button>
          </div>
        </Paper>
      )}
    </>
  );
};

export const useStyles = makeStyles()(theme => {
  return {
    root: {
      position: "relative" as const,
      padding: 16,
      display: "flex",
      flexDirection: "column" as const,
      transition: "all 250ms",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: theme.palette.grey["200"]
      },
      overflow: "hidden",
      marginTop: 10,
      marginRight: 10
    },
    title: {
      whiteSpace: "nowrap" as const,
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer"
    },
    devices: {
      position: "relative" as const,
      top: -5,
      fontWeight: "bold" as const
    },
    qty: {
      display: "flex",
      flexDirection: "column" as const,
      alignItems: "center"
    },
    statusIndicator: {
      position: "absolute" as const,
      top: 0,
      left: 0,
      height: "100%",
      width: 8,
      backgroundColor: theme.palette.status[DEFAULT],
      zIndex: 2
    },
    footer: {
      backgroundColor: "#F8F9FF",
      fontSize: "13px",
      position: "relative" as const,
      top: 16,
      left: -16,
      padding: 8,
      width: "calc(100% + 32px)",
      display: "flex",
      justifyContent: "end"
    },
    info: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "0.25em",
      alignItems: "center"
    },

    offline: {
      color: "#d32f2f",
      fontSize: "medium",
      verticalAlign: "top"
    }
  };
});

export default RoomCardComponent;
