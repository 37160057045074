import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => {
  return {
    root: {
      height: "100%",
      padding: "10px"
    },
    widget: {
      height: "100%",
      padding: "5px 0 10px 0"
    },
    loader: {
      position: "absolute",
      top: 0,
      left: 0,
      height: "100%"
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    graphContainer: {
      height: "100%"
    },
    chartRoot: {
      height: "100%"
    },
    noDataContainer: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center"
    },
    facilityName: {
      display: "flex",
      color: "grey",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      margin: "0px",
      "@media(max-width:900px)": {
        maxWidth: 150,
        display: "block"
      }
    }
  };
});
