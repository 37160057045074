import { Typography, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useGetBuildings from "src/hooks/useGetBuildings";
import useOverview from "src/hooks/useOverview";
import { FormattedBuildingWithEntity } from "src/shared/types/building";
import { setSelectedBuilding } from "src/store/buildings/reducer";
import { setUserClickedOnWidgetTitle } from "src/store/hvac/reducer";
import { Building } from "src/utils/client";
import { makeStyles } from "tss-react/mui";

interface IWidgetTitle {
  error: boolean | null;
  building: Building | FormattedBuildingWithEntity;
}

const WidgetTitle = ({ error, building }: IWidgetTitle) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { editing } = useOverview();
  const [formattedBuilding, setFormatteBuilding] = useState(null);
  const { buildings } = useGetBuildings();

  useEffect(() => {
    const foundBuilding = buildings?.find(({ id }) => id === building?.id);
    setFormatteBuilding(foundBuilding);
  }, [building]);

  const handleClick = () => {
    if (editing) return;
    dispatch(setSelectedBuilding(formattedBuilding));
    dispatch(setUserClickedOnWidgetTitle(true));
    navigate(`/buildings/${building.id}/`);
  };

  return (
    <>
      {!error && Boolean(building) && (
        <div
          data-testid="building-btn"
          className={classes.facilityName}
          onClick={handleClick}>
          <Tooltip
            title={building.location?.address}
            placement="top-end">
            <Typography
              variant="h6"
              className={classes.title}>
              {building.name}
            </Typography>
          </Tooltip>
        </div>
      )}
    </>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    title: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      "@media(max-width:900px)": {
        maxWidth: 150,
        whiteSpace: "nowrap" as const,
        textOverflow: "ellipsis",
        overflow: "hidden",
        display: "block"
      }
    },
    facilityName: {
      color: "grey",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textAlign: "right",
      cursor: "pointer",
      position: "absolute",
      top: 11,
      right: 15
    }
  };
});
export default WidgetTitle;
