/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Unit } from './Unit';
export type PressureDelta = {
  units?: Unit;
  /**
   * This property means `PressureMeasurement` satisfies the same interface as the `Measurement`
   * model (having `units` and `value` attributes.)
   */
  readonly pressure_in: number;
  /**
   * This property means `PressureMeasurement` satisfies the same interface as the `Measurement`
   * model (having `units` and `value` attributes.)
   */
  readonly pressure_out: number;
  interpolated?: PressureDelta.interpolated;
  readonly pressure_delta: string;
  /**
   * This property means `KettlePressureDelta` satisfies the same interface as the `Measurement`
   * model (having `units` and `value` attributes.)
   */
  readonly value: string;
};
export namespace PressureDelta {
  export enum interpolated {
    IN = 'in',
    OUT = 'out',
  }
}

