import { Box, Grid, Typography } from "@mui/material";
import { EventType, Stage, StageType } from "../../../../../../utils/client";
import ConnectionAlertItem from "../grid-items/connection-item/connection-item.component";
import ReminderItem from "../grid-items/reminder-item/reminder-item.component";
import ServiceLogItem from "../grid-items/service-log-item/service-log-item.component";
import AlertItem from "../grid-items/threshold-alert-item/alert-item.component";
import HvacActivityPagination from "../pagination.component";
import qlairLoader from "src/assets/qlair-load.gif";

interface ActivityLogsTableProps {
  stages: Stage[];
  loading: boolean;
  pageData: any;
  filteredList: any;
  userHasFilteredData: boolean;
  setPageData: (data: any) => void;
  setOpen: (data: boolean) => void;
}

export default function ActivityLogsTable({
  stages,
  loading,
  pageData,
  filteredList,
  userHasFilteredData,
  setPageData,
  setOpen
}: ActivityLogsTableProps) {
  const handleRenderData = (item: any) => {
    const event = item?.event;
    switch (item.event_type) {
      case EventType.THRESHOLD_ALERT:
        return (
          <AlertItem
            alert={event}
            time={item?.time}
            stages={stages}
          />
        );
      case EventType.SERVICE_LOG:
        return (
          <ServiceLogItem
            event={event}
            stages={stages}
            setOpen={setOpen}
          />
        );
      case EventType.CONNECTION_ALERT:
        return (
          <ConnectionAlertItem
            alert={event}
            stages={stages}
            time={item?.time}
          />
        );
    }
  };

  if (loading) {
    return (
      <Box
        py={2}
        display={"flex"}
        data-testid="loader"
        justifyContent={"center"}>
        <img
          src={qlairLoader}
          alt="Logo"
        />
      </Box>
    );
  }

  return (
    <>
      <Grid
        container
        spacing={2}>
        {stages?.map((stage, index) => {
          if (!stage.filter && stage.stage_type !== StageType.AIRFLOW) {
            return (
              <ReminderItem
                stage={stage}
                key={`reminder_item_${index}`}
              />
            );
          }
        })}
        {pageData?.map(handleRenderData)}
      </Grid>
      {Boolean(pageData?.length) ? (
        <HvacActivityPagination
          hvacActivity={filteredList}
          setPageData={setPageData}
        />
      ) : (
        <Typography
          textAlign={"center"}
          my={3}>
          {userHasFilteredData
            ? "No activity that with those filters"
            : "No activity yet"}
        </Typography>
      )}
    </>
  );
}
