import { Button, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ModalComponent from "src/shared/components/modal/modal.component";
import { makeStyles } from "tss-react/mui";

import ConfirmDelete from "src/components/ConfirmDelete";
import { useAppSelector } from "src/hooks/useAppSelector";
import { useNavigate } from "react-router";
import useGetUserPages from "src/hooks/useGetUserWidgets";
import useOverview from "src/hooks/useOverview";
import { getUserId } from "src/store/auth/selectors";
import { useUpdateUsersPagesMutation } from "src/store/services/overview/overview.service";
import { v4 as uuid } from "uuid";

const PageDetailsModalComponent = () => {
  const userId = useAppSelector(getUserId);
  const { classes } = useStyles();
  const { pages } = useGetUserPages();
  const navigate = useNavigate();
  const [updateUsersPages] = useUpdateUsersPagesMutation();
  const { isPageModalOpen, editingTabIndex, closePageModal, setEditing } =
    useOverview();
  const [pageName, setPageName] = useState("");

  useEffect(() => {
    const pageName =
      editingTabIndex === null ? "" : pages[editingTabIndex]?.pageName;
    setPageName(pageName);
  }, [isPageModalOpen]);

  const close = () => {
    closePageModal();
    setEditing(false);
  };

  const handleOnDelete = () => {
    if (editingTabIndex === null) return;
    const newTabIndex = editingTabIndex > 1 ? editingTabIndex - 1 : 0;

    const updatedPages = pages.filter(
      ({ pageName }: { pageName: string }) =>
        pageName !== pages[editingTabIndex].pageName
    );
    updateUsersPages({ userId, updatedPages });
    navigate(`/dashboard/tab/${newTabIndex}`);
    close();
  };

  const handleOnSave = () => {
    if (editingTabIndex === null) {
      const newPage = {
        pageId: uuid(),
        pageName,
        widgets: []
      };
      const updatedPages = [...pages, newPage];

      updateUsersPages({ userId, updatedPages });
    } else {
      const activePage = pages[editingTabIndex];
      const updatedPages = pages.map(page => {
        if (
          (Boolean(activePage?.pageId) && activePage?.pageId === page.pageId) ||
          activePage.pageName === page.pageName
        ) {
          return {
            ...page,
            pageName
          };
        }
        return page;
      });
      updateUsersPages({ userId, updatedPages });
    }
    close();
  };

  return (
    <div>
      <ModalComponent
        open={isPageModalOpen}
        onClose={close}>
        <div
          data-testid="page-details-modal"
          className={classes.root}>
          <Typography variant="h6">Page configuration</Typography>
          <div>
            <TextField
              label="Name"
              className={classes.input}
              value={pageName}
              onChange={({ target: { value } }) => {
                setPageName(value);
              }}
            />
          </div>
          <div className={classes.actions}>
            {editingTabIndex !== null && (
              <ConfirmDelete onConfirm={handleOnDelete} />
            )}
            <Button
              data-testid="save-btn"
              disabled={!pageName}
              variant="contained"
              onClick={handleOnSave}>
              Save
            </Button>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {},
    actions: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "1em",
      gap: "1em"
    },
    loader: {
      position: "absolute",
      top: 0,
      left: 0
    },
    input: {
      width: "95%"
    }
  };
});

export default PageDetailsModalComponent;
