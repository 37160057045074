import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      alignItems: "center",
      padding: 8
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%"
    },
    widget: {
      display: "flex"
    },
    title: {
      cursor: "pointer",
      display: "block",
      fontWeight: 500,
      fontSize: "1rem",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      "&:hover": { color: "#0795bb" }
    },
    editingTitle: {
      cursor: "default",
      display: "block",
      fontWeight: 500,
      fontSize: "1rem",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis"
    },
    lastTimeUpdated: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "0.75rem",
      minWidth: "85px"
    },
    isInAlert: {
      border: "2px solid #d72a2f",
      borderRadius: 12
    },
    body: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    time: {
      fontSize: "0.75rem"
    },
    mainBody: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      margin: "5px 0 25px 0",
      width: "100%"
    },
    footer: {
      color: "#5a5b5d",
      fontWeight: 400
    }
  };
});
