import SetNewPasswordComponent from "../set-new-password/set-new-password.component";
import toast from "react-hot-toast";
import Wrapper from "src/sections/session/session-wrapper/session-wrapper.component";
import { alpha } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { useState } from "react";
import { Box, Link, Typography } from "@mui/material";
import { CompanyInvitationsService } from "src/utils/client";
import { useNavigate, useSearchParams } from "react-router-dom";

const InvitationComponent = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get("token");
  const { classes } = useStyles();

  const [, setLoading] = useState(false);
  const [validToken, setValidToken] = useState(false);
  const [, setTokenData] = useState(null);

  const verifyToken = () => {
    if (!token) {
      return null;
    }

    setLoading(true);

    CompanyInvitationsService.verifyInvitationSession({ session_token: token })
      .then(response => {
        setTokenData(response);
        toast.success("Invitation verified");
      })
      .catch(() => {
        toast.error("Invalid invitation code");
        navigate(`/login`);
      })
      .finally(() => {
        setLoading(false);
      });
    setValidToken(true);
    return null;
  };

  return (
    <Wrapper>
      {!validToken && verifyToken()}

      {validToken && (
        <SetNewPasswordComponent
          email={""}
          token={token}
          invitation={true}
        />
      )}
      <Box className={classes.getHelp}>
        <Typography
          variant="body1"
          className={classes.supportText}>
          Get help to choose a package that suits you by contacting the
        </Typography>
        <Link href="mailto:support@i-qlair.com?subject=Help With Account">
          "MANN+HUMMEL Support"
        </Link>
      </Box>
      <div className={classes.extraOptions}>
        <Typography
          variant="body1"
          className={classes.supportText}>
          Already have an account?{" "}
          <Link
            onClick={() => {
              navigate("/login");
            }}>
            Sign In
          </Link>
        </Typography>
      </div>
    </Wrapper>
  );
};

export const useStyles = makeStyles()(theme => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center"
  },
  mainColumn: {
    paddingLeft: 24,
    paddingRight: 24
  },
  verifyForm: {
    height: 280,
    maxWidth: 375,
    display: "flex",
    flexDirection: "column" as const,
    alignSelf: "center",
    justifyContent: "space-between",
    position: "relative" as const
  },
  fieldContainer: {
    height: 64,
    display: "grid"
  },
  getHelp: {
    marginTop: "3em",
    textAlign: "center" as const,
    maxWidth: 350
  },
  extraOptions: {
    marginTop: 24,
    textAlign: "center" as const,
    maxWidth: 350
  },
  supportText: {
    marginTop: 12,
    marginBottom: 12,
    lineHeight: 1.5
  },
  verificationBtn: {
    marginTop: "1em"
  },
  tokenInput: {
    "& .MuiInputBase-input": {
      borderRadius: 8,
      textAlign: "center" as const,
      position: "relative",
      backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
      width: "100%",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow"
      ]),
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main
      }
    }
  }
}));

export default InvitationComponent;
