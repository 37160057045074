import { useState } from "react";
import { makeStyles } from "tss-react/mui";
import { WidgetSettings } from "../widgets-config";
import moment from "moment";
import Error from "src/shared/components/Error/Error";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  CircularProgress
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import useGetBuildings from "src/hooks/useGetBuildings";
import { getRange } from "../../utils/time-span-utils";
import FormInput from "src/shared/components/form-input/form-input.component";
import FormAction from "src/shared/components/form-action/form-action";

export enum timeRangeFetchMethod {
  TWO_WEEKS = "twoWeeks",
  ONE_MONTH = "oneMonth",
  CUSTOM_RANGE = "customRange",
  CUSTOM_DATES = "customDates"
}
export enum timeSpanOption {
  DAYS = "days",
  WEEKS = "weeks",
  MONTHS = "months"
}
export type TimeRangeObject = {
  method: timeRangeFetchMethod;
  timeQuantity?: number;
  timeSpan?: timeSpanOption;
  startDate?: string;
  endDate?: string;
};

const AqBuildingWithTimeSettingsComponent = ({
  onSave,
  currentProps
}: WidgetSettings) => {
  const { classes } = useStyles();
  const [buildingId, setBuildingId] = useState<string>(
    currentProps.buildingId ?? ""
  );
  const [method, setMethod] = useState<timeRangeFetchMethod>(
    timeRangeFetchMethod.TWO_WEEKS
  );
  const twoWeeksAgo = moment().subtract(2, "weeks").startOf("day").valueOf();
  const today = moment().valueOf();
  const [startDate, setStartDate] = useState(twoWeeksAgo);
  const [endDate, setEndDate] = useState(today);
  const [timeQuantity, setTimeQuantity] = useState<number>(2);
  const [timeSpan, setTimeSpan] = useState<timeSpanOption>(
    timeSpanOption.WEEKS
  );
  const { buildings, isLoading, isFetching, isError, refetch } =
    useGetBuildings();

  const aqBuildings = buildings?.filter(building => building.indoorData);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value as timeRangeFetchMethod;
    setMethod(selectedValue);
  };

  const handleTimeRangeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTimeQuantity(Number(event.target.value));
  };

  const handleTimeSpanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const option = event.target.value as timeSpanOption;
    setTimeSpan(option);
  };

  const handleOnSave = () => {
    onSave({
      ...currentProps,
      buildingId,
      building: buildings.find(({ id }) => id === buildingId),
      range: getRange(method, timeQuantity, timeSpan, startDate, endDate)
    });
  };

  return (
    <div
      data-testid="aq-building-settings"
      className={classes.root}>
      {isLoading || isFetching ? (
        <div
          className={classes.loader}
          data-testid="aq-building-loader-component">
          <CircularProgress />
        </div>
      ) : (
        <>
          {isError ? (
            <Error
              error={isError}
              setRefetchAgain={refetch}
              showGenericMessage
            />
          ) : (
            <div className={classes.container}>
              <FormInput
                label="Building"
                setValue={setBuildingId}
                array={aqBuildings}
                inputValue={buildingId}></FormInput>
              <div className={classes.ranges}>
                <Typography>Time Range:</Typography>
                <FormControl>
                  <RadioGroup
                    row
                    defaultValue={timeRangeFetchMethod.TWO_WEEKS}
                    name="range-options-group"
                    onChange={handleRadioChange}>
                    <FormControlLabel
                      value={timeRangeFetchMethod.TWO_WEEKS}
                      control={<Radio />}
                      label="Last Two Weeks"
                    />
                    <FormControlLabel
                      value={timeRangeFetchMethod.ONE_MONTH}
                      control={<Radio />}
                      label="Last Month"
                    />
                    <FormControlLabel
                      value={timeRangeFetchMethod.CUSTOM_RANGE}
                      control={<Radio />}
                      label="Custom Range"
                    />
                    <FormControlLabel
                      value={timeRangeFetchMethod.CUSTOM_DATES}
                      control={<Radio />}
                      label="Custom Dates"
                    />
                  </RadioGroup>
                </FormControl>
                <div className={classes.inputSpace}>
                  {method === timeRangeFetchMethod.TWO_WEEKS ? (
                    <div className={classes.inputs}>
                      <div className={classes.setTimeSpan}>
                        <Typography>Two Weeks Ago</Typography>
                        <Typography>-</Typography>
                        <Typography>Present</Typography>
                      </div>
                    </div>
                  ) : method === timeRangeFetchMethod.ONE_MONTH ? (
                    <div className={classes.inputs}>
                      <div className={classes.setTimeSpan}>
                        <Typography>One Month Ago</Typography>
                        <Typography>-</Typography>
                        <Typography>Present</Typography>
                      </div>
                    </div>
                  ) : method === timeRangeFetchMethod.CUSTOM_RANGE ? (
                    <div className={classes.inputs}>
                      <div className={classes.customRange}>
                        <TextField
                          fullWidth
                          label="Number"
                          type="number"
                          defaultValue={timeQuantity}
                          onChange={handleTimeRangeChange}
                        />
                        <FormControl fullWidth>
                          <InputLabel id="label">Range</InputLabel>
                          <Select
                            labelId="label"
                            id="range"
                            value={timeSpan}
                            label="Range"
                            onChange={handleTimeSpanChange}>
                            <MenuItem value={timeSpanOption.DAYS}>
                              days
                            </MenuItem>
                            <MenuItem value={timeSpanOption.WEEKS}>
                              weeks
                            </MenuItem>
                            <MenuItem value={timeSpanOption.MONTHS}>
                              months
                            </MenuItem>
                          </Select>
                        </FormControl>
                        <Typography>-</Typography>
                        <Typography>Present</Typography>
                      </div>
                    </div>
                  ) : (
                    method === timeRangeFetchMethod.CUSTOM_DATES && (
                      <div className={classes.inputs}>
                        <DatePicker
                          label="Start Date"
                          value={startDate}
                          onChange={date => setStartDate(date.valueOf())}
                          renderInput={params => <TextField {...params} />}
                        />
                        <DatePicker
                          label="End Date"
                          value={endDate}
                          onChange={date => setEndDate(date.valueOf())}
                          renderInput={params => <TextField {...params} />}
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}
      <FormAction
        requiresConfig={currentProps.requiresConfig}
        saveDisabled={!buildingId}
        onSave={handleOnSave}></FormAction>
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {},
    loader: {
      display: "flex",
      justifyContent: "center"
    },
    container: {
      marginTop: "1em"
    },
    ranges: {
      marginTop: "1em",
      display: "flex",
      flexDirection: "column"
    },
    inputSpace: {
      height: "3rem",
      width: "100%"
    },
    inputs: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "100%",
      gap: 12
    },
    setTimeSpan: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "90%",
      border: "1px solid lightgrey",
      borderRadius: "8px",
      gap: 12
    },
    customRange: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "90%",
      gap: 12
    }
  };
});

export default AqBuildingWithTimeSettingsComponent;
