import { useState, MouseEvent, ReactElement } from "react";

import moment from "moment";
import {
  Grid,
  Button,
  Typography,
  Menu,
  MenuItem,
  Modal,
  Box,
  useTheme
} from "@mui/material";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import ErrorIcon from "@mui/icons-material/Error";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";

import {
  setAlertsDrawerOpen,
  setDrawerOpen,
  setSelectedNotification,
  setSelectedNotificationId
} from "src/store/notification/reducer";
import { useStyles } from "./notification-row.style";
import {
  useSubscribeToNotificationMutation,
  useUnSubscribeFromNotificationMutation,
  useDeleteNotificationMutation,
  useToggleNotificationMutation
} from "src/store/services/notifications/notificationsService";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { NotificationDefinitionReturn } from "src/utils/client";
import useGetNotificationsAndSubscriptions from "../hooks/useGetNotificationsAndSubscriptions";
import { useAppSelector } from "src/hooks/useAppSelector";
import { getUserId } from "src/store/auth/selectors";

type Props = {
  notification: NotificationDefinitionReturn;
};

const NotificationRowComponent = ({ notification }: Props) => {
  const { classes } = useStyles();
  const userId = useAppSelector(getUserId);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [modelOpen, setModalOpen] = useState<boolean>(false);

  const { subscriptions } = useGetNotificationsAndSubscriptions();
  const [subscribeToNotification] = useSubscribeToNotificationMutation();
  const [unSubscribeFromNotification] =
    useUnSubscribeFromNotificationMutation();
  const [deleteNotification] = useDeleteNotificationMutation();
  const [toggleNotification] = useToggleNotificationMutation();

  const dispatch = useAppDispatch();
  const open = Boolean(anchorEl);
  const theme = useTheme();

  const handleOpenModal = () => {
    setModalOpen(true);
    handleClose();
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const notificationIsSubscribed = subscriptions?.find(
    subscription => subscription.notification_id === notification.id
  );

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onEditNotificationClick = () => {
    dispatch(setDrawerOpen(true));
    dispatch(setSelectedNotification(notification));
    handleClose();
  };

  const onViewAlertsClick = () => {
    dispatch(setSelectedNotificationId(notification.id));
    dispatch(setAlertsDrawerOpen(true));
    handleClose();
  };

  const onDeleteNotification = () => {
    deleteNotification({
      buildingId: notification.building_id,
      notificationId: notification.id
    });
    handleCloseModal();
  };

  const onEnableNotificationClick = () => {
    let activate: boolean = !notification?.is_active;

    toggleNotification({
      notificationId: notification.id,
      buildingId: notification.building_id,
      activate: activate
    });

    handleClose();
  };

  const onSubscibeNotificationClick = () => {
    handleClose();

    const payload = {
      userId: userId,
      buildingId: notification.building_id,
      notificationId: notification.id
    };

    if (notificationIsSubscribed) {
      unSubscribeFromNotification(payload);
      return;
    }

    subscribeToNotification(payload);
  };

  const HandleModalView = (): ReactElement => {
    return (
      <Modal
        open={modelOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={handleCloseModal}>
        <Box
          data-testid="modal-box"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: `2px solid ${theme.palette.primary.main}`,
            boxShadow: 24,
            p: 4,
            borderRadius: "5px"
          }}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2">
            Notification deletion!
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}>
            Are you sure you want to delete notification.
          </Typography>
          <Box
            display="div"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
              bottom: 0
            }}>
            <Button
              color="primary"
              data-testid="delete-modal-btn"
              onClick={onDeleteNotification}>
              Yes, delete
            </Button>
            <Button
              data-testid="close-modal-btn"
              color="error"
              onClick={handleCloseModal}>
              No, cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };

  return (
    <div data-testid="notification-row">
      <HandleModalView />
      <Grid
        container
        className={classes.root}
        columnSpacing={1}>
        <Grid
          item
          xs={10}
          sm={2}
          md={3}
          lg={3}>
          <Typography>{notification.title}</Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={3}
          lg={3}>
          <Typography>{notification.description}</Typography>
        </Grid>

        <Grid
          item
          xs={4}
          sm={2}
          lg={2}>
          <Typography data-testid="last-alert-value-time">
            {notification.last_alert_value_time
              ? moment
                  .utc(notification.last_alert_value_time)
                  .local()
                  .format("lll")
              : "--"}
          </Typography>
        </Grid>

        <Grid
          item
          xs={4}
          sm={1}
          md={1}
          lg={1}>
          <Typography>
            {notification.in_alert ? (
              <ErrorIcon
                data-testid="error-icon"
                color="error"
              />
            ) : (
              <CheckBoxRoundedIcon
                data-testid="check-icon"
                color="success"
              />
            )}
          </Typography>
        </Grid>

        <Grid
          item
          xs={4}
          sm={1}
          md={1}
          lg={1}>
          {/* {Check here if the user has a subscription equal to this notiications ID} */}
          <Typography>
            {notificationIsSubscribed ? (
              <DoneOutlineIcon color="success" />
            ) : (
              <ClearRoundedIcon color="error" />
            )}
          </Typography>
        </Grid>

        <Grid
          item
          xs={3}
          sm={1}
          md={1}
          lg={1}>
          <Typography>
            {notification.is_active ? (
              <DoneOutlineIcon
                data-testid="active-icon"
                color="success"
              />
            ) : (
              <ClearRoundedIcon
                data-testid="inactive-icon"
                color="error"
              />
            )}
          </Typography>
        </Grid>

        <Grid
          item
          xs={4}
          sm={2}
          lg={1}>
          <>
            <Button
              onClick={handleClick}
              color="info"
              data-testid="options-button">
              Action
            </Button>

            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              disableScrollLock
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
              <MenuItem
                data-testid="subscribe"
                onClick={onSubscibeNotificationClick}>
                {notificationIsSubscribed ? "Unsubscribe" : "Subscribe"}
              </MenuItem>

              <MenuItem
                data-testid="view-alerts"
                onClick={onViewAlertsClick}>
                View Alerts
              </MenuItem>

              <MenuItem
                data-testid="edit-notif"
                onClick={onEditNotificationClick}>
                Edit Notification
              </MenuItem>

              <MenuItem
                data-testid="delete-notif"
                onClick={handleOpenModal}>
                Delete Notification
              </MenuItem>

              <MenuItem
                data-testid="enable-notif"
                onClick={onEnableNotificationClick}>
                {notification?.is_active ? "Disable" : "Enable"}
              </MenuItem>
            </Menu>
          </>
        </Grid>
      </Grid>
    </div>
  );
};

export default NotificationRowComponent;
