import CloseIcon from "@mui/icons-material/CloseRounded";
import { makeStyles } from "tss-react/mui";

import {
  DrawerProps,
  IconButton,
  Drawer,
  Paper,
  Typography,
  Box
} from "@mui/material";

type Props = {
  bottomSection?: () => JSX.Element;
};

const DrawerComponent = (props: Props & DrawerProps) => {
  const { bottomSection, children, onClose, open, anchor, title } = props;
  const { classes } = useStyles();
  return (
    <Drawer
      data-testid="drawer"
      anchor={anchor}
      open={open}
      onClose={onClose}>
      <Paper
        className={classes.header}
        elevation={6}>
        <Box>
          <Typography variant="h6">{title}</Typography>
          <IconButton
            data-testid="close-icon"
            className={classes.closeBtn}
            onClick={() => {
              onClose(null, "backdropClick");
            }}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Paper>
      <Paper
        className={classes.content}
        elevation={0}>
        {children}
      </Paper>
      <Paper
        className={classes.bottomSection}
        elevation={6}>
        {bottomSection && bottomSection()}
      </Paper>
    </Drawer>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    header: {
      display: "flex",
      overflow: "auto",
      justifyContent: "space-between",
      padding: 16,
      borderRadius: 0,
      zIndex: 1,
      boxShadow:
        "0px 3px 8px rgba(0, 0, 0, 0.05), 0px 1px 18px rgba(0, 0, 0, 0.03), 0px 6px 10px rgba(0, 0, 0, 0.04);"
    },
    content: {
      paddingBottom: 8,
      display: "flex",
      overflow: "auto",
      justifyContent: "space-between",
      padding: 16,
      borderRadius: 0,
      height: "100%",
      zIndex: 0
    },
    bottomSection: {
      display: "flex",
      justifyContent: "space-between",
      borderRadius: 0,
      backgroundColor: "#F3F6FF",
      borderTop: "1px solid #E5E5E5",
      paddingBottom: 16,
      zIndex: 1
    },
    closeBtn: {
      width: 29,
      height: 29,
      borderRadius: "50%",
      position: "absolute" as const,
      top: 16,
      right: 16,
      cursor: "pointer",
      padding: 0,
      "& svg": {
        fontSize: "13pt",
        color: "black"
      }
    }
  };
});

export default DrawerComponent;
