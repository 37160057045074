import { useState, MouseEvent } from "react";
import AhuStagesAccordionComponent from "../ahu-stages-accordion/ahu-stages-accordion.component";
import AddAhuStageComponent from "../add-ahu-stage/add-ahu-stage.component";
import classnames from "classnames";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { makeStyles } from "tss-react/mui";
import { useDispatch } from "react-redux";

import {
  Box,
  Grid,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Typography
} from "@mui/material";
import { FormattedAhu } from "src/shared/types/ahu";
import {
  handleAHUModal,
  setIsEditingAhu,
  setSelectedAHU
} from "src/store/hvac/reducer";
import { useNavigate } from "react-router-dom";
type Props = {
  ahu: FormattedAhu;
};

const AHURowComponent = (props: Props) => {
  const { ahu } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const openEdit = () => {
    dispatch(setIsEditingAhu(true));
    dispatch(handleAHUModal(true));
    dispatch(setSelectedAHU(ahu));
    setAnchorEl(null);
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Menu
        disableScrollLock={true}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClose}>
        <MenuItem
          onClick={openEdit}
          data-testid="edit-button">
          Edit
        </MenuItem>
      </Menu>
      <Box className={classes.statusIndicator} />
      <Grid
        data-testid="ahu-row"
        container
        className={classnames(classes.root)}
        onClick={() => {
          dispatch(setSelectedAHU(ahu));

          navigate(`/buildings/hvac/${ahu.building_id}/${ahu.id}`);
        }}>
        <Grid
          item
          lg={4}
          md={4}
          sm={6}
          xs={12}>
          <Typography className={classnames(classes.column)}>
            {ahu.name}
          </Typography>
          <Hidden mdUp>
            <Typography
              variant="subtitle1"
              className={classes.column}
              sx={{ fontWeight: "400 !important" }}>
              Number of Stages {ahu.stages.length}
            </Typography>
          </Hidden>
        </Grid>

        <Hidden mdDown>
          <Grid
            item
            lg={2}
            md={2}>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "400 !important", textAlign: "center" }}>
              {ahu.stages.length}
            </Typography>
          </Grid>
        </Hidden>
        <Grid
          item
          lg={3}
          md={3}
          sm={5}
          xs={11}>
          {ahu && ahu.stages && (
            <div onClick={event => event.stopPropagation()}>
              <AhuStagesAccordionComponent ahu={ahu} />
            </div>
          )}
        </Grid>
        <Grid
          item
          xs={1}>
          <IconButton
            onClick={handleClick}
            data-testid="more-button">
            <MoreVertIcon />
          </IconButton>
        </Grid>
      </Grid>
      <AddAhuStageComponent />
    </>
  );
};

export const useStyles = makeStyles()(theme => {
  if (!theme.palette.pressureStatus) {
    theme.palette.pressureStatus = {
      too_low: "rgb(238,130,238)",
      good: "#008000",
      moderate: "#CCCC00",
      high: "#FFA500",
      very_high: "#FF0000",
      too_high: "#800081",
      default: "#a9a9a9"
    };
  }
  return {
    root: {
      paddingRight: 16,
      paddingLeft: 16,
      transition: "all 250ms",
      "&:hover": {
        backgroundColor: theme.palette.grey["200"]
      },
      cursor: "pointer",
      backgroundColor: "white",
      alignItems: "center"
    },
    column: {
      whiteSpace: "nowrap" as const,
      textOverflow: "ellipsis",
      overflow: "hidden",
      paddingLeft: 5,
      paddingBottom: 0
    },
    name: {
      cursor: "pointer"
    },
    doorNumber: {
      whiteSpace: "nowrap" as const,
      textOverflow: "ellipsis",
      overflow: "hidden",
      padding: 6,
      paddingLeft: 16
    },
    firstColumn: {
      paddingLeft: "5% !important"
    },
    indoorNumber: {
      paddingBottom: 6,
      paddingTop: 6
    },
    actions: {
      paddingTop: 8,
      textAlign: "center" as const,
      display: "flex",
      alignItems: "center"
    },
    address: {
      whiteSpace: "nowrap" as const,
      textOverflow: "ellipsis",
      overflow: "hidden",
      padding: 0,
      paddingLeft: 6
    },
    centered: {
      textAlign: "center" as const,
      paddingTop: 8
    },
    selected: {
      backgroundColor: theme.palette.grey["300"]
    },
    qtyDisplay: {
      display: "flex",
      justifyContent: "center"
    },
    statusIndicator: {
      position: "absolute" as const,
      height: "100%",
      top: 0,
      left: 0,
      width: 8,
      background: theme.palette.pressureStatus.default,
      zIndex: 2
    }
  };
});

export default AHURowComponent;
