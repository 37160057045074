export const HAZARDOUS = "Hazardous";
export const UNHEALTHY = "Unhealthy";
export const VERY_UNHEALTHY = "Very unhealthy";
export const SENSITIVE = "Unhealthy for sensitive groups";
export const MODERATE = "Moderate";
export const GOOD = "Good";
export const EXCELLENT = "Excellent";
export const POOR = "Poor";
export const DEFAULT = "Default";
export const OUTDATED = "Outdated";

export const BUILDING_STATUSES = [
  SENSITIVE,
  HAZARDOUS,
  UNHEALTHY,
  MODERATE,
  GOOD,
  VERY_UNHEALTHY,
  EXCELLENT
] as const;
