import { useAppSelector } from "src/hooks/useAppSelector";
import { getBuildingView } from "src/store/buildings/selector";
import { Views } from "src/shared/constants/generals";

const useGetBuildingView = () => {
  const view = useAppSelector(getBuildingView);

  return {
    isMapView: view === Views.MAP,
    isListView: view === Views.LIST,
    isChartView: view === Views.CHART
  };
};

export default useGetBuildingView;
