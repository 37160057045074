import { Typography, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const tooltipText = (
  <div>
    <Typography>Activity will display chronologically: </Typography>
    <div style={{ padding: "0.25rem 0.75rem" }}>
      <Typography>1. Service logs for the unit. </Typography>
      <Typography>2. Generated alerts if enabled and subscribed to.</Typography>
      <Typography>3. Device offline notifications.</Typography>
    </div>
  </div>
);

const ActivityTooltip = () => {
  return (
    <Tooltip
      title={tooltipText}
      style={{ color: "rgba(0, 0, 0, 0.4)" }}
      arrow>
      <InfoOutlinedIcon fontSize="small" />
    </Tooltip>
  );
};

export default ActivityTooltip;
