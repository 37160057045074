import { createSlice } from "@reduxjs/toolkit";
import { login } from "./actions";
import { AuthState } from "./types";
import { AUTH_STATUS } from "./constants";

const initialState: AuthState = {
  AccessToken: "",
  loading: false,
  RefreshToken: "",
  authStatus: AUTH_STATUS.UNAUTHENTICATED,
  user: null
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUnauthenticated(state) {
      state.AccessToken = "";
      state.RefreshToken = "";
      state.authStatus = AUTH_STATUS.UNAUTHENTICATED;
      state.user = null;
    },
    setAuthenticated(state, action) {
      const { tokens, user } = action.payload;
      state.authStatus = AUTH_STATUS.AUTHENTICATED;
      state.AccessToken = tokens.AccessToken;
      state.user = user;
    }
  },
  extraReducers: builder => {
    const signInPendingReducer = (state: AuthState) => {
      state.loading = true;
    };
    const signInFulfiledReducer = (state: AuthState) => {
      state.loading = false;
    };
    const signInRejectedReducer = (state: AuthState) => {
      state.loading = false;
    };

    builder
      .addCase(login.pending, signInPendingReducer)
      .addCase(login.fulfilled, signInFulfiledReducer)
      .addCase(login.rejected, signInRejectedReducer);
  }
});

export const { reducer: authReducer } = authSlice;
