import useOverview from "src/hooks/useOverview";
import { Button, Stack } from "@mui/material";

type Props = {
  requiresConfig: any;
  saveDisabled: any;
  onSave: any;
  type?: "button" | "submit" | "reset";
};

const FormAction = ({
  requiresConfig,
  saveDisabled,
  onSave,
  type = "button"
}: Props) => {
  const { closeConfigurationModal } = useOverview();
  return (
    <Stack
      alignItems={"center"}
      justifyContent={"center"}
      gap={"1em"}
      flexDirection={"row"}
      marginTop={"1em"}>
      {!requiresConfig && (
        <Button onClick={closeConfigurationModal}>Cancel</Button>
      )}
      <Button
        type={type}
        disabled={saveDisabled}
        variant="contained"
        onClick={onSave}>
        Save
      </Button>
    </Stack>
  );
};

export default FormAction;
