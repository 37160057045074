import { Grid, Hidden, Typography } from "@mui/material";
import { useContext } from "react";
import { DrawerContext } from "src/sections/dashboard/Context/DashboardContext";
import { makeStyles } from "tss-react/mui";

const BuildingsTableHeaderComponent = () => {
  const { classes } = useStyles();
  const { filter } = useContext(DrawerContext);

  return (
    <Grid
      container
      className={classes.root}
      data-testid="table-header">
      <Grid
        item
        sm={4}
        md={3}
        lg={3}>
        <Typography className={classes.name}>Name</Typography>
      </Grid>
      <Hidden mdDown>
        <Grid
          item
          sm={3}
          md={3}
          lg={3}>
          <Typography>Location</Typography>
        </Grid>
      </Hidden>
      <Grid
        item
        sm={2}
        md={1}
        className={classes.centered}>
        <Typography>Devices</Typography>
      </Grid>
      {filter === "iaq" && (
        <Hidden smDown>
          <Grid
            item
            sm={2}
            md={2}
            className={classes.centered}>
            <Typography>
              Indoor <Hidden mdDown>AQI</Hidden>
            </Typography>
          </Grid>
          <Grid
            item
            sm={2}
            md={2}
            className={classes.centered}>
            <Typography>
              Outdoor <Hidden mdDown>AQI</Hidden>
            </Typography>
          </Grid>
        </Hidden>
      )}
      <Hidden smDown>
        <Grid
          item
          sm={2}
          md={1}
          className={classes.centered}>
          <Typography>Action</Typography>
        </Grid>
      </Hidden>
    </Grid>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {
      "& .MuiTypography-body1": {
        color: "#919293"
      },
      backgroundColor: "white"
    },
    name: {},
    centered: {
      textAlign: "center" as const
    }
  };
});

export default BuildingsTableHeaderComponent;
