import moment from "moment";
import useUserPascalSettings from "src/hooks/useUserPascalSettings";
import { mergeStageAndLogData } from "src/sections/hvac/air-handling-units/ahu-details/utils/chartData+serviceLogs-util";
import { ServiceLog, Stage, StageType } from "./client";
import { getStageBackground } from "src/sections/hvac/air-handling-units/ahu-details/utils/accordion-background";
import service from "src/assets/service.png";

export type Range = {
  to: number;
  from: number;
  color: string;
};

const SERVICE_IMAGE = new Image(20, 20);
SERVICE_IMAGE.src = service;

export const convertFromPascalsToPSI = (value: number) => value * 0.000145038;
export const convertFromPascalsToIWG = (value: number) => value / 249;
export const convertFromIWGToPascals = (value: number) => value * 249;
export const convertRangesValue = (
  isUserUsingPascal: boolean,
  ranges: Range[],
  isSendingValue = false
) => {
  return Object.values(ranges)?.map(({ from, to, color }) => ({
    color,
    from: isUserUsingPascal
      ? from
      : isSendingValue
      ? convertFromIWGToPascals(from)
      : convertFromPascalsToIWG(from),
    to: isUserUsingPascal
      ? to
      : isSendingValue
      ? convertFromIWGToPascals(to)
      : convertFromPascalsToIWG(to)
  }));
};

export const convertToPascalsIfUserUsePascalSettings = (pressure: number) => {
  const isUserUsingPascal = useUserPascalSettings();
  return isUserUsingPascal ? convertFromIWGToPascals(pressure) : pressure;
};

export const convertToIWGIfUserUsePascals = (pressure: number) => {
  const isUserUsingPascal = useUserPascalSettings();
  return isUserUsingPascal ? pressure : convertFromPascalsToIWG(pressure);
};

export const convertPascalsToPSI = (pressure: number) => {
  const pascalsInOnePSI = 6894.76;
  return pressure / pascalsInOnePSI;
};
export const getAhuPressureLineChartData = (
  ahuStages: Stage[],
  timeSeries: any,
  isUserUsingPascal: boolean,
  showDotsOnLine: boolean = true,
  showLogData: boolean = false,
  serviceLogs: ServiceLog[] = [],
  activeStage: Stage = null
) =>
  ahuStages?.map((stage, index) => {
    const stageData = timeSeries[stage.id];
    if (!stageData) return { label: stage?.name, data: [] };
    const isAirFlowStage = stage?.stage_type === StageType.AIRFLOW;
    const pressureData = stageData.map(item => ({
      value: isAirFlowStage
        ? Number(item.air_velocity?.mean)
        : isUserUsingPascal
        ? convertFromIWGToPascals(Number(item.pressure_drop?.mean))
        : Number(item.pressure_drop?.mean),
      date: moment(item.time).toDate().valueOf()
    }));

    const lineAndDotColor = isAirFlowStage
      ? "#bebebe"
      : getStageBackground(activeStage?.id, stage.id, index);

    return {
      labels: stageData.map(({ time }) => time),
      label: stage.name,
      stageId: stage.id,
      // color of dot
      backgroundColor: lineAndDotColor,
      // color of line
      borderColor: lineAndDotColor,
      // how much line is curved,
      tension: 0.5,
      radius: 3,
      pointStyle: context => {
        // By default pointStyle is circle
        // In case that service log exist on chart we will show image
        if (context?.raw?.showBullet) {
          return SERVICE_IMAGE;
        }
        if (!showDotsOnLine) {
          return false;
        }
      },
      yAxisID: isAirFlowStage ? "y1" : "y",
      data: showLogData
        ? mergeStageAndLogData(
            Boolean(activeStage) ? activeStage : stage,
            pressureData,
            serviceLogs
          )
        : pressureData
    };
  });

export const getChartDataForDetailsPage = (
  data: any,
  name: string,
  color: string,
  isDashed: boolean = false
) => {
  if (!Boolean(data)) return null;

  return {
    labels: data.map(({ date }) => moment(date).toDate().valueOf()),
    label: name,
    borderColor: isDashed ? "rgba(0,0,0,0.7)" : color,
    tension: 0.5,
    borderDash: isDashed ? [10, 2] : [0, 0],
    data: data.map(({ value, date }) => ({
      value,
      date: moment(date).toDate().valueOf()
    }))
  };
};

export const getChartDataForKettle = (
  data: any,
  name: string,
  color: string
) => {
  if (!Boolean(data)) return null;
  return {
    labels: data.map(({ time }) => moment(time).toDate().valueOf()),
    label: name,
    borderColor: color,
    tension: 0.5,
    data: data.map(({ data, time }) => ({
      value: convertFromPascalsToPSI(data?.pressure?.mean ?? 0),
      date: moment(time).toDate().valueOf()
    }))
  };
};
