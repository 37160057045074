import { FormApi } from "final-form";
import { FormControl, FormLabel, Autocomplete, TextField } from "@mui/material";
import { FormFields } from "./notification-drawer.component";
import { Dispatch, SetStateAction } from "react";

type Props = {
  form: FormApi<any, Partial<any>>;
  label: string;
  type: string;
  data: any;
  isBuilding?: boolean;
  isSensor?: boolean;
  setSelectedBuildingId?: Dispatch<SetStateAction<string>>;
  setSelectedDevice?: Dispatch<SetStateAction<any>>;
  setSelectedDeviceParent?: Dispatch<SetStateAction<any>>;
  inputLabel: string;
};

const AutoCompleteFormField = ({
  form,
  label,
  type,
  data,
  isBuilding = false,
  isSensor = false,
  setSelectedBuildingId,
  setSelectedDeviceParent,
  setSelectedDevice,
  inputLabel
}: Props) => {
  const resetDevices = () => {
    setSelectedDeviceParent(null);
    setSelectedDevice(null);
  };

  const handleOnChange = (_event, value) => {
    if (isSensor) {
      form.change(FormFields.BUILDING_ID, value?.building_id);
      setSelectedDevice(value);
      setSelectedBuildingId(value.building_id);
      return;
    }
    if (isBuilding) {
      form.change(FormFields.BUILDING_ID, value?.id);
      setSelectedBuildingId(value.id);
      resetDevices();
      return;
    }
    form.change(FormFields.BUILDING_ID, value?.building_id);
    setSelectedDeviceParent(value);
    setSelectedBuildingId(value.building_id);
  };

  return (
    <>
      <FormControl>
        <FormLabel data-testid="label">{label}</FormLabel>
      </FormControl>
      <Autocomplete
        data-testid="autocomplete-component"
        onChange={handleOnChange}
        disablePortal
        options={data}
        value={inputLabel ?? ""}
        renderInput={params => (
          <TextField
            {...params}
            label={type}
          />
        )}
      />
    </>
  );
};

export default AutoCompleteFormField;
