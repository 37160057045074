import { useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

interface IConfirmDeleteProps {
  confirmText?: string;
  cancelText?: string;
  onConfirm: () => void;
}

function ConfirmDelete({
  confirmText = "Yes, delete",
  cancelText = "No, cancel",
  onConfirm
}: IConfirmDeleteProps) {
  const [awaitConfirmation, setAwaitConfirmation] = useState<boolean>(false);

  if (!awaitConfirmation) {
    return (
      <Button
        data-testid="delete-btn"
        color="warning"
        onClick={() => setAwaitConfirmation(true)}>
        Delete
      </Button>
    );
  }

  return (
    <Stack
      data-testid="confirm-delete-container"
      direction="row">
      <Button
        variant="text"
        color="error"
        data-testid="confirm-delete-btn"
        onClick={onConfirm}>
        {confirmText}
      </Button>
      <Button
        variant="text"
        data-testid="cancel-confirmation"
        onClick={() => setAwaitConfirmation(false)}>
        {cancelText}
      </Button>
    </Stack>
  );
}

export default ConfirmDelete;
