import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";

const selectBuildingCost = (state: RootState) => state.buildingCost;
export const getTCOCurve = createSelector(
  selectBuildingCost,
  slice => slice.showTCOCurve
);

export const getTCOData = createSelector(
  selectBuildingCost,
  slice => slice.TCOData
);

export const getCOOData = createSelector(
  selectBuildingCost,
  slice => slice.COOData
);
