import { Box, Container, Stack, Typography } from "@mui/material";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { setSelectedAhuDetailsStageId } from "src/store/hvac/reducer";
import { Stage, Unit } from "src/utils/client";
import { getAirflowBackground } from "../../utils/accordion-background";

type AirflowProps = {
  stage?: Stage;
  airflow?: { value: number; units: string };
  expandedStageId: string | null;
  setExpandedStageId?: any;
};

const Airflow = ({
  stage,
  airflow,
  expandedStageId,
  setExpandedStageId
}: AirflowProps) => {
  const dispatch = useAppDispatch();
  const bgColor = getAirflowBackground(expandedStageId, stage?.id);

  const handleChange = (stage: Stage) => {
    const isExpanded = expandedStageId === stage?.id;
    setExpandedStageId(isExpanded ? false : stage?.id);
    dispatch(setSelectedAhuDetailsStageId(isExpanded ? null : stage?.id));
  };

  return (
    <Container
      data-testid="container"
      sx={styles.container}
      style={{ padding: 0 }}
      onClick={() => handleChange(stage)}>
      <Stack
        py={2}
        pl={"6px"}
        direction={"row"}
        justifyContent={"space-between"}>
        <Stack width={0.75}>
          <Stack sx={styles.leftContainer}>
            <Typography fontWeight={500}>Airflow</Typography>
            <Box
              sx={styles.line}
              bgcolor={bgColor}
            />
          </Stack>
        </Stack>
        <Stack
          width={0.25}
          alignItems={"center"}
          margin={"4px"}>
          <Typography sx={styles.airflow}>
            {airflow?.value?.toFixed(2)}
          </Typography>
          <Typography sx={styles.units}>
            {airflow?.units === Unit.METERS_PER_S && "m/s"}
          </Typography>
        </Stack>
      </Stack>
    </Container>
  );
};

const styles = {
  container: {
    border: "2px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "0rem 0rem 1rem 1rem",
    cursor: "pointer"
  },
  leftContainer: {
    height: 1,
    justifyContent: "space-between",
    pl: "26px",
    pb: 1
  },
  line: {
    width: 0.9,
    height: "0.3rem",
    borderRadius: "1rem"
  },
  airflow: {
    fontSize: "1.25rem",
    fontWeight: "800",
    color: "#7F7F7F"
  },
  units: {
    marginTop: "-0.5rem",
    color: "#BEBEBE"
  }
};

export default Airflow;
