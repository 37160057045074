/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * `senzit`: Represents the actual incoming senzit device measurements, which are absolute pressure on one side of a filter stage
 * `dp_senzit`:  Represents the "vitual" senzit device at a stage that holds calculated pressure delta
 */
export enum HvacSensorType {
  ARANET = 'aranet',
  SYNETICA_DP = 'synetica_dp',
  SYNETICA_AF = 'synetica_af',
  SENZIT = 'senzit',
  DP_SENZIT = 'dp_senzit',
}
