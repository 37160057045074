// import glebal configs here

import { createTheme } from "@mui/material/styles";
import { green, purple } from "@mui/material/colors";

export default {
  name: "This name comes from config",
  title: "Example",
  favicon: "react.ico",
  mapCenter: {
    lat: 38.78129,
    lng: -102.195998
  },
  currency: "USD",
  theme: createTheme({
    palette: {
      primary: green,
      secondary: purple
    },
    typography: {
      fontFamily: [
        '"Cera Pro"',
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(",")
    }
  }),
  assets: "example",
  apiStage: "",
  amLicense: ""
};
