import classNames from "classnames";
import { makeStyles } from "tss-react/mui";
import { alpha, Tooltip, Typography } from "@mui/material";

import {
  AHU_DEFAULT,
  PRESSURE_TOO_LOW,
  PRESSURE_GOOD,
  PRESSURE_MODERATE,
  PRESSURE_HIGH,
  PRESSURE_VERY_HIGH,
  PRESSURE_TOO_HIGH,
  AHU_NO_DEVICE
} from "src/shared/constants/ahu-status";
import { StageAndFilterload } from "src/utils/client";
import useUserPascalSettings from "src/hooks/useUserPascalSettings";
import { convertFromPascalsToIWG } from "src/utils/pressure.utils";
import useGetHvacStatusInfoDictionary from "src/hooks/useGetHvacStatusInfoDictionary";
import { DEFAULT } from "src/shared/constants/building-status";

type Props = {
  stage: StageAndFilterload;
};

const MaxPressureComponent = ({ stage }: Props) => {
  const { classes } = useStyles();
  const isUserUsingPascals = useUserPascalSettings();
  const { dictionary: pollInfoDict } = useGetHvacStatusInfoDictionary();

  const value = stage?.filterload?.current_pressure;
  const convertedPressure = isUserUsingPascals
    ? value
    : convertFromPascalsToIWG(value);
  const status =
    pollInfoDict[stage.stage_type]?.["pressure_status"]?.find(
      ({ high, low }) => high >= convertedPressure && low <= convertedPressure
    )?.status ?? DEFAULT.toLowerCase();

  return (
    <div
      className={classNames(
        classes.pollutant,
        classes[`background${status.replace(" ", "_")}`]
      )}
      data-testid="max-pressure">
      <Tooltip
        title={status.toLowerCase()}
        disableFocusListener={
          status === AHU_DEFAULT || status === AHU_NO_DEVICE
        }
        disableHoverListener={
          status === AHU_DEFAULT || status === AHU_NO_DEVICE
        }
        disableTouchListener={
          status === AHU_DEFAULT || status === AHU_NO_DEVICE
        }>
        <Typography
          className={classNames(
            classes.root,
            classes[`status${status.replace(" ", "_")}`]
          )}>
          {Number(convertedPressure.toFixed(2))}
        </Typography>
      </Tooltip>
    </div>
  );
};

export const useStyles = makeStyles()(theme => {
  if (!theme.palette.pressureStatus) {
    theme.palette.pressureStatus = {
      too_low: "rgb(238,130,238)",
      good: "#008000",
      moderate: "#CCCC00",
      high: "#FFA500",
      very_high: "#FF0000",
      too_high: "#800081",
      default: "#a9a9a9"
    };
  }
  if (!theme.palette.ahuStatus) {
    theme.palette.ahuStatus = {
      clean_filters: "#008000",
      moderately_clean: "#CCCC00",
      moderately_dirty: "#FFA500",
      dirty_filters: "#FF0000",
      default: "#a9a9a9"
    };
  }
  return {
    root: {},
    pollutant: {
      textAlign: "center" as const,
      border: "1px solid #E9EAEA",
      borderRadius: 8,
      padding: 2,
      minWidth: 50
    },
    infoContainer: {
      marginTop: 4
    },
    info: {
      color: theme.palette.primary[500] || theme.palette.primary["main"],
      fontSize: 16
    },
    [`background${PRESSURE_TOO_LOW.replace(/ /g, "_")}`]: {
      background: alpha(theme.palette.pressureStatus[PRESSURE_TOO_LOW], 0.2),
      border: `1px solid ${alpha(
        theme.palette.pressureStatus[PRESSURE_TOO_LOW],
        0.5
      )}`
    },
    [`background${PRESSURE_GOOD.replace(/ /g, "_")}`]: {
      background: alpha(theme.palette.pressureStatus[PRESSURE_GOOD], 0.2),
      border: `1px solid ${alpha(
        theme.palette.pressureStatus[PRESSURE_GOOD],
        0.5
      )}`
    },
    [`background${PRESSURE_MODERATE.replace(/ /g, "_")}`]: {
      background: alpha(theme.palette.pressureStatus[PRESSURE_MODERATE], 0.2),
      border: `1px solid ${alpha(
        theme.palette.pressureStatus[PRESSURE_MODERATE],
        0.5
      )}`
    },
    [`background${PRESSURE_HIGH.replace(/ /g, "_")}`]: {
      background: alpha(theme.palette.pressureStatus[PRESSURE_HIGH], 0.2),
      border: `1px solid ${alpha(
        theme.palette.pressureStatus[PRESSURE_HIGH],
        0.5
      )}`
    },
    [`background${PRESSURE_VERY_HIGH.replace(/ /g, "_")}`]: {
      background: alpha(theme.palette.pressureStatus[PRESSURE_VERY_HIGH], 0.2),
      border: `1px solid ${alpha(
        theme.palette.pressureStatus[PRESSURE_VERY_HIGH],
        0.5
      )}`
    },
    [`background${PRESSURE_TOO_HIGH.replace(/ /g, "_")}`]: {
      background: alpha(theme.palette.pressureStatus[PRESSURE_TOO_HIGH], 0.2),
      border: `1px solid ${alpha(
        theme.palette.pressureStatus[PRESSURE_TOO_HIGH],
        0.5
      )}`
    },
    [`background${AHU_DEFAULT.replace(/ /g, "_")}`]: {
      background: alpha(theme.palette.ahuStatus[AHU_DEFAULT], 0.2),
      border: `1px solid ${alpha(theme.palette.ahuStatus[AHU_DEFAULT], 0.5)}`
    },
    [`background${AHU_NO_DEVICE.replace(/ /g, "_")}`]: {
      background: alpha(theme.palette.ahuStatus[AHU_DEFAULT], 0.2),
      border: `1px solid ${alpha(theme.palette.ahuStatus[AHU_DEFAULT], 0.5)}`
    },
    [`status${PRESSURE_TOO_LOW}`]: {
      color: theme.palette.pressureStatus[PRESSURE_TOO_LOW]
    },
    [`status${PRESSURE_GOOD}`]: {
      color: theme.palette.pressureStatus[PRESSURE_GOOD]
    },
    [`status${PRESSURE_MODERATE}`]: {
      color: theme.palette.pressureStatus[PRESSURE_MODERATE]
    },
    [`status${PRESSURE_HIGH}`]: {
      color: theme.palette.pressureStatus[PRESSURE_HIGH]
    },
    [`status${PRESSURE_VERY_HIGH}`]: {
      color: theme.palette.pressureStatus[PRESSURE_VERY_HIGH]
    },
    [`status${PRESSURE_TOO_HIGH}`]: {
      color: theme.palette.pressureStatus[PRESSURE_TOO_HIGH]
    },
    [`status${AHU_DEFAULT}`]: {
      color: theme.palette.pressureStatus[AHU_DEFAULT]
    },
    [`status${AHU_NO_DEVICE.replace(/ /g, "_")}`]: {
      color: theme.palette.pressureStatus[AHU_DEFAULT]
    }
  };
});

export default MaxPressureComponent;
