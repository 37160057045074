import { Fab, MenuItem, Menu, ListItemIcon } from "@mui/material";

import PopupState, {
  bindTrigger,
  bindMenu,
  bindToggle
} from "material-ui-popup-state";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import useOverview from "src/hooks/useOverview";
import useGetAppBreakPoints from "src/hooks/useGetAppBreakPoints";

interface IMenu {
  classes: any;
  onSave: () => void;
  pagesLength: number;
}

const MenuAction = ({ classes, onSave, pagesLength }: IMenu) => {
  const { isSm } = useGetAppBreakPoints();
  if (isSm) return null;

  const {
    editing,
    openWidgetModal,
    openPageModal,
    setEditing,
    setEditingTabIndex
  } = useOverview();

  const pageExist = Boolean(pagesLength);
  const disableAddingPages = pagesLength >= 15;

  const handleOnEdit = (value: boolean) => {
    setEditing(value);
  };
  const handleOnSave = () => {
    onSave();
    handleOnEdit(false);
  };

  return editing ? (
    <Fab
      data-testid="menuAction-fab-saveIcon"
      onClick={handleOnSave}
      color="primary"
      aria-label="edit"
      className={classes.fab}>
      <SaveIcon />
    </Fab>
  ) : (
    <PopupState
      variant="popover"
      popupId="editOverview">
      {state => {
        return (
          <>
            <Fab
              data-testid="menuAction-fab-edit"
              color="primary"
              aria-label="edit"
              className={classes.fab}
              {...bindTrigger(state)}>
              <EditIcon />
            </Fab>
            <Menu
              data-testid="menuAction-menu"
              sx={{ top: -70 }}
              id="editOverview"
              keepMounted
              {...bindMenu(state)}>
              <MenuItem
                disabled={disableAddingPages}
                onClick={() => {
                  openPageModal();
                  setEditingTabIndex(null);
                  state.close();
                }}>
                <ListItemIcon>
                  <DashboardCustomizeIcon />
                </ListItemIcon>
                Add Page
              </MenuItem>
              <MenuItem
                disabled={!pageExist}
                {...bindToggle(state)}
                onClick={() => {
                  openWidgetModal();
                  state.close();
                }}>
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                Add Widget
              </MenuItem>
              <MenuItem
                data-testid="menuAction-editPage"
                disabled={!pageExist}
                onClick={() => handleOnEdit(true)}>
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                Edit Page
              </MenuItem>
            </Menu>
          </>
        );
      }}
    </PopupState>
  );
};

export default MenuAction;
