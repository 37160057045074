import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { Stage } from "src/utils/client";
import { Grid, Typography } from "@mui/material";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { handleFilterStageModal } from "src/store/hvac/reducer";
import { setStageToEdit } from "src/store/hvac/reducer";
import useGetAppBreakPoints from "src/hooks/useGetAppBreakPoints";
import { useStyles } from "./reminder-item.style";

type Props = {
  stage: Stage;
};

const ReminderItem = (props: Props) => {
  const { stage } = props;
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const { isMd } = useGetAppBreakPoints();

  return isMd ? (
    <Grid
      data-testid="reminder"
      sm={12}
      xs={12}
      container
      className={classes.log}>
      <Grid
        data-testid="reminder-md"
        sm={12}
        xs={12}
        container
        className={classes.margin}>
        <Grid
          sm={8}
          xs={8}
          container
          className={classes.item}>
          <Grid
            item
            sm={2}
            xs={2}
            className={classes.iconItem}>
            <QuestionMarkIcon
              fontSize="small"
              className={classes.question}
            />
          </Grid>
          <Grid
            item
            sm={8}
            xs={8}>
            <Typography className={classes.logTitle}>{stage.name}</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          sm={3}
          xs={3}
          className={classes.editItem}>
          <Typography
            data-testid="edit-md"
            className={classes.edit}
            onClick={() => {
              dispatch(handleFilterStageModal(true));
              dispatch(setStageToEdit(stage));
            }}>
            Edit
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        sm={12}
        xs={12}
        className={classes.descriptionItem}>
        <div>
          <Typography className={classes.font}>
            Filter Information is missing.
          </Typography>
          <Typography className={classes.font}>
            Enter Filter Information to gain additional insights including a
            predicted Remaining Useful Life.
          </Typography>
        </div>
      </Grid>
    </Grid>
  ) : (
    <Grid
      data-testid="reminder"
      item
      lg={12}
      md={12}
      className={classes.log}>
      <Grid
        data-testid="reminder-lg"
        item
        lg={1}
        md={1}
        className={classes.questionItem}>
        <QuestionMarkIcon
          fontSize="small"
          className={classes.question}
        />
      </Grid>
      <Grid
        item
        lg={14}
        md={14}
        className={classes.descriptionItem}>
        <div className={classes.flex}>
          <Typography className={classes.font}>
            Filter Information is missing for
          </Typography>
          <Typography className={`${classes.stage} ${classes.font}`}>
            {stage.name}
          </Typography>
        </div>
        <Typography className={classes.font}>
          Enter Filter Information to gain additional insights including a
          predicted Remaining Useful Life.
        </Typography>
      </Grid>
      <Grid
        item
        lg={1}
        md={1}
        className={classes.edit}>
        <Typography
          data-testid="edit-lg"
          className={classes.editButton}
          onClick={() => {
            dispatch(setStageToEdit(stage));
            dispatch(handleFilterStageModal(true));
          }}>
          Edit
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ReminderItem;
