/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AqiScale } from './AqiScale';
import type { Language } from './Language';
import type { UnitPreferences } from './UnitPreferences';
export type UserSettings_Output = {
  language?: Language;
  aqi_scale?: AqiScale;
  unit_prefs?: UnitPreferences;
  send_reports?: boolean;
  send_alerts?: boolean;
  default_webportal_view?: UserSettings_Output.default_webportal_view;
};
export namespace UserSettings_Output {
  export enum default_webportal_view {
    AQ = 'aq',
    HVAC = 'hvac',
    KETTLE = 'kettle',
    PAINT_BOOTH = 'paint_booth',
  }
}

