import { makeStyles } from "tss-react/mui";
export const getTabsStyle = {
  display: "flex",
  overflow: "auto",
  marginLeft: "40px",
  marginTop: "12px",
  scrollbarWidth: "none"
};

export const getTabStyle = (
  isDragging: boolean,
  draggableStyle: any,
  index: number,
  selectedTab: number
) => ({
  userSelect: "none",
  background: isDragging ? "white" : "rgb(243, 246, 255)",
  ...draggableStyle,
  height: "48px",
  minWidth: "120px",
  display: " flex",
  justifyContent: "center",
  alignItems: "center",
  borderBottom: selectedTab === index && "2px solid #0795bb"
});

export const getEditIconStyle = {
  fontSize: "17px",
  marginLeft: 2,
  cursor: "pointer",
  opacity: "0.7"
};

export const useStyles = makeStyles()(() => {
  return {
    root: {
      padding: 16,
      paddingTop: 0,
      background: "rgb(243, 246, 255)",
      height: "100%"
    },
    title: {
      display: "flex",
      alignItems: "start",
      justifyContent: "space-between",
      "@media(min-width: 600px)": {
        backgroundColor: "transparent"
      }
    },
    body: {
      marginTop: 4
    },
    mainGrid: {
      "@media(max-width: 600px)": {
        flexDirection: "column-reverse"
      }
    },
    saveBtn: {
      marginLeft: "1em"
    },
    widgetHover: {
      width: "100%",
      height: "100%",
      position: "absolute",
      top: 0,
      left: 0,
      backgroundColor: "rgba(0,0,0,0.5)",
      opacity: 0,
      transition: "all 0.25s",
      cursor: "move",
      "&:hover": {
        opacity: 1,
        transition: "all 0.25s"
      }
    },
    configBody: {
      marginTop: "1em"
    },
    loader: {
      position: "absolute",
      left: 0,
      height: "100%",
      top: 0
    },
    fab: {
      position: "fixed",
      right: 16,
      bottom: 16
    },
    logo: {
      marginBottom: "2em",
      width: "150px",
      filter: "brightness(0) invert(1)"
    },
    tourCard: {
      width: 250,
      position: "absolute",
      bottom: 8,
      right: 8
    }
  };
});

export const getSkelWrapperStyle = {
  height: "80px",
  display: "flex",
  alignItems: "center",
  justifyContent: "end",
  paddingRight: 23
};

export const getSkelStyle = {
  position: "absolute",
  bottom: 2,
  right: 2,
  bgcolor: "grey.400"
};
