/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AlertDeviceType } from './AlertDeviceType';
import type { AlertKey } from './AlertKey';
/**
 * Helper model for storing details of the notification associated with an alert.
 *
 * Note that these values only represent the details of the notification at the time
 * the alert was generated and are not kept in sync with the original `NotificationDefinition` object.
 */
export type AlertNotificationDetails = {
  id: string;
  created_user_id: string;
  title: string;
  company_id: string;
  building_id: string;
  alert_device_type: AlertDeviceType;
  alert_key: AlertKey;
  threshold: number;
  threshold_direction?: AlertNotificationDetails.threshold_direction;
  time_required?: number;
  device_id: string;
  is_active: boolean;
  text_message_enabled: boolean;
  email_enabled: boolean;
};
export namespace AlertNotificationDetails {
  export enum threshold_direction {
    GT = 'gt',
    LT = 'lt',
    EQ = 'eq',
  }
}

