/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ServiceLog } from '../models/ServiceLog';
import type { ServiceLogNew } from '../models/ServiceLogNew';
import type { ServiceLogReturn } from '../models/ServiceLogReturn';
import type { ServiceLogUpdates } from '../models/ServiceLogUpdates';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ServiceLogsService {
  /**
   * Create Service Log
   * @param buildingId
   * @param requestBody
   * @returns ServiceLog Successful Response
   * @throws ApiError
   */
  public static createServiceLog(
    buildingId: string,
    requestBody: ServiceLogNew,
  ): CancelablePromise<ServiceLog> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/buildings/{building_id}/service_logs/',
      path: {
        'building_id': buildingId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Service Log Range
   * Two types of service logs can be retrieved:
   * `HVAC`: include `ahu_id` and optional `stage_id` query params
   * `Kettle`: include `kettle_id` query param
   * `PaintBooth`: include `booth_id` query param
   * @param buildingId
   * @param startTs
   * @param endTs
   * @param ahuId
   * @param kettleId
   * @param boothId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getServiceLogRange(
    buildingId: string,
    startTs: string,
    endTs?: (string | null),
    ahuId?: (string | null),
    kettleId?: (string | null),
    boothId?: (string | null),
  ): CancelablePromise<(Array<ServiceLogReturn> | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/service_logs/',
      path: {
        'building_id': buildingId,
      },
      query: {
        'start_ts': startTs,
        'end_ts': endTs,
        'ahu_id': ahuId,
        'kettle_id': kettleId,
        'booth_id': boothId,
      },
      errors: {
        400: `Must include either \`ahu_id\` or \`kettle_id\`.`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Service Log
   * Two types of service logs can be retrieved:
   * `AHU`: include `ahu_id` and `stage_id` query params
   * `Kettle`: include `kettle_id` query param
   * `PaintBooth`: include `booth_id` query param
   * @param buildingId
   * @param serviceLogId
   * @param ahuId
   * @param stageId
   * @param kettleId
   * @param boothId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getServiceLog(
    buildingId: string,
    serviceLogId: string,
    ahuId?: (string | null),
    stageId?: (string | null),
    kettleId?: (string | null),
    boothId?: (string | null),
  ): CancelablePromise<(ServiceLogReturn | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/service_logs/{service_log_id}',
      path: {
        'building_id': buildingId,
        'service_log_id': serviceLogId,
      },
      query: {
        'ahu_id': ahuId,
        'stage_id': stageId,
        'kettle_id': kettleId,
        'booth_id': boothId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Update Service Log
   * @param buildingId
   * @param serviceLogId
   * @param requestBody
   * @returns ServiceLog Successful Response
   * @throws ApiError
   */
  public static updateServiceLog(
    buildingId: string,
    serviceLogId: string,
    requestBody: ServiceLogUpdates,
  ): CancelablePromise<ServiceLog> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/buildings/{building_id}/service_logs/{service_log_id}',
      path: {
        'building_id': buildingId,
        'service_log_id': serviceLogId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Service Log not found`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Delete Service Log
   * Two types of service logs can be retrieved:
   * `HVAC`: include `ahu_id` and `stage_id` query params
   * `Kettle`: include `kettle_id` query param
   * `PaintBooth`: include `booth_id` query param
   * @param buildingId
   * @param serviceLogId
   * @param ahuId
   * @param stageId
   * @param kettleId
   * @param boothId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static deleteServiceLog(
    buildingId: string,
    serviceLogId: string,
    ahuId?: (string | null),
    stageId?: (string | null),
    kettleId?: (string | null),
    boothId?: (string | null),
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/buildings/{building_id}/service_logs/{service_log_id}',
      path: {
        'building_id': buildingId,
        'service_log_id': serviceLogId,
      },
      query: {
        'ahu_id': ahuId,
        'stage_id': stageId,
        'kettle_id': kettleId,
        'booth_id': boothId,
      },
      errors: {
        404: `Service Log not found`,
        422: `Validation Error`,
      },
    });
  }
}
