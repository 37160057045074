import { makeStyles } from "tss-react/mui";
export const useStyles = makeStyles()(() => ({
  loginForm: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    justifyContent: "space-between",
    position: "relative",
    maxWidth: 400,
    height: 400
  },
  infoBar: {
    transform: "translateX(-25%) !important",
    "@media (max-width: 700px)": {
      transform: "translateX(0) !important",
      left: 0,
      right: 0,
      position: "relative",
      marginBottom: 15
    }
  },
  fieldContainer: {
    height: 64,
    display: "grid"
  },
  forgotPassword: {
    textAlign: "right",
    lineHeight: 0,
    cursor: "pointer"
  },
  getHelp: {
    marginTop: "3em",
    textAlign: "center",
    maxWidth: 400
  },
  copyright: {
    marginTop: "3em",
    textAlign: "center",
    color: "lightgray"
  },
  extraOptions: {
    marginTop: 24,
    textAlign: "center",
    maxWidth: 350
  },
  supportText: {
    lineHeight: 1.4
  },
  small: {
    color: "#e9e9e9"
  }
}));
