/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AqDevice } from '../models/AqDevice';
import type { AqDeviceAndLatest } from '../models/AqDeviceAndLatest';
import type { AqDeviceUpdates } from '../models/AqDeviceUpdates';
import type { AqDeviceUpdatesMinimal } from '../models/AqDeviceUpdatesMinimal';
import type { PostAqDeviceBody } from '../models/PostAqDeviceBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AqDevicesService {
  /**
   * Create Aq Device
   * @param buildingId
   * @param requestBody
   * @returns AqDevice Successful Response
   * @throws ApiError
   */
  public static createAqDevice(
    buildingId: string,
    requestBody: PostAqDeviceBody,
  ): CancelablePromise<AqDevice> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/buildings/{building_id}/aq-devices/',
      path: {
        'building_id': buildingId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Database Error`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Aq Devices For Building
   * Returns possibly empty list of aq devices for the building.
   * @param buildingId
   * @returns AqDevice Successful Response
   * @throws ApiError
   */
  public static getAqDevicesForBuilding(
    buildingId: string,
  ): CancelablePromise<Array<AqDevice>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/aq-devices/',
      path: {
        'building_id': buildingId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Aq Device
   * Returns the aq_device, optionally with its latest measurement (or `null` is the id is invalid).
   * @param deviceId
   * @param buildingId
   * @param includeLatest
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getAqDevice(
    deviceId: string,
    buildingId: string,
    includeLatest: boolean = true,
  ): CancelablePromise<(AqDeviceAndLatest | AqDevice | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/aq-devices/{device_id}',
      path: {
        'device_id': deviceId,
        'building_id': buildingId,
      },
      query: {
        'include_latest': includeLatest,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Update Aq Device
   * @param deviceId
   * @param buildingId
   * @param requestBody
   * @returns AqDevice Successful Response
   * @throws ApiError
   */
  public static updateAqDevice(
    deviceId: string,
    buildingId: string,
    requestBody: (AqDeviceUpdates | AqDeviceUpdatesMinimal),
  ): CancelablePromise<AqDevice> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/buildings/{building_id}/aq-devices/{device_id}',
      path: {
        'device_id': deviceId,
        'building_id': buildingId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Database Error`,
        404: `Invalid Object Id`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Delete Aq Device
   * @param deviceId
   * @param buildingId
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public static deleteAqDevice(
    deviceId: string,
    buildingId: string,
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/buildings/{building_id}/aq-devices/{device_id}',
      path: {
        'device_id': deviceId,
        'building_id': buildingId,
      },
      errors: {
        404: `Invalid Object Id`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Aq Devices
   * Returns list of all aq-devices by default, or all offline devices if the filter is 'offline'.
   * @param filter
   * @param withLatest
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getAqDevices(
    filter?: null,
    withLatest: boolean = false,
  ): CancelablePromise<(Array<AqDeviceAndLatest> | Array<AqDevice>)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/aq-devices/',
      query: {
        'filter': filter,
        'with_latest': withLatest,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
