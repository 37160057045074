// Array containing amcharts' series color scheme with step set to 3.
export const colors: string[] = [
  "#90CAE5",
  "#A290E5",
  "#E58fDB",
  "#E59190",
  "#E5DE90",
  "#C1EEB8",
  "#B7EEDE",
  "#B8CCEE",
  "#D3B7EE",
  "#EEB7D7",
  "#DE9270",
  "#C6DE70"
];

// If a stage is selected the user will see it's color matching with line on chart (#90CAE5) and all unselected stages as grey. Otherwise if no stage is selected they will match their chart counterprt using the colors array.
export const getStageBackground = (
  expandedStageId: string | null,
  stageId: string,
  index: number
) => {
  if (expandedStageId === stageId) return colors[0];

  if (!Boolean(expandedStageId)) return colors[index];

  return "rgba(0,0,0,0.1)";
};

const airflowColors: string[] = ["#BEBEBE", "rgba(0, 0, 0, 0.1)"];

// Airflow is represented as #BEBEBE on the chart, but will match the color of the other unselected stages (rgba(0, 0, 0, 0.1)) if a stage is currently selected.)
export const getAirflowBackground = (
  expanded: boolean | string,
  id: string
) => {
  if (expanded === id) {
    return airflowColors[0];
  } else if (expanded) {
    return airflowColors[1];
  } else {
    return airflowColors[0];
  }
};
