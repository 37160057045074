/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaintBoothSetPoint } from '../models/PaintBoothSetPoint';
import type { PaintBoothSetPointNew } from '../models/PaintBoothSetPointNew';
import type { PaintBoothSetPointReturn } from '../models/PaintBoothSetPointReturn';
import type { PaintBoothSetPointUpdates } from '../models/PaintBoothSetPointUpdates';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PaintBoothSetPointsService {
  /**
   * Create Set Point
   * @param buildingId
   * @param requestBody
   * @returns PaintBoothSetPoint Successful Response
   * @throws ApiError
   */
  public static createSetPoint(
    buildingId: string,
    requestBody: PaintBoothSetPointNew,
  ): CancelablePromise<PaintBoothSetPoint> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/buildings/{building_id}/paint-booths/{booth_id}/set-points/',
      path: {
        'building_id': buildingId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `\`set_point_time\` a datetime in the past.`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Set Point Range
   * @param buildingId
   * @param boothId
   * @param startTs
   * @param endTs
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getSetPointRange(
    buildingId: string,
    boothId: string,
    startTs: string,
    endTs?: (string | null),
  ): CancelablePromise<(Array<PaintBoothSetPointReturn> | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/paint-booths/{booth_id}/set-points/',
      path: {
        'building_id': buildingId,
        'booth_id': boothId,
      },
      query: {
        'start_ts': startTs,
        'end_ts': endTs,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Set Point
   * Get the set point at the given `created_at` time
   * @param buildingId
   * @param boothId
   * @param createdAt
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getSetPoint(
    buildingId: string,
    boothId: string,
    createdAt: string,
  ): CancelablePromise<(PaintBoothSetPointReturn | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/paint-booths/{booth_id}/set-points/{created_at}',
      path: {
        'building_id': buildingId,
        'booth_id': boothId,
        'created_at': createdAt,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Update Set Point
   * @param buildingId
   * @param boothId
   * @param createdAt
   * @param requestBody
   * @returns PaintBoothSetPointReturn Successful Response
   * @throws ApiError
   */
  public static updateSetPoint(
    buildingId: string,
    boothId: string,
    createdAt: string,
    requestBody: PaintBoothSetPointUpdates,
  ): CancelablePromise<PaintBoothSetPointReturn> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/buildings/{building_id}/paint-booths/{booth_id}/set-points/{created_at}',
      path: {
        'building_id': buildingId,
        'booth_id': boothId,
        'created_at': createdAt,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Set point not found`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Delete Set Point
   * @param buildingId
   * @param boothId
   * @param createdAt
   * @returns any Successful Response
   * @throws ApiError
   */
  public static deleteSetPoint(
    buildingId: string,
    boothId: string,
    createdAt: string,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/buildings/{building_id}/paint-booths/{booth_id}/set-points/{created_at}',
      path: {
        'building_id': buildingId,
        'booth_id': boothId,
        'created_at': createdAt,
      },
      errors: {
        404: `Set point not found`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get User Set Point
   * Get the chosen set point the user/booth for the overview page.
   * @param buildingId
   * @param boothId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getUserSetPoint(
    buildingId: string,
    boothId: string,
  ): CancelablePromise<(PaintBoothSetPointReturn | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/paint-booths/{booth_id}/set-points/user-select/',
      path: {
        'building_id': buildingId,
        'booth_id': boothId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Save User Set Point
   * Save the set point the user has chosed for the paint booth for the overview page.
   * @param buildingId
   * @param boothId
   * @param createdAt
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public static saveUserSetPoint(
    buildingId: string,
    boothId: string,
    createdAt: string,
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/buildings/{building_id}/paint-booths/{booth_id}/set-points/user-select/{created_at}',
      path: {
        'building_id': buildingId,
        'booth_id': boothId,
        'created_at': createdAt,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
