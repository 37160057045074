import BuildingFiltersComponent from "../building-filters/building-filters.component";
import BuildingsToolbarComponent from "../buildings-toolbar/buildings-toolbar.component";
import { makeStyles } from "tss-react/mui";
import { Paper } from "@mui/material";
import useGetBuildingView from "../hooks/useGetBuildingView";
import { FormattedBuildingWithEntity } from "src/shared/types/building";
import Table from "src/shared/components/Table/Table";

type Props = {
  buildings: FormattedBuildingWithEntity[];
};

const BuildingsListComponent = ({ buildings }: Props) => {
  const { classes } = useStyles();
  const { isMapView, isListView } = useGetBuildingView();

  return (
    <div data-testid="buildings-list">
      <Paper
        data-testid="paper"
        className={isMapView ? classes.filtersFixed : classes.filters}>
        <BuildingFiltersComponent />
        {!isMapView && <BuildingsToolbarComponent />}
      </Paper>
      <Table
        showHeader={isListView}
        type="building"
        data={buildings}
      />
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    filtersFixed: {
      display: "flex",
      overflow: "auto",
      justifyContent: "space-between",
      marginBottom: 10,
      padding: 16,
      borderRadius: 0,
      boxShadow:
        "0px 3px 5px rgba(0, 0, 0, 0.05), 0px 1px 18px rgba(0, 0, 0, 0.03), 0px 6px 10px rgba(0, 0, 0, 0.04);"
    },
    filters: {
      backgroundColor: "transparent",
      boxShadow: "none",
      display: "flex",
      overflow: "auto",
      justifyContent: "space-between",
      padding: 16,
      paddingBottom: 5,
      borderRadius: 0,
      marginBottom: 8
    },
    bottomBorderMap: {
      display: "none"
    }
  };
});

export default BuildingsListComponent;
