import { Pollutant, StatusColor } from "./aq-widget-enums";
import {
  GOOD,
  MODERATE,
  SENSITIVE,
  UNHEALTHY,
  VERY_UNHEALTHY,
  HAZARDOUS
} from "src/shared/constants/building-status";

export const formatMetric = (metric: string) => {
  switch (metric) {
    case Pollutant.aqi:
      return Pollutant.aqiTitle;
    case Pollutant.cotwo:
      return Pollutant.cotwoTitle;
    case Pollutant.voc:
      return Pollutant.vocTitle;
    case Pollutant.pmtwo:
      return Pollutant.pmtwoTitle;
    case Pollutant.pmten:
      return Pollutant.pmtenTitle;
    default:
      return "--";
  }
};

export const getStatusColor = (status: string) => {
  switch (status) {
    case GOOD:
      return StatusColor.good;
    case MODERATE:
      return StatusColor.moderate;
    case SENSITIVE:
      return StatusColor.sensitive;
    case UNHEALTHY:
      return StatusColor.unhealthy;
    case VERY_UNHEALTHY:
      return StatusColor.veryUnhealthy;
    case HAZARDOUS:
      return StatusColor.hazardous;
    default:
      return StatusColor.default;
  }
};
