import { makeStyles } from "tss-react/mui";
export const useStyles = makeStyles()(() => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      overflow: "hidden",
      width: 350
    },
    title: {
      display: "flex",
      alignItems: "center",
      justifyContent: "left",
      padding: "1em",
      paddingTop: "0.3em",
      paddingBottom: "0.3em",
      borderBottom: "1px solid #E9EAEA"
    },
    notificationTitle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "1em",
      paddingTop: "0.3em",
      paddingBottom: "0.3em",
      borderBottom: "1px solid #E9EAEA"
    },
    body: {
      flex: 1,
      display: "flex",
      height: "70%"
    },
    bodyContent: {
      flex: 1,
      display: "flex",
      flexDirection: "column"
    },
    list: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      overflow: "auto",
      padding: 0
    },
    actions: {
      display: "flex",
      justifyContent: "end",
      borderTop: "1px solid #E9EAEA",
      "& button": {
        margin: ".5em"
      },
      width: 350
    },
    doneIcon: {
      fontSize: 20,
      marginRight: "0.5em"
    },
    doneIconText: {
      fontSize: 10
    },
    loadMoreContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 8,
      paddingBottom: 8,
      width: "100%"
    },
    switch: {
      zoom: 0.75,
      marginRight: "1em"
    }
  };
});
