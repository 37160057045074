import moment from "moment";
import { Stage, ServiceLogReturn } from "src/utils/client";

type StageDatum = {
  date: number;
  value: number;
  showBullet?: boolean;
  title?: string;
  description?: string;
  worker?: string;
};

// Finds the index of the datum in a stage's data that matches a service log's date. If there is no match returns null.
function findClosestDatum(stageData: StageDatum[], logDate: number) {
  return stageData.reduce(
    (closestIndex: number, datum: StageDatum, currentIndex: number) => {
      const currentDatumFormatted = moment(datum.date).format("ll");
      const logDateFormatted = moment(logDate).format("ll");
      return currentDatumFormatted === logDateFormatted
        ? currentIndex
        : closestIndex;
    },
    null
  );
}

// Returns a string with new lines interspersed throughout so that a
// service log's description fits neatly within a line chart's tooltip.
// 25 characters are allowed on each line, and words are kept together.
// Only 4 lines are allowed in total, with the final line recieving an
// ellipsis if the entire description is still too long.
function formatDescription(description: string) {
  const maxLength = 35;
  const words = description.split(" ");
  let newLines = 0;
  let formattedString = "";
  let currentLine = "";

  for (let word of words) {
    if (currentLine.length + word.length <= maxLength) {
      currentLine += ` ${word}`;
    } else {
      if (newLines === 3) {
        currentLine += "...";
        break;
      }
      formattedString += currentLine + "\n";
      currentLine = `${word}`;
      newLines++;
    }
  }
  if (currentLine.length > 0) {
    formattedString += currentLine;
  }
  return formattedString;
}

function formatTitle(title: string) {
  const maxLength = 60;
  const words = title.split(" ");
  let newLines = 0;
  let formattedString = "";
  let currentLine = "";

  for (let word of words) {
    if (currentLine.length + word.length <= maxLength) {
      currentLine += `${word} `;
    } else {
      if (newLines === 3) {
        currentLine += "...";
        break;
      }
      formattedString += currentLine + "\n";
      currentLine = `${word}`;
      newLines++;
    }
  }
  if (currentLine.length > 0) {
    formattedString += currentLine;
  }
  return formattedString;
}

// Creates new fields in a stage's data by using the above functions to
// 1. Find the logs related to the stage.
// 2. Find the closest datum within a stage's data for a log to attach to.
// 3. Format the service log's description
// Returns the stage's updated data for use in a line chart.
export function mergeStageAndLogData(
  stage: Stage,
  data: StageDatum[],
  serviceLogs: ServiceLogReturn[]
) {
  serviceLogs
    // @ts-ignore
    .filter(serviceLog => serviceLog?.details?.ids?.stage_id === stage.id)
    .map(({ time, user, details }) => {
      const date = new Date(time).getTime();
      const closestDatum = findClosestDatum(data, date);
      const logDatum = data[closestDatum];
      if (logDatum) {
        const formatedTitle = formatTitle(details.title);
        const formatedDescription = formatDescription(details.notes);

        logDatum.showBullet = true;
        logDatum.title = `${formatedTitle}`;
        logDatum.description = `${formatedDescription}`;
        logDatum.worker = `${user?.name}`;
      }
    });
  return data;
}
