import { useEffect, useState, Dispatch, SetStateAction } from "react";
import ModalComponent from "src/shared/components/modal/modal.component";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GridViewIcon from "@mui/icons-material/GridView";
import { widgets } from "../widgets/widgets-config";
import { makeStyles } from "tss-react/mui";
import { IWidget } from "../view/types";
import {
  Button,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import WidgetPreview from "../components/WidgetPreview/WidgetPreview";
import useOverview from "src/hooks/useOverview";
import useGetUserPages from "src/hooks/useGetUserWidgets";
import { useParams } from "react-router";
import { formatWidget } from "./utils";

type Props = {
  setWidgets: Dispatch<SetStateAction<IWidget[]>>;
};

const WidgetsModalComponent = (props: Props) => {
  const { setWidgets } = props;
  const { classes } = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const tabId = Number(useParams()?.tabId);
  const { isWidgetModalOpen, closeWidgetModal, setEditing } = useOverview();
  const { pages } = useGetUserPages();
  const existingWidgets =
    pages &&
    // @ts-ignore
    (pages[tabId]?.widgets?.body || pages[tabId]?.widgets);
  const [bodyWidgets, setBodyWidgets] = useState([]);
  const [widgetsToAdd, setWidgetsToAdd] = useState([]);
  const [availableView, setAvailableView] = useState("image");
  const [toAddView, setToAddleView] = useState("list");
  const [filterText, setFilterText] = useState("");

  const close = () => {
    closeWidgetModal();
    setEditing(false);
  };

  useEffect(() => {
    setWidgetsToAdd([]);
  }, [isWidgetModalOpen]);

  useEffect(() => {
    let widgetsList = Object.values(widgets);
    setBodyWidgets(widgetsList);
  }, [widgets]);

  useEffect(() => {
    if (filterText) {
      let widgetsList = Object.values(widgets);
      let regexp = new RegExp(`${filterText}*`, "i");
      setBodyWidgets(widgetsList.filter(item => regexp.test(item.name)));
    } else {
      let widgetsList = Object.values(widgets);
      setBodyWidgets(widgetsList);
    }
  }, [filterText]);

  return (
    <ModalComponent
      open={isWidgetModalOpen}
      onClose={close}>
      <div
        data-testid="widgets-modal"
        className={classes.root}>
        <Typography variant="h6">Widgets {isMd && "md"}</Typography>
        <div
          className={classes.body}
          style={{ overflow: isMd ? "auto" : "hidden" }}>
          <Grid
            container
            columnSpacing={2}
            sx={{ padding: 1, height: isMd ? "auto" : "100%" }}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ height: isMd ? "auto" : "calc(100% - 100px)" }}>
              <div className={classes.listTitle}>
                <Typography gutterBottom>Available Widgets</Typography>
                <ToggleButtonGroup
                  color="primary"
                  size="small"
                  exclusive
                  value={availableView}
                  onChange={(_event, value) => {
                    if (value) {
                      setAvailableView(value);
                    }
                  }}>
                  <ToggleButton value="list">
                    <FormatListBulletedIcon />
                  </ToggleButton>
                  <ToggleButton value="image">
                    <GridViewIcon />
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
              <div>
                <TextField
                  className={classes.filter}
                  label="Search"
                  value={filterText}
                  onChange={({ target: { value } }) => {
                    setFilterText(value);
                  }}
                />
              </div>
              <div className={classes.column}>
                {bodyWidgets.map(widget => {
                  return (
                    <WidgetPreview
                      key={`widget_preview_${widget.name}`}
                      widget={widget}
                      widgetsToAdd={widgetsToAdd}
                      setWidgetsToAdd={setWidgetsToAdd}
                      view={availableView}
                    />
                  );
                })}
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ height: "calc(100% - 100px)" }}>
              <div style={{ height: "100%" }}>
                <div
                  className={classes.listTitle}
                  style={{ marginBottom: 8 }}>
                  <Typography gutterBottom>Widgets to be added</Typography>
                  <ToggleButtonGroup
                    color="primary"
                    size="small"
                    exclusive
                    value={toAddView}
                    onChange={(_event, value) => {
                      if (value) {
                        setToAddleView(value);
                      }
                    }}>
                    <ToggleButton value="list">
                      <FormatListBulletedIcon />
                    </ToggleButton>
                    <ToggleButton value="image">
                      <GridViewIcon />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
                <div className={classes.column}>
                  {widgetsToAdd.map((widget, index) => (
                    <WidgetPreview
                      key={`widget_add_preview_${index}`}
                      widget={{ ...widget, index }}
                      view={toAddView}
                      toBeAdded
                      widgetsToAdd={widgetsToAdd}
                      setWidgetsToAdd={setWidgetsToAdd}
                    />
                  ))}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={classes.actions}>
          <Button onClick={close}>Cancel</Button>
          <Button
            variant="contained"
            onClick={() => {
              setWidgets([
                ...existingWidgets,
                ...widgetsToAdd.map(formatWidget)
              ]);
              close();
            }}>
            Add Widgets
          </Button>
        </div>
      </div>
    </ModalComponent>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    content: {
      display: "flex"
    },
    root: {},
    buttonsContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: "100%",
      gap: 16
    },
    body: {
      height: "calc(90vh - 60px)"
    },
    column: {
      height: "100%",
      overflow: "auto",
      padding: 4
    },
    listTitle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    filter: {
      width: "100%",
      marginBottom: 8
    },
    actions: {
      display: "flex",
      gap: 8,
      justifyContent: "end"
    }
  };
});

export default WidgetsModalComponent;
