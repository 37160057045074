import { useGetUserSettingsQuery } from "src/store/services/settings/settings.service";
import { useAppSelector } from "src/hooks/useAppSelector";
import { getUserId } from "src/store/auth/selectors";
import { useEffect } from "react";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import {
  setPressureSetting,
  setTemperatureSetting
} from "src/store/reports/reducer";
export const useGetUserSettings = () => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector(getUserId);
  const {
    data: user,
    isLoading,
    isFetching,
    isError
  } = useGetUserSettingsQuery(userId);

  const temperature = user?.settings?.unit_prefs?.temperature;
  const pressure = user?.settings?.unit_prefs.pressure;

  useEffect(() => {
    dispatch(setTemperatureSetting(temperature));
    dispatch(setPressureSetting(pressure));
  }, [user]);

  return {
    isLoading,
    isError,
    isFetching,
    userSettingsExist: Boolean(user) && !isFetching && !isLoading,
    pressure,
    aqi_scale: user?.settings?.aqi_scale,
    langauge: user?.settings?.language,
    default_webportal_view: user?.settings.default_webportal_view,
    temperature,
    phoneNumber: user?.phone_number,
    userSettings: user?.settings?.unit_prefs,
    sendAlerts: user?.settings?.send_alerts,
    tvoc: null
  };
};
