import { Grid, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { makeStyles } from "tss-react/mui";
import useGetNotificationsAndSubscriptions from "../hooks/useGetNotificationsAndSubscriptions";

const NotificationsHeaderComponent = () => {
  const { handleSort, sort } = useGetNotificationsAndSubscriptions();
  const { classes } = useStyles();
  const { bySubscribed, byEnabled } = sort;
  const activeStyle = { fontWeight: 600, color: "#0795bb" };

  return (
    <div
      data-testid="notifications-header"
      className={classes.root}>
      <Grid
        container
        columnSpacing={1}>
        <Grid
          item
          xs={2}
          md={3}
          lg={3}>
          <Typography variant="subtitle1">Notification Title</Typography>
        </Grid>
        <Grid
          item
          xs={3}
          lg={3}>
          <Typography variant="subtitle1">Notification Description</Typography>
        </Grid>
        <Grid
          item
          xs={2}
          lg={2}>
          <Typography
            variant="subtitle1"
            style={{ display: "flex", alignItems: "center", gap: "2px" }}>
            Last Alert
            <Tooltip
              title="The date and time displayed indicate the last time that this notification was generated based on the threshold set."
              arrow
              placement="top">
              <InfoOutlinedIcon fontSize="small" />
            </Tooltip>
          </Typography>
        </Grid>
        <Grid
          item
          xs={1}
          lg={1}>
          <Typography
            variant="subtitle1"
            style={{ display: "flex", alignItems: "center", gap: "2px" }}>
            In Alert
            <Tooltip
              title="This is a True/False flag to indicate if the specified Notification definition is currently In An Alerted State. A alert definition will become Out Of Alert State when the latest device value is outside of the defined threshold."
              arrow
              placement="top">
              <InfoOutlinedIcon fontSize="small" />
            </Tooltip>
          </Typography>
        </Grid>
        <Grid
          item
          xs={1}
          lg={1}
          data-testid="subscribe-column"
          onClick={() => handleSort("subscribed")}
          sx={{ cursor: "pointer" }}>
          <Typography
            variant="subtitle1"
            data-testid="subscribe-column-text"
            sx={bySubscribed && activeStyle}
            style={{ display: "flex", alignItems: "center", gap: "2px" }}>
            Subscribed{" "}
            <Tooltip
              title="Notifications are defined at a Building Level. Users can individually choose to Subscribe or Unsubscribe to a particular Notification. If you would like to Subscribe to a Notification, please do so using the Action menu to the right."
              arrow
              placement="top">
              <InfoOutlinedIcon fontSize="small" />
            </Tooltip>
          </Typography>
        </Grid>
        <Grid
          item
          xs={1}
          md={1}
          lg={1}
          data-testid="enable-column"
          onClick={() => handleSort("enabled")}
          sx={{ cursor: "pointer" }}>
          <Typography
            variant="subtitle1"
            data-testid="enable-column-text"
            sx={byEnabled && activeStyle}
            style={{ display: "flex", alignItems: "center", gap: "2px" }}>
            Enabled{" "}
            <Tooltip
              title="Notifications can be enabled or disabled from Alerting if so desired. Note that this will disable the notification for all users who Subscribe to that notification. To enable/disable notification, please do so using the Action menu to the right."
              arrow
              placement="top">
              <InfoOutlinedIcon fontSize="small" />
            </Tooltip>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {}
  };
});

export default NotificationsHeaderComponent;
