import { useMemo, useState } from "react";
import moment, { Moment } from "moment";
import classNames from "classnames";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import pdfReport from "src/assets/pdf_report.png";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { renderWeekPickerDay } from "src/utils/date-picker.utils";

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { useStyles } from "./full-report-form.style";
import LoaderComponent from "src/shared/components/loader/loader.component";
import { useReportMutation } from "src/store/services/reports/reportsService";
import toast from "react-hot-toast";
import useGetBuildings from "src/hooks/useGetBuildings";
import { useParams } from "react-router-dom";

enum ReportPresets {
  LAST_MONTH = "last_month",
  LAST_TWO_MONTHS = "last_two_months",
  RECENT_TWO_WEEKS = "recent_two_weeks",
  RECENT_ONE_MONTH = "recent_one_month",
  RECENT_TWO_MONTHS = "recent_two_months",
  ALWAYS_CURRENT_TWO_WEEKS = "always_current_two_weeks",
  ALWAYS_CURRENT_ONE_MONTH = "always_current_one_month"
}

const FullReportFormComponent = () => {
  const { buildings: facilities } = useGetBuildings();
  const { classes } = useStyles();
  const [startDate, setStartDate] = useState<Moment | number>(
    moment().subtract(1, "month").startOf("day")
  );
  const [endDate, setEndDate] = useState<Moment | number>(
    moment().endOf("day")
  );
  const [selectedBuildingId, setSelectedBuildingId] = useState<string>(null);
  const [companyId, setCompanyId] = useState<string>(null);
  const [selectedPreset, setSelectedPreset] = useState<string>("");

  const { category } = useParams();

  const [getReport, { isLoading }] = useReportMutation();

  const reportType: string = "building";

  const handleCreateReport = () => {
    const startTs: any =
      typeof startDate === "object" ? startDate.format() : undefined;
    const endTs: any =
      typeof endDate === "object" ? endDate?.format() : undefined;

    getReport({
      companyId: companyId,
      buildingId: selectedBuildingId,
      startTs: startTs,
      endTs: endTs
    })
      .then((res: any) => {
        let href = URL.createObjectURL(res.data);

        var link = document.createElement("a");
        link.href = href;
        link.download = `building_report.pdf`;
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        toast.success("PDF downloaded");
      })
      .catch(_ => {
        toast.error("Failed to generate PDF");
      });
    return;
  };

  const disabled = () => {
    if (!startDate || !endDate) return true;

    if (!Boolean(selectedBuildingId)) {
      if (
        (reportType === "account" && !companyId) ||
        (reportType === "building" && !selectedBuildingId)
      ) {
        return true;
      }
    }
    return false;
  };

  const onLastMonthChanged = () => {
    setStartDate(moment().subtract(1, "month").startOf("month").startOf("day"));
    setEndDate(moment().subtract(1, "month").endOf("month").endOf("day"));
    setSelectedPreset(ReportPresets.LAST_MONTH);
  };

  const onLastTwoMonthChanged = () => {
    setStartDate(moment().subtract(2, "month").startOf("month").startOf("day"));
    setEndDate(moment().subtract(1, "month").endOf("month").endOf("day"));
    setSelectedPreset(ReportPresets.LAST_TWO_MONTHS);
  };

  const onCurrentTwoWeeksChanged = () => {
    setStartDate(moment().subtract(2, "week").startOf("day"));
    setEndDate(moment().endOf("day"));
    setSelectedPreset(ReportPresets.ALWAYS_CURRENT_TWO_WEEKS);
  };

  const filteredFacilities = useMemo(() => {
    if (!facilities.length) return null;

    return facilities.filter(facility => {
      if (category === "iaq") {
        return facility.has_rooms;
      }
      if (category === "kettle") {
        return facility.has_kettles;
      }
    });
  }, [facilities, category]);

  if (isLoading) return <LoaderComponent />;

  return (
    <div
      data-testid="full-report"
      className={classes.root}>
      <div className={classes.container}>
        <Typography
          variant="h4"
          gutterBottom>
          Generate Report
        </Typography>
        <Grid
          container
          spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}>
            <div>
              {reportType === "building" && (
                <FormControl fullWidth>
                  <InputLabel id="facility-label">Facility *</InputLabel>
                  <Select
                    labelId="facility-label"
                    value={selectedBuildingId || ""}
                    label="Facility"
                    sx={{ backgroundColor: "#fff" }}
                    onChange={({ target: { value } }) => {
                      const facility = facilities.find(f => f.id === value);
                      setSelectedBuildingId(value);
                      setCompanyId(facility.companyId);
                    }}
                    inputProps={{
                      "data-testid": "facility-select"
                    }}>
                    {filteredFacilities?.map(facility => {
                      return (
                        <MenuItem
                          key={`facility_option_report_${facility.id}`}
                          value={facility.id}>
                          {facility.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            </div>
          </Grid>
          <Grid
            item
            container
            xs={12}
            spacing={2}>
            <Grid
              item
              xs={12}
              md={7}>
              <Box>
                <div className={classes.reportStep}>
                  <div className={classes.reportStepNumber}>1</div>{" "}
                  <span>Select the report you want to generate</span>
                </div>
                <div className={classes.optionsContainer}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={6}>
                      <Box className={classNames(classes.optionItem)}>
                        <img
                          src={pdfReport}
                          alt="PDF Report"
                          className={classes.dashboardImage}
                        />
                        <div className={classNames(classes.itemTitle)}>
                          <CheckCircleOutlineIcon />
                          PDF Report
                        </div>
                        <Typography
                          variant="body2"
                          className={classes.itemDescription}>
                          PDF Report you can print the report...
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </div>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}>
              <Box>
                <div className={classes.reportStep}>
                  <div className={classes.reportStepNumber}>2</div>{" "}
                  <span>Select a predefined timeframe or a custom date</span>
                </div>
                <div>
                  <Button
                    data-testid="change-btn"
                    className={classes.btn}
                    onClick={onLastMonthChanged}>
                    {selectedPreset === ReportPresets.LAST_MONTH ? (
                      <CheckCircleOutlineIcon />
                    ) : (
                      <RadioButtonUncheckedIcon />
                    )}
                    Last Month
                  </Button>
                  <Button
                    data-testid="change-btn"
                    className={classes.btn}
                    onClick={onLastTwoMonthChanged}>
                    {selectedPreset === ReportPresets.LAST_TWO_MONTHS ? (
                      <CheckCircleOutlineIcon />
                    ) : (
                      <RadioButtonUncheckedIcon />
                    )}
                    Last 2 Months
                  </Button>
                  <Button
                    data-testid="change-btn"
                    className={classes.btn}
                    onClick={onCurrentTwoWeeksChanged}>
                    {selectedPreset ===
                    ReportPresets.ALWAYS_CURRENT_TWO_WEEKS ? (
                      <CheckCircleOutlineIcon />
                    ) : (
                      <RadioButtonUncheckedIcon />
                    )}
                    Always current 2 weeks
                  </Button>
                </div>
                <div className={classes.datePickerSection}>
                  {startDate !== -1 && startDate !== -2 && (
                    <div className={classes.datePickerGroup}>
                      <div className={classes.datePicker}>
                        <DatePicker
                          label="From date *"
                          value={startDate}
                          onChange={newValue => {
                            setStartDate(newValue);
                            setSelectedPreset("");
                          }}
                          renderInput={params => (
                            <TextField
                              sx={{ backgroundColor: "#fff" }}
                              {...params}
                            />
                          )}
                          renderDay={renderWeekPickerDay}
                        />
                      </div>
                      <div className={classes.datePicker}>
                        <DatePicker
                          label="To date *"
                          renderDay={renderWeekPickerDay}
                          value={endDate}
                          onChange={newValue => {
                            setEndDate(newValue);
                            setSelectedPreset("");
                          }}
                          renderInput={params => (
                            <TextField
                              sx={{ backgroundColor: "#fff" }}
                              {...params}
                            />
                          )}
                        />
                      </div>
                    </div>
                  )}
                  {startDate === -1 && (
                    <Typography className={classes.always}>
                      Always current 2 weeks
                    </Typography>
                  )}
                </div>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Box className={classes.buttonsGroup}>
          <Button
            variant="contained"
            onClick={handleCreateReport}
            disabled={disabled()}>
            Generate Report
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default FullReportFormComponent;
