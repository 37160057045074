import { Box, Stack, Typography } from "@mui/material";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import { setSelectedKettleType } from "src/store/kettle/reducer";
import { getSelectedKettleType } from "src/store/kettle/selector";
import { KettleChartColors } from "./kettle.details";

export enum KettleTypes {
  INLET = "Inlet",
  OUTLET = "Outlet",
  DIFFERENTIAL = "Differential"
}

interface KettleDetailsCardProps {
  type: KettleTypes;
  pressure: string;
  alertColor?: string;
  isDifferentialPressureOutAlert?: boolean;
}

const KettleDetailsCard = ({
  type,
  pressure,
  alertColor,
  isDifferentialPressureOutAlert
}: KettleDetailsCardProps) => {
  const dispatch = useAppDispatch();
  const selectedKettleType = useAppSelector(getSelectedKettleType);
  const alertText = isDifferentialPressureOutAlert ? "Bad" : "Good";
  const isDiffrentialPressure: boolean = type === KettleTypes.DIFFERENTIAL;
  const lineColor =
    KettleChartColors[type.toUpperCase() as keyof typeof KettleChartColors];

  const handleClick = () => {
    if (type === KettleTypes.DIFFERENTIAL) {
      return;
    }

    const newType =
      selectedKettleType === type.toLowerCase()
        ? null
        : (type.toLowerCase() as "outlet" | "inlet");

    dispatch(setSelectedKettleType(newType));
  };

  return (
    <Stack
      data-testid={"container"}
      style={styles.card}
      onClick={handleClick}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}>
        <Typography>{type} pressure</Typography>
        <Typography>{pressure} PSI</Typography>
      </Stack>
      {isDiffrentialPressure ? (
        <Box
          data-testid={"alert-box"}
          style={{
            ...styles.alert,
            backgroundColor: alertColor,
            alignSelf: "start"
          }}>
          {alertText}
        </Box>
      ) : (
        <Box
          data-testid={"line-box"}
          height={"3px"}
          width={"20px"}
          bgcolor={lineColor}
        />
      )}
    </Stack>
  );
};

const styles = {
  card: {
    border: "2px solid rgba(0,0,0,0.1)",
    borderRadius: 8,
    padding: "5px 10px 15px 10px",
    margin: "10px 10px 10px 0",
    minHeight: 80,
    justifyContent: "space-between",
    cursor: "pointer"
  },
  alert: {
    padding: "3px 5px",
    borderRadius: 8,
    color: "white"
  }
};

export default KettleDetailsCard;
