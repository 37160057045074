import { useGetUserSettings } from "src/sections/settings/hooks";
import { TemperatureUnits } from "src/utils/client";

export default function useUserCelsiusSettings() {
  const { temperature } = useGetUserSettings();

  return (
    Boolean(temperature) &&
    temperature.toLowerCase() === TemperatureUnits.CELCIUS
  );
}
