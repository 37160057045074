import { ProgressBarData } from "../types";
import { formatMetric } from "./aq-widget-utils";
import { Measurement, PollInfoReturn } from "src/utils/client";

export const calculatePercentage = (
  value: number,
  min: number,
  max: number
) => {
  return ((value - min) / (max - min)) * 100;
};

// Function to create the correct data shape for ProgressBarsComponent.
// Example Object = {name: "Humidity", value: 24, percentage: 24, status: "Moderate"}
export const shapeProgressBarData = (
  keys: string[],
  data: Record<string, Measurement>,
  pollInfoDict: Record<string, PollInfoReturn>,
  temperature: string
): ProgressBarData[] => {
  if (data && pollInfoDict) {
    const arr = [];

    for (let key of keys) {
      const pollInfo = pollInfoDict[key];
      const value =
        typeof data[key]?.value === "number" ? data[key].value : "--";

      const obj: any = {
        name: "",
        value: value,
        percentage: 0,
        status: "",
        unit: ""
      };

      obj.name = pollInfo?.short_name ? pollInfo.short_name : formatMetric(key);

      obj.unit = pollInfo?.default_unit?.symbol;

      if (key === "t" && temperature === "fahrenheit") {
        typeof value === "number" ? (obj.value = value * (9 / 5) + 32) : null;
        obj.unit = "°F";
      }

      if (pollInfo?.statuses?.thresholds) {
        for (let range of pollInfo.statuses.thresholds) {
          if (obj.value <= range.high && obj.value >= range.low) {
            obj.status = range.status;
          }
        }
      }

      obj.percentage =
        typeof value === "number"
          ? calculatePercentage(
              obj.value,
              pollInfo?.min_value,
              pollInfo?.max_value
            )
          : null;

      arr.push(obj);
    }
    return arr;
  }
};
