/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcknowledgementStatus } from '../models/AcknowledgementStatus';
import type { AlertAcknowledgementNew } from '../models/AlertAcknowledgementNew';
import type { AlertNew } from '../models/AlertNew';
import type { AlertReturn } from '../models/AlertReturn';
import type { AlertUpdates } from '../models/AlertUpdates';
import type { ClickSource } from '../models/ClickSource';
import type { UpdateManyAlertsRequest } from '../models/UpdateManyAlertsRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AlertsService {
  /**
   * Update Many Alerts
   * Bulk update list of alerts as `is_read` or `high_priority`.
   *
   * Returns:
   * - `True`, if everything was successful.
   * - A list of the updates that failed for some reason.
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static updateManyAlerts(
    requestBody: Array<UpdateManyAlertsRequest>,
  ): CancelablePromise<(boolean | Array<UpdateManyAlertsRequest>)> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/alerts/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Database Error`,
        404: `Invalid Object Id`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Alerts For User
   * @param start
   * @param end
   * @param alertStateFilter
   * @returns AlertReturn Successful Response
   * @throws ApiError
   */
  public static getAlertsForUser(
    start?: (string | null),
    end?: (string | null),
    alertStateFilter?: (AcknowledgementStatus | null),
  ): CancelablePromise<Array<AlertReturn>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/alerts/',
      query: {
        'start': start,
        'end': end,
        'alert_state_filter': alertStateFilter,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Alerts For Notification
   * @param notificationId
   * @param start
   * @param end
   * @param alertStateFilter
   * @returns AlertReturn Successful Response
   * @throws ApiError
   */
  public static getAlertsForNotification(
    notificationId: string,
    start?: (string | null),
    end?: (string | null),
    alertStateFilter?: (AcknowledgementStatus | null),
  ): CancelablePromise<Array<AlertReturn>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/notifications/{notification_id}/alerts/',
      path: {
        'notification_id': notificationId,
      },
      query: {
        'start': start,
        'end': end,
        'alert_state_filter': alertStateFilter,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Alert
   * @param notificationId
   * @param alertId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getAlert(
    notificationId: string,
    alertId: string,
  ): CancelablePromise<(AlertReturn | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/notifications/{notification_id}/alerts/{alert_id}',
      path: {
        'notification_id': notificationId,
        'alert_id': alertId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Update Alert
   * Set `Alert.is_read` or `Alert.high_priority` attributes.
   * @param notificationId
   * @param alertId
   * @param requestBody
   * @returns AlertReturn Successful Response
   * @throws ApiError
   */
  public static updateAlert(
    notificationId: string,
    alertId: string,
    requestBody: AlertUpdates,
  ): CancelablePromise<AlertReturn> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/notifications/{notification_id}/alerts/{alert_id}',
      path: {
        'notification_id': notificationId,
        'alert_id': alertId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Database Error`,
        404: `Invalid Object Id`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Delete Alert
   * @param notificationId
   * @param alertId
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public static deleteAlert(
    notificationId: string,
    alertId: string,
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/notifications/{notification_id}/alerts/{alert_id}',
      path: {
        'notification_id': notificationId,
        'alert_id': alertId,
      },
      errors: {
        404: `Invalid Object Id`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Report Alert Click
   * Note:
   * Endpoint to report Alert clicks where ClickSource is email or SMS.
   * Webportal Alert clicks are recorded/created in other put endpoints.
   * @param notificationId
   * @param alertId
   * @param clickSource
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public static reportAlertClick(
    notificationId: string,
    alertId: string,
    clickSource: ClickSource,
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/notifications/{notification_id}/alerts/{alert_id}/click',
      path: {
        'notification_id': notificationId,
        'alert_id': alertId,
      },
      query: {
        'click_source': clickSource,
      },
      errors: {
        400: `Database Error`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Acknowledge Alert
   * @param notificationId
   * @param alertId
   * @param requestBody
   * @returns AlertReturn Successful Response
   * @throws ApiError
   */
  public static acknowledgeAlert(
    notificationId: string,
    alertId: string,
    requestBody: AlertAcknowledgementNew,
  ): CancelablePromise<AlertReturn> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/notifications/{notification_id}/alerts/{alert_id}/acknowledgements',
      path: {
        'notification_id': notificationId,
        'alert_id': alertId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Database Error`,
        404: `Invalid Object Id`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Check Individual Notification Definition
   * Checks if a notification is in alert.  If so, returns the Alert object, otherwise the `alert` attribute returned will be null.
   * In either case, the relevent data is returned under the `data` attribute to indicate why there is/is not an alert.
   *
   * If the notification checked is inactive, then the `data` key is a string message: "Notification is inactive".
   * @param notificationId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static checkIndividualNotificationDefinition(
    notificationId: string,
  ): CancelablePromise<(AlertNew | null)> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/notifications/{notification_id}/check/',
      path: {
        'notification_id': notificationId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
