import { apiService } from "../api";
import { UsersService } from "src/utils/client";
import { SettingsOptions } from "./types";
import { User } from "src/utils/client";

export const generalService = apiService
  .enhanceEndpoints({
    addTagTypes: ["USERSETTINGS", "OPTIONS"]
  })
  .injectEndpoints({
    endpoints: build => ({
      getSettingsOptions: build.query<SettingsOptions, void>({
        queryFn: async () => {
          const settingsOptions =
            await UsersService.avaiableUserSettingOptions();
          return { data: settingsOptions };
        },
        providesTags: ["OPTIONS"]
      }),
      getUserSettings: build.query<User, string>({
        queryFn: async userId => {
          const userSettings = await UsersService.getUser(userId);
          const timeout = setTimeout(() => {
            if (document) {
              let combo = document.querySelector(".goog-te-combo");
              if (combo) {
                combo["value"] = userSettings.settings.language;
                let event = new Event("change");
                combo.dispatchEvent(event);
              }
              clearTimeout();
            }
            clearTimeout(timeout);
          }, 1000);
          return { data: userSettings };
        },
        providesTags: ["USERSETTINGS"]
      }),
      updateUserSettings: build.mutation<User, any>({
        // @ts-ignore
        queryFn: async data => {
          const { userId, newUser } = data;
          return await UsersService.updateUser(userId, newUser);
        },
        invalidatesTags: ["USERSETTINGS"]
      })
    })
  });

export const {
  useGetSettingsOptionsQuery,
  useGetUserSettingsQuery,
  useUpdateUserSettingsMutation
} = generalService;
