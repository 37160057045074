import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";

const selectKettle = (state: RootState) => state.kettle;

export const getSelectedKettle = createSelector(
  selectKettle,
  state => state.selectedKettle
);

export const getKettleStartDate = createSelector(
  selectKettle,
  state => state.kettleStartDate
);

export const getKettleEndDate = createSelector(
  selectKettle,
  state => state.kettleEndDate
);

export const getKettleSearchTerm = createSelector(
  selectKettle,
  state => state.kettleSearchTerm
);

export const getSelectedKettleOutlet = createSelector(
  selectKettle,
  state => state.selectedOutlet
);

export const getSelectedKettleType = createSelector(
  selectKettle,
  state => state.selectedKettleType
);

export const getKettleChartGranularity = createSelector(
  selectKettle,
  state => state.granularity
);
