import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";

const selectServiceLog = (state: RootState) => state.serviceLog;

export const getServiceLogModalState = createSelector(
  selectServiceLog,
  state => state.serviceLogModalOpen
);

export const getSelectedServiceLog = createSelector(
  selectServiceLog,
  state => state.selectedServiceLog
);

export const getExpandedLog = createSelector(
  selectServiceLog,
  state => state.expandedLog
);

export const getDownloadModalState = createSelector(
  selectServiceLog,
  state => state.downloadModalOpen
);
export const getEditServiceLogModalState = createSelector(
  selectServiceLog,
  state => state.editServiceLogModalOpen
);
