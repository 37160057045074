import {
  Backdrop,
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Skeleton
} from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import assets from "src/utils/config-assets";
import { getSkelWrapperStyle, getSkelStyle } from "../../view/overview.style";

type IWelcome = {
  classes: any;
  loading: boolean;
};

const Welcome = ({ classes, loading }: IWelcome) => {
  return (
    <Backdrop
      open={true}
      transitionDuration={{ exit: 500 }}>
      <Box
        display="div"
        style={{ textAlign: "center" }}>
        <Box display="div">
          <img
            src={assets.logo.default}
            className={classes.logo}
          />
        </Box>
        <Box display="div">
          <Typography
            variant="h3"
            sx={{ color: "white", textShadow: "0 0 10px #666" }}>
            Welcome To Your Dashboard
          </Typography>
        </Box>
      </Box>
      {!loading && (
        <Card className={classes.tourCard}>
          <CardContent sx={{ paddingBottom: "0 !important" }}>
            <Typography
              gutterBottom
              variant="h5"
              component="div">
              Customize Your Dashboard
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              gutterBottom>
              You can add widgets, click the pencil below to get started.
            </Typography>
            <Box display="div">
              <Grid
                container
                spacing={1}>
                <Grid
                  item
                  xs={9}>
                  <Skeleton
                    animation={false}
                    variant="rectangular"
                    height={30}
                  />
                  <Skeleton
                    animation={false}
                    variant="rectangular"
                    sx={{ marginTop: 1 }}
                    width="100%"
                    height={100}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}>
                  <Skeleton
                    animation={false}
                    variant="rectangular"
                    height={"100%"}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box
              display="div"
              sx={getSkelWrapperStyle}>
              <Skeleton
                variant="circular"
                width={68}
                height={68}
                sx={getSkelStyle}
              />
              <EastIcon sx={{ fontSize: 38, paddingTop: 1 }} />
            </Box>
          </CardContent>
        </Card>
      )}
    </Backdrop>
  );
};

export default Welcome;
