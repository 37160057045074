import moment from "moment";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import { getStageName } from "../../activity-utils";
import { Grid, Typography } from "@mui/material";
import { ConnectionAlert, Stage } from "src/utils/client";
import useGetAppBreakPoints from "src/hooks/useGetAppBreakPoints";
import { useStyles } from "./connection-item.style";

type Props = {
  alert: ConnectionAlert;
  stages: Stage[];
  time: string;
};

const ConnectionAlertItem = ({ alert, stages, time }: Props) => {
  const { classes } = useStyles();
  const { isMd } = useGetAppBreakPoints();
  const reconnectedAt = alert?.reconnected_at;
  // @ts-ignore
  const stageName = getStageName(stages, alert?.device?.stage_id);

  return isMd ? (
    <Grid
      data-testid="connection"
      sm={12}
      xs={12}
      container
      className={classes.alert}>
      <Grid
        data-testid="connection-md"
        sm={12}
        xs={12}
        container
        className={classes.margin}>
        <Grid
          sm={8}
          xs={8}
          container
          className={classes.item}>
          <Grid
            item
            sm={2}
            xs={2}
            className={classes.iconItem}>
            <WifiOffIcon
              data-testid="wifi"
              fontSize="small"
              className={classes.connection}
              style={
                reconnectedAt
                  ? { background: "#4CAF50" }
                  : { background: "rgb(232, 37, 71)" }
              }
            />
          </Grid>
          <Grid
            item
            sm={6}
            xs={6}>
            <Typography
              className={classes.alertTitle}
              style={
                reconnectedAt
                  ? { color: "green" }
                  : { color: "rgb(232, 37, 71)" }
              }>
              {reconnectedAt ? "Signal Regained" : "Signal Lost"}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          sm={4}
          xs={4}
          className={classes.stageItem}>
          <Typography className={classes.stage}>{stageName}</Typography>
        </Grid>
      </Grid>
      <Grid
        item
        sm={12}
        xs={12}
        className={classes.descriptionItem}>
        <div>
          <div className={classes.descriptionPair}>
            <Typography className={classes.descriptionKey}>Device:</Typography>
            <Typography className={classes.descriptionValue}>
              {alert?.device?.name}
            </Typography>
          </div>
          <div className={classes.descriptionPair}>
            <Typography className={classes.descriptionKey}>
              Lost Connection:
            </Typography>
            <Typography className={classes.descriptionValue}>
              {moment(alert?.disconnected_at).format("lll")}
            </Typography>
          </div>
          {reconnectedAt ? (
            <div className={classes.descriptionPair}>
              <Typography className={classes.descriptionKey}>
                Reconnected:
              </Typography>
              <Typography className={classes.descriptionValue}>
                {moment(alert?.reconnected_at).format("lll")}
              </Typography>
            </div>
          ) : (
            <div>
              <Typography className={classes.offline}>
                Device offline.
              </Typography>
            </div>
          )}
        </div>
      </Grid>
      <Grid
        item
        sm={12}
        xs={12}
        className={classes.margin}>
        <Typography className={classes.date}>
          {moment(time).format("lll")}
        </Typography>
      </Grid>
    </Grid>
  ) : (
    <Grid
      data-testid="connection"
      item
      lg={12}
      md={12}
      className={classes.alert}>
      <Grid
        data-testid="connection-lg"
        item
        lg={1}
        md={1}
        className={classes.connectionItem}>
        <WifiOffIcon
          data-testid="wifi-lg"
          fontSize="small"
          className={classes.connection}
          style={
            reconnectedAt
              ? { background: "#4CAF50" }
              : { background: "rgb(232, 37, 71)" }
          }
        />
      </Grid>
      <Grid
        item
        lg={2}
        md={2}
        className={classes.item}>
        <Typography
          className={classes.alertTitle}
          style={
            reconnectedAt ? { color: "green" } : { color: "rgb(232, 37, 71)" }
          }>
          {reconnectedAt ? "Signal Regained" : "Signal Lost"}
        </Typography>
      </Grid>
      <Grid
        item
        lg={2}
        md={2}
        className={classes.item}>
        <Typography className={classes.stage}>{stageName}</Typography>
      </Grid>
      <Grid
        item
        lg={9}
        md={10}
        className={classes.descriptionItem}>
        <div>
          <div className={classes.descriptionPair}>
            <Typography className={classes.descriptionKey}>Device:</Typography>
            <Typography> {alert?.device?.name}</Typography>
          </div>
          <div className={classes.descriptionPair}>
            <Typography className={classes.descriptionKey}>
              Lost Connection:
            </Typography>
            <Typography>
              {moment(alert?.disconnected_at).format("lll")}
            </Typography>
          </div>
          {reconnectedAt ? (
            <div className={classes.descriptionPair}>
              <Typography className={classes.descriptionKey}>
                Reconnected:
              </Typography>
              <Typography>
                {moment(alert?.reconnected_at).format("lll")}
              </Typography>
            </div>
          ) : (
            <div>
              <Typography className={classes.offline}>
                Device offline.
              </Typography>
            </div>
          )}
        </div>
      </Grid>
      <Grid
        item
        lg={2}
        md={2}
        className={classes.dateItem}>
        <Typography className={classes.date}>
          {moment(time).format("MMM D, YYYY")}
        </Typography>
        <Typography className={classes.date}>
          {moment(time).format("h:mm A")}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ConnectionAlertItem;
