import { makeStyles } from "tss-react/mui";
export const useStyles = makeStyles()(() => {
  return {
    root: {},
    gridHeader: {
      display: "none"
    },
    gridTableHeader: {
      paddingTop: 24
    },
    gridRoot: {
      backgroundColor: "white"
    }
  };
});
