import * as Sentry from "@sentry/react";

const isProduction = NODE_ENV === "production";

const isLocalInstance =
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1";

if (!isProduction) {
  console.log(`Running as ${NODE_ENV} on version ${VERSION}`);
}

const sentryConfig: Sentry.BrowserOptions = {
  enabled: !isLocalInstance,

  // Performance Monitoring
  tracesSampleRate: isProduction ? 1.0 : 1.0, //TODO: Reduce sample rate in production once app is stable enough
  // Session Replay
  // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysSessionSampleRate: isProduction ? 0.1 : 1.0,
  // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  replaysOnErrorSampleRate: 1.0,

  // Configuration
  dsn: "https://ffa380405f7c4217bb1636a1c2b3f480@o4504922205782016.ingest.sentry.io/4505595963572224",
  environment: NODE_ENV,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        // 'localhost', -- Disable tracing locally
        "https://dashboard.qlairdevelopment.com/",
        "https://dashboard.v2.i-qlair.com/"
      ]
    }),
    new Sentry.Replay()
  ]
};

Sentry.init(sentryConfig);
