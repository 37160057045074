import { useState, useMemo } from "react";
import { makeStyles } from "tss-react/mui";
import { WidgetSettings } from "../widgets-config";
import { GetCompanyIDFromBuildingId } from "src/utils/buildings.util";
import Error from "src/shared/components/Error/Error";
import { CircularProgress } from "@mui/material";
import {
  DeviceGroup,
  getParentsForInput
} from "../../utils/entity-selection-utils";
import useGetWidgetSettingsEntities from "src/hooks/useGetWidgetSettingsEntities";
import FormInput from "src/shared/components/form-input/form-input.component";
import FormAction from "src/shared/components/form-action/form-action";

const WaterKettleSettings = ({ onSave, currentProps }: WidgetSettings) => {
  const { classes } = useStyles();
  const [buildingId, setBuildingId] = useState<string>(
    currentProps.buildingId ?? ""
  );
  const [kettleId, setKettleId] = useState<string>(currentProps.ahuId ?? "");

  const { buildings, deviceParents, isLoading, isFetching, isError, refetch } =
    useGetWidgetSettingsEntities();

  const kettleBuildings = buildings?.filter(building => building.has_kettles);

  const kettlesForInput = useMemo(
    () => getParentsForInput(deviceParents, buildingId, DeviceGroup.kettle),
    [deviceParents, buildingId, isLoading, isFetching]
  );

  const handleOnSave = () => {
    onSave({
      ...currentProps,
      buildingId,
      companyId: GetCompanyIDFromBuildingId(buildingId, buildings),
      kettleId
    });
  };

  const handleOnKettleChange = (value: string) => {
    const selectedKettle = kettlesForInput?.find(kettle => kettle.id === value);
    if (selectedKettle) {
      setBuildingId(selectedKettle.building_id);
    }
    setKettleId(value);
  };
  return (
    <div
      data-testid="water-kettle-settings"
      className={classes.root}>
      {isLoading || isFetching ? (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      ) : (
        <>
          {isError ? (
            <Error
              error={isError}
              setRefetchAgain={refetch}
              showGenericMessage
            />
          ) : (
            <div>
              <FormInput
                label="Building"
                inputValue={buildingId}
                setValue={setBuildingId}
                array={kettleBuildings}></FormInput>

              <div className={classes.entitySelector}>
                <FormInput
                  label="Kettle"
                  inputValue={kettleId}
                  setValue={handleOnKettleChange}
                  array={kettlesForInput}></FormInput>
              </div>
            </div>
          )}
        </>
      )}
      <FormAction
        requiresConfig={currentProps.requiresConfig}
        onSave={handleOnSave}
        saveDisabled={!kettleId}></FormAction>
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {},
    loader: {
      display: "flex",
      justifyContent: "center"
    },

    entitySelector: {
      marginTop: "1em"
    }
  };
});

export default WaterKettleSettings;
