// import global configs here

import ourAirConfig from "./ourair.config.js";

export default {
  name: "Default Name",
  title: `${ourAirConfig.title} - ${NODE_ENV}`,
  assets: "default",
  apiUrl: `${API_BASE_URL}`,
  cookiesDomain: ".i-qlair.com",
  apiStage: "",
  theme: ourAirConfig.theme,
  amLicense: "",
  mapCenter: {
    lat: 38.78129,
    lng: -102.195998
  },
  currency: "USD"
};
