import { useMemo } from "react";
import { useParams } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from "@mui/material";
import { useGetPaintBoothsWithZonesQuery } from "src/store/services/booth/booth.service";
import PaintSensorDetails from "../PaintSensorDetails";
import { makeStyles } from "tss-react/mui";
import LoaderComponent from "src/shared/components/loader/loader.component";
import { useAppSelector } from "src/hooks/useAppSelector";
import {
  getChartSensorsIds,
  getChartZoneSensorsIds,
  getSelectedBoothSensorId
} from "src/store/booth/selector";
import { PaintBoothSensor } from "src/utils/client";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import {
  setChartSensorsIds,
  setChartZonesSensorsIds,
  setSelectedSensorId
} from "src/store/booth/reducer";
import { useGetChartIds } from "src/hooks/useGetBoothChartData";
const PaintBoothSidePanel = () => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const { buildingId, boothId } = useParams();
  const selectedSensorId = useAppSelector(getSelectedBoothSensorId);
  const chartZoneSensorsIds = useAppSelector(getChartZoneSensorsIds) ?? [];
  const chartSensorsIds = useAppSelector(getChartSensorsIds) ?? [];
  const { chartIds, sensors, isLoadingSensors, isErrorSensors } =
    useGetChartIds();
  const {
    data: booths,
    isLoading: isLoadingBooths,
    isError: isErrorBooths
  } = useGetPaintBoothsWithZonesQuery(buildingId);

  const loading = isLoadingBooths || isLoadingSensors;
  const error = isErrorBooths || isErrorSensors;

  const accordionData = useMemo(() => {
    if (!Boolean(booths) || !Boolean(sensors)) return [];
    const paintBooth = booths[boothId]?.paint_booth;
    const zones = booths[boothId]?.zones;

    const zonesWithSensors = Object.values(zones).map(zone => ({
      ...zone,
      sensors: sensors.filter(({ zone_id }) => zone_id === zone.id)
    }));
    return [{ ...paintBooth, zones: zonesWithSensors }];
  }, [sensors, booths]);

  const initialsIds = useMemo(() => {
    if (!accordionData.length) return [];
    if (chartIds.length > 1) {
      const parents = chartIds
        ?.map(id => findParentIds(accordionData, id))
        .flatMap(id => id);
      const filteredParentsIds = parents?.filter(
        (id, index) => parents.indexOf(id) === index
      );
      return [...chartIds, ...filteredParentsIds];
    }
    return findParentIds(accordionData, chartIds?.at(0));
  }, [accordionData]);

  return (
    <div>
      <Typography
        sx={{ fontWeight: 500, textTransform: "uppercase", mb: "20px" }}>
        General Information
      </Typography>
      {loading && initialsIds.length > 0 ? (
        <LoaderComponent
          data-testid="paintbooth-sidepanel-loader-component"
          customWidth={60}
        />
      ) : error ? (
        <Typography
          data-testId="error"
          className={classes.errorMessage}>
          An error occured please try again
        </Typography>
      ) : (
        <>
          {accordionData.map(booth => {
            return (
              <Accordion
                defaultExpanded={initialsIds.includes(booth.id)}
                classes={{ root: classes.root }}
                key={booth.id}>
                <AccordionSummary
                  classes={{ root: classes.summary }}
                  expandIcon={<ExpandMoreIcon sx={{ fontSize: "20px" }} />}>
                  {booth.name}
                </AccordionSummary>
                <AccordionDetails classes={{ root: classes.details }}>
                  {booth?.zones?.map(zone => {
                    return (
                      <Accordion
                        data-testid={`accordion-zones-${zone.id}`}
                        classes={{ root: classes.root }}
                        onChange={(
                          _event: React.SyntheticEvent,
                          expanded: boolean
                        ) => {
                          const sensorsIds = zone.sensors.map(({ id }) => id);
                          const ids = !expanded
                            ? chartZoneSensorsIds.filter(
                                id => !sensorsIds.includes(id)
                              )
                            : [...chartZoneSensorsIds, ...sensorsIds];
                          dispatch(setChartZonesSensorsIds(ids));
                          if (selectedSensorId) {
                            dispatch(setSelectedSensorId(null));
                          }
                        }}
                        defaultExpanded={initialsIds.includes(zone.id)}
                        key={zone.id}>
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon sx={{ fontSize: "20px" }} />
                          }
                          classes={{ root: classes.summary }}>
                          {zone.name}
                        </AccordionSummary>
                        <AccordionDetails classes={{ root: classes.details }}>
                          {zone?.sensors?.map(sensor => {
                            return (
                              <Accordion
                                classes={{ root: classes.root }}
                                onChange={(
                                  _event: React.SyntheticEvent,
                                  expanded: boolean
                                ) => {
                                  const ids = !expanded
                                    ? chartSensorsIds.filter(
                                        id => id !== sensor.id
                                      )
                                    : [...chartSensorsIds, sensor.id];
                                  dispatch(setChartSensorsIds(ids));
                                  if (selectedSensorId) {
                                    dispatch(setSelectedSensorId(null));
                                  }
                                }}
                                defaultExpanded={
                                  chartIds.length === 1
                                    ? selectedSensorId === sensor.id ||
                                      chartIds?.at(0) === sensor.id
                                    : false
                                }
                                key={sensor.id}>
                                <AccordionSummary
                                  classes={{ root: classes.summary }}
                                  expandIcon={
                                    <ExpandMoreIcon sx={{ fontSize: "20px" }} />
                                  }>
                                  {sensor.name}
                                </AccordionSummary>
                                <AccordionDetails
                                  classes={{ root: classes.details }}>
                                  <PaintSensorDetails
                                    buildingId={buildingId}
                                    boothId={boothId}
                                    zoneId={
                                      sensor.zone_id
                                    }></PaintSensorDetails>
                                </AccordionDetails>
                              </Accordion>
                            );
                          })}
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </>
      )}
    </div>
  );
};

export default PaintBoothSidePanel;

const useStyles = makeStyles()(() => {
  return {
    root: {
      width: "100%",
      color: "black !important",
      boxShadow: "none",
      minHeight: "24px !important",
      margin: "3px 0 !important",
      padding: "0 0 0 3px !important",
      "& .Mui-expanded": {
        minHeight: "24px !important",
        margin: "3px 0 !important"
      }
    },
    summary: {
      flexDirection: "row-reverse",
      minHeight: "24px !important",
      margin: "3px 0 !important",
      padding: "0",
      "& .MuiAccordionSummary-content": {
        margin: "3px 0 !important"
      }
    },
    details: {
      padding: "5px",
      "& .Mui-expanded": {
        padding: "0 0 0 0",
        margin: "3px 0 !important"
      }
    },
    errorMessage: {
      fontSize: "30px",
      display: "flex",
      justifyContent: "center",
      marginTop: "30px"
    }
  };
});

const findParentIds = (
  array: {
    zones: {
      sensors: PaintBoothSensor[];
      name: string;
      building_id: string;
      booth_id: string;
      id: string;
      created_at: string;
    }[];
    name: string;
    building_id: string;
    id: string;
    created_at: string;
  }[],
  childId: string
) => {
  const parentIds = [];

  function findParentsRecursive(items: any, childId: string) {
    for (const item of items) {
      if (item.id === childId) {
        if (item.parentId !== null) {
          parentIds.push(item?.zone_id ?? item.booth_id);
          findParentsRecursive(array, item.zone_id);
        }
      } else {
        if (item.zones && item.zones.length > 0) {
          findParentsRecursive(item.zones, childId);
        }
        if (item.sensors && item.sensors.length > 0) {
          findParentsRecursive(item.sensors, childId);
        }
      }
    }
  }

  findParentsRecursive(array, childId);
  return parentIds;
};
