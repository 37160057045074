export enum widgetNames {
  stageGauge = "Pressure Gauge",
  pressureCurves = "Filter Pressure Curves",
  serviceLogs = "Service Logs",
  waterServiceLogs = "Service Logs for Water Devices",
  paintBoothServiceLogs = "Service Logs for Paint Booths",
  boothPressure = "Booth Pressure",
  boothPressurePoint = "Booth Pressure Point",
  note = "Note",
  alerts = "All Recent Notifications",
  aranetBatteryLife = "Device Battery Life",
  filterResistance = "Filter Pressure",
  filterLoad = "Filter Load",
  remainingWeeks = "Remaining Weeks",
  waterKettle = "Kettle Water Pressure Tracking",
  waterKettleChart3Days = "Kettle Water Pressure (3 days)",
  outdoorAQ = "Outdoor AQI",
  indoorAQ = "Indoor Device AQI",
  buildingPerformance = "Building Performance Over Time",
  buildingAQ = "Average Building AQI Over Time",
  buildingAQOverview = "Overview of Building AQI Over Time",
  aqCurves = "Air Quality Line Chart (1 year)",
  aqTable = "Air Quality Table (1 year)",
  co2Decay = "CO₂ Decay",
  filtrationEfficiency = "Filtration Efficiency",
  separator = "Horizontal Separator"
}
