import { makeStyles } from "tss-react/mui";
import { Grid, Typography, Hidden } from "@mui/material";

const RoomTableHeaderComponent = () => {
  const { classes } = useStyles();
  return (
    <Grid
      container
      className={classes.root}
      data-testid="table-header">
      <Grid
        xs={2}
        sm={1}
        lg={1}
        item>
        <Typography className={classes.name}>Status</Typography>
      </Grid>
      <Grid
        item
        xs={5}
        sm={4}
        md={3}
        lg={2}>
        <Typography>Room Name</Typography>
      </Grid>
      <Hidden mdDown>
        <Grid
          item
          md={2}
          lg={2}>
          <Typography>Floor Name</Typography>
        </Grid>
      </Hidden>
      <Hidden lgDown>
        <Grid
          item
          md={3}
          lg={2}>
          <Typography>Number of devices</Typography>
        </Grid>
      </Hidden>
      <Hidden
        smDown
        lgUp>
        <Grid
          item
          sm={2}
          md={1}>
          <Typography>Devices</Typography>
        </Grid>
      </Hidden>
      <Grid
        item
        md={2}
        sm={2}
        xs={4}
        lg={2}
        className={classes.centered}>
        <Typography>Current AQI</Typography>
      </Grid>
      <Hidden smDown>
        <Grid
          item
          sm={2}
          md={2}
          lg={2}
          className={classes.centered}>
          <Typography>Action</Typography>
        </Grid>
      </Hidden>
    </Grid>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {
      "& .MuiTypography-body1": {
        color: "#919293"
      },
      backgroundColor: "white"
    },
    name: {
      paddingLeft: "5%"
    },
    centered: {
      textAlign: "center" as const
    }
  };
});

export default RoomTableHeaderComponent;
