import useGetKettleAndSenorsData from "src/hooks/useGetKettleAndSensorsData";
import { Typography } from "@mui/material";
import LoaderComponent from "src/shared/components/loader/loader.component";
import Error from "src/shared/components/Error/Error";
import kettleImg from "src/assets/kettle.png";
import useGetBuildings from "src/hooks/useGetBuildings";
import useOverview from "src/hooks/useOverview";
import { useStyles as useAhuStagesStyles } from "../ahu-stage-gauge-widget/ahu-stage-gauge-widget.style";
import { makeStyles } from "tss-react/mui";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { setSelectedKettle } from "src/store/kettle/reducer";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { setSelectedOverviewTab } from "src/store/overview/reducer";
import { setUserClickedOnWidgetTitle } from "src/store/hvac/reducer";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useOverviewTab } from "../../hooks/useGetOverviewTab";

const WaterKettleWidget = ({ buildingId, kettleId, requiresConfig }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { editing } = useOverview();
  const { classes: ahuStagesClasses } = useAhuStagesStyles();
  const { classes } = useStyles();
  const { buildings, isLoading: isLoadingBuildings } = useGetBuildings();
  const isGaugeWidget = true;
  const { tabId } = useOverviewTab();

  const {
    kettle,
    inletPSI,
    outletPSI,
    differentialPSI,
    isDifferentialPressureOutAlert,
    lastUpdatedTime,
    isLoading,
    isError,
    refetch
  } = useGetKettleAndSenorsData(
    buildingId,
    kettleId,
    isGaugeWidget,
    requiresConfig
  );

  const building = buildings?.find(
    building => building.id === kettle?.building_id
  );

  const alertText = isDifferentialPressureOutAlert
    ? "In Alert"
    : "Out of Alert";

  const handleOnClick = () => {
    if (editing) return;
    dispatch(setSelectedKettle(kettle));
    dispatch(setSelectedOverviewTab(tabId));
    dispatch(setUserClickedOnWidgetTitle(true));
    navigate(`/buildings/kettle/${building?.id}/${kettle?.id}`);
  };

  return (
    <div data-testid="water-kettle-widget">
      {requiresConfig ? (
        <>
          <Typography variant="h6">Filter Pressure</Typography>
          <Typography>Configuration is required</Typography>
        </>
      ) : isLoading || isLoadingBuildings ? (
        <LoaderComponent />
      ) : isError ? (
        <Error
          setRefetchAgain={refetch}
          error={isError}
        />
      ) : (
        <div className={classes.root}>
          <div className={ahuStagesClasses.body}>
            <div
              className={ahuStagesClasses.header}
              onClick={handleOnClick}>
              <Typography
                variant="h6"
                className={editing ? classes.default : classes.pointer}>
                {kettle.name}
              </Typography>
            </div>
            <div className={classes.kettleBody}>
              <div className={classNames(classes.imgContainer, "notranslate")}>
                <img
                  src={kettleImg}
                  className={classes.img}></img>
                <Typography
                  className={classes.inlet}>{`IN: ${inletPSI} PSI`}</Typography>
                <Typography
                  className={
                    classes.outlet
                  }>{`OUT: ${outletPSI} PSI`}</Typography>
              </div>
            </div>
            <div className={classNames(classes.info, "notranslate")}>
              <Typography>
                Differential pressure: {`${differentialPSI} PSI`}
              </Typography>
              <div className={classes.alertInfo}>
                {isDifferentialPressureOutAlert ? (
                  <WarningIcon sx={{ color: "red" }} />
                ) : (
                  <CheckCircleIcon sx={{ color: "green" }} />
                )}
                <Typography
                  sx={{
                    color: isDifferentialPressureOutAlert ? "red" : "green",
                    marginLeft: 3
                  }}>
                  {alertText}
                </Typography>
              </div>
            </div>
          </div>
          <div className={classes.buildingInfo}>
            <Typography>{building?.name}</Typography>
            <Typography>{lastUpdatedTime}</Typography>
          </div>
        </div>
      )}
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {
      height: "100%",
      padding: "10px"
    },
    pointer: {
      cursor: "pointer",
      "&:hover": { color: "#0795bb" }
    },
    default: {
      cuursor: "default"
    },
    kettleBody: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column"
    },
    inlet: {
      position: "absolute",
      left: 30,
      top: 25
    },
    outlet: {
      position: "absolute",
      right: 30,
      top: 25
    },
    imgContainer: {
      position: "relative",
      "& p": {
        fontSize: 14,
        fontWeight: 500
      }
    },
    img: {
      height: "220px"
    },
    info: {
      display: "flex",
      justifyContent: "center",
      marginBottom: 15,
      "& p:first-of-type": {
        marginRight: 10
      }
    },
    alertInfo: {
      display: "flex",
      marginLeft: 5,
      justifyContent: "center",
      "& p": {
        marginLeft: 3
      }
    },
    buildingInfo: {
      display: "flex",
      justifyContent: "space-between",
      position: "relative",
      bottom: 0,
      "& p": {
        fontSize: "13px",
        color: "#a9a9a9",
        fontWeight: 500
      },
      marginLeft: 10,
      marginRight: 10
    }
  };
});

export default WaterKettleWidget;
