import { makeStyles } from "tss-react/mui";
import { useMemo } from "react";
import LoaderComponent from "src/shared/components/loader/loader.component";
import { Typography, Grid } from "@mui/material";
import useGetBuildingPerformanceData from "../../hooks/useGetBuildingPerformanceData";
import WidgetTitle from "../../components/WidgetTitle/WidgetTitle";
import Error from "src/shared/components/Error/Error";
import ProgressBars from "../../components/ProgressBars/progress-bars.component";
import AQIOverallScore from "../../components/AqOverallScore/aqi-overall-score.component";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { getOverallScore } from "../../utils/building-performance-widget-utils";
import { shapePerformanceBarData } from "../../utils/building-performance-widget-utils";
import { useDispatch } from "react-redux";
import { setUserClickedOnWidgetTitle } from "src/store/hvac/reducer";
import { useNavigate } from "react-router-dom";
import useOverview from "src/hooks/useOverview";
import { FormattedBuildingWithEntity } from "src/shared/types/building";
import { setSelectedOverviewTab } from "src/store/overview/reducer";
import { TimeRangeObject } from "../aq-building-with-time-settings/aq-building-with-time-settings.component";
import {
  defaultTwoWeeksRange,
  getStartAndEndDate
} from "../../utils/time-span-utils";
import { useOverviewTab } from "../../hooks/useGetOverviewTab";

type Props = {
  buildingId: string;
  building: FormattedBuildingWithEntity;
  requiresConfig?: boolean;
  range?: TimeRangeObject;
};

const BuildingPerformanceWidgetComponent = ({
  buildingId,
  building,
  requiresConfig,
  range
}: Props) => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { editing } = useOverview();
  const dispatch = useDispatch();
  const { tabId } = useOverviewTab();
  const widgetsRange = range ?? defaultTwoWeeksRange;

  const { performanceData, isLoading, isError, refetch } =
    useGetBuildingPerformanceData(buildingId, widgetsRange, requiresConfig);

  const performanceDataExist = Boolean(performanceData);

  const formattedData = useMemo(() => {
    return shapePerformanceBarData(performanceData);
  }, [performanceDataExist]);

  const overallScore = useMemo(() => {
    return getOverallScore(performanceData?.score_avgs);
  }, [performanceDataExist]);

  const { start, end } = useMemo(() => {
    return getStartAndEndDate(
      widgetsRange?.method,
      widgetsRange?.startDate,
      widgetsRange?.endDate,
      widgetsRange?.timeSpan,
      widgetsRange?.timeQuantity
    );
  }, [widgetsRange]);

  const handleTitleClick = () => {
    if (editing) return;
    dispatch(setUserClickedOnWidgetTitle(true));
    dispatch(setSelectedOverviewTab(tabId));
    navigate(`/buildings/iaq/${buildingId}`);
  };

  return (
    <div
      className={classes.root}
      data-testid="building-performance">
      <div className={classes.header}>
        <div
          className={editing ? classes.default : classes.pointer}
          onClick={handleTitleClick}>
          <div style={{ marginRight: "0.5rem" }}>
            <ApartmentIcon fontSize="small" />
          </div>
          <Typography variant="h6">Building Performance</Typography>
        </div>
      </div>

      {requiresConfig ? (
        <Typography>Configuration is required</Typography>
      ) : isLoading ? (
        <LoaderComponent />
      ) : (
        <>
          {!isError && (
            <WidgetTitle
              error={isError}
              building={building}
            />
          )}
          <div className={classes.widgetBody}>
            {isError ? (
              <Error
                setRefetchAgain={refetch}
                error={isError}
              />
            ) : (
              <>
                <Grid
                  container
                  spacing={1}
                  className={classes.contentContainer}>
                  <AQIOverallScore
                    title={"Overall Average"}
                    color={overallScore.color}
                    value={overallScore.average}
                    status={overallScore.status}
                  />
                  <ProgressBars data={formattedData} />
                </Grid>
                <Typography
                  variant="subtitle1"
                  className={classes.timeFrame}>
                  {start} - {end}
                </Typography>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      justifyContent: "space-between",
      padding: "10px"
    },
    header: {
      display: "flex"
    },
    widgetBody: {
      flex: 1,
      overflow: "hidden",
      alignItems: "center",
      "@media(max-width:900px)": {
        overflow: "auto"
      }
    },
    contentContainer: {
      overflow: "hidden",
      "@media(max-width:900px)": {
        overflow: "auto"
      }
    },
    timeFrame: {
      position: "absolute",
      bottom: 0,
      right: 16,
      fontSize: "14px !important"
    },
    default: {
      display: "flex",
      cursor: "default"
    },
    pointer: {
      display: "flex",
      cursor: "pointer",
      "&:hover": { color: "#0795bb" }
    }
  };
});

export default BuildingPerformanceWidgetComponent;
