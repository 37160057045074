import {
  Ahu,
  Room,
  Kettle,
  NotificationDeviceParentsResp,
  NotificationDevicesResp,
  HvacSensorType
} from "src/utils/client";

export enum DeviceGroup {
  hvac = "hvac",
  aq = "aq",
  kettle = "kettle",
  paint = "paint_booth"
}

enum DeviceParentId {
  hvac = "ahu_id",
  aq = "room_id"
}

export const getParentsForInput = (
  deviceParents: NotificationDeviceParentsResp,
  buildingId: string,
  group: string
) => {
  if (!Boolean(deviceParents)) return [];

  const parents: Ahu[] | Room[] | Kettle[] = deviceParents[group] ?? [];

  return parents
    ?.filter(parent => {
      if (!buildingId) return parent;
      return parent.building_id === buildingId;
    })
    ?.map(parent => ({
      ...parent,
      key: parent.id
    }));
};

export const getSensorsWithStageNameForInput = (
  deviceParents: NotificationDeviceParentsResp,
  devices: NotificationDevicesResp,
  buildingId: string,
  ahuId: string
) => {
  if (!Boolean(deviceParents) || !Boolean(devices)) return [];

  const sensors = devices[DeviceGroup.hvac] ?? [];
  const ahus = deviceParents[DeviceGroup.hvac]?.map(ahu => ahu);
  const sensorsWithStageName = sensors
    ?.filter(sensor => {
      if (sensor.details.sensor_type === HvacSensorType.SYNETICA_AF) {
        return false;
      }
      return true;
    })
    .map(sensor => {
      const sensorWithStageName = { ...sensor, stage_name: "" };
      let stageNameFound = false;
      ahus?.map(ahu => {
        ahu?.stages?.map(stage => {
          if (stage.id === sensor.stage_id) {
            sensorWithStageName.stage_name = stage.name;
            stageNameFound = true;
          }
        });
      });
      if (!stageNameFound) {
        sensorWithStageName.stage_name = sensor.name;
      }
      return sensorWithStageName;
    });

  return sensorsWithStageName
    ?.filter(sensor => {
      if (!buildingId) return sensor;
      if (Boolean(ahuId)) {
        return (
          sensor.building_id === buildingId &&
          sensor[DeviceParentId.hvac] === ahuId
        );
      }
      return sensor.building_id === buildingId;
    })
    ?.map(sensor => ({
      ...sensor,
      key: sensor.id
    }));
};

export const getAqDevicesForInput = (
  devices: NotificationDevicesResp,
  buildingId: string,
  roomId: string
) => {
  if (!Boolean(devices)) return [];

  const sensors = devices[DeviceGroup.aq] ?? [];

  return sensors
    ?.filter(sensor => {
      if (!buildingId) return sensor;
      if (Boolean(roomId)) {
        return (
          sensor.building_id === buildingId &&
          sensor[DeviceParentId.aq] === roomId
        );
      }
      return sensor.building_id === buildingId;
    })
    ?.map(sensor => ({
      ...sensor,
      key: sensor.id
    }));
};
