export const LABEL_COLOR = {
  good: {
    color: "rgb(106, 222, 89)",
    text: "good",
    background: "rgba(106, 222, 89, 0.1)"
  },
  moderate: {
    color: "rgb(244, 200, 58)",
    text: "moderate",
    background: "rgba(244, 200, 58, 0.1)"
  },
  high: {
    color: "rgb(232, 37, 71)",
    text: "high",
    background: "rgba(232, 37, 71, 0.1)"
  },
  offline: {
    color: "rgb(125, 125, 125)",
    text: "offline",
    background: "rgba(125, 125, 125, 0.1)"
  }
};
