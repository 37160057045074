import { widgets as widgetsConfig } from "../../widgets/widgets-config";
import WidgetActionsComponent from "../widget-actions/widget-actions.component";
import useOverview from "src/hooks/useOverview";
import { IWidget } from "../../view/types";

interface WidgetProps {
  widget: IWidget;
  widgets: IWidget[];
  setWidgetToConfigure: (widget: IWidget) => void;
  setWidgets: (widget: IWidget[]) => void;
}

const Widget = ({
  widget,
  widgets,
  setWidgetToConfigure,
  setWidgets
}: WidgetProps) => {
  const { editing } = useOverview();
  // Before refactor text widget had type of test which is saved on backend and this is fallback for this type
  const widgetType =
    widget.type === "test"
      ? "note"
      : widget.type === "pressureCurves7days"
      ? "pressureCurves"
      : widget.type === "waterServiceLog"
      ? "serviceLogsBody"
      : widget.type === "paintBoothServiceLog"
      ? "serviceLogsBody"
      : widget.type;

  const widgetId = widget.props?.widgetId ?? null;
  const PreviewComponent = widgetsConfig[widgetType]?.Component;
  const showConfigBtn =
    !widget.props.requiresConfig &&
    Boolean(widgetsConfig[widgetType]?.settingsComponent);
  const handleOnConfigure = () => {
    setWidgetToConfigure(widget);
  };
  const handleOnRemove = () => {
    const updatedWidgetsOnSelectedPage = widgets.filter(widget => {
      if (widget.props?.widgetId) {
        return widget?.props?.widgetId !== widgetId;
      }
      return widget.props.index !== widget.props.index;
    });
    setWidgets(updatedWidgetsOnSelectedPage);
  };
  return (
    <>
      {editing && (
        <WidgetActionsComponent
          onRemove={handleOnRemove}
          onOpenSettings={handleOnConfigure}
          showConfigBtn={showConfigBtn}
        />
      )}
      {Boolean(PreviewComponent) && (
        <PreviewComponent
          {...widget.props}
          dataGrid={widget.gridData}></PreviewComponent>
      )}
    </>
  );
};

export default Widget;
