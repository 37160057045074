import { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { Box } from "@mui/system";
import {
  Alert as AlertModal,
  AlertTitle,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Tab,
  Typography,
  Pagination
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import AlertRowComponent from "../alert-row/alert-row.component";
import AlertsDatesModalComponent from "../alerts-dates-modal/alerts-dates-modal.component";
import AlertModalComponent from "../alert-modal/alert-modal.component";
import LoaderComponent from "src/shared/components/loader/loader.component";

import { useStyles } from "./alerts-drawer.style";
import { AlertReturn, UpdateManyAlertsRequest } from "src/utils/client";
import { useAppSelector } from "src/hooks/useAppSelector";
import {
  getAlertsDrawerState,
  getSelectedNotificationId
} from "src/store/notification/selectors";
import {
  setAlertModalData,
  setAlertModalOpen,
  setAlertsDrawerOpen,
  setSelectedNotificationId
} from "src/store/notification/reducer";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import {
  useGetAlertQuery,
  useGetAlertsQuery,
  useGetNotificationsQuery,
  useMarkAsReadMutation
} from "src/store/services/notifications/notificationsService";

enum ALERT_TABS {
  UNREAD = "unread",
  LAST = "last"
}

const AlertsDrawerComponent = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [internalError] = useState(null);
  const [selectedTab, setSelectedTab] = useState<`${ALERT_TABS}`>(
    ALERT_TABS.LAST
  );
  const [datesModalOpen, setDatesModalOpen] = useState<boolean>(false);
  const [alerstToDisplay, setAlertsToDisplay] = useState<AlertReturn[]>([]);
  const [notification, setNotification] = useState<any>(null);
  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);

  const dispatch = useAppDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const notificationId = searchParams.get("n");
  const alertId = searchParams.get("a");
  const pageSize: number = 50;
  const selectedNotificationId = useAppSelector(getSelectedNotificationId);
  const open = useAppSelector(getAlertsDrawerState);

  const {
    data: alerts,
    isLoading,
    isFetching
  } = useGetAlertsQuery({
    notificationId: selectedNotificationId
  });
  const unreadAlerts = alerts?.filter(alert => !alert.is_read);
  const { data: alertToOpen } = useGetAlertQuery(
    { alertId: alertId, notificationId: notificationId },
    {
      skip: !(alertId && notificationId)
    }
  );
  const { data: notifications } = useGetNotificationsQuery();
  const [markAsRead] = useMarkAsReadMutation();
  const isUnreadTabSelected = selectedTab === ALERT_TABS.UNREAD;
  const isLast30DaysTabSelected = selectedTab === ALERT_TABS.LAST;

  const handlePageChange = (_: any, value: number) => {
    setPage(value);

    if (isUnreadTabSelected) {
      setAlertsToDisplay(getPage(value, pageSize, unreadAlerts));
    } else if (isLast30DaysTabSelected) {
      setAlertsToDisplay(getPage(value, pageSize, alerts));
    }
  };

  const onClose = () => {
    setTotal(0);
    setAlertsToDisplay([]);
    setCount(1);
    dispatch(setAlertsDrawerOpen(false));
    dispatch(setSelectedNotificationId(null));
    setNotification(null);
    setSelectedTab(ALERT_TABS.LAST);
  };

  const goToNotifications = () => {
    navigate("/notifications");
    onClose();
  };

  const loadMore = () => {
    setDatesModalOpen(true);
  };

  const markAllAsRead = () => {
    const alertsToUpdate = alerts.map(
      (item): UpdateManyAlertsRequest => ({
        alert_id: item.id,
        notification_id: item.notification.id,
        updates: {
          is_read: true
        }
      })
    );

    markAsRead({
      alerts: alertsToUpdate,
      notificationId: selectedNotificationId
    });
  };

  const getPage = (page: number, size: number, list: AlertReturn[]) => {
    const start = (page - 1) * size;
    const end = start + size;

    return list?.slice(start, end);
  };

  const HandlePaginationView = (): React.ReactElement => {
    if (alerstToDisplay?.length <= 0) return null;

    return (
      <Pagination
        count={count}
        page={page}
        onChange={handlePageChange}
      />
    );
  };

  useEffect(() => {
    if (!selectedNotificationId || !notifications) return;

    const selectedNotification = notifications?.find(notification => {
      return notification.id === selectedNotificationId;
    });
    setNotification(selectedNotification);
  }, [selectedNotificationId, notifications]);

  useEffect(() => {
    if (!alerts) return;

    if (isUnreadTabSelected) {
      setCount(Math.ceil(unreadAlerts.length / pageSize));
      setPage(1);
      setTotal(unreadAlerts.length);
      setAlertsToDisplay(getPage(1, pageSize, unreadAlerts));
    } else if (isLast30DaysTabSelected) {
      setCount(Math.ceil(alerts.length / pageSize));
      setPage(1);
      setTotal(alerts.length);
      setAlertsToDisplay(getPage(1, pageSize, alerts));
    }
  }, [alerts, selectedTab, unreadAlerts?.length]);

  useEffect(() => {
    if (!notificationId || !alertId || !alertToOpen) return;

    dispatch(setAlertsDrawerOpen(true));
    dispatch(setSelectedNotificationId(notificationId));
    dispatch(setAlertModalData(alertToOpen));
    dispatch(setAlertModalOpen(true));
  }, [alertId, notificationId, alertToOpen]);

  if (internalError) {
    return (
      <Drawer
        open={open}
        onClose={onClose}
        anchor="right"
        keepMounted={false}>
        <AlertModal
          severity="error"
          style={{ width: 350 }}>
          <AlertTitle>
            Error{internalError.message ? `: ${internalError.message}` : ""}
          </AlertTitle>
          <div style={{ whiteSpace: "pre-line" }}>
            {internalError.componentStack}
          </div>
        </AlertModal>
      </Drawer>
    );
  }

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      keepMounted={false}>
      <div
        data-testid="alerts-drawer"
        className={classes.root}>
        {(isLoading || isFetching) && <LoaderComponent />}

        <div className={classes.title}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">Alerts {total}</Typography>
        </div>

        {notification && (
          <div className={classes.notificationTitle}>
            <Typography variant="body2">
              Displaying for: {notification.title}
            </Typography>
          </div>
        )}

        <div className={classes.body}>
          <div className={classes.bodyContent}>
            <TabContext value={selectedTab}>
              <TabList
                onChange={(_event, newVal) => {
                  setSelectedTab(newVal);
                }}
                sx={{ width: "350px" }}>
                <Tab
                  sx={{ width: "175px" }}
                  wrapped
                  label="Unread Last 30 days"
                  value="unread"
                />
                <Tab
                  sx={{ width: "175px" }}
                  wrapped
                  label="All Last 30 days"
                  value="last"
                />
              </TabList>

              <TabPanel
                value={selectedTab}
                className={classes.list}>
                <HandlePaginationView />

                <List>
                  {alerstToDisplay?.map((alert, index) => {
                    return (
                      <ListItem
                        sx={{ width: 350 }}
                        key={`alert_row_${alert.notification.id}_${index}`}
                        disablePadding>
                        <ListItemButton sx={{ padding: 0 }}>
                          <AlertRowComponent alert={alert} />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                  {!alerstToDisplay?.length && (
                    <ListItem
                      sx={{ width: 350 }}
                      disablePadding>
                      <Box className={classes.loadMoreContainer}>
                        <Typography>No notifications last 30 days</Typography>
                      </Box>
                    </ListItem>
                  )}
                  <ListItem
                    sx={{ width: 350 }}
                    disablePadding>
                    <Box className={classes.loadMoreContainer}>
                      <Button onClick={loadMore}>See More</Button>
                    </Box>
                  </ListItem>
                </List>
              </TabPanel>
            </TabContext>

            <div className={classes.actions}>
              <Button
                variant="text"
                onClick={markAllAsRead}>
                <DoneAllIcon className={classes.doneIcon} />
                <p className={classes.doneIconText}>MARK ALL READ</p>
              </Button>
              <Button
                variant="contained"
                onClick={goToNotifications}>
                SETTINGS
              </Button>
            </div>
          </div>
        </div>
      </div>

      <AlertModalComponent />

      <AlertsDatesModalComponent
        open={datesModalOpen}
        onClose={() => {
          setDatesModalOpen(false);
        }}
      />
    </Drawer>
  );
};

export default AlertsDrawerComponent;
