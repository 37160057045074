import { FormattedBuilding } from "src/shared/types/building";
import { apiService } from "../api";
import {
  Ahu,
  AhusService,
  AqDeviceAndLatest,
  AqDevicesService,
  BuildingEntityInfo,
  BuildingSummary,
  EventService,
  HvacData,
  HvacDataService,
  HvacSensor,
  HvacSensorsService,
  InsightsService,
  MiscService,
  RoomStatus,
  RoomsService,
  TradefairDashboardData,
  WebPortalService,
  Event,
  LegacyDeviceDataResp
} from "src/utils/client";
import { BuildingForUpdate } from "src/sections/buildings/buildings-modal/buildings-modal.component";
import { Building } from "src/utils/client";
import { BuildingsService } from "src/utils/client";
import {
  handleBuildingModal,
  setSelectedBuilding
} from "src/store/buildings/reducer";

export const generalService = apiService
  .enhanceEndpoints({
    addTagTypes: [
      "BUILDINGS",
      "BUILDINGSENTITYS",
      "BUILDINGROOMS",
      "TRADEFAIRINFO",
      "ROOMDEVICES",
      "DEVICELEGACYDATA",
      "DEVICE",
      "DEVICEINSIGHTS",
      "BUILDINGHVACS",
      "BUILDINGAHUS",
      "AHUFILTERLOAD",
      "HVACDEVICEDATA",
      "EVENTDATA"
    ]
  })
  .injectEndpoints({
    endpoints: build => ({
      getBuildingSummaries: build.query<FormattedBuilding[], void>({
        queryFn: async () => {
          const buildingSummaries = await WebPortalService.buildingSummaries();
          const formattedBuildings = Object.values(buildingSummaries).map(
            (summary: BuildingSummary) => {
              return {
                name: summary.building.name,
                id: summary.building.id,
                location: summary.building.location,
                companyId: summary.building.company_id,
                deviceCount: summary.aq_device_count,
                indoorData: Object.values(summary.latest_indoor_aq_data)[0],
                outdoorData: summary.latest_outdoor_aq_data
              };
            }
          );
          return { data: formattedBuildings };
        },
        providesTags: ["BUILDINGS"]
      }),
      getBuildingsEntity: build.query<Record<string, BuildingEntityInfo>, void>(
        {
          queryFn: async () => {
            const entities = await WebPortalService.buildingEntityInfo();
            return { data: entities };
          },
          providesTags: ["BUILDINGSENTITYS"]
        }
      ),
      createBuilding: build.mutation<Building, BuildingForUpdate>({
        // @ts-ignore
        queryFn: async (building, { dispatch }) => {
          const { companyId } = building;
          const newBuilding = await BuildingsService.createBuilding(
            companyId,
            building
          );
          dispatch(handleBuildingModal(false));
          dispatch(setSelectedBuilding(null));
          return newBuilding;
        },

        invalidatesTags: ["BUILDINGS"]
      }),
      updateBuilding: build.mutation<FormattedBuilding, BuildingForUpdate>({
        // @ts-ignore
        queryFn: async (building, { dispatch }) => {
          const { companyId, id } = building;
          const updatedBuilding = await BuildingsService.updateBuilding(
            companyId,
            id,
            building
          );
          dispatch(handleBuildingModal(false));
          dispatch(setSelectedBuilding(null));
          return updatedBuilding;
        },
        invalidatesTags: ["BUILDINGS"]
      }),
      getTradeFairDashboardInfo: build.query<
        TradefairDashboardData,
        { companyId: string; buildingId: string }
      >({
        queryFn: async args => {
          const { companyId, buildingId } = args;
          const info = await MiscService.tradefairDashboardInfo(
            companyId,
            buildingId
          );
          return { data: info };
        },
        providesTags: ["TRADEFAIRINFO"]
      }),
      getBuildingRooms: build.query<RoomStatus[], string>({
        queryFn: async buildingId => {
          const rooms = await RoomsService.getRoomStatuses(buildingId);
          return { data: rooms };
        },
        providesTags: (_result, _error, buildingId) => [
          { type: "BUILDINGROOMS", buildingId }
        ]
      }),
      createBuildingRoom: build.mutation<any, any>({
        //@ts-ignore
        queryFn: async args => {
          const { buildingId, room } = args;
          const newBuildingRoom = await RoomsService.createRoom(
            buildingId,
            room
          );
          return newBuildingRoom;
        },
        invalidatesTags: (_result, _error, { buildingId }) => [
          { type: "BUILDINGROOMS", buildingId }
        ]
      }),
      updateBuildingRoom: build.mutation<any, any>({
        //@ts-ignore
        queryFn: async args => {
          const { buildingId, room } = args;
          const updatedBuildingRoom = await RoomsService.updateRoom(
            buildingId,
            room.roomId,
            room
          );
          return updatedBuildingRoom;
        },
        invalidatesTags: (_result, _error, { buildingId }) => [
          { type: "BUILDINGROOMS", buildingId }
        ]
      }),
      getRoomDevices: build.query<
        RoomStatus,
        { buildingId: string; roomId: string; includeDeviceStatuses?: boolean }
      >({
        //@ts-ignore
        queryFn: async args => {
          const { buildingId, roomId, includeDeviceStatuses } = args;
          const includeStatuses =
            includeDeviceStatuses === false ? includeDeviceStatuses : true;
          const devices = await RoomsService.getRoom(
            buildingId,
            roomId,
            includeStatuses
          );
          return { data: devices };
        },
        providesTags: ["ROOMDEVICES"]
      }),
      getDevice: build.query<
        AqDeviceAndLatest,
        { deviceId: string; buildingId: string }
      >({
        queryFn: async args => {
          const { buildingId, deviceId } = args;
          const device = await AqDevicesService.getAqDevice(
            deviceId,
            buildingId
          );
          return { data: device };
        },
        providesTags: ["DEVICE"]
      }),
      getDeviceLegacyData: build.query<
        LegacyDeviceDataResp,
        {
          deviceId: string;
          buildingId: string;
          startDate: number;
          endDate: number;
          granularity: any;
        }
      >({
        queryFn: async args => {
          const { deviceId, buildingId, startDate, endDate, granularity } =
            args;
          const deviceLegacyData = await WebPortalService.legacyDeviceData(
            buildingId,
            deviceId,
            String(startDate),
            String(endDate),
            granularity
          );
          return { data: deviceLegacyData };
        },
        providesTags: ["DEVICELEGACYDATA"]
      }),
      getDeviceInsight: build.query<
        any,
        {
          deviceId: string;
          buildingId: string;
          startDate: string;
          endDate: string;
        }
      >({
        queryFn: async args => {
          const { buildingId, deviceId, startDate, endDate } = args;
          const deviceInsights = await InsightsService.getAqInsights(
            buildingId,
            {
              building_id: buildingId,
              device_id: deviceId,
              time_range: {
                start: startDate,
                end: endDate
              }
            }
          );
          return { data: deviceInsights };
        },
        providesTags: ["DEVICEINSIGHTS"]
      }),
      createDevice: build.mutation({
        //@ts-ignore
        queryFn: async args => {
          const {
            building_id,
            name,
            room_id,
            hardware_id,
            serial_number,
            device_type
          } = args;
          const device = await AqDevicesService.createAqDevice(building_id, {
            name,
            building_id,
            room_id,
            hardware_id,
            serial_number,
            device_type
          });
          return device;
        },
        invalidatesTags: ["ROOMDEVICES"]
      }),
      updateDevice: build.mutation<any, any>({
        //@ts-ignore
        queryFn: async args => {
          const {
            deviceId,
            buildingId,
            name,
            roomId,
            hardware_id,
            serial_number,
            device_type
          } = args;
          return await AqDevicesService.updateAqDevice(deviceId, buildingId, {
            name,
            building_id: buildingId,
            room_id: roomId,
            hardware_id,
            serial_number,
            device_type
          });
        },
        invalidatesTags: ["ROOMDEVICES"]
      }),
      getHvacSensorsForBuilding: build.query<HvacSensor[], string>({
        queryFn: async buildingId => {
          const hvacSensors =
            await HvacSensorsService.getHvacSensorsForBuilding(buildingId);
          return { data: hvacSensors };
        },
        providesTags: (_result, _error, buildingId) => [
          { type: "BUILDINGHVACS", buildingId }
        ]
      }),
      getAhusInBuilding: build.query<Ahu[], string>({
        queryFn: async buildingId => {
          const ahus = await AhusService.getAhusForBuilding(buildingId);
          return { data: ahus };
        },
        providesTags: (_result, _error, buildingId) => [
          { type: "BUILDINGAHUS", buildingId }
        ]
      }),
      getFilterLoadForAhu: build.query({
        queryFn: async args => {
          const { buildingId, ahuId, startDate, endDate, granularity } = args;
          const filterLoad = await HvacDataService.ahuStageData(
            buildingId,
            ahuId,
            startDate,
            endDate,
            granularity
          );
          return { data: filterLoad };
        },
        providesTags: (_result, _error, { ahuId }) => [
          { type: "AHUFILTERLOAD", ahuId }
        ]
      }),
      getLatestHvacDeviceData: build.query<
        HvacData,
        { buildingId: string; deviceId: string }
      >({
        queryFn: async args => {
          const { buildingId, deviceId } = args;

          const hvacData =
            await HvacDataService.getLatestHvacDeviceDataPointForASensor(
              deviceId,
              buildingId
            );

          return { data: hvacData };
        },
        providesTags: (_result, _error, { deviceId }) => [
          { type: "HVACDEVICEDATA", deviceId }
        ]
      }),
      getAhuAuditLogs: build.query<
        Event[],
        { buildingId: string; ahuId: string; startTs: string; endTs: string }
      >({
        queryFn: async args => {
          const { buildingId, startTs, endTs, ahuId } = args;
          const auditLogs = await EventService.getAuditLog(
            buildingId,
            startTs,
            endTs,
            ahuId
          );
          return { data: auditLogs.reverse() };
        },
        providesTags: (_result, _error, { ahuId }) => [
          { type: "EVENTDATA", ahuId }
        ]
      }),
      getKettleAuditLogs: build.query<
        Event[],
        { buildingId: string; kettleId: string; startTs: string; endTs: string }
      >({
        queryFn: async args => {
          const { buildingId, kettleId, startTs, endTs } = args;
          const auditLogs = await EventService.getAuditLog(
            buildingId,
            startTs,
            endTs,
            null,
            kettleId
          );
          return { data: auditLogs.reverse() };
        },
        providesTags: (_result, _error, { kettleId }) => [
          { type: "EVENTDATA", kettleId }
        ]
      }),
      getPaintBoothAuditLogs: build.query<
        Event[],
        {
          buildingId: string;
          paintBoothId: string;
          startTs: string;
          endTs: string;
        }
      >({
        queryFn: async args => {
          const { buildingId, paintBoothId, startTs, endTs } = args;
          const auditLogs = await EventService.getAuditLog(
            buildingId,
            startTs,
            endTs,
            null,
            null,
            paintBoothId
          );
          return { data: auditLogs.reverse() };
        },
        providesTags: (_result, _error, { paintBoothId }) => [
          { type: "EVENTDATA", paintBoothId }
        ]
      })
    })
  });

export const {
  useGetBuildingSummariesQuery,
  useGetBuildingsEntityQuery,
  useCreateBuildingMutation,
  useUpdateBuildingMutation,
  useGetTradeFairDashboardInfoQuery,
  useGetBuildingRoomsQuery,
  useCreateBuildingRoomMutation,
  useUpdateBuildingRoomMutation,
  useGetRoomDevicesQuery,
  useGetDeviceInsightQuery,
  useGetDeviceQuery,
  useGetDeviceLegacyDataQuery,
  useCreateDeviceMutation,
  useUpdateDeviceMutation,
  useGetAhusInBuildingQuery,
  useGetHvacSensorsForBuildingQuery,
  useGetFilterLoadForAhuQuery,
  useGetLatestHvacDeviceDataQuery,
  useGetAhuAuditLogsQuery,
  useGetKettleAuditLogsQuery,
  useGetPaintBoothAuditLogsQuery
} = generalService;
