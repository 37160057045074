import { v4 as uuid } from "uuid";
import { Page, WidgetPositionInGrid, IWidget } from "./types";
import { Layout } from "react-grid-layout";
import { widgets } from "../widgets/widgets-config";

// For grid to work we need to configure each widget in space so x and y positions is needed
// additionally we need to configure width and heigth of widets by columns and row from zero to 12
export const getGridDataForWidget = (
  widget: IWidget,
  bodyLength: number
): WidgetPositionInGrid => {
  const backupWidgetgridData = widgets[widget.type];

  const yPosition = !isNaN(widget.gridData?.y) ? widget.gridData.y : bodyLength;
  const xPosition = !isNaN(widget.gridData?.x) ? widget.gridData.x : 0;

  return Object.assign({}, widget.gridData, {
    x: xPosition,
    y: yPosition,
    h: widget.gridData?.h ? widget.gridData.h : backupWidgetgridData.gridData.h,
    w: widget.gridData?.w ? widget.gridData.w : backupWidgetgridData.gridData.w
  });
};

export const createWidgetId = (
  requiresConfig: boolean,
  currentWidgetId: string
) => (requiresConfig ? uuid() : currentWidgetId);

export const updateGridLayoutDataForPage = (
  overviewData: IWidget[],
  widgetBodyLayout: Layout[]
) => {
  // To remember where each widget position is we need to keep data on servers from react-grid-layout and for that we will use widgetBodyLayout
  return overviewData.map(widget => {
    const widgetLayoutDataAfterMoving = widgetBodyLayout.find(
      ({ i }: { i: string }) => {
        // Each widget will have own id to sepearete then, but we will use index for old widgets for backward compatibility
        return i === widget.props.widgetId || widget.props.index === i;
      }
    );
    if (widgetLayoutDataAfterMoving) {
      return Object.assign({}, widget, {
        gridData: widgetLayoutDataAfterMoving
      });
    }
    return widget;
  });
};

export const updatePages = (
  pages: Page[],
  selectedTabIndex: number,
  newWidgetsData: any
): Page[] => {
  return pages.map(page => {
    const activePage = pages[selectedTabIndex];
    if (
      (Boolean(page.pageId) && activePage.pageId === page.pageId) ||
      activePage.pageName === page.pageName
    ) {
      return { ...page, widgets: newWidgetsData };
    }
    return page;
  });
};

export const checkLuminance = (hexColor: string) => {
  if (hexColor) {
    hexColor = hexColor.replace(/^#/, "");
    const red = parseInt(hexColor.slice(0, 2), 16);
    const green = parseInt(hexColor.slice(2, 4), 16);
    const blue = parseInt(hexColor.slice(4, 6), 16);
    const perceivedLuminance = red * 0.299 + green * 0.587 + blue * 0.114;
    return perceivedLuminance;
  } else return 151;
};
