/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InsightsRequestBody } from '../models/InsightsRequestBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class InsightsService {
  /**
   * Get Aq Insights
   * Returns aggregated aq data for a device for the given range.
   *
   * Args:
   * device_id: When `device_id` is `None`, all the indoor aq data for the building's devices will be used to generate the insights.
   * time_range: Defaults to past 14 days. `time_range.start` and `time_range.end` can be given as Unix timestamps integers or floats in seconds or milliseconds, or as isoformat strings.
   *
   * ```
   * Examples:
   * 1677827157                       (seconds)
   * 1677827157.5451648               (seconds)
   * 1677827157545                    (milliseconds)
   * 2023-03-03T07:05:57.545165+00:00 (isoformat)
   * 2023-03-03T02:05:57.545165+05:00 (isoformat)
   * ```
   * All of the above generate the same timestamp in the backend
   * (except where minor place values were omitted).
   * @param buildingId
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getAqInsights(
    buildingId: string,
    requestBody: InsightsRequestBody,
  ): CancelablePromise<(Record<string, string> | null)> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/insights/',
      query: {
        'building_id': buildingId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
