/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AqDevice } from '../models/AqDevice';
import type { Building } from '../models/Building';
import type { BuildingNew } from '../models/BuildingNew';
import type { BuildingUpdates } from '../models/BuildingUpdates';
import type { HvacSensor } from '../models/HvacSensor';
import type { KettleSensor } from '../models/KettleSensor';
import type { NotificationSubscription } from '../models/NotificationSubscription';
import type { OfflineDevicesReturn } from '../models/OfflineDevicesReturn';
import type { PaintBoothSensor } from '../models/PaintBoothSensor';
import type { TimeRange } from '../models/TimeRange';
import type { WebReportData } from '../models/WebReportData';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class BuildingsService {
  /**
   * Get Aq Devices For Building
   * Returns possibly empty list of aq devices for the building.
   * @param buildingId
   * @returns AqDevice Successful Response
   * @throws ApiError
   */
  public static getAqDevicesForBuilding(
    buildingId: string,
  ): CancelablePromise<Array<AqDevice>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/aq-devices/',
      path: {
        'building_id': buildingId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Buildings
   * @param companyId
   * @returns Building Successful Response
   * @throws ApiError
   */
  public static getBuildings(
    companyId: string,
  ): CancelablePromise<Array<Building>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/companies/{company_id}/buildings/',
      path: {
        'company_id': companyId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Create Building
   * @param companyId
   * @param requestBody
   * @returns Building Successful Response
   * @throws ApiError
   */
  public static createBuilding(
    companyId: string,
    requestBody: BuildingNew,
  ): CancelablePromise<Building> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/companies/{company_id}/buildings/',
      path: {
        'company_id': companyId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Database Error`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Building
   * @param companyId
   * @param buildingId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getBuilding(
    companyId: string,
    buildingId: string,
  ): CancelablePromise<(Building | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/companies/{company_id}/buildings/{building_id}',
      path: {
        'company_id': companyId,
        'building_id': buildingId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Update Building
   * @param companyId
   * @param buildingId
   * @param requestBody
   * @returns Building Successful Response
   * @throws ApiError
   */
  public static updateBuilding(
    companyId: string,
    buildingId: string,
    requestBody: BuildingUpdates,
  ): CancelablePromise<Building> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/companies/{company_id}/buildings/{building_id}',
      path: {
        'company_id': companyId,
        'building_id': buildingId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Database Error`,
        404: `Invalid Object Id`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Delete Building
   * @param companyId
   * @param buildingId
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public static deleteBuilding(
    companyId: string,
    buildingId: string,
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/companies/{company_id}/buildings/{building_id}',
      path: {
        'company_id': companyId,
        'building_id': buildingId,
      },
      errors: {
        400: `Database Error`,
        404: `Invalid Object Id`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Building By Id
   * Helper endpoint to get a building with just an id.
   * Prefer `/companies/{company_id}/buildings/{building_id}`
   * if the company_id is available.
   * @param buildingId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getBuildingById(
    buildingId: string,
  ): CancelablePromise<(Building | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}',
      path: {
        'building_id': buildingId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Hvac Sensors For Building
   * Returns possibly empty list of hvac sensors for the building.
   * @param buildingId
   * @param includeInactive
   * @returns HvacSensor Successful Response
   * @throws ApiError
   */
  public static getHvacSensorsForBuilding(
    buildingId: string,
    includeInactive: boolean = false,
  ): CancelablePromise<Array<HvacSensor>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/hvac-sensors/',
      path: {
        'building_id': buildingId,
      },
      query: {
        'include_inactive': includeInactive,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Kettle Sensors For Building
   * Returns possibly empty list of kettle sensors for the building.
   * @param buildingId
   * @returns KettleSensor Successful Response
   * @throws ApiError
   */
  public static getKettleSensorsForBuilding(
    buildingId: string,
  ): CancelablePromise<Array<KettleSensor>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/kettle-sensors/',
      path: {
        'building_id': buildingId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Paint Booth Sensors For Building
   * Returns possibly empty list of paint_booth_sensor for the building.
   * @param buildingId
   * @returns PaintBoothSensor Successful Response
   * @throws ApiError
   */
  public static getPaintBoothSensorsForBuilding(
    buildingId: string,
  ): CancelablePromise<Array<PaintBoothSensor>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/paint-booth-sensors/',
      path: {
        'building_id': buildingId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Offline Devices For Building
   * @param buildingId
   * @param includeInactive
   * @returns OfflineDevicesReturn Successful Response
   * @throws ApiError
   */
  public static getOfflineDevicesForBuilding(
    buildingId: string,
    includeInactive: boolean = false,
  ): CancelablePromise<OfflineDevicesReturn> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/offline_devices/',
      path: {
        'building_id': buildingId,
      },
      query: {
        'include_inactive': includeInactive,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get All Buildings
   * Returns all buildings.
   * @returns Building Successful Response
   * @throws ApiError
   */
  public static getAllBuildings(): CancelablePromise<Array<Building>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/all-buildings/',
    });
  }
  /**
   * Get All Notification Subscriptions For Building
   * @param buildingId
   * @returns NotificationSubscription Successful Response
   * @throws ApiError
   */
  public static getAllNotificationSubscriptionsForBuilding(
    buildingId: string,
  ): CancelablePromise<Array<NotificationSubscription>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/notification-subscriptions/',
      path: {
        'building_id': buildingId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Make Pdf Report
   * Generates a single page pdf iaq report for a building.
   * If no `time_range` is provided, defaults to the last two weeks.
   *
   * `time_range.start` and `time_range.end` can be given as Unix timestamps integers
   * or floats in seconds or milliseconds, or as isoformat strings.
   * ```
   * Examples:
   * 1677827157                       (seconds)
   * 1677827157.5451648               (seconds)
   * 1677827157545                    (milliseconds)
   * 2023-03-03T07:05:57.545165+00:00 (isoformat)
   * 2023-03-03T02:05:57.545165+05:00 (isoformat)
   * ```
   * All of the above generate the same timestamp in the backend
   * (except where minor place values were omitted).
   * @param companyId
   * @param buildingId
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static makePdfReport(
    companyId: string,
    buildingId: string,
    requestBody?: (TimeRange | null),
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/reports/pdf/',
      query: {
        'company_id': companyId,
        'building_id': buildingId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `A report could not be generated`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Web Report Data
   * Main endpoint for delivering the data the web report uses.
   * If no `building_id` is provided, a company-level report is produced (all buildings for the company).
   * If a `building_id` is provided, a building-level report is made.
   * If no `time_range` is provided, defaults to the last two weeks.
   *
   * `time_range.start` and `time_range.end` can be given as Unix timestamps integers
   * or floats in seconds or milliseconds, or as isoformat strings.
   * ```
   * Examples:
   * 1677827157                       (seconds)
   * 1677827157.5451648               (seconds)
   * 1677827157545                    (milliseconds)
   * 2023-03-03T07:05:57.545165+00:00 (isoformat)
   * 2023-03-03T02:05:57.545165+05:00 (isoformat)
   * ```
   * All of the above generate the same timestamp in the backend
   * (except where minor place values were omitted).
   * @param companyId
   * @param buildingId
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getWebReportData(
    companyId: string,
    buildingId: string,
    requestBody?: (TimeRange | null),
  ): CancelablePromise<(WebReportData | null)> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/reports/web/',
      query: {
        'company_id': companyId,
        'building_id': buildingId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `At least on of building_id or company_id must be provided`,
        422: `Validation Error`,
      },
    });
  }
}
