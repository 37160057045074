import { Box, Container, Typography } from "@mui/material";
import useUserPascalSettings from "src/hooks/useUserPascalSettings";
import { LABEL_COLOR } from "../utils/ahuUtils";

type PressureLabelProps = {
  currentPressure?: string;
};

interface Label {
  color: string;
  text: string;
  background: string;
}

const PressureLabel = ({ currentPressure }: PressureLabelProps) => {
  const isUserUsingPascals = useUserPascalSettings();
  const thresholds = isUserUsingPascals
    ? { MODERATE: 125, HIGH: 250 }
    : { MODERATE: 0.5, HIGH: 1 };

  function setLabel(currentPressure: number) {
    if (currentPressure < thresholds.MODERATE) {
      return LABEL_COLOR.good;
    } else if (
      currentPressure >= thresholds.MODERATE &&
      currentPressure < thresholds.HIGH
    ) {
      return LABEL_COLOR.moderate;
    } else if (currentPressure >= thresholds.HIGH) {
      return LABEL_COLOR.high;
    } else {
      return LABEL_COLOR.offline;
    }
  }

  const label: Label = setLabel(Number(currentPressure));

  return (
    <Container style={{ padding: 0 }}>
      <Box
        data-testid="chip"
        sx={styles.range(label)}>
        {label.text}
      </Box>
      <Typography
        data-testid="pressure"
        sx={styles.pressure(label)}>
        {currentPressure}
      </Typography>
    </Container>
  );
};

const styles = {
  range: (label: Label) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    fontWeight: 500,
    borderRadius: "0.5rem",
    padding: "0.15rem 0.25rem",
    background: label.background,
    color: label.color,
    border: `1px solid ${label.color}`
  }),
  pressure: (label: Label) => ({
    fontSize: "1.25rem",
    fontWeight: "800",
    marginTop: "2px",
    textAlign: "center",
    color: label.color
  })
};

export default PressureLabel;
