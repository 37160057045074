import { makeStyles } from "tss-react/mui";
import { DEFAULT } from "src/shared/constants/building-status";
import {
  styled,
  LinearProgress,
  linearProgressClasses,
  Grid,
  Typography
} from "@mui/material";
import { ProgressBarData } from "../../types";
import usePaleteColor from "src/hooks/usePaleteColor";

type Props = {
  data: ProgressBarData[];
};

const PALETTE_STATUS = "status";

const ProgressBars = ({ data }: Props) => {
  return (
    <Grid
      item
      data-testid="progress-bar"
      xs={12}
      md={8}>
      {Boolean(data) && (
        <Grid
          container
          rowSpacing={1}>
          {data?.map(metric => (
            <ProgressBar
              {...metric}
              key={`proggresbar-${metric.name}`}></ProgressBar>
          ))}
        </Grid>
      )}
    </Grid>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    box: {
      borderRadius: 4,
      padding: 4,
      transition: "all 0.2s",
      paddingTop: "4px !important",
      marginTop: "4px !important",
      "&:hover": {
        transition: "all 0.2s",
        backgroundColor: "#eee"
      }
    },
    label: {
      color: "grey",
      fontSize: "10px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    value: {
      fontWeight: "450"
    }
  };
});

export default ProgressBars;

const ProgressBar = ({
  name,
  value,
  percentage,
  status,
  unit = null
}: ProgressBarData) => {
  const { classes } = useStyles();
  // status represent part of theme file and colors config
  const { statusColor } = usePaleteColor(PALETTE_STATUS, status ?? DEFAULT);
  const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 4,
    borderRadius: 2,
    width: "90%",
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[300]
    }
  }));

  return (
    <Grid
      item
      xs={3}
      className={classes.box}
      key={`item_${name}`}>
      <Typography className={classes.label}>
        {name} {unit && `(${unit})`}
      </Typography>
      <div>
        {typeof value === "number" ? (
          <Typography
            data-testid={`progressbar-value-${name}`}
            className={classes.value}>
            {Number(value.toFixed(2))}
          </Typography>
        ) : (
          <Typography>--</Typography>
        )}
        <StyledLinearProgress
          data-testid={`progressbar-linearprogress-${name}`}
          sx={{
            [`& .${linearProgressClasses.bar}`]: {
              backgroundColor: statusColor
            }
          }}
          variant="determinate"
          value={percentage > 100 ? 100 : percentage}
        />
      </div>
    </Grid>
  );
};
