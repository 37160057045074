export const setMap = map => {
  window["map"] = map;
};

export const moveToLocation = location => {
  if (location) {
    window["map"].panTo({ lat: location.latitude, lng: location.longitude });
    window["map"].setZoom(12);
  }
};
