/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TimeRange } from './TimeRange';
/**
 * Attributes:
 * entity: The type of entity the `id` field is used for.
 */
export type HvacExportRequestBody = {
  id: string;
  entity_type: HvacExportRequestBody.entity_type;
  time_range: TimeRange;
};
export namespace HvacExportRequestBody {
  export enum entity_type {
    BUILDING = 'building',
    AHU = 'ahu',
    STAGE = 'stage',
  }
}

