enum LegacyWidgetTypes {
  paintBoothServiceLog = "paintBoothServiceLog",
  kettleServiceLog = "waterServiceLog",
  pressureCurves7days = "pressureCurves7days"
}

enum GenericWidgetTypes {
  serviceLog = "serviceLogsBody",
  pressureCurve = "pressureCurves"
}

enum LegacyWidgetNames {
  paintBoothServiceLog = "Service Logs for Paint Booths",
  kettleServiceLog = "Service Logs for Water Devices",
  pressureCurves7days = "Filter Pressure Curves (7 days)",
  pressureCurves3days = "Filter Pressure Curves (3 days)"
}

enum GenericWidgetNames {
  serviceLog = "Service Logs",
  pressureCurve = "Filter Pressure Curves"
}

export const getWidgetType = (type: string) => {
  switch (type) {
    case LegacyWidgetTypes.pressureCurves7days:
      return GenericWidgetTypes.pressureCurve;
    case LegacyWidgetTypes.kettleServiceLog:
      return GenericWidgetTypes.serviceLog;
    case LegacyWidgetTypes.paintBoothServiceLog:
      return GenericWidgetTypes.serviceLog;
    default:
      return type;
  }
};

export const getWidgetName = (name: string) => {
  switch (name) {
    case LegacyWidgetNames.paintBoothServiceLog:
      return GenericWidgetNames.serviceLog;
    case LegacyWidgetNames.kettleServiceLog:
      return GenericWidgetNames.serviceLog;
    case LegacyWidgetNames.pressureCurves7days:
      return GenericWidgetNames.pressureCurve;
    case LegacyWidgetNames.pressureCurves3days:
      return GenericWidgetNames.pressureCurve;
    default:
      return name;
  }
};
