import LoaderComponent from "src/shared/components/loader/loader.component";
import SetNewPasswordComponent from "../set-new-password/set-new-password.component";
import toast from "react-hot-toast";
import Wrapper from "src/sections/session/session-wrapper/session-wrapper.component";
import CustomTextField from "src/shared/components/custom-input/custom-input.component";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { alpha } from "@mui/material/styles";
import { useState } from "react";
import { Box, Button, Grid, Typography, Link } from "@mui/material";
import { Field, Form } from "react-final-form";
import { TokenVerify } from "src/shared/types/login.type";
import { AccountsService } from "src/utils/client";
import { makeStyles } from "tss-react/mui";
import { useNavigate, useSearchParams } from "react-router-dom";

const RecoverCheckCodeComponent = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const email = searchParams.get("email");
  const newAccount = searchParams.get("newAccount");
  const { classes } = useStyles();
  const [loading, setLoading] = useState(false);
  const [tokenValidated, setTokenValidated] = useState(null);

  const validate = (values: TokenVerify) => {
    let { email, token } = values;
    let errors: any = {};
    if (!email) {
      errors.email = "Email is required";
    }

    if (!token) {
      errors.token = "Token is required";
    }

    return errors;
  };

  const submit = (_values: TokenVerify) => {
    setLoading(true);
    setTokenValidated(null);

    let { email, token } = _values;

    let values = {
      email,
      token
    };

    AccountsService.checkVerificationCode({ email, token })
      .then(() => {
        toast.success("Code verified");
        setTokenValidated(values);
      })
      .catch(error => {
        console.log(error.message || error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Wrapper>
      {!tokenValidated && (
        <Form
          onSubmit={submit}
          validate={validate}
          initialValues={{
            email
          }}
          render={({ handleSubmit, hasValidationErrors }) => (
            <form
              data-testid="check-code"
              className={classes.verifyForm}
              onSubmit={handleSubmit}>
              {loading && <LoaderComponent />}
              <Box mb={5}>
                <Typography variant="h4">Enter Token from Email</Typography>
                <Typography variant="subtitle1">
                  Manage air quality effectively
                </Typography>
              </Box>
              <Grid
                container
                spacing={2}>
                <Grid
                  item
                  xs={12}>
                  <Field name="token">
                    {({ input, meta }) => (
                      <CustomTextField
                        autoFocus
                        label={null}
                        error={Boolean(meta.touched && meta.error)}
                        helperText={meta.touched && meta.error}
                        InputProps={input}
                        placeholder="Verification code"
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={
                  Boolean(tokenValidated) || hasValidationErrors || loading
                }
                className={classes.verificationBtn}
                data-testid="send-token-button">
                Send
                <ArrowForwardRoundedIcon sx={{ marginLeft: 1, fontSize: 16 }} />
              </Button>
            </form>
          )}
        />
      )}

      {tokenValidated && (
        <SetNewPasswordComponent
          email={tokenValidated.email}
          token={tokenValidated.token}
          createAccount={Boolean(newAccount)}
        />
      )}
      <Box className={classes.getHelp}>
        <Typography
          variant="body1"
          className={classes.supportText}>
          Get help to choose a package that suits you by contacting the
        </Typography>
        <Link href="mailto:support@i-qlair.com?subject=Help With Account">
          "MANN+HUMMEL Support"
        </Link>
      </Box>
      <div className={classes.extraOptions}>
        <Typography
          variant="body1"
          className={classes.supportText}>
          Already have an account?{" "}
          <Link
            onClick={() => {
              navigate("/login");
            }}>
            Sign In
          </Link>
        </Typography>
        {!newAccount && (
          <>
            <Typography variant="body1">
              Don't have an account yet?{" "}
              <Link
                onClick={() => {
                  navigate("/create-account");
                }}>
                Create Account
              </Link>
            </Typography>
          </>
        )}
      </div>
    </Wrapper>
  );
};

export const useStyles = makeStyles()(theme => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center"
  },
  mainColumn: {
    paddingLeft: 24,
    paddingRight: 24
  },
  verifyForm: {
    height: 280,
    maxWidth: 375,
    display: "flex",
    flexDirection: "column" as const,
    alignSelf: "center",
    justifyContent: "space-between",
    position: "relative" as const
  },
  fieldContainer: {
    height: 64,
    display: "grid"
  },
  getHelp: {
    marginTop: "3em",
    textAlign: "center" as const,
    maxWidth: 350
  },
  extraOptions: {
    marginTop: 24,
    textAlign: "center" as const,
    maxWidth: 350
  },
  supportText: {
    marginTop: 12,
    marginBottom: 12,
    lineHeight: 1.5
  },
  verificationBtn: {
    marginTop: "1em"
  },
  tokenInput: {
    "& .MuiInputBase-input": {
      borderRadius: 8,
      textAlign: "center" as const,
      position: "relative",
      backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
      width: "100%",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow"
      ]),
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main
      }
    }
  }
}));

export default RecoverCheckCodeComponent;
