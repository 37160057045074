import { useGetAhuForBuildingQuery } from "src/store/services/widgets/widgets.service";
import { useGetAhuStagesQuery } from "src/store/services/hvac/hvac.service";
import useGetBuildings from "src/hooks/useGetBuildings";

const useGetDataForAhu = (
  buildingId: string,
  ahuId: string,
  requiresConfig: boolean
) => {
  const skipEndpointCall = requiresConfig || !Boolean(ahuId);
  const {
    buildings,
    isLoading: isLoadingBuilding,
    isFetching: isFetchingBuilding,
    isError: isBuildingError,
    refetch: buildingRefetch
  } = useGetBuildings();
  const {
    data: ahu,
    isLoading: isLoadingAhu,
    isFetching: isFetchingAhu,
    isError: isAhuError,
    refetch: ahuRefetch
  } = useGetAhuForBuildingQuery(
    {
      buildingId,
      ahuId
    },
    { skip: skipEndpointCall }
  );
  const {
    data: stages,
    isLoading: isLoadingAhuStage,
    isFetching: isFetchingAhuStage,
    isError: isAhuStageError,
    refetch: ahuStagesRefetch
  } = useGetAhuStagesQuery(
    {
      buildingId,
      ahuId
    },
    { skip: skipEndpointCall }
  );

  return {
    isLoading: isLoadingAhu || isLoadingAhuStage || isLoadingBuilding,
    isFetching: isFetchingAhu || isFetchingAhuStage || isFetchingBuilding,
    stages,
    building: buildings?.find(({ id }) => id === buildingId),
    ahu,
    isError: isBuildingError || isAhuStageError || isAhuError,
    refetchAhuData: () => {
      ahuRefetch();
      ahuStagesRefetch();
      buildingRefetch();
    }
  };
};

export default useGetDataForAhu;
