import { makeStyles } from "tss-react/mui";
export const useStyles = makeStyles()(() => {
  return {
    root: {
      height: "100%",
      padding: "10px"
    },
    loader: {
      position: "absolute",
      top: 0,
      left: 0,
      height: "100%"
    },
    subtitle: {
      whiteSpace: "nowrap",
      flex: 1,
      overflow: "hidden",
      textOverflow: "ellipsis",
      textAlign: "right",
      position: "relative",
      top: -2
    },
    noDevice: {
      display: "flex",
      height: "100%",
      alignItems: "center",
      justifyContent: "center"
    },
    smallHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "row",
      top: -10,
      position: "relative",
      gap: 8
    },
    header: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column"
    },
    default: {
      cursor: "default",
      display: "flex",
      gap: "4px"
    },
    pointer: {
      cursor: "pointer",
      display: "flex",
      gap: "4px",
      "&:hover": { color: "#0795bb" }
    },
    linkIcon: {
      margin: "auto",
      transform: "rotate(-30deg)",
      fontSize: 15,
      color: "#a9a9a9"
    },
    body: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column"
    },
    gaugeContainer: {
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      position: "relative"
    },
    buildingName: {
      position: "relative",
      top: -15
    },
    inletOutletPSI: {
      display: "flex",
      width: "90%",
      position: "absolute",
      top: "20%",
      justifyContent: "space-between"
    },
    inletOutletPSIContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "center"
    }
  };
});
