import Wave2 from "src/assets/wave2.png";
import { makeStyles } from "tss-react/mui";
export const useStyles = makeStyles()(theme => {
  return {
    root: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    },
    main: {
      height: "100%"
    },
    description: {
      backgroundColor:
        theme.palette.primary["500"] || theme.palette.primary["main"],
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      padding: "3%",
      position: "relative",
      overflow: "hidden"
    },
    subtitle: {
      color: "#fff"
    },
    wave2: {
      marginTop: "4em",
      backgroundImage: `url(${Wave2})`,
      width: "100%",
      height: "60%",
      position: "fixed",
      backgroundSize: "cover",
      backgroundPosition: "400px",
      filter: "grayscale(0) !important"
    },
    logo: {
      marginBottom: 50,
      width: 150
    },
    wavesSide: {
      position: "absolute",
      right: 0,
      filter: "brightness(0) invert(1)"
    },
    mainColumn: {
      paddingLeft: 24,
      paddingRight: 24,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    content: {
      zIndex: 2
    }
  };
});
