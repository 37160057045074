import { useState } from "react";
import { Box, Container, Grid, Paper, Stack, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import useGetChartData from "src/hooks/useGetChartData";
import useGetKettleAndSenorsData from "src/hooks/useGetKettleAndSensorsData";
import LoaderComponent from "src/shared/components/loader/loader.component";
import { setSelectedKettle } from "src/store/kettle/reducer";
import {
  getKettleChartGranularity,
  getKettleEndDate,
  getKettleStartDate,
  getSelectedKettle
} from "src/store/kettle/selector";
import { useGetKettlesForBuildingQuery } from "src/store/services/kettle/kettle.service";
import DetailsHeader from "../../components/DetailsHeader";
import ActivityLogs from "../hvac/air-handling-units/ahu-details/hvac-activity-log/activity-log.component";
import KettleChart from "./kettle.chart";
import KettleDetailsCard, { KettleTypes } from "./kettle.details.card";

export enum KettleChartColors {
  INLET = "#90CAE5",
  OUTLET = "#A290E5",
  DIFFERENTIAL = "#E58fDB"
}

export const KettleDetails = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { buildingId, kettleId } = useParams();
  const kettle = useAppSelector(getSelectedKettle);
  const startDate = useAppSelector(getKettleStartDate);
  const endDate = useAppSelector(getKettleEndDate);
  const granularity = useAppSelector(getKettleChartGranularity);
  const [openAddServiceLog, setOpenAddServiceLog] = useState(false);

  const {
    isLoading: isLoadingKettleData,
    inletPSI,
    inletSensor,
    outletSensor,
    outletPSI,
    differentialPSI,
    isDifferentialPressureOutAlert,
    alertColor
  } = useGetKettleAndSenorsData(buildingId, kettleId);

  const { data: kettlesInBuilding } = useGetKettlesForBuildingQuery(buildingId);

  const {
    chartData,
    chartDataExist,
    isLoading: isLoadingCharts
  } = useGetChartData(
    inletSensor?.id,
    outletSensor?.id,
    buildingId,
    startDate,
    endDate,
    granularity
  );

  const loading = isLoadingKettleData || isLoadingCharts;

  const navigateToKettle = (next: boolean) => {
    const currentIndex =
      kettlesInBuilding?.findIndex(item => item.id === kettleId) ?? 0;

    let newIndex = next ? currentIndex + 1 : currentIndex - 1;
    if (newIndex === -1) {
      newIndex = kettlesInBuilding.length - 1;
    }

    if (newIndex === kettlesInBuilding.length) {
      newIndex = 0;
    }

    let nextKettle = kettlesInBuilding[newIndex];
    let newUrl = `/buildings/kettle/${buildingId}/${nextKettle.id}`;
    dispatch(setSelectedKettle(nextKettle));
    navigate(newUrl);
  };

  return (
    <Container
      maxWidth={false}
      sx={{ p: 2, pt: 0, bgcolor: "#f3f6ff" }}
      data-testid="kettle-details">
      {loading ? (
        <LoaderComponent />
      ) : (
        <>
          <DetailsHeader
            item={kettle}
            loading={loading}
            hasItems={kettlesInBuilding?.length > 1}
            navigate={navigateToKettle}
          />
          <Paper sx={{ heigh: "100vh", padding: "10px" }}>
            <Grid
              container
              spacing={2}>
              <Grid
                item
                xs={12}
                sm={5}
                md={4}
                lg={3}
                xl={2.5}>
                <Box sx={styles.asideContainer}>
                  <Typography>Pressure details</Typography>
                  <KettleDetailsCard
                    type={KettleTypes.INLET}
                    pressure={inletPSI}
                  />
                  <KettleDetailsCard
                    type={KettleTypes.OUTLET}
                    pressure={outletPSI}
                  />
                  <KettleDetailsCard
                    type={KettleTypes.DIFFERENTIAL}
                    pressure={differentialPSI}
                    alertColor={alertColor}
                    isDifferentialPressureOutAlert={
                      isDifferentialPressureOutAlert
                    }
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={7}
                md={8}
                lg={9}
                xl={9.5}>
                <Stack my={2}>
                  <KettleChart
                    chartData={chartData}
                    chartDataExist={chartDataExist}
                  />
                  <Box style={styles.auditLogContainer}>
                    <ActivityLogs
                      key={openAddServiceLog ? "open" : "closed"}
                      kettle={kettle}
                      buildingId={buildingId}
                      open={openAddServiceLog}
                      setOpen={setOpenAddServiceLog}
                    />
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Paper>
        </>
      )}
    </Container>
  );
};

const styles = {
  auditLogContainer: {
    border: "2px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "1rem",
    ml: { md: "-1rem", sm: "0rem" }
  },
  asideContainer: {
    position: "sticky",
    top: 52,
    padding: "16px",
    paddingTop: "32px"
  }
};

export default KettleDetails;
