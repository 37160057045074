import moment from "moment";
import { useMemo } from "react";
import LoaderComponent from "src/shared/components/loader/loader.component";
import { Container, Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import useGetPolutantDictionary from "src/hooks/useGetPollutantDictionary";
import { useGetUserSettings } from "src/sections/settings/hooks";
import { setSelectedDeviceMetric } from "src/store/hvac/reducer";
import {
  getDeviceGraphEndDate,
  getDeviceGraphGranuality,
  getDeviceGraphStartDate,
  getSelectedDeviceMetric
} from "src/store/hvac/selectors";
import {
  useGetDeviceInsightQuery,
  useGetDeviceLegacyDataQuery,
  useGetDeviceQuery,
  useGetRoomDevicesQuery
} from "src/store/services/buildings/buildings.service";
import { getChartDataForDetailsPage } from "src/utils/pressure.utils";
import { convertToFahrenheit } from "src/utils/units";
import AirQualityOverTime from "../aq-over-time/aq.over-time.section";
import AverageValues from "../average-values/average.values";
import DeviceDetailsHeader from "../header/device.details.header";
import DeviceDetailsSideMenu from "../side-menu/device.details.side.menu";

enum UserSettingsKeys {
  AQI = "aqi",
  TEMPERATURE = "t",
  H = "h",
  FORMALDEHYDE = "form",
  OZONE = "o3",
  NITROGEN_DIOXIDE = "no2",
  SULFOR_DIOXIDE = "so2",
  RESPIRABLE_PARTICULATE_MATTER = "pmone"
}

export const DEFAULT_METRIC = "aqi";

export const DeviceDetails = () => {
  const { buildingId, roomId, deviceId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pollInfoDict } = useGetPolutantDictionary();
  const { temperature } = useGetUserSettings();
  const granularity = useAppSelector(getDeviceGraphGranuality);
  const selectedMetric = useAppSelector(getSelectedDeviceMetric);
  const startDate = useAppSelector(getDeviceGraphStartDate);
  const endDate = useAppSelector(getDeviceGraphEndDate);
  const unixStartDate = moment(startDate).startOf("day").unix();
  const unixEndDate = moment(endDate).endOf("day").unix();

  const { data: roomDevices, isLoading: isLoadingDevices } =
    useGetRoomDevicesQuery({ buildingId, roomId });

  const devices = roomDevices?.devices;

  const selectedDevice = devices?.find(d => d.id === deviceId);

  const { data: details, isLoading: isLoadingDevice } = useGetDeviceQuery({
    deviceId,
    buildingId
  });

  const {
    data: historyData,
    isLoading,
    isFetching
  } = useGetDeviceLegacyDataQuery({
    deviceId,
    buildingId,
    startDate: unixStartDate,
    endDate: unixEndDate,
    granularity
  });

  const { data: recommendation, isLoading: loadingRecommendation } =
    useGetDeviceInsightQuery({
      deviceId,
      buildingId,
      startDate: unixStartDate.toString(),
      endDate: unixEndDate.toString()
    });

  const loading =
    isLoading || isLoadingDevice || isFetching || isLoadingDevices;

  const historyExist = Boolean(historyData);

  const updatedHistoryData = useMemo(() => {
    if (!historyExist || loading) return {};
    return Object.fromEntries(
      Object.entries(historyData).map(([key, value]) => {
        const updatedValue = Object.entries(value).map(([key, value]) => {
          if (pollInfoDict[key]) {
            return Object.assign({}, pollInfoDict[key], {
              key,
              data: Object.entries(value).map(([date, value]) => ({
                date: moment.unix(date as any),
                value: selectedMetric
                  ? selectedMetric === UserSettingsKeys.TEMPERATURE &&
                    temperature.toLowerCase() === "fahrenheit"
                    ? convertToFahrenheit(value as number)
                    : value
                  : value
              }))
            });
          }
          return value;
        });
        return [key, updatedValue];
      })
    );
  }, [loading, temperature, selectedMetric, granularity]);

  const historyDateExist = Boolean(updatedHistoryData?.length);

  const chartData = useMemo(() => {
    if (loading) return [];
    const outdoorData = updatedHistoryData.outdoor.find(item => {
      const key = selectedMetric ?? DEFAULT_METRIC;
      return item.key === key;
    });

    if (Boolean(selectedMetric)) {
      const indoorData = updatedHistoryData.indoor.find(
        item => item.key === selectedMetric
      );

      return [
        getChartDataForDetailsPage(
          indoorData?.data ?? null,
          indoorData?.short_name.toString(),
          indoorData?.color.toString()
        ),
        (outdoorData?.data as any)?.length
          ? getChartDataForDetailsPage(
              outdoorData?.data ?? null,
              `Outdoor ${outdoorData?.short_name}`,
              outdoorData?.color.toString(),
              true
            )
          : null
      ].filter(Boolean);
    }
    if (historyExist) {
      return updatedHistoryData.aqi_normal
        .filter(
          ({ name }) =>
            name !== UserSettingsKeys.AQI &&
            name !== UserSettingsKeys.TEMPERATURE &&
            name !== UserSettingsKeys.FORMALDEHYDE &&
            name !== UserSettingsKeys.H &&
            name !== UserSettingsKeys.RESPIRABLE_PARTICULATE_MATTER
        )
        .map(data => {
          return getChartDataForDetailsPage(
            data.data,
            data.short_name.toString(),
            data.color.toString()
          );
        })
        .concat([
          getChartDataForDetailsPage(
            outdoorData?.data ?? null,
            `Outdoor ${outdoorData?.short_name}`,
            outdoorData?.color.toString(),
            true
          )
        ]);
    }
  }, [selectedMetric, loading, historyDateExist, granularity]);

  const navigateToDevice = (next: boolean) => {
    let deviceIndex = devices?.findIndex(item => item.id === details?.id);

    let newIndex = next ? deviceIndex + 1 : deviceIndex - 1;
    if (newIndex === -1) {
      newIndex = devices.length - 1;
    }

    if (newIndex === devices.length) {
      newIndex = 0;
    }

    let newDevice = devices[newIndex];
    let newUrl = `/buildings/iaq/${buildingId}/rooms/${roomId}/devices/${newDevice.id}`;
    dispatch(setSelectedDeviceMetric(null));
    navigate(newUrl);
  };

  return (
    <Container
      maxWidth={false}
      sx={{ p: 2, bgcolor: "rgb(243, 246, 255)" }}>
      {loading ? (
        <LoaderComponent />
      ) : (
        <>
          <DeviceDetailsHeader
            device={selectedDevice}
            hasMore={devices?.length > 1}
            disabled={loading || loadingRecommendation}
            onNavigate={navigateToDevice}
          />
          <Grid
            container
            spacing={2}>
            <DeviceDetailsSideMenu details={details} />
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              lg={9}>
              <AirQualityOverTime
                loading={loading}
                chartData={chartData}
              />
              <AverageValues
                loading={loading}
                chartData={chartData}
                recommendation={recommendation}
                updatedHistoryData={updatedHistoryData}
                loadingRecommendation={loadingRecommendation}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  );
};

export default DeviceDetails;
