import { useMemo } from "react";
import { useGetHvacStatusInfoDictionaryQuery } from "src/store/services/general/general.service";
import { convertFromIWGToPascals } from "src/utils/pressure.utils";
import useUserPascalSettings from "./useUserPascalSettings";

const useGetHvacStatusInfoDictionary = () => {
  const { data, isLoading, isFetching, isError } =
    useGetHvacStatusInfoDictionaryQuery();
  const isUserUsingPascals = useUserPascalSettings();
  const dataExist = Boolean(data);
  const convertedDictionary = useMemo(() => {
    if (isLoading || isFetching || !dataExist) return {};
    // Data for this endpoint is saved in IWG so when user is using pascals we need to convert it
    return Object.fromEntries(
      Object.entries(data).map(([key, value]) => {
        const newValue = Object.assign({}, value, {
          pressure_status: value.pressure_status.map(
            ({ low, high, status }) => ({
              status,
              low: isUserUsingPascals ? convertFromIWGToPascals(low) : low,
              high: isUserUsingPascals ? convertFromIWGToPascals(high) : high
            })
          )
        });
        return [key, newValue];
      })
    );
  }, [isLoading, isFetching, dataExist]);

  return {
    dictionary: convertedDictionary,
    isLoading,
    isFetching,
    isError
  };
};

export default useGetHvacStatusInfoDictionary;
