import qlairWhiteLogo from "src/assets/qlair-white-logo-350w.png";
import Wave2 from "src/assets/wave2.png";
import { Grid, Typography, Box } from "@mui/material";
import { useStyles } from "./session-wrapper.style";

type Props = {
  children: any;
};

const SessionWrapperComponent = (props: Props) => {
  const { children } = props;
  const { classes } = useStyles();
  return (
    <div
      className={classes.root}
      data-testid="session-wrapper">
      <Grid
        container
        className={classes.main}>
        <Grid
          sx={{
            display: {
              xs: "none !important",
              sm: "flex !important"
            }
          }}
          item
          sm={5}
          lg={3}
          md={4}
          className={classes.description}>
          <img
            src={Wave2}
            className={classes.wavesSide}></img>
          <img
            src={qlairWhiteLogo}
            className={classes.logo}></img>
          <Typography
            variant="h4"
            className={classes.subtitle}
            gutterBottom>
            Powerful Industrial IoT Solutions backed by MANN+HUMMEL,
            <br />
            Leaders In Filtration
          </Typography>
          <Typography
            variant="body1"
            className={classes.subtitle}>
            Turn real-time data into action for cleaner, safer, and more
            efficient environments that thrive.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={7}
          md={8}
          lg={9}
          className={classes.mainColumn}>
          <Box
            className={classes.wave2}
            sx={{
              display: { xs: "block", sm: "none" }
            }}></Box>
          <div className={classes.content}>{children}</div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SessionWrapperComponent;
