import { useRef } from "react";
import LoaderComponent from "src/shared/components/loader/loader.component";
import { makeStyles } from "tss-react/mui";
import { Typography } from "@mui/material";
import useGetFilterLoadData from "../hooks/useGetFilterLoadData";
import Error from "src/shared/components/Error/Error";
import { Stage } from "../filter-load-widget/filter-load-widget.component";

type Props = {
  ahuId?: string;
  requiresConfig?: boolean;
  buildingId?: string;
};

const FilterResistanceWidgetComponent = ({
  ahuId,
  requiresConfig,
  buildingId
}: Props) => {
  const { classes } = useStyles();
  const componentRef = useRef(null);

  const { stages, ahu, loading, error, refetch } = useGetFilterLoadData(
    buildingId,
    ahuId,
    requiresConfig
  );

  return (
    <div
      data-testid="filter-resistance-widget"
      className={classes.root}
      ref={componentRef}>
      {requiresConfig ? (
        <div>
          <Typography variant="h6">Filter Resistance</Typography>
          <Typography>Configuration is required</Typography>
        </div>
      ) : loading ? (
        <LoaderComponent />
      ) : error ? (
        <Error
          error={error}
          setRefetchAgain={refetch}></Error>
      ) : (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column"
          }}>
          <div>
            <Typography variant="h6">Filter Resistance: {ahu.name}</Typography>
          </div>
          <div className={classes.grid}>
            {Object.values(stages).map(stage => {
              return (
                <Stage
                  key={stage.name}
                  stage={stage}
                  isLoadWidget={false}></Stage>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {
      height: "100%",
      padding: "10px"
    },
    loader: {
      position: "absolute",
      top: 0,
      left: 0
    },
    grid: {
      display: "flex",
      height: "100%"
    }
  };
});

export default FilterResistanceWidgetComponent;
