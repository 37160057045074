import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from "@mui/material";
import moment from "moment";
import { ChangeEvent } from "react";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import useUserPascalSettings from "src/hooks/useUserPascalSettings";
import LoaderComponent from "src/shared/components/loader/loader.component";
import { setSelectedAhuDetailsStageId } from "src/store/hvac/reducer";
import { Stage, StageType } from "src/utils/client";
import { convertFromIWGToPascals } from "src/utils/pressure.utils";
import AhuAccordionCard from "../../../../../components/AhuAccordionCard";
import useHvacSensors from "../../../../../hooks/useHvacSensors";
import Airflow from "./airflow/ahu-airflow.component";
import FilterDetails from "./filter-details/filter-details.component";

type StageAccordionProps = {
  stage: Stage;
  index?: number;
  buildingId: string;
  expandedStageId: string | null;
  terminalPressure?: number;
  setExpandedStageId: React.Dispatch<React.SetStateAction<string>>;
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const StageAccordion = ({
  stage,
  index,
  buildingId,
  expandedStageId,
  terminalPressure,
  setIsDrawerOpen,
  setExpandedStageId
}: StageAccordionProps) => {
  const dispatch = useAppDispatch();
  const isUserUsingPascals = useUserPascalSettings();
  const { hvacData, isLoading, isError } = useHvacSensors(buildingId, stage);

  const currentPressure = Boolean(hvacData?.data)
    ? isUserUsingPascals
      ? convertFromIWGToPascals(hvacData?.data?.pressure_drop?.left).toFixed(2)
      : hvacData?.data?.pressure_drop?.left.toFixed(2)
    : "---";

  const lastConnection: string = moment(hvacData?.time).format("lll");

  const handleChange =
    (stage: Stage) => (_: ChangeEvent, isExpanded: boolean) => {
      const newId: string | null = isExpanded ? stage.id : null;
      setExpandedStageId(newId);
      dispatch(setSelectedAhuDetailsStageId(newId));
    };

  if (stage.stage_type === StageType.AIRFLOW) {
    return (
      <Airflow
        expandedStageId={expandedStageId}
        setExpandedStageId={setExpandedStageId}
        airflow={hvacData?.data?.air_velocity}
        stage={stage}
      />
    );
  } else {
    return (
      <Accordion
        data-testid="accordion"
        disableGutters
        elevation={0}
        sx={styles.accordion}
        expanded={expandedStageId === stage.id}
        onChange={handleChange(stage)}>
        <AccordionSummary sx={{ width: 1, px: 1 }}>
          {isLoading ? (
            <LoaderComponent></LoaderComponent>
          ) : isError ? (
            <Typography
              data-testid="error"
              variant="h6">
              Error while loading latest data
            </Typography>
          ) : (
            <AhuAccordionCard
              stage={stage}
              index={index}
              expandedStageId={expandedStageId}
              currentPressure={currentPressure}
            />
          )}
        </AccordionSummary>
        <AccordionDetails sx={styles.details}>
          <FilterDetails
            stage={stage}
            lastConnection={lastConnection}
            terminalPressure={terminalPressure}
            setIsDrawerOpen={setIsDrawerOpen}
          />
        </AccordionDetails>
      </Accordion>
    );
  }
};

const styles = {
  accordion: {
    border: `2px solid rgba(0, 0, 0, 0.1)`,
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    }
  },
  details: {
    borderTop: "2px solid rgba(0, 0, 0, 0.1)"
  }
};

export default StageAccordion;
