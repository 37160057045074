import { makeStyles } from "tss-react/mui";
export const useStyles = makeStyles()(() => {
  return {
    log: {
      display: "flex",
      margin: "0.5rem 0rem 1rem 1rem",
      borderTop: "2px solid rgba(0, 0, 0, 0.1)",
      "&:first-of-type": {
        borderTop: 0
      }
    },
    margin: {
      margin: "0.5rem 0rem"
    },
    stageItem: {
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
      padding: "2px"
    },
    iconItem: {
      display: "flex",
      alignItems: "center",
      padding: "2px",
      marginLeft: "0.5rem"
    },
    item: {
      display: "flex",
      alignItems: "center",
      padding: "2px"
    },
    wrenchItem: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: "-1rem"
    },
    wrench: {
      color: "#FFF",
      padding: "2px",
      background: "#0795BB",
      borderRadius: "1px",
      boxShadow: "0.2rem 0.1rem 0.1rem rgba(0, 0, 0, 0.2)"
    },
    logTitle: {
      color: "#0795BB",
      fontWeight: 500
    },
    stage: {
      fontWeight: 500
    },
    descriptionItem: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      padding: "2px 2px 2px 2rem"
    },
    worker: {
      fontSize: "0.8rem",
      color: "rgba(0, 0, 0, 0.5)"
    },
    workerAndEdit: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%"
    },
    editButton: {
      fontWeight: 500,
      color: "#0795BB",
      cursor: "pointer",
      ":hover": {
        textDecoration: "underline"
      }
    },
    dateItem: {
      display: "flex",
      alignItems: "end",
      justifyContent: "center",
      flexDirection: "column",
      padding: "2px"
    },
    date: {
      paddingRight: "0.5rem",
      color: "rgba(0, 0, 0, 0.5)"
    }
  };
});
