import { RootState } from "../rootReducer";

// TODO Remove fallback after some time to use redux state
export const getIsSigningIn = (state: RootState) => state?.auth?.loading;

export const getAccessToken = (state: RootState) =>
  state?.auth?.AccessToken || localStorage.getItem("token");

export const getUserName = (state: RootState) =>
  state?.auth?.user?.name || localStorage.getItem("name");

export const getUserId = (state: RootState) =>
  state?.auth?.user?.id || localStorage.getItem("id");

export const getUserEmail = (state: RootState) =>
  state?.auth?.user?.email || localStorage.getItem("email");

export const getUserTerms = (state: RootState) =>
  state?.auth?.user?.accepted_terms || localStorage.getItem("terms");
