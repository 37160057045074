import { makeStyles } from "tss-react/mui";
export const useStyles = makeStyles()(() => {
  return {
    root: {
      width: 350,
      display: "flex",
      flexDirection: "column",
      height: "100%",
      overflow: "hidden"
    },
    title: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "1em",
      paddingTop: "0.5em",
      paddingBottom: "0.5em",
      borderBottom: "1px solid #E9EAEA"
    },
    body: {
      flex: 1,
      display: "flex",
      height: "93%"
    },
    form: {
      flex: 1,
      display: "flex",
      flexDirection: "column"
    },
    formContent: {
      flex: 1,
      padding: "0.75em",
      display: "flex",
      flexDirection: "column",
      overflow: "auto",
      maxHeight: "calc(100% - 60px)"
    },
    panel: {
      backgroundColor: "#F8F9FF",
      padding: 8,
      display: "flex",
      gap: 8,
      border: "1px solid #EBEFFC",
      borderRadius: 8,
      marginBottom: 8,
      flexDirection: "column",
      position: "relative"
    },

    actions: {
      display: "flex",
      padding: "0.75em",
      justifyContent: "end",
      borderTop: "1px solid #E9EAEA",
      "& button": {
        marginLeft: "1em"
      }
    },
    input: {
      width: "100%",
      backgroundColor: "white"
    },
    fieldset: {
      position: "absolute",
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(255,255,255, 0.5)",
      zIndex: 2
    }
  };
});
