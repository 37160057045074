import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { Typography } from "@mui/material";
import { NA } from "src/shared/constants/generals";
import useGetPolutantDictionary from "src/hooks/useGetPollutantDictionary";
import AirQualityIndicator from "src/sections/buildings/air-quality-indicator/air-quality-indicator";
import { useGetLatestOutdoorAqDataPointQuery } from "src/store/services/general/general.service";
import { useStyles } from "./room-outdoor-pollutants.style";
import LoaderComponent from "src/shared/components/loader/loader.component";
import useGetBuildings from "src/hooks/useGetBuildings";
import { useParams } from "react-router-dom";

type Props = {
  classesFromWidget?: any;
  forWidget?: boolean;
};

const RoomOutdoorPollutantsComponent = ({
  classesFromWidget,
  forWidget
}: Props) => {
  const { buildingId } = useParams();
  const { classes } = useStyles();
  const { pollInfoDict } = useGetPolutantDictionary();
  const { building } = useGetBuildings(buildingId);
  const {
    data: outdoorData,
    isLoading,
    isFetching
  } = useGetLatestOutdoorAqDataPointQuery(building?.id, {
    skip: !Boolean(building)
  });

  const pollutantWidth =
    outdoorData?.data && !forWidget
      ? `${100 / Object.keys(outdoorData.data)?.length}% !important`
      : "100%";

  return (
    <div
      className={forWidget ? classesFromWidget?.root : classes.root}
      data-testid="room-pollutant">
      {!forWidget && (
        <div className={classes.header}>
          <Typography variant="subtitle1">Outdoor</Typography>
          <Typography
            variant="body1"
            gutterBottom
            className={classes.address}>
            <FmdGoodIcon />
            {building?.location?.address}
          </Typography>
        </div>
      )}

      <div className={classes.wrapper}>
        {isLoading || isFetching ? (
          <LoaderComponent></LoaderComponent>
        ) : (
          outdoorData?.data &&
          Object.keys(outdoorData.data).map(key => {
            const value = outdoorData.data[key].value || (forWidget ? "-" : NA);

            return (
              <>
                <AirQualityIndicator
                  key={key}
                  value={value}
                  pollutantType={key}
                  isPollutant
                  showLabel
                  label={pollInfoDict[key]?.short_name || key}
                  contianerWidth={pollutantWidth}
                />
              </>
            );
          })
        )}
      </div>
    </div>
  );
};

export default RoomOutdoorPollutantsComponent;
