import { useEffect, useMemo, useState } from "react";
import { Alert, AlertTitle, Grid, Box, Stack } from "@mui/material";
import LoaderComponent from "src/shared/components/loader/loader.component";
import WidgetsModalComponent from "../widgets-modal/widgets-modal.component";
import AlertModalComponent from "src/sections/notifications/alert-modal/alert-modal.component";
import PageDetailsModalComponent from "../page-details-modal/page-details-modal.component";
import {
  Responsive,
  WidthProvider,
  Layout,
  ResponsiveProps,
  WidthProviderProps
} from "react-grid-layout";
import { useStyles } from "./overview.style";
import Widget from "../components/Widget/Widget";
import ConfigurationModal from "../components/ConfigurationModal/ConfigurationModal";
import MenuAction from "../components/MenuAction/MenuAction";
import PageTabs from "../components/PageTabs/PageTabs";
import Welcome from "../components/Welcome/Welcome";
import useOverview from "src/hooks/useOverview";
import { v4 as uuid } from "uuid";
import { Page, IWidget } from "./types";
import {
  updatePages,
  updateGridLayoutDataForPage,
  getGridDataForWidget
} from "./utils";
import { useUpdateUsersPagesMutation } from "src/store/services/overview/overview.service";
import { getUserId } from "src/store/auth/selectors";
import { useAppSelector } from "src/hooks/useAppSelector";
import { useParams } from "react-router";
import useGetWidgetsForPage from "../hooks/useGetPageData";
import PaintBoothSelect from "../components/PaintBoothSelect";

const GRID_LAYOUT_HEIGTH = 170;

export const Overview = () => {
  const userId = useAppSelector(getUserId);
  const { classes } = useStyles();
  const tabId = Number(useParams()?.tabId);

  const {
    editing,
    isConfigurationModalOpen,
    closeConfigurationModal,
    openConfigurationModal
  } = useOverview();

  const {
    pages,
    paintBoothWidgets,
    isLoading: isLoadingWidgets,
    isFetching,
    widgets,
    setWidgets,
    isError: error
  } = useGetWidgetsForPage();

  const [updateUsersPages, { isLoading: isLoadingSave }] =
    useUpdateUsersPagesMutation();

  const loading = isLoadingWidgets || isLoadingSave;
  const [widgetToConfigure, setWidgetToConfigure] = useState(null);
  const ResponsiveGridLayout = useMemo(() => WidthProvider(Responsive), []);
  const pagesLength = pages?.length;

  const widgetsToConfigure =
    widgets?.filter(widget => widget?.props?.requiresConfig) ?? [];

  useEffect(() => {
    if (isLoadingWidgets || !widgetsToConfigure?.length) return;
    const newWidget = widgetsToConfigure[0];

    if (newWidget.type === "alerts") {
      save({
        ...newWidget.props,
        widgetId: newWidget?.props?.widgetId ?? uuid()
      });
      return;
    }
    handleOpenConfigurationModal(newWidget);
  }, [widgetsToConfigure.length]);

  const save = (newWidgetConfig: any) => {
    const { requiresConfig, widgetId } = newWidgetConfig;
    let updatedPages: Page[];
    if (requiresConfig) {
      const newWidgets = widgets.map(widget => {
        if (widget.props.widgetId === widgetId) {
          return {
            ...widget,
            props: {
              ...newWidgetConfig,
              requiresConfig: false
            }
          };
        }
        return widget;
      });
      updatedPages = updatePages(pages, tabId, newWidgets);
    } else {
      const newWidgetsData = widgets?.map(widget => {
        if (
          Boolean(widget.props?.widgetId) &&
          widget.props.widgetId === widgetId
        ) {
          return Object.assign({}, widget, { props: newWidgetConfig });
        } else return widget;
      });

      updatedPages = updatePages(pages, tabId, newWidgetsData);
    }
    updateUsersPages({ userId, updatedPages });
    closeConfigurationModal();
  };

  const handleOpenConfigurationModal = (widgetToConfigure: IWidget) => {
    setWidgetToConfigure(widgetToConfigure);
    openConfigurationModal();
  };

  const handleOnSave = () => {
    const updatedPages = updatePages(pages, tabId, widgets);
    updateUsersPages({ userId, updatedPages });
  };

  const updateItemLayout = (itemLayout: Layout[]) => {
    const newWidgetsData = updateGridLayoutDataForPage(widgets, itemLayout);
    setWidgets(newWidgetsData);
  };
  const GRIDCONFIG = {
    rowHeight: GRID_LAYOUT_HEIGTH,
    isDraggable: editing,
    isResizable: editing,
    containerPadding: {
      lg: [16, 16]
    },
    margin: {
      lg: [16, 16]
    },
    compactType: "vertical",
    className: "layout",
    breakpoints: {
      lg: 1200,
      md: 900,
      sm: 600,
      xs: 0,
      xxs: 0
    },
    cols: { lg: 12, md: 12, sm: 6, xs: 2, xxs: 2 },
    onDragStop: updateItemLayout,
    onResizeStop: updateItemLayout
  } as Readonly<ResponsiveProps & WidthProviderProps>;

  return (
    <Box
      display="div"
      data-widgetsid="overview"
      className={classes.root}>
      {loading || isFetching ? (
        <LoaderComponent />
      ) : error ? (
        <OverviewError error={error}></OverviewError>
      ) : (
        <>
          {!Boolean(pagesLength) && (
            <Welcome
              classes={classes}
              loading={loading}></Welcome>
          )}
          <Stack
            justifyContent={"space-between"}
            alignItems={"center"}
            flexDirection={"row"}>
            <PageTabs pages={pages} />
            {paintBoothWidgets?.length > 0 && <PaintBoothSelect />}
          </Stack>

          {Boolean(widgets?.length) && (
            <Box
              display="div"
              className={classes.body}>
              <Grid
                container
                className={classes.mainGrid}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}>
                  <ResponsiveGridLayout {...GRIDCONFIG}>
                    {widgets.map((widget, index: number) => {
                      if (!widget || !widget?.props) return null;
                      const widgetIndex =
                        widget.props?.widgetId ?? `body_wiget_${index}`;
                      const dataGrid = getGridDataForWidget(
                        widget,
                        widgets?.length
                      );

                      return (
                        <div
                          className={
                            widget?.type === "separator"
                              ? "separator-widget"
                              : "widget"
                          }
                          key={widgetIndex}
                          data-grid={dataGrid}
                          data-widgettype={widget.type}>
                          <Widget
                            widget={widget}
                            widgets={widgets}
                            setWidgetToConfigure={handleOpenConfigurationModal}
                            setWidgets={setWidgets}></Widget>
                        </div>
                      );
                    })}
                  </ResponsiveGridLayout>
                </Grid>
              </Grid>
            </Box>
          )}

          {isConfigurationModalOpen && (
            <ConfigurationModal
              widgetToConfigure={widgetToConfigure}
              handleOnSave={save}></ConfigurationModal>
          )}

          <WidgetsModalComponent setWidgets={setWidgets} />

          <AlertModalComponent />

          <MenuAction
            classes={classes}
            onSave={handleOnSave}
            pagesLength={pagesLength}></MenuAction>

          <PageDetailsModalComponent />
        </>
      )}
    </Box>
  );
};

export const OverviewComponent = Overview;

const OverviewError = ({ error }) => {
  return (
    <Alert severity="error">
      <AlertTitle>Error{error.message ? `: ${error.message}` : ""}</AlertTitle>
      <div style={{ whiteSpace: "pre-line" }}>{error.componentStack}</div>
    </Alert>
  );
};
