import { useState } from "react";
import { makeStyles } from "tss-react/mui";
import { WidgetSettings } from "../widgets-config";
import { TextField } from "@mui/material";
import FormAction from "src/shared/components/form-action/form-action";

const TextWidgetSettingsComponent = (props: WidgetSettings) => {
  const { currentProps, onSave } = props;
  const { classes } = useStyles();
  const [text, setText] = useState<string>(currentProps.title ?? "");

  const handleOnSave = () => {
    onSave({ ...currentProps, title: text });
  };
  return (
    <div
      data-testid="text-widget-settings"
      className={classes.root}>
      <TextField
        label="Text to display"
        value={text}
        onChange={({ target: { value } }) => {
          setText(value);
        }}
      />
      <FormAction
        requiresConfig={currentProps.requiresConfig}
        onSave={handleOnSave}
        saveDisabled={false}></FormAction>
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: { padding: "10px" },
    actions: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "1em",
      gap: "1em"
    }
  };
});

export default TextWidgetSettingsComponent;
