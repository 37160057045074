import { useEffect, useState } from "react";
import moment from "moment";
import SidebarComponent from "./components/sidebar/sidebar.component";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import CustomSwitchComponent from "src/shared/components/custom-switch/custom-switch.component";
import PollutantCardComponent from "./components/pollutant-card/pollutant-card.component";
import { makeStyles } from "tss-react/mui";

import {
  Button,
  Card,
  Divider,
  Drawer,
  FormControlLabel,
  Grid,
  Typography
} from "@mui/material";
import { useGetUserSettings } from "src/sections/settings/hooks";
import useGetAppBreakPoints from "src/hooks/useGetAppBreakPoints";
import { useAppSelector } from "src/hooks/useAppSelector";
import { getTradefairSelectedPollutant } from "src/store/buildings/selector";
import LoaderComponent from "src/shared/components/loader/loader.component";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { setTradefairSelectedDeviceId } from "src/store/buildings/reducer";
import useGetTradefairInfo from "./hooks/useGetTradefairInfo";
import useGetSelectedDevice from "./hooks/useGetSelectedDevice";
import { useNavigate, useParams } from "react-router-dom";

export const TradefairDashboard = () => {
  const { buildingId } = useParams();
  let pollutant = { label: "Air Quality Index", key: "total_aqi" };
  let devices = [];
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const { default_webportal_view } = useGetUserSettings();
  const navigate = useNavigate();
  const mainView =
    default_webportal_view === "hvac" ? default_webportal_view : "indoor";
  const { isSm, isMd } = useGetAppBreakPoints();
  const displayOutdoor = true;
  const [drawerOpen, setDrawerOpen] = useState(false);
  let isTv = false;

  const { mainData, isLoading, isError } = useGetTradefairInfo();
  const { selectedDevice } = useGetSelectedDevice();
  const selectedPollutant = useAppSelector(getTradefairSelectedPollutant);

  const [time, setTime] = useState(moment().format("MMM D, YYYY, hh:mm A"));

  useEffect(() => {
    let interval = setInterval(() => {
      setTime(moment().format("MMM D, YYYY, hh:mm A"));
    }, 1000 * 1);
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (mainData) {
    // @ts-ignore
    pollutant = mainData.pollutant_list.find(
      poll => poll.key === selectedPollutant
    );

    for (let key in mainData.aq_devices) {
      let device = { ...mainData.aq_devices[key] };
      devices.push({
        ...device,
        id: key,
        aqData: mainData.aq_data[key]
      });
    }

    if (displayOutdoor) {
      devices = [
        { device_name: "Outdoor", aqData: mainData.outdoor_data },
        ...devices
      ];
    }
    // @ts-ignore
    isTv = Boolean(mainData.vertical_tv_resolution);
  }

  devices = devices.sort((_, next) => {
    if (!next.aqData) {
      return -1;
    }
    return 0;
  });

  let displayNoData =
    (displayOutdoor && devices.length === 1) || devices.length === 0;

  return isLoading ? (
    <LoaderComponent />
  ) : isError ? (
    <p style={{ textAlign: "center" }}>
      We couldn't load data. Please try again later.
    </p>
  ) : (
    <div
      className={classes.root}
      style={{ zoom: isTv ? 2 : 1 }}
      data-testid="tradefair-dashboard">
      <div className={classes.header}>
        <div>
          <img
            onClick={() => {
              navigate(`/buildings/${buildingId}/${mainView}`);
            }}
            src={mainData.logo_link}
            className={classes.mhLogo}
          />
        </div>
        {!isMd && !isTv && (
          <div>
            <Typography variant="h6">
              {mainData.building_name}, {mainData.building_address}
            </Typography>
          </div>
        )}
        <div>
          <Typography
            variant="h6"
            className={classes.time}>
            {time}
          </Typography>
        </div>
      </div>
      {displayNoData && (
        <div className={classes.noData}>
          <Typography variant="h6">No data to display</Typography>
        </div>
      )}
      {!displayNoData && !isTv && isMd && (
        <div>
          <Typography
            variant="subtitle1"
            className={classes.address}>
            {mainData.building_name}, {mainData.building_address}
          </Typography>
        </div>
      )}
      {!displayNoData && isTv && (
        <div className={classes.info}>
          <div>
            <Typography variant="h6">
              {mainData.building_name}, {mainData.building_address}
            </Typography>
          </div>
        </div>
      )}
      {!displayNoData && isSm && (
        <>
          <div
            className={classes.drawerBtn}
            onClick={() => setDrawerOpen(true)}>
            <DoubleArrowIcon style={{ fontSize: "15px" }} />
          </div>
          <Drawer
            anchor="left"
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}>
            <SidebarComponent drawerOpen={drawerOpen} />
          </Drawer>
        </>
      )}
      {!displayNoData && (
        <Grid
          container
          className={classes.content}
          spacing={[0, 2]}>
          {!isTv && !isSm && (
            <Grid
              item
              sm={drawerOpen ? 4 : 0.1}
              md={drawerOpen ? 4 : 0.1}
              lg={drawerOpen ? 3 : 0.1}
              xl={drawerOpen ? 2 : 0.1}
              className={classes.sidebar}>
              <SidebarComponent drawerOpen={drawerOpen} />
              <div
                className={classes.drawerBtn}
                style={{ top: 150 }}
                onClick={() => setDrawerOpen(!drawerOpen)}>
                <DoubleArrowIcon
                  style={{
                    fontSize: "15px",
                    transform: drawerOpen ? "rotate(180deg)" : "none"
                  }}
                />
              </div>
            </Grid>
          )}
          {!selectedDevice && (
            <Grid
              item
              xs={12}
              sm={isTv ? 12 : drawerOpen ? 8 : 11.8}
              md={isTv ? 12 : drawerOpen ? 8 : 11.8}
              lg={isTv ? 12 : drawerOpen ? 9 : 11.8}
              xl={isTv ? 12 : drawerOpen ? 10 : 11.8}
              sx={{ overflow: isTv ? "hidden" : "auto" }}>
              <div className={classes.pollutantInfo}>
                <div className={classes.info}>
                  <Typography
                    variant="h6"
                    className={classes.subtitle}>
                    {pollutant?.label}
                  </Typography>
                  {!isTv && (
                    <FormControlLabel
                      control={<CustomSwitchComponent />}
                      label={`${displayOutdoor ? "Hide" : "Show"} Outdoor`}
                    />
                  )}
                </div>
                <Divider sx={{ marginBottom: 2 }} />
                <Grid
                  container
                  spacing={[1, 4]}
                  sx={{ paddingBottom: "24px" }}
                  className={classes.devices}>
                  {devices.map((item, index) => {
                    let isOutdoor: boolean = item.device_name === "Outdoor";
                    let isTvOutdoor: boolean = isOutdoor && isTv;

                    if (isTv && index > 2) {
                      return false;
                    }

                    return (
                      <Grid
                        xs={12}
                        sm={12}
                        md={isTvOutdoor ? 12 : 6}
                        lg={isTvOutdoor ? 12 : isTv ? 6 : 4}
                        xl={isTvOutdoor ? 12 : isTv ? 6 : 3}
                        item
                        key={`card_${index}`}>
                        <Typography
                          variant="subtitle1"
                          className={classes.deviceName}
                          gutterBottom>
                          {item.device_name}{" "}
                          {item.room ? `(${item.room})` : null}
                        </Typography>
                        <PollutantCardComponent
                          pollutant={item.device_name}
                          aqData={item.aqData}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            </Grid>
          )}
          {selectedDevice && (
            <Grid
              item
              xs={12}
              sm={isTv ? 12 : drawerOpen ? 8 : 11.8}
              md={isTv ? 12 : drawerOpen ? 8 : 11.8}
              lg={isTv ? 12 : drawerOpen ? 9 : 11.8}
              xl={isTv ? 12 : drawerOpen ? 10 : 11.8}
              sx={{ overflow: isTv ? "hidden" : "auto" }}>
              <div className={classes.pollutantInfo}>
                <div className={classes.info}>
                  <Typography
                    variant="h6"
                    className={classes.subtitle}>
                    {selectedDevice.device_name} ({selectedDevice.room})
                  </Typography>
                  <Button
                    variant="text"
                    onClick={() =>
                      dispatch(setTradefairSelectedDeviceId(null))
                    }>
                    Show all devices
                  </Button>
                </div>
                <Divider sx={{ marginBottom: 2 }} />
                <Grid
                  container
                  spacing={[1, 4]}
                  sx={{ paddingBottom: "24px" }}
                  className={classes.devices}>
                  {selectedDevice.aqData &&
                    selectedDevice.aqData.pollutants.map(pollutant => {
                      let pollutantDeff = mainData.pollutant_list.find(
                        item => item.key === pollutant.key
                      );
                      if (pollutantDeff) {
                        pollutant.short_label = pollutantDeff.short_label;
                      }
                      return (
                        <Grid
                          xs={12}
                          sm={6}
                          md={6}
                          lg={isTv ? 6 : 4}
                          xl={isTv ? 6 : 3}
                          item
                          key={`card_poll_${pollutant.key}`}>
                          <Card className={classes.pollutantCard}>
                            <div
                              className={classes.pcHeader}
                              style={{
                                backgroundColor:
                                  mainData.color_dict[pollutant.pollutant_class]
                              }}>
                              {pollutant.pollutant_class}
                            </div>
                            <div className={classes.data}>
                              <Typography
                                className={classes.pcTitle}
                                variant="h5">
                                {pollutant.label}{" "}
                                {pollutant.short_label &&
                                  `(${pollutant.short_label})`}
                              </Typography>
                              <Typography className={classes.pcText}>
                                {`${pollutant.value} ${pollutant.units}`}
                              </Typography>
                            </div>
                          </Card>
                        </Grid>
                      );
                    })}
                </Grid>
              </div>
            </Grid>
          )}
        </Grid>
      )}
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {
      backgroundColor: "rgb(247,250,250)",
      width: "100%",
      height: "100%",
      padding: 24,
      display: "flex",
      flexDirection: "column" as const,
      overflow: "auto"
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 16
    },
    content: {
      flex: 1
    },
    sidebar: {
      display: "flex",
      flexDirection: "column" as const
    },
    logo: {
      height: 40,
      "@media (max-width: 600px)": {
        height: 24
      }
    },
    mhLogo: {
      height: 40,
      cursor: "pointer",
      marginRight: "2em",
      "@media (max-width: 600px)": {
        height: 24
      }
    },
    time: {
      color: "#66797C",
      fontSize: "16px"
    },
    subtitle: {
      fontSize: "18px"
    },
    purifiersList: {
      display: "flex",
      overflow: "auto",
      paddingBottom: 26,
      paddingTop: 16,
      paddingRight: 5,
      paddingLeft: 5
    },
    info: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "0.25em"
    },
    drawerBtn: {
      position: "fixed" as const,
      top: 20,
      left: 0,
      width: 20,
      height: 30,
      backgroundColor: "#0895BA",
      color: "white",
      borderRadius: "0 4px 4px 0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "12px",
      cursor: "pointer"
    },
    address: {
      position: "relative" as const,
      top: -20,
      color: "black",
      "@media (max-width: 900px)": {
        top: 0
      },
      "@media (max-width: 600px)": {
        top: -10
      }
    },
    pollutantInfo: {
      marginTop: 16
    },
    qrCode: {
      width: 60
    },
    purifierTv: {
      minWidth: 0
    },
    devices: {
      "@media (max-width: 600px)": {
        position: "absolute" as const,
        width: "calc(100% - 40px)"
      }
    },
    deviceName: {
      color: "#66797C",
      fontWeight: 500,
      textOverflow: "ellipsis" as const,
      whiteSpace: "nowrap" as const,
      overflow: "hidden"
    },
    pollutantCard: {
      borderRadius: 20,
      boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.15);",
      "@media (max-width: 600px)": {
        borderRadius: 8
      }
    },
    pcHeader: {
      height: 125,
      color: "white",
      fontSize: "28px",
      backgroundColor: "gray",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      fontWeight: 500,
      "@media (max-width: 600px)": {
        height: 60,
        fontSize: "22px"
      }
    },
    data: {
      padding: "8px 16px",
      textAlign: "center"
    },
    pcTitle: {
      fontSize: "14pt",
      lineHeight: 2
    },
    pcText: {
      lineHeight: 2
    },
    noData: {
      display: "flex",
      justifyContent: "center"
    }
  };
});

export const TradefairDashboardComponent = TradefairDashboard;
