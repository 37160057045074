/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AlertDeviceType } from './AlertDeviceType';
import type { AlertKey } from './AlertKey';
/**
 * Fields on the Notification Model that should be updatable.
 *
 * Note that `entity_type` defines the scope of devices that should trigger alerts.
 * `EntityType.building` means any device matching the notification condition triggers an alert,
 * while `EntityType.device` only watchs a single specified device.
 */
export type NotificationDefinitionUpdates = {
  title: string;
  company_id: string;
  building_id: string;
  alert_device_type: AlertDeviceType;
  alert_key: AlertKey;
  threshold: number;
  threshold_direction?: NotificationDefinitionUpdates.threshold_direction;
  time_required?: number;
  device_id: string;
  is_active?: boolean;
  text_message_enabled?: boolean;
  email_enabled?: boolean;
  in_alert?: boolean;
  last_alert_id?: (string | null);
  last_alert_created_at?: (string | null);
  last_alert_value?: (number | null);
  last_alert_value_time?: (string | null);
};
export namespace NotificationDefinitionUpdates {
  export enum threshold_direction {
    GT = 'gt',
    LT = 'lt',
    EQ = 'eq',
  }
}

