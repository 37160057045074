import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type InitialState = {
  selectedOverviewTab: number | null;
};

const initialState: InitialState = {
  selectedOverviewTab: null
};

export const overviewSlice = createSlice({
  name: "overview",
  initialState: { ...initialState },
  reducers: {
    setSelectedOverviewTab: (state, action: PayloadAction<number>) => {
      state.selectedOverviewTab = action.payload;
    }
  }
});

export const { setSelectedOverviewTab } = overviewSlice.actions;

export const { reducer: overviewReducer } = overviewSlice;
