import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  KeyboardEvent,
  MouseEvent
} from "react";
import toast from "react-hot-toast";
import config from "src/utils/config";
import DrawerComponent from "src/shared/components/drawer/drawer.component";
import { makeStyles } from "tss-react/mui";
import { Field, Form } from "react-final-form";
import { useDispatch } from "react-redux";

import {
  Box,
  Button,
  Divider,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment
} from "@mui/material";

import {
  useGetEnergyCompaniesQuery,
  useGetEnergySourcesQuery,
  useGetFilterTypesQuery,
  useLazyCacluateTCOQuery
} from "src/store/services/building-cost/building-cost.service";
import { setCOOData, setShowTCOCurve } from "src/store/building-cost/reducer";

export type ChangeIntervalType = {
  change_interval: string;
  filter_cost: string;
  energy_cost: string;
  labor_cost: string;
};

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  asPascals?: boolean;
  initialValues?: any;
  ahuId?: string;
  ahu?: any;
  stage?: any;
  chartData?: any;
  showTCOCurve?: any;
  buildingId?: string;
};

const drawerType = "right";

const CostOfOwnershipDrawerComponent = (props: Props) => {
  const {
    open,
    setOpen,
    asPascals,
    initialValues,
    ahuId,
    ahu,
    stage,
    chartData,
    showTCOCurve,
    buildingId
  } = props;

  const dispatch = useDispatch();
  const { classes } = useStyles();
  const ahuTCODataError = false;
  const [calculateTCO] = useLazyCacluateTCOQuery();
  const { data: tcoFilterTypes } = useGetFilterTypesQuery();

  const { data: buildingsEnergySources } = useGetEnergySourcesQuery();

  const { data: buildingsEnergyCompanies } = useGetEnergyCompaniesQuery();
  const [formValues, setFormValues] = useState<any>(initialValues?.data);
  const [hasValidationErrorsValue, setHasValidationErrorsValue] =
    useState<any>(false);
  let pressureUnit = asPascals ? "Pa" : "iwg";
  let currencyUnit = config.currency;

  const energyCompaniesKeys =
    buildingsEnergyCompanies && Object.values(buildingsEnergyCompanies);
  const energyCompaniesValues =
    buildingsEnergyCompanies && Object.keys(buildingsEnergyCompanies);
  const energyCompanies = energyCompaniesKeys?.map((value, index) => {
    return { key: energyCompaniesValues[index], value };
  });
  const energySourcesKeys =
    buildingsEnergySources && Object.values(buildingsEnergySources);
  const energySourcesValues =
    buildingsEnergySources && Object.keys(buildingsEnergySources);
  const energySources = energySourcesKeys?.map((value, index) => {
    return { key: energySourcesValues[index], value };
  });

  useEffect(() => {
    if (ahuTCODataError) {
      setOpen(true);
      toast.error("Could Not Calculate Cost, Please Try Different Inputs");
    }
  }, [ahuTCODataError]);

  const toggleDrawer =
    (open: boolean) => (event: KeyboardEvent | MouseEvent) => {
      if (
        event?.type === "keydown" &&
        ((event as KeyboardEvent).key === "Tab" ||
          (event as KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpen(open);
    };

  const submit = values => {
    setFormValues(values);

    const body = {
      ...values,
      maximum_pressure_value: asPascals
        ? parseInt(values?.maximum_pressure_value) * 0.004
        : values?.maximum_pressure_value,
      pressure_start: asPascals
        ? parseInt(values?.pressure_start) * 0.004
        : values?.pressure_start,
      pressure_end: asPascals
        ? parseInt(values?.pressure_end) * 0.004
        : values?.pressure_end
    };

    calculateTCO({ ahuId, stageId: stage?.id, buildingId, body }, true);
    dispatch(setShowTCOCurve({ ahuId, stageId: stage.id, buildingId }));

    setOpen(false);

    dispatch(setCOOData({ ahuId, stageId: stage?.id, buildingId, data: body }));
  };

  const validate = values => {
    const requiredErrorLabels = {
      labor_cost: "Labor cost is required",
      labor_time: "Labor time is required",
      filters_per_array: "Number of Filters per Stage is required",
      filter_cost: "Filter Cost per Filter is required",
      flow_rate: "Total Unit Airflow Rate is required",
      energy_cost: "Energy cost is required",
      cotwo_per_kilowatt_output: "Tones of CO2 is required",
      maximum_pressure_value: "Max allowable filter is required",
      filter_type: "Filter Type is required",
      change_interval: "Current change interval is required",
      pressure_start: "Initial Filter Pressure is required",
      pressure_end: "Final Filter Pressure is required",
      buildings_energy_source: "Buildings energy source is required",
      buildings_energy_company: "Buildings energy company is required"
    };

    let errors: any = {};

    const isCotwoValid =
      values.cotwo_per_kilowatt_output ||
      values.buildings_energy_source ||
      values.buildings_energy_company;

    Object.keys(requiredErrorLabels).forEach(key => {
      if (!values[key]) {
        errors[key] = requiredErrorLabels[key];
      }
      if (!isCotwoValid) {
        errors.cotwo_per_kilowatt_output =
          requiredErrorLabels.cotwo_per_kilowatt_output;
        errors.buildings_energy_source =
          requiredErrorLabels.buildings_energy_source;
        errors.buildings_energy_company =
          requiredErrorLabels.buildings_energy_company;
      } else {
        delete errors.cotwo_per_kilowatt_output;
        delete errors.buildings_energy_source;
        delete errors.buildings_energy_company;
      }
    });

    return errors;
  };

  const AutoFillInitialValuesFromDevices = (defaultValues = undefined) => {
    if (
      ahu &&
      stage &&
      chartData &&
      chartData[0] &&
      chartData[0].data.length > 0
    ) {
      const isFirstFill = defaultValues?.stageId !== stage?.id;
      let maxPressure,
        minPressure,
        filterType,
        changeInterval,
        filtersPerArray,
        nominalAirFlow;
      if (isFirstFill) {
        maxPressure = Math.max(...chartData[0]?.data?.map(item => item.value));
        minPressure = Math.min(...chartData[0]?.data?.map(item => item.value));
        filterType = tcoFilterTypes[stage?.stage_type];
        changeInterval = parseInt(stage?.data?.remaining_useful_life) / 4;
        filtersPerArray = stage?.nr_of_filters;
        nominalAirFlow = ahu?.nominal_air_flow;
      } else {
        maxPressure = defaultValues?.data?.pressure_end;
        minPressure = defaultValues?.data?.pressure_start;
        filterType = defaultValues?.data?.filter_type;
        changeInterval = defaultValues?.data?.change_interval;
        filtersPerArray = defaultValues?.data?.filters_per_array;
        nominalAirFlow = defaultValues?.data?.flow_rate;
      }

      const autoFillValues = {
        ...(minPressure && {
          pressure_start: minPressure
        }),
        ...(maxPressure && {
          pressure_end: maxPressure
        }),
        ...(filterType && {
          filter_type: filterType
        }),
        ...(changeInterval && {
          change_interval: changeInterval
        }),
        ...(filtersPerArray && {
          filters_per_array: filtersPerArray
        }),
        ...(defaultValues?.data?.maximum_pressure_value && {
          maximum_pressure_value: asPascals
            ? Math.round(defaultValues?.data?.maximum_pressure_value * 249)
            : defaultValues?.data?.maximum_pressure_value
        }),
        ...(nominalAirFlow && {
          flow_rate: nominalAirFlow
        })
      };
      return { ...defaultValues?.data, ...autoFillValues };
    }
  };

  useEffect(() => {
    const existAhuOrStage = ahu || stage;
    const isSameBuilding = buildingId === initialValues?.buildingId;
    const isSameAhuAndStage =
      ahuId === initialValues?.ahuId && stage?.id === initialValues?.stageId;

    if (!existAhuOrStage) {
      setFormValues({});
    } else if (isSameBuilding) {
      const autoFill = AutoFillInitialValuesFromDevices(initialValues);
      setFormValues(autoFill);
      if (
        autoFill &&
        showTCOCurve?.stageId &&
        showTCOCurve?.stageId !== stage?.id
      ) {
        const body = {
          ...autoFill,
          maximum_pressure_value: asPascals
            ? parseInt(autoFill?.maximum_pressure_value) * 0.004
            : autoFill?.maximum_pressure_value,
          pressure_start: asPascals
            ? parseInt(autoFill?.pressure_start) * 0.004
            : autoFill?.pressure_start,
          pressure_end: asPascals
            ? parseInt(autoFill?.pressure_end) * 0.004
            : autoFill?.pressure_end
        };

        calculateTCO({ ahuId, stageId: stage.id, buildingId, body }, true);
        dispatch(setShowTCOCurve({ ahuId, stageId: stage.id, buildingId }));
      }
    } else if (!isSameBuilding && !isSameAhuAndStage) {
      dispatch(setCOOData({ ahuId, stageId: stage?.id, buildingId, data: {} }));
      const autoFill = AutoFillInitialValuesFromDevices();
      setFormValues(autoFill);
    }
  }, [ahuId, ahu, stage, chartData]);

  const bottomSection = () => {
    return (
      <div className={classes.actions}>
        <Button
          type="button"
          variant="text"
          color="inherit"
          onClick={toggleDrawer(false)}>
          Cancel
        </Button>
        <Button
          variant="contained"
          type="submit"
          disabled={hasValidationErrorsValue}
          form="cot-form">
          Calculate
        </Button>
      </div>
    );
  };

  return (
    <DrawerComponent
      anchor={drawerType}
      open={open}
      onClose={toggleDrawer(false)}
      title="Total Cost of Ownership"
      bottomSection={bottomSection}>
      <Box
        sx={{ width: 300 }}
        role="presentation">
        <Form
          initialValues={{
            filter_type:
              formValues?.filter_type || (tcoFilterTypes && tcoFilterTypes[0]),
            filters_per_array: formValues?.filters_per_array,
            flow_rate: formValues?.flow_rate,
            change_interval: formValues?.change_interval,
            maximum_pressure_value: formValues?.maximum_pressure_value,
            labor_cost: formValues?.labor_cost,
            labor_time: formValues?.labor_time,
            energy_cost: formValues?.energy_cost,
            filter_cost: formValues?.filter_cost,
            cotwo_per_kilowatt_output: formValues?.cotwo_per_kilowatt_output,
            buildings_energy_source: formValues?.buildings_energy_source,
            buildings_energy_company: formValues?.buildings_energy_company,
            pressure_start: formValues?.pressure_start,
            pressure_end: formValues?.pressure_end
          }}
          mutators={{
            setCustomValue: ([field, value], state, utils) => {
              utils.changeValue(state, field, () => value);
            }
          }}
          onSubmit={submit}
          validate={validate}
          render={({ handleSubmit, hasValidationErrors, form }) => {
            setHasValidationErrorsValue(hasValidationErrors);
            return (
              <form
                onSubmit={handleSubmit}
                data-testid="cot-form"
                id="cot-form">
                <div className={classes.formSection}>
                  <div>
                    <Field name="filter_type">
                      {({ input, meta }) => (
                        <FormControl fullWidth>
                          <InputLabel>Filter Type</InputLabel>
                          <Select
                            label="Filter Type"
                            className={classes.textInput}
                            error={Boolean(meta.touched && meta.error)}
                            inputProps={input}>
                            {tcoFilterTypes?.map(
                              (filterType: string, index) => (
                                <MenuItem
                                  key={`filterType-${index}`}
                                  value={filterType}>
                                  {filterType}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                  </div>
                  <div>
                    <Field name="filters_per_array">
                      {({ input, meta }) => (
                        <TextField
                          placeholder={null}
                          label="Number of Filters per Stage (count)"
                          className={classes.textInput}
                          type="number"
                          error={Boolean(meta.touched) && Boolean(meta.error)}
                          helperText={meta.touched && meta.error}
                          InputProps={input}></TextField>
                      )}
                    </Field>
                  </div>
                  <div>
                    <Field name="flow_rate">
                      {({ input, meta }) => (
                        <TextField
                          placeholder={null}
                          label="Total Unit Airflow Rate (cfm)"
                          className={classes.textInput}
                          type="number"
                          error={Boolean(meta.touched) && Boolean(meta.error)}
                          helperText={meta.touched && meta.error}
                          InputProps={input}></TextField>
                      )}
                    </Field>
                  </div>
                  <div>
                    <Field name="maximum_pressure_value">
                      {({ input, meta }) => (
                        <TextField
                          placeholder={null}
                          label={`Max Allowable Filter Pressure (${pressureUnit})`}
                          className={classes.textInput}
                          type="number"
                          error={Boolean(meta.touched) && Boolean(meta.error)}
                          helperText={meta.touched && meta.error}
                          InputProps={input}></TextField>
                      )}
                    </Field>
                  </div>
                  <div>
                    <Field name="labor_cost">
                      {({ input, meta }) => (
                        <TextField
                          placeholder={null}
                          label="Labor Cost (dollars per hour)"
                          className={classes.textInput}
                          type="number"
                          error={Boolean(meta.touched) && Boolean(meta.error)}
                          helperText={meta.touched && meta.error}
                          InputProps={{
                            ...input,
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                {currencyUnit}
                              </InputAdornment>
                            )
                          }}></TextField>
                      )}
                    </Field>
                  </div>
                  <div>
                    <Field name="labor_time">
                      {({ input, meta }) => (
                        <TextField
                          placeholder={null}
                          label="Labor Time (total man-hours)"
                          className={classes.textInput}
                          type="number"
                          error={Boolean(meta.touched) && Boolean(meta.error)}
                          helperText={meta.touched && meta.error}
                          InputProps={input}></TextField>
                      )}
                    </Field>
                  </div>
                  <div>
                    <Field name="energy_cost">
                      {({ input, meta }) => (
                        <TextField
                          placeholder={null}
                          label="Energy Cost (dollars per kilowatt hour)"
                          className={classes.textInput}
                          type="number"
                          error={Boolean(meta.touched) && Boolean(meta.error)}
                          helperText={meta.touched && meta.error}
                          InputProps={{
                            ...input,
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                {currencyUnit}
                              </InputAdornment>
                            )
                          }}></TextField>
                      )}
                    </Field>
                  </div>
                  <div>
                    <Field name="filter_cost">
                      {({ input, meta }) => (
                        <TextField
                          placeholder={null}
                          label="Filter Cost per Filter (dollars)"
                          className={classes.textInput}
                          type="number"
                          error={Boolean(meta.touched) && Boolean(meta.error)}
                          helperText={meta.touched && meta.error}
                          InputProps={{
                            ...input,
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                {currencyUnit}
                              </InputAdornment>
                            )
                          }}></TextField>
                      )}
                    </Field>
                  </div>
                </div>
                <div className={classes.formSection}>
                  <Typography
                    variant="caption"
                    gutterBottom>
                    Kg of CO2 Generated Per Kilowatt Hour
                  </Typography>
                  <div>
                    <Field name="buildings_energy_company">
                      {({ input, meta }) => (
                        <FormControl fullWidth>
                          <InputLabel>Building's Energy Company</InputLabel>
                          <Select
                            label="Building's Energy Company"
                            className={classes.textInput}
                            error={Boolean(meta.touched && meta.error)}
                            inputProps={input}
                            onChange={event => {
                              const energyCompany = event.target.value;
                              form.mutators.setCustomValue(
                                "cotwo_per_kilowatt_output",
                                energyCompanies.find(
                                  company => company.key === energyCompany
                                ).value
                              );
                              form.mutators.setCustomValue(
                                "buildings_energy_company",
                                energyCompany
                              );
                              form.mutators.setCustomValue(
                                "buildings_energy_source",
                                undefined
                              );
                            }}>
                            {energyCompanies?.map((company, index) => (
                              <MenuItem
                                key={`company-${index}`}
                                value={company.key}>
                                {company.key}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                  </div>
                  <Divider
                    orientation="horizontal"
                    className={classes.divider}
                    flexItem>
                    OR
                  </Divider>
                  <div>
                    <Field name="buildings_energy_source">
                      {({ input, meta }) => (
                        <FormControl fullWidth>
                          <InputLabel>Building's Energy Source</InputLabel>
                          <Select
                            label="Building's Energy Source"
                            className={classes.textInput}
                            error={Boolean(meta.touched && meta.error)}
                            inputProps={input}
                            onChange={event => {
                              const energySource = event.target.value;
                              form.mutators.setCustomValue(
                                "cotwo_per_kilowatt_output",
                                energySources.find(
                                  source => source.key === energySource
                                ).value
                              );
                              form.mutators.setCustomValue(
                                "buildings_energy_source",
                                energySource
                              );
                              form.mutators.setCustomValue(
                                "buildings_energy_company",
                                undefined
                              );
                            }}>
                            {energySources?.map((source, index) => (
                              <MenuItem
                                key={`source-${index}`}
                                value={source.key}>
                                {source.key}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                  </div>

                  <Divider
                    orientation="horizontal"
                    className={classes.divider}
                    flexItem>
                    OR
                  </Divider>
                  <div>
                    <Field name="cotwo_per_kilowatt_output">
                      {({ input, meta }) => (
                        <TextField
                          placeholder={null}
                          label="Kg of CO2"
                          className={classes.textInput}
                          type="number"
                          error={Boolean(meta.touched) && Boolean(meta.error)}
                          helperText={meta.touched && meta.error}
                          InputProps={input}></TextField>
                      )}
                    </Field>
                  </div>
                </div>
                <div className={classes.formSection}>
                  <Typography variant="caption">
                    Rate of Filter Pressure Increase
                  </Typography>
                  <div>
                    <Field name="pressure_start">
                      {({ input, meta }) => (
                        <TextField
                          placeholder={null}
                          label={`Initial Filter Pressure (${pressureUnit})`}
                          className={classes.textInput}
                          type="number"
                          error={Boolean(meta.touched) && Boolean(meta.error)}
                          helperText={meta.touched && meta.error}
                          InputProps={input}></TextField>
                      )}
                    </Field>
                  </div>
                  <div>
                    <Field name="pressure_end">
                      {({ input, meta }) => (
                        <TextField
                          placeholder={null}
                          label={`Final Filter Pressure (${pressureUnit})`}
                          className={classes.textInput}
                          type="number"
                          error={Boolean(meta.touched) && Boolean(meta.error)}
                          helperText={meta.touched && meta.error}
                          InputProps={input}></TextField>
                      )}
                    </Field>
                  </div>
                  <div>
                    <Field name="change_interval">
                      {({ input, meta }) => (
                        <TextField
                          placeholder={null}
                          label="Current Change Interval (Months)"
                          className={classes.textInput}
                          type="number"
                          error={Boolean(meta.touched) && Boolean(meta.error)}
                          helperText={meta.touched && meta.error}
                          InputProps={input}></TextField>
                      )}
                    </Field>
                  </div>
                </div>
              </form>
            );
          }}
        />
      </Box>
    </DrawerComponent>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    actions: {
      marginTop: "1em",
      display: "flex",
      width: "100%",
      justifyContent: "space-around"
    },
    bar: {
      position: "relative" as const
    },
    formSection: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      backgroundColor: "#F3F6FF",
      borderRadius: 8,
      padding: 8,
      textAlign: "center",
      marginBottom: 8,
      "&:nth-of-type(2)": {
        marginBottom: "2em"
      }
    },
    textInput: {
      width: "100%",
      marginBottom: "0.5em",
      backgroundColor: "#fff"
    },
    divider: {
      marginBottom: 8
    }
  };
});

export default CostOfOwnershipDrawerComponent;
