import {
  AggGranularity,
  AggItem,
  PaintBooth,
  PaintBoothAndZones,
  PaintBoothData,
  PaintBoothDataService,
  PaintBoothSensor,
  PaintBoothService,
  PaintBoothSetPoint,
  PaintBoothSetPointNew,
  PaintBoothSetPointReturn,
  PaintBoothSetPointsService
} from "src/utils/client";
import { apiService } from "../api";

type BoothRequest = {
  buildingId: string;
  boothId: string;
  created_at: string;
  startTs: string;
};

export const boothService = apiService
  .enhanceEndpoints({
    addTagTypes: [
      "PAINTBOOTH",
      "BOOTHSWITHZONES",
      "SETPOINTS",
      "BOOTH",
      "ZONESENSOR",
      "SETPOINTRANGE",
      "USERSETPOINT",
      "BOOTHSENSOR",
      "SENSORDATA",
      "SENSORWIDGETDATA"
    ]
  })
  .injectEndpoints({
    endpoints: build => ({
      getPaintBoothsForBuilding: build.query<PaintBooth[], string>({
        queryFn: async buildingId => {
          const booths = await PaintBoothService.getPaintBoothsForBuilding(
            buildingId
          );

          return { data: booths };
        },
        providesTags: (_result, _error, buildingId) => [
          { type: "PAINTBOOTH", buildingId }
        ]
      }),
      getPaintBoothsWithZones: build.query<
        Record<string, PaintBoothAndZones>,
        string
      >({
        queryFn: async buildingId => {
          const boothWithZones =
            await PaintBoothService.getPaintBoothsWithZones(buildingId);
          return { data: boothWithZones };
        },
        providesTags: (_result, _error, buildingId) => [
          { type: "BOOTHSWITHZONES", buildingId }
        ]
      }),
      getPaintBoothSetPoints: build.query<
        PaintBoothSetPointReturn[],
        Omit<BoothRequest, "created_at">
      >({
        queryFn: async args => {
          const { buildingId, boothId, startTs } = args;
          const points = await PaintBoothSetPointsService.getSetPointRange(
            buildingId,
            boothId,
            startTs
          );
          return { data: points };
        },
        providesTags: (_result, _error, { boothId }) => [
          { type: "SETPOINTS", boothId }
        ]
      }),
      getPaintBooth: build.query<
        PaintBooth,
        Omit<BoothRequest, "created_at" | "startTs">
      >({
        queryFn: async args => {
          const { buildingId, boothId } = args;
          const booth = await PaintBoothService.getPaintBooth(
            buildingId,
            boothId
          );
          return { data: booth };
        },
        providesTags: (_result, _error, { boothId }) => [
          { type: "BOOTH", boothId }
        ]
      }),
      getSensorDataForPaintBoothZone: build.query<
        PaintBoothData,
        Omit<BoothRequest, "created_at" | "startTs"> & { zoneId: string }
      >({
        queryFn: async args => {
          const { buildingId, boothId, zoneId } = args;
          const booth =
            await PaintBoothDataService.getLatestPaintBoothSensorDataPointForZone(
              buildingId,
              boothId,
              zoneId
            );
          return { data: booth };
        },
        providesTags: (_result, _error, { boothId }) => [
          { type: "BOOTH", boothId }
        ]
      }),
      getPaintBoothSetPointRange: build.query<
        PaintBoothSetPointReturn[],
        Omit<BoothRequest, "created_at">
      >({
        queryFn: async args => {
          const { buildingId, boothId, startTs } = args;
          const paintBoothRanges =
            await PaintBoothSetPointsService.getSetPointRange(
              buildingId,
              boothId,
              startTs
            );
          return { data: paintBoothRanges };
        },
        providesTags: (_result, _error, { boothId }) => [
          { type: "SETPOINTRANGE", boothId }
        ]
      }),
      getUserSetPoint: build.query<
        PaintBoothSetPointReturn | null,
        Omit<BoothRequest, "created_at" | "startTs">
      >({
        queryFn: async args => {
          const { buildingId, boothId } = args;
          const userSetPoints =
            await PaintBoothSetPointsService.getUserSetPoint(
              buildingId,
              boothId
            );
          return { data: userSetPoints };
        },
        providesTags: ["USERSETPOINT"]
      }),
      saveUserSetPoint: build.mutation<boolean, Omit<BoothRequest, "startTs">>({
        queryFn: async args => {
          const { buildingId, boothId, created_at } = args;
          const result = await PaintBoothSetPointsService.saveUserSetPoint(
            buildingId,
            boothId,
            created_at
          );
          return { data: result };
        },
        invalidatesTags: ["USERSETPOINT"]
      }),
      createPaintBoothSetPoint: build.mutation<
        PaintBoothSetPoint,
        { buildingId: string; body: PaintBoothSetPointNew }
      >({
        queryFn: async args => {
          const { buildingId, body } = args;
          const setpoint = await PaintBoothSetPointsService.createSetPoint(
            buildingId,
            body
          );
          return { data: setpoint };
        },
        invalidatesTags: ["SETPOINTS"]
      }),
      deletePaintBoothSetPoint: build.mutation<
        any,
        Omit<BoothRequest, "startTs">
      >({
        queryFn: async args => {
          const { buildingId, boothId, created_at } = args;

          return await PaintBoothSetPointsService.deleteSetPoint(
            buildingId,
            boothId,
            created_at
          );
        },
        invalidatesTags: ["SETPOINTS"]
      }),
      getPaintBoothSensors: build.query<
        PaintBoothSensor[],
        Omit<BoothRequest, "created_at" | "startTs">
      >({
        queryFn: async args => {
          const { buildingId, boothId } = args;
          const boothSensors = await PaintBoothService.getSensors(
            buildingId,
            boothId
          );
          return { data: boothSensors };
        },
        providesTags: ["BOOTHSENSOR"]
      }),
      getPaintBoothSensorData: build.query<
        AggItem[],
        {
          sensorId: string;
          buildingId: string;
          startDate: string;
          endDate: string;
          granularity: AggGranularity.DAY | AggGranularity.HOUR;
        }
      >({
        queryFn: async args => {
          const { buildingId, sensorId, startDate, endDate, granularity } =
            args;
          const data = await PaintBoothDataService.getPaintBoothSensorData(
            sensorId,
            buildingId,
            startDate,
            endDate,
            granularity
          );
          return { data };
        },
        providesTags: (_result, _error, { sensorId }) => [
          { type: "SENSORDATA", sensorId }
        ]
      }),
      getAllPaintBoothSensorData: build.query<
        (AggItem & {
          sensorId: string;
        })[][],
        {
          sensorsId: string[];
          buildingId: string;
          startDate: string;
          endDate: string;
          granularity: AggGranularity;
        }
      >({
        queryFn: async args => {
          const { buildingId, sensorsId, startDate, endDate, granularity } =
            args;

          const promises = sensorsId.map(id =>
            PaintBoothDataService.getPaintBoothSensorData(
              id,
              buildingId,
              startDate,
              endDate,
              granularity
            ).then(data =>
              data.map(d => Object.assign({}, d, { sensorId: id }))
            )
          );
          const data = await Promise.all(promises);
          return { data };
        },
        providesTags: (_result, _error, buildingId) => [
          { type: "SENSORDATA", buildingId }
        ]
      }),

      getLatestBoothSensorData: build.query<
        PaintBoothData,
        { buildingId: string; sensorId: string }
      >({
        queryFn: async args => {
          const { buildingId, sensorId } = args;
          const data =
            await PaintBoothDataService.getLatestPaintBoothDataPointForASensor(
              sensorId,
              buildingId
            );
          return { data };
        },
        providesTags: (_result, _error, { sensorId }) => [
          { type: "SENSORWIDGETDATA", sensorId }
        ]
      })
    })
  });

export const {
  useGetPaintBoothsForBuildingQuery,
  useGetPaintBoothQuery,
  useGetPaintBoothSetPointRangeQuery,
  useGetSensorDataForPaintBoothZoneQuery,
  useGetPaintBoothsWithZonesQuery,
  useGetPaintBoothSetPointsQuery,
  useCreatePaintBoothSetPointMutation,
  useDeletePaintBoothSetPointMutation,
  useGetUserSetPointQuery,
  useSaveUserSetPointMutation,
  useGetPaintBoothSensorsQuery,
  useGetPaintBoothSensorDataQuery,
  useGetAllPaintBoothSensorDataQuery,
  useGetLatestBoothSensorDataQuery
} = boothService;
