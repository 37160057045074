import get from "lodash/get";
import classNames from "classnames";
import FmdGoodRoundedIcon from "@mui/icons-material/FmdGoodRounded";
import ParkIcon from "@mui/icons-material/Park";
import HomeIcon from "@mui/icons-material/Home";
import { makeStyles } from "tss-react/mui";
import { OverlayView } from "@react-google-maps/api";
import { getBuildingStatus } from "src/utils/buildings.util";
import { NA } from "src/shared/constants/generals";
import { FormattedBuildingWithEntity } from "src/shared/types/building";
import { Tooltip } from "@mui/material";

import {
  UNHEALTHY,
  SENSITIVE,
  MODERATE,
  GOOD,
  EXCELLENT,
  DEFAULT
} from "src/shared/constants/building-status";
import { useGetUserSettings } from "src/sections/settings/hooks";
import useGetPolutantDictionary from "src/hooks/useGetPollutantDictionary";
import { useNavigate } from "react-router-dom";

type Props = {
  building: FormattedBuildingWithEntity;
};

const BuildingMapMarkerComponent = (props: Props) => {
  const { building } = props;
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { default_webportal_view } = useGetUserSettings();
  const mainView = default_webportal_view === "hvac" ? "hvac" : "indoor";
  const { pollInfoDict } = useGetPolutantDictionary();
  let indoorStatus = DEFAULT;
  let outdoorStatus = DEFAULT;

  if (
    pollInfoDict &&
    pollInfoDict.aqi &&
    pollInfoDict.aqi.statuses &&
    pollInfoDict.aqi.statuses.thresholds
  ) {
    for (let status of pollInfoDict.aqi.statuses.thresholds) {
      if (
        building.indoorData &&
        building.indoorData.data.aqi.value >= status.low &&
        building.indoorData.data.aqi.value <= status.high
      ) {
        indoorStatus = getBuildingStatus(status.status);
      }
      if (
        building.outdoorData &&
        building.outdoorData.data.aqi.value >= status.low &&
        building.outdoorData.data.aqi.value <= status.high
      ) {
        outdoorStatus = getBuildingStatus(status.status);
      }
    }
  }

  return building.location ? (
    <OverlayView
      key={`marker_${building.id}`}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      position={{
        lat: building.location.latitude,
        lng: building.location.longitude
      }}>
      <div className={classes.root}>
        <div
          className={classNames(
            classes.indoor,
            classes[`background${indoorStatus}`]
          )}>
          {get(building, "indoorData.data.aqi.value") === 0
            ? 0
            : get(building, "indoorData.data.aqi.value") || NA}
        </div>
        <div
          className={classNames(
            classes.outdoorTriangle,
            classes[`triangle${outdoorStatus}`]
          )}></div>
        <div
          className={classNames(
            classes.outdoor,
            classes[`background${outdoorStatus}`]
          )}>
          {get(building, "outdoorData.data.aqi.value") === 0
            ? 0
            : get(building, "outdoorData.data.aqi.value") || NA}
        </div>
        <div
          className={classNames(
            classes.indoorTriangle,
            classes[`triangle${indoorStatus}`]
          )}></div>

        <div className={classNames(classes.iconContainer, classes.outdoorIcon)}>
          <div className={classes.leftTriangle}></div>
          <ParkIcon />
          <div className={classes.rightTriangle}></div>
        </div>

        <div className={classNames(classes.iconContainer, classes.indoorIcon)}>
          <div className={classes.leftTriangle}></div>
          <HomeIcon />
          <div className={classes.rightTriangle}></div>
        </div>

        <Tooltip title={building.name}>
          <div
            onClick={() => {
              navigate(`/buildings/${building.id}/${mainView}`);
            }}
            className={classNames(
              classes.mark,
              classes[`border${indoorStatus}`]
            )}>
            <FmdGoodRoundedIcon
              fontSize="large"
              className={classes.markerIcon}
            />
          </div>
        </Tooltip>
      </div>
    </OverlayView>
  ) : null;
};

export const useStyles = makeStyles()(theme => {
  if (!theme.palette.status) {
    theme.palette.status = {
      Excellent: "rgb(21, 142, 72)",
      Good: "rgb(106, 222, 89)",
      Moderate: "rgb(244, 200, 58)",
      "Unhealthy for sensitive groups": "rgb(253, 146, 49)",
      Unhealthy: "rgb(232, 37, 71)",
      Hazardous: "rgb(188, 8, 37)",
      "Very unhealthy": "rgb(188, 8 ,37)",
      Default: "rgb(125, 125, 125)"
    };
  }
  return {
    root: {
      position: "relative" as const,
      width: 54,
      height: 54,
      fontSize: "15px",
      fontWeight: 400
    },
    mark: {
      position: "absolute" as const,
      backgroundColor: theme.palette.common.white,
      width: 54,
      height: 54,
      borderRadius: "50%",
      borderWidth: 2,
      borderStyle: "solid",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      transition: "all 250ms",
      "&:hover": {
        backgroundColor: theme.palette.grey["200"]
      }
    },
    markerIcon: {
      color: theme.palette.grey["400"]
    },
    indoor: {
      position: "absolute" as const,
      textAlign: "right" as const,
      padding: 2.5,
      width: 55,
      color: "white",
      bottom: 4,
      left: 40,
      paddingLeft: 14,
      height: 21
    },
    outdoor: {
      position: "absolute" as const,
      textAlign: "right" as const,
      padding: 2.5,
      width: 48,
      color: "white",
      top: 4,
      left: 40,
      height: 21
    },
    outdoorTriangle: {
      display: "inline-block",
      width: 0,
      height: 0,
      borderTop: "21px solid transparent",
      position: "absolute" as const,
      top: 4,
      left: 88
    },
    indoorTriangle: {
      display: "inline-block",
      width: 0,
      height: 0,
      borderTop: "21px solid transparent",
      position: "absolute" as const,
      bottom: 4,
      left: 95
    },
    iconContainer: {
      color: "white",
      position: "absolute" as const,
      backgroundColor: "#5A5B5D",
      paddingTop: 1,
      height: 21,
      "& svg": {
        fontSize: "18px"
      }
    },
    leftTriangle: {
      display: "inline-block",
      width: 0,
      height: 0,
      borderBottom: "21px solid transparent",
      position: "absolute" as const,
      top: 0,
      left: -6,
      borderRight: `6px solid #5A5B5D`
    },
    rightTriangle: {
      display: "inline-block",
      width: 0,
      height: 0,
      borderTop: "21px solid transparent",
      position: "absolute" as const,
      top: 0,
      right: -6,
      borderLeft: `6px solid #5A5B5D`
    },
    outdoorIcon: {
      top: 4,
      left: 96
    },
    indoorIcon: {
      bottom: 4,
      left: 103
    },
    [`triangle${UNHEALTHY}`]: {
      borderLeft: `6px solid ${theme.palette.status[UNHEALTHY]}`
    },
    [`triangle${SENSITIVE}`]: {
      borderLeft: `6px solid ${theme.palette.status[SENSITIVE]}`
    },
    [`triangle${MODERATE}`]: {
      borderLeft: `6px solid ${theme.palette.status[MODERATE]}`
    },
    [`triangle${GOOD}`]: {
      borderLeft: `6px solid ${theme.palette.status[GOOD]}`
    },
    [`triangle${EXCELLENT}`]: {
      borderLeft: `6px solid ${theme.palette.status[EXCELLENT]}`
    },
    [`triangle${DEFAULT}`]: {
      borderLeft: `6px solid ${theme.palette.grey["500"]}`
    },

    [`border${UNHEALTHY}`]: {
      borderColor: theme.palette.status[UNHEALTHY]
    },
    [`border${SENSITIVE}`]: {
      borderColor: theme.palette.status[SENSITIVE]
    },
    [`border${MODERATE}`]: {
      borderColor: theme.palette.status[MODERATE]
    },
    [`border${GOOD}`]: {
      borderColor: theme.palette.status[GOOD]
    },
    [`border${EXCELLENT}`]: {
      borderColor: theme.palette.status[EXCELLENT]
    },
    [`border${DEFAULT}`]: {
      borderColor: theme.palette.grey["500"]
    },
    [`background${UNHEALTHY}`]: {
      backgroundColor: theme.palette.status[UNHEALTHY]
    },
    [`background${SENSITIVE}`]: {
      backgroundColor: theme.palette.status[SENSITIVE]
    },
    [`background${MODERATE}`]: {
      backgroundColor: theme.palette.status[MODERATE]
    },
    [`background${GOOD}`]: {
      backgroundColor: theme.palette.status[GOOD]
    },
    [`background${EXCELLENT}`]: {
      backgroundColor: theme.palette.status[EXCELLENT]
    },
    [`background${DEFAULT}`]: {
      backgroundColor: theme.palette.grey["500"]
    }
  };
});

export default BuildingMapMarkerComponent;
