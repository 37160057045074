import { useRef } from "react";
import LoaderComponent from "src/shared/components/loader/loader.component";
import { makeStyles } from "tss-react/mui";
import { DEFAULT } from "src/shared/constants/building-status";
import { Typography, useTheme, alpha } from "@mui/material";
import Error from "src/shared/components/Error/Error";
import useGetHvacStatusInfoDictionary from "src/hooks/useGetHvacStatusInfoDictionary";
import { addUnderscoreInWord } from "src/shared/utils/textUtils";
import { StageAndFilterload } from "src/utils/client";
import useUserPascalSettings from "src/hooks/useUserPascalSettings";
import useGetFilterLoadData from "../hooks/useGetFilterLoadData";
import { convertFromPascalsToIWG } from "src/utils/pressure.utils";

type Props = {
  ahuId?: string;
  requiresConfig?: boolean;
  buildingId?: string;
};

const FilterLoadWidgetComponent = ({
  ahuId,
  requiresConfig,
  buildingId
}: Props) => {
  const { classes } = useStyles();
  const componentRef = useRef(null);

  const { stages, ahu, loading, error, refetch } = useGetFilterLoadData(
    buildingId,
    ahuId,
    requiresConfig
  );

  return (
    <div
      data-testid="filter-load-widget"
      className={classes.root}
      ref={componentRef}>
      {requiresConfig ? (
        <div>
          <Typography variant="h6">Filter Load</Typography>
          <Typography>Configuration is required</Typography>
        </div>
      ) : loading ? (
        <LoaderComponent />
      ) : error ? (
        <Error
          error={error}
          setRefetchAgain={refetch}></Error>
      ) : (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column"
          }}>
          <div>
            <Typography variant="h6">Filter Load: {ahu?.name}</Typography>
          </div>

          <div className={classes.grid}>
            {Object.values(stages).map(stage => {
              return (
                <Stage
                  key={stage.name}
                  stage={stage}
                  isLoadWidget={true}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {
      height: "100%",
      padding: "10px"
    },
    loader: {
      position: "absolute",
      top: 0,
      left: 0
    },
    grid: {
      display: "flex",
      height: "100%"
    },
    gridColumn: {
      display: "flex",
      flexGrow: 1,
      flexDirection: "column",
      justifyContent: "space-between",
      border: "1px solid",
      borderRadius: 4,
      padding: 5,
      margin: 8
    },
    stageName: {
      display: "flex",
      justifyContent: "center",
      whiteSpace: "nowrap",
      overflow: "hidden"
    },
    value: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingBottom: 6,
      marginTop: 0
    },
    percentage: {
      fontWeight: "bold"
    },
    unit: {
      fontSize: "smaller"
    }
  };
});

export default FilterLoadWidgetComponent;

export const Stage = ({
  stage,
  isLoadWidget,
  isRemaingingWeekWidget = false
}: {
  stage: StageAndFilterload;
  isLoadWidget: boolean;
  isRemaingingWeekWidget?: boolean;
}) => {
  const { classes } = useStyles();
  const { dictionary } = useGetHvacStatusInfoDictionary();
  const theme = useTheme();
  const isUserUsingPascals = useUserPascalSettings();

  const currentValue = isRemaingingWeekWidget
    ? "remaining_useful_life"
    : isLoadWidget
    ? "filter_loading"
    : "current_pressure";

  const themePallete = isLoadWidget ? "ahuStatus" : "pressureStatus";

  const dictionaryStatuses =
    isLoadWidget && !isRemaingingWeekWidget
      ? "loading_status"
      : "pressure_status";
  const value = stage.filterload?.[currentValue] ?? 0;

  const convertedValue = !isLoadWidget
    ? isUserUsingPascals
      ? stage.filterload?.[currentValue]
      : convertFromPascalsToIWG(stage.filterload?.[currentValue])
    : stage.filterload?.[currentValue] ?? 0;

  const statusValue =
    stage.stage_type === "other"
      ? DEFAULT.toLowerCase()
      : dictionary[stage.stage_type][dictionaryStatuses].find(
          ({ high, low }) => high >= convertedValue && low <= convertedValue
        )?.status ?? DEFAULT.toLowerCase();

  const color =
    theme.palette[themePallete][
      addUnderscoreInWord(statusValue.toLowerCase())
    ] ?? "#898989";

  const unit = isRemaingingWeekWidget
    ? "weeks"
    : isLoadWidget
    ? "%"
    : isUserUsingPascals
    ? "Pa"
    : "IWG";

  return (
    <div
      key={`widget_stage_${stage.id}`}
      className={classes.gridColumn}
      style={
        value
          ? {
              borderColor: color,
              color,
              backgroundColor: alpha(color, 0.05)
            }
          : {
              borderColor: "#000",
              color: "#000",
              backgroundColor: alpha("#000", 0.05)
            }
      }>
      <Typography className={classes.stageName}>
        <span title={stage.name}>{stage.name}</span>
      </Typography>
      <Typography className={classes.value}>
        <span className={classes.percentage}>
          {convertedValue ? `${convertedValue.toFixed(0)} ${unit}` : "-----"}
        </span>
      </Typography>
    </div>
  );
};
