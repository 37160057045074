import {
  Suspense,
  useEffect,
  useState,
  ChangeEvent,
  ReactElement
} from "react";

import {
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Checkbox,
  Button,
  Box,
  Typography,
  useTheme
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import toast from "react-hot-toast";

import {
  Company,
  CompanyInvitationsService,
  CompanyMembershipService,
  UsersService
} from "src/utils/client";
import { filterUsers } from "./filterUsers";
import { formatDate } from "src/utils/ulid-converter";
import LoaderComponent from "src/shared/components/loader/loader.component";
import ContentContainer from "src/layout/ContentContainer";
import InviteMember from "./InviteMember";
import { removeDuplicatesById } from "./removeDuplicatesById";
import Dropdown, { Option } from "src/components/Dropdown";
import { useStyles, modalStyle } from "./user-administration-portal.styles";
import { useAppSelector } from "src/hooks/useAppSelector";
import { getUserId } from "src/store/auth/selectors";

function UserAdministrationPortal() {
  const currentUserId = useAppSelector(getUserId);
  const { classes } = useStyles();
  const [userMemberships, setUserMemberships] = useState<Array<Company>>([]);
  const [selectedCompany, setSelectedCompany] = useState<Company>(
    userMemberships[0]
  );
  const [users, setUsers] = useState([]);
  const [emailFilter, setEmailFilter] = useState<string>("");
  const [checkedUser, setCheckedUser] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [userStatusFilter, setUserStatusFilter] = useState<Option>({
    id: "all",
    name: "All"
  });
  const [inviteMemberModalOpened, setInviteMemberModalOpened] =
    useState<boolean>(false);
  const theme = useTheme();
  const [modelOpen, setModalOpen] = useState<boolean>(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const fetchUserMemberships = async () => {
      try {
        const { companies } = await UsersService.getUserMemberships(
          currentUserId
        );
        setUserMemberships(removeDuplicatesById(companies));
        handleChangeCompany(companies[0]);
      } catch (error) {
        console.error("Failed to fetch user memberships:", error);
      }
    };
    fetchUserMemberships();
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        if (!selectedCompany) return;
        const members = await CompanyMembershipService.getCompanyMemberships(
          selectedCompany.id
        );
        const invited = await CompanyInvitationsService.getAllInvitations(
          selectedCompany.id
        );
        setUsers(filterUsers(members, invited));
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch user memberships:", error);
      }
    };
    fetchUsers();
  }, [selectedCompany]);

  const filteredUsers = users.filter(user => {
    // Always filter yourself out as a user
    if (user.user_id === currentUserId) return false;

    const filteredByEmail = user.user_email
      .toLowerCase()
      .includes(emailFilter.toLowerCase());

    if (!filteredByEmail) return false;

    if (userStatusFilter.id === "all") return true;

    if (userStatusFilter.id === "pending") {
      return user.invitation_status === "pending";
    }

    if (userStatusFilter.id === "accepted") {
      return user.invitation_status !== "pending";
    }

    return true;
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (checkedUser === event.target.id) return setCheckedUser("");
    setCheckedUser(event.target.id);
  };

  const handleChangeStatusFilter = (filter: Option) => {
    setUserStatusFilter(filter);
  };

  const handleChangeCompany = (company: Company) => {
    setEmailFilter("");
    setSelectedCompany(company);
  };

  const refreshMembershipData = async () => {
    setLoading(true);
    const members = await CompanyMembershipService.getCompanyMemberships(
      selectedCompany.id
    );

    const invited = await CompanyInvitationsService.getAllInvitations(
      selectedCompany.id
    );

    setUsers(filterUsers(members, invited));
    setLoading(false);
  };

  const deleteUser = () => {
    const selectedUser = users.find(user => user.user_email === checkedUser);
    if (selectedUser.invitation_status === "pending") {
      CompanyInvitationsService.revokeInvitation(
        selectedCompany.id,
        selectedUser.id
      );
    } else {
      CompanyMembershipService.removeUserFromCompany(
        selectedCompany.id,
        selectedUser.user_id
      );
    }
    toast.success("User deleted");
    refreshMembershipData();
    handleCloseModal();
  };

  const openModal = () => {
    handleOpenModal();
  };

  const getUserInvitiationStatus = (status: string) => {
    return status === "pending" ? "Pending" : "Accepted";
  };

  var statusOptions = [
    { id: "all", name: "All" },
    { id: "accepted", name: "Accepted" },
    { id: "pending", name: "Pending" }
  ];

  const HandleModalView = (): ReactElement => {
    return (
      <Modal
        open={modelOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={handleCloseModal}>
        <Box sx={modalStyle(theme)}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2">
            User deactivation!
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}>
            Are you sure you want to deactivate user <b>{checkedUser}</b>? The
            user will no longer be able to log into{" "}
            <b>{selectedCompany?.name}</b> account and will no longer receive
            notifications. No user data will be deleted. You will be able to
            reactivate the user later.
          </Typography>
          <Box
            display="div"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
              bottom: 0
            }}>
            <Button
              color="primary"
              onClick={deleteUser}>
              Yes, delete
            </Button>
            <Button
              color="error"
              onClick={handleCloseModal}>
              No, cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };

  return (
    <Suspense fallback={<p>Loading</p>}>
      <HandleModalView />
      <InviteMember
        isOpened={inviteMemberModalOpened}
        setInviteMemberModalOpened={setInviteMemberModalOpened}
        refreshMembershipData={refreshMembershipData}
        companies={userMemberships}
      />
      <ContentContainer>
        <h1>User Administration Portal</h1>
        <section className={classes.filters}>
          <Dropdown
            label="Company"
            options={userMemberships}
            selected={selectedCompany}
            handleChange={handleChangeCompany}
          />
          <Dropdown
            label="Status"
            options={statusOptions}
            selected={userStatusFilter}
            handleChange={handleChangeStatusFilter}
          />

          <TextField
            sx={{ marginTop: 0 }}
            label="Search by email"
            placeholder="Search by email"
            value={emailFilter}
            onChange={({ target: { value } }) => {
              setEmailFilter(value);
            }}
          />

          <Button
            variant="outlined"
            className={classes.button}
            disabled={!checkedUser}
            onClick={openModal}>
            <DeleteIcon /> Delete
          </Button>

          <Button
            variant="contained"
            onClick={() => setInviteMemberModalOpened(true)}
            className={classes.buttonOutline}>
            <AddIcon />
            Invite Member
          </Button>
        </section>
        {loading ? (
          <LoaderComponent />
        ) : (
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>User Email</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Invitation Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUsers?.map((user, index) => (
                  <TableRow
                    key={user.user_id ?? index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell
                      component="th"
                      scope="row">
                      <Checkbox
                        id={user.user_email}
                        checked={checkedUser === user.user_email}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </TableCell>
                    <TableCell>{user.user_email}</TableCell>
                    <TableCell>{user.role}</TableCell>
                    <TableCell>
                      {getUserInvitiationStatus(user.invitation_status)}
                    </TableCell>

                    <TableCell>
                      <>{formatDate(user.created_at)}</>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </ContentContainer>
    </Suspense>
  );
}

export default UserAdministrationPortal;
