import { Container, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { FormattedAhu } from "src/shared/types/ahu";
import { Stage } from "src/utils/client";
import StageAccordion from "./stage-accordion.component";
import { checkTheValue } from "./filter-details/filter-details.component";

type AhuInformationProps = {
  ahu: FormattedAhu;
  stages: Stage[];
  terminalPressure?: number;
  setIsDrawerOpen?: any;
};

const AhuInformation = ({
  ahu,
  stages,
  terminalPressure,
  setIsDrawerOpen
}: AhuInformationProps) => {
  const [expandedStageId, setExpandedStageId] = useState<string | null>(null);

  return (
    <Container style={{ padding: 0, width: "90%" }}>
      <Typography
        mb={1}
        fontWeight={500}>
        GENERAL INFORMATION
      </Typography>

      <Stack
        mb={1}
        direction={"row"}
        justifyContent={"space-between"}>
        <Typography>Zone</Typography>
        <Typography fontWeight={500}>{checkTheValue(ahu?.zone)}</Typography>
      </Stack>

      <Stack
        mb={2}
        direction={"row"}
        justifyContent={"space-between"}>
        <Typography>Variable Fan Drive</Typography>
        <Typography fontWeight={500}>
          {ahu?.variable_freq_drive ? ahu?.variable_freq_drive : "---"}
        </Typography>
      </Stack>

      <Stack borderRadius={"1rem"}>
        {stages &&
          stages?.map((stage: Stage, index: number) => {
            return (
              <StageAccordion
                key={`stage_${index}`}
                index={index}
                stage={stage}
                buildingId={ahu.building_id}
                expandedStageId={expandedStageId}
                terminalPressure={terminalPressure}
                setIsDrawerOpen={setIsDrawerOpen}
                setExpandedStageId={setExpandedStageId}
              />
            );
          })}
      </Stack>
    </Container>
  );
};

export default AhuInformation;
