/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserSettings_Output } from './UserSettings_Output';
/**
 * Internal Admin User entity, with 'id' field
 */
export type AdminUser = {
  name: string;
  email: string;
  phone_number: string;
  settings?: UserSettings_Output;
  role: AdminUser.role;
  id: string;
  created_at: string;
  accepted_terms?: any;
};
export namespace AdminUser {
  export enum role {
    QLAIR_SUPER_ADMIN = 'QlairSuperAdmin',
    QLAIR_WRITE_ADMIN = 'QlairWriteAdmin',
    QLAIR_READ_ADMIN = 'QlairReadAdmin',
  }
}

