import { useCallback, useState } from "react";
import config from "src/utils/config";
import { makeStyles } from "tss-react/mui";
import { GoogleMap } from "@react-google-maps/api";
import { googleMapStyles } from "./mapstyles";
import { setMap as setGlobalMap } from "src/utils/maps.utils";

const containerStyle = {
  width: "100%",
  height: "100%"
};

const center = {
  lat: 38.78129,
  lng: -102.195998
};

type Props = {
  children?: any;
  onLoad?: any;
};

let onLoadMap;

const MapComponent = (props: Props) => {
  const { children } = props;
  const { classes } = useStyles();
  const [, setMap] = useState(null);

  onLoadMap = useCallback(function callback(map) {
    setMap(map);
    setGlobalMap(map);
  }, []);

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  const options: google.maps.MapOptions = {
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
    mapTypeId: "roadmap",
    styles: googleMapStyles,
    minZoom: 4,
    maxZoom: 12,
    zoomControl: false
  };

  return (
    <div
      className={classes.root}
      data-testid="map">
      <div className={classes.gradient}></div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={config.mapCenter || center}
        zoom={4}
        onLoad={onLoadMap}
        onUnmount={onUnmount}
        options={options}>
        {children}
      </GoogleMap>
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {
      width: "100%",
      height: "100%"
    },
    gradient: {
      position: "absolute" as const,
      zIndex: 2,
      width: "100%",
      height: "125px",
      background:
        "linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 100%);"
    }
  };
});

export const testPort = {
  onLoadMap
};

export default MapComponent;
