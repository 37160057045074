import classnames from "classnames";
import SignalWifiOffIcon from "@mui/icons-material/SignalWifiOff";
import { getAQIStatus, getAQValueFromRoom } from "src/utils/buildings.util";
import { RoomStatus } from "src/utils/client";
import { Button, Grid, Hidden, Typography } from "@mui/material";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import {
  handleRoomModal,
  setIsEditingRoom,
  setSelectedRoom
} from "src/store/hvac/reducer";
import AirQualityIndicator from "src/sections/buildings/air-quality-indicator/air-quality-indicator";
import { useStyles } from "./room-row.style";
import { useStyles as useStatusColors } from "src/shared/styles/statusColors";
import { MouseEvent } from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  room: RoomStatus;
};

const RoomRowComponent = ({ room }: Props) => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { classes: statusClasses } = useStatusColors();
  const status = getAQIStatus(room.latest_aqi);
  const deviceCount = room.devices.length || 0;
  const aqValue = getAQValueFromRoom(room, "latest_aqi");

  const changeToDevices = () => {
    dispatch(setSelectedRoom(room.room));
    navigate(`rooms/${room.room.id}`);
  };

  const openRoomDetailsModal = (
    event: MouseEvent<HTMLButtonElement>,
    room: RoomStatus
  ) => {
    event.stopPropagation();
    dispatch(setIsEditingRoom(true));
    dispatch(setSelectedRoom(room.room));
    dispatch(handleRoomModal(true));
  };

  let hasOutdated = false;

  return (
    <>
      {Boolean(room.room.id) && (
        <Grid
          data-testid="room-row"
          container
          className={classes.root}
          key={`room_row_${room.room.id}`}
          onClick={changeToDevices}>
          <Grid
            xs={2}
            sm={1}
            lg={1}
            item
            className={classes.statusContainer}>
            <div
              className={classnames(
                classes.statusIndicator,
                statusClasses[`back${status.replace(/ /g, "_")}`]
              )}></div>
          </Grid>
          <Grid
            item
            xs={5}
            sm={4}
            md={3}
            lg={2}>
            <Hidden mdUp>
              <Typography
                className={classnames(classes.floorName, classes.firstColumn)}>
                Floor {room.room.floor}
              </Typography>
            </Hidden>
            <Typography
              variant="body1"
              translate="no"
              className={classnames(
                classes.column,
                classes.firstColumn,
                classes.name
              )}>
              {room.room.name}
              {hasOutdated && <SignalWifiOffIcon className={classes.offline} />}
            </Typography>
            <>
              <Hidden smUp>
                <Typography
                  className={classnames(classes.address, classes.firstColumn)}>
                  {deviceCount} devices
                </Typography>
              </Hidden>
              <Hidden smUp>
                <div
                  className={classnames(classes.address, classes.firstColumn)}>
                  <Button
                    data-testid="edit-room-btn-small"
                    variant="text"
                    onClick={event => openRoomDetailsModal(event, room)}>
                    Edit Room
                  </Button>
                </div>
              </Hidden>
            </>
          </Grid>
          <Hidden mdDown>
            <Grid
              item
              md={2}
              lg={2}>
              <Typography
                translate="no"
                className={classes.column}>
                {room.room.floor}
              </Typography>
            </Grid>
          </Hidden>
          <Hidden smDown>
            <Grid
              item
              sm={2}
              md={1}
              lg={2}>
              <Typography className={classes.column}>
                {deviceCount} Devices
              </Typography>
            </Grid>
          </Hidden>
          <Grid
            item
            md={2}
            sm={2}
            xs={4}
            className={classes.aqi}>
            <AirQualityIndicator value={aqValue} />
          </Grid>
          <Hidden smDown>
            <Grid
              item
              sm={2}
              md={2}
              className={classes.actions}>
              <div>
                <Button
                  data-testid="edit-room-btn"
                  variant="text"
                  onClick={event => openRoomDetailsModal(event, room)}>
                  Edit Room
                </Button>
              </div>
            </Grid>
          </Hidden>
        </Grid>
      )}
    </>
  );
};

export default RoomRowComponent;
