import { useState, useMemo } from "react";
import { makeStyles } from "tss-react/mui";
import { WidgetSettings } from "../widgets-config";
import { GetCompanyIDFromBuildingId } from "src/utils/buildings.util";
import Error from "src/shared/components/Error/Error";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@mui/material";
import {
  DeviceGroup,
  getParentsForInput
} from "../../utils/entity-selection-utils";
import useGetWidgetSettingsEntities from "src/hooks/useGetWidgetSettingsEntities";
import { getServiceLogType } from "./utils";

const serviceLogTypes = {
  AHU: {
    buildingFilter: building => building.has_ahus,
    deviceGroup: DeviceGroup.hvac,
    idKey: "ahuId"
  },
  Kettle: {
    buildingFilter: building => building.has_kettles,
    deviceGroup: DeviceGroup.kettle,
    idKey: "kettleId"
  },
  PaintBooth: {
    buildingFilter: building => building.has_paint_booths,
    deviceGroup: DeviceGroup.paint,
    idKey: "paintBoothId"
  }
};

const ServiceLogsSettingsComponent = ({
  onSave,
  currentProps
}: WidgetSettings) => {
  const { classes } = useStyles();
  const [serviceLogType, setServiceLogType] = useState(
    getServiceLogType(currentProps)
  );
  const [selectedId, setSelectedId] = useState(
    currentProps[serviceLogTypes[serviceLogType].idKey] ?? ""
  );
  const [buildingId, setBuildingId] = useState(currentProps.buildingId ?? "");

  const { buildings, deviceParents, isLoading, isFetching, isError, refetch } =
    useGetWidgetSettingsEntities();

  const filteredBuildings = useMemo(() => {
    return (
      buildings?.filter(serviceLogTypes[serviceLogType].buildingFilter) || []
    );
  }, [buildings, serviceLogType]);

  const devicesForInput = useMemo(() => {
    return getParentsForInput(
      deviceParents,
      buildingId,
      serviceLogTypes[serviceLogType].deviceGroup
    );
  }, [deviceParents, buildingId, serviceLogType]);

  const handleRadioChange = event => {
    const newServiceLogType = event.target.value;
    setSelectedId("");
    setBuildingId("");
    setServiceLogType(newServiceLogType);
  };

  return (
    <div className={classes.root}>
      {isLoading || isFetching ? (
        <CircularProgress className={classes.loader} />
      ) : isError ? (
        <Error
          error={isError}
          setRefetchAgain={refetch}
          showGenericMessage
        />
      ) : (
        <div>
          <RadioGroup
            row
            value={serviceLogType}
            onChange={handleRadioChange}>
            {Object.entries(serviceLogTypes).map(([type, _]) => (
              <FormControlLabel
                key={type}
                value={type}
                control={<Radio />}
                label={type}
              />
            ))}
          </RadioGroup>

          <FormControl fullWidth>
            <InputLabel>Building</InputLabel>
            <Select
              value={buildingId}
              label="Building"
              onChange={({ target: { value } }) => {
                setSelectedId("");
                setBuildingId(value);
              }}>
              {filteredBuildings.map(building => (
                <MenuItem
                  key={`building_option_${building.id}`}
                  value={building.id}>
                  {building.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            fullWidth
            className={classes.deviceSelector}>
            <InputLabel>{serviceLogType}</InputLabel>
            <Select
              value={selectedId}
              label={serviceLogType}
              onChange={({ target: { value } }) => {
                const selectedDevice = devicesForInput?.find(
                  device => device.id === value
                );
                if (selectedDevice) {
                  setBuildingId(selectedDevice.building_id);
                }
                setSelectedId(value);
              }}>
              {devicesForInput?.map(device => (
                <MenuItem
                  key={`${serviceLogType.toLowerCase()}_option_${device.id}`}
                  value={device.id}>
                  {device.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
      <div className={classes.actions}>
        <Button
          disabled={!selectedId}
          variant="contained"
          onClick={() =>
            onSave({
              buildingId,
              companyId: GetCompanyIDFromBuildingId(buildingId, buildings),
              [serviceLogTypes[serviceLogType].idKey]: selectedId,
              requiresConfig: currentProps.requiresConfig,
              widgetName: currentProps.widgetName,
              widgetId: currentProps.widgetId,
              serviceLogType
            })
          }>
          Save
        </Button>
      </div>
    </div>
  );
};

export const useStyles = makeStyles()(() => ({
  root: {},
  loader: {
    display: "flex",
    justifyContent: "center"
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "1em",
    gap: "1em"
  },
  deviceSelector: {
    marginTop: "1em",
    display: "flex"
  }
}));

export default ServiceLogsSettingsComponent;
