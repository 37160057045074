import { useStyles } from "./notifications-list.style";
import LoaderComponent from "src/shared/components/loader/loader.component";
import useGetNotificationsAndSubscriptions from "../hooks/useGetNotificationsAndSubscriptions";
import Table from "src/shared/components/Table/Table";

const NotificationsListComponent = () => {
  const { classes } = useStyles();
  const { isLoading, isFetching, sortedNotifications } =
    useGetNotificationsAndSubscriptions();

  return isLoading || isFetching ? (
    <LoaderComponent />
  ) : (
    <div
      data-testid="notifications-list"
      className={classes.root}>
      <Table
        data={sortedNotifications}
        type="notifications"
        showHeader={true}
      />
    </div>
  );
};

export default NotificationsListComponent;
