export const NA = "N/A";

export enum Views {
  CHART = "chart",
  MAP = "map",
  LIST = "list"
}

export const gridConfiguration = {
  xs: 12,
  sm: 6,
  md: 4,
  lg: 3,
  xl: 2
};
