import {
  useGetHvacSensorsForBuildingQuery,
  useGetLatestHvacDeviceDataQuery
} from "../store/services/buildings/buildings.service";
import { Stage } from "../utils/client";

export default function useHvacSensors(buildingId: string, stage: Stage) {
  const {
    data: hvacSensors,
    isLoading: isLoadingSensors,
    isFetching: isFetchingSensors,
    isError,
    isSuccess
  } = useGetHvacSensorsForBuildingQuery(buildingId);

  const {
    data: hvacData,
    isLoading: isLoadingHvacData,
    isFetching: isFetchingHvacData
  } = useGetLatestHvacDeviceDataQuery(
    {
      buildingId,
      deviceId: hvacSensors?.find(({ stage_id }) => stage_id === stage.id)?.id
    },
    { skip: !isSuccess }
  );

  const isLoading =
    isLoadingHvacData ||
    isLoadingSensors ||
    isFetchingSensors ||
    isFetchingHvacData;

  return { hvacData, isLoading, isError };
}
