import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Button,
  ButtonBase,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  Tooltip
} from "@mui/material";

import LoaderComponent from "src/shared/components/loader/loader.component";
import { COUNTRIES } from "src/shared/constants/countries";
import { useStyles } from "./settings.style";
import { useUpdateUserSettingsMutation } from "src/store/services/settings/settings.service";
import { useAppSelector } from "src/hooks/useAppSelector";
import { getUserEmail, getUserId, getUserName } from "src/store/auth/selectors";
import { useGetGeneralSettingsOptions, useGetUserSettings } from "../hooks";
import FormDropdownField from "../components/FormDropdownField";
import FormButtonField from "../components/FormButtonField";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useNavigate } from "react-router-dom";

const Views = {
  AQ: "aq",
  HVAC: "hvac",
  KETTLE: "kettle"
};
const mainViewOptions = [
  { value: "aq", label: "AQ" },
  { value: "hvac", label: "HVAC" },
  { value: "kettle", label: "Kettle" }
];

const Settings = () => {
  const navigate = useNavigate();
  const userId = useAppSelector(getUserId);
  const userName = useAppSelector(getUserName);
  const userEmail = useAppSelector(getUserEmail);
  const { classes } = useStyles();
  const {
    isLoading: isLoadingOptions,
    isFetching,
    languages,
    temperatures,
    aqiStandards,
    pressures
  } = useGetGeneralSettingsOptions();

  const {
    temperature: serverTemperatureSetting,
    langauge: serverLangaugeSetting,
    aqi_scale: serverAqiscaleSetting,
    pressure: serverPressureSetting,
    default_webportal_view: serverDefaultView,
    phoneNumber: serverPhoneNumber,
    sendAlerts: serverSendAlerts,
    userSettingsExist,
    isLoading: isLoadingUserSettings,
    isFetching: isFetchingUserSettings
  } = useGetUserSettings();

  const [updateUserSettings, { isLoading: isSaving }] =
    useUpdateUserSettingsMutation();

  const loading =
    isLoadingUserSettings ||
    isLoadingOptions ||
    isSaving ||
    isFetchingUserSettings ||
    isFetching;

  const [phone, setPhone] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [temperature, setTemp] = useState("");
  const [language, setLang] = useState("");
  const [aqi_scale, setAqiScale] = useState("");
  const [pressure, setPressure] = useState("");
  const [showPhoneError, setShowPhoneError] = useState(false);
  const [send_alerts, setAlertsPreference] = useState(true);
  const [default_webportal_view, setShowDeviceType] = useState("");

  useEffect(() => {
    if (loading) return;
    setLang(serverLangaugeSetting);
    setAqiScale(serverAqiscaleSetting);
    setPressure(serverPressureSetting);
    setTemp(serverTemperatureSetting);
    setShowDeviceType(
      serverDefaultView === Views.AQ
        ? Views.AQ
        : serverDefaultView === Views.KETTLE
        ? Views.KETTLE
        : Views.HVAC
    );
    setAlertsPreference(serverSendAlerts);
  }, [userSettingsExist, loading]);

  const handleSave = () => {
    const dataForEndpoint = {
      userId,
      newUser: {
        name: userName,
        email: userEmail,
        accepted_terms: true,
        phone_number: `${phoneCode}${phone}`,
        settings: {
          language,
          aqi_scale,
          send_alerts,
          default_webportal_view,
          unit_prefs: {
            pressure,
            temperature
          }
        }
      }
    };
    updateUserSettings(dataForEndpoint).finally(() =>
      toast.success("Settings saved successfully")
    );
  };
  const handleBack = () => navigate("/overview");

  useEffect(() => {
    if (!serverPhoneNumber) return;
    for (let country of COUNTRIES) {
      let regexp = new RegExp(`\\${country.mobileCode}`);
      if (!regexp.test(serverPhoneNumber)) continue;
      setPhoneCode(country.mobileCode);
      setPhone(serverPhoneNumber.replace(country.mobileCode, ""));
      break;
    }
  }, [userSettingsExist]);

  return (
    <div
      className={classes.root}
      data-testid="settings">
      {loading && <LoaderComponent />}
      <>
        <div className={classes.title}>
          <IconButton
            data-testid="back-btn"
            onClick={handleBack}
            className={classes.backBtn}
            size="small">
            <ArrowBackIosRoundedIcon />
          </IconButton>
          <h2 data-testid="configuration-title">System Configuration</h2>
        </div>
        <div className={classes.separator}></div>

        <div className={classes.details}>
          <div
            className={classes.settings}
            style={{ paddingBottom: 8 }}>
            <Grid
              spacing={2}
              container
              className={classes.settingsGrid}>
              <Grid
                item
                xs={12}
                sm={4}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center"
                }}>
                <Typography sx={{ marginRight: 2 }}>Phone</Typography>
                <Tooltip
                  title={
                    "By enabling text messages, you consent to receive messages from our service which may incur carrier costs."
                  }
                  arrow>
                  <InfoOutlinedIcon fontSize="small" />
                </Tooltip>
              </Grid>
              <Grid
                item
                xs={6}
                sm={4}>
                <Select
                  onChange={({ target: { value } }) => setPhoneCode(value)}
                  className={classes.select}
                  value={phoneCode}
                  placeholder="Country code"
                  data-testid="phonecode-select"
                  inputProps={{
                    "data-testid": "phonecode-select"
                  }}>
                  {COUNTRIES.map(country => (
                    <MenuItem
                      key={`phone_code_option_${country.code}`}
                      value={country.mobileCode}>
                      <div className={classes.option}>
                        {country.mobileCode} ({country.name})
                      </div>
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid
                item
                xs={6}
                sm={4}>
                <div className={classes.phoneContainer}>
                  <TextField
                    sx={{ width: "100%" }}
                    label="Phone"
                    placeholder="Phone"
                    error={showPhoneError}
                    onFocus={() => setShowPhoneError(false)}
                    value={phone}
                    onChange={({ target: { value } }) => {
                      if (value === "" || /^\d+$/.test(value)) {
                        setPhone(value);
                      }
                    }}
                  />
                </div>
              </Grid>
            </Grid>
            <Typography color={"darkgray"}>
              You must add your phone number to receive text message
              notifications
            </Typography>
          </div>
          <FormButtonField
            classes={classes}
            labelName="Temperature"
            setValue={setTemp}
            initalValue={temperature}
            systemOptions={temperatures}></FormButtonField>

          <FormDropdownField
            classes={classes}
            labelName="Language"
            setValue={setLang}
            initalValue={language}
            systemOptions={languages}
            type="lang"></FormDropdownField>

          <FormDropdownField
            classes={classes}
            labelName="AQI Standard"
            setValue={setAqiScale}
            initalValue={aqi_scale}
            systemOptions={aqiStandards}
            labelConfig={{ translate: "no" }}
            type="aqi"></FormDropdownField>

          <FormDropdownField
            classes={classes}
            labelName="Main View"
            setValue={setShowDeviceType}
            initalValue={default_webportal_view}
            systemOptions={mainViewOptions}
            type="view"></FormDropdownField>

          <FormDropdownField
            classes={classes}
            labelName="Pressure"
            setValue={setPressure}
            initalValue={pressure}
            systemOptions={pressures}
            type="pressure"></FormDropdownField>

          <div className={classes.settings}>
            <Grid
              spacing={2}
              container
              className={classes.settingsGrid}>
              <Grid
                item
                xs={12}
                sm={4}>
                <Typography>Enable / Disable notifications</Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={4}>
                <Switch
                  onChange={(_event, checked) => setAlertsPreference(checked)}
                  checked={send_alerts}
                />
              </Grid>
            </Grid>
          </div>
          <ButtonBase
            onClick={() => {
              navigate("/notifications");
            }}
            className={classes.notifications}>
            <Typography>Notifications Management</Typography>
            <ArrowForwardIosIcon />
          </ButtonBase>
          <div className={classes.actions}>
            <Button
              onClick={handleSave}
              className={classes.actionBtn}
              variant="contained"
              data-testid="apply-button">
              Apply
            </Button>
            <Button
              onClick={handleBack}
              className={classes.actionBtn}>
              Cancel
            </Button>
          </div>
        </div>
        <p style={{ textAlign: "center", fontSize: 14 }}>
          Copyright 2024 - qlair, A MANN+HUMMEL SOLUTION
        </p>
      </>
    </div>
  );
};

export const SettingsComponent = Settings;
