import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import LineChart from "../../../shared/components/line-chart/line-chart-component";
import {
  handleDownloadModal,
  setAhuChartGranularty,
  setAhuDetailsEndDate,
  setAhuDetailsStartDate,
  setDownloadModalEntity,
  setDownloadModalEntityId
} from "../../../store/hvac/reducer";
import {
  getAhuChartGranularity,
  getAhuDetailsEndDate,
  getAhuDetailsStartDate,
  getSelectedAhu,
  getSelectedAhuDetailsStageId
} from "../../../store/hvac/selectors";
import { Stage, StageType } from "../../../utils/client";
import { renderWeekPickerDay } from "../../../utils/date-picker.utils";
import { AggGranularity } from "../../../utils/client";
interface DateChartProps {
  stages: Array<Stage>;
  chartData: any;
  terminalPressure: number;
}

export default function DateChart({
  stages,
  chartData,
  terminalPressure
}: DateChartProps) {
  const dispatch = useAppDispatch();
  const ahu = useAppSelector(getSelectedAhu);
  const endDate = useAppSelector(getAhuDetailsEndDate);
  const startDate = useAppSelector(getAhuDetailsStartDate);
  const selectedStageId = useAppSelector(getSelectedAhuDetailsStageId);
  const granularity = useAppSelector(getAhuChartGranularity);

  const airflowStage = stages?.find(
    stage => stage.stage_type === StageType.AIRFLOW
  );
  const chartDataExist = chartData?.some(({ data }) => Boolean(data.length));
  const selectedStageHasData =
    Boolean(selectedStageId) &&
    Boolean(chartData) &&
    Boolean(chartData?.at(0)?.data?.length);
  const title = airflowStage
    ? "Filter Pressure and Airflow"
    : "Filter Pressure";

  const openDownloadDataModal = () => {
    dispatch(handleDownloadModal(true));
    dispatch(setDownloadModalEntity("ahu"));
    dispatch(setDownloadModalEntityId(ahu.id));
  };

  return (
    <Container
      maxWidth={false}
      style={{ padding: 0 }}>
      <Grid
        container
        alignItems={"center"}
        justifyContent={"space-between"}>
        <Grid item>
          <Typography fontWeight={500}>{title.toUpperCase()}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={"auto"}>
          <Stack
            direction={{ md: "row", sm: "column" }}
            width={{ sm: 1 }}>
            <FormControl
              fullWidth
              style={{ marginTop: 8, width: 130, marginRight: 20 }}>
              <InputLabel id="granularity-label">Granularity</InputLabel>
              <Select
                data-testid="granulatiry-select"
                labelId="granularity-label"
                value={granularity ?? AggGranularity.DAY}
                label="Granularity"
                onChange={({ target: { value } }) => {
                  dispatch(
                    setAhuChartGranularty(
                      value as AggGranularity.DAY | AggGranularity.HOUR
                    )
                  );
                }}>
                <MenuItem value={"hour"}>Hours</MenuItem>
                <MenuItem value={"day"}>Days</MenuItem>
              </Select>
            </FormControl>
            <Box
              width={"160px"}
              mr={"1.2rem"}>
              <DatePicker
                label="From date"
                value={startDate}
                renderDay={renderWeekPickerDay}
                onChange={newValue => {
                  dispatch(setAhuDetailsStartDate(newValue.toString()));
                }}
                renderInput={params => <TextField {...params} />}
              />
            </Box>
            <Box
              width={"160px"}
              mr={"1.2rem"}>
              <DatePicker
                label="To date"
                value={endDate}
                renderDay={renderWeekPickerDay}
                onChange={newValue => {
                  dispatch(setAhuDetailsEndDate(newValue.toString()));
                }}
                renderInput={params => <TextField {...params} />}
              />
            </Box>
            <Box mt={1}>
              <Button
                data-testid="download"
                onClick={openDownloadDataModal}
                variant="text">
                Download
              </Button>
            </Box>
          </Stack>
        </Grid>
      </Grid>

      <>
        {chartDataExist ? (
          <Box
            position={"relative"}
            data-testid="chart"
            height={"400px"}
            mb={"20px"}>
            <LineChart
              data={chartData}
              maintainAspectRatio={false}
              showSecondYAxis={Boolean(airflowStage)}
              showTerminalPressure={Boolean(selectedStageId)}
              expectedTerminalPressure={terminalPressure}
            />
          </Box>
        ) : (
          <Stack sx={styles.empty}>
            {!selectedStageHasData && (
              <>
                <Typography
                  gutterBottom
                  variant="subtitle1">
                  NO DEVICE DATA AVAILABLE
                </Typography>
                <Typography variant="body2">
                  Please check that your device has its connection
                </Typography>
              </>
            )}
          </Stack>
        )}
      </>
    </Container>
  );
}

const styles = {
  empty: {
    py: 5,
    my: 3,
    bgcolor: "#F3F6FF",
    textAlign: "center",
    borderRadius: "8px",
    boxShadow:
      "0px 11px 15px rgba(47, 74, 170, 0.05), 0px 9px 46px rgba(47, 74, 170, 0.03), 0px 24px 38px rgba(47, 74, 170, 0.04);"
  }
};
