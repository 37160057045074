import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";

const selectBuildings = (state: RootState) => state.buildings;

export const getBuildingView = createSelector(
  selectBuildings,
  state => state.view
);

export const getBuildingModalState = createSelector(
  selectBuildings,
  state => state.buildingModalOpen
);
export const getReportModalState = createSelector(
  selectBuildings,
  state => state.reportModalOpen
);
export const getSelectedBuilding = createSelector(
  selectBuildings,
  state => state.selectedBuilding
);
export const getIsBuildingEdit = createSelector(
  selectBuildings,
  state => state.isEditingBuilding
);
export const getSearchTerm = createSelector(
  selectBuildings,
  state => state.searchTerm
);

export const getSorterType = createSelector(
  selectBuildings,
  state => state.sorterType
);

export const getIsEditingBuilding = createSelector(
  selectBuildings,
  state => state.isEditingBuilding
);

export const getTradefairSelectedPollutant = createSelector(
  selectBuildings,
  state => state.tradefairSelectedPollutant
);

export const getTradefairSelectedDeviceId = createSelector(
  selectBuildings,
  state => state.tradefairSelectedDeviceId
);
