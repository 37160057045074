import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { Event, EventType } from "src/utils/client";
import { useStyles } from "./activity-filters.style";
import { Service_type } from "../../../service-log/add-service-log/add-service-log.component";
type Filters = {
  setFilteredList: Dispatch<SetStateAction<Event[]>>;
  auditLogs: Event[];
};

const ActivityFilters = ({ setFilteredList, auditLogs }: Filters) => {
  const { classes } = useStyles();
  const [isFilterSingalsActive, setIsFilterSingnalsActive] = useState(false);
  const [isFilterAlertsActive, setIsFilterAlertsActive] = useState(false);

  const [isFilterLogsActive, setIsFilterLogsActive] = useState(false);
  const [isFilterOthersActive, setIsFilterOthersActive] = useState(false);

  useEffect(() => {
    if (
      !isFilterSingalsActive &&
      !isFilterAlertsActive &&
      !isFilterLogsActive &&
      !isFilterOthersActive
    ) {
      setFilteredList(auditLogs);
      return;
    }

    const filteredData = auditLogs?.filter(
      event =>
        (event.event_type === EventType.CONNECTION_ALERT &&
          isFilterSingalsActive) ||
        // @ts-ignore
        (event?.event?.details?.service_type ===
          Service_type.FilterAhuReplacement &&
          isFilterLogsActive) ||
        // @ts-ignore
        (event?.event?.details?.service_type ===
          Service_type.FilterKettleReplacement &&
          isFilterLogsActive) ||
        // @ts-ignore
        (event?.event?.details?.service_type ===
          Service_type.PaintBoothReplacement &&
          isFilterLogsActive) ||
        // @ts-ignore
        (event?.event?.details?.service_type ===
          Service_type.AhuOtherReplacement &&
          isFilterOthersActive) ||
        // @ts-ignore
        (event?.event?.details?.service_type ===
          Service_type.KettleOtherReplacement &&
          isFilterOthersActive) ||
        // @ts-ignore
        (event?.event?.details?.service_type ===
          Service_type.PaintBoohtOtherReplacement &&
          isFilterOthersActive) ||
        (event.event_type === EventType.THRESHOLD_ALERT && isFilterAlertsActive)
    );

    setFilteredList(filteredData);
  }, [
    auditLogs,
    isFilterSingalsActive,
    isFilterAlertsActive,
    isFilterLogsActive,
    isFilterOthersActive
  ]);

  const handleAlertFilter = () => {
    setIsFilterAlertsActive(!isFilterAlertsActive);
  };

  const handleSingnalsFilter = () => {
    setIsFilterSingnalsActive(!isFilterSingalsActive);
  };
  const handleLogsFilter = () => {
    setIsFilterLogsActive(!isFilterLogsActive);
  };

  const handleOtherLogsFilter = () => {
    setIsFilterOthersActive(!isFilterOthersActive);
  };
  const handleClearAll = () => {
    setIsFilterSingnalsActive(false);
    setIsFilterAlertsActive(false);
    setIsFilterLogsActive(false);
    setIsFilterOthersActive(false);
  };

  return (
    <div>
      <FormGroup className={classes.filters}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isFilterSingalsActive}
              onClick={handleSingnalsFilter}
            />
          }
          label="Signals updates"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isFilterAlertsActive}
              onClick={handleAlertFilter}
            />
          }
          label="Alert updates"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isFilterLogsActive}
              onClick={handleLogsFilter}
            />
          }
          label="Service logs-Filter Replacements"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isFilterOthersActive}
              onClick={handleOtherLogsFilter}
            />
          }
          label="Service logs-Other"
        />
        <Button onClick={handleClearAll}>Clean all filters</Button>
      </FormGroup>
    </div>
  );
};

export default ActivityFilters;
