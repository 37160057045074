import { Button, Container, Stack, Typography, alpha } from "@mui/material";
import moment from "moment";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { handleFilterStageModal, setStageToEdit } from "src/store/hvac/reducer";
import { Stage } from "src/utils/client";
import KeyValueRow from "../../../../../../components/KeyValueRow";
import FilterLoading from "./filter-loading.component";

interface FilterDetailsProps {
  stage?: Stage;
  lastConnection?: string;
  terminalPressure?: number;
  setIsDrawerOpen?: any;
}

export function checkTheValue(val: string | number | undefined, unit = "") {
  return val ? `${val} ${unit}` : "---";
}

const FilterDetails = ({
  stage,
  lastConnection,
  terminalPressure,
  setIsDrawerOpen
}: FilterDetailsProps) => {
  const dispatch = useAppDispatch();

  const size = { xl: "0.75rem", lg: "0.7rem", md: "0.8rem" };
  const dimensionExist: boolean = !!stage?.height || !!stage?.depth;
  const replaceDate = stage?.filter?.replaced_date
    ? moment(stage?.filter?.replaced_date).format("lll")
    : "---";

  const onEdit = () => {
    dispatch(setStageToEdit(stage));
    dispatch(handleFilterStageModal(true));
  };

  return (
    <Container style={{ padding: 0 }}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        py={"4px"}>
        <Typography
          color={alpha("#000000", 0.75)}
          fontSize={size}>
          Filter Loading
        </Typography>
        <Typography
          sx={styles.editButton}
          onClick={onEdit}>
          Edit
        </Typography>
      </Stack>

      <FilterLoading />

      <KeyValueRow
        text="Last Connection"
        value={checkTheValue(lastConnection)}
      />

      <KeyValueRow
        text="Remaining Filter Service Life"
        value={checkTheValue(stage?.filter?.expected_lifetime_months, "months")}
      />

      <KeyValueRow
        text="Expected Terminal Pressure"
        value={checkTheValue(terminalPressure)}
      />

      <KeyValueRow
        text="Replaced Date"
        value={replaceDate}
      />

      <KeyValueRow
        text="Filter Rating"
        value={checkTheValue(stage?.filter?.filter_rating, "MERV")}
      />

      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        px={"2px"}
        py={"4px"}>
        <Typography
          color={alpha("#000000", 0.75)}
          fontSize={size}>
          Dimensions
        </Typography>
        <Stack direction={"row"}>
          {dimensionExist ? (
            <>
              <Typography
                whiteSpace={"pre"}
                color={alpha("#000000", 0.75)}
                fontSize={size}>
                Height
              </Typography>
              <Typography
                whiteSpace={"pre"}
                fontWeight={500}
                fontSize={size}>
                {stage?.height ? ` ${stage?.height} in ` : " --- "}
              </Typography>
              <Typography
                whiteSpace={"pre"}
                fontSize={size}
                color={alpha("#000000", 0.75)}>
                Depth
              </Typography>
              <Typography
                whiteSpace={"pre"}
                fontWeight={500}
                fontSize={size}>
                {stage?.depth ? ` ${stage.depth} in` : " ---"}
              </Typography>
            </>
          ) : (
            <Typography
              fontWeight={500}
              fontSize={size}>
              ---
            </Typography>
          )}
        </Stack>
      </Stack>

      <KeyValueRow
        text="Number of Filters"
        value={checkTheValue(stage?.filter?.number_of_filters)}
      />

      <Stack mt={1}>
        <Button
          variant="contained"
          onClick={() => setIsDrawerOpen(true)}>
          Calculate Total Cost of Ownership
        </Button>
      </Stack>
    </Container>
  );
};

const styles = {
  editButton: {
    fontWeight: "500",
    color: "#0795BB",
    cursor: "pointer",
    ":hover": {
      textDecoration: "underline"
    }
  }
};

export default FilterDetails;
