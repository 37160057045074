/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

// IMPORTANT NOTE: when the client is generated this files replaces request.ts
// the request.ts file is used to connect the services
// so, when changes are done here those changes needs to be replicated in request.ts

import axios from "axios";
import FormData from "form-data";
import get from "lodash/get";
import config from "src/utils/config";
const globalConfig = config;
import toast from "react-hot-toast";
import { ApiError } from "src/utils/client/core/ApiError";
import { CancelablePromise } from "src/utils/client/core/CancelablePromise";

import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type { ApiRequestOptions } from "src/utils/client/core/ApiRequestOptions";
import type { ApiResult } from "src/utils/client/core/ApiResult";
import type { OnCancel } from "src/utils/client/core/CancelablePromise";
import type { OpenAPIConfig } from "src/utils/client/core/OpenAPI";

const unauthorizedStatuses = [401, 403];

const customAxios = axios.create({
  withCredentials: true,
  baseURL: config.apiUrl + config.apiStage,
  headers: {
    "Content-Type": "application/json"
  },
  paramsSerializer: params => {
    let result = "";
    Object.keys(params).forEach(key => {
      result += `${key}=${encodeURIComponent(params[key])}&`;
    });
    return result.substr(0, result.length - 1);
  }
});

export const APIv2 = customAxios;

const reqInterceptor = config => {
  const cancelTokenSource = axios.CancelToken.source();
  config.params = config.params || {};
  config.cancelToken = cancelTokenSource.token;

  /*let token = window.localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = token;
  }*/

  let accesstoken = window.localStorage.getItem("token");
  if (accesstoken) {
    config.headers.authorization = `Bearer ${accesstoken}`;
  }
  return config;
};

const resInterceptor = error => {
  if (
    error &&
    error.response &&
    (unauthorizedStatuses.includes(error.response.status) ||
      unauthorizedStatuses.includes(error.status))
  ) {
    // cookies.remove("loginTokenCookie", {
    //   path: "/",
    //   domain: config.cookiesDomain
    // });
    // cookies.remove("idTokenCookie", {
    //   path: "/",
    //   domain: config.cookiesDomain
    // });
    // cookies.remove("refreshTokenCookie", {
    //   path: "/",
    //   domain: config.cookiesDomain
    // });
    // cookies.remove("messagesUtk", { path: "/", domain: config.cookiesDomain });
    // localStorage.clear();
    // store.dispatch(setDashboardProp({ prop: "noToken", value: true }));
    //location.reload();
  }
  if (error) {
    let err = get(
      error,
      "response.data.message",
      get(error, "response.data.detail[0].msg", "Error in the API call")
    );

    if (error.response && error.response.status !== 500) {
      if (err.type && err.type === "application/json") {
        var reader = new FileReader();
        reader.onload = function () {
          toast.error(String(reader.result));
        };
        reader.readAsText(err);
      } else {
        toast.error(err);
      }
    }

    throw error;
  }
  return error;
};

customAxios.interceptors.request.use(reqInterceptor);
customAxios.interceptors.response.use(null, resInterceptor);

export const testPort = {
  reqInterceptor,
  resInterceptor
};

const isDefined = <T>(
  value: T | null | undefined
): value is Exclude<T, null | undefined> => {
  return value !== undefined && value !== null;
};

const isString = (value: any): value is string => {
  return typeof value === "string";
};

const isStringWithValue = (value: any): value is string => {
  return isString(value) && value !== "";
};

const isBlob = (value: any): value is Blob => {
  return (
    typeof value === "object" &&
    typeof value.type === "string" &&
    typeof value.stream === "function" &&
    typeof value.arrayBuffer === "function" &&
    typeof value.constructor === "function" &&
    typeof value.constructor.name === "string" &&
    /^(Blob|File)$/.test(value.constructor.name) &&
    /^(Blob|File)$/.test(value[Symbol.toStringTag])
  );
};

const isFormData = (value: any): value is FormData => {
  return value instanceof FormData;
};

const isSuccess = (status: number): boolean => {
  return status >= 200 && status < 300;
};

const getQueryString = (params: Record<string, any>): string => {
  const qs: string[] = [];

  const append = (key: string, value: any) => {
    qs.push(`${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);
  };

  const process = (key: string, value: any) => {
    if (isDefined(value)) {
      if (Array.isArray(value)) {
        value.forEach(v => {
          process(key, v);
        });
      } else if (typeof value === "object") {
        Object.entries(value).forEach(([k, v]) => {
          process(`${key}[${k}]`, v);
        });
      } else {
        append(key, value);
      }
    }
  };

  Object.entries(params).forEach(([key, value]) => {
    process(key, value);
  });

  if (qs.length > 0) {
    return `?${qs.join("&")}`;
  }

  return "";
};

const getUrl = (config: OpenAPIConfig, options: ApiRequestOptions): string => {
  const encoder = config.ENCODE_PATH || encodeURI;

  const path = options.url
    .replace("{api-version}", config.VERSION)
    .replace(/{(.*?)}/g, (substring: string, group: string) => {
      if (options.path?.hasOwnProperty(group)) {
        return encoder(String(options.path[group]));
      }
      return substring;
    });

  const url = `${globalConfig.apiUrl + globalConfig.apiStage}${path}`;

  if (options.query) {
    return `${url}${getQueryString(options.query)}`;
  }

  return url;
};

const getFormData = (options: ApiRequestOptions): FormData | undefined => {
  if (options.formData) {
    const formData = new FormData();

    const process = (key: string, value: any) => {
      if (isString(value) || isBlob(value)) {
        formData.append(key, value);
      } else {
        formData.append(key, JSON.stringify(value));
      }
    };

    Object.entries(options.formData)
      .filter(([_, value]) => isDefined(value))
      .forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach(v => process(key, v));
        } else {
          process(key, value);
        }
      });

    return formData;
  }
  return undefined;
};

type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;

const resolve = async <T>(
  options: ApiRequestOptions,
  resolver?: T | Resolver<T>
): Promise<T | undefined> => {
  if (typeof resolver === "function") {
    return (resolver as Resolver<T>)(options);
  }
  return resolver;
};

const getHeaders = async (
  config: OpenAPIConfig,
  options: ApiRequestOptions,
  formData?: FormData
): Promise<Record<string, string>> => {
  const token = await resolve(options, config.TOKEN);
  const additionalHeaders = await resolve(options, config.HEADERS);
  const formHeaders =
    (typeof formData?.getHeaders === "function" && formData?.getHeaders()) ||
    {};

  const headers = Object.entries({
    Accept: "application/json",
    ...additionalHeaders,
    ...options.headers,
    ...formHeaders
  })
    .filter(([_, value]) => isDefined(value))
    .reduce(
      (headers, [key, value]) => ({
        ...headers,
        [key]: String(value)
      }),
      {} as Record<string, string>
    );

  if (isStringWithValue(token)) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  if (options.body) {
    if (options.mediaType) {
      headers["Content-Type"] = options.mediaType;
    } else if (isBlob(options.body)) {
      headers["Content-Type"] = options.body.type || "application/octet-stream";
    } else if (isString(options.body)) {
      headers["Content-Type"] = "text/plain";
    } else if (!isFormData(options.body)) {
      headers["Content-Type"] = "application/json";
    }
  }

  return headers;
};

const getRequestBody = (options: ApiRequestOptions): any => {
  if (options.body) {
    return options.body;
  }
  return undefined;
};

const sendRequest = async <T>(
  config: OpenAPIConfig,
  options: ApiRequestOptions,
  url: string,
  body: any,
  formData: FormData | undefined,
  headers: Record<string, string>,
  onCancel: OnCancel
): Promise<AxiosResponse<T>> => {
  const source = axios.CancelToken.source();

  const requestConfig: AxiosRequestConfig = {
    url,
    headers,
    data: body ?? formData,
    method: options.method,
    withCredentials: config.WITH_CREDENTIALS,
    cancelToken: source.token
  };

  onCancel(() => source.cancel("The user aborted a request."));

  try {
    return await customAxios.request(requestConfig);
  } catch (error) {
    const axiosError = error as AxiosError<T>;
    if (axiosError.response) {
      return axiosError.response;
    }
    throw error;
  }
};

const getResponseHeader = (
  response: AxiosResponse<any>,
  responseHeader?: string
): string | undefined => {
  if (responseHeader) {
    const content = response.headers[responseHeader];
    if (isString(content)) {
      return content;
    }
  }
  return undefined;
};

const getResponseBody = (response: AxiosResponse<any>): any => {
  if (response.status !== 204) {
    return response.data;
  }
  return undefined;
};

const catchErrorCodes = (
  options: ApiRequestOptions,
  result: ApiResult
): void => {
  const errors: Record<number, string> = {
    400: "Bad Request",
    401: "Unauthorized",
    403: "Forbidden",
    404: "Not Found",
    500: "Internal Server Error",
    502: "Bad Gateway",
    503: "Service Unavailable",
    ...options.errors
  };

  const error = errors[result.status];
  if (error) {
    throw new ApiError(options, result, error);
  }

  if (!result.ok) {
    throw new ApiError(options, result, "Generic Error");
  }
};

/**
 * Request method
 * @param config The OpenAPI configuration object
 * @param options The request options from the service
 * @returns CancelablePromise<T>
 * @throws ApiError
 */
export const request = <T>(
  config: OpenAPIConfig,
  options: ApiRequestOptions
): CancelablePromise<T> => {
  return new CancelablePromise(async (resolve, reject, onCancel) => {
    try {
      const url = getUrl(config, options);
      const formData = getFormData(options);
      const body = getRequestBody(options);
      const headers = await getHeaders(config, options, formData);

      if (!onCancel.isCancelled) {
        const response = await sendRequest<T>(
          config,
          options,
          url,
          body,
          formData,
          headers,
          onCancel
        );
        const responseBody = getResponseBody(response);
        const responseHeader = getResponseHeader(
          response,
          options.responseHeader
        );

        const result: ApiResult = {
          url,
          ok: isSuccess(response.status),
          status: response.status,
          statusText: response.statusText,
          body: responseHeader ?? responseBody
        };

        catchErrorCodes(options, result);

        resolve(result.body);
      }
    } catch (error) {
      reject(error);
    }
  });
};
