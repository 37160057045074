import { apiService } from "../api";
import {
  ServiceLog,
  ServiceLogNew,
  ServiceLogUpdates,
  ServiceLogReturn,
  ServiceLogsService
} from "../../../utils/client";
import { sortServiceLogs } from "src/utils/widgets";

export const serviceLogsService = apiService
  .enhanceEndpoints({ addTagTypes: ["SERVICELOGRANGE"] })
  .injectEndpoints({
    endpoints: build => ({
      getServiceLog: build.query<
        ServiceLogReturn[],
        {
          buildingId: string;
          ahuId: string;
          startDate: string;
          endDate: string;
          kettleId?: string;
          paintBoothId?: string;
        }
      >({
        queryFn: async args => {
          const {
            buildingId,
            ahuId,
            startDate,
            endDate,
            kettleId,
            paintBoothId
          } = args;
          const serviceLogRange = await ServiceLogsService.getServiceLogRange(
            buildingId,
            startDate,
            endDate,
            ahuId,
            kettleId,
            paintBoothId
          );

          return { data: sortServiceLogs(serviceLogRange ?? []) };
        },
        providesTags: (_result, _error, { ahuId }) => [
          { type: "SERVICELOGRANGE", ahuId }
        ],
        // @ts-expect-error
        invalidatesTags: ["EVENTDATA"]
      }),
      createServiceLog: build.mutation<ServiceLog, ServiceLogNew>({
        //@ts-ignore
        queryFn: async newServiceLog => {
          const serviceLog = await ServiceLogsService.createServiceLog(
            newServiceLog.building_id,
            newServiceLog
          );
          return serviceLog;
        },
        // @ts-expect-error
        invalidatesTags: ["SERVICELOGRANGE", "EVENTDATA"]
      }),
      updateServiceLog: build.mutation<
        ServiceLog,
        {
          buildingId: string;
          serviceLogId: string;
          body: ServiceLogUpdates;
        }
      >({
        //@ts-ignore
        queryFn: async updatedServiceLog => {
          const serviceLog = await ServiceLogsService.updateServiceLog(
            updatedServiceLog.buildingId,
            updatedServiceLog.serviceLogId,
            updatedServiceLog.body
          );
          return serviceLog;
        },
        //@ts-expect-error
        invalidatesTags: ["SERVICELOGRANGE", "EVENTDATA"]
      }),
      deleteServiceLog: build.mutation<
        any,
        {
          buildingId: string;
          serviceLogId: string;
          ahuId?: string;
          stageId?: string;
          kettleId?: string;
          boothId?: string;
        }
      >({
        // @ts-ignore
        queryFn: async args => {
          const {
            buildingId,
            serviceLogId,
            ahuId,
            stageId,
            kettleId,
            boothId
          } = args;
          return ServiceLogsService.deleteServiceLog(
            buildingId,
            serviceLogId,
            ahuId,
            stageId,
            kettleId,
            boothId
          );
        },
        //@ts-expect-error
        invalidatesTags: ["SERVICELOGRANGE", "EVENTDATA"]
      })
    })
  });

export const {
  useGetServiceLogQuery,
  useCreateServiceLogMutation,
  useUpdateServiceLogMutation,
  useDeleteServiceLogMutation
} = serviceLogsService;
