import {
  HAZARDOUS,
  UNHEALTHY,
  SENSITIVE,
  MODERATE,
  GOOD,
  EXCELLENT,
  DEFAULT,
  VERY_UNHEALTHY
} from "src/shared/constants/building-status";
import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(theme => {
  if (!theme.palette.status) {
    theme.palette.status = {
      Excellent: "rgb(21, 142, 72)",
      Good: "rgb(106, 222, 89)",
      Moderate: "rgb(244, 200, 58)",
      "Unhealthy for sensitive groups": "rgb(253, 146, 49)",
      Unhealthy: "rgb(232, 37, 71)",
      Hazardous: "rgb(188, 8, 37)",
      "Very unhealthy": "rgb(188, 8 ,37)",
      Default: "rgb(125, 125, 125)"
    };
  }
  return {
    root: {
      padding: 16,
      background: "rgb(243, 246, 255)"
    },
    loader: {
      backgroundColor: "transparent"
    },
    info: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "0.25em"
    },
    title: {
      marginBottom: "1em",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& div": {
        display: "flex",
        alignItems: "center"
      }
    },
    titleG: {
      padding: "16px 16px 0 16px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "@media (max-width: 1200px)": {
        display: "block"
      }
    },
    navButton: {
      "@media (max-width: 400px)": {
        "& button": { display: "contents !important" }
      }
    },
    dateFilters: {
      flex: 1,
      display: "flex",
      justifyContent: "flex-end"
    },
    datePicker: {
      width: 155,
      marginLeft: "1em"
    },
    average: {
      padding: 16,
      display: "flex",
      flexDirection: "column" as const,
      alignItems: "center"
    },
    metrics: {
      display: "flex",
      marginBottom: "1em"
    },
    metric: {
      paddingRight: "0.5em",
      paddingLeft: "0.5em",
      marginRight: "1em",
      border: `1px solid ${theme.palette.grey["300"]}`
    },
    avg: {
      fontWeight: "bold" as const,
      fontSize: "2.5em"
    },
    avgStatus: {
      fontWeight: "bold" as const
    },
    chartContainer: {
      marginBottom: "1em"
    },
    loadingRecommendation: {
      height: "100%",
      width: "100%",
      top: 0,
      left: 0,
      position: "absolute" as const
    },
    prevBtn: {
      marginRight: "0.5em"
    },
    card: {
      padding: 16,
      marginBottom: 16,
      position: "relative" as const
    },
    side: {
      height: "calc(100vh - 130px)",
      display: "flex",
      flexDirection: "column" as const,
      "@media (max-width: 900px)": {
        height: "auto"
      }
    },
    pollutants: {
      flex: "1",
      overflow: "auto",
      "@media (max-width: 900px)": {
        display: "flex",
        flex: "none"
      }
    },
    canvasContainer: {
      height: "500px !important",
      position: "relative"
    },
    rangePicker: {
      top: 55,
      position: "absolute" as const,
      "& div > div > div > ul > .MuiListItem-root:nth-last-of-type(1)": {
        display: "none"
      },
      "& div > div > div > ul > .MuiListItem-root:nth-last-of-type(2)": {
        display: "none"
      },
      right: 16,
      "@media (max-width: 1200px)": {
        right: "auto",
        left: -300
      },
      "@media (max-width: 900px)": {
        right: "auto",
        left: -190,
        "& div > div > div > ul": {
          display: "none"
        }
      },
      "@media (max-width: 657px)": {
        right: -16,
        left: "auto",
        "& div > div > div > div": {
          flexDirection: "column"
        },
        "& div > div > div > div > *": {
          flexDirection: "row"
        }
      },
      "@media (max-width: 400px)": {
        right: -16,
        "& div > div > div > div": {
          flexDirection: "column"
        },
        "& div > div > div > div > *": {
          flexDirection: "row"
        }
      }
    },
    header: {
      display: "flex",
      justifyContent: "space-between"
    },
    buildingNameTitle: {
      color: "grey",
      marginRight: "0.3em"
    },
    [`status${HAZARDOUS.replace(/ /g, "_")}`]: {
      color: theme.palette.status[HAZARDOUS]
    },
    [`status${UNHEALTHY.replace(/ /g, "_")}`]: {
      color: theme.palette.status[UNHEALTHY]
    },
    [`status${SENSITIVE.replace(/ /g, "_")}`]: {
      color: theme.palette.status[SENSITIVE]
    },
    [`status${MODERATE.replace(/ /g, "_")}`]: {
      color: theme.palette.status[MODERATE]
    },
    [`status${GOOD.replace(/ /g, "_")}`]: {
      color: theme.palette.status[GOOD]
    },
    [`status${EXCELLENT.replace(/ /g, "_")}`]: {
      color: theme.palette.status[EXCELLENT]
    },
    [`status${VERY_UNHEALTHY.replace(/ /g, "_")}`]: {
      color: theme.palette.status[VERY_UNHEALTHY]
    },
    [`status${DEFAULT}`]: {
      color: theme.palette.status[DEFAULT]
    }
  };
});

export default useStyles;
