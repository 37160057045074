import { useState, MouseEvent } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import * as AhuIcon from "src/assets/ahu-icon.png";
import AhuStagesAccordionComponent from "../ahu-stages-accordion/ahu-stages-accordion.component";
import {
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Card,
  CardActionArea,
  Box
} from "@mui/material";
import {
  handleAHUModal,
  setIsEditingAhu,
  setSelectedAHU
} from "src/store/hvac/reducer";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import useStyles from "./ahu-card.styles";
import { useNavigate } from "react-router-dom";

type Props = {
  ahu: any;
};

const AhuCardComponent = ({ ahu }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const openEdit = () => {
    dispatch(setIsEditingAhu(true));
    dispatch(handleAHUModal(true));
    dispatch(setSelectedAHU(ahu));
    setAnchorEl(null);
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Card className={classes.root}>
        <IconButton
          aria-controls={menuOpen ? "ahu-menu" : undefined}
          className={classes.actionsBtn}
          onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          disableScrollLock={true}
          id="ahu-menu"
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}>
          <MenuItem onClick={openEdit}>Edit</MenuItem>
        </Menu>
        <Box className={classes.statusIndicator} />
        <CardActionArea
          onClick={() => {
            dispatch(setSelectedAHU(ahu));

            navigate(`/buildings/hvac/${ahu.building_id}/${ahu.id}`);
          }}>
          <div
            className={classes.header}
            data-testid="ahu-card">
            <div className={classes.statusCircle}>
              <img
                src={AhuIcon.default}
                className={classes.previewIcon}
              />
            </div>
            <div className={classes.nameContainer}>
              <Typography
                variant="body1"
                className={classes.title}>
                {ahu.name}
              </Typography>
              <Typography
                variant="subtitle1"
                className={classes.title}
                sx={{ fontWeight: "400 !important" }}>
                Number of Stages {ahu.stages.length}
              </Typography>
            </div>
          </div>
        </CardActionArea>
        {ahu && ahu.stages && <AhuStagesAccordionComponent ahu={ahu} />}
      </Card>
    </>
  );
};

export default AhuCardComponent;
