import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Brightness5Icon from "@mui/icons-material/Brightness5";
import { IconButton } from "@mui/material";
import { makeStyles } from "tss-react/mui";

type Props = {
  onRemove?: any;
  onOpenSettings?: any;
  showConfigBtn?: boolean;
};

const WidgetActionsComponent = (props: Props) => {
  const { onRemove, onOpenSettings, showConfigBtn } = props;
  const { classes } = useStyles();
  return (
    <div
      data-testid="widget-actions"
      className={classes.root}>
      {showConfigBtn && (
        <IconButton
          data-testid="widget-actions-icon"
          className={classes.iconBtn}
          onClick={onOpenSettings}>
          <Brightness5Icon />
        </IconButton>
      )}
      <IconButton
        data-testid="widget-actions-icon"
        className={classes.iconBtn}
        onClick={onRemove}>
        <DeleteOutlineIcon />
      </IconButton>
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {
      position: "absolute",
      top: 0,
      zIndex: 2,
      right: 0
    },
    iconBtn: {
      zoom: 0.8
    }
  };
});

export default WidgetActionsComponent;
