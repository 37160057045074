import { makeStyles } from "tss-react/mui";
export const useStyles = makeStyles()(theme => {
  if (!theme.palette.pressureStatus) {
    theme.palette.pressureStatus = {
      too_low: "rgb(238,130,238)",
      good: "#008000",
      moderate: "#CCCC00",
      high: "#FFA500",
      very_high: "#FF0000",
      too_high: "#800081",
      default: "#a9a9a9"
    };
  }
  return {
    root: {
      position: "relative" as const,
      display: "flex",
      flexDirection: "column" as const,
      transition: "all 250ms",
      overflow: "hidden",
      marginTop: 10,
      marginRight: 10
    },
    title: {
      whiteSpace: "nowrap" as const,
      textOverflow: "ellipsis",
      overflow: "hidden",
      fontWeight: "500",
      flex: 1
    },
    installation: {
      textAlign: "center" as const,
      marginTop: "0.5em"
    },
    info: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "0.25em"
    },
    qty: {
      fontSize: "16px"
    },
    header: {
      padding: "16px 16px 8px 16px",
      display: "flex",
      alignItems: "center"
    },
    statusCircle: {
      width: 32,
      height: 32,
      borderRadius: "50%",
      background: "grey",
      marginRight: "1em",
      overflow: "hidden"
    },
    previewIcon: {
      width: "100%",
      height: "100%"
    },
    actionsBtn: {
      position: "absolute",
      right: 8,
      top: 4,
      zIndex: 2
    },
    statusIndicator: {
      position: "absolute" as const,
      height: "100%",
      top: 0,
      left: 0,
      width: 8,
      background: theme.palette.pressureStatus.default,
      zIndex: 2
    },
    nameContainer: {
      width: "70%"
    }
  };
});

export default useStyles;
