import { makeStyles } from "tss-react/mui";

const RouteNoMatchComponent = () => {
  const { classes } = useStyles();

  return (
    <div
      className={classes.root}
      data-testid="no-match">
      <h1>Page not found</h1>
      <h4>The page you are looking for could not be found!</h4>
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column" as const
    }
  };
});

export default RouteNoMatchComponent;
