import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => {
  return {
    filters: {
      display: "flex",
      flexDirection: "row",
      padding: "0 0 1rem 2rem",
      borderBottom: "2px solid rgba(0,0,0,0.1)"
    }
  };
});
