/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AggGranularity } from '../models/AggGranularity';
import type { AggItemAndScores } from '../models/AggItemAndScores';
import type { IndexAndCategory } from '../models/IndexAndCategory';
import type { OutdoorAqData } from '../models/OutdoorAqData';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class OutdoorAqDataService {
  /**
   * Get Outdoor Aq Data
   * Returns aggregated aq data for device for given range.
   * If `end_ts` is `None`, current time is used.
   *
   * `start_ts` and `end_ts` can be given as Unix timestamps integers
   * or floats in seconds or milliseconds,  or as isoformat strings.
   * ```
   * Examples:
   * 1677827157                       (seconds)
   * 1677827157.5451648               (seconds)
   * 1677827157545                    (milliseconds)
   * 2023-03-03T07:05:57.545165+00:00 (isoformat)
   * 2023-03-03T02:05:57.545165+05:00 (isoformat)
   * ```
   * All of the above generate the same timestamp in the backend
   * (except where minor place values were omitted).
   * @param buildingId
   * @param startTs
   * @param endTs
   * @param granularity
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getOutdoorAqData(
    buildingId: string,
    startTs: string,
    endTs?: (string | null),
    granularity?: AggGranularity,
  ): CancelablePromise<(Array<AggItemAndScores> | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/outdoor-aq-data/',
      path: {
        'building_id': buildingId,
      },
      query: {
        'start_ts': startTs,
        'end_ts': endTs,
        'granularity': granularity,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Raw Outdoor Aq Data
   * Returns raw outdoor aq data for a building for a given time range.
   * If `add_aqi` is True, the Total Aqi of the outdoor data is added to the data dictionary.
   *
   * If `end_ts` is `None`, current time is used.
   *
   * `start_ts` and `end_ts` can be given as Unix timestamps integers
   * or floats in seconds or milliseconds, or as isoformat strings.
   * ```
   * Examples:
   * 1677827157                       (seconds)
   * 1677827157.5451648               (seconds)
   * 1677827157545                    (milliseconds)
   * 2023-03-03T07:05:57.545165+00:00 (isoformat)
   * 2023-03-03T02:05:57.545165+05:00 (isoformat)
   * ```
   * All of the above generate the same timestamp in the backend
   * (except where minor place values were omitted).
   * @param buildingId
   * @param startTs
   * @param endTs
   * @param addAqi
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getRawOutdoorAqData(
    buildingId: string,
    startTs: string,
    endTs?: (string | null),
    addAqi: boolean = true,
  ): CancelablePromise<(Array<OutdoorAqData> | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/outdoor-aq-data/raw/',
      path: {
        'building_id': buildingId,
      },
      query: {
        'start_ts': startTs,
        'end_ts': endTs,
        'add_aqi': addAqi,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Latest Outdoor Aq Data Point
   * @param buildingId
   * @param addPollutatantIndexes
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getLatestOutdoorAqDataPoint(
    buildingId: string,
    addPollutatantIndexes: boolean = true,
  ): CancelablePromise<(OutdoorAqData | Record<string, (OutdoorAqData | Record<string, IndexAndCategory>)> | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/outdoor-aq-data/latest/',
      path: {
        'building_id': buildingId,
      },
      query: {
        'add_pollutatant_indexes': addPollutatantIndexes,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
