import { useState } from "react";
import moment from "moment";
import ModalComponent from "src/shared/components/modal/modal.component";
import { Button, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { makeStyles } from "tss-react/mui";
import { renderWeekPickerDay } from "src/utils/date-picker.utils";

type Props = {
  open?: boolean;
  onClose?: any;
};

const AlertsDatesModalComponent = (props: Props) => {
  const { open, onClose } = props;
  const { classes } = useStyles();
  const [endDate, setEndDate] = useState(moment().toDate());
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "week").toDate()
  );

  const loadAlerts = () => {
    onClose();
  };

  return (
    <ModalComponent
      open={open}
      onClose={onClose}>
      <div
        data-testid="alerts-dates-modal"
        className={classes.root}>
        <Typography
          variant="h6"
          gutterBottom>
          Date range
        </Typography>
        <div>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(newValue: Date) => {
              setStartDate(newValue);
            }}
            renderInput={params => <TextField {...params} />}
            renderDay={renderWeekPickerDay}
          />
          <div></div>

          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(newValue: Date) => {
              setEndDate(newValue);
            }}
            renderInput={params => <TextField {...params} />}
            renderDay={renderWeekPickerDay}
          />
        </div>
        <div className={classes.actions}>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            data-testid="apply-button"
            variant="contained"
            onClick={loadAlerts}>
            Apply
          </Button>
        </div>
      </div>
    </ModalComponent>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {},
    actions: {
      marginTop: "1em",
      display: "flex",
      justifyContent: "end",
      "& button": {
        marginLeft: "1em"
      }
    }
  };
});

export default AlertsDatesModalComponent;
