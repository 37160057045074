import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import PressureLabel from "../../sections/hvac/air-handling-units/ahu-details/ahu-information/pressure-label.component";
import { getStageBackground } from "../../sections/hvac/air-handling-units/ahu-details/utils/accordion-background";
import { Stage } from "../../utils/client";

interface AhuAccordionCardProps {
  stage: Stage;
  index?: number;
  expandedStageId: string;
  currentPressure: string;
}

export default function AhuAccordionCard({
  stage,
  index,
  expandedStageId,
  currentPressure
}: AhuAccordionCardProps) {
  const bgColor = getStageBackground(expandedStageId, stage.id, index);

  return (
    <Stack
      width={1}
      direction={"row"}
      justifyContent={"space-between"}>
      <Stack
        width={0.75}
        direction={"row"}>
        <Box
          data-testId="icon"
          sx={styles.icon(expandedStageId === stage.id)}>
          <ExpandMoreIcon />
        </Box>
        <Stack
          justifyContent={"space-between"}
          pb={1}>
          <Tooltip
            title={stage.name}
            placement="top">
            <Typography sx={styles.name}>{stage.name}</Typography>
          </Tooltip>
          <Box
            sx={styles.line}
            bgcolor={bgColor}
          />
        </Stack>
      </Stack>
      <Stack
        px={"10px"}
        pt={"4px"}>
        <PressureLabel currentPressure={currentPressure} />
      </Stack>
    </Stack>
  );
}

const styles = {
  icon: (expanded: boolean) => ({
    my: "auto",
    mr: "2px",
    transform: `rotate(${expanded ? "180" : 0}deg)`,
    transition: "transform 0.2s ease-in-out"
  }),
  line: {
    width: 0.9,
    height: "0.3rem",
    borderRadius: "1rem"
  },
  name: {
    fontWeight: "500",
    flexWrap: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    height: "40%"
  }
};
