import ModalComponent from "src/shared/components/modal/modal.component";
import { Box, Typography } from "@mui/material";
import { widgets } from "../../widgets/widgets-config";
import useOverview from "src/hooks/useOverview";
import { IWidget } from "../../view/types";
import { getWidgetName, getWidgetType } from "./utils";

interface IConfigurationModal {
  widgetToConfigure: IWidget;
  handleOnSave: (newWidgetConfig: any) => void;
}

const ConfigurationModal = ({
  widgetToConfigure,
  handleOnSave
}: IConfigurationModal) => {
  const { isConfigurationModalOpen, closeConfigurationModal } = useOverview();

  const defaultWidgetSettingsConfig =
    widgets[getWidgetType(widgetToConfigure?.type)];

  const WidgetConfigurationComponent =
    defaultWidgetSettingsConfig?.settingsComponent;

  const widgetName = getWidgetName(widgetToConfigure?.props?.widgetName);

  return (
    <ModalComponent
      hideClose={false}
      open={isConfigurationModalOpen}
      onClose={closeConfigurationModal}>
      <Box display="div">
        <Box display="div">
          <Typography
            data-testid="widget-configuration"
            gutterBottom
            variant="h6">
            {"Widget Configuration"}
          </Typography>
          <Typography
            data-testid="widget-name"
            gutterBottom>
            {widgetName}
          </Typography>
        </Box>

        <Box display="div">
          <WidgetConfigurationComponent
            currentProps={widgetToConfigure.props}
            onSave={handleOnSave}
          />
        </Box>
      </Box>
    </ModalComponent>
  );
};

export default ConfigurationModal;
