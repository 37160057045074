import { Box, Container, Grid, Paper, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import useUserPascalSettings from "src/hooks/useUserPascalSettings";
import LoaderComponent from "src/shared/components/loader/loader.component";
import { setSelectedAHU } from "src/store/hvac/reducer";
import {
  getSelectedAhu,
  getSelectedAhuDetailsStageId
} from "src/store/hvac/selectors";
import { StageAndFilterload } from "src/utils/client";
import {
  convertFromIWGToPascals,
  getAhuPressureLineChartData
} from "src/utils/pressure.utils";
import DetailsHeader from "../../../../../components/DetailsHeader";
import CostOfOwnership from "../../../../../components/AhuDetails/CostOfOwnership";
import DateChart from "../../../../../components/AhuDetails/DateChart.tsx";
import AddAhuStageComponent from "../../add-ahu-stage/add-ahu-stage.component";
import AhuInformation from "../ahu-information/ahu-information.component";
import ActivityLogs from "../hvac-activity-log/activity-log.component";
import useGetAhuData from "./hooks/useGetAhuData";
import { getCOOData, getTCOCurve } from "src/store/building-cost/selector";
import { useLazyCacluateTCOQuery } from "src/store/services/building-cost/building-cost.service";
import { setShowTCOCurve } from "src/store/building-cost/reducer";

export const AhuDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { buildingId, ahuId } = useParams();
  const COOData = useAppSelector(getCOOData);
  const showTCOCurve = useAppSelector(getTCOCurve);
  const [_trigger, TCOData] = useLazyCacluateTCOQuery();
  const ahu = useAppSelector(getSelectedAhu);
  const selectedStageId = useAppSelector(getSelectedAhuDetailsStageId);
  const isUserUsingPascals = useUserPascalSettings();

  const [chartData, setChartData] = useState([]);
  const [terminalPressure, setTerminalPressure] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activeAhuStage, setActiveAhuStage] =
    useState<StageAndFilterload | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const { stages, ahusInBuilding, filterload, serviceLogs, loading, fetching } =
    useGetAhuData({ buildingId, ahuId });

  const ahuExist = Boolean(ahu && Object.keys(ahu).length);
  const filterLoadExist = Boolean(filterload && Object.keys(filterload).length);
  const showLogData = true;
  const showDotsOnLine = true;

  useEffect(() => {
    if (!ahuExist || !filterLoadExist) {
      setChartData([]);
      return;
    }

    const chartData = getAhuPressureLineChartData(
      stages,
      filterload,
      isUserUsingPascals,
      showDotsOnLine,
      showLogData,
      serviceLogs,
      activeAhuStage
    );

    if (Boolean(selectedStageId)) {
      const activeAhuStage = chartData?.filter(
        ({ stageId }) => stageId === selectedStageId
      );

      const selectedStage = stages?.find(({ id }) => id === selectedStageId);
      const endPressure = selectedStage?.filter?.expected_end_pressure_iwg;

      setTerminalPressure(
        isUserUsingPascals ? convertFromIWGToPascals(endPressure) : endPressure
      );
      setChartData(activeAhuStage);
      return;
    }
    setTerminalPressure(0);
    setChartData(chartData);
  }, [
    stages,
    activeAhuStage?.id,
    filterload,
    ahuExist,
    loading,
    ahuId,
    selectedStageId
  ]);

  useEffect(() => {
    if (!Boolean(selectedStageId)) {
      setActiveAhuStage(null);
    } else {
      setActiveAhuStage(stages?.find(({ id }) => id === selectedStageId));
    }
  }, [selectedStageId]);

  const navigateToAhu = (next: boolean) => {
    const currentShownAHUIndex =
      ahusInBuilding?.findIndex(item => item.id == ahuId) ?? 0;

    let newIndex = next ? currentShownAHUIndex + 1 : currentShownAHUIndex - 1;
    if (newIndex === -1) {
      newIndex = ahusInBuilding.length - 1;
    }

    if (newIndex === ahusInBuilding.length) {
      newIndex = 0;
    }

    let nextAhu = ahusInBuilding[newIndex];
    let newUrl = `/buildings/hvac/${buildingId}/${nextAhu.id}`;
    dispatch(setSelectedAHU(Object.assign({}, nextAhu, { stages })));
    navigate(newUrl);
  };

  useEffect(() => {
    if (buildingId !== showTCOCurve?.buildingId) {
      dispatch(setShowTCOCurve({}));
    }
  }, [buildingId]);

  return (
    <Container
      maxWidth={false}
      sx={{ p: 2, pt: 0, bgcolor: "#f3f6ff" }}
      data-testid="ahu-details">
      {loading || fetching ? (
        <LoaderComponent />
      ) : (
        <>
          <DetailsHeader
            item={ahu}
            loading={loading}
            hasItems={ahusInBuilding?.length > 1}
            navigate={navigateToAhu}
          />
          <Paper sx={{ heigh: "100vh", padding: "10px" }}>
            <Grid
              container
              spacing={2}>
              <Grid
                item
                xs={12}
                sm={5}
                md={4}
                lg={3}
                xl={2.5}>
                <Box sx={styles.asideContainer}>
                  <AhuInformation
                    ahu={ahu}
                    stages={stages}
                    terminalPressure={terminalPressure}
                    setIsDrawerOpen={setIsDrawerOpen}
                  />
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                sm={7}
                md={8}
                lg={9}
                xl={9.5}>
                <Stack my={2}>
                  <DateChart
                    stages={stages}
                    chartData={chartData}
                    terminalPressure={terminalPressure}
                  />
                  <Box sx={styles.auditLogContainer}>
                    <ActivityLogs
                      open={open}
                      setOpen={setOpen}
                      stages={stages}
                      buildingId={buildingId}
                      ahu={ahu}
                    />
                  </Box>
                </Stack>
                <CostOfOwnership
                  open={isDrawerOpen}
                  TCOData={TCOData?.data ?? []}
                  COOData={COOData}
                  chartData={chartData}
                  showTCOCurve={showTCOCurve}
                  activeAhuStage={activeAhuStage}
                  setIsDrawerOpen={setIsDrawerOpen}
                />
              </Grid>
            </Grid>
          </Paper>
          <AddAhuStageComponent />
        </>
      )}
    </Container>
  );
};

export const styles = {
  auditLogContainer: {
    border: "2px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "1rem",
    ml: { md: "-1rem", sm: "0rem" }
  },
  asideContainer: {
    position: "sticky",
    top: 52,
    padding: "16px",
    marginLeft: "-1rem",
    display: "flex",
    justifyContent: "center",
    marginBottom: "16px",
    paddingRight: "4px",
    paddingTop: "32px"
  }
};

export default AhuDetails;
