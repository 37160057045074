import { useGetPollutantDictionaryQuery } from "src/store/services/general/general.service";
import useUserCelsiusSettings from "./useUserCelsiusSettings";
import { PollInfoReturn, Threshold } from "src/utils/client";
import { convertToFahrenheit } from "src/utils/units";

const useGetPolutantDictionary = () => {
  const {
    data: pollInfoDicts,
    isLoading,
    isFetching,
    isError
  } = useGetPollutantDictionaryQuery();

  return {
    pollInfoDict: updatedBasedOnUserPrefs(pollInfoDicts),
    isLoading,
    isFetching,
    isError
  };
};

export default useGetPolutantDictionary;

const updatedBasedOnUserPrefs = (
  pollInfoDicts: Record<string, PollInfoReturn>
) => {
  const isUserUsingCelsius = useUserCelsiusSettings();
  // TODO Refactor this after redux migration
  const temperature = pollInfoDicts?.t;
  const updatedTemperatureInfo = Object.assign({}, temperature, {
    min_value: isUserUsingCelsius
      ? temperature?.min_value
      : convertToFahrenheit(temperature?.min_value),
    max_value: isUserUsingCelsius
      ? temperature?.max_value
      : convertToFahrenheit(temperature?.max_value),
    statuses: {
      unit: isUserUsingCelsius ? "celcius" : "fahrenheit",
      thresholds: temperature?.statuses.thresholds.map((item: Threshold) => ({
        ...item,
        high: isUserUsingCelsius ? item?.high : convertToFahrenheit(item?.high),
        low: isUserUsingCelsius ? item?.low : convertToFahrenheit(item?.low)
      }))
    }
  });
  return Object.assign({}, pollInfoDicts, { t: updatedTemperatureInfo });
};
