import { Box, Stack, Typography } from "@mui/material";

interface KettleInfoProps {
  inletPSI: string;
  outletPSI: string;
  alertColor: string;
  differentialPSI: string;
}

const KettleInfo = ({
  inletPSI,
  outletPSI,
  alertColor,
  differentialPSI
}: KettleInfoProps) => {
  return (
    <>
      <Stack
        mt={"5px"}
        direction={"row"}
        alignItems={"center"}>
        <Typography>Inlet pressure </Typography>
        <Box style={styles.kettlePressure}>
          <Typography>{inletPSI} PSI</Typography>
        </Box>
      </Stack>
      <Stack
        mt={"5px"}
        direction={"row"}
        alignItems={"center"}>
        <Typography>Outlet pressure </Typography>
        <Box style={styles.kettlePressure}>
          <Typography>{outletPSI} PSI</Typography>
        </Box>
      </Stack>
      <Stack
        my={"5px"}
        direction={"row"}
        alignItems={"center"}>
        <Typography>Differential pressure</Typography>
        <Box style={{ ...styles.kettlePressure, borderColor: alertColor }}>
          <Typography style={{ color: alertColor }}>
            {differentialPSI} PSI
          </Typography>
        </Box>
      </Stack>
    </>
  );
};

const styles = {
  kettlePressure: {
    borderWidth: "2px",
    borderStyle: "solid",
    padding: "1px 10px",
    borderRadius: "15px",
    borderColor: "gray",
    marginLeft: "10px"
  }
};

export default KettleInfo;
