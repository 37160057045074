import CloseIcon from "@mui/icons-material/CloseRounded";
import { makeStyles } from "tss-react/mui";
import { Modal, ModalProps, Paper, Fade, IconButton } from "@mui/material";

type Props = {
  hideClose?: boolean;
};

const ModalComponent = (props: Props & ModalProps) => {
  const { children, onClose, open, hideClose } = props;
  const { classes } = useStyles();
  const propsCopy = { ...props };
  delete propsCopy.hideClose;

  return (
    <Modal
      data-testid="modal"
      closeAfterTransition
      {...propsCopy}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}>
      <Fade in={open}>
        <Paper
          className={classes.content}
          elevation={3}>
          {!hideClose && (
            <IconButton
              data-testid="modal-close-icon"
              className={classes.closeBtn}
              onClick={() => {
                onClose(null, "backdropClick");
              }}>
              <CloseIcon />
            </IconButton>
          )}
          {children}
        </Paper>
      </Fade>
    </Modal>
  );
};

export const useStyles = makeStyles()(theme => {
  return {
    content: {
      position: "absolute",
      width: "fit-content",
      minWidth: 300,
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${theme.palette.grey["500"]}`,
      padding: "1.5em 3em",
      overflow: "hidden",
      maxWidth: 1000,
      maxHeight: "100%",
      "@media (max-width: 425px)": {
        width: "100%",
        padding: "20px",
        height: "100vh",
        overflowY: "scroll"
      },
      borderRadius: theme.shape.borderRadius * 4
    },
    closeBtn: {
      width: 29,
      height: 29,
      borderRadius: "50%",
      position: "absolute" as const,
      top: 16,
      right: 16,
      cursor: "pointer",
      padding: 0,
      "& svg": {
        fontSize: "13pt",
        color: "black"
      }
    }
  };
});

export default ModalComponent;
