import { useState } from "react";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import InputAdornment from "@mui/material/InputAdornment";
import { Form, Field } from "react-final-form";
import { Box, Button, Typography, Link } from "@mui/material";
import CustomTextField from "src/shared/components/custom-input/custom-input.component";
import Wrapper from "../session-wrapper/session-wrapper.component";
import LoaderComponent from "src/shared/components/loader/loader.component";
import { LoginFormFields } from "src/shared/types/login.type";
import { login } from "/store/auth/actions";
import { getIsSigningIn } from "src/store/auth/selectors";
import { useAppSelector } from "src/hooks/useAppSelector";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { LoginInformation } from "src/store/auth/types";
import { useStyles } from "./login.style";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const isLoading = useAppSelector(getIsSigningIn);
  const [showPassword, setShowPassword] = useState(false);
  const validate = (values: LoginFormFields) => {
    const errors: LoginFormFields = {};
    const { username, password } = values;

    if (!username) {
      errors.username = "Required";
    }

    if (!password) {
      errors.password = "Required";
    }
    return errors;
  };

  const handleLogin = (userCreadential: LoginInformation) => {
    dispatch(login(userCreadential)).then(() => {
      navigate("/dashboard");
      toast.success("Welcome");
    });
  };

  return (
    <Wrapper>
      <>
        <Form
          onSubmit={handleLogin}
          validate={validate}
          render={({ handleSubmit, hasValidationErrors }) => (
            <form
              data-testid="login"
              className={classes.loginForm}
              onSubmit={handleSubmit}>
              {isLoading && <LoaderComponent />}

              <Box mb={5}>
                <Typography variant="h4">
                  IoT Dashboard <small className={classes.small}>v2.x</small>
                </Typography>
                Welcome back! Enter your account details below to view your
                latest device data:
              </Box>

              <div className={classes.fieldContainer}>
                <Field name="username">
                  {({ input, meta }) => (
                    <CustomTextField
                      label="Email or username"
                      error={Boolean(meta.touched && meta.error)}
                      helperText={meta.touched && meta.error}
                      InputProps={input}
                      placeholder="Enter your email"
                      onKeyDown={(event: any) => {
                        if (event.key === " ") {
                          event.preventDefault();
                        }
                      }}
                    />
                  )}
                </Field>
              </div>
              <div className={classes.fieldContainer}>
                <Field name="password">
                  {({ input, meta }) => (
                    <CustomTextField
                      label="Password"
                      placeholder="Enter your password"
                      error={Boolean(meta.touched && meta.error)}
                      helperText={meta.touched && meta.error}
                      InputProps={{
                        ...input,
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}>
                            {showPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </InputAdornment>
                        )
                      }}
                      type={showPassword ? "text" : "password"}
                      onKeyDown={(event: any) => {
                        if (event.key === " ") {
                          event.preventDefault();
                        }
                      }}
                    />
                  )}
                </Field>
              </div>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={hasValidationErrors || isLoading}
                data-testid="login-button">
                LOGIN
                <ArrowForwardRoundedIcon sx={{ marginLeft: 1, fontSize: 16 }} />
              </Button>

              <Box className={classes.forgotPassword}>
                <Link
                  onClick={() => {
                    navigate("/recover");
                  }}
                  data-testid="forgot-password-button">
                  <small>Reset Password?</small>
                </Link>
              </Box>
            </form>
          )}
        />
      </>
      <Box className={classes.getHelp}>
        <Typography
          variant="body1"
          className={classes.supportText}>
          Trouble logging in? <br></br> Get in touch with us:
        </Typography>
        <Link href="mailto:support@i-qlair.com?subject=Help With Account">
          QLAIR SUPPORT TEAM
        </Link>
      </Box>

      <Box className={classes.copyright}>
        <div>
          Copyright 2024 <br></br> qlair, A MANN+HUMMEL SOLUTION
        </div>
      </Box>
    </Wrapper>
  );
};

export default Login;
