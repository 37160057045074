import classNames from "classnames";
import { makeStyles } from "tss-react/mui";
import { alpha, Box, Paper, Typography, useTheme } from "@mui/material";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { getSelectedDeviceMetric } from "src/store/hvac/selectors";
import { useAppSelector } from "src/hooks/useAppSelector";
import { setSelectedDeviceMetric } from "src/store/hvac/reducer";

type Props = {
  metric: any;
  indoorValue?: number;
  outdoorValue?: number;
};

const DevicePollutantComponent = (props: Props) => {
  const { metric, indoorValue = 0, outdoorValue = 0 } = props;

  let metricStatus;
  let indoorColor;
  let outdoorColor;
  let metricStatusName = "status";
  const selectedMetric = useAppSelector(getSelectedDeviceMetric);
  const { classes } = useStyles();
  const isSelected = selectedMetric === metric?.name;
  let theme: any = useTheme();
  let dispatch: Dispatch = useDispatch();

  const handleOnCick = (name: string) => {
    dispatch(setSelectedDeviceMetric(selectedMetric === name ? null : name));
  };

  if (metric?.statuses?.thresholds) {
    if (metric.name === "h") {
      metricStatusName = "humidityStatus";
    }

    if (metric.name === "t") {
      metricStatusName = "temperatureStatus";
    }
  }

  if (metric.name === "mold_risk") {
    metric.statuses = {
      thresholds: [
        {
          color: "#6bde59",
          min: 0,
          max: 25
        },
        {
          color: "#fbc02d",
          min: 25,
          max: 50
        },
        {
          color: "#f57c00",
          min: 50,
          max: 75
        },
        {
          color: "#9f0000",
          min: 75,
          max: 100
        }
      ]
    };
  }
  // FIX: use the `getMetricStatus` util function that is used in
  // src/sections/indoor-air-quality/devices/device-details/view/device-details.component.tsx
  if (metric?.statuses?.thresholds) {
    const thresholds = metric.statuses.thresholds;
    for (let mStatus of thresholds) {
      let avg = indoorValue;
      if (avg >= mStatus.low && avg <= mStatus.high) {
        if (mStatus.status) {
          metricStatus = mStatus.status;
          indoorColor = theme.palette.status[mStatus.status];
        } else {
          indoorColor = theme["palette"][metricStatusName][mStatus.status];
        }
      }
    }
    // check if value exceeds the highest range
    if (!metricStatus) {
      const maxRange = thresholds[thresholds.length - 1];
      if (indoorValue > maxRange.high) {
        metricStatus = maxRange.status;
        indoorColor = theme.palette.status[metricStatus];
      }
    }

    for (let mStatus of metric?.statuses?.thresholds) {
      let avg = outdoorValue;
      if (avg >= mStatus.low && avg <= mStatus.high) {
        if (mStatus.status) {
          outdoorColor = theme.palette.status[mStatus.status];
        } else {
          outdoorColor = theme["palette"][metricStatusName][mStatus.status];
        }
      }
    }
    // check if value exceeds the highest range
    if (!outdoorColor) {
      const maxRange = thresholds[thresholds.length - 1];
      if (outdoorValue > maxRange.high) {
        outdoorColor = theme.palette.status[metricStatus];
      }
    }
  }

  let metricLabel = metric.short_name || "";

  if (!metricLabel.includes("(") && metric.unit) {
    let unit = metric.unit;
    if (unit === "ppb") {
      unit = "PPB";
    }
    if (unit === "ppm") {
      unit = "PPM";
    }
    metricLabel = `${metric.short_name} (${unit})`;
  }

  return (
    <Paper
      data-testid="device-pollutant"
      className={classNames(
        classes.pollutant,
        selectedMetric && isSelected ? classes.selected : null,
        selectedMetric && !isSelected ? classes.unselected : null
      )}
      onClick={() => handleOnCick(metric.name)}>
      <Box
        className={
          selectedMetric && !isSelected
            ? classes.hiddenBar
            : classes.barIndicator
        }
        sx={{ backgroundColor: metric.color + " !important" }}></Box>
      <div className={classes.name}>
        <Typography>{metricLabel}</Typography>
        {Boolean(indoorColor) && (
          <Box
            className={classNames(classes.statusIndicator)}
            sx={{
              color: indoorColor,
              backgroundColor: alpha(indoorColor || "#000000", 0.25)
            }}>
            <Typography variant="overline">{metricStatus}</Typography>
          </Box>
        )}
      </div>
      <div className={classes.qtyLine}>
        <Typography variant="overline">current indoor value</Typography>
        <Typography
          variant="h5"
          sx={{ color: indoorColor }}>
          {isNaN(indoorValue) ? "N/A" : indoorValue.toFixed(0)}
        </Typography>
      </div>
      {Boolean(outdoorValue) && (
        <div className={classes.qtyLine}>
          <Typography variant="overline">outdoor</Typography>
          <Typography
            variant="h5"
            sx={{ color: outdoorColor }}>
            {outdoorValue.toFixed(0)}
          </Typography>
        </div>
      )}
    </Paper>
  );
};

export const useStyles = makeStyles()(theme => {
  return {
    pollutant: {
      padding: "10px 16px 12px 24px",
      marginBottom: 8,
      overflow: "hidden",
      position: "relative" as const,
      minWidth: 250,
      "@media (max-width: 900px)": {
        width: 250,
        display: "inline-block",
        marginRight: 16
      }
    },
    selected: {
      boxShadow:
        "0px 11px 15px rgba(0, 0, 0, 0.05), 0px 9px 46px rgba(0, 0, 0, 0.03), 0px 24px 38px rgba(0, 0, 0, 0.04)",
      zIndex: 2
    },
    unselected: {},
    name: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "0.5em",
      alignItems: "center"
    },
    qtyLine: {
      display: "flex",
      justifyContent: "space-between",
      height: 25
    },
    statusIndicator: {
      height: 26,
      borderRadius: theme.shape.borderRadius,
      padding: "0px 8px",
      "& span": {
        fontWeight: 500,
        lineHeight: 1
      }
    },
    barIndicator: {
      position: "absolute" as const,
      left: 0,
      top: 0,
      height: "100%",
      width: 8,
      backgroundColor: "grey",
      transition: "all 250ms"
    },
    hiddenBar: {
      display: "hidden",
      transition: "all 250ms"
    }
  };
});

export default DevicePollutantComponent;
