import moment from "moment";
import { useAppSelector } from "src/hooks/useAppSelector";
import {
  getAhuChartGranularity,
  getAhuDetailsEndDate,
  getAhuDetailsStartDate
} from "src/store/hvac/selectors";
import {
  useGetAhusInBuildingQuery,
  useGetFilterLoadForAhuQuery
} from "src/store/services/buildings/buildings.service";
import { useGetAhuStagesQuery } from "src/store/services/hvac/hvac.service";
import { useGetServiceLogQuery } from "src/store/services/servicelogs/servicelogs.service";
type Props = {
  buildingId: string;
  ahuId: string;
};

const useGetAhuData = ({ buildingId, ahuId }: Props) => {
  const startDate = useAppSelector(getAhuDetailsStartDate);
  const endDate = useAppSelector(getAhuDetailsEndDate);
  const startDateUnix = moment(startDate).unix().toString();
  const endDateUnix = moment(endDate).endOf("day").unix().toString();
  const granularity = useAppSelector(getAhuChartGranularity);
  const {
    data: stages,
    isLoading: isLoadingStages,
    isFetching: isFetchingStages
  } = useGetAhuStagesQuery({
    buildingId,
    ahuId
  });
  const {
    data: ahusInBuilding,
    isLoading: isLoadingAhusInBuilding,
    isFetching: isFetchingAhusInBuilding
  } = useGetAhusInBuildingQuery(buildingId);

  const {
    data: filterload,
    isLoading: isLoadingFilterLoad,
    isFetching: isFetchingFilterLoad
  } = useGetFilterLoadForAhuQuery({
    buildingId,
    ahuId,
    startDate: startDateUnix,
    endDate: endDateUnix,
    granularity
  });

  const {
    data: serviceLogs,
    isLoading: isLoadingServiceLogs,
    isFetching: isFetchingServiceLogs
  } = useGetServiceLogQuery({
    buildingId,
    ahuId,
    startDate: startDateUnix,
    endDate: endDateUnix
  });
  const loading =
    isLoadingAhusInBuilding ||
    isLoadingFilterLoad ||
    isLoadingStages ||
    isLoadingServiceLogs;

  const fetching =
    isFetchingAhusInBuilding ||
    isFetchingFilterLoad ||
    isFetchingServiceLogs ||
    isFetchingStages;

  return { stages, ahusInBuilding, filterload, serviceLogs, loading, fetching };
};

export default useGetAhuData;
