import useGetUserPages from "src/hooks/useGetUserWidgets";
import { useState, useEffect } from "react";
import { WidgetsData } from "../view/types";
import { useParams } from "react-router";

const useGetWidgetsForPage = () => {
  const {
    pages,
    isLoading: isLoadingPages,
    isFetching: isFetchingPages,
    isError
  } = useGetUserPages();
  const { tabId } = useParams();
  const selectedPage = pages[tabId];
  const [pageWidgets, setPageWidgets] = useState<WidgetsData[]>([]);

  const isLoading = isLoadingPages;
  const isFetching = isFetchingPages;

  useEffect(() => {
    if (isLoading || isFetching) return;
    const allWidgetsOnPage = selectedPage?.widgets;
    setPageWidgets(allWidgetsOnPage);
  }, [isLoading, isFetching, tabId, pages]);

  return {
    pages,
    isLoading,
    isFetching,
    isError,
    widgets: pageWidgets,
    setWidgets: setPageWidgets,
    selectedPage: pages[tabId],
    pagesLength: pages?.length,
    paintBoothWidgets: pageWidgets?.filter(
      ({ type }) => type === "boothPressure" || type === "boothPressurePoint"
    )
  };
};

export default useGetWidgetsForPage;
