/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminResetPasswordRequestBody } from '../models/AdminResetPasswordRequestBody';
import type { CheckCodeRequestBody } from '../models/CheckCodeRequestBody';
import type { CreateNewUserRequestBody } from '../models/CreateNewUserRequestBody';
import type { ResetPasswordRequestBody } from '../models/ResetPasswordRequestBody';
import type { RespBody } from '../models/RespBody';
import type { SendCodeRequestBody } from '../models/SendCodeRequestBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AccountsService {
  /**
   * Send Email Verification Code
   * @param requestBody
   * @returns RespBody Successful Response
   * @throws ApiError
   */
  public static sendEmailVerificationCode(
    requestBody: SendCodeRequestBody,
  ): CancelablePromise<RespBody> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/create-account/send-verification-code/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        409: `Email Conflict`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Send Reset Password Verification Code
   * @param requestBody
   * @returns RespBody Successful Response
   * @throws ApiError
   */
  public static sendResetPasswordVerificationCode(
    requestBody: SendCodeRequestBody,
  ): CancelablePromise<RespBody> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/reset-password/send-verification-code/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Check Verification Code
   * @param requestBody
   * @returns RespBody Successful Response
   * @throws ApiError
   */
  public static checkVerificationCode(
    requestBody: CheckCodeRequestBody,
  ): CancelablePromise<RespBody> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/check-verification-code/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Create New User Account
   * @param requestBody
   * @returns RespBody Successful Response
   * @throws ApiError
   */
  public static createNewUserAccount(
    requestBody: CreateNewUserRequestBody,
  ): CancelablePromise<RespBody> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/create-account/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        409: `Email Conflict`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Reset Password
   * @param requestBody
   * @returns RespBody Successful Response
   * @throws ApiError
   */
  public static resetPassword(
    requestBody: ResetPasswordRequestBody,
  ): CancelablePromise<RespBody> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/reset-password/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Admin Reset Password
   * @param requestBody
   * @returns RespBody Successful Response
   * @throws ApiError
   */
  public static adminResetPassword(
    requestBody: AdminResetPasswordRequestBody,
  ): CancelablePromise<RespBody> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/admin-reset-password/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
