import { useGetLongTermAqDataQuery } from "src/store/services/air-quality/air-quality.service";
import moment from "moment";

const INDOOR_WEEKLY_KEY = "indoor_weekly";
const INDOOR_MONTHLY_KEY = "indoor_aqi_scale_monthly";

const useGetLongTermAQData = (buildingId: string, requiresConfig: boolean) => {
  const oneYearAgo = String(
    moment().subtract(12, "months").startOf("day").valueOf()
  );
  const {
    data: longTermData,
    isLoading,
    isError,
    isFetching,
    refetch
  } = useGetLongTermAqDataQuery(
    {
      buildingId,
      startDate: oneYearAgo
    },
    { skip: requiresConfig }
  );
  const longDataExist = Boolean(longTermData);

  return {
    indoorWeeklyData: longDataExist ? longTermData[INDOOR_WEEKLY_KEY] : null,
    indoorWeeklyAqiData: longDataExist
      ? longTermData["indoor_aqi_scale_weekly"]
      : null,
    indoorMonthlyAQIData: longDataExist
      ? longTermData[INDOOR_MONTHLY_KEY]
      : null,
    isLoading,
    isFetching,
    isError,
    refetch
  };
};

export default useGetLongTermAQData;
