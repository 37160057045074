enum LogType {
  ahu = "AHU",
  kettle = "Kettle",
  paintBooth = "PaintBooth"
}

export const getServiceLogType = (props: any) => {
  switch (props.serviceLogType) {
    case LogType.ahu:
      return LogType.ahu;
    case LogType.kettle:
      return LogType.kettle;
    case LogType.paintBooth:
      return LogType.paintBooth;
    default:
      break;
  }
  switch (true) {
    case props.ahuId !== undefined:
      return LogType.ahu;
    case props.kettleId !== undefined:
      return LogType.kettle;
    case props.paintBoothId !== undefined:
      return LogType.paintBooth;
    default:
      return LogType.ahu;
  }
};
