import { useState } from "react";
import { makeStyles } from "tss-react/mui";
import { WidgetSettings } from "../widgets-config";
import Error from "src/shared/components/Error/Error";
import { CircularProgress } from "@mui/material";
import useGetBuildings from "src/hooks/useGetBuildings";
import FormInput from "src/shared/components/form-input/form-input.component";
import FormAction from "src/shared/components/form-action/form-action";

const BuildingSettingsComponent = ({
  currentProps,
  onSave
}: WidgetSettings) => {
  const { classes } = useStyles();
  const [buildingId, setBuildingId] = useState<string>(
    currentProps.buildingId ?? ""
  );
  const { buildings, isLoading, isFetching, isError, refetch } =
    useGetBuildings();

  const handleOnSave = () => {
    onSave({
      ...currentProps,
      buildingId,
      building: buildings.find(({ id }) => id === buildingId)
    });
  };
  return (
    <div
      data-testid="building-settings"
      className={classes.root}>
      {isLoading || isFetching ? (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      ) : (
        <>
          {isError ? (
            <Error
              error={isError}
              setRefetchAgain={refetch}
              showGenericMessage
            />
          ) : (
            <FormInput
              label="Building"
              inputValue={buildingId}
              setValue={setBuildingId}
              array={buildings}></FormInput>
          )}
        </>
      )}
      <FormAction
        requiresConfig={currentProps.requiresConfig}
        saveDisabled={!buildingId}
        onSave={handleOnSave}></FormAction>
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {},
    loader: {
      display: "flex",
      justifyContent: "center"
    },
    actions: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "1em",
      gap: "1em"
    }
  };
});

export default BuildingSettingsComponent;
