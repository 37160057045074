import { useState, useMemo } from "react";
import { makeStyles } from "tss-react/mui";
import { WidgetSettings } from "../widgets-config";
import { GetCompanyIDFromBuildingId } from "src/utils/buildings.util";
import Error from "src/shared/components/Error/Error";
import { CircularProgress } from "@mui/material";
import {
  DeviceGroup,
  getParentsForInput,
  getSensorsWithStageNameForInput
} from "../../utils/entity-selection-utils";
import useGetWidgetSettingsEntities from "src/hooks/useGetWidgetSettingsEntities";
import FormInput from "src/shared/components/form-input/form-input.component";
import FormAction from "src/shared/components/form-action/form-action";

const SelectAhuStageSettingsComponent = ({
  onSave,
  currentProps
}: WidgetSettings) => {
  const { classes } = useStyles();
  const [buildingId, setBuildingId] = useState<string>(
    currentProps?.buildingId ?? ""
  );
  const [ahuId, setAhuId] = useState<string>(currentProps?.ahuId ?? "");
  const [stageId, setStageId] = useState<string>(currentProps?.stageId ?? "");
  const {
    buildings,
    deviceParents,
    devices,
    isLoading,
    isFetching,
    isError,
    refetch
  } = useGetWidgetSettingsEntities();

  const ahuBuildings = buildings?.filter(building => building.has_ahus);

  const ahusForInput = useMemo(
    () => getParentsForInput(deviceParents, buildingId, DeviceGroup.hvac),
    [deviceParents, devices, buildingId, isLoading, isFetching]
  );

  const stagesForInput = useMemo(
    () =>
      getSensorsWithStageNameForInput(
        deviceParents,
        devices,
        buildingId,
        ahuId
      ),
    [deviceParents, devices, buildingId, ahuId, isLoading, isFetching]
  );

  const handleOnBuildingChange = (value: string) => {
    setAhuId("");
    setStageId("");
    setBuildingId(value);
  };
  const handleOnAhuChange = (value: string) => {
    const selectedAhu = ahusForInput?.find(ahu => ahu.id === value);
    if (selectedAhu) {
      setBuildingId(selectedAhu.building_id);
    }
    setAhuId(value);
    setStageId("");
  };
  const handelOnStageChange = (value: string) => {
    const selectedStage = stagesForInput?.find(
      stage => stage.stage_id === value
    );
    if (selectedStage) {
      setBuildingId(selectedStage.building_id);
      setAhuId(selectedStage.ahu_id);
    }
    setStageId(value);
  };
  const handleOnSave = () => {
    onSave({
      ...currentProps,
      buildingId,
      companyId: GetCompanyIDFromBuildingId(buildingId, buildings),
      ahuId,
      stageId
    });
  };
  return (
    <div
      data-testid="select-ahu-stage-settings"
      className={classes.root}>
      {isLoading || isFetching ? (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      ) : (
        <>
          {isError ? (
            <Error
              error={isError}
              setRefetchAgain={refetch}
              showGenericMessage
            />
          ) : (
            <div>
              <div>
                <FormInput
                  label="Building"
                  inputValue={buildingId}
                  setValue={handleOnBuildingChange}
                  array={ahuBuildings}></FormInput>
              </div>
              <div className={classes.ahuSelector}>
                <FormInput
                  label="AHU"
                  inputValue={ahuId}
                  setValue={handleOnAhuChange}
                  array={ahusForInput}></FormInput>
              </div>
              <div className={classes.ahuSelector}>
                <FormInput
                  label="Stage"
                  inputValue={stageId}
                  setValue={handelOnStageChange}
                  array={stagesForInput}></FormInput>
              </div>
            </div>
          )}
        </>
      )}
      <FormAction
        onSave={handleOnSave}
        requiresConfig={currentProps.requiresConfig}
        saveDisabled={!stageId}></FormAction>
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {
      height: "100%"
    },
    loader: {
      display: "flex",
      justifyContent: "center"
    },

    ahuSelector: {
      marginTop: "1em"
    },
    rangeRow: {
      display: "flex",
      gap: 12,
      alignItems: "center"
    }
  };
});

export default SelectAhuStageSettingsComponent;
