import { makeStyles } from "tss-react/mui";
import { AlertReturn } from "src/utils/client";
import { Typography, Grid } from "@mui/material";

type Props = {
  alert?: AlertReturn;
  color?: string;
};

const AlertInfo = (props: Props) => {
  const { alert, color } = props;
  const { classes } = useStyles();
  const value = alert?.user_pref_alert_value?.toFixed(2);
  const threshold = alert?.user_pref_threshold?.toFixed(2);
  const thresholdDirection = alert?.notification?.threshold_direction;

  return (
    <div className={classes.info}>
      <Typography className={classes.header}>{alert?.header}</Typography>
      <div className={classes.greyBox}>
        <Grid
          container
          className={classes.container}>
          <Grid
            item
            md={6}
            sm={6}
            xs={6}
            className={classes.info}>
            <Typography className={classes.text}>
              ALERTED MEASUREMENT
            </Typography>
            <Typography
              className={classes.value}
              style={{ color: `${color}` }}>
              {value ? value : "---"}
            </Typography>
          </Grid>
          <Grid
            item
            md={6}
            sm={6}
            xs={6}
            className={classes.info}>
            <Typography className={classes.text}>THRESHOLD</Typography>
            <Typography className={classes.text}>
              {thresholdDirection === "gt" ? " GREATER THAN" : " LESS THAN"}
            </Typography>
            <Typography className={classes.threshold}>
              {threshold ? threshold : "---"}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    info: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column"
    },
    header: {
      textAlign: "center"
    },
    container: {
      padding: "0rem 1rem"
    },
    greyBox: {
      background: "rgba(0, 0, 0, 0.07)",
      width: "90%",
      borderRadius: "2px",
      padding: "0.5rem 0rem 0.25rem 0rem"
    },
    text: {
      textAlign: "center",
      fontSize: "0.8rem"
    },
    value: {
      fontSize: "1.65rem",
      fontWeight: 500
    },
    threshold: {
      fontWeight: 500
    }
  };
});

export default AlertInfo;
