import { useState, MouseEvent } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { FormattedBuildingWithEntity } from "src/shared/types/building";
import { Button, Menu, MenuItem } from "@mui/material";
import {
  handleBuildingModal,
  handleReportModal,
  setBuildingEditing
} from "src/store/buildings/reducer";
import { useAppDispatch } from "src/hooks/useAppDispatch";

type Props = {
  building: FormattedBuildingWithEntity;
  variant?: "text" | "outlined";
};
const BuildingOptions = ({ building, variant = "outlined" }: Props) => {
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openFullReport = (event: MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    dispatch(handleReportModal(true));
  };

  const openEdit = () => {
    dispatch(handleBuildingModal(true));
    dispatch(setBuildingEditing(true));
  };

  const openDashboard = () => {
    window.open(`/#/dashboard/tv/${building.id}`);
  };

  return (
    <>
      <Button
        variant={variant}
        onClick={handleClick}
        data-testid="options-button">
        {variant === "text" ? (
          <MoreVertIcon
            data-testid="icon"
            sx={{ marginRight: 1 }}
          />
        ) : (
          "Action"
        )}
      </Button>
      <Menu
        data-testid="menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        <MenuItem
          data-testid="dashboard"
          onClick={() => {
            handleClose();
            openDashboard();
          }}>
          Open Dashboard
        </MenuItem>
        <MenuItem
          onClick={event => {
            handleClose();
            openFullReport(event);
          }}
          data-testid="create-report-button">
          Create Report
        </MenuItem>
        <MenuItem
          data-testid="edit"
          onClick={() => {
            handleClose();
            openEdit();
          }}>
          Edit Building
        </MenuItem>
      </Menu>
    </>
  );
};

export default BuildingOptions;
