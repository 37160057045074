import moment from "moment";
import { alpha } from "@mui/material/styles";
import { ValueObject, CategoryObject } from "../types/aq-widget-types";
import {
  EXCELLENT,
  GOOD,
  MODERATE,
  SENSITIVE,
  UNHEALTHY,
  VERY_UNHEALTHY,
  POOR,
  HAZARDOUS
} from "src/shared/constants/building-status";
import { getStageBackground } from "src/sections/hvac/air-handling-units/ahu-details/utils/accordion-background";
import { formatMetric, getStatusColor } from "./aq-widget-utils";

export const indoorAqOrder = ["aqi", "cotwo", "voc", "pmtwo", "pmten"];

export function formatDates(obj: ValueObject) {
  if (!obj) return [];
  const dates = Object.keys(obj);
  return dates.map(date => moment(date).format("MMM YYYY")).reverse();
}

export function getValues(obj: ValueObject) {
  if (!obj) return [];

  const values = Object.values(obj).map(value => {
    if (typeof value === "number") {
      return value;
    }
    return false;
  });
  return values.reverse();
}

export function getCategories(obj: CategoryObject) {
  if (!obj) return [];

  const categories = Object.values(obj);
  return categories.reverse();
}

export function getBackground(value: number | string, category: string) {
  const statusColor = getStatusColor(category);
  return value === "--"
    ? {
        borderColor: "rgb(125,125,125)",
        background: alpha("rgb(125,125,125)", 0.1)
      }
    : {
        borderColor: statusColor,
        background: alpha(statusColor, 0.1)
      };
}

export function orderData(data: Record<string, any>) {
  const orderedData = {};
  for (const key of indoorAqOrder) {
    if (data.hasOwnProperty(key)) {
      orderedData[key] = data[key];
    }
  }
  return orderedData;
}

export const getAQChartData = (indoorWeeklyObj: Record<string, any>) => {
  if (!indoorWeeklyObj) return;
  const orderedObj = orderData(indoorWeeklyObj);

  return Object.entries(orderedObj).map(([label, dataObj], index) => {
    const title = formatMetric(label);
    const color = getStageBackground(null, label, index);
    return {
      label: title,
      data: Object.entries(dataObj).map(([date, value]) => ({
        date,
        value
      })),
      radius: 3,
      tension: 0.5,
      yAxisId: "y",
      backgroundColor: color,
      borderColor: color
    };
  });
};

export function formatLabel(category: string) {
  switch (category) {
    case GOOD:
      return EXCELLENT;
    case MODERATE:
      return GOOD;
    case SENSITIVE:
      return MODERATE;
    case UNHEALTHY:
      return UNHEALTHY;
    case VERY_UNHEALTHY:
      return POOR;
    case HAZARDOUS:
      return HAZARDOUS;
    default:
      return "Unavailable";
  }
}
