import {
  useGetPaintBoothQuery,
  useGetPaintBoothSetPointRangeQuery,
  useGetPaintBoothSensorsQuery
} from "src/store/services/booth/booth.service";
import moment from "moment";

const useGetPaintBoothData = (
  buildingId: string,
  boothId: string,
  requiresConfig: boolean,
  showSetPoint = false
) => {
  const {
    data: paintBooth,
    isLoading: isLoadingPaintBooth,
    isError: isErrorPaintBooth,
    refetch: refetchPaintBooth
  } = useGetPaintBoothQuery(
    {
      buildingId,
      boothId
    },
    { skip: requiresConfig }
  );

  const {
    data: setPointRanges,
    isLoading: isLoadingSetPointRange,
    isError: isErrorSetPointRange,
    refetch: refetchSetPointRange
  } = useGetPaintBoothSetPointRangeQuery(
    {
      buildingId,
      boothId,
      startTs: moment().subtract(2, "months").format()
    },
    { skip: requiresConfig || !showSetPoint }
  );

  const {
    data: sensors,
    isLoading: isLoadingSensors,
    isError: isErrorSensors
  } = useGetPaintBoothSensorsQuery(
    {
      buildingId,
      boothId
    },
    { skip: requiresConfig }
  );

  return {
    sensors,
    setPointRanges,
    paintBooth,
    loading: isLoadingPaintBooth || isLoadingSetPointRange || isLoadingSensors,
    error: isErrorPaintBooth || isErrorSetPointRange || isErrorSensors,
    refetchAgain: () => {
      refetchPaintBooth();
      refetchSetPointRange();
    }
  };
};

export default useGetPaintBoothData;
