import { makeStyles } from "tss-react/mui";
import { useMemo } from "react";
import { Grid, Typography } from "@mui/material";
import LoaderComponent from "src/shared/components/loader/loader.component";
import WidgetTitle from "src/sections/overview/components/WidgetTitle/WidgetTitle";
import Error from "src/shared/components/Error/Error";
import useGetBuildingAQData from "../../hooks/useGetBuildingAQData";
import { formatBuildingPollutants } from "../../utils/aq-building-widget-utils";
import { alpha } from "@mui/material/styles";
import { TimeRangeObject } from "../aq-building-with-time-settings/aq-building-with-time-settings.component";
import {
  defaultTwoWeeksRange,
  getStartAndEndDate
} from "../../utils/time-span-utils";
import { FormattedBuildingWithEntity } from "src/shared/types/building";
import { setSelectedOverviewTab } from "src/store/overview/reducer";
import { setUserClickedOnWidgetTitle } from "src/store/hvac/reducer";
import useOverview from "src/hooks/useOverview";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useOverviewTab } from "../../hooks/useGetOverviewTab";

type Props = {
  building: FormattedBuildingWithEntity;
  requiresConfig?: boolean;
  range?: TimeRangeObject;
};

const INDOOR_POLLUTANTS = "indoor";
const POLLUTANT_STATUSES = "indoor_categories";

const BuildingAqWidgetComponent = ({
  building,
  requiresConfig,
  range
}: Props) => {
  const { classes } = useStyles();
  const widgetsRange = range ?? defaultTwoWeeksRange;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { editing } = useOverview();
  const { tabId } = useOverviewTab();

  const { buildingAqData, isLoading, isError, refetch } = useGetBuildingAQData(
    building?.id,
    widgetsRange,
    requiresConfig
  );

  const aqDataExist = Boolean(buildingAqData);

  const pollutants = aqDataExist
    ? Object.entries(buildingAqData[INDOOR_POLLUTANTS])
    : [];
  const statuses = aqDataExist
    ? Object.values(buildingAqData[POLLUTANT_STATUSES])
    : [];

  const pollutantData = useMemo(
    () => formatBuildingPollutants(pollutants, statuses),
    [pollutants, statuses]
  );

  const { start, end } = useMemo(() => {
    return getStartAndEndDate(
      widgetsRange?.method,
      widgetsRange?.startDate,
      widgetsRange?.endDate,
      widgetsRange?.timeSpan,
      widgetsRange?.timeQuantity
    );
  }, [widgetsRange]);

  const handleTitleClick = () => {
    if (editing) return;
    dispatch(setSelectedOverviewTab(tabId));
    dispatch(setUserClickedOnWidgetTitle(true));
    navigate(`/buildings/iaq/${building?.id}`);
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography
          variant="h6"
          className={editing ? classes.default : classes.pointer}
          onClick={handleTitleClick}>
          Building Average Air Quality
        </Typography>
      </div>

      {requiresConfig ? (
        <Typography>Configuration is required</Typography>
      ) : isLoading ? (
        <LoaderComponent />
      ) : (
        <>
          {!isError && (
            <WidgetTitle
              error={isError}
              building={building}
            />
          )}
          <div className={classes.widgetBody}>
            {isError ? (
              <Error
                setRefetchAgain={refetch}
                error={isError}
              />
            ) : (
              <>
                <Grid
                  container
                  className={classes.headers}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: "0.5rem" }}>
                    Pollutant
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{ textAlign: "center" }}>
                    Average Value
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{ textAlign: "center" }}>
                    Health Class
                  </Grid>
                </Grid>
                {pollutantData && (
                  <>
                    {pollutantData.map((metric, index) => {
                      return (
                        <Grid
                          container
                          key={metric.title}
                          style={{
                            margin: "1rem 0rem",
                            backgroundColor:
                              index % 2 === 0
                                ? "rgb(243, 246, 255)"
                                : "transparent"
                          }}>
                          <Grid
                            item
                            xs={6}
                            className={classes.title}>
                            <Typography>{metric.title}</Typography>
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            className={classes.value}>
                            <Typography>{metric.value}</Typography>
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            className={classes.status}
                            style={{
                              border: `1px solid ${metric.color}`,
                              background: alpha(metric.color, 0.4)
                            }}>
                            <Typography>{metric.status}</Typography>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </>
                )}

                <Typography
                  variant="subtitle1"
                  className={classes.timeFrame}>
                  {start} - {end}
                </Typography>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      padding: "10px"
    },
    header: {
      display: "flex"
    },
    widgetBody: {
      height: "100%",
      overflow: "auto",
      marginBottom: "1rem"
    },
    headers: {
      margin: "1rem 0rem",
      color: "grey"
    },
    title: {
      paddingLeft: "0.5rem",
      display: "flex",
      alignItems: "center"
    },
    value: {
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    status: {
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: " center",
      borderRadius: "1rem"
    },
    timeFrame: {
      position: "absolute",
      bottom: 0,
      right: 16,
      fontSize: "14px !important"
    },
    default: {
      cursor: "default"
    },
    pointer: {
      cursor: "pointer",
      "&:hover": { color: "#0795bb" }
    }
  };
});

export default BuildingAqWidgetComponent;
