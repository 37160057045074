import { useEffect, useState } from "react";

const TIMER_SECONDS = 30;
const SECOND_MS = 1000;

const useErrorTimer = (error: boolean) => {
  const [retryTimer, setRetryTimer] = useState(TIMER_SECONDS);

  useEffect(() => {
    if (!error) return;

    const timer = setInterval(() => {
      setRetryTimer(prevVal => (prevVal === 0 ? TIMER_SECONDS : prevVal - 1));
    }, SECOND_MS);

    return () => {
      clearInterval(timer);
    };
  }, [error]);

  return {
    retryTimer
  };
};

export default useErrorTimer;
