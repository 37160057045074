import { Grid, Select, Typography, MenuItem } from "@mui/material";
import { FormFieldProps } from "../types";

const FormDropdownField = ({
  classes,
  setValue,
  initalValue,
  systemOptions,
  labelConfig,
  labelName,
  type
}: FormFieldProps) => {
  return (
    <div className={classes.settings}>
      <Grid
        spacing={2}
        container
        className={classes.settingsGrid}>
        <Grid
          item
          xs={12}
          sm={4}>
          <Typography>{labelName}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}>
          <Select
            onChange={({ target: { value } }) => setValue(value)}
            className={classes.select}
            value={initalValue}
            data-testid="language-select">
            {systemOptions?.map(option => {
              const value =
                type === "aqi"
                  ? option.label
                  : "key" in option
                  ? option.key
                  : option.value;
              return (
                <MenuItem
                  key={`option_${value}`}
                  value={value}>
                  <div
                    className={classes.option}
                    {...labelConfig}>
                    {option.label}
                  </div>
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
      </Grid>
    </div>
  );
};

export default FormDropdownField;
