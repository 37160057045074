import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PressureUnits } from "src/utils/client";
import { TemperatureUnits } from "src/utils/client";
import { initialState } from "./types";
// TODO REMOVE settings after reports are migrated to new redux and rtk query
const initialState: initialState = {
  temperatureSetting: TemperatureUnits.FAHRENHEIT,
  pressureSetting: PressureUnits.IWG
};
export const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setTemperatureSetting: (
      state,
      action: PayloadAction<`${TemperatureUnits}`>
    ) => {
      state.temperatureSetting = action.payload;
    },
    setPressureSetting: (state, action: PayloadAction<`${PressureUnits}`>) => {
      state.pressureSetting = action.payload;
    }
  }
});

export const { setTemperatureSetting, setPressureSetting } =
  reportsSlice.actions;

export const { reducer: reportsReducer } = reportsSlice;
