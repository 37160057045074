/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AggGranularity } from '../models/AggGranularity';
import type { AggItem } from '../models/AggItem';
import type { KettleData } from '../models/KettleData';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class KettleDataService {
  /**
   * Get Kettle Sensor Data
   * Returns aggregated kettle data for a device for the given range.
   * If `end_ts` is `None`, current time is used.
   *
   *
   * `start_ts` and `end_ts` can be given as Unix timestamps integers
   * or floats in seconds or milliseconds, or as isoformat strings.
   * ```
   * Examples:
   * 1677827157                       (seconds)
   * 1677827157.5451648               (seconds)
   * 1677827157545                    (milliseconds)
   * 2023-03-03T07:05:57.545165+00:00 (isoformat)
   * 2023-03-03T02:05:57.545165+05:00 (isoformat)
   * ```
   * All of the above generate the same timestamp in the backend
   * (except where minor place values were omitted).
   * @param sensorId
   * @param buildingId
   * @param startTs
   * @param endTs
   * @param granularity
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getKettleSensorData(
    sensorId: string,
    buildingId: string,
    startTs: string,
    endTs?: (string | null),
    granularity?: AggGranularity,
  ): CancelablePromise<(Array<AggItem> | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/kettle-data/{sensor_id}',
      path: {
        'sensor_id': sensorId,
        'building_id': buildingId,
      },
      query: {
        'start_ts': startTs,
        'end_ts': endTs,
        'granularity': granularity,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Raw Kettle Sensor Data
   * Returns raw kettle data for the device for the given range.
   * If `end_ts` is `None`, current time is used.
   *
   * `start_ts` and `end_ts` can be given as Unix timestamps integers
   * or floats in seconds or milliseconds, or as isoformat strings.
   * ```
   * Examples:
   * 1677827157                       (seconds)
   * 1677827157.5451648               (seconds)
   * 1677827157545                    (milliseconds)
   * 2023-03-03T07:05:57.545165+00:00 (isoformat)
   * 2023-03-03T02:05:57.545165+05:00 (isoformat)
   * ```
   * All of the above generate the same timestamp in the backend
   * (except where minor place values were omitted).
   * @param sensorId
   * @param buildingId
   * @param startTs
   * @param endTs
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getRawKettleSensorData(
    sensorId: string,
    buildingId: string,
    startTs: string,
    endTs?: (string | null),
  ): CancelablePromise<(Array<KettleData> | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/kettle-data/{sensor_id}/raw/',
      path: {
        'sensor_id': sensorId,
        'building_id': buildingId,
      },
      query: {
        'start_ts': startTs,
        'end_ts': endTs,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Download Raw Csv
   * Export raw kettle data to csv for a kettle sensor
   * @param deviceId
   * @param buildingId
   * @param startTs
   * @param endTs
   * @returns any Successful Response
   * @throws ApiError
   */
  public static downloadRawCsv(
    deviceId: string,
    buildingId: string,
    startTs: string,
    endTs?: (string | null),
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/kettle-data/{device_id}/raw/csv/',
      path: {
        'device_id': deviceId,
        'building_id': buildingId,
      },
      query: {
        'start_ts': startTs,
        'end_ts': endTs,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Latest Kettle Sensor Data Point
   * Get the most recent raw sensor measurements for a device.
   * @param sensorId
   * @param buildingId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getLatestKettleSensorDataPoint(
    sensorId: string,
    buildingId: string,
  ): CancelablePromise<(KettleData | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/buildings/{building_id}/kettle-data/{sensor_id}/latest/',
      path: {
        'sensor_id': sensorId,
        'building_id': buildingId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
