export const AHU_DEFAULT = "default";
export const PRESSURE_TOO_LOW = "too_low";
export const PRESSURE_GOOD = "good";
export const PRESSURE_MODERATE = "moderate";
export const PRESSURE_HIGH = "high";
export const PRESSURE_VERY_HIGH = "very_high";
export const PRESSURE_TOO_HIGH = "too_high";

export const LOAD_CLEAN_FILTERS = "clean_filters";
export const LOAD_MODERATELY_CLEAN = "moderately_clean";
export const LOAD_MODERATELY_DIRTY = "moderately_dirty";
export const LOAD_DIRTY_FILTERS = "dirty_filters";

export const AHU_UNIT_HEALTHY = "Unit Healthy";
export const AHU_HEALTHY = "Healthy";
export const AHU_NO_STAGES = "No Stages";
export const AHU_NO_STATUS = "No Status";

export const AHU_NO_DEVICE = "no device";

export const AHU_VIEW_OPTION_LOAD = "Show Filter Load";
export const AHU_VIEW_OPTION_PRESSURE = "Show Pressure";
export const AHU_VIEW_OPTION_REMAINING = "Show Remaining Weeks";

export const EXPECTANCY_GREEN = "green";
export const EXPECTANCY_YELLOW = "yellow";
export const EXPECTANCY_ORANGE = "orange";
export const EXPECTANCY_RED = "red";
