import useStyles from "./air-quality-indicator.style";
import classnames from "classnames";
import { DEFAULT } from "src/shared/constants/building-status";
import { Tooltip, Typography, Box } from "@mui/material";
import useGetAppBreakPoints from "src/hooks/useGetAppBreakPoints";
import useGetBuildingView from "../hooks/useGetBuildingView";
import { useGetBuildingAQIStatus } from "../hooks";
import { addUnderscoreInWord } from "src/shared/utils/textUtils";
import { useStyles as useStatusColors } from "src/shared/styles/statusColors";

interface AirQualityIndicatorProps {
  label?: string;
  value: "N/A" | "-" | string | number;
  showLabel?: boolean;
  isPollutant?: boolean;
  pollutantType?: string;
  contianerWidth?: string;
}

const AirQualityIndicator = ({
  label = "",
  value,
  showLabel = false,
  isPollutant = false,
  pollutantType = "aqi",
  contianerWidth
}: AirQualityIndicatorProps) => {
  const { classes } = useStyles();
  const { classes: classesStatusColor } = useStatusColors();
  const { isSm } = useGetAppBreakPoints();
  const { isListView } = useGetBuildingView();
  const { status } = useGetBuildingAQIStatus(Number(value), pollutantType);
  const statusClass = addUnderscoreInWord(status);
  const isDefaultStatus = status === DEFAULT;

  const pollutantClasses = `${classesStatusColor[`status${statusClass}`]}
  ${isPollutant ? classes.pollutantQtyBigger : classes.pollutantQty}`;

  return (
    <Box
      data-testid="pollutant-card"
      sx={{ width: contianerWidth }}
      className={classnames(
        isPollutant ? classes.pollutant : classes.devices,
        classesStatusColor[`background${addUnderscoreInWord(status)}`],
        isListView && !isSm ? classes.qtyContainer : null
      )}>
      <Tooltip
        title={status}
        disableFocusListener={isDefaultStatus}
        disableHoverListener={isDefaultStatus}
        disableTouchListener={isDefaultStatus}>
        <Typography
          data-testid="pollutant-value"
          className={pollutantClasses}>
          {value}
        </Typography>
      </Tooltip>
      {showLabel && (
        <Typography
          data-testid="indicator-label"
          translate="no"
          variant="body2"
          sx={styles.label}>
          {label}
        </Typography>
      )}
    </Box>
  );
};

const styles = {
  label: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 0.5,
    paddingRight: 0.5
  }
};

export default AirQualityIndicator;
