export enum Pollutant {
  aqi = "aqi",
  aqiLong = "Air Quality Index",
  aqiUnit = "AQI",
  aqiTitle = "AQI",
  cotwo = "cotwo",
  cotwoLong = "Carbon Dioxide",
  cotwoUnit = "ppm",
  cotwoTitle = "CO₂",
  voc = "voc",
  vocLong = "Volatile Organic Compounds",
  vocUnit = "ppm",
  vocTitle = "VOC",
  pmone = "pmone",
  pmoneTitle = "PM1",
  pmtwo = "pmtwo",
  pmtwoLong = "Fine Particulate Matter <2.5µm",
  pmtwoTitle = "PM2.5",
  pmten = "pmten",
  pmtenLong = "Inhalable Particulate Matter <10µm",
  pmtenTitle = "PM10",
  pmUnit = "µg/m³"
}

export enum StatusColor {
  good = "rgb(106, 222, 89)",
  moderate = "rgb(224, 200, 58)",
  sensitive = "rgb(253, 146, 49)",
  unhealthy = "rgb(232, 37, 71)",
  veryUnhealthy = "rgb(188, 8, 37)",
  hazardous = "rgb(118, 8, 37)",
  default = "rgb(125, 125, 125)"
}

export enum PerformanceMetric {
  aqi = "aqi",
  aqiTitle = " Air Quality Index",
  aqi_hundred = "aqi_hundred",
  aqi_hundredTitle = "Air Quality",
  ashrae = "ashrae_score",
  ashraeTitle = "Ashrae Score",
  asthma = "asthma_score",
  asthmaTitle = "Asthma Reduction",
  comfort = "comfort_score",
  comfortTitle = "Comfort Score",
  covid = "covid",
  covidTitle = "Covid Resistance",
  energy = "energy_optimization",
  energyTitle = "Efficiency",
  mold = "mold_score",
  moldTitle = "Mold Resistance",
  productivity = "productivity_score",
  productivityTitle = "Productivity"
}
