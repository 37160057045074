import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type FeaturesForExport = "booth" | "ahu" | "kettle";

type InitialState = {
  id: string;
  featureType: FeaturesForExport;
  isOpen: boolean;
};

const initialState: InitialState = {
  // Id represent id of specific device for example kettle id, ahu id etc.
  id: "",
  featureType: "ahu",
  isOpen: false
};

export const downloadSlice = createSlice({
  name: "download",
  initialState,
  reducers: {
    setDeviceId: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
    setFeatureTypeForExport: (
      state,
      action: PayloadAction<FeaturesForExport>
    ) => {
      state.featureType = action.payload;
    },
    setModalState: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    }
  }
});

export const { setDeviceId, setFeatureTypeForExport, setModalState } =
  downloadSlice.actions;

export const { reducer: downloadReducer } = downloadSlice;
