export const googleMapStyles = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5"
      }
    ]
  },
  {
    elementType: "labels",
    stylers: [
      {
        visibility: "on"
      }
    ]
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "on"
      }
    ]
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161"
      }
    ]
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f5f5"
      }
    ]
  },
  {
    featureType: "administrative.land_parcel",
    stylers: [
      {
        visibility: "on"
      }
    ]
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd"
      }
    ]
  },
  {
    featureType: "administrative.neighborhood",
    stylers: [
      {
        visibility: "on"
      }
    ]
  },
  /*{
    featureType: 'administrative.province',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },*/
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee"
      }
    ]
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575"
      }
    ]
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5"
      }
    ]
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e"
      }
    ]
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff"
      }
    ]
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575"
      }
    ]
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#dadada"
      }
    ]
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161"
      }
    ]
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e"
      }
    ]
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5"
      }
    ]
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee"
      }
    ]
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#c9c9c9"
      }
    ]
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e"
      }
    ]
  }
];

export const baiduMapStyles = [
  {
    featureType: "all",
    elementType: "all",
    stylers: {
      lightness: 10,
      saturation: -100
    }
  },
  {
    featureType: "railway",
    elementType: "all",
    stylers: {
      visibility: "off"
    }
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: {
      visibility: "off"
    }
  },
  {
    featureType: "green",
    elementType: "all",
    stylers: {
      color: "#056197",
      visibility: "off"
    }
  },
  {
    featureType: "subway",
    elementType: "all",
    stylers: {
      visibility: "off"
    }
  },
  {
    featureType: "manmade",
    elementType: "all",
    stylers: {
      visibility: "off"
    }
  },
  {
    featureType: "local",
    elementType: "all",
    stylers: {
      visibility: "off"
    }
  },
  {
    featureType: "arterial",
    elementType: "labels",
    stylers: {
      visibility: "off"
    }
  },
  {
    featureType: "boundary",
    elementType: "geometry.fill",
    stylers: {
      visibility: "off"
    }
  },
  {
    featureType: "building",
    elementType: "all",
    stylers: {
      visibility: "off"
    }
  },
  {
    featureType: "label",
    elementType: "all",
    stylers: {
      visibility: "off"
    }
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: {
      visibility: "off"
    }
  },
  {
    featureType: "poi",
    elementType: "labels.text.stroke",
    stylers: {
      visibility: "off"
    }
  },
  {
    featureType: "administrative",
    elementType: "labels",
    stylers: {
      visibility: "off"
    }
  },
  {
    featureType: "road",
    elementType: "labels",
    stylers: {
      visibility: "off"
    }
  }
];
