import { makeStyles } from "tss-react/mui";
import { useState, useMemo, ChangeEvent } from "react";
import { Typography, Switch } from "@mui/material";
import { indoorAqOrder } from "../../utils/aq-long-term-widgets-utils";
import { formatDates } from "../../utils/aq-long-term-widgets-utils";
import LoaderComponent from "src/shared/components/loader/loader.component";
import WidgetTitle from "../../components/WidgetTitle/WidgetTitle";
import Error from "src/shared/components/Error/Error";
import ErrorIcon from "@mui/icons-material/Error";
import Column from "./table-column.component";
import { FormattedBuildingWithEntity } from "src/shared/types/building";
import useGetLongTermAQData from "../../hooks/useGetLongTermAQData";
import { setSelectedOverviewTab } from "src/store/overview/reducer";
import { setUserClickedOnWidgetTitle } from "src/store/hvac/reducer";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import useOverview from "src/hooks/useOverview";
import { useOverviewTab } from "../../hooks/useGetOverviewTab";

type Props = {
  buildingId?: string;
  building?: FormattedBuildingWithEntity;
  requiresConfig?: boolean;
};

const AqTableWidgetComponent = ({
  buildingId,
  building,
  requiresConfig
}: Props) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { editing } = useOverview();
  const [showStatus, setShowStatus] = useState(false);
  const { tabId } = useOverviewTab();

  const { indoorMonthlyAQIData, isLoading, isFetching, isError, refetch } =
    useGetLongTermAQData(buildingId, requiresConfig);

  const monthlyDataExist = Boolean(indoorMonthlyAQIData);

  const formattedDates = useMemo(() => {
    if (!monthlyDataExist) return [];
    return formatDates(indoorMonthlyAQIData["aqi"]);
  }, [monthlyDataExist]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setShowStatus(event.target.checked);
  };

  const handleTitleClick = () => {
    if (editing) return;
    dispatch(setSelectedOverviewTab(tabId));
    dispatch(setUserClickedOnWidgetTitle(true));
    navigate(`/buildings/iaq/${buildingId}`);
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography
          variant="h6"
          className={editing ? classes.default : classes.pointer}
          onClick={handleTitleClick}>
          Indoor Air Quality
        </Typography>
        <div className={classes.switch}>
          <Switch
            size="small"
            checked={showStatus}
            onChange={handleChange}
          />
        </div>
      </div>
      {requiresConfig ? (
        <Typography>Configuration is required</Typography>
      ) : isLoading || isFetching ? (
        <LoaderComponent />
      ) : (
        <div className={classes.widget}>
          {!isError && (
            <WidgetTitle
              error={isError}
              building={building}
            />
          )}
          {isError ? (
            <Error
              setRefetchAgain={refetch}
              error={isError}
            />
          ) : (
            <>
              {Object.keys(indoorMonthlyAQIData).length ? (
                <div className={classes.table}>
                  <div className={classes.column}>
                    <Typography className={classes.title}>Date</Typography>
                    {Boolean(formattedDates) &&
                      formattedDates.map((date, index) => {
                        return (
                          <div
                            key={`date_${index}`}
                            className={classes.date}
                            style={{
                              backgroundColor:
                                index % 2 === 0
                                  ? "rgb(243, 246, 255)"
                                  : "transparent"
                            }}>
                            {date}
                          </div>
                        );
                      })}
                  </div>
                  {indoorAqOrder?.map(metric => {
                    return (
                      <Column
                        key={`${metric}_column`}
                        metric={metric}
                        showStatus={showStatus}
                        data={indoorMonthlyAQIData}
                      />
                    );
                  })}
                </div>
              ) : (
                <div className={classes.noData}>
                  <ErrorIcon />
                  <Typography>No Air Quality Data</Typography>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {
      height: "100%",
      width: "100%",
      padding: "10px"
    },
    header: {
      display: "flex",
      alignItems: "center"
    },
    switch: {
      marginLeft: "0.25rem"
    },
    widget: {
      height: "100%",
      width: "100%"
    },
    table: {
      display: "flex",
      justifyContent: "space-between",
      textAlign: "center",
      width: "100%"
    },
    column: {
      width: "16.4%"
    },
    title: {
      fontWeight: "bold",
      color: "grey",
      padding: "0.2rem 0rem"
    },
    date: {
      border: "1px solid transparent",
      padding: "0.2rem 0rem",
      marginBottom: "2px"
    },
    noData: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: "grey"
    },
    default: {
      cursor: "default"
    },
    pointer: {
      cursor: "pointer",
      "&:hover": { color: "#0795bb" }
    }
  };
});

export default AqTableWidgetComponent;
