import { makeStyles } from "tss-react/mui";
import { Grid, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";

type Props = {
  title: string;
  color: string;
  value: number;
  status: string;
};

const AQIOverallScore = ({ title, color, value, status }: Props) => {
  const { classes } = useStyles();

  return (
    <>
      {color && (
        <Grid
          data-testid="aqi-overall-score"
          item
          xs={12}
          md={4}>
          <div
            className={classes.colorBox}
            style={{
              border: `1px solid ${color}`,
              background: alpha(color, 0.1)
            }}>
            <Typography className={classes.title}>{title}</Typography>

            <Typography
              data-testid="aqi-value"
              style={{ fontSize: "2rem", color }}>
              {value ? value : "N / A"}
            </Typography>

            <Typography
              className={classes.status}
              style={{ color }}>
              {status}
            </Typography>
          </div>
        </Grid>
      )}
    </>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    colorBox: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "8px"
    },
    title: {
      fontSize: "0.8rem",
      color: "grey"
    },
    status: {
      fontSize: "1rem",
      textAlign: "center",
      marginTop: "-10px",
      fontWeight: "bold"
    }
  };
});

export default AQIOverallScore;
