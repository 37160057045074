import { BuildingOverviewRow } from "src/utils/client";
import { makeStyles } from "tss-react/mui";
import { TableRow, TableCell, Typography, Tooltip } from "@mui/material";
import {
  getBackground,
  formatLabel
} from "../../utils/aq-long-term-widgets-utils";
import { alpha } from "@mui/material/styles";

type Props = {
  device: BuildingOverviewRow;
  index: number;
  showStatus: boolean;
};

const INDOOR_POLLUTANTS = "indoor";
const POLLUTANT_STATUSES = "indoor_categories";

const PollutantRow = ({ device, index, showStatus }: Props) => {
  return (
    <TableRow
      sx={{
        backgroundColor: index % 2 === 0 ? "rgb(243, 246, 255)" : "transparent"
      }}
      data-testid="table-row">
      <TableCell>{device.room_name}</TableCell>
      <TableCell>{device.device_name}</TableCell>
      <PollutantCell
        showStatus={showStatus}
        value={device[INDOOR_POLLUTANTS].aqi?.toFixed(2)}
        status={device[POLLUTANT_STATUSES].aqi}
      />
      <PollutantCell
        showStatus={showStatus}
        value={device[INDOOR_POLLUTANTS].cotwo?.toFixed(2)}
        status={device[POLLUTANT_STATUSES].cotwo}
      />
      <PollutantCell
        showStatus={showStatus}
        value={device[INDOOR_POLLUTANTS].voc?.toFixed(2)}
        status={device[POLLUTANT_STATUSES].voc}
      />
      <PollutantCell
        showStatus={showStatus}
        value={device[INDOOR_POLLUTANTS].pmtwo?.toFixed(2)}
        status={device[POLLUTANT_STATUSES].pmtwo}
      />
      <PollutantCell
        showStatus={showStatus}
        value={device[INDOOR_POLLUTANTS].pmten?.toFixed(2)}
        status={device[POLLUTANT_STATUSES].pmten}
      />
    </TableRow>
  );
};

type CellProps = {
  showStatus: boolean;
  value: string;
  status: string;
};

const PollutantCell = ({ showStatus, value, status }: CellProps) => {
  const { classes } = useStyles();
  const { borderColor } = getBackground(value, status);
  const score = value ?? "--";
  const label = formatLabel(status);
  const tooltip = showStatus ? score : score === "--" ? "Unavailable" : status;

  return (
    <TableCell align="center">
      <Tooltip
        title={tooltip}
        placement="top-end"
        disableInteractive>
        <Typography
          className={classes.status}
          style={{
            border: `1px solid ${borderColor}`,
            background: alpha(borderColor, 0.4)
          }}>
          {showStatus ? label : score}
        </Typography>
      </Tooltip>
    </TableCell>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    status: {
      borderWidth: "1px",
      borderStyle: "solid",
      padding: "0.2rem 0.5rem",
      borderRadius: "1rem",
      minWidth: "6.5rem"
    }
  };
});

export default PollutantRow;
