import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ServiceLog } from "src/utils/client";

const initialState = {
  serviceLogModalOpen: false,
  selectedServiceLog: null,
  expandedLog: false,
  downloadModalOpen: false,
  listModalOpen: false,
  editServiceLogModalOpen: false
};

export const serviceLogSlice = createSlice({
  name: "servicelogs",
  initialState,
  reducers: {
    handleServiceLogModal: (state, action: PayloadAction<boolean>) => {
      state.serviceLogModalOpen = action.payload;
    },
    setExpandedLog: (state, action: PayloadAction<boolean>) => {
      state.expandedLog = action.payload;
    },
    handleDownloadModalState: (state, action: PayloadAction<boolean>) => {
      state.downloadModalOpen = action.payload;
    },
    setSelectedServiceLog: (
      state,
      action: PayloadAction<ServiceLog | null>
    ) => {
      state.selectedServiceLog = action.payload;
    },
    handleEditServiceLogModal: (state, action: PayloadAction<boolean>) => {
      state.editServiceLogModalOpen = action.payload;
    }
  }
});

export const {
  handleServiceLogModal,
  setExpandedLog,
  handleDownloadModalState,
  setSelectedServiceLog,
  handleEditServiceLogModal
} = serviceLogSlice.actions;

export const { reducer: serviceLogReducer } = serviceLogSlice;
