import MaxPressureComponent from "../max-pressure/max-pressure.component";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import MaxLoadComponent from "../max-load/max-load.component";
import RemainingWeeksComponent from "../remaining-weeks/remaining-weeks.component";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { nanoid } from "nanoid";
import { makeStyles } from "tss-react/mui";

import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Paper,
  Typography
} from "@mui/material";

import {
  AHU_VIEW_OPTION_LOAD,
  AHU_VIEW_OPTION_PRESSURE,
  AHU_VIEW_OPTION_REMAINING
} from "src/shared/constants/ahu-status";
import { StageAndFilterload } from "src/utils/client";
import { FormattedAhu } from "src/shared/types/ahu";
import { useAppSelector } from "src/hooks/useAppSelector";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import {
  getExpandedAhusById,
  getSelectedAhuTableOptions
} from "src/store/hvac/selectors";
import {
  handleFilterStageModal,
  setExpandedAhusById,
  setSelectedAHU
} from "src/store/hvac/reducer";
import { useNavigate, useParams } from "react-router-dom";

type Props = {
  ahu?: FormattedAhu;
  setOpen?: any;
};

const AhuStagesAccordionComponent = ({ ahu }: Props) => {
  const { buildingId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const expandedAhuIds = useAppSelector(getExpandedAhusById);
  const selectedViewOptions = useAppSelector(getSelectedAhuTableOptions);
  const isExpanded = expandedAhuIds.includes(ahu.id);

  const handleOnExpandClick = () => {
    if (isExpanded) {
      dispatch(setExpandedAhusById(expandedAhuIds.filter(id => id !== ahu.id)));
    } else {
      dispatch(setExpandedAhusById([...expandedAhuIds, ahu.id]));
    }
  };
  const handleOnFilterClick = () => {
    dispatch(setSelectedAHU(ahu));
    navigate(`/buildings/hvac/${buildingId}/${ahu.id}`);
  };
  return (
    <div
      data-testid="ahu-stages-accordion"
      className={classes.root}>
      <Accordion
        square
        disableGutters
        expanded={isExpanded}
        onChange={handleOnExpandClick}>
        <AccordionSummary
          expandIcon={<ExpandCircleDownOutlinedIcon sx={{ fontSize: 16 }} />}
          className={classes.accordionHeader}
          data-testid="ahu-stages-accordion-header">
          Filter Stages
        </AccordionSummary>
        <AccordionDetails className={classes.expanded}>
          {ahu.stages.map((stage: StageAndFilterload) => (
            <Paper
              key={`ahu-card_${nanoid()}`}
              className={classes.stage}
              onClick={handleOnFilterClick}>
              <div className={classes.stageInfo}>
                <div className={classes.stageName}>
                  <Typography
                    sx={{ fontWeight: 700 }}
                    data-testid="stage-name">
                    {stage.name}
                  </Typography>
                </div>
                {selectedViewOptions.includes(AHU_VIEW_OPTION_PRESSURE) && (
                  <div className={classes.stageData}>
                    <Typography
                      className={classes.pressureLbl}
                      variant="body1">
                      Pressure
                    </Typography>
                    <MaxPressureComponent stage={stage} />
                  </div>
                )}
                {selectedViewOptions.includes(AHU_VIEW_OPTION_LOAD) && (
                  <div className={classes.stageData}>
                    <Typography
                      className={classes.pressureLbl}
                      variant="body1">
                      Filter Load
                    </Typography>
                    <MaxLoadComponent stage={stage} />
                  </div>
                )}
                {selectedViewOptions.includes(AHU_VIEW_OPTION_REMAINING) && (
                  <div className={classes.stageData}>
                    <Typography
                      className={classes.pressureLbl}
                      variant="body1">
                      Remaining Weeks
                    </Typography>
                    <RemainingWeeksComponent stage={stage} />
                  </div>
                )}
              </div>
            </Paper>
          ))}
        </AccordionDetails>
        <AccordionActions className={classes.accordionActions}>
          <Button
            variant="text"
            onClick={() => {
              dispatch(setSelectedAHU(ahu));
              dispatch(handleFilterStageModal(true));
            }}
            data-testid="add-filter-button">
            Add Filter Stage
            <AddCircleOutlineIcon className={classes.addIcon} />
          </Button>
        </AccordionActions>
      </Accordion>
    </div>
  );
};

export const useStyles = makeStyles()(theme => {
  return {
    root: {},
    expanded: {
      backgroundColor: "#EBEFFC",
      paddingBottom: "0.5em"
    },
    accordionHeader: {
      backgroundColor: "#F8F9FF",
      color: theme.palette.primary[500] || theme.palette.primary["main"],
      "& .MuiAccordionSummary-content": {
        flexGrow: 0,
        marginRight: "0.5em"
      },
      "& .MuiAccordionSummary-expandIconWrapper": {
        color: theme.palette.primary[500] || theme.palette.primary["main"]
      }
    },
    accordionActions: {
      backgroundColor: "#EBEFFC",
      justifyContent: "center",
      paddingTop: 0
    },
    pressureLbl: {
      color: "#5A5B5D",
      marginRight: "0.5em"
    },
    stage: {
      borderRadius: 8,
      marginBottom: "0.4em",
      cursor: "pointer",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "&:hover": {
        backgroundColor: theme.palette.grey["200"]
      }
    },
    stageInfo: {
      width: "100%",
      marginBottom: "0.5em"
    },
    stageName: {
      textAlign: "center",
      textTransform: "uppercase",
      borderBottom: "1px solid #D9D9D9",
      padding: 4,
      fontWeight: 700
    },
    stageData: {
      paddingRight: "1em",
      paddingLeft: "1em",
      marginTop: "0.5em",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    offline: {
      color: "#d32f2f",
      fontSize: "medium",
      verticalAlign: "top"
    },
    addIcon: { fontSize: 16, marginLeft: "0.5em" }
  };
});

export default AhuStagesAccordionComponent;
