import * as am5 from "@amcharts/amcharts5";
import "./assets/cera-pro/stylesheet.css";
import "./index.css";
import "./styles/rgl.css";
import "./styles/react-resizable.css";
import config from "src/utils/config";
import assets from "src/utils/config-assets";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import Routes from "./routes/Routes";
import { PersistGate } from "redux-persist/integration/react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { CssBaseline } from "@mui/material";
import { store, persistor } from "./store/store";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import Theme from "./styles/Theme";
import "src/observability/sentry.service";
if (config.amLicense) {
  am5.addLicense(config.amLicense);
}

document.title = config.title;

let link: any = document.querySelector("link[rel~='icon']");

if (!link) {
  link = document.createElement("link");
  link.rel = "icon";
  document.getElementsByTagName("head")[0].appendChild(link);
}

link.href = assets.favicon.default;

const container = document.getElementById("react-root");
const root = createRoot(container!);

if (NODE_ENV !== "production") {
  console.log(`Running as ${NODE_ENV} on version ${VERSION}`);
}

root.render(
  <LocalizationProvider dateAdapter={AdapterMoment}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={Theme}>
        <Provider store={store}>
          <PersistGate
            loading={null}
            persistor={persistor}>
            <CssBaseline>
              <Routes />
            </CssBaseline>
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  </LocalizationProvider>
);
