import classnames from "classnames";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { getAQValueFromBuilding } from "src/utils/buildings.util";
import { Grid, Paper, Typography, Box } from "@mui/material";
import { FormattedBuildingWithEntity } from "src/shared/types/building";
import useGetBuildingView from "../hooks/useGetBuildingView";
import useGetAppBreakPoints from "src/hooks/useGetAppBreakPoints";
import AirQualityIndicator from "../air-quality-indicator/air-quality-indicator";
import { useStyles } from "./building-row.style";
import BuildingOptions from "../building-options/building-options";
import { useNavigate, useParams } from "react-router-dom";
import { useContext } from "react";
import { DrawerContext } from "src/sections/dashboard/Context/DashboardContext";
import { Filters } from "src/shared/components/main-menu/main-menu.component";

const OUTDOOR_AQI_KEY = "outdoorData.data.aqi.value";
const INDOOR_AQI_KEY = "indoorData.data.aqi.value";

const BuildingsRowComponent = ({
  building
}: {
  building: FormattedBuildingWithEntity;
}) => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { isMd, isSm } = useGetAppBreakPoints();
  const { isMapView, isListView } = useGetBuildingView();
  const { category } = useParams();
  const { filter } = useContext(DrawerContext);
  const outdoorValue = getAQValueFromBuilding(building, OUTDOOR_AQI_KEY);
  const indoorValue = getAQValueFromBuilding(building, INDOOR_AQI_KEY);

  let deviceCount: number = 0;
  if (!building) return null;
  switch (category) {
    case Filters.HVAC:
      deviceCount = building.hvac_sensor_count;
      break;
    case Filters.KETTLE:
      deviceCount = building.kettle_sensor_count;
      break;
    case Filters.BOOTH:
      deviceCount = building.paint_booth_sensor_count;
      break;
    case Filters.IAQ:
      deviceCount = building.aq_device_count;
      break;
  }

  const handleOnRowClick = () => {
    navigate(`/buildings/${category}/${building.id}`);
  };

  return (
    <Paper
      data-testid="buildings-row"
      className={classnames(classes.root, isListView ? classes.row : null)}>
      <Grid
        spacing={1}
        container
        data-testid="buildings-row-grid"
        onClick={handleOnRowClick}>
        <Grid
          item
          md={isListView ? 3 : 12}
          sm={isMapView || isListView ? 4 : 12}
          lg={isListView ? 3 : 12}
          xs={12}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              translate="no"
              variant="h6"
              className={classes.name}>
              {building.name}
            </Typography>
          </Box>

          <div>
            {(!isListView || (isListView && isMd)) && (
              <Typography className={classes.address}>
                <FmdGoodIcon />
                {building.location?.address}
              </Typography>
            )}
            {!isSm && isMapView && (
              <div
                onClick={event => event.stopPropagation()}
                className={classes.fullReportMap}>
                <BuildingOptions building={building} />
              </div>
            )}
          </div>
        </Grid>
        {isListView && !isMd && (
          <Grid
            item
            lg={3}
            md={3}>
            <Typography className={classes.address}>
              <FmdGoodIcon />
              {building.location?.address}
            </Typography>
          </Grid>
        )}
        <Grid
          item
          md={isListView ? 1 : 4}
          sm={isMapView ? 2 : isListView ? 2 : 4}
          xs={4}
          lg={isListView ? 1 : 4}
          className={isListView && !isSm ? classes.itemCentered : null}>
          <div
            className={classnames(
              classes.devices,
              isListView && !isSm ? classes.qtyContainer : null
            )}>
            <Typography
              variant="h4"
              className={classes.pollutantQty}>
              {deviceCount}
            </Typography>

            {(!isListView || isSm) && (
              <Typography
                variant="body2"
                sx={{ lineHeight: 3 }}>
                Devices
              </Typography>
            )}
          </div>
        </Grid>
        {filter === "iaq" && (
          <>
            <Grid
              item
              md={isListView ? 2 : 4}
              sm={isMapView ? 2 : isListView ? 2 : 4}
              xs={4}
              lg={isListView ? 2 : 4}
              className={isListView && !isSm ? classes.itemCentered : null}>
              <AirQualityIndicator
                value={indoorValue}
                showLabel={!isListView || isSm}
                label="Indoor AQI"></AirQualityIndicator>
            </Grid>
            <Grid
              item
              md={isListView ? 2 : 4}
              sm={isMapView ? 2 : isListView ? 2 : 4}
              xs={4}
              lg={isListView ? 2 : 4}
              className={isListView && !isSm ? classes.itemCentered : null}>
              <AirQualityIndicator
                value={outdoorValue}
                showLabel={!isListView || isSm}
                label="Outdoor AQI"></AirQualityIndicator>
            </Grid>
          </>
        )}
        {!isSm && isListView && (
          <Grid
            item
            sm={2}
            md={1}
            lg={1}
            className={classes.itemCentered}
            onClick={event => event.stopPropagation()}>
            <BuildingOptions building={building} />
          </Grid>
        )}
      </Grid>
      {!isSm && !isMapView && !isListView && (
        <div
          onClick={event => event.stopPropagation()}
          className={classes.footer}>
          <BuildingOptions
            variant="text"
            building={building}
          />
        </div>
      )}
    </Paper>
  );
};

export default BuildingsRowComponent;
