import { useMemo } from "react";
import { useGetBuildingRoomsQuery } from "src/store/services/buildings/buildings.service";
import { useAppSelector } from "src/hooks/useAppSelector";
import LoaderComponent from "src/shared/components/loader/loader.component";
import Table from "src/shared/components/Table/Table";
import useGetHvacView from "src/sections/hvac/air-handling-units/hooks/useGetHvacView";
import { getAirQualitySearchTerm } from "src/store/hvac/selectors";
import { useParams } from "react-router-dom";

export const Rooms = () => {
  const { buildingId } = useParams();
  const {
    data: rooms,
    isLoading,
    isFetching
  } = useGetBuildingRoomsQuery(buildingId, { skip: !buildingId });
  const { isListViewActive } = useGetHvacView();
  const roomsSearchTerm = useAppSelector(getAirQualitySearchTerm);

  const searchedRooms = useMemo(() => {
    if (!Boolean(rooms)) return [];

    if (roomsSearchTerm?.length)
      return rooms?.filter(({ room }) =>
        room.name.toLowerCase().includes(roomsSearchTerm)
      );
    return rooms;
  }, [roomsSearchTerm, isFetching, isLoading, rooms?.length]);

  return (
    <div data-testid="rooms">
      {isLoading || isFetching ? (
        <LoaderComponent />
      ) : (
        <Table
          showHeader={isListViewActive}
          type="room"
          data={searchedRooms}
        />
      )}
    </div>
  );
};

export default Rooms;
