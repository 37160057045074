import { Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

type Props = {
  title?: string;
};

const SeparatorWidgetComponent = (props: Props) => {
  const { title } = props;
  const { classes } = useStyles();
  return (
    <div
      data-testid="separator-widget"
      className={classes.root}>
      <Typography variant="h5">{title}</Typography>
      <div className={classes.line}></div>
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {
      display: "flex",
      alignItems: "center",
      gap: 16,
      width: "100%"
    },
    line: {
      flex: 1,
      height: 2,
      marginTop: 4,
      backgroundColor: "#ebebeb"
    }
  };
});

export default SeparatorWidgetComponent;
