import { makeStyles } from "tss-react/mui";
import { Select, MenuItem, TextField } from "@mui/material";
import { setSearchTerm, setSorterType } from "src/store/buildings/reducer";
import { useAppSelector } from "src/hooks/useAppSelector";
import { getSearchTerm, getSorterType } from "src/store/buildings/selector";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { Sorters } from "src/store/buildings/types";

const sorterOptions: Array<{
  value: string;
  label: string;
}> = [
  {
    label: "Name",
    value: "name"
  },
  {
    label: "Indoor Air Quality",
    value: "indoor"
  },
  {
    label: "Outdoor Air Quality",
    value: "outdoor"
  }
];

const BuildingFiltersComponent = () => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const searchTerm = useAppSelector(getSearchTerm);
  const sortedType = useAppSelector(getSorterType);

  return (
    <div
      className={classes.root}
      data-testid="building-filters">
      <Select
        data-testid="sorter"
        className={classes.select}
        value={sortedType}
        autoWidth
        onChange={({ target: { value } }) => {
          dispatch(setSorterType(value as Sorters));
        }}
        renderValue={value => {
          return (
            <span>
              <strong>
                {sorterOptions.find(item => item.value === value).label}
              </strong>
            </span>
          );
        }}>
        {sorterOptions.map(item => (
          <MenuItem
            key={item.value}
            value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      <TextField
        data-testid="search"
        onChange={({ target: { value } }) => {
          dispatch(setSearchTerm(value));
        }}
        value={searchTerm}
        className={classes.search}
        placeholder="Search"
        type="search"
      />
    </div>
  );
};

export const useStyles = makeStyles()(theme => {
  return {
    root: {
      display: "flex",
      flex: 1
    },
    select: {
      marginRight: "1em",
      height: 40,
      backgroundColor: theme.palette.common.white
    },
    search: {
      marginTop: 0,
      minWidth: 129,
      maxWidth: 250,
      flex: 1,
      "& input": {
        backgroundColor: "white"
      }
    }
  };
});

export default BuildingFiltersComponent;
