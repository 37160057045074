import { useEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import AnimatedTheme from "@amcharts/amcharts5/themes/Animated";
import { makeStyles } from "tss-react/mui";
import { nanoid } from "nanoid";
import { TimeUnit } from "@amcharts/amcharts5/.internal/core/util/Time";
import { StageType } from "src/utils/client";

export enum XType {
  DATE = "date",
  MONEY = "money",
  WEIGHT = "weight"
}

type Props = {
  id?: string;
  data: any[];
  airflowStage?: any;
  isStageSelected?: boolean;
  terminalPressure?: number;
  ranges?: any[];
  yTitle?: string;
  xTitle?: string;
  xType?: XType;
  granularity?: { timeUnit: TimeUnit; count: number };
  maxLimit?: (maxNumber: number) => number;
  onSelection?: (startDate: number, endDate: number) => void;
  useDefaultMarker?: boolean;
  horizontalRanges?: any[];
  defaultLegends?: any[];
  defaultStrokeColor?: string;
  useYAxisScrollBar?: boolean;
  defaultYAxisZoom?: { min: number; max: number };
  minMaxYAxis?: { min: number; max: number };
  currencyUnit?: string;
  smooth?: boolean;
  rangesColor?: string[];
};

const LinesChartComponent = (props: Props) => {
  let {
    id = nanoid(),
    data,
    ranges,
    yTitle,
    xTitle,
    xType = XType.DATE,
    maxLimit,
    onSelection,
    granularity = { timeUnit: "hour", count: 2 },
    useDefaultMarker,
    horizontalRanges,
    defaultLegends,
    defaultStrokeColor,
    useYAxisScrollBar,
    defaultYAxisZoom,
    minMaxYAxis,
    currencyUnit,
    rangesColor = [],
    airflowStage,
    isStageSelected,
    terminalPressure,
    smooth = true
  } = props;
  const { classes } = useStyles();
  let [rootState, setRoot] = useState<any>();
  let [chartState, setChart] = useState<any>();
  let [legendState, setLegend] = useState<any>();
  let [xAxisState, setXAxis] = useState<any>();
  let [yAxisState, setYAxis] = useState<any>();
  const [airflowAxis, setAirflowAxis] = useState<any>();
  let [, setRangeState] = useState<any>([]);
  let [previousYTitle, setPreviousYTitle] = useState<string>(yTitle);
  let [startZoomY, setStartZoomY] = useState<number>();
  let [endZoomY, setEndZoomY] = useState<number>();
  let [maxLimitState, setMaxLimit] = useState<number>();
  let [minLimitState, setMinLimit] = useState<number>();
  useEffect(() => {
    let selectStatedX;
    let selectStartedY = null;
    let chart;

    try {
      let root = am5.Root.new(`line_chart_${id}`);
      setRoot(root);
      root.setThemes([AnimatedTheme.new(root)]);

      chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          wheelX: "scroll",
          wheelY: "scroll",
          layout: root.verticalLayout,
          maxTooltipDistance: -1
        })
      );

      if (useYAxisScrollBar) {
        chart.set(
          "scrollbarY",
          am5.Scrollbar.new(root, { orientation: "vertical" })
        );
      }
      const isMobile = window.matchMedia("(max-width: 1024px)").matches;
      chart.get("colors").set("step", 3);

      setChart(chart);

      if (yTitle) {
        chart?.leftAxesContainer.children.push(
          am5.Label.new(root, {
            text: yTitle,
            fontWeight: "500",
            rotation: 270,
            centerX: am5.p50,
            y: am5.p50,
            fontFamily: "Cera Pro"
          })
        );
      }

      let dateAxis =
        xType === XType.MONEY || xType === XType.WEIGHT
          ? am5xy.ValueAxis.new(root, {
              maxDeviation: 0.3,
              renderer: am5xy.AxisRendererX.new(root, {})
            })
          : am5xy.DateAxis.new(root, {
              maxDeviation: 0.2,
              baseInterval: granularity,
              groupData: true,
              renderer: am5xy.AxisRendererX.new(root, {})
            });

      let xAxis = chart.xAxes.push(dateAxis);
      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          maxDeviation: 0.3,
          renderer: am5xy.AxisRendererY.new(root, {}),
          autoZoom: false
        })
      );

      chart.set(
        "cursor",
        am5xy.XYCursor.new(root, {
          behavior: isMobile ? "none" : "zoomXY",
          xAxis: xAxis,
          yAxis: yAxis
        })
      );

      let cursor = chart.get("cursor");

      xAxis.get("renderer").labels.template.setAll({
        fontFamily: "Cera Pro"
      });

      if (xTitle) {
        xAxis.children.push(
          am5.Label.new(root, {
            text: xTitle,
            textAlign: "center",
            x: am5.p50,
            center: am5.p50
          })
        );
      }

      chart.zoomOutButton.events.on("click", () => {
        if (onSelection) {
          onSelection(null, null);
        }
      });

      cursor.events.on("selectstarted", function () {
        selectStatedX = cursor.getPrivate("positionX");
        selectStartedY = cursor.getPrivate("positionY");
      });

      cursor.events.on("selectended", function () {
        let selectEndedX = cursor.getPrivate("positionX");
        let selectEndedY = cursor.getPrivate("positionY");

        let startValue = xAxis.positionToValue(
          xAxis.toAxisPosition(selectStatedX)
        );
        let endValue = xAxis.positionToValue(
          xAxis.toAxisPosition(selectEndedX)
        );

        let startValueY = yAxis.positionToValue(
          yAxis.toAxisPosition(selectStartedY)
        );

        let endValueY = yAxis.positionToValue(
          yAxis.toAxisPosition(selectEndedY)
        );

        setStartZoomY(startValueY);
        setEndZoomY(endValueY);

        if (onSelection) {
          onSelection(startValue, endValue);
        }
      });

      yAxis.get("renderer").labels.template.setAll({
        fontFamily: "Cera Pro"
      });

      let legend = useDefaultMarker
        ? chart.children.unshift(
            am5.Legend.new(root, {
              nameField: "name",
              fillField: "color",
              strikeField: "color",
              centerX: am5.p50,
              x: am5.percent(50),
              marginBottom: 50,

              tooltip: am5.Tooltip.new(root, {}),
              fontFamily: "Cera Pro",
              useDefaultMarker,
              height: isMobile ? am5.percent(100) : am5.percent(60),
              verticalScrollbar: am5.Scrollbar.new(root, {
                orientation: "vertical"
              })
            })
          )
        : chart.children.push(
            am5.Legend.new(root, {
              centerX: am5.p50,
              x: am5.percent(50),
              marginTop: 0,
              marginBottom: 50,
              tooltip: am5.Tooltip.new(root, {}),
              fontFamily: "Cera Pro",
              useDefaultMarker,
              height: isMobile ? am5.percent(100) : am5.percent(60),
              verticalScrollbar: am5.Scrollbar.new(root, {
                orientation: "vertical"
              })
            })
          );

      if (!defaultLegends) {
        legend.itemContainers.template.setAll({
          tooltipText: "Click to hide/show",
          tooltipY: 5
        });

        legend.labels.template.setAll({
          fontFamily: "Cera Pro"
        });

        legend.itemContainers.template.events.on("pointerover", function (e) {
          let itemContainer = e.target;
          let tooltip = itemContainer.getTooltip();

          tooltip.get("background").setAll({
            fill: am5.color(0x000000),
            fillOpacity: 0.8,
            pointerOrientation: "up",
            position: "relative",
            fontFamily: "Cera Pro"
          });

          // As series list is data of a legend, dataContext is series
          let series = itemContainer.dataItem.dataContext;

          chart.series.each(function (chartSeries) {
            if (chartSeries != series) {
              chartSeries.strokes.template.setAll({
                strokeOpacity: 0.15,
                stroke: am5.color(0x000000)
              });
            } else {
              chartSeries.strokes.template.setAll({
                strokeWidth: 3
              });
            }
          });
        });

        legend.itemContainers.template.events.on("pointerout", function () {
          chart.series.each(function (chartSeries) {
            chartSeries.strokes.template.setAll({
              strokeOpacity: 1,
              strokeWidth: 2,
              stroke: chartSeries.get("stroke")
            });
          });
        });
      } else {
        legend.labels.template.setAll({
          fontSize: 10,
          fontFamily: "Cera Pro"
        });
      }

      setLegend(legend);

      // put axis ranges to back
      chart.plotContainer.children.moveValue(chart.topGridContainer, 0);
      chart.appear(1000, 100);

      setXAxis(xAxis);
      setYAxis(yAxis);
      root._logo?.dispose();
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (chartState) {
      let yRenderer = am5xy.AxisRendererY.new(rootState, {
        opposite: true
      });
      let airflowYAxis = chartState.yAxes.push(
        am5xy.ValueAxis.new(rootState, {
          maxDeviation: 1,
          renderer: yRenderer
        })
      );
      airflowYAxis.get("renderer").grid.template.setAll({
        stroke: "transparent"
      });
      yRenderer.labels.template.set("fill", am5.color(0xbebebe));

      chartState.rightAxesContainer.children.clear();
      if (airflowStage) {
        chartState.rightAxesContainer.children.push(
          am5.Label.new(rootState, {
            text: "Airflow (M/S)",
            fontWeight: "500",
            rotation: -270,
            centerX: am5.p50,
            y: am5.p50,
            fontFamily: "Cera Pro"
          })
        );
      }

      setAirflowAxis(airflowYAxis);
    }
  }, [chartState, airflowStage]);

  useEffect(() => {
    if (yAxisState) {
      yAxisState.axisRanges.clear();
      let rangeDataItem = yAxisState.makeDataItem({
        value: terminalPressure,
        affectsMinMax: true
      });
      let range = yAxisState.createAxisRange(rangeDataItem);

      if (isStageSelected && Number(terminalPressure)) {
        range.get("label").setAll({
          fill: am5.color(0xdb0000),
          text: terminalPressure,
          location: 0,
          inside: true,
          centerX: 0,
          centerY: am5.p100,
          fontWeight: "bold"
        });

        range.get("grid").setAll({
          stroke: am5.color(0xdb0000),
          strokeOpacity: 1,
          strokeWidth: 2,
          strokeDasharray: [4, 4],
          visible: true
        });
      } else {
        yAxisState.axisRanges.clear();
      }
    }
  }, [yAxisState, isStageSelected, terminalPressure]);

  useEffect(() => {
    if (rootState) {
      if (chartState.series) {
        chartState.series.clear();
      }
      for (let metric of data) {
        let labelText = "";
        if (xType === XType.DATE) {
          labelText =
            '{name}\n{valueX.formatDate("MMM d, yyyy")}[/]\n{title}[/]{description}{worker}[bold]{valueY}[/]';
        } else if (xType === XType.MONEY) {
          labelText = `${currencyUnit}{valueY.formatNumber("#,###.")}`;
        } else if (xType === XType.WEIGHT) {
          labelText = '{valueY.formatNumber("#,###.")} tons';
        }

        let options: any = {
          name: metric.short_name,
          interpolationDuration: 0,
          xAxis: xAxisState,
          yAxis: yAxisState,
          valueYField: "value",
          valueXField: "date",
          tension: 0.1,
          ...(metric.color && { stroke: am5.color(metric.color) }),
          ...(defaultStrokeColor && { stroke: am5.color(defaultStrokeColor) }),
          tooltip: am5.Tooltip.new(rootState, {
            getFillFromSprite: false,
            pointerOrientation: "horizontal",
            getStrokeFromSprite: true,
            getLabelFillFromSprite: false,
            autoTextColor: false,
            labelText,
            showTooltipOn: "always"
          })
        };

        if (metric.dashed) {
          options.stroke = am5.color(0x000000);
          options.fill = am5.color(0x000000);
        }

        if (metric.stage_type === StageType.AIRFLOW) {
          if (airflowAxis) {
            options.stroke = am5.color(0xbebebe);
            options.yAxis = airflowAxis;
          }
        }

        let lineSerie = smooth
          ? am5xy.SmoothedXLineSeries.new(rootState, options)
          : am5xy.LineSeries.new(rootState, options);

        lineSerie
          .get("tooltip")
          .get("background")
          .setAll({
            fill: am5.color(0xffffff),
            fillOpacity: 1
          });

        lineSerie.get("tooltip").label.setAll({
          fill: am5.color(0x000000),
          fontFamily: "Cera Pro"
        });

        if (metric.stage_type === StageType.AIRFLOW) {
          lineSerie.get("tooltip").set("getStrokeFromSprite", false);
          lineSerie
            .get("tooltip")
            .get("background")
            .setAll({
              stroke: am5.color(0xbebebe)
            });
        }

        let series = chartState.series.push(lineSerie);

        // series is a required parameter to define dataContext.
        // DO NOT REMOVE.
        //@ts-ignore
        series?.bullets?.push(function (rootState, series, dataItem) {
          if (dataItem?.dataContext?.showBullet === true) {
            let circle = am5.Circle.new(rootState, {
              radius: 5,
              fill: lineSerie.get("fill"),
              fillOpacity: 1
            });
            if (metric.stage_type === StageType.AIRFLOW) {
              circle.set("fill", am5.color(0xbebebe));
            }
            return am5.Bullet.new(rootState, {
              sprite: circle
            });
          }
        });

        series.strokes.template.setAll({
          strokeWidth: 2
        });

        if (metric.dashed) {
          series.strokes.template.setAll({
            strokeDasharray: [8, 4]
          });
        }

        series?.data?.setAll(metric.data);
      }

      if (legendState) {
        if (defaultLegends) {
          legendState.data.setAll(defaultLegends);
        } else {
          legendState.data.setAll(chartState.series.values);
        }
      }

      if (horizontalRanges) {
        xAxisState.axisRanges.clear();
        for (let range of horizontalRanges) {
          const dataItem = xAxisState.makeDataItem({
            value: range.value,
            above: true
          });
          const rangeLine = xAxisState.createAxisRange(dataItem);
          rangeLine.get("grid").setAll({
            visible: true,
            strokeOpacity: 1,
            stroke: am5.color(range.color)
          });
        }
      }

      xAxisState.setAll({
        baseInterval: granularity
      });
    }
  }, [data, rootState, airflowAxis]);

  useEffect(() => {
    if (yTitle !== previousYTitle) {
      chartState?.leftAxesContainer.children.removeIndex(0);
      chartState?.leftAxesContainer.children.unshift(
        am5.Label.new(rootState, {
          text: yTitle,
          fontWeight: "500",
          rotation: 270,
          centerX: am5.p50,
          y: am5.p50,
          fontFamily: "Cera Pro"
        })
      );
      setPreviousYTitle(yTitle);
    }
  }, [yTitle]);

  useEffect(() => {
    if (rootState && ranges) {
      if (yAxisState) {
        yAxisState.axisRanges.clear();
      }
      let rangesDataItems = [];
      let max = Math.max(
        ...data.map(dataSet =>
          Math.max(...dataSet.data.map(item => item.value))
        )
      );
      let currentRange;

      for (let range of ranges) {
        range.nanoid = nanoid();
        let dataItem = yAxisState.makeDataItem({
          value: range.min,
          endValue: range.max
        });
        if (max >= range.min && max <= range.max) {
          currentRange = range;
        }
        yAxisState.createAxisRange(dataItem);
        dataItem.get("axisFill").setAll({
          visible: true,
          fillOpacity: 0.2,
          fill: am5.color(range.color)
        });
        rangesDataItems.push(dataItem);
      }
      if (maxLimit) {
        let limit = maxLimit(max);
        setMinLimit(0);
        setMaxLimit(limit);
      } else {
        if (minMaxYAxis) {
          setMinLimit(minMaxYAxis.min);
          setMaxLimit(minMaxYAxis.max);
          if (defaultYAxisZoom) {
            setStartZoomY(defaultYAxisZoom.min);
            setEndZoomY(defaultYAxisZoom.max);
          }
        } else if (currentRange) {
          let nextRange =
            ranges[
              ranges.findIndex(range => range.nanoid === currentRange.nanoid) +
                1
            ];
          if (nextRange) {
            setMinLimit(0);
            setMaxLimit(nextRange.min * 1.1);
          } else {
            setMinLimit(0);
            setMaxLimit(currentRange.max);
          }
        }
      }
      setRangeState(rangesDataItems);
    }
  }, [ranges, rangesColor]);

  useEffect(() => {
    if (yAxisState && minMaxYAxis) {
      if (
        minMaxYAxis.min != minLimitState ||
        minMaxYAxis.max != maxLimitState
      ) {
        yAxisState.setAll({
          max: minMaxYAxis.max,
          min: minMaxYAxis.min
        });
      }
    }
  }, [minMaxYAxis]);

  useEffect(() => {
    if (yAxisState) {
      yAxisState.zoomToValues(startZoomY, endZoomY);
    }
  }, [endZoomY]);

  useEffect(() => {
    if (yAxisState) {
      yAxisState.setAll({
        max: maxLimitState
      });
    }
  }, [maxLimitState]);

  return (
    <div
      id={`line_chart_${id}`}
      className={classes.chart}></div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {
      width: "100%"
    },
    chart: {
      display: "block",
      minHeight: 320
    }
  };
});

export default LinesChartComponent;
