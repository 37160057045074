import { NA } from "src/shared/constants/generals";
import { RoomStatus } from "./client";

import {
  EXCELLENT,
  GOOD,
  HAZARDOUS,
  MODERATE,
  SENSITIVE,
  UNHEALTHY,
  POOR
} from "src/shared/constants/building-status";
import { Device, FormattedBuilding } from "src/shared/types/building";
import get from "lodash/get";
import useGetPolutantDictionary from "src/hooks/useGetPollutantDictionary";
export const getBuildingStatus = (className: string) => {
  if (!className) {
    return NA;
  }

  let statusArray = [
    SENSITIVE,
    HAZARDOUS,
    UNHEALTHY,
    MODERATE,
    GOOD,
    EXCELLENT
  ];

  for (let status of statusArray) {
    let regExp = new RegExp(status, "i");
    if (regExp.test(className)) {
      return status;
    }
  }

  return NA;
};

export const getAQIStatus = (value: number) => {
  const { pollInfoDict } = useGetPolutantDictionary();
  let status = NA;

  if (pollInfoDict.aqi) {
    for (let range of pollInfoDict.aqi.statuses.thresholds) {
      if (value !== null && value >= range.low && value <= range.high) {
        status = range.status;
      }
    }
  }

  return status;
};

export const getMetricStatus = (
  metricName: string,
  value: number,
  dictionaries
) => {
  let status = NA;

  if (metricName === "voc_ppb") {
    metricName = "tvoc";
  }

  if (metricName === "total_aqi") {
    metricName = "aqi";
  }
  if (dictionaries[metricName] && dictionaries[metricName].statuses) {
    const thresholds = dictionaries[metricName].statuses.thresholds;
    for (let range of thresholds) {
      if (value !== null && value >= range.low && value <= range.high) {
        status = range.status;
      }
    }
    // check if value exceeds the highest range
    if (status === NA) {
      const maxRange = thresholds[thresholds.length - 1];
      if (value > maxRange.high) {
        status = maxRange.status;
      }
    }
  }

  return status;
};

export const getMetricClass = (score: number) => {
  if (isNaN(score)) {
    return NA;
  }
  if (score < 33) {
    return POOR;
  } else if (score > 66) {
    return EXCELLENT;
  } else {
    return MODERATE;
  }
};

export const GetCompanyIDFromBuildingId = (
  buildingId: string,
  buildings: FormattedBuilding[]
) => {
  const building = buildings?.find(b => b.id === buildingId);
  return building?.companyId;
};

export const getBuildingNameFromBuildingId = (
  buildingId: string,
  buildings: FormattedBuilding[]
) => {
  const building = buildings?.find(b => b.id === buildingId);
  return building?.name;
};

export const getValue = (value: number | null) =>
  typeof value === "number" ? Math.round(value) : NA;

export const getAQValueFromDevice = (device: Device, key: string) =>
  getValue(get(device, key));

export const getAQValueFromRoom = (room: RoomStatus, key: string) =>
  getValue(get(room, key));

export const getAQValueFromBuilding = (
  building: FormattedBuilding,
  key: string
) => getValue(get(building, key));

export const getDeviceCountFromBuilding = (
  building: FormattedBuilding,
  key: string
) => getValue(get(building, key));
