export const getAuthInfo = () => {
  let token = localStorage.getItem("token");
  if (token) {
    return token;
  }
  return null;
};

export const currentUserName = () => {
  let userName = localStorage.getItem("name");
  return userName || "none";
};
