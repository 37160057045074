import axios from "axios";
import { apiService } from "../api";

const fetchPdf = (
  companyId: string,
  buildingId: string,
  startTs: string,
  endTs: string
) => {
  let token = window.localStorage.getItem("token");
  const data =
    startTs && endTs
      ? {
          start: startTs,
          end: endTs
        }
      : null;
  return axios.post(
    `${API_BASE_URL}/reports/pdf/?company_id=${companyId}&building_id=${buildingId}`,
    data,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`
      },
      responseType: "blob"
    }
  );
};

export const reportsService = apiService
  .enhanceEndpoints({
    addTagTypes: ["REPORT"]
  })
  .injectEndpoints({
    endpoints: build => ({
      report: build.mutation<
        any,
        {
          companyId: string;
          buildingId: string;
          startTs: string;
          endTs: string;
        }
      >({
        // @ts-ignore
        queryFn: async data => {
          const { companyId, buildingId, startTs, endTs } = data;

          return await fetchPdf(companyId, buildingId, startTs, endTs);
        },
        invalidatesTags: []
      })
    })
  });

export const { useReportMutation } = reportsService;
