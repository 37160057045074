import {
  Button,
  FormControl,
  MenuItem,
  Typography,
  OutlinedInput,
  Select,
  Checkbox,
  ListItemText,
  SelectChangeEvent
} from "@mui/material";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import {
  handleDownloadModal,
  setDownloadModalEntity,
  setDownloadModalEntityId,
  setExpandedAhusById,
  setSelectedAhuTableOptions
} from "src/store/hvac/reducer";
import {
  getExpandedAhusById,
  getSelectedAhuTableOptions
} from "src/store/hvac/selectors";
import { useGetAhusWithFilterLoadQuery } from "src/store/services/hvac/hvac.service";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import {
  AHU_VIEW_OPTION_LOAD,
  AHU_VIEW_OPTION_PRESSURE,
  AHU_VIEW_OPTION_REMAINING
} from "src/shared/constants/ahu-status";
import useGetAppBreakPoints from "src/hooks/useGetAppBreakPoints";
import { makeStyles } from "tss-react/mui";
import { useParams } from "react-router-dom";
const viewOptions = [
  AHU_VIEW_OPTION_LOAD,
  AHU_VIEW_OPTION_PRESSURE,
  AHU_VIEW_OPTION_REMAINING
];

const AhuTableActions = () => {
  const { buildingId } = useParams();
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const expandedIds = useAppSelector(getExpandedAhusById);
  const selectedOptions = useAppSelector(getSelectedAhuTableOptions);
  const { data: ahus } = useGetAhusWithFilterLoadQuery(buildingId, {
    skip: !buildingId
  });
  const { isSm } = useGetAppBreakPoints();

  const handleOnExpandCollapse = () => {
    const ahuIds = ahus.map(({ id }) => id);

    if (ahuIds.length === expandedIds.length) {
      dispatch(setExpandedAhusById([]));
    } else {
      dispatch(setExpandedAhusById(ahuIds));
    }
  };

  const handleDownloadClick = () => {
    dispatch(handleDownloadModal(true));
    dispatch(setDownloadModalEntity("building"));
    dispatch(setDownloadModalEntityId(buildingId));
  };
  const handleOptionChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value }
    } = event;
    dispatch(setSelectedAhuTableOptions(value));
  };

  return (
    <>
      <Button
        onClick={handleOnExpandCollapse}
        variant="contained">
        Expand / Collapse All
      </Button>
      <div style={{ marginRight: "0.5rem" }}>
        <FormControl>
          <Button
            onClick={handleDownloadClick}
            variant="contained"
            className={classes?.downloadBtn}>
            Download
          </Button>
        </FormControl>

        {!isSm && (
          <FormControl>
            <Select
              multiple
              value={selectedOptions}
              onChange={handleOptionChange}
              input={<OutlinedInput />}
              displayEmpty
              renderValue={() => (
                <div className={classes?.viewOptions}>
                  <SettingsIcon
                    sx={{
                      marginRight: "0.2em",
                      color: "#446DFF",
                      fontSize: 18
                    }}
                  />
                  <Typography>Configure View</Typography>
                </div>
              )}>
              {viewOptions.map(name => (
                <MenuItem
                  key={name}
                  value={name}>
                  <Checkbox checked={selectedOptions.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </div>
    </>
  );
};
export const useStyles = makeStyles()(() => {
  return {
    root: {
      display: "flex",
      justifyContent: "space-between"
    },
    viewOptions: {
      display: "flex",
      alignItems: "center"
    },
    downloadBtn: {
      marginRight: "1em",
      marginTop: 1
    }
  };
});

export default AhuTableActions;
