import { Dispatch, SetStateAction } from "react";
import useUserPascalSettings from "src/hooks/useUserPascalSettings";
import { Typography, TextField } from "@mui/material";
import { capitalizeFirstLetter } from "src/utils/text.utils";
import { PressureUnits } from "src/utils/client";
import { Range } from "src/utils/pressure.utils";

type RangeProps = {
  ranges: Range[];
  range: Range;
  index: number;
  setRanges: Dispatch<SetStateAction<Range[]>>;
  isPSI?: boolean;
};

const SelectRange = ({
  ranges,
  range,
  index,
  setRanges,
  isPSI = false
}: RangeProps) => {
  const isUserUsingPascal = useUserPascalSettings();
  const inputSteps = isUserUsingPascal ? 1 : 0.05;

  const valueSteps = isUserUsingPascal ? 0 : 2;

  const unit = isPSI
    ? "PSI"
    : isUserUsingPascal
    ? capitalizeFirstLetter(PressureUnits.PASCALS)
    : PressureUnits.IWG.toUpperCase();

  const handleOnChange = (value: string, type: "from" | "to" | "color") => {
    const updatedRange = updateRange(
      ranges,
      range,
      index,
      type === "color" ? value : Number(value),
      type
    );

    setRanges(updatedRange);
  };

  return (
    <div
      style={{ display: "flex", gap: 12, alignItems: "center" }}
      data-testId="range">
      <Typography data-testId="number">{index + 1}</Typography>
      <TextField
        label="From"
        type="number"
        value={range.from.toFixed(valueSteps)}
        sx={{ width: 150 }}
        inputProps={{ step: inputSteps }}
        InputProps={{
          endAdornment: <Typography variant="body2">{unit}</Typography>
        }}
        onChange={({ target: { value } }) => {
          handleOnChange(value, "from");
        }}
      />
      <TextField
        label="To"
        type="number"
        value={range.to.toFixed(valueSteps)}
        inputProps={{ step: inputSteps }}
        sx={{ width: 150 }}
        InputProps={{
          endAdornment: <Typography variant="body2">{unit}</Typography>
        }}
        onChange={({ target: { value } }) => {
          handleOnChange(value, "to");
        }}
      />
      <TextField
        label="Color"
        type="color"
        sx={{ width: 75 }}
        value={range.color}
        onChange={({ target: { value } }) => {
          handleOnChange(value, "color");
        }}
      />
    </div>
  );
};

export default SelectRange;

const updateRange = (
  ranges: Range[],
  currentRange: Range,
  index: Number,
  newValue: number | string,
  newKey: string
) => {
  return Object.keys(ranges).map(key => {
    if (Number(key) === index) {
      return Object.assign({}, currentRange, { [newKey]: newValue });
    }
    return ranges[key];
  });
};
