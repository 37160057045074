import {
  Chart as ChartJS,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-date-fns";
import zoomPlugin from "chartjs-plugin-zoom";
import moment from "moment";
import annotationPlugin from "chartjs-plugin-annotation";
import useUserPascalSettings from "src/hooks/useUserPascalSettings";
import useMediaQuery from "src/hooks/useMediaQuery";
import { useRef } from "react";
import { Button } from "@mui/material";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";

const LOWER_MIN_VALUE = 0.9;

type Range = { max: number; min: number; color: string };
const plugin = {
  id: "customCanvasBackgroundColor",
  beforeDraw: (chart, _args, options) => {
    const drawBgColors = options.drawBgColors;
    if (!drawBgColors) return;
    const ranges: Range[] = options.bgRanges;

    // We will draw rectengle directly on canvas
    const {
      ctx,
      chartArea: { left, width, height },
      scales: { y },
      legend: { legendItems }
    } = chart;

    const maxYValue = y.max;
    const minYValue = y.min;
    const isAllLinesUnselected = legendItems.every(({ hidden }) => hidden);
    ranges.map((range, index) => drawBgColor(range, index));

    function drawBgColor(range: Range, index: number) {
      const { color, max, min } = range;
      ctx.save();
      const topYposition = y.getPixelForValue(
        ranges.length - 1 === index ? (y.max > max ? y.max : max) : max
      );
      const maxYValueOnAxis = y.getPixelForValue(maxYValue);

      const rectMax =
        min < maxYValue && max > maxYValue ? maxYValueOnAxis : topYposition;

      const rectMin =
        min < minYValue
          ? y.getPixelForValue(minYValue)
          : y.getPixelForValue(min);

      ctx.fillStyle = color;

      ctx.fillRect(
        left,
        rectMax,
        width,
        isAllLinesUnselected ? height : rectMin - rectMax
      );
      ctx.restore();
    }
  }
};

export default function LineChart({
  data,
  isChartReponsive = true,
  maintainAspectRatio = true,
  drawBgColors = false,
  bgRanges = [],
  showTerminalPressure = false,
  showSecondYAxis = false,
  showFirstYAxisTitle = true,
  expectedTerminalPressure = 0,
  isKettle = false,
  customYTitle = null,
  customYMinValue = null,
  customYMaxValue = null,
  enableZoom = true,
  isWidget = false
}) {
  if (!data.length) return;
  const isTablet = useMediaQuery("(max-width: 1024px)");
  const isUserUsingPascal = useUserPascalSettings();
  const chartRef = useRef(null);
  const enableInteraction = enableZoom || !isTablet;
  const yAxisTitle = customYTitle
    ? customYTitle
    : `Pressure (${isKettle ? "PSI" : isUserUsingPascal ? "Pa" : "IWG"})`;
  const xAxesDates = [
    ...data.flatMap(({ data }) => data.map(({ date }) => date))
  ] as number[];

  const yAxesValues: number[] = data.flatMap(({ data }) =>
    data.map(({ value }) => value)
  );

  const yMaxValue = customYMaxValue ?? Math.max(...yAxesValues);
  const minXAxisZoomLimit = getOldestDate(xAxesDates)
    .subtract(1, "day")
    .startOf("day")
    .valueOf();

  const maxXAxisZoomLimit = getNewestDate(xAxesDates).endOf("day").valueOf();

  const minYAxisZoomLimit =
    customYMinValue ?? expectedTerminalPressure !== 0
      ? expectedTerminalPressure > 0
        ? expectedTerminalPressure * LOWER_MIN_VALUE
        : expectedTerminalPressure * 1.1
      : Math.min(...yAxesValues) * LOWER_MIN_VALUE;

  // Limit zoom of user with max value and adding 10% to it
  const maxYAxisZoomLimit =
    expectedTerminalPressure > 0
      ? expectedTerminalPressure * 1.1
      : yMaxValue * 1.1;

  const chartOptions = {
    responsive: isChartReponsive,
    maintainAspectRatio,
    parsing: {
      xAxisKey: "date",
      yAxisKey: "value"
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day"
        }
      },
      y: {
        type: "linear",
        display: true,
        position: "left",
        title: {
          display: showFirstYAxisTitle,
          text: yAxisTitle,
          font: {
            size: 16,
            weight: "600"
          }
        },
        ticks: {
          // numbers on Y axis
          callback: function (val: number) {
            if (val < 10) return val.toFixed(2);
            return val.toFixed();
          }
        }
      },
      // Second Y axis
      y1: {
        type: "linear",
        display: showSecondYAxis,
        position: "right",
        title: {
          display: true,
          text: "Airflow (M/S)",
          font: {
            size: 16,
            weight: "600"
          }
        },
        // grid line settings
        grid: {
          drawOnChartArea: false // only want the grid lines for one axis to show up
        }
      }
    },

    plugins: {
      tooltip: {
        enabled: true,

        callbacks: {
          beforeLabel: ctx => {
            const { showBullet, worker, description, title } = ctx.raw;
            if (!showBullet) return;

            return `${title} \n ${description} \n ${worker}`;
          }
        }
      },
      // limit lines
      annotation: {
        annotations: {
          type: "line",
          borderColor: "red",
          label: {
            // control when we show doted line
            display: showTerminalPressure,
            backgroundColor: "red",
            borderColor: "red",
            borderRadius: 10,
            borderDash: [6, 6],
            borderWidth: 2,
            content: () =>
              "Expected Terminal Pressure:" + expectedTerminalPressure,
            rotation: "auto"
          },
          scaleID: "y",
          value: expectedTerminalPressure
        }
      },
      customCanvasBackgroundColor: {
        drawBgColors,
        bgRanges
      },
      legend: {
        position: "bottom" as const
      },
      zoom: {
        limits: {
          y: {
            min: customYMinValue ?? minYAxisZoomLimit,
            max: customYMaxValue ?? maxYAxisZoomLimit
          },
          x: {
            min: minXAxisZoomLimit,
            max: maxXAxisZoomLimit
          }
        },
        // controls for zoom
        zoom: {
          wheel: {
            enabled: isWidget ? false : enableInteraction
          },
          pinch: {
            enabled: enableInteraction
          },
          drag: {
            enabled: enableInteraction
          },
          mode: "xy"
        }
      },
      pan: {
        enabled: enableInteraction,
        mode: "xy",
        speed: 100
      }
    }
  };

  const handleResetZoom = () => {
    if (!chartRef?.current) return;
    chartRef.current.resetZoom();
  };
  return (
    <>
      <Line
        // @ts-ignore
        options={chartOptions}
        ref={chartRef}
        data={{
          labels: data[0]?.labels,
          datasets: data
        }}></Line>
      {enableInteraction && (
        <Button
          size="small"
          onClick={handleResetZoom}
          variant="contained"
          sx={{
            position: "absolute",
            bottom: 5,
            right: 60
          }}>
          <ZoomInMapIcon></ZoomInMapIcon>
        </Button>
      )}
    </>
  );
}

ChartJS.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin,
  plugin,
  annotationPlugin
);

const getNewestDate: typeof getOldestDate = dates => {
  const formatedDates = getAllDates(dates);
  return moment.max(formatedDates);
};

const getOldestDate = (dates: number[]) => {
  const formatedDates = getAllDates(dates);
  return moment.min(formatedDates);
};

const getAllDates = (dates: number[]) => dates.map(d => moment(d));
