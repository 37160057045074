import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => {
  return {
    alert: {
      display: "flex",
      margin: "0.5rem 0rem 0.5rem 1rem",
      borderTop: "2px solid rgba(0, 0, 0, 0.1)",
      "&:first-of-type": {
        borderTop: 0
      }
    },
    connectionItem: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: "-1rem"
    },
    connection: {
      color: "#fff",
      padding: "2px",
      borderRadius: "1px",
      boxShadow: "0.2rem 0.1rem 0.1rem rgba(0, 0, 0, 0.2)"
    },
    item: {
      display: "flex",
      alignItems: "center",
      padding: "2px"
    },
    alertTitle: {
      fontWeight: 500
    },
    stage: {
      fontWeight: 500
    },
    descriptionItem: {
      display: "flex",
      alignItems: "center",
      padding: "2px 2px 2px 2rem"
    },
    descriptionPair: {
      display: "flex"
    },
    descriptionKey: {
      fontWeight: 500,
      marginRight: "4px"
    },
    offline: {
      fontWeight: 500,
      marginRight: "2px",
      color: "rgb(232, 37, 71)"
    },
    dateItem: {
      display: "flex",
      alignItems: "end",
      justifyContent: "center",
      flexDirection: "column",
      padding: "2px"
    },
    date: {
      paddingRight: "0.5rem",
      color: "rgba(0, 0, 0, 0.5)"
    },
    margin: {
      margin: "0.5rem 0rem"
    },
    stageItem: {
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
      padding: "2px"
    },
    descriptionValue: {
      paddingLeft: "1rem"
    },
    iconItem: {
      display: "flex",
      alignItems: "center",
      padding: "2px",
      marginLeft: "0.5rem"
    }
  };
});
