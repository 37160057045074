import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";

const selectHvac = (state: RootState) => state.hvac;

export const getIsTableExpanded = createSelector(
  selectHvac,
  state => state.tableExpanded
);

export const getAhuModalState = createSelector(
  selectHvac,
  state => state.ahuModalOpen
);
export const getDownloadModalState = createSelector(
  selectHvac,
  state => state.downloadModalOpen
);

export const getDownloadModalEntity = createSelector(
  selectHvac,
  state => state.downloadModalEntity
);
export const getDownloadModalEntityId = createSelector(
  selectHvac,
  state => state.downloadModalEntityId
);
export const getSelectedAhu = createSelector(
  selectHvac,
  state => state.selectedAHU
);
export const getIsEditingAhu = createSelector(
  selectHvac,
  state => state.isEditingAhu
);
export const getHvacSearchTerm = createSelector(
  selectHvac,
  state => state.searchHvacTerm
);
export const getAirQualitySearchTerm = createSelector(
  selectHvac,
  state => state.airQualitySearchTerm
);
export const getDevicesSearchTerm = createSelector(
  selectHvac,
  state => state.deviceSearchTerm
);
export const getSelectedAhuTableOptions = createSelector(selectHvac, state => {
  return state.selectedAhuTableOptions;
});
export const getHvacView = createSelector(selectHvac, state => state.view);
/**
 * Gets all expanded ahus on hvac page to show more data to user
 */
export const getExpandedAhusById = createSelector(
  selectHvac,
  state => state.expandedAhusById
);

export const getFilterStageModalState = createSelector(
  selectHvac,
  state => state.filterStageModalOpen
);

export const getRoomModalState = createSelector(
  selectHvac,
  state => state.roomModalOpen
);

export const getSelectedRoom = createSelector(
  selectHvac,
  state => state.selectedRoom
);

export const getIsEditingRoom = createSelector(
  selectHvac,
  state => state.isEditingRoom
);
export const getDeviceModalState = createSelector(
  selectHvac,
  state => state.deviceModalOpen
);

export const getDeviceGraphGranuality = createSelector(
  selectHvac,
  state => state.deviceGraphGranuality
);

export const getSelectedDeviceMetric = createSelector(
  selectHvac,
  state => state.selectedDeviceMetric
);

export const getDeviceGraphStartDate = createSelector(selectHvac, state => {
  return state.deviceGraphStartDate;
});

export const getDeviceGraphEndDate = createSelector(
  selectHvac,
  state => state.deviceGraphEndData
);

export const getIsEditingDevice = createSelector(
  selectHvac,
  state => state.isEditingDevice
);

export const getSelectedAhuDetailsStageId = createSelector(
  selectHvac,
  state => state.selectedAhuDetailsStageId
);

export const getAhuDetailsStartDate = createSelector(
  selectHvac,
  state => state.ahuDetailsStartDate
);

export const getAhuDetailsEndDate = createSelector(
  selectHvac,
  state => state.ahuDetailsEndDate
);

export const getStageToEdit = createSelector(
  selectHvac,
  state => state.stageToEdit
);

export const getUserClickedOnWidgetTitle = createSelector(
  selectHvac,
  state => state.userClickedOnWidgetTitle
);

export const getAhuChartGranularity = createSelector(
  selectHvac,
  state => state.granularity
);
