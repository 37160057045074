import { Pollutant } from "./aq-widget-enums";
import { BuildingOverviewRow } from "src/utils/client";

const INDOOR_POLLUTANTS = "indoor";

export type SortOrder = "asc" | "desc";

const formatPollutantKey = (pollutant: Pollutant) => {
  switch (pollutant) {
    case Pollutant.aqiTitle:
      return Pollutant.aqi;
    case Pollutant.cotwoTitle:
      return Pollutant.cotwo;
    case Pollutant.vocTitle:
      return Pollutant.voc;
    case Pollutant.pmtwoTitle:
      return Pollutant.pmtwo;
    case Pollutant.pmtenTitle:
      return Pollutant.pmten;
    default:
      return Pollutant.aqi;
  }
};

const descendingComparator = (
  deviceA: BuildingOverviewRow,
  deviceB: BuildingOverviewRow,
  selectedPollutant: Pollutant
) => {
  const a = deviceA[INDOOR_POLLUTANTS][selectedPollutant] ?? 0;
  const b = deviceB[INDOOR_POLLUTANTS][selectedPollutant] ?? 0;
  return b - a;
};

const getComparator = (order: SortOrder, selectedPollutant: Pollutant) => {
  return order === "desc"
    ? (deviceA: BuildingOverviewRow, deviceB: BuildingOverviewRow) =>
        descendingComparator(deviceA, deviceB, selectedPollutant)
    : (deviceA: BuildingOverviewRow, deviceB: BuildingOverviewRow) =>
        -descendingComparator(deviceA, deviceB, selectedPollutant);
};

export const sortRows = (
  data: BuildingOverviewRow[],
  order: SortOrder,
  selectedPollutant: Pollutant
) => {
  return data
    ?.slice()
    .sort(getComparator(order, formatPollutantKey(selectedPollutant)));
};
