import { makeStyles } from "tss-react/mui";
import { useMemo } from "react";
import get from "lodash/get";
import ForestIcon from "@mui/icons-material/Forest";
import LoaderComponent from "src/shared/components/loader/loader.component";
import { Typography, Grid } from "@mui/material";
import { DEFAULT } from "src/shared/constants/building-status";
import { useGetUserSettings } from "src/sections/settings/hooks";
import { FormattedBuildingWithEntity } from "src/shared/types/building";
import WidgetTitle from "../../components/WidgetTitle/WidgetTitle";
import Error from "src/shared/components/Error/Error";
import usePaleteColor from "src/hooks/usePaleteColor";
import useGetPolutantDictionary from "src/hooks/useGetPollutantDictionary";
import useGetOutdoorAqData from "../../hooks/useGetOutdoorAQData";
import useOverview from "src/hooks/useOverview";
import { shapeProgressBarData } from "../../utils/aq-progress-bars-utils";
import AQIOverallScore from "../../components/AqOverallScore/aqi-overall-score.component";
import ProgressBars from "../../components/ProgressBars/progress-bars.component";
import moment from "moment";
import { NA } from "src/shared/constants/generals";
import { useDispatch } from "react-redux";
import { setSelectedOverviewTab } from "src/store/overview/reducer";
import { setUserClickedOnWidgetTitle } from "src/store/hvac/reducer";
import { useNavigate } from "react-router-dom";
import { useOverviewTab } from "../../hooks/useGetOverviewTab";

type Props = {
  buildingId: string;
  building: FormattedBuildingWithEntity;
  requiresConfig?: boolean;
};
const keys = ["t", "h", "co", "so2", "no2", "pmtwo", "pmten", "o3"];

const PALETTE_STATUS = "status";

const OutdoorAqWidgetComponent = ({
  buildingId,
  building,
  requiresConfig
}: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { editing } = useOverview();
  const { pollInfoDict } = useGetPolutantDictionary();
  const { temperature } = useGetUserSettings();
  const { classes } = useStyles();
  const { tabId } = useOverviewTab();

  const { outdoorAqData, isLoading, isError, refetch } = useGetOutdoorAqData(
    buildingId,
    requiresConfig
  );

  const outdoorDataExist = Boolean(outdoorAqData);

  const outdoorStatus = useMemo(
    () => get(outdoorAqData, "aqis.aqi.category") ?? DEFAULT,
    [outdoorDataExist]
  );

  const outdoorAqi = useMemo(
    () => get(outdoorAqData, "aqis.aqi.index") ?? NA,
    [outdoorDataExist]
  );

  const { statusColor } = usePaleteColor(PALETTE_STATUS, outdoorStatus);

  const formattedData = useMemo(() => {
    if (!outdoorDataExist) return;
    return shapeProgressBarData(
      keys,
      outdoorAqData.data.data as any,
      pollInfoDict,
      temperature
    );
  }, [outdoorDataExist]);

  const handleTitleClick = () => {
    if (editing) return;
    dispatch(setSelectedOverviewTab(tabId));
    dispatch(setUserClickedOnWidgetTitle(true));
    navigate(`/buildings/iaq/${buildingId}`);
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div
          className={editing ? classes.default : classes.pointer}
          onClick={handleTitleClick}>
          <div style={{ marginRight: "0.5rem" }}>
            <ForestIcon fontSize="small" />
          </div>
          <Typography variant="h6">Outdoor Air Quality</Typography>
        </div>
      </div>

      {requiresConfig ? (
        <Typography>Configuration is required</Typography>
      ) : isLoading ? (
        <LoaderComponent />
      ) : (
        <>
          {!isError && (
            <WidgetTitle
              error={isError}
              building={building}
            />
          )}
          <div className={classes.widgetContent}>
            {isError ? (
              <Error
                setRefetchAgain={refetch}
                error={isError}
              />
            ) : (
              <>
                <Grid
                  container
                  spacing={1}
                  className={classes.contentContainer}>
                  <AQIOverallScore
                    title={"Outdoor AQI"}
                    color={statusColor}
                    value={Number(outdoorAqi)}
                    status={outdoorStatus}
                  />
                  <ProgressBars data={formattedData} />
                </Grid>
                {building && (
                  <Typography
                    variant="subtitle1"
                    className={classes.location}>
                    {`${building.location.city}, ${building.location.country}`}
                    {" || "}
                    {moment().subtract(2, "weeks").format("ll")} -{" "}
                    {moment().format("ll")}
                  </Typography>
                )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {
      height: "100%",
      padding: "10px"
    },
    header: {
      display: "flex"
    },
    widgetContent: {
      width: "100%",
      display: "flex",
      flexDirection: "column"
    },
    contentContainer: {
      height: "100%",
      overflow: "hidden",
      "@media(max-width:900px)": {
        overflow: "auto"
      }
    },
    location: {
      position: "absolute",
      bottom: 0,
      right: 16,
      fontSize: "14px !important"
    },
    default: {
      display: "flex",
      cursor: "default"
    },
    pointer: {
      display: "flex",
      cursor: "pointer",
      "&:hover": { color: "#0795bb" }
    }
  };
});

export default OutdoorAqWidgetComponent;
