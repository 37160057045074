import { makeStyles } from "tss-react/mui";
export const useStyles = makeStyles()(theme => {
  return {
    root: {
      transition: "all 250ms",
      "&:hover": {
        backgroundColor: theme.palette.grey["200"]
      },
      cursor: "pointer",
      backgroundColor: "white",
      marginBottom: "0.5em",
      padding: 16,
      paddingTop: 4,
      position: "relative" as const,
      overflow: "hidden"
    },
    row: {
      borderRadius: 0,
      boxShadow: "none",
      paddingRight: 24,
      paddingLeft: 24,
      margin: 0,
      position: "relative" as const,
      paddingBottom: 8
    },
    column: {
      whiteSpace: "nowrap" as const,
      textOverflow: "ellipsis",
      overflow: "hidden",
      padding: 8,
      paddingLeft: 5,
      paddingBottom: 0
    },
    name: {
      fontSize: "18px",
      fontWeight: 500,
      padding: 8
    },
    doorNumber: {
      whiteSpace: "nowrap" as const,
      textOverflow: "ellipsis",
      overflow: "hidden",
      padding: 6,
      paddingLeft: 16
    },
    address: {
      whiteSpace: "nowrap" as const,
      textOverflow: "ellipsis",
      overflow: "hidden",
      padding: 0,
      lineHeight: 2.5,
      "& svg": {
        marginRight: "0.5em",
        fontSize: "18px",
        color: "#273B4A",
        opacity: 0.5,
        position: "relative" as const,
        top: 4
      }
    },
    centered: {
      textAlign: "center" as const,
      paddingTop: 8
    },
    devices: {
      textAlign: "center" as const,
      border: "1px solid #E9EAEA",
      borderRadius: 4,
      paddingBottom: 2
    },
    qtyContainer: {
      minWidth: 58,
      width: "fit-content",
      padding: 8,
      "& h4": {
        fontSize: "16px",
        marginTop: 0
      }
    },
    selected: {
      backgroundColor: theme.palette.grey["300"]
    },
    fullReportMap: {
      position: "absolute" as const,
      top: 16,
      right: 16,
      fontSize: "13px"
    },

    footer: {
      backgroundColor: "#F8F9FF",
      fontSize: "13px",
      position: "relative" as const,
      top: 16,
      left: -16,
      padding: 8,
      width: "calc(100% + 32px)",
      display: "flex",
      justifyContent: "end"
    },
    itemCentered: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    pollutantQty: {
      fontWeight: 400,
      marginTop: 8
    }
  };
});
