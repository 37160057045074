import { useState } from "react";
import { makeStyles } from "tss-react/mui";
import { WidgetSettings } from "../widgets-config";
import { GetCompanyIDFromBuildingId } from "src/utils/buildings.util";
import useGetBuildings from "src/hooks/useGetBuildings";
import FormInput from "src/shared/components/form-input/form-input.component";
import FormAction from "src/shared/components/form-action/form-action";

const DecayAndFiltrationEfficiencySettingsComponent = ({
  onSave,
  currentProps
}: WidgetSettings) => {
  const { classes } = useStyles();
  const { buildings } = useGetBuildings();
  const [buildingId, setBuildingId] = useState(currentProps.buildingId);
  const handleOnSave = () => {
    onSave({
      ...currentProps,
      buildingId,
      companyId: GetCompanyIDFromBuildingId(buildingId, buildings)
    });
  };

  return (
    <div
      data-testid="cotwo-decay-settings"
      className={classes.root}>
      <FormInput
        label="Building"
        inputValue={buildingId}
        setValue={setBuildingId}
        array={buildings}></FormInput>
      <FormAction
        requiresConfig={currentProps.requiresConfig}
        onSave={handleOnSave}
        saveDisabled={buildingId}></FormAction>
    </div>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: { padding: "10px" },

    loader: {
      position: "absolute",
      top: 0,
      left: 0
    }
  };
});

export default DecayAndFiltrationEfficiencySettingsComponent;
