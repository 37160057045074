import { Stack, Typography } from "@mui/material";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import ModalComponent from "src/shared/components/modal/modal.component";
import { setModalState } from "src/store/booth/reducer";
import { getModalState, getUserAction } from "src/store/booth/selector";
import { Field, Form } from "react-final-form";
import moment from "moment";
import {
  FormHelperText,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Box,
  Select,
  MenuItem
} from "@mui/material";
import { renderWeekPickerDay } from "src/utils/date-picker.utils";
import { AnyObject } from "final-form";
import LoaderComponent from "src/shared/components/loader/loader.component";
import {
  useCreatePaintBoothSetPointMutation,
  useDeletePaintBoothSetPointMutation,
  useGetPaintBoothSetPointsQuery
} from "src/store/services/booth/booth.service";
import { getUserId } from "src/store/auth/selectors";
import { useState } from "react";
import ConfirmationDialog from "src/shared/components/ConfirmationDialog";
import { useParams } from "react-router-dom";
import qlairLoader from "src/assets/qlair-load.gif";
import { DateTimePicker } from "@mui/x-date-pickers";

const options = [
  {
    id: "1",
    value: "booth_balanced",
    name: "Booth Balanced"
  },
  {
    id: "2",
    value: "filter_change",
    name: "Filter Changed"
  },
  {
    id: "3",
    value: "booth_sealed",
    name: "Booth Sealed"
  },
  {
    id: "4",
    value: "good_paint_quality",
    name: "Good Paint Quality"
  },
  {
    id: "5",
    value: "poor_paint_quality",
    name: "Poor Paint Quality"
  },
  {
    id: "6",
    value: "other",
    name: "Other"
  }
];

const SetPointsModal = () => {
  const dispatch = useAppDispatch();
  const { boothId, buildingId } = useParams();
  const userId = useAppSelector(getUserId);
  const isOpen = useAppSelector(getModalState);
  const startTs = moment().subtract(5, "month").format();
  const isUserAdding = useAppSelector(getUserAction) === "adding";
  const [openComfirm, setOpenComfirm] = useState(false);
  const [selectedCreatedTime, setSelectedCreatedTime] = useState(null);

  const { data: setPoints, isLoading: isLoadingSetPoints } =
    useGetPaintBoothSetPointsQuery(
      {
        buildingId,
        boothId,
        startTs
      },
      { skip: isUserAdding }
    );

  const [createPaintBoothSetPoint, { isLoading: isCreating }] =
    useCreatePaintBoothSetPointMutation();

  const [deletePaintBoothSetPoint, { isLoading: isDeleting }] =
    useDeletePaintBoothSetPointMutation();

  const loading = isCreating || isDeleting || isLoadingSetPoints;

  const initialValues = {
    booth_id: null,
    notes: null,
    userId: null,
    set_point_time: moment()
  };
  const handleOnClose = () => {
    dispatch(setModalState(false));
    setOpenComfirm(false);
  };
  const handleValidate = (values: AnyObject) => {
    const errors: AnyObject = {};

    const { type, notes, set_point_time, title } = values;

    if (isUserAdding) {
      if (!Boolean(notes)) {
        errors.comment = "Comment is required";
      }
      if (!Boolean(set_point_time)) {
        errors.set_point_time = "Date is required";
      }
      if (!Boolean(title)) {
        errors.title = "Title is required";
      }
      if (!Boolean(type)) {
        errors.type = "Type is required";
      }
    }

    return errors;
  };
  const handleSubmit = (setPoint: any) => {
    const requestObject = {
      buildingId,
      body: {
        ...setPoint,
        title: setPoint?.title ?? "",
        booth_id: boothId,
        user_id: userId,
        building_id: buildingId,
        set_point_time: setPoint?.set_point_time?.toISOString()
      }
    };

    createPaintBoothSetPoint(requestObject)?.finally(() => handleOnClose());
  };

  const handleDelete = () => {
    const boothId = setPoints.find(
      ({ created_at }) => created_at === selectedCreatedTime
    )?.booth_id;
    deletePaintBoothSetPoint({
      buildingId,
      boothId,
      created_at: selectedCreatedTime
    })?.finally(() => handleOnClose());
  };
  return (
    <>
      <ModalComponent
        open={isOpen}
        onClose={handleOnClose}>
        {loading ? (
          <Box
            data-testid="loading-cmp"
            sx={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}>
            <img
              src={qlairLoader}
              width={150}
            />
          </Box>
        ) : (
          <Stack>
            <Typography
              variant="h6"
              gutterBottom>
              {`${isUserAdding ? "Add" : "Remove"} Set Point`}
            </Typography>
            <Form
              initialValues={initialValues}
              validate={handleValidate}
              onSubmit={handleSubmit}
              render={({ handleSubmit, hasValidationErrors, form }) => {
                return (
                  <form
                    onSubmit={handleSubmit}
                    style={{ maxWidth: 350 }}>
                    {false && <LoaderComponent />}
                    {isUserAdding && (
                      <>
                        <Field name="type">
                          {({ input, meta }) => (
                            <FormControl fullWidth>
                              <InputLabel>Category*</InputLabel>
                              <Select
                                data-testid="type-field"
                                label="Category*"
                                sx={styles.textInput}
                                error={Boolean(meta.touched && meta.error)}
                                inputProps={input}>
                                {options?.map(({ id, value, name }) => (
                                  <MenuItem
                                    key={`booth-${id}`}
                                    value={value}>
                                    {`${name}`}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText>
                                e.g. Filter Changed, Booth Balanced
                              </FormHelperText>
                            </FormControl>
                          )}
                        </Field>
                        <Field name="title">
                          {({ input, meta }) => (
                            <TextField
                              label="Title*"
                              inputProps={{ maxLength: 100 }}
                              rows={2}
                              sx={styles.textInput}
                              error={Boolean(meta.touched && meta.error)}
                              helperText={meta.touched && meta.error}
                              InputProps={input}
                            />
                          )}
                        </Field>
                        <Field name="notes">
                          {({ input, meta }) => (
                            <TextField
                              label="Comment*"
                              inputProps={{ maxLength: 100 }}
                              rows={2}
                              sx={styles.textInput}
                              error={Boolean(meta.touched && meta.error)}
                              helperText={meta.touched && meta.error}
                              InputProps={input}
                            />
                          )}
                        </Field>
                        <Field name="set_point_time">
                          {({ input, meta }) => (
                            <DateTimePicker
                              onChange={value => {
                                form.change("set_point_time", value);
                              }}
                              value={form.getState().values.set_point_time}
                              label="Date*"
                              renderDay={renderWeekPickerDay}
                              renderInput={params => (
                                <TextField
                                  placeholder={null}
                                  label="Date(UTC)*"
                                  data-testid="date-input"
                                  style={{ width: "100%" }}
                                  sx={styles.textInput}
                                  error={
                                    Boolean(meta.touched) && Boolean(meta.error)
                                  }
                                  helperText={meta.touched && meta.error}
                                  InputProps={input}
                                  {...params}></TextField>
                              )}
                            />
                          )}
                        </Field>
                      </>
                    )}
                    {!isUserAdding && (
                      <FormControl fullWidth>
                        <InputLabel>Select*</InputLabel>
                        <Select
                          label="Select*"
                          sx={styles.textInput}
                          onChange={event =>
                            setSelectedCreatedTime(event.target.value)
                          }>
                          {setPoints?.map(({ created_at, title }) => (
                            <MenuItem
                              key={`booth-${created_at}`}
                              value={created_at}>
                              {title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    <Stack
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      flexDirection={"row"}
                      gap={"10px"}
                      sx={styles.formActions}>
                      <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        data-testid="close-btn"
                        onClick={handleOnClose}>
                        Cancel
                      </Button>
                      {isUserAdding ? (
                        <Button
                          variant="contained"
                          type="submit"
                          disabled={hasValidationErrors}
                          data-testid="save-button">
                          {"Save"}
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          onClick={() => setOpenComfirm(true)}
                          disabled={!selectedCreatedTime}
                          data-testid="save-button">
                          {"Remove Set Point"}
                        </Button>
                      )}
                    </Stack>
                  </form>
                );
              }}></Form>
          </Stack>
        )}
      </ModalComponent>
      <ConfirmationDialog
        title="Warning"
        text="The Set Point will be deleted for all user and cannot be restored"
        open={openComfirm}
        setOpen={setOpenComfirm}
        showWarningIcon={true}
        confirmButtonText="Remove Set Point"
        onConfirm={handleDelete}></ConfirmationDialog>
    </>
  );
};

export default SetPointsModal;

const styles = {
  textInput: {
    width: "100%"
  },
  formActions: {
    marginTop: "1rem"
  }
};
