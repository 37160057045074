import { BuildingsState, Sorters } from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FormattedBuildingWithEntity } from "src/shared/types/building";

const initialState: BuildingsState = {
  view: "list",
  reportModalOpen: false,
  buildingModalOpen: false,
  selectedBuilding: null,
  searchTerm: "",
  sorterType: "name",
  isEditingBuilding: false,
  tradefairSelectedPollutant: null,
  tradefairSelectedDeviceId: ""
};

export const buildingsSlice = createSlice({
  name: "buildings",
  initialState: { ...initialState },
  reducers: {
    setBuildingView: (
      state,
      action: PayloadAction<"list" | "map" | "chart">
    ) => {
      state.view = action.payload;
    },
    handleReportModal: (state, action: PayloadAction<boolean>) => {
      state.reportModalOpen = action.payload;
    },
    handleBuildingModal: (state, action: PayloadAction<boolean>) => {
      state.buildingModalOpen = action.payload;
    },
    setSelectedBuilding: (
      state,
      action: PayloadAction<FormattedBuildingWithEntity>
    ) => {
      state.selectedBuilding = action.payload;
    },
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    setSorterType: (state, action: PayloadAction<Sorters>) => {
      state.sorterType = action.payload;
    },
    setBuildingEditing: (state, action: PayloadAction<boolean>) => {
      state.isEditingBuilding = action.payload;
    },
    setTradefairSelectedPollutant: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.tradefairSelectedPollutant = action.payload;
    },
    setTradefairSelectedDeviceId: (state, action: PayloadAction<string>) => {
      state.tradefairSelectedDeviceId = action.payload;
    }
  }
});

export const {
  setBuildingView,
  handleReportModal,
  handleBuildingModal,
  setSelectedBuilding,
  setSearchTerm,
  setSorterType,
  setBuildingEditing,
  setTradefairSelectedPollutant,
  setTradefairSelectedDeviceId
} = buildingsSlice.actions;

export const { reducer: buildingsReducer } = buildingsSlice;
