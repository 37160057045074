import {
  AggGranularity,
  AggItem,
  Kettle,
  KettleData,
  KettleDataService,
  KettleSensorAndLatest,
  KettleService
} from "src/utils/client";
import { apiService } from "../api";

type KettleRequest = {
  buildingId: string;
  kettleId: string;
};

export const kettleService = apiService
  .enhanceEndpoints({
    addTagTypes: [
      "KETTLES",
      "KETTLESENSORS",
      "KETTLEDETAILS",
      "KETTLEWIDGET",
      "KETTLE",
      "KETTLECHART"
    ]
  })
  .injectEndpoints({
    endpoints: build => ({
      getKettlesForBuilding: build.query<Kettle[], string>({
        queryFn: async buildingId => {
          const kettles = await KettleService.getKettlesForBuilding(buildingId);
          return { data: kettles };
        },
        providesTags: ["KETTLES"]
      }),
      getKettleSensorsForBuilding: build.query<
        KettleSensorAndLatest[],
        { buildingId: string; kettleId: string }
      >({
        queryFn: async args => {
          const { buildingId, kettleId } = args;
          const sensors = await KettleService.getSensorsForKettle(
            buildingId,
            kettleId
          );
          return { data: sensors };
        },
        providesTags: ["KETTLESENSORS"]
      }),
      getKettleData: build.query<KettleData, KettleRequest>({
        queryFn: async args => {
          const { buildingId, kettleId } = args;
          const kettleData =
            await KettleDataService.getLatestKettleSensorDataPoint(
              kettleId,
              buildingId
            );
          return { data: kettleData };
        },
        providesTags: ["KETTLEWIDGET"]
      }),
      getKettle: build.query<any, KettleRequest>({
        queryFn: async args => {
          const { buildingId, kettleId } = args;
          const kettle = await KettleService.getKettle(buildingId, kettleId);
          return { data: kettle };
        },
        providesTags: (_result, _error, { kettleId }) => [
          { type: "KETTLE", kettleId }
        ]
      }),
      getKettleChartData: build.query<
        AggItem[],
        {
          sensorId: string;
          buildingId: string;
          startTime: string;
          endTime: string;
          granularity?: AggGranularity.DAY | AggGranularity.HOUR;
        }
      >({
        queryFn: async args => {
          const { sensorId, buildingId, startTime, endTime, granularity } =
            args;
          const data = await KettleDataService.getKettleSensorData(
            sensorId,
            buildingId,
            startTime,
            endTime,
            granularity
          );
          return { data };
        },
        providesTags: (_result, _error, { sensorId }) => [
          { type: "KETTLECHART", sensorId }
        ]
      })
    })
  });

export const {
  useGetKettlesForBuildingQuery,
  useGetKettleSensorsForBuildingQuery,
  useGetKettleDataQuery,
  useGetKettleQuery,
  useGetKettleChartDataQuery
} = kettleService;
