import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "20px",
    width: "100%",
    height: "100%",
    overflow: "auto",
    alignItems: "start"
  },
  container: {
    maxWidth: 1280
  },
  reportOptionsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "1em 0",
    gap: "4em"
  },
  reportOptionHalf: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  reportStep: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    gap: "1em",
    marginBottom: "1.5em"
  },
  reportStepNumber: {
    borderRadius: "50%",
    border: `1px solid ${
      theme.palette.primary[500] || theme.palette.primary["main"]
    }`,
    width: "30px",
    height: "30px",
    fontSize: "18px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.primary[500] || theme.palette.primary["main"]
  },
  optionsContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "2em",
    "@media (max-width: 1100px)": {
      width: "100%",
      overflow: "auto"
    }
  },
  optionItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "1em",
    border: `2px solid ${
      theme.palette.primary[500] || theme.palette.primary["main"]
    }`,
    padding: "1em 2em",
    backgroundColor: "#fff",
    margin: 16,
    marginBottom: 32,
    cursor: "pointer",
    "& svg": {
      color: theme.palette.primary[500] || theme.palette.primary["main"]
    },
    "@media (max-width: 550px)": {
      padding: "1em"
    }
  },
  itemTitle: {
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
    fontSize: "1.2em"
  },
  itemDescription: {
    fontWeight: "bold"
  },
  itemTitleSelected: {
    color: theme.palette.primary[500] || theme.palette.primary["main"]
  },
  optionItemSelected: {
    border: `2px solid ${
      theme.palette.primary[500] || theme.palette.primary["main"]
    }`,
    boxShadow: "0px 18px 18px 0px rgba(0,0,0,0.23)"
  },
  dashboardImage: {
    maxWidth: "150px",
    "@media (max-width: 1100px)": {
      width: "150px"
    }
  },
  datePickerSection: {
    width: "100%"
  },
  datePickerGroup: {
    display: "flex",
    flexDirection: "row",
    gap: "1em",
    alignItems: "center"
  },
  buttonsGroup: {
    display: "flex",
    flexDirection: "row",
    gap: "1em",
    justifyContent: "flex-end",
    marginTop: "2em",
    width: "100%"
  },
  clearButton: {
    marginTop: "1em",
    width: "20%",
    height: "2.5em"
  },
  btn: {
    marginRight: "1em",
    marginTop: "1em",
    gap: "1em",
    backgroundColor: "#fff"
  },
  datePicker: {
    marginTop: "1em",
    width: "40%"
  },
  submitBtn: {
    marginTop: "1em"
  },
  loader: {
    top: 0,
    left: 0
  },
  always: {
    marginTop: "1em"
  }
}));
