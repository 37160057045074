import { Card, Grid, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useAppSelector } from "src/hooks/useAppSelector";
import { useGetTradeFairDashboardInfoQuery } from "src/store/services/buildings/buildings.service";
import { getTradefairSelectedPollutant } from "src/store/buildings/selector";
import useGetBuildings from "src/hooks/useGetBuildings";
import { useParams } from "react-router-dom";
type Props = {
  aqData?: any;
  pollutant?: string;
};

const PollutantCardComponent = (props: Props) => {
  const { buildingId } = useParams();
  const { aqData } = props;
  const { building } = useGetBuildings(buildingId);
  const { classes } = useStyles();
  const { data: mainData } = useGetTradeFairDashboardInfoQuery(
    {
      companyId: building?.companyId,
      buildingId
    },
    { skip: !Boolean(building) }
  );
  const selectedPollutant = useAppSelector(getTradefairSelectedPollutant);
  // @ts-ignore
  const isTv = mainData.vertical_tv_resolution;
  let headerClass = classes.header;
  let statusText = aqData ? aqData.overall_status : null;
  let statusClass = aqData ? aqData.overall_status : null;

  if (isTv) {
    headerClass = classes.tvHeader;
  }

  if (selectedPollutant !== "total_aqi") {
    let pollutantData;

    if (aqData && aqData.pollutants) {
      pollutantData = aqData.pollutants.find(
        item => item.key === selectedPollutant
      );
    }

    if (pollutantData && pollutantData.pollutant_class) {
      statusText = pollutantData.pollutant_class;
      statusClass = pollutantData.pollutant_class;
    } else {
      statusText = "-";
      statusClass = null;
    }
  }

  return (
    <Card className={classes.root}>
      {aqData && (
        <>
          <div
            className={headerClass}
            style={{
              backgroundColor: mainData.color_dict[statusClass]
            }}>
            {statusText}
          </div>
          <div
            className={classes.data}
            data-testid="pollutant-card">
            <Grid container>
              {aqData.pollutants &&
                [...aqData.pollutants]
                  .sort((prev, next) => {
                    if (prev.order > next.order) {
                      return 1;
                    }
                    if (prev.order < next.order) {
                      return -1;
                    }
                    return 0;
                  })
                  .map((pollutant, index) => {
                    let alertStatus: boolean =
                      pollutant.value >=
                      mainData.pollutant_alert_dict[pollutant.key];
                    return (
                      <Grid
                        item
                        key={`pollutant_${pollutant.key}`}
                        xs={4}
                        sm={4}>
                        <div
                          className={classes.pollutant}
                          style={{
                            color: alertStatus ? mainData.alert_color : "black",
                            top:
                              aqData.pollutants.length > 3 && index === 2
                                ? 1
                                : 0,
                            position: "relative",
                            borderBottom:
                              aqData.pollutants.length > 3 && index === 2
                                ? "0.5px solid #BCCACC"
                                : "none"
                          }}>
                          <Typography className={classes.label}>
                            {pollutant.label}{" "}
                            {pollutant.short_label &&
                              `(${pollutant.short_label})`}
                          </Typography>
                          <div className={classes.pollutantQty}>
                            <Typography className={classes.qty}>
                              {pollutant.value}
                            </Typography>
                            <Typography className={classes.units}>
                              {pollutant.units}
                            </Typography>
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
            </Grid>
          </div>
        </>
      )}
      {!aqData && (
        <div className={classes.data}>Not enough data to display</div>
      )}
    </Card>
  );
};

export const useStyles = makeStyles()(() => {
  return {
    root: {
      borderRadius: 20,
      boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.15);",
      "@media (max-width: 600px)": {
        borderRadius: 8
      }
    },
    header: {
      height: 125,
      color: "white",
      fontSize: "28px",
      backgroundColor: "gray",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: 500,
      "@media (max-width: 600px)": {
        height: 60,
        fontSize: "22px"
      }
    },
    outdoorHeader: {
      height: 180,
      color: "white",
      fontSize: "28px",
      backgroundColor: "gray",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: 500,
      "@media (max-width: 600px)": {
        height: 60,
        fontSize: "22px"
      }
    },
    tvHeader: {
      height: 120,
      color: "white",
      fontSize: "28px",
      backgroundColor: "gray",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: 500,
      "@media (max-width: 600px)": {
        height: 60,
        fontSize: "22px"
      }
    },
    data: {
      padding: "8px 16px",
      "& :nth-of-type(1) > :first-of-type": {
        paddingLeft: 0,
        borderTop: "none"
      },
      "& :nth-of-type(2) > :first-of-type": {
        borderTop: "none"
      },
      "& :nth-of-type(3) > :first-of-type": {
        borderTop: "none",
        borderRight: "none"
      },
      "& :nth-of-type(6) > :first-of-type": {
        borderRight: "none"
      }
    },
    pollutant: {
      borderRight: "0.5px solid #BCCACC",
      borderTop: "0.5px solid #BCCACC",
      padding: 8,
      paddingBottom: 4,
      paddingTop: 4,
      overflow: "hidden",
      "@media (max-width: 600px)": {
        borderBottom: "none"
      },
      height: "100%"
    },
    pollutantQty: {
      display: "flex",
      alignItems: "baseline"
    },
    label: {
      fontSize: "14px",
      textOverflow: "ellipsis",
      overflow: "hidden"
    },
    qty: {
      fontSize: "14px",
      marginRight: "0.3em",
      fontWeight: 500
    },
    units: {
      fontSize: "9px"
    },
    comparison: {
      padding: 16,
      paddingTop: 8,
      paddingBottom: 8
    }
  };
});

export default PollutantCardComponent;
